import React from 'react';
import _ from 'lodash';
import Moment from 'react-moment';
import { ReactComponent as HelpIcon } from '../../../../assets/icons/help-circle.svg';
import l from '../../../../lang';
import './styles.scss';
import Loading from '../../../common/loading';
import MessageError from '../../../common/message-error';
import { createDate } from '../../../../utils/index';
import Popup from '../../../common/popup';
import config from '../../../../config';
import Card from '../../../common/card';

const Balances = ({ balances }) => {
  return (
    <Card>
      <div className="balances">
        <div className="balances__title">
          <span>{l('home.balances.title')}</span>
          {!balances.loading && !balances.error && !_.isEmpty(balances.data) && (
            <div className="balances__total-accumulated">
              <span className="balances__total-accumulated__title">{l('home.balance.totalAccumulated')}</span>
              <span>{balances.data.totalAccumulated}</span>
              <span className="balances__total-accumulated__currency">{balances.data.currency}</span>
              <Popup text={l('balance.totalAccumulated.desc')} icon={<HelpIcon />} />
            </div>
          )}
        </div>
        {!balances.loading && !balances.error && _.isEmpty(balances.data) && (
          <div className="balances__no-balance">
            <div>{l('balances.noData')}</div>
          </div>
        )}
        {balances.loading && (
          <div className="balances__loading">
            <Loading />
          </div>
        )}
        {balances.error && (
          <div className="balances__error">
            <MessageError />
          </div>
        )}
        {!balances.loading && !balances.error && !_.isEmpty(balances.data) && (
          <>
            <div className="balances__box">
              <div className="balances__desc">
                <span>{l('home.balance.availableBalance')}</span>
                <Popup text={l('balance.availableBalance.desc')} icon={<HelpIcon />} />
              </div>
              <div className="balances__number">
                <span>
                  {balances.data.currency} {balances.data.availableBalance}
                </span>
              </div>
            </div>
            <div className="balances__box balances__box--center">
              <div className="balances__desc">
                <span>{l('home.balance.inTransitBalance')}</span>
                <Popup
                  text={l('balance.inTransitBalance.desc', config.daysToWithdrawLocalPMs, config.daysToWithdrawCards)}
                  icon={<HelpIcon />}
                />
              </div>
              <div className="balances__number">
                <span>
                  {balances.data.currency} {balances.data.inTransitBalance}
                </span>
              </div>
            </div>
            <div className="balances__box">
              <div className="balances__desc">
                <span>{l('home.balance.currentBalance')}</span>
                <Popup text={l('balance.currentBalance.desc')} icon={<HelpIcon />} />
              </div>
              <div className="balances__number">
                <span>
                  {balances.data.currency} {balances.data.currentBalance}
                </span>
              </div>
            </div>
            {balances.data.updatedAt && (
              <div className="balances__note">
                <span>{l('home.balance.lastUpdate')} </span>
                <Moment format="DD/MM/YYYY hh:mm:ss a">{createDate(balances.data.updatedAt)}</Moment>
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default Balances;
