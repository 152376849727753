import { useHistory } from 'react-router-dom';
import Parser from 'html-react-parser';

import l from '../../../../lang';

import { Routes } from '../../../../config/routes';

import LinkIcon from '../../../../assets/icons/payment-link-squared.svg?url';
import SubscriptionsIcon from '../../../../assets/icons/payment-subscriptions-squared.svg?url';
import DgoCircleIcon from '../../../../assets/icons/payment-dgo-squared.svg?url';
import ArrowRight from '../../../../assets/icons/arrow-right-blue.svg?url';

import ButtonLink from '../../../common/button-link';
import CardTitle from '../../../common/card-title';
import Card from '../../../common/card';
import CardPayment from '../../../common/card-payment';

import { getLanguage, openNewTabAndFocus } from '../../../../utils';
import { Urls } from '../../../../config/urls';

import {
  segmentProductMenuCreatePaymentLink,
  segmentProductMenuSeeFirstStepsDoc,
  segmentProductMenuUseCommissions,
} from '../../../../utils/analytics';

interface ProductCardsProps {
  shouldAnimationsRun: boolean;
}

const ProductCards = ({ shouldAnimationsRun }: ProductCardsProps) => {
  const history = useHistory();

  const onPaymentLinkHandler = () => {
    segmentProductMenuCreatePaymentLink();
    history.push(Routes.CREATE_ONE_TIME_PAYMENT_LINK);
  };

  const onPayAsYouGoHandler = () => {
    segmentProductMenuUseCommissions();
    history.push(Routes.PAYMENT_METHODS);
  };

  const onFirstStepsHandler = () => {
    const lang = getLanguage() || 'en';
    const docUrl = Urls.docs.firstSteps[lang];

    segmentProductMenuSeeFirstStepsDoc();
    openNewTabAndFocus(docUrl);
  };

  return (
    <Card>
      <CardTitle>{l('home.readyMsg.subtitle')}</CardTitle>
      <div className="products-desc">{l('home.product.desc')}</div>
      <div className={`three-cards ${shouldAnimationsRun ? 'card__animated' : ''}`}>
        <CardPayment>
          <div className="card__title card__with-icon">
            <img src={DgoCircleIcon} alt="checked" />
            {l('home.product.payment.first-steps')}
          </div>
          <div className="card__desc">{Parser(l('home.product.payment.first-steps.desc'))}</div>
          <div className="card__desc bottom-link">
            <ButtonLink
              text={
                <div className="card__with-icon">
                  {l('home.product.payment.first-steps.link')}
                  <img src={ArrowRight} className="arrow" />
                </div>
              }
              onClick={onFirstStepsHandler}
            />
          </div>
        </CardPayment>

        <CardPayment>
          <div className="card__title card__with-icon">
            <img src={SubscriptionsIcon} alt="checked" />
            {l('home.product.payment.commissions')}
          </div>
          <div className="card__desc">{Parser(l('home.product.payment.commissions.desc'))}</div>
          <div className="card__desc bottom-link">
            <ButtonLink
              text={
                <div className="card__with-icon">
                  {l('home.product.payment.commissions.link')}
                  <img src={ArrowRight} className="arrow" />
                </div>
              }
              onClick={onPayAsYouGoHandler}
            />
          </div>
        </CardPayment>

        <CardPayment>
          <div className="card__title card__with-icon">
            <img src={LinkIcon} alt="checked" />
            {l('home.product.payment.link')}
          </div>
          <div className="card__desc">{Parser(l('home.product.payment.link.desc'))}</div>
          <div className="card__desc bottom-link">
            <ButtonLink
              text={
                <div className="card__with-icon">
                  {l('home.product.payment.link.link')}
                  <img src={ArrowRight} className="arrow" />
                </div>
              }
              onClick={onPaymentLinkHandler}
            />
          </div>
        </CardPayment>
      </div>
    </Card>
  );
};

export default ProductCards;
