import { useDispatch, useSelector } from 'react-redux';
import {
  getPlans,
  getSubscriptions,
  setSubscriptionDetails,
  setPlanDetails,
  deactivateSubscription,
  clearCreateUpdatePlan,
  createUpdatePlan,
  deactivatePlan,
  cleanErrorSubscriptions,
  getCurrencies,
  cleanCurrencies,
} from './actions';
import { SubscriptionsSelectors } from './selectors';
import { Plan, Subscription } from './interfaces';
import { Values } from '../interfaces';

export const useSubscriptions = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(SubscriptionsSelectors.getLoading),
    error: useSelector(SubscriptionsSelectors.getError),
    errorInfo: useSelector(SubscriptionsSelectors.getErrorInfo),
    allSubscriptions: useSelector(SubscriptionsSelectors.getAllSubscriptions),
    details: useSelector(SubscriptionsSelectors.getDetails),
    allPlans: useSelector(SubscriptionsSelectors.getAllPlans),
    currencies: useSelector(SubscriptionsSelectors.getCurrencies),
    exchangeRates: useSelector(SubscriptionsSelectors.getExchangeRates),
    createUpdatePlanSuccess: useSelector(SubscriptionsSelectors.createUpdatePlanSuccess),
    planDetails: useSelector(SubscriptionsSelectors.getPlanDetails),
    deactivateSubscription: useSelector(SubscriptionsSelectors.getDeactivateSubscription),
    deactivatePlan: useSelector(SubscriptionsSelectors.getDeactivatePlan),
    cleanErrorSubscriptions: () => dispatch(cleanErrorSubscriptions()),
    getCurrencies: (country: string) => dispatch(getCurrencies(country)),
    getPlans: () => dispatch(getPlans()),
    cleanCurrencies: () => dispatch(cleanCurrencies()),
    createUpdatePlan: (values: Values) => dispatch(createUpdatePlan(values)),
    clearCreateUpdatePlan: () => dispatch(clearCreateUpdatePlan()),
    getSubscriptions: (page?: number, rowsPerPage?: number) => dispatch(getSubscriptions(page, rowsPerPage)),
    setSubscriptionDetails: (subscription: Subscription | null) => dispatch(setSubscriptionDetails(subscription)),
    setPlanDetails: (plan: Plan | null) => dispatch(setPlanDetails(plan)),
    setDeactivateSubscription: (subscriptionId: number) => dispatch(deactivateSubscription(subscriptionId)),
    setDeactivatePlan: (planId: number) => dispatch(deactivatePlan(planId)),
  };
};
