import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const PopupInElement = ({ Content, top, children, disabled }) => {
  const ref = useRef(null);
  const [focusInInput, setFocusInInput] = useState(false);
  useEffect(() => {
    if (ref.current && !disabled) {
      const inputs = ref.current.getElementsByTagName('input');
      if (inputs.length > 0) {
        Array.from(inputs).forEach(input => {
          input.addEventListener('focus', () => {
            setFocusInInput(true);
          });
          input.addEventListener('blur', () => {
            setFocusInInput(false);
          });
        });
      }
    }
  }, []);

  return (
    <>
      {Content && !disabled ? (
        <div ref={ref} className={`popover__wrapper ${focusInInput ? 'focus_input' : ''}`}>
          <a>{children}</a>
          <div className={`popover__content ${top ? 'popover__content--top' : ''}`}>
            <p className="popover__message">{Content}</p>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

PopupInElement.propTypes = {
  Content: PropTypes.element,
  top: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PopupInElement;
