import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Bugsnag from '@bugsnag/js';
import Parser, { domToReact } from 'html-react-parser';
import Message from '../message';
import l from '../../../lang';
import { defaultMsgGettingData, errorCodes } from '../../../utils/error-codes';

const MessageError = ({ errorInfo, iconClose, onClose }) => {
  const { level, title, message } = errorCodes().GENERIC_ERROR;
  let type = level;
  let titleKey = title;
  let messageKey = message;
  let args = [];
  let align = 'left';

  useEffect(() => {
    if (errorInfo?.messageArgs) {
      errorInfo.messageArgs.forEach(arg => {
        if (arg === defaultMsgGettingData) {
          Bugsnag.notify(new Error(`Error getting data for message: "${l(messageKey)}"`));
        }
      });
    }
  }, [errorInfo, messageKey]);

  if (errorInfo) {
    type = errorInfo.level;
    titleKey = errorInfo.title;
    messageKey = errorInfo.message;
    args = errorInfo.messageArgs || [];
    align = errorInfo.align ?? align;
  }

  return (
    <Message
      type={type}
      title={titleKey && l(titleKey)}
      align={align}
      body={
        <div>
          {messageKey &&
            Parser(l(messageKey, ...args), {
              replace: domNode => {
                try {
                  if (domNode.name === 'button') {
                    const onclick = domNode.attribs.onclick;
                    const className = domNode.attribs.class;

                    delete domNode.attribs.onclick;
                    delete domNode.attribs.class;
                    return (
                      <button {...domNode.attribs} className={className} onClick={() => eval(onclick)}>
                        {domToReact(domNode.children)}
                      </button>
                    );
                  }
                } catch (error) {
                  Bugsnag.notify(new Error(`Error trying parse message: "${l(messageKey)}"`));
                }
              },
            })}
        </div>
      }
      iconClose={iconClose}
      onClose={onClose}
    />
  );
};

MessageError.propTypes = {
  errorInfo: PropTypes.object,
  iconClose: PropTypes.object,
  onClose: PropTypes.func,
};

export default MessageError;
