import React, { FunctionComponent } from 'react';
import ButtonCustom from '../../../../../common/__deprecated__/button-custom';

interface CardHeaderButonProps {
  text: string;
  sizeClass: string;
  icon: string;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const CardHeaderButon: FunctionComponent<CardHeaderButonProps> = ({ text, icon, sizeClass, disabled, onClick }) => (
  <ButtonCustom
    size={sizeClass}
    text={text}
    endIcon={<img src={icon} alt="status-btn" />}
    variant="contained"
    onClick={onClick}
    disabled={disabled}
    component={undefined}
    innerComponent={undefined}
    fullWidth={undefined}
  />
);
