import { useDashboard } from '../../../../../store/dashboard';
import l from '../../../../../lang';
import Card from '../../../../common/card';
import UserCircleIcon from '../../../../../assets/icons/user-circle.svg?url';
import { Urls } from '../../../../../config/urls';

import './styles.scss';

const BankAccountCompanyInformation = () => {
  const { merchantMe } = useDashboard();
  const { country, name, lastName, documentType, document } = merchantMe!;

  return (
    <Card>
      <div className="bank_account_company_information">
        <div className="bank_account_company_information__header">
          <img src={UserCircleIcon} alt="user" width={20} />
          <h2>{l('companyInformation.kycTwo.companyOwnerLegalInformation')}</h2>
        </div>
        <div className="bank_account_company_information__container">
          <div className="bank_account_company_information__container__data">
            <div className="bank_account_company_information__container__data__field_name">{l('beneficiary.name')}</div>
            <div className="bank_account_company_information__container__data__field_value">
              {`${name || ''} ${lastName || ''}`.trim()}
            </div>
          </div>
          <div className="bank_account_company_information__container__data">
            <div className="bank_account_company_information__container__data__field_name">{l('beneficiary.country')}</div>
            <div className="bank_account_company_information__container__data__field_value">
              <img
                alt={`img-country-${country}`}
                className="clients__country"
                src={`${Urls.flags.replace(':code', country)}`}
              />
              <span>{l(`countryCode.${country}`)}</span>
            </div>
          </div>
          <div className="bank_account_company_information__container__data">
            <div className="bank_account_company_information__container__data__field_name">
              {l('beneficiary.documentType')}
            </div>
            <div className="bank_account_company_information__container__data__field_value">{documentType}</div>
          </div>
          <div className="bank_account_company_information__container__data">
            <div className="bank_account_company_information__container__data__field_name">{l('beneficiary.document')}</div>
            <div className="bank_account_company_information__container__data__field_value">{document}</div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BankAccountCompanyInformation;
