import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import l from '../../../lang';
import CreatePaymentForm from './create-payment-form';
import ButtonCustom from '../../common/__deprecated__/button-custom';
import Loading from '../../common/loading';
import { init, paymentResult } from '../../../store/virtual-pos/actions';
import TopDashboardSection from '../../common/top-dashboard-section';
import Dialog from '../../common/dialog';

const VirtualPOS = ({ loading, showResult, confirmSuccess, fnInit, fnShowResult }) => {
  useEffect(() => {
    fnInit();
  }, [fnInit]);

  useEffect(() => {
    if (confirmSuccess) {
      fnShowResult(true);
    }
  }, [fnShowResult, confirmSuccess]);

  return (
    <>
      <TopDashboardSection title={l('menu.item.VIRTUAL_POS')} subtitle={l('menu.item.VIRTUAL_POS.desc')} />
      <div className="virtual-pos">
        <div className="virtual-pos__content">
          {loading && <Loading fullScreen />}
          <CreatePaymentForm key={showResult} />
          {showResult && (
            <Dialog
              dividers={false}
              title={l('virtualPos.title')}
              unpaddingFooter
              unpaddingContent
              fullScreenMobile
              body={
                <div className="virtual-pos__result">
                  <div>
                    <div className="virtual-pos__title">{l('virtualPos.paymentCreated')}</div>
                  </div>
                </div>
              }
              footer={
                <div className="confirm-footer-container">
                  <ButtonCustom
                    text={l('common.close')}
                    size="full-bottom"
                    variant="contained"
                    onClick={() => fnShowResult(false)}
                  />
                </div>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

VirtualPOS.propTypes = {
  loading: PropTypes.bool,
  confirmSuccess: PropTypes.object,
  showResult: PropTypes.bool,
  fnShowResult: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.virtualPos.loading,
  confirmSuccess: state.virtualPos.confirmSuccess,
  showResult: state.virtualPos.showResult,
});

const mapDispatchToProps = dispatch => ({
  fnInit: () => dispatch(init()),
  fnShowResult: country => dispatch(paymentResult(country)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VirtualPOS);
