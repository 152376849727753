import { FC } from 'react';
import l from '../../../../../../lang';
import { Urls } from '../../../../../../config/urls';
import Dialog from '../../../../../common/dialog';
import { useMediaQuery } from '@mui/material';
import { ReactComponent as AllCountriesIcon } from '../../../../../../assets/icons/all-countries.svg';
import './styles.scss';
import { useDashboard } from '../../../../../../store/dashboard';
import ShareLink from '../../../../payment-link/share-link';

interface PlanCreatedDialogProps {
  isEdition?: boolean;
  name: string;
  url: string;
  country: string;
  currency: string;
  amount: number;
  description: string;
  frequencyType: string;
  frequencyValue: number;
  crossBorderData?: { secondCurrency: string; secondAmount: number };
  onClose: () => void;
}

const PlanCreatedDialog: FC<PlanCreatedDialogProps> = ({
  name,
  frequencyValue,
  frequencyType,
  url,
  country,
  currency,
  amount,
  description,
  crossBorderData,
  onClose,
}) => {
  const isTabletMobile = useMediaQuery('(max-width:768px)');
  const { merchantMe } = useDashboard();
  const crossBorderCurrency = 'USD';

  const isCrossBorder = merchantMe?.country !== country;

  const descriptionBox = (
    <div className="plan_dialog__share__card">
      <div className="plan_dialog__share__card__headline">{l('paymentLink.shareLink.field3')}</div>
      <div className="plan_dialog__share__card__content">{description || ' - '}</div>
    </div>
  );

  return (
    <Dialog
      dividers={false}
      title={<p className="plan_dialog__share__headline">{l('subscriptions.plan.sharePage.pageTitle')}</p>}
      unpaddingFooter
      fullScreenMobile
      handleClose={onClose}
      type="sm"
      body={
        <div className="plan_dialog__share">
          <div className="plan_dialog__share__wrapper">
            <div className="plan_dialog__share__cards">
              <div className="plan_dialog__share__card">
                <div className="plan_dialog__share__card__headline">{l('subscriptions.plan.summaryCard.planName')}</div>
                <div className="plan_dialog__share__card__content">{name}</div>
              </div>
              <div className="vertical_line" />
              <div className="plan_dialog__share__card">
                <div className="plan_dialog__share__card__headline">{l('paymentLink.shareLink.field1')}</div>
                <div className="plan_dialog__share__card__content">
                  <div className="plan_dialog__share__card__country">
                    {country ? (
                      <>
                        <img alt="" src={`${Urls.flags.replace(':code', country)}`} />
                        <span>{l(`countryCode.${country}`)}</span>
                      </>
                    ) : (
                      <>
                        <AllCountriesIcon height={18} width={18} />
                        <span>{l(`subscriptions.allCountries`)}</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="plan_dialog__share__cards">
              {!isTabletMobile && <div className="vertical_line" />}
              <div className="plan_dialog__share__card">
                <div className="plan_dialog__share__card__headline">
                  {isCrossBorder && crossBorderData && crossBorderData?.secondCurrency !== currency ? (
                    <>
                      {country ? (
                        <>
                          <div className="plan_dialog__share__card__headline__half_left">
                            {l(
                              crossBorderCurrency === currency
                                ? 'paymentLink.amountWantToCharge'
                                : 'paymentLink.amountYourClientWillPay'
                            )}
                          </div>

                          <div className="plan_dialog__share__card__headline__half_right">
                            {l(
                              crossBorderCurrency === currency
                                ? 'paymentLink.amountYourClientWillPay'
                                : 'paymentLink.amountYouWillReceive'
                            )}
                          </div>
                        </>
                      ) : (
                        <span>
                          {l(
                            crossBorderCurrency === currency
                              ? 'paymentLink.amountWantToCharge'
                              : 'paymentLink.amountYourClientWillPay'
                          )}
                        </span>
                      )}
                    </>
                  ) : (
                    <span>{l('paymentLink.shareLink.field2')}</span>
                  )}
                </div>
                <div className="plan_dialog__share__card__content">
                  {isCrossBorder && crossBorderData && crossBorderData?.secondCurrency !== currency ? (
                    <>
                      {country ? (
                        <>
                          <div className="plan_dialog__share__card__content__half_left">
                            {currency} {amount}
                          </div>
                          <div className="plan_dialog__share__card__content__half_right">
                            {crossBorderData?.secondCurrency} {crossBorderData?.secondAmount}
                          </div>
                        </>
                      ) : (
                        <div>
                          {currency} {amount}
                        </div>
                      )}
                    </>
                  ) : (
                    <span>
                      {currency} {amount}
                    </span>
                  )}
                </div>
              </div>
              <div className="vertical_line" />
              <div className="plan_dialog__share__card">
                <div className="plan_dialog__share__card__headline">{l('subscriptions.plan.card3.frequency')}</div>
                <div className="plan_dialog__share__card__content">
                  {frequencyValue}{' '}
                  {l(`subscriptions.frequency.${frequencyType}${(frequencyValue || 0) > 1 ? '.plural' : ''}`)}
                </div>
              </div>
            </div>
            {isTabletMobile && <>{descriptionBox}</>}
          </div>
          <ShareLink url={url} />
        </div>
      }
      footer={<div className="plan_dialog__share__footer" />}
    />
  );
};

export default PlanCreatedDialog;
