import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import config from './config';

Bugsnag.start({
  ...config,
  plugins: [new BugsnagPluginReact()],
});

export const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
