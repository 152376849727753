import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { MenuItem, Menu } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import l from '../../lang';
import './styles.scss';
import { Button } from '../common/button';

const useStyles = makeStyles({
  root: {
    '& .MuiButton-label': {
      textTransform: 'none',
    },
  },
});

const LanguageSelect = ({ languages, onClick, selected = '' }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelecLang = lang => {
    if (selected !== lang.value) {
      onClick(lang.value);
    }
    handleClose();
  };

  return (
    <>
      <Button variant='text' className={classes.root} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {l(`common.language.${selected}`)}
        <ExpandMoreIcon />
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {React.Children.toArray(
          languages.map(lang => (
            <MenuItem key={lang.value} onClick={() => handleSelecLang(lang)}>
              {l(`common.language.${lang.value}`)}
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
};

LanguageSelect.propTypes = {
  languages: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default LanguageSelect;
