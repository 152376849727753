import { Grid, useMediaQuery } from '@mui/material';
import l from '../../../../../lang';

import './styles.scss';
import { Formik } from 'formik';
import { Button } from '../../../../common/button';
import Loading from '../../../../common/loading';
import MessageError from '../../../../common/message-error';
import { CreatePlanForm } from './form';
import TopDashboardSection from '../../../../common/top-dashboard-section';
import { ReactComponent as BackIcon } from '../../../../../assets/icons/back.svg';
import ButtonLink from '../../../../common/button-link';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '../../../../../config/routes';
import { SUBSCRIPTION_ALL_COUNTRIES, SubscriptionCustomFrequency, SubscriptionFrequency } from '../../../../../constants';
import ReviewPlan from './review-plan';
import { useEffect, useState } from 'react';
import { useSubscriptions } from '../../../../../store/subscriptions';
import PlanCreatedDialog from './plan-created-dialog';
import { Plan } from '../../../../../store/subscriptions/interfaces';
import { getSubscriptionFrequency, getSubscriptionFrequencyType } from '../../../../../utils';
import Card from '../../../../common/card';
import { PlanSummary } from './summary';

export interface SubscriptionValues {
  country: string;
  currency: string;
  amount: number;
  planName: string;
  description: string;
  frequency: SubscriptionFrequency;
  frequencyValue: number;
  frequencyType: SubscriptionCustomFrequency;
  crossBorderData: {
    secondCurrency: string;
    secondAmount: number;
  };
}

const CreateEditPlan = () => {
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const history = useHistory();
  const { id: planId } = useParams<{ id: string }>();
  const [isReviewing, setIsReviewing] = useState(false);
  const { createUpdatePlan, loading, error, errorInfo, createUpdatePlanSuccess, allPlans, clearCreateUpdatePlan } =
    useSubscriptions();
  const [editPlan] = useState<Plan | null>(allPlans?.data.find(p => `${p.id}` === planId) || null);

  useEffect(() => {
    if (planId && !editPlan) {
      backTo();
    }
  }, []);

  const initialValues = {
    country: editPlan ? editPlan?.country || 'ALL' : '',
    currency: editPlan?.currency || '',
    amount: editPlan?.amount || 0,
    planName: editPlan?.name || '',
    description: editPlan?.description || '',
    frequency:
      editPlan?.frequencyType && editPlan?.frequencyValue
        ? getSubscriptionFrequency(editPlan?.frequencyType, editPlan?.frequencyValue)
        : SubscriptionFrequency.MONTHLY,
    frequencyValue: editPlan?.frequencyValue || 15,
    frequencyType: editPlan?.frequencyType
      ? getSubscriptionFrequencyType(editPlan?.frequencyType)
      : SubscriptionCustomFrequency.DAY,
    crossBorderData: {
      secondCurrency: '',
      secondAmount: 0,
    },
  };

  const validate = (values: SubscriptionValues) => {
    const errors: Partial<Record<keyof SubscriptionValues, string>> = {};
    if (!values.country) {
      errors.country = l('form.input.required');
    }
    if (!values.currency) {
      errors.currency = l('form.input.required');
    }
    if (!values.amount) {
      errors.amount = l('form.input.required');
    } else if (values.amount < 1 ) {
      errors.amount = l('paymentLink.amount.error');
    }
    if (!values.planName) {
      errors.planName = l('form.input.required');
    }
    if (!values.description) {
      errors.description = l('form.input.required');
    }
    if (!values.frequency) {
      errors.frequency = l('form.input.required');
    }
    if (values.frequency === SubscriptionFrequency.CUSTOM) {
      if (!values.frequencyValue) {
        errors.frequencyValue = l('form.input.required');
      }
      if (!values.frequencyType) {
        errors.frequencyType = l('form.input.required');
      }
    }
    return errors;
  };

  const backTo = () => {
    if (isReviewing) {
      setIsReviewing(false);
    } else {
      history.push(Routes.SUBSCRIPTIONS_PLANS);
    }
  };

  const handleSubmit = (values: SubscriptionValues) => {
    createUpdatePlan({
      ...(editPlan && { id: editPlan.id, merchantId: editPlan.merchantId }),
      country: values.country !== SUBSCRIPTION_ALL_COUNTRIES ? values.country : null,
      currency: values.currency,
      amount: values.amount,
      name: values.planName,
      description: values.description,
      frequencyValue: values.frequencyValue,
      frequencyType: values.frequencyType,
    });
  };

  return (
    <>
      <TopDashboardSection
        title={l('menu.item.SUBSCRIPTIONS')}
        componentBottom={
          <div className="payment-link__back_btn">
            <ButtonLink
              type="secondary"
              text={
                <>
                  <BackIcon />{' '}
                  {isReviewing ? l('subscriptions.plans.details.backEdit') : l('subscriptions.plans.details.backBtn')}
                </>
              }
              onClick={backTo}
            />
          </div>
        }
      />
      <div className="create_plan__container">
        <Formik<SubscriptionValues>
          initialValues={initialValues}
          validate={validate}
          onSubmit={values => {
            if (isReviewing) {
              handleSubmit(values);
            } else {
              setIsReviewing(true);
            }
          }}
        >
          {formikParams => (
            <Grid container spacing={2}>
              <Grid item md={8} xs={12}>
                <Card>
                  {error && (
                    <div className="create_plan__error">
                      <MessageError errorInfo={errorInfo} />
                    </div>
                  )}
                  {!isReviewing && (
                    <div>
                      <div className="create_plan__title">
                        {editPlan ? l('subscriptions.plan.editPlan') : l('subscriptions.plan.createPlan')}
                      </div>
                      <div className="create_plan__subtitle">
                        {editPlan
                          ? l('subscriptions.plan.editPlanDescription')
                          : l('subscriptions.plan.createPlanDescription')}
                      </div>
                      <form onSubmit={formikParams.handleSubmit}>
                        <CreatePlanForm isEdition={!!editPlan} {...formikParams}></CreatePlanForm>
                      </form>
                    </div>
                  )}
                  {isReviewing && (
                    <div>
                      <div className="create_plan__title">{l('subscriptions.plan.confirmPlanDetails.pageTitle')}</div>
                      <div className="create_plan__subtitle">
                        {editPlan
                          ? l('subscriptions.plan.confirmUpdatePlanDetails.pageDescription')
                          : l('subscriptions.plan.confirmPlanDetails.pageDescription')}
                      </div>
                      <ReviewPlan {...formikParams.values} />
                    </div>
                  )}
                  <div className="create_plan__confirm">
                    <Button onClick={formikParams.submitForm} type="submit" fullWidth>
                      {isReviewing ? (
                        <span>
                          {editPlan
                            ? l('subscriptions.plan.confirmUpdatePlanDetails.updatePlan')
                            : l('subscriptions.plan.confirmPlanDetails.createPlan')}
                        </span>
                      ) : (
                        l('subscriptions.plan.updateSubscription.reviewPlan')
                      )}
                    </Button>
                    <Button className="create_plan__cancel" onClick={backTo} variant="text" fullWidth>
                      {l('common.cancel')}
                    </Button>
                  </div>
                </Card>
              </Grid>
              {isDesktop && !isReviewing && (
                <Grid item md={4} xs={12}>
                  <PlanSummary values={formikParams.values} />
                </Grid>
              )}
              {createUpdatePlanSuccess && (
                <PlanCreatedDialog
                  url={createUpdatePlanSuccess.url as string}
                  country={createUpdatePlanSuccess.country as string}
                  currency={createUpdatePlanSuccess.currency}
                  amount={createUpdatePlanSuccess.amount}
                  description={createUpdatePlanSuccess.description}
                  name={createUpdatePlanSuccess.name}
                  frequencyType={createUpdatePlanSuccess.frequencyType}
                  frequencyValue={createUpdatePlanSuccess.frequencyValue}
                  crossBorderData={{
                    secondCurrency: formikParams.values.crossBorderData.secondCurrency,
                    secondAmount: formikParams.values.crossBorderData.secondAmount,
                  }}
                  onClose={() => {
                    clearCreateUpdatePlan();
                    history.push(Routes.SUBSCRIPTIONS_PLANS);
                  }}
                />
              )}
              {loading && <Loading fullScreen />}
            </Grid>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateEditPlan;
