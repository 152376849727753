import { IconButton as MuiIconButton } from '@mui/material';
import { styled } from '@mui/system';
interface IconButtonProps {
  children: JSX.Element;
  onClick?: () => void;
}

const DefaultIconButton = (props: IconButtonProps) => {
  return <MuiIconButton {...props} />;
};

const IconButton = styled(DefaultIconButton)(({ theme }) => ({
  padding: 0,
}));

export default IconButton;
