import Loading from '../../../common/loading';
import MessageError from '../../../common/message-error';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import l from '../../../../lang';
import { useEffect, useState } from 'react';
import { Refresh } from '@mui/icons-material';
import Table from '../../../common/table';
import { ReactComponent as EmptyIcon } from '../../../../assets/icons/empty-subscriptions.svg';
import Moment from 'react-moment';
import ChipStatus from '../../../common/chip-status';
import { Urls } from '../../../../config/urls';
import { useSubscriptions } from '../../../../store/subscriptions';
import { ReactComponent as AllCountriesIcon } from '../../../../assets/icons/all-countries.svg';
import ButtonMoreMenu from '../../../common/button-more-menu';
import { Routes } from '../../../../config/routes';
import { Subscription } from '../../../../store/subscriptions/interfaces';
import DialogSubscriptionCancel from '../dialog-cancel';

const Subscriptors = () => {
  const history = useHistory();
  const { loading, error, allSubscriptions, getSubscriptions, setSubscriptionDetails, setDeactivateSubscription } =
    useSubscriptions();
  const [cancelSubscription, setCancelSubscription] = useState<Subscription | null>(null);

  useEffect(() => {
    getSubscriptions();
  }, []);

  useEffect(() => {
    if (allSubscriptions?.outdated) getSubscriptions();
  }, [allSubscriptions?.outdated]);

  const showDetail = (sub: Subscription) => {
    setSubscriptionDetails(sub);
    history.push(Routes.SUBSCRIPTIONS_DETAIL);
  };

  const dataExist = allSubscriptions && allSubscriptions?.data?.length > 0;

  return (
    <div className="subscriptors">
      {loading && (
        <div className="subscriptors__loading">
          <Loading />
        </div>
      )}
      {error && (
        <div className="subscriptors__error">
          <MessageError iconClose={<Refresh />} onClose={() => getSubscriptions()} />
        </div>
      )}
      {!loading && !error && (
        <div>
          <Table
            dense
            withBtnMenu
            headers={[
              <span className="subscriptors__left">{l('subscriptions.startDate')}</span>,
              <span className="subscriptors__left">{l('subscriptions.clientName')}</span>,
              <span className="subscriptors__left">{l('subscriptions.planName')}</span>,
              <span className="subscriptors__left">{l('subscriptions.country')}</span>,
              <span className="subscriptors__right">{l('subscriptions.amountToCharge')}</span>,
              <span className="subscriptors__left">{l('subscriptions.status')}</span>,
              <span className="subscriptors__left">{l('subscriptions.lastPaymentStatus')}</span>,
            ]}
            emptyBody={
              <div className="subscriptors__no_data">
                <EmptyIcon />
                <div className="subscriptors__no_data__title">{l('subscriptions.emptySubscriptions.title')}</div>
                <div>{l('subscriptions.emptySubscriptions.body')}</div>
              </div>
            }
            body={
              (dataExist &&
                allSubscriptions?.data?.map(sub => [
                  <span className="subscriptors__left">
                    {sub.createdAt ? <Moment format="DD/MM/YYYY">{sub.createdAt}</Moment> : '-'}
                  </span>,
                  <span className="subscriptors__left">
                    {sub.clientFirstName} {sub.clientLastName}
                  </span>,
                  <span className="subscriptors__left">{sub.plan.name}</span>,
                  <span className="subscriptors__left">
                    {sub.country ? (
                      <span className="subscriptors__country">
                        <img
                          alt=""
                          className="subscriptors__country_flag"
                          src={`${Urls.flags.replace(':code', sub.country)}`}
                        />
                        <span>&nbsp;{sub.country}</span>
                      </span>
                    ) : (
                      <span className="subscriptors__country">
                        <AllCountriesIcon />
                        <span>&nbsp;{l('subscriptions.allCountries')}</span>
                      </span>
                    )}
                  </span>,
                  <span className="subscriptors__right">{sub.plan.currency + ' ' + sub.plan.amount}</span>,
                  <span className="subscriptors__left">
                    {sub.active ? (
                      <div className="subscriptors__status_active">{l('subscriptions.active')}</div>
                    ) : (
                      <div className="subscriptors__status_inactive">{l('subscriptions.inactive')}</div>
                    )}
                  </span>,
                  <span className="subscriptors__left">
                    {(sub.subscriptionExecutions?.length && sub.subscriptionExecutions[0].status && (
                      <ChipStatus
                        withBullet={false}
                        fullWidth
                        status={sub.subscriptionExecutions[0].status}
                        text={l(`subscriptions.executionStatus.${sub.subscriptionExecutions[0].status}`)}
                      />
                    )) ||
                      '-'}
                  </span>,
                  <ButtonMoreMenu
                    items={[
                      { content: l('subscriptions.seeDetails'), onClick: () => showDetail(sub) },
                      {
                        content: <span className="subscriptors__alert_label">{l('subscriptions.details.cancel')}</span>,
                        onClick: () => setCancelSubscription(sub),
                        hidden: !sub.active,
                      },
                    ]}
                  />,
                ])) ||
              null
            }
            rowsPerPageOptions={[10, 25, 50]}
            rowsLength={allSubscriptions?.totalElements}
            rowsPerPage={allSubscriptions?.size}
            page={(allSubscriptions?.page || 1) - 1}
            handleChangePage={(event, newPage) => getSubscriptions(newPage + 1, allSubscriptions?.size)}
            handleChangeRowsPerPage={event => getSubscriptions(1, event.target.value)}
            maxHeight={undefined}
          />
        </div>
      )}
      {cancelSubscription && (
        <DialogSubscriptionCancel
          cancelType="SUBSCRIPTION"
          onClose={confirm => {
            setCancelSubscription(null);
            if (confirm && cancelSubscription?.id) setDeactivateSubscription(cancelSubscription.id);
          }}
        />
      )}
    </div>
  );
};

export default Subscriptors;
