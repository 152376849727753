import _ from 'lodash';
import { errorFieldsCode, invalidDocumentCode } from '../../utils/error-codes';
import { handleErrorInfo } from '../../utils';
import { VirtualPosActionType } from './actions';
import { Currencies, DocumentTypes, ErrorFields, ExchangeRates } from '../interfaces';
import { VirtualPOSState } from './interfaces';

const initialState: VirtualPOSState = {
  loading: false,
  error: false,
  errorInfo: undefined,
  errorFields: undefined,
  currencies: {},
  exchangeRates: {},
  documentTypes: {},
  clientData: undefined,
  createSuccess: undefined,
  confirmSuccess: undefined,
  showResult: false,
};

const VirtualPos = (state = initialState, action: any) => {
  switch (action.type) {
    case VirtualPosActionType.INIT: {
      return {
        ...state,
        ...initialState,
      };
    }
    case VirtualPosActionType.GET_CURRENCIES: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        currencies: {},
        exchangeRates: {}
      };
    }
    case VirtualPosActionType.GET_CURRENCIES_SUCCESS: {
      const currencies: Currencies = {};
      const exchangeRates: ExchangeRates = {};
      action.response.forEach((c: { currency: string }) => {
        currencies[c.currency] = c.currency;
      });
      action.response.forEach((c: { currency: string, exchangeRate: number }) => {
        exchangeRates[c.currency] = c.exchangeRate;
      });
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        currencies,
        exchangeRates
      };
    }
    case VirtualPosActionType.GET_CURRENCIES_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(VirtualPosActionType.GET_CURRENCIES_ERROR, action.error),
        currencies: {},
        exchangeRates: {}
      };
    }
    case VirtualPosActionType.GET_DOCUMENT_TYPES: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        documentTypes: {},
      };
    }
    case VirtualPosActionType.GET_DOCUMENT_TYPES_SUCCESS: {
      const documentTypes: DocumentTypes = {};
      action.response.forEach((dt: { documentType: string }) => {
        documentTypes[dt.documentType] = dt.documentType;
      });
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        documentTypes,
      };
    }
    case VirtualPosActionType.GET_DOCUMENT_TYPES_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(VirtualPosActionType.GET_DOCUMENT_TYPES_ERROR, action.error),
        documentTypes: {},
      };
    }
    case VirtualPosActionType.CREATE_PAYMENT: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        errorFields: undefined,
        clientData: undefined,
        createSuccess: undefined,
      };
    }
    case VirtualPosActionType.CREATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        clientData: action.response.clientData,
        createSuccess: action.response.createSuccess,
      };
    }
    case VirtualPosActionType.CREATE_PAYMENT_ERROR: {
      const errorInfo = handleErrorInfo(VirtualPosActionType.CREATE_PAYMENT_ERROR, action.error);
      const errorFields: ErrorFields = {};

      if (errorInfo && errorInfo.code === errorFieldsCode && errorInfo.errorFields) {
        errorInfo.errorFields.forEach((field: string) => {
          errorFields[field] = action.error.sentValues[field];
        });
      } else if (errorInfo && errorInfo.code === invalidDocumentCode) {
        errorFields.clientDocument = action.error.sentValues.clientDocument;
      }
      if (!_.isEmpty(errorFields)) {
        return {
          ...state,
          loading: false,
          error: false,
          errorInfo: undefined,
          errorFields,
        };
      }
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo,
      };
    }
    case VirtualPosActionType.CONFIRM_PAYMENT: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        errorFields: undefined,
      };
    }
    case VirtualPosActionType.CONFIRM_PAYMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        clientData: undefined,
        confirmSuccess: {
          ...action.response,
        },
      };
    }
    case VirtualPosActionType.CONFIRM_PAYMENT_ERROR: {
      const errorInfo = handleErrorInfo(VirtualPosActionType.CONFIRM_PAYMENT_ERROR, action.error);
      const errorFields: ErrorFields = {};

      if (errorInfo && errorInfo.code === errorFieldsCode && errorInfo.errorFields) {
        errorInfo.errorFields.forEach((field: string) => {
          errorFields[field] = action.error.sentValues[field];
        });
      } else if (errorInfo && errorInfo.code === invalidDocumentCode) {
        errorFields.clientDocument = action.error.sentValues.clientDocument;
      }
      if (!_.isEmpty(errorFields)) {
        return {
          ...state,
          loading: false,
          error: false,
          errorInfo: undefined,
          errorFields,
        };
      }
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo,
      };
    }
    case VirtualPosActionType.PAYMENT_RESULT: {
      return {
        ...state,
        ...(action.show
          ? {
              showResult: true,
            }
          : {
              showResult: false,
              createSuccess: undefined,
              confirmSuccess: undefined,
              currencies: {},
              documentTypes: {},
            }),
      };
    }
    default:
      return state;
  }
};

export default VirtualPos;
