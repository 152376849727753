import { ChartOptions } from 'chart.js';

export const getOptions = (currency: string, emptyChart: boolean): ChartOptions<'line'> => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      borderColor: '#ececec',
      borderWidth: 1,
      backgroundColor: '#fff',
      titleColor: '#454f5b',
      titleFont: {
        weight: '700',
        size: 12,
      },
      bodyColor: '#454f5b',
      padding: 15,
      usePointStyle: true,
      callbacks: {
        label: context => {
          return `${currency} ${context.parsed.y}`;
        },
      },
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: currency,
      },
      min: 0,
      suggestedMax: emptyChart ? 5000 : undefined,
      ticks: {
        stepSize: emptyChart ? 1000 : undefined,
      },
    },
  },
});
