import { FunctionComponent } from 'react';
import './styles.scss';

interface JumioDialogProps {
  url: string;
}

export const JumioDialog: FunctionComponent<JumioDialogProps> = ({ url }) => {
  return (
    <div className="jumio">
      <iframe
        src={url}
        allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
        allowFullScreen
      ></iframe>
    </div>
  );
};
