import './styles.scss';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getOptions, plugin, chartColors, chartEmptyColor } from './config';
import l from '../../../../../../lang';
import TooltipIcon from '../../../../../common/tooltip-icon';
import { ReactComponent as HelpIcon } from '../../../../../../assets/icons/question-circle.svg';
import { useReports } from '../../../../../../store/reports';
import { useEffect } from 'react';
import ErrorRetry from '../../../error-retry';
import Loading from '../../../../../common/loading';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, plugin);

interface TransactionsByPaymentMethodProps {
  period: number;
}

const TransactionsByPaymentMethod = ({ period }: TransactionsByPaymentMethodProps) => {
  const { transactionsByPaymentMethod, getTransactionsByPaymentMethod } = useReports();
  const { loading, error, data } = transactionsByPaymentMethod;

  useEffect(() => {
    getTransactionsByPaymentMethod(period);
  }, [period]);

  const emptyChart = !data.length || (data.length === 1 && !data[0].type);
  const noTransactionLabel = [l('reports.transactionsBy.empty')];

  const chartData: ChartData<'doughnut'> = {
    labels: !emptyChart ? data.map(item => l(`paymentMethod.type.${item.type}`)) : noTransactionLabel,
    datasets: [
      {
        data: !emptyChart ? data.map(item => item.percentage) : [],
        backgroundColor: !emptyChart ? chartColors : chartEmptyColor,
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className="transactions_by_payment_method">
      <div className="transactions_by_payment_method__title">
        <span>{l('reports.transactionsByPaymentMethod.title')}</span>
        <TooltipIcon
          placement="right"
          text={l('reports.transactionsByPaymentMethod.tooltip')}
          icon={<HelpIcon width={21} height={21} color="primary" />}
        />
      </div>
      <div className="transactions_by_payment_method__chart">
        {error && <ErrorRetry onRetry={() => getTransactionsByPaymentMethod(period)} />}
        {loading && <Loading />}
        {!loading && !error && <Doughnut height={300} data={chartData} options={getOptions(data, emptyChart)} />}
      </div>
    </div>
  );
};

export default TransactionsByPaymentMethod;
