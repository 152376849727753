import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { start as startAction, refreshStatus as refreshStatusAction, setLoading } from './actions';
import {
  changeShowUpLevel,
  setDisplayMessageKycStep1 as setDisplayMessageKycStep1Action,
} from '../company-information/actions';
import { JumioSelectors } from './selectors';
import useEventListener from '../../hooks/use-event-listener';
import { JumioStatusType } from './interfaces';

export const useJumioIntegration = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const url = useSelector(JumioSelectors.getUrl);

  const start = useCallback(() => dispatch(startAction()), [dispatch]);

  const refreshStatus = useCallback(() => dispatch(refreshStatusAction()), [dispatch]);

  const setDisplayMessageKycStep1 = useCallback(
    (displayed: boolean) => dispatch(setDisplayMessageKycStep1Action(displayed)),
    [dispatch]
  );

  const loading = useSelector(JumioSelectors.getLoading);
  const status = useSelector(JumioSelectors.getStatus);
  const limitReached = useSelector(JumioSelectors.getLimitReached);
  const tryCount = useSelector(JumioSelectors.getTryCount);

  const TIME_WAITING_JUMIO = 5000;
  const onFinish = () => {
    setDisplayMessageKycStep1(true);
    dispatch(setLoading(true));
  };

  useEffect(() => {
    if (JumioStatusType.REJECTED === status) {
      dispatch(setLoading(false));
      dispatch(changeShowUpLevel(false));
    }
  }, [status]);

  useEffect(() => {
    if (
      loading &&
      ![JumioStatusType.PASSED, JumioStatusType.WARNING, JumioStatusType.REJECTED].includes(
        status ?? JumioStatusType.UNKNOWN
      )
    ) {
      const id = setInterval(() => dispatch(refreshStatusAction()), TIME_WAITING_JUMIO);
      return () => {
        clearInterval(id);
      };
    }
  }, [status, loading]);

  function receiveMessage(event: any) {
    if (!url) {
      return;
    }

    const src = new URL(url);
    const origin = `${src.protocol}//${src.host}`;
    if (event.origin === origin) {
      const data = JSON.parse(event?.data);
      if (['success', 'error'].includes(data?.payload?.value)) {
        onFinish();
      }
    }
  }

  useEventListener('message', receiveMessage);

  return {
    loading,
    status,
    limitReached,
    tryCount,
    url,
    start,
    refreshStatus,
    setDisplayMessageKycStep1,
  };
};
