import { FunctionComponent, ReactElement } from 'react';
import Parser from 'html-react-parser';
import l from '../../../../../../lang';
import CardStep from '..';
import { StatusKYCStepOne } from '../../enums/StatusKYCStepOne';
import { CardStyle } from '../../enums/CardStyle';
import ButtonCustom from '../../../../../common/__deprecated__/button-custom';
import ContentStep from '../../content-steps';
import { segmentKycStep1CompleteB } from '../../../../../../utils/analytics';
import ButtonLink from '../../../../../common/button-link';
import { useDashboard } from '../../../../../../store/dashboard';
import { HasFeatureFunction, useFeature } from '../../../../../../hooks/use-features';
import { MerchantFeatures } from '../../../../../../constants';

interface CardProps {
  cardStyle: CardStyle;
  tag: string;
  headline: string;
  subheadline: string | JSX.Element | JSX.Element[];
  content: string | JSX.Element | JSX.Element[];
  bottomElement: ReactElement;
  disabled: boolean;
}

const getCardPropsFromStatusKYCStepOne = (
  statusKycStepOne: StatusKYCStepOne,
  isBlocked: boolean,
  limitStepOne: number,
  onClickOneHandler: () => void,
  company: boolean,
  hasFeature: HasFeatureFunction
): CardProps => {
  let cardStyle = CardStyle.MAIN;
  let tag = '';
  let headline = '';
  let subheadline: string | JSX.Element | JSX.Element[];
  let content: string | JSX.Element | JSX.Element[];
  let bottomElement = <></>;
  let disabled = true;

  const onContactHandler = () => {
    window.Intercom('show');
  };

  switch (statusKycStepOne) {
    case StatusKYCStepOne.REJECTED_BY_WEBSITE:
      cardStyle = CardStyle.WARNING;
      tag = l('companyInformation.steps.tag.reviewInformation.website');
      headline = l('companyInformation.step1.headline.reviewInformation.website');
      subheadline = l('companyInformation.step1.subheadline.reviewInformation.website');
      content = Parser(l('companyInformation.step1.content.completed', limitStepOne));
      bottomElement = <div className="info_steps__pending-line" />;
      break;
    case StatusKYCStepOne.REJECTED_BY_INDUSTRY:
      cardStyle = CardStyle.ERROR;
      tag = l('companyInformation.steps.tag.reviewInformation.industry');
      headline = l('companyInformation.step1.headline.reviewInformation.industry');
      subheadline = l('companyInformation.step1.subheadline.reviewInformation.industry');
      content = '';
      bottomElement = <div className="info_steps__error-line" />;
      break;
    case StatusKYCStepOne.MISSING_VALIDATION:
      cardStyle = CardStyle.WARNING;
      tag = l('companyInformation.steps.tag.missingValidation');
      headline = l('companyInformation.step1.headline.missingValidation');
      subheadline = l('companyInformation.step1.subheadline.missingValidation');
      content = l('companyInformation.step1.content.missingValidation');
      bottomElement = !isBlocked ? (
        <ButtonCustom
          size="small-full"
          text={l('companyInformation.step1.button.missingValidation')}
          onClick={onClickOneHandler}
          component={undefined}
          innerComponent={undefined}
          fullWidth={undefined}
        />
      ) : (
        <div className="info_steps__disabled-line" />
      );
      disabled = isBlocked;
      break;
    case StatusKYCStepOne.RETRY_VALIDATION:
      cardStyle = CardStyle.WARNING;
      tag = l('companyInformation.steps.tag.retryValidation');
      headline = l('companyInformation.step1.headline.retryValidation');
      subheadline = l('companyInformation.step1.subheadline.retryValidation');
      content = l('companyInformation.step1.content.retryValidation');
      bottomElement = !isBlocked ? (
        <ButtonCustom
          size="small-full"
          text={l('companyInformation.step1.button.retryValidation')}
          onClick={onClickOneHandler}
          component={undefined}
          innerComponent={undefined}
          fullWidth={undefined}
        />
      ) : (
        <div className="info_steps__disabled-line" />
      );
      disabled = isBlocked;
      break;
    case StatusKYCStepOne.ERROR_VALIDATION:
      cardStyle = CardStyle.ERROR;
      tag = l('companyInformation.steps.tag.errorValidation');
      headline = l('companyInformation.step1.headline.errorValidation');
      subheadline = l('companyInformation.step1.subheadline.errorValidation');
      content = <ButtonLink onClick={onContactHandler} text={l('companyInformation.step1.link.errorValidation')} />;
      break;
    case StatusKYCStepOne.COMPLETED:
      cardStyle = CardStyle.SUCCESS;
      tag = l('companyInformation.steps.tag.completed');

      headline = hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
        ? l(`companyInformation.step1.headline.completed.${company ? 'company' : 'physical'}`)
        : l('companyInformation.step1.headline.completed');

      subheadline = hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
        ? Parser(l('companyInformation.step1.subheadline.completed.company/physical'))
        : Parser(l('companyInformation.step1.subheadline.completed'));

      content = hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
        ? Parser(l('companyInformation.step1.content.completed.company/physical', limitStepOne))
        : Parser(l('companyInformation.step1.content.completed', limitStepOne));

      bottomElement = <div className="info_steps__completed-line" />;
      break;
    case StatusKYCStepOne.ENABLED:
      cardStyle = CardStyle.MAIN;
      tag = l('companyInformation.steps.tag.complete');

      headline = hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
        ? l('companyInformation.step1.headline.complete.company/physical')
        : l('companyInformation.step1.headline.complete');

      subheadline = hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
        ? l(`companyInformation.step1.subheadline.complete.${company ? 'company' : 'physical'}`)
        : l('companyInformation.step1.subheadline.complete');

      content = hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
        ? l(`companyInformation.step1.content.complete.${company ? 'company' : 'physical'}`)
        : l('companyInformation.step1.content.complete');

      bottomElement = !isBlocked ? (
        <ButtonCustom
          size="small-full"
          text={l('companyInformation.step1.button.complete')}
          onClick={onClickOneHandler}
          component={undefined}
          innerComponent={undefined}
          fullWidth={undefined}
        />
      ) : (
        <div className="info_steps__disabled-line" />
      );
      disabled = isBlocked;
      break;
  }

  return {
    tag,
    headline,
    cardStyle,
    subheadline,
    content,
    bottomElement,
    disabled,
  };
};

interface CardStepProps {
  statusKycStepOne: StatusKYCStepOne;
  onClick: (step: number) => void;
  limitStepOne: number;
}

export const CardStepOne: FunctionComponent<CardStepProps> = ({ statusKycStepOne, onClick, limitStepOne }) => {
  const onClickOne = (segmentOption: number) => {
    segmentKycStep1CompleteB(segmentOption);
    onClick(1);
  };

  const { userMe, merchantMe } = useDashboard();
  const { hasFeature } = useFeature();

  const { blacklisted, enabled } = userMe || {};
  const { company } = merchantMe || {};

  const stepOneCardProps = getCardPropsFromStatusKYCStepOne(
    statusKycStepOne,
    blacklisted || !enabled,
    limitStepOne,
    () => onClickOne(2),
    company || false,
    hasFeature
  );

  return (
    <CardStep
      cardStyle={stepOneCardProps.cardStyle}
      title={l('companyInformation.step1')}
      onClick={() => onClickOne(1)}
      tag={stepOneCardProps.tag}
      disabled={stepOneCardProps.disabled}
    >
      {statusKycStepOne != StatusKYCStepOne.ENABLED && (
        <ContentStep
          headline={stepOneCardProps.headline}
          subheadline={stepOneCardProps.subheadline}
          content={stepOneCardProps.content}
          bottomElement={stepOneCardProps.bottomElement}
        />
      )}
      <div></div>
    </CardStep>
  );
};
