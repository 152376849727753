import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .custom-input': {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    backgroundColor: '#ffffff9e',
    fontSize: '14px',
  },
  inputDisabled: {
    backgroundColor: '#f1f1f19e',
  },
  inputLabelDisabled: {
    backgroundColor: 'transparent',
  },
}));

const Input = ({
  type = 'text',
  label,
  name,
  value,
  defaultValue,
  multiline,
  rows,
  placeholder,
  maxLength,
  required,
  disabled,
  shrink,
  readOnly,
  onChange,
  onBlur,
  error,
  prefix,
  minDate,
  maxDate,
  message,
  size,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  return (
    <div className={classes.root} key={`input-container-${name}`}>
      <TextField
        id={name}
        key={`input-${name}`}
        fullWidth
        size={size}
        label={label}
        variant="outlined"
        type={type === 'password' && showPassword ? 'text' : type}
        name={name}
        className="custom-input"
        value={value}
        defaultValue={defaultValue}
        multiline={multiline}
        rows={rows}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        error={!!error}
        helperText={error && typeof error === 'string' ? error : message}
        inputProps={{
          maxLength,
          step: 'any',
          min: minDate,
          max: maxDate,
        }}
        InputLabelProps={{
          shrink,
          classes: {
            root: classes.input,
            disabled: classes.inputLabelDisabled,
          },
        }}
        InputProps={{
          readOnly,
          classes: {
            root: classes.input,
            disabled: classes.inputDisabled,
          },
          endAdornment: type === 'password' && (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,
        }}
      />
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  shrink: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  message: PropTypes.string,
  size: PropTypes.string,
};

export default Input;
