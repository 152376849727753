import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Loading = ({ fullScreen = false, noBackground = false }) => {
  return (
    <div className={`lds-container ${fullScreen ? 'full-screen' : ''} ${noBackground ? 'no-background' : ''}`}>
      <div className={`lds-ring ${fullScreen ? 'full-screen' : ''}`}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

Loading.propTypes = {
  fullScreen: PropTypes.bool,
  noBackground: PropTypes.bool,
};

export default Loading;
