export enum EmailValidationActionType {
  SEND_EMAIL_VALIDATION = 'EMAIL_VALIDATION/SEND',
  SEND_EMAIL_VALIDATION_SUCCESS = 'EMAIL_VALIDATION/SEND_SUCCESS',
  SEND_EMAIL_VALIDATION_ERROR = 'EMAIL_VALIDATION/SEND_ERROR',
  CONFIRM_EMAIL = 'EMAIL_VALIDATION/CONFIRM_EMAIL',
  CONFIRM_EMAIL_SUCCESS = 'EMAIL_VALIDATION/CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_ERROR = 'EMAIL_VALIDATION/CONFIRM_EMAIL_ERROR',
}

export const sendEmailValidation = (resend: boolean) => ({
  type: EmailValidationActionType.SEND_EMAIL_VALIDATION,
  resend,
});

export const sendEmailValidationSuccess = (response: any) => ({
  type: EmailValidationActionType.SEND_EMAIL_VALIDATION_SUCCESS,
  response,
});

export const sendEmailValidationError = (error: any) => ({
  type: EmailValidationActionType.SEND_EMAIL_VALIDATION_ERROR,
  error,
});

export const sendConfirmEmail = (token: string) => ({
  type: EmailValidationActionType.CONFIRM_EMAIL,
  token,
});

export const sendConfirmEmailSuccess = (response: any) => ({
  type: EmailValidationActionType.CONFIRM_EMAIL_SUCCESS,
  response,
});

export const sendConfirmEmailError = (error: any) => ({
  type: EmailValidationActionType.CONFIRM_EMAIL_ERROR,
  error,
});
