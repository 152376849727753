import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Moment from 'react-moment';
import l from '../../../../lang';
import ChipStatus from '../../../common/chip-status';
import { createDate } from '../../../../utils';

const SettlementDetail = ({ detail }) => {
  return (
    <div className="settlement-detail">
      <div className="settlement-detail__label-status">
        <ChipStatus status={detail.status} text={l(`settlements.status.${detail.status}`)} />
      </div>

      <div>
        <div className="settlement-detail__item">
          <div className="settlement-detail__subtitle">{l('settlements.date')}</div>
          <Moment format="DD/MM/YYYY">{createDate(detail.date)}</Moment>
        </div>
        <div className="settlement-detail__item">
          <div className="settlement-detail__subtitle">{l('settlements.time')}</div>
          <Moment format="hh:mm:ss a">{createDate(detail.date)}</Moment>
        </div>
      </div>
      <div>
        <div className="settlement-detail__item">
          <div className="settlement-detail__subtitle">{l('settlements.amountRequested')}</div>
          <div>
            {detail.currency} {detail.requestedAmount}
          </div>
        </div>
        <div className="settlement-detail__item">
          <div className="settlement-detail__subtitle">{l('settlements.fee')}</div>
          <div>
            {detail.currency} {detail.settlementFee}
          </div>
        </div>
      </div>
      <div>
        <div className="settlement-detail__item">
          <div className="settlement-detail__subtitle">{l('settlements.bank')}</div>
          <div>{detail.bankName}</div>
        </div>
        <div className="settlement-detail__item">
          <div className="settlement-detail__subtitle">{l('settlements.paymentDate')}</div>
          <div>
            {detail.paymentDate ? <Moment format="DD/MM/YYYY hh:mm:ss a">{createDate(detail.paymentDate)}</Moment> : ' - '}
          </div>
        </div>
      </div>
    </div>
  );
};

SettlementDetail.propTypes = {
  detail: PropTypes.object,
};

export default SettlementDetail;
