import './styles.scss';
import TabsMUI from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { ReactNode, useEffect, useState } from 'react';
import { styled } from '@mui/material';

interface TabPanelProps {
  id: string;
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ id, children, value, index, ...other }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${id}-tabpanel-${index}`}
      aria-labelledby={`${id}-tab-${index}`}
      {...other}
    >
      {value === index && <div className="tabs__content">{children}</div>}
    </div>
  );
};

const DefaultTab = styled((props: TabProps) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '150%',
}));

const a11yProps = (id: string, index: number) => {
  return {
    id: `${id}-tab-${index}`,
    'aria-controls': `${id}-tabpanel-${index}`,
  };
};

interface TabsProps {
  id: string;
  idxItemSelected?: number;
  items: {
    title: string | ReactNode;
    content: string | ReactNode;
  }[];
  onChange?: (tabIdx: number) => void;
}

const Tabs = ({ id, items, idxItemSelected, onChange }: TabsProps) => {
  const [value, setValue] = useState(idxItemSelected || 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onChange && onChange(newValue);
  };

  const titles: string | ReactNode[] = [];
  const contents: string | ReactNode[] = [];

  items.forEach(item => {
    titles.push(item.title);
    contents.push(item.content);
  });

  useEffect(() => {
    idxItemSelected && setValue(idxItemSelected);
  }, [idxItemSelected]);

  return (
    <div className="tabs">
      <div className="tabs__header">
        <TabsMUI value={value} onChange={handleChange} aria-label={`${id}-tabs`}>
          {titles.map((t, i) => (
            <DefaultTab key={i} label={t} {...a11yProps(id, i)} />
          ))}
        </TabsMUI>
      </div>
      {contents.map((c, i) => (
        <TabPanel key={i} id={id} value={value} index={i}>
          {c}
        </TabPanel>
      ))}
    </div>
  );
};

export default Tabs;
