import './styles.scss';
import { FC } from 'react';
import l from '../../../../../../lang';
import { ReactComponent as AllCountriesIcon } from '../../../../../../assets/icons/all-countries.svg';
import { SubscriptionValues } from '..';
import { Urls } from '../../../../../../config/urls';
import { SUBSCRIPTION_ALL_COUNTRIES } from '../../../../../../constants';

const ReviewPlan: FC<SubscriptionValues> = values => {
  const getFlag = (code: string) => (
    <img className="input_country_phone__flag" src={Urls.flags.replace(':code', code)} alt="" />
  );
  return (
    <div className="review_plan__container">
      <div className="review_plan__item">
        <div className="review_plan__item__title">{l('subscriptions.plan.summaryCard.planName')}</div>
        <div className="review_plan__item__value">{values.planName}</div>
      </div>
      <div className="review_plan__item">
        <div className="review_plan__item__title">{l('subscriptions.plan.summaryCard.planDescription')}</div>
        <div className="review_plan__item__value">{values.description}</div>
      </div>
      <div className="review_plan__item">
        <div className="review_plan__item__title">{l('subscriptions.plan.summaryCard.subscribersCountry')}</div>
        <div className="review_plan__item__value flag">
          {values.country === SUBSCRIPTION_ALL_COUNTRIES && (
            <>
              <span>
                <AllCountriesIcon height={18} />
              </span>
              <span>{l('subscriptions.allCountries')}</span>
            </>
          )}
          {values.country !== SUBSCRIPTION_ALL_COUNTRIES && (
            <>
              {getFlag(values.country)}
              <span>{values.country}</span>
            </>
          )}
        </div>
      </div>
      <div className="review_plan__item">
        <div className="review_plan__item__title">{l('subscriptions.plan.summaryCard.paymentAmount')}</div>
        <div className="review_plan__item__value">
          {values.currency} {values.amount}
        </div>
      </div>
      <div className="review_plan__item">
        <div className="review_plan__item__title">{l('subscriptions.plan.summaryCard.paymentFrequency')}</div>
        <div className="review_plan__item__value">
          {values.frequencyValue}{' '}
          {l(`subscriptions.frequency.${values.frequencyType}${(values.frequencyValue || 0) > 1 ? '.plural' : ''}`)}
        </div>
      </div>
    </div>
  );
};

export default ReviewPlan;
