import { PanelStore } from '../interfaces';

export namespace ReportsSelectors {
  export const getSummary = (state: PanelStore.RootState) => {
    return state.reports.summary;
  };
  export const getPaymentVolume = (state: PanelStore.RootState) => {
    return state.reports.paymentVolume;
  };
  export const getTransactionsByCountry = (state: PanelStore.RootState) => {
    return state.reports.transactionsByCountry;
  };
  export const getTransactionsByPaymentSolution = (state: PanelStore.RootState) => {
    return state.reports.transactionsByPaymentSolution;
  };
  export const getTransactionsByPaymentMethod = (state: PanelStore.RootState) => {
    return state.reports.transactionsByPaymentMethod;
  };
}
