import { useEffect, useState } from 'react';
import { Refresh } from '@mui/icons-material';
import Loading from '../../../../common/loading';
import Table from '../../../../common/table';
import { Urls } from '../../../../../config/urls';
import l from '../../../../../lang';
import './styles.scss';
import MessageError from '../../../../common/message-error';
import { usePaymentLink } from '../../../../../store/payment-link';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EmptyIcon } from '../../../../../assets/icons/empty-payment-links.svg';
import ButtonLink from '../../../../common/button-link';
import { Routes } from '../../../../../config/routes';
import { ReactComponent as AllCountriesIcon } from '../../../../../assets/icons/all-countries.svg';
import { ReactComponent as ShareIcon } from '../../../../../assets/icons/share.svg';
import { ReactComponent as ShareDisableIcon } from '../../../../../assets/icons/share-disable.svg';
import { Button } from '../../../../common/button';
import { RecurringPaymentLink } from '../../../../../store/payment-link/interfaces';
import PaymentLinkDialog from '../../payment-link-dialog';
import Switch from '../../../../common/switch';

const CreatedRecurringPaymentLink = () => {
  const history = useHistory();
  const [share, setShare] = useState<RecurringPaymentLink | null>(null);

  const { createdRecurringPaymentLinks, getCreatedRecurringPaymentLinks, changeStatusRecurringPaymentLink } =
    usePaymentLink();
  const { loading, error, allPaymentLinks } = createdRecurringPaymentLinks;

  useEffect(() => {
    getCreatedRecurringPaymentLinks();
  }, []);

  const dataExist = allPaymentLinks && allPaymentLinks?.data?.length > 0;

  return (
    <div className="created_recurring_payment_link">
      {loading && (
        <div className="created_recurring_payment_link__loading">
          <Loading />
        </div>
      )}
      {error && (
        <div className="created_recurring_payment_link__error">
          <MessageError iconClose={<Refresh />} onClose={() => getCreatedRecurringPaymentLinks()} />
        </div>
      )}
      {!loading && !error && (
        <div>
          <Table
            dense
            headers={[
              <span className="created_recurring_payment_link__left">{l('createdPaymentLink.creationDate')}</span>,
              <span className="created_recurring_payment_link__left">{l('createdPaymentLink.link')}</span>,
              <span className="created_recurring_payment_link__left">{l('createdPaymentLink.country')}</span>,
              <span className="created_recurring_payment_link__left">{l('createdPaymentLink.description')}</span>,
              <span className="created_recurring_payment_link__right">{l('createdPaymentLink.amount')}</span>,
              <span className="created_recurring_payment_link__left">{l('createdPaymentLink.status')}</span>,
              <span className="created_recurring_payment_link__left">{l('createdPaymentLink.action')}</span>,
            ]}
            emptyBody={
              <div className="created_recurring_payment_link__no_data">
                <EmptyIcon />
                <div className="created_recurring_payment_link__no_data__title">
                  {l('createdPaymentLink.noRecurringPaymentLinks')}
                </div>
                <div>
                  <ButtonLink
                    text={l('createdPaymentLink.noPaymentLinkDesc.1')}
                    onClick={() => history.push(Routes.CREATE_RECURRING_PAYMENT_LINK)}
                  />
                  {l('createdPaymentLink.noPaymentLinkDesc.2')}
                </div>
              </div>
            }
            body={
              (dataExist &&
                allPaymentLinks?.data?.map(pl => [
                  <span className="created_recurring_payment_link__left">
                    {pl.createdAt ? <Moment format="DD/MM/YYYY">{pl.createdAt}</Moment> : '-'}
                  </span>,
                  <div className="created_recurring_payment_link__left created_recurring_payment_link__link">
                    <div className="created_recurring_payment_link__link__wrapper">
                      <div className="created_recurring_payment_link__link__text">
                        <span>{pl.recurringPaymentLinkUrl || '-'}</span>
                      </div>
                    </div>
                  </div>,
                  <span className="created_recurring_payment_link__left">
                    {pl.country ? (
                      <span className="created_recurring_payment_link__country">
                        <img
                          alt=""
                          className="created_recurring_payment_link__country_flag"
                          src={`${Urls.flags.replace(':code', pl.country)}`}
                        />
                        <span>&nbsp;{pl.country}</span>
                      </span>
                    ) : (
                      <span className="created_recurring_payment_link__country">
                        <AllCountriesIcon />
                        <span>&nbsp;{l('common.all.m')}</span>
                      </span>
                    )}
                  </span>,
                  <span className="created_recurring_payment_link__left">{pl.description || '-'}</span>,
                  <span className="created_recurring_payment_link__right">
                    {pl.currency && pl.amount ? pl.currency + ' ' + pl.amount : '-'}
                  </span>,
                  <span className="created_recurring_payment_link__left created_recurring_payment_link__status">
                    <Switch
                      checked={pl.enabled}
                      disabled={false}
                      onChange={() => changeStatusRecurringPaymentLink(pl.recurringLinkToken, !pl.enabled)}
                    />
                    <span className="created_recurring_payment_link__status__text">
                      {pl.enabled ? l('createdPaymentLink.status.active') : l('createdPaymentLink.status.inactive')}
                    </span>
                  </span>,
                  <span className="created_recurring_payment_link__left">
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={pl.enabled ? <ShareIcon /> : <ShareDisableIcon />}
                      onClick={() => setShare(pl)}
                      disabled={!pl.enabled}
                    >
                      {l('createdPaymentLink.share')}
                    </Button>
                  </span>,
                ])) ||
              null
            }
            rowsPerPageOptions={[10, 25, 50]}
            rowsLength={allPaymentLinks?.totalElements}
            rowsPerPage={allPaymentLinks?.size}
            page={allPaymentLinks?.page}
            handleChangePage={(event, newPage) => getCreatedRecurringPaymentLinks(newPage, allPaymentLinks?.size)}
            handleChangeRowsPerPage={event => getCreatedRecurringPaymentLinks(0, event.target.value)}
          />
          {share && (
            <PaymentLinkDialog
              isRecurring
              url={share.recurringPaymentLinkUrl}
              country={share.country}
              currency={share.currency}
              amount={share.amount}
              description={share.description}
              onClose={() => setShare(null)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CreatedRecurringPaymentLink;
