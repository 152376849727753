import { FunctionComponent } from 'react';
import { Card } from '@mui/material';
import Parser from 'html-react-parser';
import l from '../../../../../lang';
import { Button } from '../../../../common/button';
import Dialog from '../../../../common/dialog';
import { ReactComponent as DocumentFailedImage } from '../../../../../assets/images/document-failed.svg';
import '../styles.scss';

interface DialogWarningKycStep1Props {
  onClose: () => void;
}

const CardWarningKycStep1: FunctionComponent<DialogWarningKycStep1Props> = ({ onClose }) => (
  <Card classes={{ root: 'dialog_kyc__card dialog_kyc__card__warning' }} elevation={0}>
    <DocumentFailedImage />
    <div>{Parser(l('kyc_step1.message.warning.headline'))}</div>
    <div>{Parser(l('kyc_step1.message.warning.subheadline'))}</div>
    <div className="dialog_kyc__card__button">
      <Button onClick={onClose}>{l('kyc_step1.message.warning.button')}</Button>
    </div>
  </Card>
);

const DialogWarningKycStep1: FunctionComponent<DialogWarningKycStep1Props> = ({ onClose }) => {
  return (
    <Dialog
      dividers={false}
      unpaddingFooter
      unpaddingContent
      type="xs"
      titleCenter
      handleClose={onClose}
      body={<CardWarningKycStep1 onClose={onClose} />}
    />
  );
};

export default DialogWarningKycStep1;
