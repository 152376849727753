import { Values } from '../interfaces';

export enum CompanyInformationActionType {
  SEND_KYC = 'COMPANY_INFO/SEND_KYC',
  SEND_KYC_SUCCESS = 'COMPANY_INFO/SEND_KYC_SUCCESS',
  SEND_KYC_ERROR = 'COMPANY_INFO/SEND_KYC_ERROR',
  GET_DOCUMENT_TYPES_FOR_KYC = 'COMPANY_INFO/GET_DOCUMENT_TYPES_FOR_KYC',
  GET_DOCUMENT_TYPES_FOR_KYC_SUCCESS = 'COMPANY_INFO/GET_DOCUMENT_TYPES_FOR_KYC_SUCCESS',
  GET_DOCUMENT_TYPES_FOR_KYC_ERROR = 'COMPANY_INFO/GET_DOCUMENT_TYPES_FOR_KYC_ERROR',
  GET_INDUSTRIES = 'COMPANY_INFO/GET_INDUSTRIES',
  GET_INDUSTRIES_SUCCESS = 'COMPANY_INFO/GET_INDUSTRIES_SUCCESS',
  GET_INDUSTRIES_ERROR = 'COMPANY_INFO/GET_INDUSTRIES_ERROR',
  GET_MISSING_DOCUMENTS = 'COMPANY_INFO/GET_MISSING_DOCUMENTS',
  GET_MISSING_DOCUMENTS_SUCCESS = 'GET_MISSING_DOCUMENTS_SUCCESS',
  GET_MISSING_DOCUMENTS_ERROR = 'GET_MISSING_DOCUMENTS_ERROR',
  SHOW_UPLOAD_LOGO = 'COMPANY_INFO/SHOW_UPLOAD_LOGO',
  CLEAN_LOGO_SUCCESS = 'COMPANY_INFO/CLEAN_LOGO_SUCCESS',
  DELETE_LOGO = 'COMPANY_INFO/DELETE_LOGO',
  DELETE_LOGO_SUCCESS = 'COMPANY_INFO/DELETE_LOGO_SUCCESS',
  DELETE_LOGO_ERROR = 'COMPANY_INFO/DELETE_LOGO_ERROR',
  UPLOAD_LOGO = 'COMPANY_INFO/UPLOAD_LOGO',
  UPLOAD_LOGO_SUCCESS = 'COMPANY_INFO/UPLOAD_LOGO_SUCCESS',
  UPLOAD_LOGO_ERROR = 'COMPANY_INFO/UPLOAD_LOGO_ERROR',
  SHOW_UP_LEVEL = 'COMPANY_INFO/SHOW_UP_LEVEL',
  GET_KYC_LEVELS = 'COMPANY_INFO/GET_KYC_LEVELS',
  GET_KYC_LEVELS_SUCCESS = 'COMPANY_INFO/GET_KYC_LEVELS_SUCCESS',
  GET_KYC_LEVELS_ERROR = 'COMPANY_INFO/GET_KYC_LEVELS_ERROR',
  DISPLAY_MESSAGE_KYC_STEP_1 = 'DISPLAY_MESSAGE_KYC_STEP_1',
}

export const sendKYC = (values: Values, kycLevel: number) => ({
  type: CompanyInformationActionType.SEND_KYC,
  values,
  kycLevel,
});

export const sendKYCSuccess = () => ({
  type: CompanyInformationActionType.SEND_KYC_SUCCESS
});

export const sendKYCError = (error: any) => ({
  type: CompanyInformationActionType.SEND_KYC_ERROR,
  error,
});

export const getDocumentTypesForKYC = (country: string) => ({
  type: CompanyInformationActionType.GET_DOCUMENT_TYPES_FOR_KYC,
  country,
});

export const getDocumentTypesForKYCSuccess = (response: any) => ({
  type: CompanyInformationActionType.GET_DOCUMENT_TYPES_FOR_KYC_SUCCESS,
  response,
});

export const getDocumentTypesForKYCError = (error: any) => ({
  type: CompanyInformationActionType.GET_DOCUMENT_TYPES_FOR_KYC_ERROR,
  error,
});

export const getIndustries = () => ({
  type: CompanyInformationActionType.GET_INDUSTRIES
});

export const getIndustriesSuccess = (response: any) => ({
  type: CompanyInformationActionType.GET_INDUSTRIES_SUCCESS,
  response,
});

export const getIndustriesError = (error: any) => ({
  type: CompanyInformationActionType.GET_INDUSTRIES_ERROR,
  error,
});

export const showUploadLogo = (show: boolean) => ({
  type: CompanyInformationActionType.SHOW_UPLOAD_LOGO,
  show,
});

export const changeShowUpLevel = (show: boolean) => ({
  type: CompanyInformationActionType.SHOW_UP_LEVEL,
  show,
});

export const deleteLogo = () => ({
  type: CompanyInformationActionType.DELETE_LOGO,
});

export const deleteLogoSuccess = (response: any) => ({
  type: CompanyInformationActionType.DELETE_LOGO_SUCCESS,
  response,
});

export const deleteLogoError = (error: any) => ({
  type: CompanyInformationActionType.DELETE_LOGO_ERROR,
  error,
});

export const uploadNewLogo = (logo: any) => ({
  type: CompanyInformationActionType.UPLOAD_LOGO,
  logo,
});

export const uploadNewLogoSuccess = (response: any) => ({
  type: CompanyInformationActionType.UPLOAD_LOGO_SUCCESS,
  response,
});

export const uploadNewLogoError = (error: any) => ({
  type: CompanyInformationActionType.UPLOAD_LOGO_ERROR,
  error,
});

export const cleanSuccess = () => ({
  type: CompanyInformationActionType.CLEAN_LOGO_SUCCESS,
});

export const getKycLevels = () => ({
  type: CompanyInformationActionType.GET_KYC_LEVELS,
});

export const getKycLevelsSuccess = (response: any) => ({
  type: CompanyInformationActionType.GET_KYC_LEVELS_SUCCESS,
  response,
});

export const getKycLevelsError = (error: any) => ({
  type: CompanyInformationActionType.GET_KYC_LEVELS_ERROR,
  error,
});

export const getMissingDocuments = () => ({
  type: CompanyInformationActionType.GET_MISSING_DOCUMENTS,
});

export const getMissingDocumentsSuccess = (response: any) => ({
  type: CompanyInformationActionType.GET_MISSING_DOCUMENTS_SUCCESS,
  response,
});

export const getMissingDocumentsError = (error: any) => ({
  type: CompanyInformationActionType.GET_MISSING_DOCUMENTS_ERROR,
  error,
});

export const setDisplayMessageKycStep1 = (payload: boolean) => ({
  type: CompanyInformationActionType.DISPLAY_MESSAGE_KYC_STEP_1,
  payload,
});