import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  error: {
    marginLeft: '14px',
  },
}));

const InputCheckbox = ({ label, name, value, disabled, onChange, onBlur, error }) => {
  const classes = useStyles();
  return (
    <FormControl error={!!error}>
      <FormControlLabel
        control={
          <Checkbox
            id={name}
            key={`input-checkbox-${name}`}
            name={name}
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            error={!!error}
          />
        }
        label={label}
      />
      {error && <FormHelperText className={classes.error}>{error}</FormHelperText>}
    </FormControl>
  );
};

InputCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
};

export default InputCheckbox;
