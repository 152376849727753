import { FC } from 'react';
import { Card } from '@mui/material';
import l from '../../../../lang';
import { Button } from '../../../common/button';
import Dialog from '../../../common/dialog';
import { ReactComponent as CancelImage } from '../../../../assets/images/cancel-subscription.svg';

import './styles.scss';

interface DialogSubscriptionCancelProps {
  cancelType: 'SUBSCRIPTION' | 'PLAN';
  onClose: (confirm: boolean) => void;
}

const CardConfirmClose: FC<DialogSubscriptionCancelProps> = ({ cancelType, onClose }) => (
  <Card classes={{ root: 'subscriptions_cancel__card subscriptions_cancel__card__warning' }} elevation={0}>
    <CancelImage />

    {cancelType === 'SUBSCRIPTION' && (
      <>
        <div>{l('subscriptions.cancel.title')}</div>
        <div>{l('subscriptions.cancel.body1')}</div>
        <div>{l('subscriptions.cancel.body2')}</div>
      </>
    )}

    {cancelType === 'PLAN' && (
      <>
        <div>{l('subscriptions.plans.cancel.title')}</div>
        <div>{l('subscriptions.plans.cancel.body1')}</div>
        <div>{l('subscriptions.plans.cancel.body2')}</div>
      </>
    )}

    <div className="subscriptions_cancel__card__button">
      <div>
        <Button onClick={() => onClose(true)}>
          {cancelType === 'SUBSCRIPTION' && l('subscriptions.cancel.confirm')}
          {cancelType === 'PLAN' && l('subscriptions.plans.cancel.confirm')}
        </Button>
      </div>
      <Button variant="text" onClick={() => onClose(false)}>
        {l('subscriptions.cancel.no')}
      </Button>
    </div>
  </Card>
);

const DialogSubscriptionCancel: FC<DialogSubscriptionCancelProps> = ({ cancelType, onClose }) => {
  return (
    <Dialog
      dividers={false}
      unpaddingFooter
      unpaddingContent
      type="xs"
      titleCenter
      handleClose={() => onClose(false)}
      body={<CardConfirmClose cancelType={cancelType} onClose={onClose} />}
    />
  );
};

export default DialogSubscriptionCancel;
