import AddBankForm from '../add-bank-form';
import l from '../../../../../lang';
import { useBankAccounts } from '../../../../../store/bank-accounts';

const AddBankAccount = () => {
  const { addBankAccount } = useBankAccounts();

  const handleSubmit = (valuesToSend: any) => {
    delete valuesToSend.bankAccountConfirm;
    addBankAccount(valuesToSend);
  };

  return (
    <AddBankForm
      title={l('companyInformation.kycTwo.sectionTwo.title')}
      description={l('companyInformation.kycTwo.sectionTwo.subtitle')}
      bankAccountDocumentRequired
      submitLabel={l('bankAccounts.addBankAccount')}
      handleSubmit={handleSubmit}
    />
  );
};

export default AddBankAccount;
