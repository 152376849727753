import { Grid } from '@mui/material';
import Parser from 'html-react-parser';
import { useEffect, useState } from 'react';
import l from '../../../../../../lang';
import { FormikProps } from 'formik';
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/icons/arrow-right-completed.svg';
import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info-unfilled.svg';
import Input from '../../../../../common/input';
import Select from '../../../../../common/select';
import TooltipIcon from '../../../../../common/tooltip-icon';
import { allCountries, functionalCurrenciesByCountry } from '../../../../../../config';
import CurrencySelector from '../../../../../common/currency-selector';
import { useSubscriptions } from '../../../../../../store/subscriptions';
import { useDashboard } from '../../../../../../store/dashboard';
import { SubscriptionCustomFrequency, SubscriptionFrequency } from '../../../../../../constants';
import { SubscriptionValues } from '..';
import { getFrequencyTypeAndValue } from '../../../../../../utils';
import './styles.scss';

export const CreatePlanForm = ({
  isEdition,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}: FormikProps<SubscriptionValues> & { isEdition: boolean }) => {
  const crossBorderCurrency = 'USD';
  const { merchantMe } = useDashboard();
  const [isCrossBorder, setIsCrossBorder] = useState(false);
  const [crossBorderSameCurrency, setCrossBorderSameCurrency] = useState(false);

  const { loading, currencies, exchangeRates, cleanCurrencies, getCurrencies, cleanErrorSubscriptions } = useSubscriptions();

  const formDisabled = !merchantMe?.canOperate || merchantMe?.fraudReview || loading;

  useEffect(() => {
    if (isEdition) {
      if (values.country === '' || values.country === 'ALL') {
        cleanCurrencies();
      } else {
        getCurrencies(values.country);
      }
    }
  }, []);

  useEffect(() => {
    if (values.country === merchantMe?.country) {
      setIsCrossBorder(false);
      setCrossBorderSameCurrency(false);
      setDefaultCurrency(values.country, false);
    } else if (values.country) {
      setIsCrossBorder(true);
      setCrossBorderSameCurrency(!Object.keys(currencies).find(c => c !== crossBorderCurrency));
      setDefaultCurrency(values.country, true);
    }
  }, [currencies]);

  useEffect(() => {
    if (values.frequency && values.frequency !== SubscriptionFrequency.CUSTOM) {
      const { frequencyType, frequencyValue } = getFrequencyTypeAndValue(
        values.frequency,
        values.frequencyType,
        values.frequencyValue
      );
      setFieldValue('frequencyType', frequencyType);
      setFieldValue('frequencyValue', frequencyValue);
    }
  }, [values.frequency]);

  const setDefaultCurrency = (country: string, isCrossBorder: boolean) => {
    const countryCurrency = (functionalCurrenciesByCountry as { [key: string]: string })[country];
    if (
      currencies &&
      currencies[countryCurrency] &&
      values.currency !== countryCurrency &&
      currencies['USD'] &&
      values.currency !== 'USD'
    ) {
      if (!isCrossBorder) {
        handleOnChangeCurrency(countryCurrency);
      } else {
        if (currencies['USD']) {
          handleOnChangeCurrency('USD');
        } else {
          const cs = Object.keys(currencies);
          if (cs.length && !cs.filter(d => country === d).length) {
            handleOnChangeCurrency(cs[0]);
          }
        }
      }
    } else if (
      currencies &&
      ((currencies[countryCurrency] && values.currency === countryCurrency) ||
        (currencies['USD'] && values.currency === 'USD'))
    ) {
      handleOnChangeCurrency(values.currency, values.amount);
    }
  };

  const countries = (): Record<string, string> => {
    const countries: Record<string, string> = {
      ALL: l('paymentMethod.coverage.allCountries'),
    };

    const allowedCountries = merchantMe?.allowLocalToLocal
      ? allCountries
      : allCountries.filter(co => co !== merchantMe?.country);

    allowedCountries.forEach((co: string) => {
      countries[co] = l(`countryCode.${co}`);
    });
    return countries;
  };

  const handleOnChangeCurrency = (value: string, amount?: number) => {
    setFieldValue('currency', value);
    setFieldValue('amount', amount || '');
    if (isCrossBorder) {
      const secondCurrency = Object.keys(currencies).find(c => c !== value) || '';
      setFieldValue('crossBorderData', {
        ...values.crossBorderData,
        secondCurrency,
        secondAmount: amount && secondCurrency ? getSecondAmount(value, amount, secondCurrency) : '',
      });
    }
    cleanErrorSubscriptions();
  };

  const handleOnChangeCountry = (value: string) => {
    if (value === '' || value === 'ALL') {
      setFieldValue('currency', 'USD');
      cleanCurrencies();
    } else {
      getCurrencies(value);
    }
    setFieldValue('country', value);
  };

  const applyToFixed = (value: number) => {
    return value && value !== 0 ? value.toFixed(2) : value;
  };

  const getSecondAmount = (currency: string, amount: number, secondCurrency?: string) => {
    if (crossBorderCurrency === currency) {
      return applyToFixed(amount * exchangeRates[secondCurrency || values.crossBorderData.secondCurrency]);
    } else {
      return applyToFixed(amount / exchangeRates[currency]);
    }
  };

  const onChangeAmount = (amount: number) => {
    setFieldValue('crossBorderData', {
      ...values.crossBorderData,
      secondAmount: getSecondAmount(values.currency, amount),
    });
    setFieldValue('amount', amount);
  };

  const frequencies = [
    {
      value: SubscriptionFrequency.WEEKLY,
      displayName: l('subscriptions.customFrequency.WEEKLY'),
    },
    {
      value: SubscriptionFrequency.MONTHLY,
      displayName: l('subscriptions.customFrequency.MONTHLY'),
    },
    {
      value: SubscriptionFrequency.QUARTERLY,
      displayName: l('subscriptions.frequency.QUARTERLY'),
    },
    {
      value: SubscriptionFrequency.SEMI_ANNUALLY,
      displayName: l('subscriptions.frequency.SEMI_ANNUALLY'),
    },
    {
      value: SubscriptionFrequency.ANNUALLY,
      displayName: l('subscriptions.frequency.ANNUALLY'),
    },
    {
      value: SubscriptionFrequency.CUSTOM,
      displayName: l('subscriptions.frequency.CUSTOM'),
    },
  ];

  const frequencyTypes = [
    {
      value: SubscriptionCustomFrequency.DAY,
      displayName: l('expirationBox.DAYS'),
    },
    {
      value: SubscriptionCustomFrequency.MONTH,
      displayName: l('expirationBox.MONTHS'),
    },
    {
      value: SubscriptionCustomFrequency.YEAR,
      displayName: l('expirationBox.YEARS'),
    },
  ];

  return (
    <>
      <div className="create_plan__fields_section">
        <div className="create_plan__fields_section__title">
          <div className="title">
            <span>1. </span>
            {l('subscriptions.plan.card1.cardTitle')}
          </div>
        </div>
        <div className="create_plan_form__row">
          <div className="create_plan_form__field">
            <Input
              name="planName"
              label={l('subscriptions.plan.summaryCard.planName')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.planName}
              maxLength={100}
              error={touched.planName && errors.planName}
            />
          </div>
          <div className={`create_plan_form__info`}>
            <TooltipIcon
              placement="bottom-end"
              text={Parser(l('subscriptions.plan.card1.planNameTooltip'))}
              icon={<InfoIcon />}
            />
          </div>
        </div>
        <div className="create_plan_form__row">
          <div className="create_plan_form__field">
            <Input
              name="description"
              label={l('subscriptions.plan.card1.planDescription')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              maxLength={100}
              error={touched.description && errors.description}
            />
          </div>
          <div className={`create_plan_form__info`}>
            <TooltipIcon
              placement="bottom-end"
              text={Parser(l('subscriptions.plan.card1.planDescriptionTooltip'))}
              icon={<InfoIcon />}
            />
          </div>
        </div>
      </div>

      <div className="create_plan__fields_section">
        <div className="create_plan__fields_section__title">
          <div className="title">
            <span>2. </span>
            {l('subscriptions.plan.card2.cardTitle')}
          </div>
          <div className="subtitle">{l('subscriptions.plan.card2.paymentDetailsDescription')}</div>
        </div>
        <div className="create_plan_form__row">
          <div className="create_plan_form__field">
            <Select
              name="country"
              label={l('subscriptions.plan.card2.subscribersCountry')}
              value={values.country}
              onChange={e => handleOnChangeCountry(e.target.value)}
              options={countries()}
              disabled={formDisabled || isEdition}
              error={(touched.country && errors.country) || undefined}
            />
          </div>
          <div className={`create_plan_form__info`}>
            <TooltipIcon
              placement="bottom-end"
              text={Parser(l('subscriptions.plan.card2.subscribersCountryTooltip'))}
              icon={<InfoIcon />}
            />
          </div>
        </div>
        <Grid item xs={12}>
          {!isCrossBorder ? (
            <>
              <div className="create_plan_form__row">
                <div className="create_plan_form__field amount">
                  <Input
                    type="number"
                    name="amount"
                    label={l('subscriptions.plan.card2.chargeAmount')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.amount}
                    disabled={!values.currency}
                    error={(touched.amount && errors.amount) || undefined}
                    prefix={values.currency}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {!crossBorderSameCurrency && (
                <CurrencySelector
                  firstCurrency={crossBorderCurrency}
                  secondCurrency={Object.keys(currencies).find(c => c !== crossBorderCurrency) || ''}
                  value={values.currency}
                  onChange={value => handleOnChangeCurrency(value)}
                  disabled={formDisabled}
                />
              )}
              <div className={`create_plan_form__row calculator_row ${!crossBorderSameCurrency ? 'shrunk' : ''}`}>
                <div
                  className={`create_plan_form__field calculator_field ${
                    !crossBorderSameCurrency ? 'amount_cross_border amount_cross_border_left' : ''
                  }`}
                >
                  <Input
                    type="number"
                    name="amount"
                    label={l(
                      crossBorderCurrency === values.currency
                        ? 'paymentLink.amountWantToCharge'
                        : 'paymentLink.amountYourClientWillPay'
                    )}
                    onChange={e => onChangeAmount(e.target.value)}
                    onBlur={handleBlur}
                    value={values.amount}
                    disabled={formDisabled || !values.currency}
                    error={(touched.amount && errors.amount) || undefined}
                    prefix={values.currency}
                  />
                  {!crossBorderSameCurrency && (
                    <div>
                      <p className="create_plan_form__exchange_rate">
                        <span>{l('paymentLink.exchangeRate')}</span>
                        <span>
                          &nbsp;{values.currency} {applyToFixed(exchangeRates[values.currency])}
                        </span>
                        <ArrowRightIcon />
                        <span>
                          <span> {values.crossBorderData.secondCurrency} </span>
                          {applyToFixed(exchangeRates[values.crossBorderData.secondCurrency])}
                        </span>
                      </p>
                    </div>
                  )}
                  {crossBorderSameCurrency && (
                    <div className={`create_plan_form__info input`}>
                      <TooltipIcon
                        placement="right"
                        text={Parser(
                          l(
                            'paymentLink.tooltip.sameCurrency',
                            (functionalCurrenciesByCountry as { [key: string]: string })[merchantMe?.country || ''] || '***'
                          )
                        )}
                        icon={<InfoIcon />}
                      />
                    </div>
                  )}
                </div>
                {!crossBorderSameCurrency && (
                  <div className="create_plan_form__field calculator_field amount_cross_border amount_cross_border_right">
                    <Input
                      type="number"
                      name="second_amount"
                      label={l(
                        crossBorderCurrency === values.currency
                          ? 'paymentLink.amountYourClientWillPay'
                          : 'paymentLink.amountYouWillReceive'
                      )}
                      onChange={e => {
                        const value = e.target.value;
                        setFieldValue('crossBorderData', { ...values.crossBorderData, secondAmount: value });
                        if (crossBorderCurrency === values.crossBorderData.secondCurrency) {
                          setFieldValue('amount', applyToFixed(value * exchangeRates[values.currency]));
                        } else {
                          setFieldValue(
                            'amount',
                            applyToFixed(value / exchangeRates[values.crossBorderData.secondCurrency])
                          );
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.crossBorderData.secondAmount}
                      disabled={formDisabled}
                      prefix={Object.keys(currencies).find(c => c !== values.currency) || ''}
                    />
                    {crossBorderCurrency !== values.currency && (
                      <div>
                        <p className="create_plan_form__fx_message">
                          <span>{l('paymentLink.exchangeRate.fxMessage')}</span>
                        </p>
                      </div>
                    )}
                    <div className={`create_plan_form__info input`}>
                      <TooltipIcon
                        placement="bottom-end"
                        text={Parser(
                          l(
                            'currencySelector.tooltip',
                            Object.keys(currencies).find(c => c !== crossBorderCurrency) || crossBorderCurrency,
                            (functionalCurrenciesByCountry as { [key: string]: string })[merchantMe?.country || ''] || '***'
                          )
                        )}
                        icon={<InfoIcon />}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </Grid>
      </div>
      <div className="create_plan__fields_section">
        <div className="create_plan__fields_section__title">
          <div className="title">
            <span>3. </span>
            {l('subscriptions.plan.card3.cardTitle')}
          </div>
        </div>
        <div className="create_plan_form__row">
          <div className="create_plan_form__field">
            <Select
              name="frequency"
              label={l('subscriptions.plan.card3.frequency')}
              value={values.frequency}
              onChange={e => {
                const value = e.target.value;
                if (value === SubscriptionFrequency.CUSTOM) {
                  setFieldValue('frequencyValue', 1);
                  setFieldValue('frequencyType', SubscriptionCustomFrequency.DAY);
                }
                setFieldValue('frequency', value);
              }}
              optionsArray={frequencies}
              disabled={formDisabled || isEdition}
              error={(touched.frequency && errors.frequency) || undefined}
            />
          </div>
        </div>
        {values.frequency === SubscriptionFrequency.CUSTOM && (
          <>
            <div className="create_plan_form__row">
              <div className="create_plan_form__field custom_expiration__field">
                <Input
                  type="number"
                  name="frequencyValue"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.frequencyValue}
                  disabled={formDisabled || isEdition}
                  error={(touched.frequencyValue && errors.frequencyValue) || undefined}
                />
                <Select
                  name="frequencyType"
                  value={values.frequencyType}
                  onChange={handleChange}
                  optionsArray={frequencyTypes}
                  disabled={formDisabled || isEdition}
                  error={(touched.frequencyType && errors.frequencyType) || undefined}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
