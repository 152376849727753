import l from '../../../../lang';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import IntegrationCard from '../integration-card';
import { ReactComponent as ApiIcon } from '../../../../assets/icons/api.svg';
import { segmentIntegrationsMenuApiIntegration } from '../../../../utils/analytics';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationCard
      title={l('paymentIntegration.api.title')}
      icon={<ApiIcon />}
      color="blue"
      onDetails={() => {
        segmentIntegrationsMenuApiIntegration();
        setDetailsOpened(DetailsOpened.API);
      }}
      detailsText={l('paymentIntegration.card.link')}
    />
  );
};
