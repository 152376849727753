import Parser from 'html-react-parser';
import l from '../../../../lang';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import { ReactComponent as WooIcon } from '../../../../assets/icons/woo.svg';
import {
  segmentIntegrationsWooCommerceInstall,
  segmentIntegrationsWooCommerceKeysCopyApiKey,
  segmentIntegrationsWooCommerceKeysCopySecretKey,
  segmentIntegrationsWooCommercePageLinkDocs,
} from '../../../../utils/analytics';
import IntegrationDetails from '../integration-details';
import ButtonLink from '../../../common/button-link';
import { getLanguage, openNewTabAndFocus } from '../../../../utils';
import InputSecret from '../../../common/input-secret';
import { Urls } from '../../../../config/urls';
import { Button } from '../../../common/button';

export default () => {
  const { apiKey, secretKey, setDetailsOpened } = usePaymentIntegration();

  const getDocumentationUrl = () => {
    const lang = getLanguage();
    switch (lang) {
      case 'es':
        return Urls.docs.woocommerceIntegration.es;
      case 'pt':
        return Urls.docs.woocommerceIntegration.pt;
      default:
        return Urls.docs.woocommerceIntegration.en;
    }
  };

  const getDocumentationTestUrl = () => {
    const lang = getLanguage();
    switch (lang) {
      case 'es':
        return Urls.docs.woocommerceIntegrationTest.es;
      case 'pt':
        return Urls.docs.woocommerceIntegrationTest.pt;
      default:
        return Urls.docs.woocommerceIntegrationTest.en;
    }
  };

  return (
    <IntegrationDetails icon={<WooIcon />} color="violet" onClose={() => setDetailsOpened(DetailsOpened.NONE)}>
      <div className="integration_details__title">{l('paymentIntegration.woo.details.headline')}</div>
      <p>
        <span>{Parser(l('paymentIntegration.woo.details.description'))} </span>
        <br />
        <br />
        <ButtonLink
          text={l('paymentIntegration.woo.details.link')}
          onClick={() => {
            segmentIntegrationsWooCommercePageLinkDocs();
            openNewTabAndFocus(getDocumentationUrl());
          }}
        />
        <span>{Parser(l('paymentIntegration.woo.details.description2'))} </span>
        <br />
        <br />
        <ButtonLink
          text={l('paymentIntegration.woo.details.link2')}
          onClick={() => {
            segmentIntegrationsWooCommercePageLinkDocs();
            openNewTabAndFocus(getDocumentationTestUrl());
          }}
        />
        <span>{Parser(l('paymentIntegration.woo.details.description3'))} </span>
      </p>
      <div className="integration__key">
        <div className="integration__key__title">{l('paymentIntegration.woo.details.key.headline')}</div>
        <div>{Parser(l('paymentIntegration.woo.details.key.description'))}</div>
        <InputSecret text={apiKey} label="API Key" onCopy={() => segmentIntegrationsWooCommerceKeysCopyApiKey()} />
        <InputSecret text={secretKey} label="Secret Key" onCopy={() => segmentIntegrationsWooCommerceKeysCopySecretKey()} />
      </div>
      <Button
        className="integration_details__button"
        onClick={() => {
          segmentIntegrationsWooCommerceInstall();
          openNewTabAndFocus(Urls.woocommercePlugin);
        }}
      >
        {l('paymentIntegration.woo.details.button')}
      </Button>
    </IntegrationDetails>
  );
};
