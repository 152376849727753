import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { ItemOnboardingMenu } from '../../../../../onboarding-menu';
import { ValuesLevelTwoStepDocs } from '..';
import l from '../../../../../../../lang';
import labels from '../../../config/labels';
import { useFeature } from '../../../../../../../hooks/use-features';

interface FormObserverProps {
  displayed: boolean;
  isCompany: boolean;
  missingDocuments: string[] | null;
  onChangeOnboardingMenu: (menu: ItemOnboardingMenu[]) => void;
}

export const FormObserver = ({ displayed, isCompany, missingDocuments, onChangeOnboardingMenu }: FormObserverProps) => {
  const { values } = useFormikContext<ValuesLevelTwoStepDocs>();
  const { hasFeature } = useFeature();

  useEffect(() => {
    const { bankAccountDocument, identityDocument, proofOfAddressDocument, taxCompliantDocument, shareholdersDocument } =
      values;

    const bankAccountDocsCompleted = !!bankAccountDocument;
    const bankAccountDocsSelecetd = displayed && !bankAccountDocsCompleted;
    const bankAccountDocsHide = !!(missingDocuments && !missingDocuments?.includes('bankAccountDocument'));

    const companyOwnerDocumentsHide = !!(
      missingDocuments &&
      (!missingDocuments?.includes('identityDocument') || !missingDocuments?.includes('proofOfAddressDocument'))
    );
    const companyOwnerDocumentsCompleted = !!identityDocument && !!proofOfAddressDocument;
    const companyOwnerDocumentsSelected =
      (bankAccountDocsCompleted || bankAccountDocsHide) && !companyOwnerDocumentsCompleted;

    const companyDocumentsHide = !!(
      missingDocuments &&
      (!missingDocuments?.includes('taxCompliantDocument') ||
        (isCompany && !missingDocuments?.includes('shareholdersDocument')))
    );
    const companyDocumentsCompleted = !!taxCompliantDocument && (isCompany ? !!shareholdersDocument : true);
    const companyDocumentsSelected =
      (bankAccountDocsCompleted || bankAccountDocsHide) &&
      (companyOwnerDocumentsCompleted || companyOwnerDocumentsHide) &&
      !companyDocumentsCompleted;

    onChangeOnboardingMenu([
      {
        title: l('companyInformation.kycTwoMenu.documentation'),
        subItems: [
          {
            title: l('companyInformation.kycTwo.bankAccountDocumentation'),
            completed: bankAccountDocsCompleted,
            selected: bankAccountDocsSelecetd,
            hide: bankAccountDocsHide,
          },
          {
            title: l('companyInformation.kycTwo.companyOwnerDocuments'),
            completed: companyOwnerDocumentsCompleted,
            selected: companyOwnerDocumentsSelected,
            hide: companyOwnerDocumentsHide,
          },
          {
            title: labels.step2TaxCompliantDocumentHeadline(hasFeature, { isCompany }),
            completed: companyDocumentsCompleted,
            selected: companyDocumentsSelected,
            hide: companyDocumentsHide,
          },
        ],
      },
    ]);
  }, [values, missingDocuments]);
  return null;
};
