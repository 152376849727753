import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '../table-pagination';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    borderRadius: '4px',
    boxShadow: ' 0px 0px 0px 1px #E0E0E0',
  },
  container: {
    borderRadius: '5px',
    boxSizing: 'border-box',
    '&.--full-height': {
      height: '100%',
    },
    '&.--no-border-radius': {
      borderRadius: 0,
    },
  },
  headRow: {
    color: theme.palette.text.primary,
    backgroundColor: '#fffffff',
    fontFamily: 'Roboto',
    fontSize: 14,
    padding: 8,
    whiteSpace: 'nowrap',
    '@media (max-width:768px)': {
      fontSize: 11,
      padding: 5,
    },
    '&.--hidden-mobile': {
      '@media (max-width:768px)': {
        display: 'none',
      },
    },
  },
  headRowEmpty: {
    backgroundColor: '#fffffff',
    padding: 8,
  },
  bodyRow: {
    fontFamily: 'Roboto',
    fontSize: 14,
    padding: 8,
    minHeight: 48,
    '@media (max-width:768px)': {
      fontSize: 11,
      padding: 5,
    },
    '&.--hidden-mobile': {
      '@media (max-width:768px)': {
        display: 'none',
      },
    },
  },
  bodyRowEmpty: {
    border: 0,
    padding: 8,
    '&.--with-menu': {
      padding: 0,
    },
    '@media (max-width:768px)': {
      padding: 0,
    },
  },
}));

const Table = ({
  headers,
  body,
  dense,
  maxHeight,
  fullHeight,
  rowsPerPageOptions,
  rowsLength,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  idxHiddenColumnsOnMobile = [],
  noBorderRadius = false,
  withBtnMenu = false,
  emptyBody,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <TableContainer
        style={{ maxHeight }}
        className={`${classes.container} ${fullHeight ? '--full-height' : ''} ${noBorderRadius ? '--no-border-radius' : ''}`}
      >
        <MuiTable size={dense ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headRowEmpty} />
              {headers &&
                headers.map((h, idx) => (
                  <TableCell
                    className={`${classes.headRow} ${idxHiddenColumnsOnMobile.includes(idx) ? '--hidden-mobile' : ''}`}
                    align="center"
                    key={`head-${idx.toString()}`}
                  >
                    {h}
                  </TableCell>
                ))}
              <TableCell className={classes.headRowEmpty} />
            </TableRow>
          </TableHead>
          {body && (
            <TableBody>
              {body.map((row, rowIdx) => (
                <TableRow key={`row-${rowIdx.toString()}`} hover>
                  <TableCell className={classes.bodyRowEmpty} />
                  {row &&
                    row.map((cell, cellIdx) => {
                      if (withBtnMenu && cellIdx + 1 === row.length) {
                        return (
                          <TableCell className={`${classes.bodyRowEmpty} --with-menu`} key={`cell-${cellIdx.toString()}`}>
                            {cell}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          className={`${classes.bodyRow} ${
                            idxHiddenColumnsOnMobile.includes(cellIdx) ? '--hidden-mobile' : ''
                          }`}
                          align="center"
                          key={`cell-${cellIdx.toString()}`}
                        >
                          {cell}
                        </TableCell>
                      );
                    })}
                  {!withBtnMenu && <TableCell className={classes.bodyRowEmpty} />}
                </TableRow>
              ))}
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
      {!body && emptyBody && <>{emptyBody}</>}
      {handleChangePage && body && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          rowsLength={rowsLength}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

Table.propTypes = {
  headers: PropTypes.array,
  body: PropTypes.array,
  dense: PropTypes.bool,
  maxHeight: PropTypes.string,
  fullHeight: PropTypes.bool,
  idxHiddenColumnsOnMobile: PropTypes.array,
  rowsPerPageOptions: PropTypes.array,
  rowsLength: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};
Table.defaultProps = {
  maxHeight: undefined,
  dense: undefined,
  fullHeight: undefined,
  idxHiddenColumnsOnMobile: undefined,
};

export default Table;
