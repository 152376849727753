import React from 'react';
import { HelpOutline } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

const HelpButton = () => {
  const openWidgetZoho = () => {
    if (window.zsLoadAutoSuggestions) {
      window.zsLoadAutoSuggestions();
    }
  };

  return (
    <IconButton aria-label="help" variant="contained" onClick={() => openWidgetZoho()}>
      <HelpOutline />
    </IconButton>
  );
};

export default HelpButton;
