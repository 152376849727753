import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Moment from 'react-moment';
import l from '../../../../lang';
import ChipStatus from '../../../common/chip-status';
import { Urls } from '../../../../config/urls';
import { createDate } from '../../../../utils';

const RefundDetail = ({ detail }) => {
  return (
    <div className="refund-detail">
      <div className="refund-detail__label-status">
        <ChipStatus status={detail.status} text={l(`refunds.status.${detail.status}`)} />
      </div>

      <div>
        <div className="refund-detail__item">
          <div className="refund-detail__subtitle">{l('refunds.date')}</div>
          <Moment format="DD/MM/YYYY">{createDate(detail.date)}</Moment>
        </div>
        <div className="refund-detail__item">
          <div className="refund-detail__subtitle">{l('refunds.time')}</div>
          <Moment format="hh:mm:ss a">{createDate(detail.date)}</Moment>
        </div>
      </div>
      <div>
        <div className="refund-detail__item">
          <div className="refund-detail__subtitle">{l('refunds.country')}</div>
          <div>
            <img
              alt={`img-country-${detail.country}`}
              className="refund-detail__country"
              src={`${Urls.flags.replace(':code', detail.country)}`}
            />
          </div>
        </div>
        <div className="refund-detail__item">
          <div className="refund-detail__subtitle">{l('refunds.id')}</div>
          <div>{detail.id}</div>
        </div>
      </div>
      <div>
        <div className="refund-detail__item">
          <div className="refund-detail__subtitle">{l('refunds.transactionId')}</div>
          <div>{detail.transactionId}</div>
        </div>
        <div className="refund-detail__item">
          <div className="refund-detail__subtitle">{l('refunds.paymentMethod')}</div>
          <div>{detail.paymentMethod}</div>
        </div>
      </div>
      <div>
        <div className="refund-detail__item">
          <div className="refund-detail__subtitle">{l('refunds.localAmount')}</div>
          <div>
            {detail.currency} {detail.localAmount}
          </div>
        </div>
        <div className="refund-detail__item">
          <div className="refund-detail__subtitle">{l('refunds.balanceAmount')}</div>
          <div>
            {detail.balanceCurrency} {detail.balanceAmount}
          </div>
        </div>
      </div>
      <div>
        <div className="refund-detail__item">
          <div className="refund-detail__subtitle">{l('refunds.balanceFee')}</div>
          <div>
            {detail.balanceCurrency} {detail.balanceFee}
          </div>
        </div>
      </div>
    </div>
  );
};

RefundDetail.propTypes = {
  detail: PropTypes.object,
};

export default RefundDetail;
