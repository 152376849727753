import { PanelStore } from '../interfaces';

export namespace DashboardSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.dashboard.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.dashboard.error;
  };
  export const getMerchantMe = (state: PanelStore.RootState) => {
    return state.dashboard.merchantMe;
  };
  export const getUserMe = (state: PanelStore.RootState) => {
    return state.dashboard.userMe;
  };
  export const getCompanyWasFulfilled = (state: PanelStore.RootState) => {
    return !!state.dashboard.merchantMe?.name?.length;
  };
}
