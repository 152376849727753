import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QuickGuide from '../../../common/quick-guide';
import l from '../../../../lang';
import './styles.scss';

const QuickGuideSteps = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return createPortal(
    <div className="quick_guide_steps">
      <QuickGuide onToggle={handleToggle} open={open}>
        <Card className="quick_guide_steps__content">
          <div className="quick_guide_steps__content__header">
            <h2>{l('paymentLink.quick.guide.tab')}</h2>
            <IconButton className="quick_guide_steps__content__close" onClick={handleToggle}>
              <CloseIcon color="primary" />
            </IconButton>
          </div>
          <div className="quick_guide_steps__content__body">
            <h3>{l('paymentLink.quick.guide.headline')}</h3>
            <ol>
              <li>{l('paymentLink.quick.guide.paragraph.1')}</li>
              <li>{l('paymentLink.quick.guide.paragraph.2')}</li>
              <li>{l('paymentLink.quick.guide.paragraph.3')}</li>
            </ol>
            <hr />
            <p>{l('paymentLink.quick.guide.paragraph.4')}</p>
          </div>
        </Card>
      </QuickGuide>
    </div>,
    window.document.body
  );
};

export default QuickGuideSteps;
