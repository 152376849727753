import './styles.scss';

interface ChipStatusProps {
  status: string;
  text: string;
  fullWidth?: boolean;
  withBullet?: boolean;
}

const ChipStatus = ({ status, text, fullWidth, withBullet = true }: ChipStatusProps) => (
  <span
    className={`chip_status ${fullWidth ? 'chip_status__full_width' : ''} ${
      status && typeof status === 'string' ? `chip_status__status__${status.toLowerCase()}` : ''
    }`}
  >
    <span className={`chip_status__text ${withBullet ? 'chip_status__text__with_bullet' : ''}`}>
      {withBullet && <span className="chip_status__bullet">•</span>}
      <span>{text}</span>
    </span>
  </span>
);

export default ChipStatus;
