import { JumioStatus, StartResponse } from './interfaces';

export enum JumioType {
  START = 'START',
  START_SUCCESS = 'START_SUCCESS',
  START_ERROR = 'START_ERROR',
  REFRESH_STATUS = 'REFRESH_STATUS',
  STATUS_UPDATED = 'STATUS_UPDATED',
  LOADING_STATUS = 'LOADING_STATUS',
}

export const start = () => ({
  type: JumioType.START,
});

export const startSuccess = (startResponse: StartResponse) => ({
  type: JumioType.START_SUCCESS,
  payload: startResponse,
});

export const startError = () => ({
  type: JumioType.START_ERROR,
});

export const refreshStatus = () => ({
  type: JumioType.REFRESH_STATUS,
});

export const statusUpdated = (payload: JumioStatus) => ({
  type: JumioType.STATUS_UPDATED,
  payload,
});

export const setLoading = (payload: boolean) => ({
  type: JumioType.LOADING_STATUS,
  payload,
});
