import { handleErrorInfo } from '../../utils';
import { SignUpActionType } from './actions';
import { SignUpState } from './interfaces';

const initialState: SignUpState = {
  loading: false,
  error: false,
  errorInfo: undefined,
  success: false,
};

const SignUp = (state = initialState, action: any) => {
  switch (action.type) {
    case SignUpActionType.SENDING_SIGN_UP: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
      };
    }
    case SignUpActionType.SIGN_UP_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        success: true,
      };
    }
    case SignUpActionType.SIGN_UP_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(SignUpActionType.SIGN_UP_ERROR, action.error),
      };
    }
    default:
      return state;
  }
};

export default SignUp;
