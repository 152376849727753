import { CardStyle } from '../enums/CardStyle';
import Edit from '../../../../../assets/icons/edit.svg?url';
import Warning from '../../../../../assets/icons/warning.svg?url';
import Lock from '../../../../../assets/icons/lock.svg?url';
import Clock from '../../../../../assets/icons/clock-eight.svg?url';
import Check from '../../../../../assets/icons/check-white.svg?url';
import { FunctionComponent } from 'react';
import { CardHeaderButon } from './card-header-button';

interface cardStepStylesAttributes {
  headerIcon: string;
  sizeClass: string;
  headerClass: string;
}

const cardStepStyles = new Map<CardStyle, cardStepStylesAttributes>([
  [
    CardStyle.MAIN,
    {
      headerIcon: Edit,
      sizeClass: 'small',
      headerClass: 'card_step_main',
    },
  ],
  [
    CardStyle.WARNING,
    {
      headerIcon: Warning,
      sizeClass: 'small pending',
      headerClass: 'card_step_warning',
    },
  ],
  [
    CardStyle.SUCCESS,
    {
      headerIcon: Check,
      sizeClass: 'small success',
      headerClass: 'card_step_success',
    },
  ],
  [
    CardStyle.ERROR,
    {
      headerIcon: Warning,
      sizeClass: 'small error',
      headerClass: 'card_step_error',
    },
  ],
  [
    CardStyle.DISABLE,
    {
      headerIcon: Lock,
      sizeClass: 'small disabled',
      headerClass: 'card_step_disable',
    },
  ],
  [
    CardStyle.WAITING,
    {
      headerIcon: Clock,
      sizeClass: 'small pending',
      headerClass: 'card_step_warning',
    },
  ],
]);

interface CardStepProps {
  cardStyle: CardStyle;
  title: string;
  tag: string;
  disabled: boolean;
  onClick: () => void;
  children?: React.ReactElement;
}

const CardStep: FunctionComponent<CardStepProps> = ({
  cardStyle,
  title,
  tag: headerButtonText,
  disabled,
  onClick,
  children,
}) => {
  const styles = cardStepStyles.get(cardStyle);

  return (
    <div className="card_step">
      <div className={styles?.headerClass}>
        <div className="card_step_header">
          <div className="card_step_header_left">{title}</div>
          <div className="card_step_header_right">
            <CardHeaderButon
              text={headerButtonText}
              sizeClass={styles?.sizeClass ?? ''}
              icon={styles?.headerIcon ?? ''}
              disabled={disabled}
              onClick={onClick}
            />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default CardStep;
