import { takeLatest, put, call } from 'redux-saga/effects';
import { Urls } from '../../config/urls';
import { postTask } from '../../services';
import {
  PasswordResetActionType,
  sendPasswordResetSuccess,
  sendPasswordResetError,
  confirmPaswordResetSuccess,
  confirmPaswordResetError,
} from './actions';

function* sendPasswordReset(action: any) {
  const { response, error } = yield call(postTask, {
    url: Urls.api.sendPasswordReset,
    body: {
      email: action.email,
    },
  });

  if (response) {
    yield put(sendPasswordResetSuccess(response));
  } else {
    yield put(sendPasswordResetError(error));
  }
}

export function* sendPasswordResetSaga() {
  yield takeLatest(PasswordResetActionType.SEND_PASSWORD_RESET, sendPasswordReset);
}

function* confirmPasswordReset(action: any) {
  const { response, error } = yield call(postTask, {
    url: Urls.api.confirmPasswordReset,
    body: {
      password: action.password,
      token: action.token,
    },
  });

  if (response) {
    yield put(confirmPaswordResetSuccess(response));
  } else {
    yield put(confirmPaswordResetError(error));
  }
}

export function* confirmPasswordResetSaga() {
  yield takeLatest(PasswordResetActionType.CONFIRM_PASSWORD_RESET, confirmPasswordReset);
}
