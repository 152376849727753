import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const ItemFilter = ({ title, component, open, fnHandleClickItem }) => {
  return (
    <div>
      <ListItem button onClick={fnHandleClickItem}>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem>{component}</ListItem>
        </List>
      </Collapse>
    </div>
  );
};

ItemFilter.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  component: PropTypes.object,
  open: PropTypes.bool,
  fnHandleClickItem: PropTypes.func,
};

export default ItemFilter;
