import { takeLatest, put, call, select } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { getTask } from '../../services';
import { HomeActionType, getBalancesSuccess, getBalancesError } from './actions';
import { PanelStore } from '../interfaces';

function* getBalances() {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: Urls.api.balances,
    token: state.auth.token,
  });

  if (response) {
    yield put(getBalancesSuccess(response));
  } else {
    yield put(getBalancesError(error));
  }
}

export function* getBalancesSaga() {
  yield takeLatest(HomeActionType.GET_BALANCES, getBalances);
}
