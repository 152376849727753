import { useDispatch, useSelector } from 'react-redux';
import { Values } from '../interfaces';
import {
  addBankAccount,
  getBankAccounts,
  getBanksByCountry,
  deleteBankAccount,
  deleteBankAccountReset,
} from './actions';
import { BankAccountsSelectors } from './selectors';

export const useBankAccounts = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(BankAccountsSelectors.getLoading),
    error: useSelector(BankAccountsSelectors.getError),
    errorInfo: useSelector(BankAccountsSelectors.getErrorInfo),
    allBankAccounts: useSelector(BankAccountsSelectors.getAllBankAccounts),
    newBankAccount: useSelector(BankAccountsSelectors.getNewBankAccount),
    deletedBankAccount: useSelector(BankAccountsSelectors.getDeletedBankAccount),
    getBankAccounts: (page?: number, statusFilter?: string) => dispatch(getBankAccounts(page, statusFilter)),
    getBanksByCountry: (country: string) => dispatch(getBanksByCountry(country)),
    addBankAccount: (values: Values) => dispatch(addBankAccount(values)),
    deleteBankAccount: (id: number) => dispatch(deleteBankAccount(id)),
    bankAccountResetChanges: () => dispatch(deleteBankAccountReset()),
  };
};
