import { FunctionComponent } from 'react';
import Parser from 'html-react-parser';
import l from '../../../lang';
import { DialogConfirm, CardConfirm } from '../../common/confirm';
import { useBankAccounts } from '../../../store/bank-accounts';

interface BankAccountDeleteErrorProps {
  onClose: (confirm: boolean) => void;
}

const BankAccountDeleteError: FunctionComponent<BankAccountDeleteErrorProps> = ({ onClose }) => {
  const { deletedBankAccount } = useBankAccounts();
  const { errorInfo } = deletedBankAccount;

  const translationPrefix = errorInfo?.message ?? 'bankAccounts.delete.error';
  return (
    <DialogConfirm onClose={onClose}>
      <CardConfirm
        onClose={onClose}
        headline={Parser(l(`${translationPrefix}.headline`))}
        description={Parser(l(`${translationPrefix}.subheadline`))}
        confirmChildren={l('bankAccounts.delete.confirm.cancel.button')}
        level={'error'}
      />
    </DialogConfirm>
  );
};

export default BankAccountDeleteError;
