import Cookies from 'universal-cookie';
import config from '../config';
import en from '../assets/images/countryFlags/UK.svg?url';
import pt from '../assets/images/countryFlags/BR.svg?url';
import es from '../assets/images/countryFlags/ES.svg?url';
import { errorCodes } from './error-codes';
import { ErrorAPI } from '../store/interfaces';
import { AuthState } from '../store/auth/interfaces';
import { Frequency, SubscriptionCustomFrequency, SubscriptionFrequency } from '../constants';

export type Language = 'es' | 'en' | 'pt';
const navigatorLang = (navigator.languages && navigator.languages[0]) || navigator.language;
export const defaultLang = 'en';

export const setCookieLanguage = (lang: string) => {
  if (languages.filter(l => l.value === lang).length > 0) {
    const cookies = new Cookies();
    cookies.set('lang', lang, { path: '/' });
  }
};

export const getLanguage = (): Language => {
  const cookies = new Cookies();
  const l = cookies.get('lang');

  if (l) {
    // return cookie lang
    return l.toLowerCase();
  }

  const nl = navigatorLang && navigatorLang.match(/.{0,2}/g);
  const navLang = nl ? nl[0] : null;

  if (navLang && languages.filter(l => l.value === navLang).length > 0) {
    setCookieLanguage(navLang);
    // return navigator lang
    return navLang as Language;
  }

  setCookieLanguage(defaultLang);
  // return default lang
  return defaultLang;
};

export const realoadPage = () => {
  window.location.reload();
};

export const openNewTabAndFocus = (url: string) => {
  window.open(url);
  window.focus();
};

export const openUrl = (url: string) => {
  window.open(url);
};

export const redirecTo = (url: string) => {
  window.location.href = url;
};

export const languages = [
  {
    value: 'en',
    src: en,
  },
  {
    value: 'pt',
    src: pt,
  },
  {
    value: 'es',
    src: es,
  },
];

export const isRefreshTokenNecessary = (stateAuth: AuthState) => {
  try {
    if (stateAuth && stateAuth.token && stateAuth.token !== '') {
      if (stateAuth.tokenExpiration) {
        const dateExp: any = new Date(stateAuth.tokenExpiration);
        const dateNow: any = new Date();
        const diff = dateExp - dateNow;
        return config.refreshToken > diff;
      }
    }
  } catch (ex) {
    return true;
  }
  return true;
};

export const isTabletMobile = () => {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  return vw < 1024;
};

export const handleErrorInfo = (key: string, error?: ErrorAPI) => {
  const errorMessages: any = errorCodes(error?.appState);
  let err = null;
  try {
    if (error) {
      const { body, status } = error;
      const errorCode = body?.errorCode;
      const errorFields = body?.errorFields;

      err = errorMessages[key]
        ? (errorMessages[key][status] && errorCode && errorMessages[key][status][errorCode]) ||
          (errorMessages[key][status] && errorMessages[key][status].default) ||
          (errorCode && errorMessages[key][errorCode]) ||
          (errorCode && errorMessages[key][errorCode] && errorMessages[key][errorCode].default) ||
          (errorMessages[key] && errorMessages[key].default) ||
          null
        : null;
      if (err && errorFields) {
        return { ...err, errorFields };
      }
    }
    return err;
  } catch (ex) {
    return err;
  }
};

export const createDate = (date: string) => {
  return new Date(`${date}Z`);
};

export const countryToFlag = (isoCode: string) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
};

export const scrollIntoView = (ref: any, block = 'start', behavior = 'smooth') => {
  // block: start, center, end, or nearest.
  // behavior: auto or smooth.
  try {
    if (ref?.current) {
      ref.current.scrollIntoView({ block, behavior });
    }
  } catch (ex) {
    // Error
  }
};

export const removeProtocolFromUrl = (url: string) => {
  return url?.replace(/http(s)?:\/\//g, '') || url;
};

export const callbackUrlIsFromShopify = (callbackUrl: string) => {
  try {
    const url = new URL(callbackUrl);
    const authorizedDomains = import.meta.env.REACT_APP_SHOPIFY_DOMAINS as string;
    const shopifyDomains = authorizedDomains.split(',');

    return shopifyDomains.includes(url?.hostname);
  } catch (ex) {
    return false;
  }
};

export const getFrequencyTypeAndValue = (
  frequency: SubscriptionFrequency,
  frequencyType: SubscriptionCustomFrequency,
  frequencyValue: number
) => {
  if (frequency === SubscriptionFrequency.CUSTOM && frequencyType && frequencyValue) {
    return { frequencyType, frequencyValue };
  }

  switch (frequency) {
    case SubscriptionFrequency.WEEKLY:
      return { frequencyType: SubscriptionCustomFrequency.WEEKLY, frequencyValue: 1 };
    case SubscriptionFrequency.QUARTERLY:
      return { frequencyType: SubscriptionCustomFrequency.MONTH, frequencyValue: 3 };
    case SubscriptionFrequency.SEMI_ANNUALLY:
      return { frequencyType: SubscriptionCustomFrequency.MONTH, frequencyValue: 6 };
    case SubscriptionFrequency.ANNUALLY:
      return { frequencyType: SubscriptionCustomFrequency.YEAR, frequencyValue: 1 };
    default:
      return { frequencyType: SubscriptionCustomFrequency.MONTH, frequencyValue: 1 };
  }
};

export const getSubscriptionFrequency = (frequencyType: Frequency, frequencyValue: number): SubscriptionFrequency => {
  if (frequencyType === Frequency.WEEKLY && frequencyValue === 1) {
    return SubscriptionFrequency.WEEKLY;
  } else if (frequencyType === Frequency.MONTHLY && frequencyValue === 1) {
    return SubscriptionFrequency.MONTHLY;
  } else if (frequencyType === Frequency.MONTHLY && frequencyValue === 3) {
    return SubscriptionFrequency.QUARTERLY;
  } else if (frequencyType === Frequency.MONTHLY && frequencyValue === 6) {
    return SubscriptionFrequency.SEMI_ANNUALLY;
  } else if (frequencyType === Frequency.YEARLY && frequencyValue === 1) {
    return SubscriptionFrequency.ANNUALLY;
  } else {
    return SubscriptionFrequency.CUSTOM;
  }
};

export const getSubscriptionFrequencyType = (frequencyType: Frequency): SubscriptionCustomFrequency => {
  switch (frequencyType) {
    case Frequency.YEARLY:
      return SubscriptionCustomFrequency.YEAR;
    case Frequency.WEEKLY:
      return SubscriptionCustomFrequency.WEEKLY;
    case Frequency.MONTHLY:
      return SubscriptionCustomFrequency.MONTH;
    case Frequency.DAILY:
      return SubscriptionCustomFrequency.DAY;
  }
};
