import { PanelStore } from '../interfaces';

export namespace ClientsSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.clients.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.clients.error;
  };
  export const getErrorInfo = (state: PanelStore.RootState) => {
    return state.clients.errorInfo;
  };
  export const getAllClients = (state: PanelStore.RootState) => {
    return state.clients.allClients;
  };
}
