import { httpStatus } from '../utils/http-utils';

interface Task {
  url: string;
  body: object;
  token?: string;
  format?: 'form' | 'json';
}

interface GetTask {
  url: string;
  token?: string;
}

interface DeleteTask {
  url: string;
  token?: string;
}

export const getTask = ({ url, token }: GetTask) => {
  const headers: HeadersInit = token
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    : { 'Content-Type': 'application/json' };

  const options: RequestInit = {
    method: 'GET',
    headers,
    credentials: 'include',
  };
  return fetch(url, options)
    .then(httpStatus)
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

export const postTask = ({ url, body: data, token, format = 'json' }: Task) => {
  const headers = {
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  const options: RequestInit = {
    method: 'POST',
    headers,
    credentials: 'include',
  };

  const bodyForm = new FormData();
  switch (format) {
    case 'form':
      Object.entries(data).forEach(([key, file]) => {
        bodyForm.append(key, file);
      });
      options.body = bodyForm;

      break;
    case 'json':
    default:
      options.body = JSON.stringify({ ...data });
      options.headers = {
        ...options.headers,
        'Content-Type': 'application/json',
      };
      break;
  }

  return fetch(url, options)
    .then(httpStatus)
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

export const patchTask = ({ url, body, token }: Task) => {
  const headers: HeadersInit = token
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    : { 'Content-Type': 'application/json' };

  const options: RequestInit = {
    method: 'PATCH',
    headers,
    credentials: 'include',
    body: JSON.stringify({ ...body }),
  };
  return fetch(url, options)
    .then(httpStatus)
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

export const uploadTask = ({ url, body: bodyRaw, token }: Task) => {
  const headers = {
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  const body = new FormData();

  Object.entries(bodyRaw)
    .filter(([_, file]) => file?.name)
    .forEach(([key, file]) => {
      body.append(key, file);
    });

  const options = {
    method: 'PATCH',
    headers,
    body,
  };

  return fetch(url, options)
    .then(httpStatus)
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

export const deleteTask = ({ url, token }: DeleteTask) => {
  const headers: HeadersInit = token
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    : { 'Content-Type': 'application/json' };

  const options: RequestInit = {
    method: 'DELETE',
    headers,
    credentials: 'include',
    body: JSON.stringify({}),
  };
  return fetch(url, options)
    .then(httpStatus)
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

export const getCSVTask = ({ url, token }: GetTask) => {
  const headers: HeadersInit = token
    ? {
        'Content-Type': 'text/csv',
        Authorization: `Bearer ${token}`,
      }
    : { 'Content-Type': 'text/csv' };

  const options: RequestInit = {
    method: 'GET',
    headers,
    credentials: 'include',
  };
  return fetch(url, options)
    .then(response => httpStatus(response, true))
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

export const getXlsxTask = ({ url, token }: GetTask) => {
  const headers: HeadersInit = token
    ? {
        'Content-Type': 'application/vnd.ms-excel',
        Authorization: `Bearer ${token}`,
      }
    : { 'Content-Type': 'application/vnd.ms-excel' };

  const options: RequestInit = {
    method: 'GET',
    headers,
    credentials: 'include',
  };
  return fetch(url, options)
    .then(response => {
      if (!response.ok) {
        const responseStatusText = response.statusText;
        const errorMessage = `${responseStatusText}`;
        throw new Error(errorMessage);
      }
      return response.arrayBuffer().then(text => ({
        response: { text },
      }));
    })
    .catch(error => ({ error }));
};
