import PropTypes from 'prop-types';
import Logo from '../../common/logo';
import './styles.scss';
import CheckIcon from '../../../assets/icons/check.svg?url';
import ArrowRight from '../../../assets/icons/arrow-right-blue.svg?url';

export interface ItemOnboardingMenu {
  title: string;
  completed?: boolean;
  selected?: boolean;
  subItems?: ItemOnboardingMenu[];
  hide?: boolean;
}

interface OnboardingMenuProps {
  body: React.ReactNode;
  header: React.ReactNode;
  items: ItemOnboardingMenu[];
}

const OnboardingMenu = ({ body, header, items }: OnboardingMenuProps) => {
  return (
    <div className="menu-onboarding">
      <nav className="nav-dashboard">
        <div className="nav-dashboard__bg">
          <div className="nav-dashboard__logo">
            <Logo size="lg" type="vertical" goHomeDisabled />
          </div>
          <ul className="menu">
            {items.map((item, idxItem) =>
              item.hide ? (
                <></>
              ) : (
                <li key={`menu-${idxItem.toString()}`} className="menu__item">
                  <div
                    className={`menu__item__container ${item.subItems ? 'menu__item__container--group' : ''} ${
                      item.selected ? 'menu__item__container--selected' : ''
                    }`}
                  >
                    <div className="menu__item__container__img">
                      {(item.selected || item.completed) && (
                        <img className="img-item-menu" src={item.selected ? ArrowRight : CheckIcon} alt="img-item-menu" />
                      )}
                    </div>
                    <span>{item.title}</span>
                  </div>
                  {item.subItems?.map((subItem, idxSubItem) =>
                    subItem.hide ? (
                      <></>
                    ) : (
                      <div key={`menu-${idxItem.toString()}-${idxSubItem.toString()}`} className="sub-menu-container">
                        <div className={`sub-menu-item ${subItem.selected ? 'sub-menu-item--selected' : ''}`}>
                          <div className="sub-menu-item__img">
                            {(subItem.selected || subItem.completed) && (
                              <img
                                className="img-sub-item-menu"
                                src={subItem.selected ? ArrowRight : CheckIcon}
                                alt="img-sub-item-menu"
                              />
                            )}
                          </div>
                          <span>{subItem.title}</span>
                        </div>
                      </div>
                    )
                  )}
                </li>
              )
            )}
          </ul>
        </div>
      </nav>
      <section className="section-dashboard">
        <div className="section-dashboard__header">{header}</div>
        <div className="section-dashboard__container">{body}</div>
      </section>
    </div>
  );
};

OnboardingMenu.prototype = {
  selected: PropTypes.string,
  body: PropTypes.object,
};

export default OnboardingMenu;
