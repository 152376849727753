import { ClientsActionType } from './actions';
import { handleErrorInfo } from '../../utils';
import { ClientsState } from './interfaces';

const initialState: ClientsState = {
  loading: true,
  error: false,
  errorInfo: undefined,
  allClients: undefined,
};

const ClientsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ClientsActionType.GET_CLIENTS: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
      };
    }
    case ClientsActionType.GET_CLIENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        allClients: {
          ...action.response,
        },
      };
    }
    case ClientsActionType.GET_CLIENTS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(ClientsActionType.GET_CLIENTS_ERROR, action.error),
        allClients: undefined,
      };
    }
    default:
      return state;
  }
};

export default ClientsReducer;
