import { FunctionComponent } from 'react';
import { KycReviewStatus } from './enums/KycReviewStatus';
import { StatusKYCStepOne } from './enums/StatusKYCStepOne';
import { StatusKYCStepTwo } from './enums/StatusKYCStepTwo';
import { CardStepOne } from './card-step/card-step-one';
import { CardStepTwo } from './card-step/card-step-two';
import { useDashboard } from '../../../../store/dashboard';
import { MerchantMe } from '../../../../store/dashboard/interfaces';
import './styles.scss';
import { useJumioIntegration } from '../../../../store/jumio';
import { JumioStatusType } from '../../../../store/jumio/interfaces';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import CardInfo from '../../../common/card-info';
import l from '../../../../lang';
import Parser from 'html-react-parser';
import { Button } from '../../../common/button';
import { ReactComponent as ClockBlueIcon } from '../../../../assets/icons/clock-blue.svg';

interface getStatusStepProps {
  level: number;
  validKYC: boolean;
  kycReview: boolean;
  kycReviewStatus: KycReviewStatus | null;
  companyWasFulfilled?: boolean;
  jumioStatus?: JumioStatusType;
  jumioLimitReached?: boolean;
  jumioTryCount?: number;
}

const getStatusStepOne = ({
  level,
  validKYC,
  kycReviewStatus,
  companyWasFulfilled,
  jumioStatus,
  jumioLimitReached,
  jumioTryCount = 0,
}: getStatusStepProps): StatusKYCStepOne => {
  if (kycReviewStatus === KycReviewStatus.REJECTED_BY_WEBSITE) {
    return StatusKYCStepOne.REJECTED_BY_WEBSITE;
  }
  if (kycReviewStatus === KycReviewStatus.REJECTED_BY_INDUSTRY) {
    return StatusKYCStepOne.REJECTED_BY_INDUSTRY;
  }
  if (level === 1 && !validKYC && companyWasFulfilled && !jumioLimitReached && jumioTryCount > 0) {
    return StatusKYCStepOne.RETRY_VALIDATION;
  }
  if (level === 1 && !validKYC && companyWasFulfilled && !jumioLimitReached && jumioTryCount === 0) {
    return StatusKYCStepOne.MISSING_VALIDATION;
  }
  if (
    level === 1 &&
    !validKYC &&
    companyWasFulfilled &&
    ![JumioStatusType.PASSED, JumioStatusType.WARNING].includes(jumioStatus || JumioStatusType.NOT_EXECUTED) &&
    jumioLimitReached
  ) {
    return StatusKYCStepOne.ERROR_VALIDATION;
  }
  if ((level === 1 && validKYC) || level > 1) {
    return StatusKYCStepOne.COMPLETED;
  }
  return StatusKYCStepOne.ENABLED;
};

const getStatusStepTwo = ({ level, validKYC, kycReview, kycReviewStatus }: getStatusStepProps): StatusKYCStepTwo => {
  if (kycReview) {
    return StatusKYCStepTwo.PENDING_APPROVAL;
  }
  if (kycReviewStatus === KycReviewStatus.REJECTED_BY_DOCUMENTATION) {
    return StatusKYCStepTwo.REJECTED_BY_DOCUMENTATION;
  }
  if (kycReviewStatus === KycReviewStatus.REJECTED_BY_WEBSITE) {
    return StatusKYCStepTwo.REJECTED_BY_WEBSITE;
  }
  if (kycReviewStatus === KycReviewStatus.REJECTED_BY_INDUSTRY) {
    return StatusKYCStepTwo.REJECTED_BY_INDUSTRY;
  }
  if (level === 4 && validKYC) {
    return StatusKYCStepTwo.COMPLETED;
  }
  if (level === 1 && !validKYC) {
    return StatusKYCStepTwo.PENDING;
  }
  return StatusKYCStepTwo.ENABLED;
};

interface KYCStepsProps {
  kycReviewStatus: KycReviewStatus | null;
  limitStepOne: number;
  onClick: (step: number) => void;
}

const KYCSteps: FunctionComponent<KYCStepsProps> = ({ limitStepOne, kycReviewStatus, onClick }) => {
  const { status: jumioStatus, limitReached: jumioLimitReached, tryCount: jumioTryCount } = useJumioIntegration();
  const { merchantMe, companyWasFulfilled } = useDashboard();
  const {
    kycLevel: { level },
    validKYC,
    kycReview,
  } = merchantMe as MerchantMe;

  const stepOneStatus = getStatusStepOne({
    level,
    validKYC,
    kycReviewStatus,
    kycReview,
    companyWasFulfilled,
    jumioStatus,
    jumioLimitReached,
    jumioTryCount,
  });

  const isTabletMobile = useMediaQuery('(max-width:768px)');

  const stepTwoStatus = getStatusStepTwo({
    level,
    validKYC,
    kycReviewStatus,
    kycReview,
  });

  return (
    <div>
      <div className="card_steps">
        <CardStepOne statusKycStepOne={stepOneStatus} onClick={onClick} limitStepOne={limitStepOne} />
        {!isTabletMobile && (
          <CardStepTwo
            statusKycStepOne={stepOneStatus}
            statusKycStep2={stepTwoStatus}
            onClick={onClick}
            limitStepOne={limitStepOne}
          />
        )}
      </div>
      {stepOneStatus === StatusKYCStepOne.ENABLED && (
        <Box>
          <Box className="card__step_one-container">
            <CardInfo bottomElement={<div className="card__accentuate-line" />}>
              <Box sx={{ textAlign: 'center' }}>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress variant="determinate" value={50} size={100} />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <span className="circular-progress__text">50%</span>
                  </Box>
                </Box>
                <div className="card__desc">{Parser(l('home.complete-profile.kyc1.desc'))}</div>
              </Box>
            </CardInfo>
            <CardInfo bottomElement={<div className="card__accentuate-line" />}>
              <Box sx={{ width: '100%', position: 'relative' }}>
                <h3 className="text-dgo-primary">{l('companyInformation.step1.headline.complete')}</h3>
                <p>{l('companyInformation.step1.subheadline.complete')}</p>
                <p>{l('companyInformation.step1.content.complete')}</p>
                <Button onClick={() => onClick(1)}>{l('companyInformation.step1.subheadline.complete.load-data')}</Button>

                <Box className="estimated-time_box">
                  <ClockBlueIcon />
                  <Box>
                    <p>
                      {l('companyInformation.step1.estimated-time')}:
                      <br />3 {l('companyInformation.step1.estimated-time.minutes')}
                    </p>
                  </Box>
                </Box>
              </Box>
            </CardInfo>
          </Box>
          {isTabletMobile && (
            <Box className="card_steps" sx={{ marginTop: '20px!important' }}>
              <CardStepTwo
                statusKycStepOne={stepOneStatus}
                statusKycStep2={stepTwoStatus}
                onClick={onClick}
                limitStepOne={limitStepOne}
              />
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

export default KYCSteps;
