import { FunctionComponent, useState } from 'react';
import Card from '../../../common/card';
import l from '../../../../lang';
import { BankAccount } from '../../../../store/bank-accounts/interfaces';
import { getBankAccountLabel, getBankBranchLabel } from '../bank-account-helper';
import { Button } from '../../../common/button';
import BankAccountPills, { BankAccountPillsProps } from '../BankAccountPills';
import BankAccountConfirmDelete from './bank-account-confirm-delete';
import { useBankAccounts } from '../../../../store/bank-accounts';
import BankAccountCompanyInformation from './bank-account-company-information';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { useDashboard } from '../../../../store/dashboard';

import './styles.scss';

const BankAccountDetails: FunctionComponent<BankAccount & BankAccountPillsProps> = ({
  id,
  accountType,
  bankName,
  bankAccount,
  bankBranch,
  beneficiaryAddress,
  country: bankCountry,
  status,
  preferred,
}) => {
  const { deleteBankAccount } = useBankAccounts();
  const [isOpenDeleteConfirmDialog, setIsOpenDeleteConfirmDialog] = useState(false);

  const handlerCloseDelete = (confirm: boolean) => {
    if (confirm) {
      deleteBankAccount(id);
    }
    setIsOpenDeleteConfirmDialog(false);
  };

  const DetailsData = () => (
    <div className="bank_account_details__container">
      {accountType ? (
        <div className="bank_account_details__container__data">
          <div className="bank_account_details__container__data__field_name">{l('beneficiary.accountType')}</div>
          <div className="bank_account_details__container__data__field_value">{l(`bankAccounts.type.${accountType}`)}</div>
        </div>
      ) : null}
      <div className="bank_account_details__container__data">
        <div className="bank_account_details__container__data__field_name">{getBankAccountLabel(bankCountry)}</div>
        <div className="bank_account_details__container__data__field_value">{bankAccount}</div>
      </div>
      {bankBranch ? (
        <div className="bank_account_details__container__data">
          <div className="bank_account_details__container__data__field_name">{getBankBranchLabel(bankCountry)}</div>
          <div className="bank_account_details__container__data__field_value">{bankBranch}</div>
        </div>
      ) : null}
      {beneficiaryAddress ? (
        <div className="bank_account_details__container__data">
          <div className="bank_account_details__container__data__field_name">{l('beneficiary.address')}</div>
          <div className="bank_account_details__container__data__field_value">{beneficiaryAddress}</div>
        </div>
      ) : null}
    </div>
  );

  const Details = () => (
    <Card>
      <div className="bank_account_details">
        <div className="bank_account_details__header">
          <h2>{bankName}</h2>
          <BankAccountPills status={status} preferred={preferred} />
        </div>
        <DetailsData />
        <div className="bank_account_details__delete">
          <Button fullWidth variant="outlined" color="error" onClick={() => setIsOpenDeleteConfirmDialog(true)}>
            <DeleteIcon />
            {l('bankAccounts.delete')}
          </Button>
        </div>
      </div>
      {isOpenDeleteConfirmDialog && <BankAccountConfirmDelete onClose={handlerCloseDelete} />}
    </Card>
  );

  return (
    <div className="bank_account_details_data_and_owner">
      <Details />
      <BankAccountCompanyInformation />
    </div>
  );
};

export default BankAccountDetails;
