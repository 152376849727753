import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { getCommissions, types } from '../../../../utils/payment-methods-utils';
import Loading from '../../../common/loading';
import Table from '../../../common/table';
import MultiSelect from '../../../common/multi-select';
import { Urls } from '../../../../config/urls';
import l from '../../../../lang';
import './styles.scss';
import { allCountries } from '../../../../config';
import ButtonLink from '../../../common/button-link';
import { Routes } from '../../../../config/routes';
import Switch from '../../../common/switch';
import Dialog from '../../../common/dialog';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import { segmentPaymentMethodActivationCountryPayment } from '../../../../utils/analytics';

const filterCountries = (selectedCountries = []) => {
  const countries = [];
  allCountries.forEach(co => {
    if (selectedCountries.includes(co)) {
      countries.push({
        name: l(`countryCode.${co}`),
        id: co,
      });
    }
  });
  return countries;
};

const PaymentMethodList = ({
  loading,
  error,
  selectedCountries,
  paymentMethodsSelected,
  allPaymentMethods,
  creditCardEnabled,
  addPaymentMethod,
  removePaymentMethod,
  onChangePage,
  onChangeFilter,
}) => {
  const history = useHistory();
  const [filteredCountries, setFilteredCountries] = useState('');
  const [countriesWithCoverage, setCountriesWithCoverage] = useState([]);

  const [initMsg, setInitMsg] = useState(false);

  useEffect(() => {
    if (selectedCountries.length) setCountriesWithCoverage(filterCountries(selectedCountries));
  }, [selectedCountries]);

  return (
    <div className="payment-method-list">
      {loading && <Loading fullScreen />}
      {initMsg && (
        <Dialog
          dividers={false}
          title={l('paymentMethodList.message.title')}
          unpaddingFooter
          unpaddingContent
          body={<div className="confirm-body-container">{l('paymentMethodList.message.body')}</div>}
          footer={
            <div className="confirm-footer-container">
              <ButtonCustom
                name="btn-confirm"
                text={l('common.confirm')}
                variant="contained"
                size="full-bottom"
                onClick={() => setInitMsg(false)}
              />
            </div>
          }
        />
      )}
      <div>
        {!allPaymentMethods.loading && !creditCardEnabled && (
          <div className="payment-method-list__card-msg">
            <span>{Parser(l('paymentMethod.creditCardDisabled'))} </span>
            <ButtonLink
              text={l('paymentMethod.creditCardDisabled.here')}
              onClick={() => history.push(Routes.COMPANY_INFORMATION)}
            />
          </div>
        )}
        <div className="payment-method-list__filter">
          <div className="payment-method-list__filter-select">
            {countriesWithCoverage.length && (
              <MultiSelect
                name="filter-country"
                size="sm"
                label={l('countries.label')}
                placeholderSearch={l('searchCountries.label')}
                withSearch
                onChange={countries => {
                  setFilteredCountries(countries);
                  onChangeFilter(countries || countriesWithCoverage.map(co => co.id).join(','));
                }}
                options={countriesWithCoverage}
              />
            )}
          </div>
        </div>
        {allPaymentMethods.loading && <Loading />}
        {!allPaymentMethods.loading && (
          <Table
            dense
            headers={[
              <div className="payment-method-list__left">{l('paymentMethod.country')}</div>,
              <div className="payment-method-list__left">{l('paymentMethod.name')}</div>,
              <div className="payment-method-list__left">{l('paymentMethod.type')}</div>,
              <div className="payment-method-list__right">{l('paymentMethod.commission')}</div>,
              <div className="payment-method-list__center">{l('paymentMethod.status')}</div>,
              <div className="payment-method-list__center">{l('paymentMethod.action')}</div>,
            ]}
            body={allPaymentMethods?.data?.map(paymentMethod => {
              const added = !!(
                paymentMethodsSelected && paymentMethodsSelected.filter(pm => pm.id === paymentMethod.id).length
              );
              return [
                <div className="payment-method-list__country">
                  <img
                    alt={`img-country-${paymentMethod.country}`}
                    className="payment-method-list__country-flag"
                    src={`${Urls.flags.replace(':code', paymentMethod.country)}`}
                  />
                  <span>{l(`countryCode.${paymentMethod.country}`)}</span>
                </div>,
                <div className="payment-method-list__left">{paymentMethod.name}</div>,
                <div className="payment-method-list__left">{types(paymentMethod.type)}</div>,
                <div className="payment-method-list__commision">{getCommissions(paymentMethod)}</div>,
                paymentMethod.enabled ? (
                  <div className="payment-method-list__status">
                    <div className="point-status point-status--green" />
                    <div>{l('paymentMethod.available')}</div>
                  </div>
                ) : (
                  <div className="payment-method-list__status">
                    <div className="point-status point-status--red" />
                    <div>{l('paymentMethod.notAvailable')}</div>
                  </div>
                ),
                <div className="payment-method-list__action">
                  <Switch
                    checked={added}
                    disabled={!paymentMethod.enabled}
                    onChange={() => {
                      segmentPaymentMethodActivationCountryPayment({
                        paymentMethodId: paymentMethod.id
                      });
                      if (added) {
                        removePaymentMethod([paymentMethod.id]);
                      } else {
                        addPaymentMethod([paymentMethod.id]);
                      }
                    }}
                  />
                </div>,
              ];
            })}
            rowsPerPageOptions={[allPaymentMethods.pagination?.size]}
            rowsLength={allPaymentMethods.pagination?.totalElements}
            rowsPerPage={allPaymentMethods.pagination?.size}
            page={allPaymentMethods.pagination?.page}
            handleChangePage={(event, newPage) => {
              onChangePage(filteredCountries || countriesWithCoverage.map(co => co.id).join(','), newPage);
            }}
          />
        )}
      </div>
    </div>
  );
};

PaymentMethodList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  selectedCountries: PropTypes.array,
  paymentMethodsSelected: PropTypes.array,
  allPaymentMethods: PropTypes.object,
  creditCardEnabled: PropTypes.bool,
  addPaymentMethod: PropTypes.func,
  removePaymentMethod: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeFilter: PropTypes.func,
};

export default PaymentMethodList;
