import React from 'react';
import './AccordionMenuItem.scss';
import { ArrowForward, ExpandMore, ExpandLess } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';

const AccordionMenuItem = ({ id, open, title, onClick }) => {
  const isTabletMobile = useMediaQuery('(max-width:768px)');
  return (
    <div className={`accordion-menu-item ${open ? 'accordion-menu-item--show' : ''}`} onClick={e => onClick(id)}>
      <div className="accordion-menu-item__title">{title}</div>
      <div className="accordion-menu-item__arrow" alt="arrow">
        {!isTabletMobile && open && <ArrowForward />}
        {isTabletMobile && open && <ExpandLess />}
        {isTabletMobile && !open && <ExpandMore />}
      </div>
    </div>
  );
};

export default AccordionMenuItem;
