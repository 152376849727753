import { setCookieLanguage, realoadPage } from '../../utils';
import { CommonActionType } from './actions';

const initialState = {};

const Common = (state = initialState, action: any) => {
  switch (action.type) {
    case CommonActionType.CHANGE_LANGUAGE: {
      setCookieLanguage(action.lang);

      const params = new URLSearchParams(window.location.search);
      const lang = params.get('lang');

      if (lang) {
        params.set('lang', action.lang);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
      }

      realoadPage();
      return state;
    }
    default:
      return state;
  }
};

export default Common;
