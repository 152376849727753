import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import { Edit as EditIcon } from '@mui/icons-material';
import { Formik } from 'formik';
import Input from '../../../common/input';
import Select from '../../../common/select';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import MessageError from '../../../common/message-error';
import l from '../../../../lang';
import { cleanProfileSuccess, savePersonalInfo, openEditPersonalInfo } from '../../../../store/profile/actions';
import InputCheckbox from '../../../common/input-checkbox';
import Loading from '../../../common/loading';
import SuccessfulAnimation from '../../../common/successful-animation';
import { updateUserMe } from '../../../../store/dashboard/actions';
import { commons as validations } from '../../../../utils/validations';
import { segmentUserMenuProfileEdit, segmentUserMenuProfileEditSave } from '../../../../utils/analytics';

const PersonalInfo = ({
  loading,
  error,
  errorInfo,
  success,
  userMe,
  showEditPersonalInfo,
  fnSavePersonalInfo,
  fnCleanProfileSuccess,
  fnUpdateUserMe,
  fnShowEditPersonalInfo,
}) => {
  const [newValues, setNewValues] = useState({});

  useEffect(() => {
    if (showEditPersonalInfo) {
      fnShowEditPersonalInfo(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (success && showEditPersonalInfo) {
      setTimeout(() => {
        fnUpdateUserMe(newValues);
        fnShowEditPersonalInfo(false);
        fnCleanProfileSuccess();
      }, 2000);
    }
    // eslint-disable-next-line
  }, [success]);

  return (
    <div className={`personal-info ${(loading || success) && !showEditPersonalInfo ? 'personal-info--hide' : ''}`}>
      {loading && (
        <div className="personal-info__loading">
          <Loading />
        </div>
      )}
      {success && (
        <div className="personal-info__success">
          <SuccessfulAnimation text={l('profile.updateSuccess')} />
        </div>
      )}
      <div className={`personal-info__container ${loading || success ? 'personal-info__container--hide' : ''}`}>
        <div className="personal-info__section">
          <div className="personal-info__section__title">{l('personalInfo.title')}</div>
          <div className="personal-info__section__edit">
            <ButtonCustom
              startIcon={<EditIcon />}
              text={l('personalInfo.edit')}
              onClick={() => {
                segmentUserMenuProfileEdit();
                fnShowEditPersonalInfo(true)
              }}
            />
          </div>
        </div>
        {!showEditPersonalInfo && (
          <>
            <div className="personal-info__box">
              <div className="personal-info__box__title">{l('personalInfo.name')}</div>
              <div className="personal-info__box__desc">{userMe.name}</div>
            </div>
            <div className="personal-info__box">
              <div className="personal-info__box__title">{l('personalInfo.email')}</div>
              <div className="personal-info__box__desc">{userMe.email}</div>
            </div>
            <div className="personal-info__box">
              <div className="personal-info__box__title">{l('personalInfo.language')}</div>
              <div className="personal-info__box__desc">{l(`common.language.${userMe.language}`)}</div>
            </div>
            <div className="personal-info__box">
              <div className="personal-info__box__title">{l('personalInfo.marketingConsent')}</div>
              <div className="personal-info__box__desc">{l(`common.${userMe.marketingConsent ? 'yes' : 'no'}`)}</div>
            </div>
          </>
        )}
        {showEditPersonalInfo && (
          <Formik
            initialValues={{
              name: userMe.name,
              email: userMe.email,
              language: userMe.language,
              marketingConsent: userMe.marketingConsent,
            }}
            validate={values => {
              const errors = {};
              if (!values.name || !validations.nameValid(values.name)) {
                errors.name = l('form.input.required');
              }
              return errors;
            }}
            onSubmit={values => {
              const { name, language, marketingConsent } = values;
              setNewValues({ name, language, marketingConsent });
              segmentUserMenuProfileEditSave({
                language,
                marketingConsent,
              })
              fnSavePersonalInfo(name, language, marketingConsent);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <div className="personal-info__box personal-info__box--left">
                  <Input
                    name="name"
                    label={l('personalInfo.name')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    disabled={loading}
                    error={errors.name && touched.name && errors.name}
                  />
                </div>
                <div className="personal-info__box personal-info__box--right">
                  <Input name="email" label={l('personalInfo.email')} value={values.email} disabled />
                </div>
                <div className="personal-info__box personal-info__box--left">
                  <Select
                    name="language"
                    label={l('personalInfo.language')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.language}
                    defaultOptionText={l('common.default.option')}
                    options={{ en: l('common.language.en'), es: l('common.language.es'), pt: l('common.language.pt') }}
                    disabled={loading}
                    error={errors.language && touched.language && errors.language}
                  />
                </div>
                <div className="personal-info__box personal-info__box--right personal-info__box--checkbox">
                  <InputCheckbox
                    name="marketingConsent"
                    label={l('personalInfo.marketingConsent')}
                    onChange={e => {
                      setFieldValue('marketingConsent', e.target.checked);
                    }}
                    onBlur={handleBlur}
                    value={values.marketingConsent}
                    disabled={loading}
                    error={errors.marketingConsent && touched.marketingConsent && errors.marketingConsent}
                  />
                </div>
                {error && (
                  <div className="personal-info__error">
                    <MessageError errorInfo={errorInfo} />
                  </div>
                )}
                {!success && !loading && (
                  <div className="confirm-footer-container personal-info__buttons">
                    <ButtonCustom
                      text={l('personalInfo.cancel')}
                      name="btn-personal-info-cancel"
                      size="half-left-square"
                      onClick={() => fnShowEditPersonalInfo(false)}
                    />
                    <ButtonCustom
                      type="submit"
                      name="btn-personal-info-save"
                      text={l('personalInfo.save')}
                      variant="contained"
                      size="half-right-square"
                      disabled={
                        values.name === userMe.name &&
                        values.language === userMe.language &&
                        values.marketingConsent === userMe.marketingConsent
                      }
                    />
                  </div>
                )}
              </form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

PersonalInfo.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  success: PropTypes.bool,
  userMe: PropTypes.object,
  showEditPersonalInfo: PropTypes.bool,
  fnSavePersonalInfo: PropTypes.func,
  fnCleanProfileSuccess: PropTypes.func,
  fnUpdateUserMe: PropTypes.func,
  fnShowEditPersonalInfo: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.profile.loading,
  error: state.profile.error,
  errorInfo: state.profile.errorInfo,
  success: state.profile.success,
  showEditPersonalInfo: state.profile.showEditPersonalInfo,
  userMe: state.dashboard.userMe,
});

const mapDispatchToProps = dispatch => ({
  fnSavePersonalInfo: (name, language, marketingConsent) => dispatch(savePersonalInfo(name, language, marketingConsent)),
  fnCleanProfileSuccess: () => dispatch(cleanProfileSuccess()),
  fnUpdateUserMe: newValues => dispatch(updateUserMe(newValues)),
  fnShowEditPersonalInfo: show => dispatch(openEditPersonalInfo(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
