// @ts-nocheck
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormObserver } from '../form-observer';
import AddBankForm from '../add-bank-form';
import l from '../../../../../lang';
import { addBankAccount } from '../../../../../store/bank-accounts/actions';
import { hideFieldsByCountry } from '../../add-bank-account-forms/bank-account-config';

const AddBankAccountOnboarding = ({
  country,
  newBankAccount,
  addBankAccount,
  areBankAccountsAdded,
  missingDocuments,
  displayed,
  onClickNext,
  onChangeMenu,
}) => {
  useEffect(() => {
    if (areBankAccountsAdded) {
      onClickNext();
    }
  }, [areBankAccountsAdded]);

  useEffect(() => {
    if (newBankAccount.success) {
      onClickNext();
    }
  }, [newBankAccount.success]);

  const handleSubmit = valuesToSend => {
    delete valuesToSend.bankAccountConfirm;
    if (areBankAccountsAdded || newBankAccount.success) {
      onClickNext();
    } else {
      addBankAccount(valuesToSend);
    }
  };

  return (
    <AddBankForm
      title={l('companyInformation.kycTwo.sectionTwo.title')}
      description={l('companyInformation.kycTwo.sectionTwo.subtitle')}
      handleSubmit={handleSubmit}
      submitLabel={l('companyInformation.next')}
      FormObserver={
        <FormObserver
          displayed={displayed}
          areBankAccountsAdded={areBankAccountsAdded}
          missingDocuments={missingDocuments}
          hideFields={hideFieldsByCountry[country]}
          onChangeOnboardingMenu={onChangeMenu}
        />
      }
    ></AddBankForm>
  );
};

const mapStateToProps = state => ({
  country: state.dashboard.merchantMe?.country,
  isCompany: state.dashboard.merchantMe?.company,
  newBankAccount: state.bankAccounts.newBankAccount,
  areBankAccountsAdded: !!state.bankAccounts.allBankAccounts?.data?.length,
  missingDocuments: state.companyInformation.missingDocuments,
});

const mapDispatchToProps = dispatch => ({
  addBankAccount: values => dispatch(addBankAccount(values, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBankAccountOnboarding);
