import MUIButton, { ButtonProps as ButtonPropsMUI } from '@mui/material/Button';
import { styled } from '@mui/system';

export interface ButtonProps extends ButtonPropsMUI {
  textTransformNone?: boolean;
}

const DefaultButton = (props: ButtonProps) => {
  return <MUIButton variant="contained" size="medium" disableElevation {...props} />;
};

export const Button = styled(DefaultButton)(({ theme, textTransformNone }) => ({
  textTransform: textTransformNone ? 'none' : undefined,
}));
