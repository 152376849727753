import { useDispatch, useSelector } from 'react-redux';
import { changeShowRequestSettlement, getBeneficiaries, getSettlements, postRequestSettlement } from './actions';
import { SettlementsSelectors } from './selectors';

export const useSettlements = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(SettlementsSelectors.getLoading),
    error: useSelector(SettlementsSelectors.getError),
    errorInfo: useSelector(SettlementsSelectors.getErrorInfo),
    allSettlements: useSelector(SettlementsSelectors.getAllSettlements),
    showRequestSettlement: useSelector(SettlementsSelectors.getShowRequestSettlement),
    requestSuccess: useSelector(SettlementsSelectors.getRequestSuccess),
    beneficiaries: useSelector(SettlementsSelectors.getBeneficiaries),
    getSettlements: (page: number, filter: string) => dispatch(getSettlements(page, filter)),
    changeShowRequestSettlement: (show: boolean) => dispatch(changeShowRequestSettlement(show)),
    getBeneficiaries: () => dispatch(getBeneficiaries()),
    postRequestSettlement: (beneficiaryId: string) => dispatch(postRequestSettlement(beneficiaryId)),
  };
};
