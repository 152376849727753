import { FunctionComponent, useState } from 'react';
import { InputBase } from '@mui/material';
import Select from '../select';
import Switch from '@mui/material/Switch';
import Input from './../input';
import l from '../../../lang';
import './styles.scss';

interface ExpirationBoxProps {
    expirationType: string
    expirationValue: string
    onChangeExpirationType: (val: string) => void
    onChangeExpirationValue: (val: string) => void
    expirationEnabled: boolean
    toggleExpirationEnabled: () => void
    errors: { expirationType: string, expirationValue: string }
    touched: { expirationType: string, expirationValue: string }
}

const ExpirationBox: FunctionComponent<ExpirationBoxProps> = ({ 
    errors, 
    touched,
    expirationEnabled, 
    toggleExpirationEnabled, 
    expirationType, 
    expirationValue, 
    onChangeExpirationType, 
    onChangeExpirationValue }) => {
    const EXPIRATIONS = [
        { value: "MINUTES", displayName: l('expirationBox.MINUTES')},
        { value: "HOURS", displayName: l('expirationBox.HOURS') },
        { value: "DAYS", displayName: l('expirationBox.DAYS') }
    ];
    return (
        <div className="expiration-box">
            <div className="expiration-box__header">
                <div className="expiration-box__title">
                    <span>{l('expirationBox.headline')}</span> <span>{l('expirationBox.headline.optional')}</span>
                </div>
                <div className="expiration-box__toggle">
                    <Switch checked={expirationEnabled} onChange={() => toggleExpirationEnabled()} />
                </div>
            </div>
            <div className="expiration-box__content">
                <span>
                    {l('expirationBox.subheadline')}
                </span>
                {
                    expirationEnabled && (
                        <div className="expiration-box__fields">
                            <Input
                                type='number'
                                name="Expiration date"
                                label={l('expirationBox.expirationDate')}
                                value={expirationValue}
                                onChange={e => onChangeExpirationValue(e.currentTarget.value)}
                                error={touched.expirationValue && errors.expirationValue}
                            />
                            <Select
                                name="country"
                                value={expirationType}
                                optionsArray={EXPIRATIONS}
                                onChange={e => {
                                    onChangeExpirationType(e.currentTarget.value);
                                }}
                                error={touched.expirationType && errors.expirationType}
                            />
                        </div>
                    )
                }

            </div>
        </div>
    );
};

export default ExpirationBox;
