import { handleErrorInfo } from '../../utils';
import { prepareExportTransactionCSV } from '../../utils/transactions-utils';
import { TransacionesActionType } from './actions';
import { TransactionsState } from './interfaces';

const initialState: TransactionsState = {
  loading: true,
  error: false,
  allTransactions: undefined,
  transactionDetail: {
    loading: false,
    error: false,
    errorInfo: undefined,
    data: undefined,
  },
  paymentMethods: {
    loading: false,
    error: false,
    errorInfo: undefined,
    data: [],
  },
  export: {
    loading: false,
    error: false,
    errorInfo: undefined,
    show: false,
    data: undefined,
  },
};

const TransactionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TransacionesActionType.GET_TRANSACTIONS: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
      };
    }
    case TransacionesActionType.GET_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        allTransactions: {
          ...action.response,
        },
      };
    }
    case TransacionesActionType.GET_TRANSACTIONS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(TransacionesActionType.GET_TRANSACTIONS_ERROR, action.error),
        allTransactions: null,
      };
    }
    case TransacionesActionType.SET_TRANSACTION_DETAIL: {
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          data: action.data,
        },
      };
    }
    case TransacionesActionType.GET_TRANSACTION_DETAIL: {
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          loading: true,
          error: false,
          errorInfo: undefined,
          data: undefined,
        },
      };
    }
    case TransacionesActionType.GET_TRANSACTION_DETAIL_SUCCESS: {
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          loading: false,
          data: action.response,
        },
      };
    }
    case TransacionesActionType.GET_TRANSACTION_DETAIL_ERROR: {
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(TransacionesActionType.GET_TRANSACTION_DETAIL_ERROR, action.error),
          data: null,
        },
      };
    }
    case TransacionesActionType.GET_PRE_CHARGEBACK_FORM: {
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          loading: true,
          error: false,
          errorInfo: undefined,
          data: {
            ...state.transactionDetail.data,
            preChargebackForm: null,
          },
        },
      };
    }
    case TransacionesActionType.GET_PRE_CHARGEBACK_FORM_SUCCESS: {
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          loading: false,
          data: {
            ...state.transactionDetail.data,
            preChargebackForm: action.response,
          },
        },
      };
    }
    case TransacionesActionType.GET_PRE_CHARGEBACK_FORM_ERROR: {
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(TransacionesActionType.GET_TRANSACTION_DETAIL_ERROR, action.error),
          data: {
            ...state.transactionDetail.data,
            preChargebackForm: null,
          },
        },
      };
    }
    case TransacionesActionType.GET_PAYMENT_METHODS_FOR_TRANSACTIONS: {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: true,
          error: false,
          errorInfo: undefined,
          data: [],
        },
      };
    }
    case TransacionesActionType.GET_PAYMENT_METHODS_FOR_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: false,
          error: false,
          errorInfo: undefined,
          data: action.response.data,
        },
      };
    }
    case TransacionesActionType.GET_PAYMENT_METHODS_FOR_TRANSACTIONS_ERROR: {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(TransacionesActionType.GET_PAYMENT_METHODS_FOR_TRANSACTIONS_ERROR, action.error),
        },
      };
    }
    case TransacionesActionType.SHOW_EXPORT_TRANSACTIONS: {
      return {
        ...state,
        export: {
          ...state.export,
          show: action.show,
          ...(action.show
            ? {
                loading: false,
                error: false,
                errorInfo: undefined,
                data: undefined,
              }
            : {}),
        },
      };
    }
    case TransacionesActionType.EXPORT_TRANSACTIONS: {
      return {
        ...state,
        exportType: action.exportType,
        export: {
          ...state.export,
          loading: true,
          error: false,
          errorInfo: undefined,
          data: undefined,
        },
      };
    }
    case TransacionesActionType.EXPORT_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        export: {
          ...state.export,
          loading: false,
          data: prepareExportTransactionCSV(action.response.text),
        },
      };
    }
    case TransacionesActionType.EXPORT_TRANSACTIONS_ERROR: {
      return {
        ...state,
        export: {
          ...state.export,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(TransacionesActionType.EXPORT_TRANSACTIONS_ERROR, action.error),
        },
      };
    }
    default:
      return state;
  }
};

export default TransactionsReducer;
