import './styles.scss';
import logoDlGO from '../../../../assets/images/dlocalGo.svg?url';

export const PrivacyPoliciesEN = () => {
  return (
    <div className="privacy_policies_en">
      <div className="privacy_policies_en__header">
        <span className="privacy_policies_en__title">Privacy Policy</span>
        <img className="privacy_policies_en__logo" src={logoDlGO} alt="" />
      </div>
      <div className="privacy_policies_en__content">
        {/* CONTENT EXPORTED DIRECTLY FROM GOOGLE DOC */}
        <div className="c28 doc-content">
          <p className="c6">
            <span className="c11">Last Update:</span>
            <span className="c0">&nbsp;08 June, 2022.&nbsp;&nbsp; </span>
          </p>
          <p className="c9">
            <span className="c11">This is the privacy notice of dlocal llp</span>
          </p>
          <ol className="c2 lst-kix_3b3h11ndmcln-0 start" start={1}>
            <li className="c10 li-bullet-0">
              <span className="c11 c19">&nbsp;Introduction</span>
            </li>
          </ol>
          <p className="c9">
            <span className="c0">
              Your privacy is very important to us. We are committed to the protection of your Personal Data, and the purpose
              of this Privacy Notice is to inform you about the way we process your Personal Data, including references to
              which data we process, how, why, and for how long, together with information about your rights as a Data
              Subject.
            </span>
          </p>
          <p className="c9">
            <span className="c5">This Privacy Notice </span>
            <span className="c5">(</span>
            <span className="c5 c13">together with our Website Terms at&nbsp;</span>
            <span className="c23 c5 c13">
              <a className="c14" href="https://docs.dlocalgo.com/documentation/terms-and-conditions">
                HERE
              </a>
            </span>
            <span className="c5">,</span>
            <span className="c0">
              &nbsp;and any other documents referred to in it) sets out the basis on which any Personal Data we collect from
              you, or that you provide to us, will be processed by us. This Privacy Notice also sets out how you can instruct
              us if you prefer to limit the use of that Personal Data, as well as the procedures that we have in place to
              safeguard your privacy.
            </span>
          </p>
          <p className="c9">
            <span className="c0">
              It is important that you read this Privacy Notice together with any other privacy notice or fair processing
              notice we may provide on specific occasions when we are collecting or processing Personal Data about you so
              that you are fully aware of how and why we are using your Personal Data. This Privacy Notice supplements the
              other notices and is not intended to override them.
            </span>
          </p>
          <ol className="c2 lst-kix_3b3h11ndmcln-0" start={2}>
            <li className="c10 li-bullet-0">
              <span className="c11 c19">&nbsp;Important information</span>
            </li>
          </ol>
          <p className="c12">
            <span className="c3">Entity</span>
          </p>
          <p className="c26">
            <span className="c5">Dlocal LLP</span>
            <span className="c5">&#39;s</span>
            <span className="c5">&nbsp;address is 4 King&rsquo;s Bench Walk, London EC4Y 7DL, United Kingdom.</span>
            <span className="c5 c20">&nbsp;</span>
          </p>
          <p className="c12">
            <span className="c0">
              Dlocal LLP may act as a Data Controller or as a Data Processor and is referred to in this Privacy Notice
              as&nbsp;&lsquo;dLocal&rsquo;,&nbsp;&lsquo;we&rsquo;, or&nbsp;&lsquo;our&rsquo;&nbsp;or&nbsp;&lsquo;us&rsquo;.
            </span>
          </p>
          <p className="c12">
            <span className="c3">Individuals from which we collect Personal Data (the &ldquo;Data Subjects&rdquo;)</span>
          </p>
          <p className="c6">
            <span className="c5">In this Privacy Notice,&nbsp;</span>
            <span className="c11">&ldquo;you&rdquo;</span>
            <span className="c5">&nbsp;or&nbsp;</span>
            <span className="c11">&ldquo;your&rdquo;</span>
            <span className="c5">
              &nbsp;means an individual who is the subject of Personal Data we process as a data controller, which would
              typically be: the visitors of our website at{' '}
            </span>
            <span className="c23 c5 c27">
              <a className="c14" href="http://www.dlocalgo.com">
                www.dlocalgo.com
              </a>
            </span>
            <span className="c5">&nbsp;(our&nbsp;</span>
            <span className="c11">&ldquo;Website&rdquo;</span>
            <span className="c5">
              ); and (ii) the representatives and end users of online merchants and other payment providers (our&nbsp;
            </span>
            <span className="c11">&ldquo;Customers&rdquo;</span>
            <span className="c0">
              ), that interact with us, open user accounts with us and access our platform to receive our payment processing
              services.
            </span>
          </p>
          <p className="c9">
            <span className="c5">For the purpose of this Privacy Notice,&nbsp;</span>
            <span className="c11">&ldquo;Data Protection Legislation&rdquo;</span>
            <span className="c5">
              &nbsp;means(i) the UK Data Protection Act 2018; and (ii) the GDPR as amended and adopted by UK law by virtue of
              section 3 of the European Union (Withdrawal) Act 2018 (the&nbsp;
            </span>
            <span className="c11">&ldquo;UK GDPR&rdquo;</span>
            <span className="c0">).</span>
          </p>
          <p className="c12">
            <span className="c3">Contact Details</span>
          </p>
          <p className="c6">
            <span className="c5">
              We have appointed a data protection officer (DPO) who is responsible for overseeing questions in relation to
              this Privacy Notice. If you have any questions about this Privacy Notice, including any requests to exercise
              your legal rights, please contact us{' '}
            </span>
            <span className="c5">at </span>
            <span className="c5">by email:&nbsp;</span>
            <span className="c18 c5">
              <a className="c14" href="mailto:dpo@dlocal.com">
                dpo@dlocal.com
              </a>
            </span>
          </p>
          <p className="c9">
            <span className="c5">
              You have the right to make a complaint at any time to the applicable supervisory authority for data protection
              issues: the Information Commissioner&rsquo;s Office of the UK (
            </span>
            <span className="c18 c5">
              <a className="c14" href="https://ico.org.uk/">
                https://ico.org.uk/
              </a>
            </span>
            <span className="c0">).</span>
          </p>
          <p className="c9">
            <span className="c0">
              We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please
              contact us in the first instance.
            </span>
          </p>
          <ol className="c2 lst-kix_3b3h11ndmcln-0" start={3}>
            <li className="c10 li-bullet-0">
              <span className="c11 c19">Information we may collect (or receive) about you</span>
            </li>
          </ol>
          <p className="c4">
            <span className="c0"></span>
          </p>
          <p className="c6">
            <span className="c0">How is your Personal Data collected:</span>
          </p>
          <ol className="c2 lst-kix_list_1-0 start" start={1}>
            <li className="c15 li-bullet-0">
              <span className="c11">Direct interactions.</span>
              <span className="c0">
                &nbsp;You may give us your Identity and Contact Data (as defined below) by filling in forms or by
                corresponding with us by post, phone, email or otherwise. This includes Personal Data you provide as a
                representative of a Customer or a prospective Customer when you:
              </span>
            </li>
          </ol>
          <ol className="c2 lst-kix_list_1-1 start" start={1}>
            <li className="c6 c29 li-bullet-0">
              <span className="c0">Apply for or enquire about our products or services;</span>
            </li>
            <li className="c22 li-bullet-0">
              <span className="c0">
                Interact with us in connection with our services or our relationship with the Customer you represent;
              </span>
            </li>
            <li className="c22 li-bullet-0">
              <span className="c0">
                Create a Customer user account on our payment processing service platform to receive our services; or
              </span>
            </li>
            <li className="c22 li-bullet-0">
              <span className="c0">Subscribe to our service or publications.</span>
            </li>
          </ol>
          <ol className="c2 lst-kix_list_1-0" start={2}>
            <li className="c8 li-bullet-0">
              <span className="c11">You use and interact with our Website.</span>
              <span className="c5">
                &nbsp;When you browse our website, we process Technical Data (defined below). We use this data for our
                legitimate interests of making sure our website works properly, including debugging, to be able to deliver
                its content, for DDOS mitigation on our website, improving our website, and performing statistical analyses
                for optimizing the quality of our website. Please see our Cookies Policy at
              </span>
              <span className="c5">&nbsp;</span>
              <span className="c23 c5">
                <a className="c14" href="https://dlocalgo.com/en/legal/cookies-policies">
                  HERE
                </a>
              </span>
              <span className="c0">&nbsp;for further details.</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">SmartFields and Redirect solutions.</span>
              <span className="c0">
                &nbsp;When you purchase goods or services from dLocal&rsquo;s clients which have chosen our Smartfield and
                Redirect solutions for collecting payments, it is most likely you will be giving your Personal Data directly
                to dLocal.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Due Diligence.</span>
              <span className="c5">
                &nbsp;When you apply to become a business customer of dLocal, we require that you provide personal data as
                detailed in this paragraph but not limited to this description. For regulatory reasons, we may request name,
                postal address, telephone number, and email address to{' '}
              </span>
              <span className="c11">fulfill</span>
              <span className="c5">
                &nbsp;our financial partner and regulatory requirements. We may also collect financial and personal
                information about you, such as your ownership interest in the company, your status{' '}
              </span>
              <span className="c11">as</span>
              <span className="c11">&nbsp;</span>
              <span className="c0">
                director or officer, your date of birth and government identifiers associated with you and your business
                (such as your social security number, tax number, or Employer Identification Number). We may also require
                bank account information.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Third parties or publicly available sources.</span>
              <span className="c0">
                &nbsp;We may receive Personal Data about you from various third parties and public sources, when we perform
                background checks, fraud prevention checks, ID checks and other &ldquo;Know Your Customer&rdquo; we need to
                perform on our Customer&rsquo;s representatives to comply with applicable financial services standards and
                requirements and to comply with applicable laws and regulations.
              </span>
            </li>
          </ol>
          <p className="c4">
            <span className="c0"></span>
          </p>
          <p className="c6">
            <span className="c0">
              We may collect, use, store and transfer different kinds of data about you which we have grouped together as
              follows:
            </span>
          </p>
          <ol className="c2 lst-kix_list_2-0 start" start={1}>
            <li className="c15 li-bullet-0">
              <span className="c11">Identity Data</span>
              <span className="c0">&nbsp;includes first name, last name, username, ID document number;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Contact Data</span>
              <span className="c0">
                &nbsp;includes contact details billing address, delivery address, email address and telephone numbers;
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Technical Data</span>
              <span className="c0">
                &nbsp;includes your internet protocol (IP) address, your login data, Google Analytics ID, internet browser
                and device type, time zone setting, location data and your use of our website, including which pages you
                visited, how you got to our Website, the time and length of your visit and your language preferences;
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Customer&rsquo;s Representatives user data</span>
              <span className="c5">&nbsp;usage data </span>
              <span className="c5">is </span>
              <span className="c0">
                generated by our Customer&rsquo;s representatives when they access our platform or when they interact with us
                by email or otherwise.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Profile Data</span>
              <span className="c0">&nbsp;includes the username and password of our Customer&rsquo;s representatives;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Marketing and Communications Data&nbsp;</span>
              <span className="c0">
                your name, position and business details and includes your preferences in receiving marketing from us and our
                third parties and your communication preferences; and
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Financial Data&nbsp;</span>
              <span className="c0">includes card data, bank account data, fiscal information;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Special category of personal data</span>
              <span className="c0">
                &nbsp;such as biometric data. There are circumstances in that we might collect special personal data; for
                example, to confirm the identity of our clients.
              </span>
            </li>
          </ol>
          <p className="c4">
            <span className="c0"></span>
          </p>
          <p className="c6">
            <span className="c5">We also collect, use and share&nbsp;</span>
            <span className="c11">Aggregated Data</span>
            <span className="c5">
              &nbsp;such as statistical or demographic data for any purpose. Aggregated Data could be derived from your
              Personal Data but is not considered Personal Data in law as this data will&nbsp;
            </span>
            <span className="c11">not</span>
            <span className="c0">
              &nbsp;directly or indirectly reveal your identity. For example, we may aggregate the Technical Data to
              calculate the percentage of users accessing a specific website feature.
            </span>
          </p>
          <p className="c9">
            <span className="c5">We may collect information about criminal convictions and </span>
            <span className="c5">offenses</span>
            <span className="c0">
              &nbsp;but only in the context of fraud or security checks when this is necessary to comply with applicable laws
              or with any applicable financial services standards or requirements.
            </span>
          </p>
          <p className="c9">
            <span className="c11">If you fail to provide Personal Data</span>
          </p>
          <p className="c9">
            <span className="c0">
              Where we need to collect Personal Data by law, or under the terms of a contract we have with you, and you fail
              to provide that data when requested, we may not be able to perform the contract we have or are trying to enter
              with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or
              service you have with us, but we will notify you if this is the case at the time.
            </span>
          </p>
          <ol className="c2 lst-kix_3b3h11ndmcln-0" start={4}>
            <li className="c10 li-bullet-0">
              <span className="c7">How we use personal data</span>
            </li>
          </ol>
          <p className="c4">
            <span className="c0"></span>
          </p>
          <p className="c6">
            <span className="c5">We may process your Personal Data for one or more lawful bases of processing (</span>
            <span className="c11">&ldquo;Lawful Basis&rdquo;</span>
            <span className="c0">) depending on the specific purpose for which we are using your data (see below).</span>
          </p>
          <p className="c9">
            <span className="c0">In accordance with this Privacy Notice dLocal may use your Personal Data in order to:</span>
          </p>
          <ol className="c2 lst-kix_list_3-0 start" start={1}>
            <li className="c15 li-bullet-0">
              <span className="c5">provide the services you request from us&nbsp;</span>
              <span className="c1">
                (Lawful Basis: to comply with our legal obligations and performance of our contract with you)
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c5">verify your identity or conduct appropriate checks for </span>
              <span className="c11">creditworthiness</span>
              <span className="c5">&nbsp;or fraud&nbsp;</span>
              <span className="c1">
                (Lawful Basis: to comply with our legal obligations and necessary for our legitimate interests)
              </span>
              <span className="c0">;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c5">
                understand your needs in order to provide you with the products and services you require&nbsp;
              </span>
              <span className="c1">(Lawful Basis: performance of our contract with you)</span>
              <span className="c0">;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c5">
                administer and manage our services, including billing for the services provided and debt collection&nbsp;
              </span>
              <span className="c1">
                (Lawful Basis: performance of our contract with you, to comply with our legal obligations and necessary for
                our legitimate interests)
              </span>
              <span className="c0">;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c5">
                distribute information, newsletters, publications and other communication via various mediums to keep you
                informed&nbsp;
              </span>
              <span className="c1">
                (Lawful Basis: your consent, performance of our contract with you and necessary for our legitimate interests)
              </span>
              <span className="c0">;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c5">research and develop new product offerings and services&nbsp;</span>
              <span className="c1">
                (Lawful Basis: performance of our contract with you and necessary for our legitimate interests)
              </span>
              <span className="c0">;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c5">manage and conduct our business and the services we provide to our Customers&nbsp;</span>
              <span className="c1">
                (Lawful Basis: performance of our contract with you and necessary for our legitimate interests)
              </span>
              <span className="c0">;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c5">
                make sure our website works properly, including debugging, to be able to deliver you its content, for DDOS
                mitigation on our website, and improving our website and performing statistical analyses&nbsp;
              </span>
              <span className="c1">(Lawful Basis: necessary for our legitimate interests)</span>
              <span className="c0">;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c5">provide you with personal offers tailored to your needs and </span>
              <span className="c11">customize</span>
              <span className="c5">&nbsp;what we show you to your preferences, with your prior consent&nbsp;</span>
              <span className="c1">
                (Lawful Basis: performance of our contract with you and necessary for our legitimate interests)
              </span>
              <span className="c0">;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c5">effectively communicate with third parties&nbsp;</span>
              <span className="c1">
                (Lawful Basis: your consent, performance of our contract with you and necessary for our legitimate interests)
              </span>
              <span className="c0">; and</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c5">as required or </span>
              <span className="c5">authorized</span>
              <span className="c5">&nbsp;by applicable law&nbsp;</span>
              <span className="c1">(Lawful Basis: to comply with our legal obligations)</span>
              <span className="c0">.</span>
            </li>
          </ol>
          <ol className="c2 lst-kix_3b3h11ndmcln-0" start={5}>
            <li className="c10 li-bullet-0">
              <span className="c7">When we may disclose the personal data</span>
            </li>
          </ol>
          <p className="c4">
            <span className="c0"></span>
          </p>
          <p className="c6">
            <span className="c0">
              Your information may, for the purposes set out in this Privacy Notice, be disclosed for processing to:
            </span>
          </p>
          <ol className="c2 lst-kix_list_4-0 start" start={1}>
            <li className="c15 li-bullet-0">
              <span className="c0">
                Our employees, our affiliates and their employees. For instance, dLocal will share your information with
                other Dlocal affiliates for the purpose of the provision of our services or when such affiliates provide
                support services to dLocal;
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c0">
                Our third-party consultants, (sub-)contractors, suppliers or other service providers who may access your
                personal information when providing services to us (including but not limited to IT support services) (This
                includes information technology experts who design and host our Website, and general service companies);
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c0">
                Auditors or contractors or other advisers auditing, assisting with or advising on any of our business
                purposes;
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c5">Analytics and search engine providers that assist us in the improvement and </span>
              <span className="c5">optimization</span>
              <span className="c0">&nbsp;of our Website;</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c0">
                Our successors in title, our prospective sellers or buyers of our business or our Affiliates when we have a
                merger or re-organisation;
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c0">
                Government bodies and law enforcement agencies and in response to other legal and regulatory requests;
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c0">
                Any third-party where such disclosure is required in order to enforce or apply our Website Terms or other
                relevant agreements; and
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c5">
                Protect the rights, property, integrity or security of our company, our customers, or others (including,
                without limitation, you). This includes exchanging information with other companies and{' '}
              </span>
              <span className="c11">organizations</span>
              <span className="c0">&nbsp;for the purposes of fraud protection and credit risk reduction.</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c0">
                Where your details are provided to any other party in accordance with an express purpose, we will require
                them to be kept safe and secure your Personal Data and only use it for the intended purpose.
              </span>
            </li>
          </ol>
          <ol className="c2 lst-kix_3b3h11ndmcln-0" start={6}>
            <li className="c10 li-bullet-0">
              <span className="c7">International transfers</span>
            </li>
          </ol>
          <p className="c4">
            <span className="c0"></span>
          </p>
          <p className="c6">
            <span className="c0">
              dLocal serves customers globally. Accordingly, your Personal Data may be shared with other Dlocal affiliates
              outside of the UK, when this is necessary for the purposes mentioned in this Privacy Notice. These countries
              include the countries in which we have operations. It also includes the countries in which some of our service
              providers are located.
            </span>
          </p>
          <p className="c9">
            <span className="c5">
              To protect your Personal Data when these are transferred to countries outside of the UK, we have implemented
              appropriate safeguards. The transfer of Personal Data from the UK to non-adequate countries is protected by
              adequate safeguards such as the UK approved Standard Contractual Clauses.
            </span>
          </p>
          <ol className="c2 lst-kix_3b3h11ndmcln-0" start={7}>
            <li className="c10 li-bullet-0">
              <span className="c7">What happens if you don&rsquo;t provide the requested personal data</span>
            </li>
          </ol>
          <p className="c4">
            <span className="c0"></span>
          </p>
          <p className="c6">
            <span className="c5">
              Where possible and practical, you may have the option to deal with dLocal on an anonymous basis or by using a
              pseudonym. However, in some circumstances, if we are unable to collect Personal Data from or about you, or if
              the Personal Data provided is incomplete or inaccurate, dLocal may not be able to assist you, including
              providing the products or services you are seeking or provide support or assist you with your queries.
            </span>
          </p>
          <ol className="c2 lst-kix_3b3h11ndmcln-0" start={8}>
            <li className="c10 li-bullet-0">
              <span className="c7">Security of personal data</span>
            </li>
          </ol>
          <p className="c4">
            <span className="c0"></span>
          </p>
          <p className="c6">
            <span className="c5">
              The Internet is not a secure medium. However, we have put in place a range of security procedures, as set out
              in this Privacy Notice. Where you have been allocated an account, this area is protected by your{' '}
            </span>
            <span className="c5">username</span>
            <span className="c0">&nbsp;and password, which you should never divulge to anyone else.</span>
          </p>
          <p className="c9">
            <span className="c5">
              Please be aware that communications over the Internet, such as emails/webmails, are not secure unless they have
              been encrypted. Your communications may route through a number of countries before being delivered. This is the
              nature of the World Wide Web/Internet. We cannot accept responsibility for any{' '}
            </span>
            <span className="c5">unauthorized</span>
            <span className="c0">&nbsp;access or loss of personal information that is beyond our control.</span>
          </p>
          <p className="c9">
            <span className="c5">We will use reasonable </span>
            <span className="c5">endeavors</span>
            <span className="c5">
              &nbsp;to implement appropriate policies, rules and technical measures to protect the Personal Data that we have
              under our control (having regard to the type and amount of that data) from{' '}
            </span>
            <span className="c5">unauthorized</span>
            <span className="c5">&nbsp;access, improper use or disclosure, </span>
            <span className="c5">unauthorized</span>
            <span className="c0">&nbsp;modification, unlawful destruction or accidental loss.</span>
          </p>
          <p className="c9">
            <span className="c5">
              We will ensure that your information will not be disclosed to government institutions or authorities except if
              required by law (e.g. when requested by regulatory bodies or law enforcement{' '}
            </span>
            <span className="c5">organizations</span>
            <span className="c5">&nbsp;in accordance with applicable legislation).</span>
          </p>
          <ol className="c2 lst-kix_3b3h11ndmcln-0" start={9}>
            <li className="c10 li-bullet-0">
              <span className="c7">Cookies</span>
            </li>
          </ol>
          <p className="c4">
            <span className="c7"></span>
          </p>
          <p className="c6">
            <span className="c5">
              dLocal uses cookies to store and collect information about your use of our Website. Cookies are small text
              files stored by the browser on your equipment&rsquo;s hard drive. They send information stored on them back to
              our web server when you access our Website. These cookies enable us to put in place personal settings and load
              your personal preferences to improve your experience. You can find out more about cookies at&nbsp;
            </span>
            <span className="c5 c18">
              <a className="c14" href="https://www.allaboutcookies.org/">
                www.allaboutcookies.org
              </a>
            </span>
            <span className="c5">
              , and more about the cookies we use on our &ldquo;Cookies Policy&rdquo; available on our Website at&nbsp;
            </span>
            <span className="c5 c23">
              <a className="c14" href="https://dlocalgo.com/en/legal/cookies-policies">
                HERE
              </a>
            </span>
            <span className="c5">.</span>
          </p>
          <ol className="c2 lst-kix_3b3h11ndmcln-0" start={10}>
            <li className="c10 li-bullet-0">
              <span className="c7">Your rights</span>
            </li>
          </ol>
          <p className="c4">
            <span className="c0"></span>
          </p>
          <p className="c6">
            <span className="c0">
              We will take all reasonable steps to ensure that all information we collect, use, or disclose is accurate,
              complete and up to date. Please contact us if your details change or if you believe the information we have
              about you is not accurate or complete.
            </span>
          </p>
          <p className="c9">
            <span className="c5">In some instances, you may also have the rights to:</span>
          </p>
          <ol className="c2 lst-kix_list_5-0 start" start={1}>
            <li className="c15 li-bullet-0">
              <span className="c11">Request access</span>
              <span className="c0">
                &nbsp;to your Personal Data (commonly known as a &ldquo;data subject access request&rdquo;). This enables you
                to receive a copy of the Personal Data we hold about you and to check that we are lawfully processing it.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Request correction</span>
              <span className="c0">
                &nbsp;of the Personal Data that we hold about you. This enables you to have any incomplete or inaccurate data
                we hold about you corrected, though we may need to verify the accuracy of the new information you provide to
                us.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Request erasure of your Personal Data.</span>
              <span className="c0">
                &nbsp;This enables you to ask us to delete or remove Personal Data where there is no good reason for us
                continuing to process it. Note, however, that we may not always be able to comply with your request of
                erasure for specific legal reasons which will be notified to you.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Object to processing</span>
              <span className="c5">
                &nbsp;of your Personal Data where we are relying on a legitimate interest (or those of a third party) and
                there is something about your particular situation which makes you want to object to processing on this
                ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object
                where we are processing your Personal Data for direct marketing purposes. In some cases, we may demonstrate
                that we have compelling legitimate grounds to process your information which do not override your rights{' '}
              </span>
              <span className="c5">and freedoms.</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Request restriction of processing</span>
              <span className="c0">
                &nbsp;of your Personal Data. This enables you to ask us to suspend the processing of your Personal Data in
                the following scenarios: if you want us to establish the data&rsquo;s accuracy; where our use of the data is
                unlawful but you do not want us to erase it; where you need us to hold the data even if we no longer require
                it as you need it to establish, exercise or defend legal claims; or You have objected to our use of your data
                but we need to verify whether we have overriding legitimate grounds to use it.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Request the transfer of your Personal Data</span>
              <span className="c0">
                &nbsp;to you or to a third party. We will provide to you, or a third party you have chosen, your Personal
                Data in a structured, commonly used, machine-readable format. Note that this right only applies to automated
                information which you initially provided consent for us to use or where we used the information to perform a
                contract with you.
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c11">Withdraw consent at any time</span>
              <span className="c0">
                &nbsp;where we are relying on consent to process your Personal Data. However, this will not affect the
                lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we
                may not be able to provide certain products or services to you. We will advise you if this is the case.
              </span>
            </li>
          </ol>
          <p className="c4">
            <span className="c24 c11"></span>
          </p>
          <p className="c6">
            <span className="c11">How to exercise your rights.</span>
          </p>
          <p className="c9">
            <span className="c5">Please click on this&nbsp;</span>
            <span className="c18 c5 c13">
              <a className="c14" href="https://omnitrack.vinciworks.com/dlocal/forms/assigned/839b5347-1ed6-4941-9338-2ecf897d448d" target="_blank">
                LINK
              </a>
            </span>
            <span className="c5 c13">&nbsp;</span>
            <span className="c0">
              and fill out the form to submit your request. This is the exclusive channel to submit a request and exercise
              your rights. Another way to submit a request will not be answered.
            </span>
          </p>
          <p className="c9">
            <span className="c11">What we may require from you.</span>
          </p>
          <p className="c9">
            <span className="c0">
              We may need to request specific information from you to help us confirm your identity. We may also contact you
              to ask for further information in relation to your request.
            </span>
          </p>
          <p className="c9">
            <span className="c11">Time limit to respond.</span>
          </p>
          <p className="c9">
            <span className="c5">
              We try to respond to all legitimate requests within one month. Occasionally it may take us longer{' '}
            </span>
            <span className="c5">than</span>
            <span className="c0">
              &nbsp;a month if your request is particularly complex, or you have made several requests. In this case, we will
              notify you and keep you updated.
            </span>
          </p>
          <p className="c9">
            <span className="c11">No fee usually required.</span>
          </p>
          <p className="c9">
            <span className="c5">
              All communication and all actions taken by dLocal regarding your rights described above are provided free of
              charge. dLocal reserves the right, in the case of clearly unfounded or unreasonable requests, to either take
              out a reasonable fee covering the administrative costs of providing the information or taking the requested
              action or refusing to
            </span>
            <span className="c11">&nbsp;</span>
            <span className="c11">fulfill</span>
            <span className="c11">&nbsp;</span>
            <span className="c0">the requested action.</span>
          </p>
          <ol className="c2 lst-kix_3b3h11ndmcln-0" start={11}>
            <li className="c10 li-bullet-0">
              <span className="c11 c19">How long we keep personal data</span>
            </li>
          </ol>
          <p className="c4">
            <span className="c0"></span>
          </p>
          <p className="c6">
            <span className="c5">
              We will only retain your Personal Data for as long as you have consented to it or when is necessary to us to
              provide you with our services or{' '}
            </span>
            <span className="c5">fulfill</span>
            <span className="c0">
              &nbsp;the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or
              reporting requirements.
            </span>
          </p>
          <p className="c9">
            <span className="c0">
              We will take reasonable steps to destroy or permanently de-identify Personal Data that is no longer needed for
              any purpose that is permitted by Data Protection Legislation.
            </span>
          </p>
          <p className="c9">
            <span className="c5">
              For instance, by law we have to keep basic information about our customers (including contact, identity,
              financial and transaction data). To determine the appropriate retention period for Personal Data, we consider
              the amount, nature, and sensitivity of the Personal Data, the potential risk of harm from{' '}
            </span>
            <span className="c11">unauthorized</span>
            <span className="c5">
              &nbsp;use or disclosure of Your Personal Data, the purposes for which we process your Personal Data and whether
              we can achieve those purposes through other means, and the applicable legal requirements.
            </span>
          </p>
          <ol className="c2 lst-kix_3b3h11ndmcln-0" start={12}>
            <li className="c10 li-bullet-0">
              <span className="c7">Changes</span>
            </li>
          </ol>
          <p className="c4">
            <span className="c0"></span>
          </p>
          <p className="c6">
            <span className="c5">
              We reserve the right to amend or edit this Privacy Notice from time to time at our discretion, such as to
              reflect changes in dLocal&rsquo;s business or practices. We may change the Privacy Notice at any time by
              posting the changed Privacy Notice on the dLocal website, including posting a notice on the dLocal{' '}
            </span>
            <span className="c11">website</span>
            <span className="c0">&nbsp;homepage indicating a change has been made.</span>
          </p>
          <p className="c17">
            <span className="c0"></span>
          </p>
        </div>
      </div>
    </div>
  );
};
