import { Redirect, useHistory } from 'react-router-dom';
import { useSubscriptions } from '../../../../../store/subscriptions';
import './styles.scss';
import { useEffect, useState } from 'react';
import { Routes } from '../../../../../config/routes';
import TopDashboardSection from '../../../../common/top-dashboard-section';
import ButtonLink from '../../../../common/button-link';
import l from '../../../../../lang';
import { ReactComponent as BackIcon } from '../../../../../assets/icons/back.svg';
import Card from '../../../../common/card';
import { useMediaQuery } from '@mui/material';
import { Button } from '../../../../common/button';
import { Close, Edit } from '@mui/icons-material';
import Moment from 'react-moment';
import { ReactComponent as UserIcon } from '../../../../../assets/icons/user-circle.svg';
import { createDate } from '../../../../../utils';
import { Urls } from '../../../../../config/urls';
import { ReactComponent as AllCountriesIcon } from '../../../../../assets/icons/all-countries.svg';
import Table from '../../../../common/table';
import { ReactComponent as EmptyIcon } from '../../../../../assets/icons/empty-subscriptions.svg';
import DialogSubscriptionCancel from '../../dialog-cancel';
import Loading from '../../../../common/loading';
import MessageError from '../../../../common/message-error';
import PlanCreatedDialog from '../create-edit/plan-created-dialog';
import ShareIcon from '@mui/icons-material/Share';

const PlanDetails = () => {
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const history = useHistory();
  const {
    planDetails: data,
    loading,
    setPlanDetails,
    setDeactivatePlan,
    error,
    errorInfo,
    deactivatePlan,
  } = useSubscriptions();
  const [showCancel, setShowCancel] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);

  useEffect(() => {
    if (!data) history.push(Routes.SUBSCRIPTIONS_PLANS);
  }, []);

  const backToSubscriptions = () => {
    setPlanDetails(null);
    history.push(Routes.SUBSCRIPTIONS_PLANS);
  };

  const headerButtons = (planId: string, fullWidth: boolean) => (
    <div className="plan_details__header__buttons">
      {data?.active && (
        <>
          <div>
            <Button
              startIcon={<Edit />}
              variant="outlined"
              textTransformNone
              fullWidth={fullWidth}
              onClick={() => history.push(Routes.SUBSCRIPTIONS_PLANS_EDIT.replace(':id', planId))}
            >
              {l('subscriptions.plans.details.edit')}
            </Button>
          </div>
          <div className="plan_details__header__buttons__cancel">
            <Button
              startIcon={<Close />}
              variant="outlined"
              textTransformNone
              color="error"
              fullWidth={fullWidth}
              onClick={() => setShowCancel(true)}
            >
              {l('subscriptions.plans.details.cancel')}
            </Button>
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      <TopDashboardSection
        title={l('subscriptions.plans.details')}
        componentBottom={
          <div className="plan_details__back_btn">
            <ButtonLink
              type="secondary"
              text={
                <>
                  <BackIcon /> {l('subscriptions.plans.details.backBtn')}
                </>
              }
              onClick={backToSubscriptions}
            />
          </div>
        }
      />
      {deactivatePlan?.error && <MessageError errorInfo={deactivatePlan.errorInfo} />}
      {deactivatePlan?.loading && <Loading fullScreen />}
      <div className="plan_details">
        <Card>
          <div className="plan_details__card_section">
            <div className="plan_details__header">
              <div className="plan_details__header__plan">
                <div className="plan_details__header__plan__title">
                  <div>{data?.name}</div>
                  {data?.active ? (
                    <div className="plans__box__status_active">{l('subscriptions.plans.active')}</div>
                  ) : (
                    <div className="plans__box__status_inactive">{l('subscriptions.plans.inactive')}</div>
                  )}
                </div>
                <div className="plan_details__header__plan_desc">{data?.description}</div>
              </div>
              {isDesktop && headerButtons(`${data?.id || ''}`, false)}
            </div>
            <div className="plan_details__divider" />

            <div className="plan_details__box_data">
              <div className="plan_details__box_data__title">{l('subscriptions.plans.details.date')}</div>
              <div className="plan_details__box_data__value">
                {data?.createdAt ? (
                  <>
                    <Moment format="DD/MM/YYYY">{createDate(data?.createdAt)}</Moment>
                    <span>,&nbsp;</span>
                    <Moment format="hh:mm:ss a">{createDate(data?.createdAt)}</Moment>
                  </>
                ) : (
                  '-'
                )}
              </div>
            </div>
            <div className="plan_details__box_data subscription_details__box_data__border_left">
              <div className="plan_details__box_data__title">{l('subscriptions.plans.amount')}</div>
              <div className="plan_details__box_data__value">
                {data?.currency} {data?.amount}
              </div>
            </div>
            <div className="plan_details__box_data subscription_details__box_data__border_left">
              <div className="plan_details__box_data__title">{l('subscriptions.plans.country')}</div>
              <div className="plan_details__box_data__value">
                {data?.country ? (
                  <span className="plan_details__country">
                    <img
                      alt=""
                      className="plan_details__country_flag"
                      src={`${Urls.flags.replace(':code', data.country)}`}
                    />
                    <span>&nbsp;{data.country}</span>
                  </span>
                ) : (
                  <span className="plan_details__country">
                    <AllCountriesIcon height={18} width={18} />
                    <span>&nbsp;{l('subscriptions.allCountries')}</span>
                  </span>
                )}
              </div>
            </div>
            <div className="plan_details__box_data subscription_details__box_data__border_left">
              <div className="plan_details__box_data__title">{l('subscriptions.plans.details.paymentFrequency')}</div>
              <div className="plan_details__box_data__value">
                {data?.frequencyValue}&nbsp;
                {l(`subscriptions.frequency.${data?.frequencyType}${(data?.frequencyValue || 0) > 1 ? '.plural' : ''}`)}
              </div>
            </div>
            {!isDesktop && headerButtons(`${data?.id || ''}`, true)}
          </div>
          <div className="plan_details__card_section">
            <div>
              <div className="plan_details__title_section">
                <UserIcon className="transaction_detail__title_section__icon" />
                {l('subscriptions.plans.details.subscribers')}
                {data?.active && (
                  <div className="plan_details__title_button">
                    <Button
                      startIcon={isDesktop && <ShareIcon />}
                      variant="outlined"
                      textTransformNone
                      color="primary"
                      fullWidth={false}
                      onClick={() => setShowShareDialog(true)}
                    >
                      {isDesktop ? l('subscriptions.plans.details.share') : <ShareIcon />}
                    </Button>
                  </div>
                )}
              </div>
              <div>
                <Table
                  dense
                  emptyBody={
                    <div className="plan_details__subscriptions__no_data">
                      <EmptyIcon />
                      <div className="plan_details__subscriptions__no_data__title">
                        {l('subscriptions.emptySubscriptions.title')}
                      </div>
                      <div>{l('subscriptions.emptySubscriptions.body')}</div>
                    </div>
                  }
                  headers={[
                    <div className="plan_details__subscriptions__left">{l('subscriptions.clientName')}</div>,
                    <div className="plan_details__subscriptions__left">{l('subscriptions.clientDocument')}</div>,
                    <div className="plan_details__subscriptions__left">{l('subscriptions.clientEmail')}</div>,
                    <div className="plan_details__subscriptions__left">{l('subscriptions.status')}</div>,
                  ]}
                  body={
                    (data?.subscriptions?.length &&
                      data.subscriptions.map(sub => {
                        return [
                          <div className="plan_details__subscriptions__left">
                            {(sub.clientFirstName && sub.clientLastName && (
                              <span>
                                {sub.clientFirstName} {sub.clientLastName}
                              </span>
                            )) ||
                              '-'}
                          </div>,
                          <div className="plan_details__subscriptions__left">
                            {(sub.clientDocumentType && sub.clientDocument && (
                              <span>
                                {sub.clientDocumentType} {sub.clientDocument}
                              </span>
                            )) ||
                              '-'}
                          </div>,
                          <div className="plan_details__subscriptions__left">{sub.clientEmail || '-'}</div>,
                          <div className="plan_details__subscriptions__left">
                            {sub.active ? (
                              <span className="subscriptors__status_active">{l('subscriptions.active')}</span>
                            ) : (
                              <span className="subscriptors__status_inactive">{l('subscriptions.inactive')}</span>
                            )}
                          </div>,
                        ];
                      })) ||
                    null
                  }
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
      {showCancel && (
        <DialogSubscriptionCancel
          cancelType="PLAN"
          onClose={confirm => {
            setShowCancel(false);
            if (confirm && data) setDeactivatePlan(data.id);
          }}
        />
      )}
      {showShareDialog && (
        <PlanCreatedDialog
          url={data?.url as string}
          name={data?.name as string}
          frequencyType={data?.frequencyType as string}
          frequencyValue={data?.frequencyValue as number}
          country={data?.country as string}
          currency={data?.currency as string}
          amount={data?.amount as number}
          description={data?.description as string}
          onClose={() => {
            setShowShareDialog(false);
          }}
        />
      )}
      {deactivatePlan && deactivatePlan.deactivated && <Redirect to={Routes.SUBSCRIPTIONS_PLANS} />}
    </>
  );
};

export default PlanDetails;
