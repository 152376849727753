import { ExportType, Filters } from '../interfaces';
import { Transaction } from './interfaces';

export enum TransacionesActionType {
  GET_TRANSACTIONS = 'TRANSACTIONS/GET_TRANSACTIONS',
  GET_TRANSACTIONS_SUCCESS = 'TRANSACTIONS/GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_ERROR = 'TRANSACTIONS/GET_TRANSACTIONS_ERROR',
  GET_PAYMENT_METHODS_FOR_TRANSACTIONS = 'TRANSACTIONS/GET_PAYMENT_METHODS_FOR_TRANSACTIONS',
  GET_PAYMENT_METHODS_FOR_TRANSACTIONS_SUCCESS = 'TRANSACTIONS/GET_PAYMENT_METHODS_FOR_TRANSACTIONS_SUCCESS',
  GET_PAYMENT_METHODS_FOR_TRANSACTIONS_ERROR = 'TRANSACTIONS/GET_PAYMENT_METHODS_FOR_TRANSACTIONS_ERROR',
  SHOW_EXPORT_TRANSACTIONS = 'TRANSACTIONS/SHOW_EXPORT_TRANSACTIONS',
  EXPORT_TRANSACTIONS = 'TRANSACTIONS/EXPORT_TRANSACTIONS',
  EXPORT_TRANSACTIONS_SUCCESS = 'TRANSACTIONS/EXPORT_TRANSACTIONS_SUCCESS',
  EXPORT_TRANSACTIONS_ERROR = 'TRANSACTIONS/EXPORT_TRANSACTIONS_ERROR',
  SET_TRANSACTION_DETAIL = 'TRANSACTIONS/SET_TRANSACTION_DETAIL',
  GET_TRANSACTION_DETAIL = 'TRANSACTIONS/GET_TRANSACTION_DETAIL',
  GET_TRANSACTION_DETAIL_SUCCESS = 'TRANSACTIONS/GET_TRANSACTION_DETAIL_SUCCESS',
  GET_TRANSACTION_DETAIL_ERROR = 'TRANSACTIONS/GET_TRANSACTION_DETAIL_ERROR',
  GET_PRE_CHARGEBACK_FORM = 'TRANSACTIONS/GET_PRE_CHARGEBACK_FORM',
  GET_PRE_CHARGEBACK_FORM_SUCCESS = 'TRANSACTIONS/GET_PRE_CHARGEBACK_FORM_SUCCESS',
  GET_PRE_CHARGEBACK_FORM_ERROR = 'TRANSACTIONS/GET_PRE_CHARGEBACK_FORM_ERROR',
}

export const getTransactions = (page?: number, filter?: Filters) => ({
  type: TransacionesActionType.GET_TRANSACTIONS,
  page,
  filter,
});

export const getTransactionsSuccess = (response: any) => ({
  type: TransacionesActionType.GET_TRANSACTIONS_SUCCESS,
  response,
});

export const getTransactionsError = (error: any) => ({
  type: TransacionesActionType.GET_TRANSACTIONS_ERROR,
  error,
});

export const getPaymentMethodsForTrans = (country: string) => ({
  type: TransacionesActionType.GET_PAYMENT_METHODS_FOR_TRANSACTIONS,
  country,
});

export const getPaymentMethodsForTransSuccess = (response: any) => ({
  type: TransacionesActionType.GET_PAYMENT_METHODS_FOR_TRANSACTIONS_SUCCESS,
  response,
});

export const getPaymentMethodsForTransError = (error: any) => ({
  type: TransacionesActionType.GET_PAYMENT_METHODS_FOR_TRANSACTIONS_ERROR,
  error,
});

export const showExportTransactions = (show: boolean) => ({
  type: TransacionesActionType.SHOW_EXPORT_TRANSACTIONS,
  show,
});

export const exportTransactions = (filter: string, type: ExportType) => ({
  type: TransacionesActionType.EXPORT_TRANSACTIONS,
  filter,
  exportType: type,
});

export const exportTransactionsSuccess = (response: any) => ({
  type: TransacionesActionType.EXPORT_TRANSACTIONS_SUCCESS,
  response,
});

export const exportTransactionsError = (error: any) => ({
  type: TransacionesActionType.EXPORT_TRANSACTIONS_ERROR,
  error,
});

export const setTransactionDetail = (data: Transaction) => ({
  type: TransacionesActionType.SET_TRANSACTION_DETAIL,
  data,
});

export const getTransactionDetail = (invoiceId: string) => ({
  type: TransacionesActionType.GET_TRANSACTION_DETAIL,
  invoiceId,
});

export const getTransactionDetailSuccess = (response: any) => ({
  type: TransacionesActionType.GET_TRANSACTION_DETAIL_SUCCESS,
  response,
});

export const getTransactionDetailError = (error: any) => ({
  type: TransacionesActionType.GET_TRANSACTION_DETAIL_ERROR,
  error,
});

export const getPreChargebackForm = (invoiceId: string) => ({
  type: TransacionesActionType.GET_PRE_CHARGEBACK_FORM,
  invoiceId,
});

export const getPreChargebackFormSuccess = (response: any) => ({
  type: TransacionesActionType.GET_PRE_CHARGEBACK_FORM_SUCCESS,
  response,
});

export const getPreChargebackFormError = (error: any) => ({
  type: TransacionesActionType.GET_PRE_CHARGEBACK_FORM_ERROR,
  error,
});
