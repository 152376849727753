import { Card } from '@mui/material';
import { FunctionComponent } from 'react';
import './styles.scss';

interface DataCardProps {
  title: string;
  icon: JSX.Element;
  color: 'blue' | 'green' | 'violet' | 'orange' | 'red' | 'magenta';
  detailsText: string;
  onDetails?: () => void;
}

const DataCard: FunctionComponent<DataCardProps> = ({ title, icon, color, detailsText, onDetails }) => (
  <Card
    onClick={onDetails}
    classes={{ root: `integration_card ${color} ${onDetails ? 'enabled' : 'disabled'}` }}
    elevation={0}
  >
    <div className="integration_card__header">
      <div className="integration_card__title">{title}</div>
      {icon}
    </div>
    <div className="integration_card__link">
      <div className={onDetails ? 'enabled' : 'disabled'}>{detailsText}</div>
    </div>
  </Card>
);

export default DataCard;
