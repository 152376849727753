import { useDispatch, useSelector } from 'react-redux';
import { getBalances, putShouldAnimationsRun } from './actions';
import { HomeSelectors } from './selectors';

export const useHome = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(HomeSelectors.getLoading),
    error: useSelector(HomeSelectors.getError),
    balances: useSelector(HomeSelectors.getBalances),
    shouldAnimationsRun: useSelector(HomeSelectors.getShouldAnimationsRun),
    getBalances: () => dispatch(getBalances()),
    putShouldAnimationsRun: (shouldRun: boolean) => dispatch(putShouldAnimationsRun(shouldRun)),
  };
};
