import { useEffect } from 'react';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import './styles.scss';
import { ApiCard } from '../integration-api';
import { WooCard } from '../integration-woo';
import { MagentoCard } from '../integration-magento';
import { ShopifyCard } from '../integration-shopify';
import { PrestashopCard } from '../integration-prestashop';
import { HostingerCard } from '../integration-hostinger';

export const IntegrationCards = () => {
  const { getIntegrationKeys, apiKey } = usePaymentIntegration();

  useEffect(() => {
    if (apiKey.length === 0) {
      getIntegrationKeys();
    }
  }, []);

  return (
    <div className="integration_cards">
      <div className="row">
        <ApiCard />
        <ShopifyCard />
        <HostingerCard />
      </div>
      <div className="row">
        <WooCard />
        <MagentoCard />
        <PrestashopCard />
      </div>
    </div>
  );
};
