import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Parser from 'html-react-parser';
import { Cancel as CancelIcon, Close as IconClose } from '@mui/icons-material';
import Bugsnag from '@bugsnag/js';
import l from '../../../lang';
import ButtonCustom from '../__deprecated__/button-custom';
import 'animate.css/animate.min.css';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    textAlign: 'center',
    padding: '15px 20px',
    margin: '10px auto',
    background: '#ffffff',
    border: '1px dashed #684d94',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 8px rgba(210, 210, 210, 0.5)',
    borderRadius: '5px',
    [theme.breakpoints.up(768)]: {
      width: 350,
    },
  },
  iconCloseButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  previewContainer: {
    maxHeight: '0px',
    transition: 'max-height .5s ease-in-out',
    '&.show': {
      margin: '20px auto',
      maxHeight: '650px',
    },
  },
  previewImg: {
    objectFit: 'cover',
    width: '300px',
    maxWidth: '100%',
    maxHeight: '200px',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  desc: {
    textAlign: 'center',
    fontSize: 14,
    marginBottom: 10,
  },
  messages: {
    marginTop: '10px',
    fontSize: 14,
    '& .error': {
      color: 'red',
    },
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconRemove: {
    display: 'flex',
    alignSelf: 'start',
    cursor: 'pointer',
    marginBottom: -4,
    marginLeft: 4,
    color: '#684d94',
  },
}));

const UploadFileWithPreview = ({
  description,
  supportedExtensions,
  maxSize,
  onChange,
  onClose,
  disabled,
  preview = false,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const toBase64 = f =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = err => reject(err);
    });

  const handleOnChange = async files => {
    setLoading(true);
    setError('');
    setFile(null);
    setFileUrl(null);
    onChange(null);

    const f = files[0];
    if (f) {
      if (f.size && f.size / 1e6 > maxSize) {
        setError(l('uploadFile.sizeExceeds'));
      } else if (supportedExtensions.filter(ext => ext === /[^.]+$/.exec(`${f.name}`.toLowerCase())[0]).length === 0) {
        setError(l('uploadFile.notSupported'));
      } else {
        const result = await toBase64(f).catch(e => Error(e));
        if (result) {
          setFile(f);
          setFileUrl(URL.createObjectURL(f));
          onChange(result);
        } else {
          setError(l('uploadFile.error'));
          Bugsnag.notify(new Error('Error to convert the file in base64.'));
        }
      }
    } else {
      setError(l('uploadFile.error'));
    }
    setLoading(false);
  };

  const handleRemove = () => {
    setError('');
    setFile(null);
    setFileUrl(null);
    setInputValue('');
    onChange(null);
  };

  return (
    <div className={classes.container}>
      {onClose && (
        <IconButton
          className={classes.iconCloseButton}
          onClick={() => {
            handleRemove();
            onClose();
          }}
        >
          <IconClose />
        </IconButton>
      )}
      <div className={`${classes.previewContainer} ${preview && fileUrl ? 'show' : ''}`}>
        {preview && fileUrl && (
          <img className={`${classes.previewImg} animate__animated animate__fadeInUp`} src={fileUrl} alt="preview" />
        )}
      </div>
      {description && <div className={classes.desc}>{Parser(description)}</div>}
      <ButtonCustom
        variant="contained"
        size="square"
        component="label"
        text={l('uploadFile.label')}
        disabled={file !== null || loading || disabled}
        innerComponent={
          <input
            type="file"
            value={inputValue}
            accept={supportedExtensions.map((t, i) => `.${t}${i + 1 > supportedExtensions.length ? ', ' : ''}`)}
            style={{ display: 'none' }}
            onChange={e => {
              setInputValue(e.target.value);
              handleOnChange(e.target.files);
            }}
          />
        }
      />
      <div className={classes.messages}>
        {!error && !file && <span>{l('uploadFile.noFiles')}</span>}
        {!error && file?.name && (
          <div className={classes.nameContainer}>
            <span className={classes.name}>
              <b>{file?.name}</b>
            </span>
            <div className={classes.iconRemove} onClick={() => handleRemove()}>
              <CancelIcon />
            </div>
          </div>
        )}
        {error && <span className="error">{error}</span>}
      </div>
    </div>
  );
};

UploadFileWithPreview.propTypes = {
  description: PropTypes.string,
  supportedExtensions: PropTypes.array,
  maxSize: PropTypes.number,
  disabled: PropTypes.bool,
  preview: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default UploadFileWithPreview;
