import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import l from '../../../../lang';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import Dialog from '../../../common/dialog';
import UploadLogo from '../upload-logo';
import Card from '../../../common/card';
import CardTitle from '../../../common/card-title';
import TableInfo from '../../../common/table-info';
import BuildingIcon from '../../../../assets/icons/building.svg?url';
import BriefcaseIcon from '../../../../assets/icons/briefcase.svg?url';
import PhoneVolumeIcon from '../../../../assets/icons/phone-volume.svg?url';
import { segmentCompanyInfoUploadLogo } from '../../../../utils/analytics';

const BasicInfo = ({
  loadingUploadLogo,
  logoUrl,
  name,
  lastName,
  documentType,
  document,
  phone,
  website,
  country,
  showUploadLogo,
  fnShowUploadLogo,
}) => {
  const onUploadLogoHandler = () => {
    segmentCompanyInfoUploadLogo();
    fnShowUploadLogo(true);
  };

  return (
    <div className="basic_info">
      <Card>
        <CardTitle>{l('companyInformation.basicInfo.title')}</CardTitle>
        <div className="basic_info_section">
          <TableInfo
            title={
              <div className="basic_info_section_title">
                <img src={BuildingIcon} alt="building-icon" />
                <div>{l('companyInformation.basicInfo.commercialInformation')}</div>
              </div>
            }
            rows={[{ label: l('companyInformation.basicInfo.website'), value: website || '-' }]}
          />
        </div>
        <div className="basic_info_section">
          <TableInfo
            title={
              <div className="basic_info_section_title">
                <img src={BriefcaseIcon} alt="building-icon" />
                <div>{l('companyInformation.basicInfo.legalInformation')}</div>
              </div>
            }
            rows={[
              { label: l('companyInformation.basicInfo.country'), value: country ? l(`countryCode.${country}`) : '-' },
              { label: l('companyInformation.basicInfo.name'), value: name + (lastName ? ` ${lastName}` : '') },
              { label: l('companyInformation.basicInfo.documentType'), value: documentType || '-' },
              { label: l('companyInformation.basicInfo.document'), value: document || '-' },
            ]}
          />
        </div>
        <div className="basic_info_section">
          <TableInfo
            title={
              <div className="basic_info_section_title">
                <img src={PhoneVolumeIcon} alt="building-icon" />
                <div>{l('companyInformation.basicInfo.contactInformation')}</div>
              </div>
            }
            rows={[{ label: l('companyInformation.basicInfo.phone'), value: phone || '-' }]}
          />
        </div>
        <div className="basic_info_section">
          <div className="basic_info_modify_logo">
            <div className="basic_info_modify_logo_title">{l('companyInformation.basicInfo.logo')}</div>
            <div className="basic_info_modify_logo_btn">
              <ButtonCustom
                text={l(`companyInformation.basicInfo.${logoUrl ? 'modifyLogo' : 'uploadLogo'}`)}
                variant="outlined"
                color="primary"
                size="square"
                onClick={onUploadLogoHandler}
              />
            </div>
          </div>
        </div>
      </Card>
      {showUploadLogo && (
        <Dialog
          dividers={false}
          unpaddingContent
          unpaddingFooter
          fullScreenMobile
          handleClose={() => {
            if (!loadingUploadLogo) {
              fnShowUploadLogo(false);
            }
          }}
          type="sm"
          title={l('companyInformation.basicInfo.logo')}
          body={<UploadLogo />}
        />
      )}
    </div>
  );
};

BasicInfo.propTypes = {
  loadingUploadLogo: PropTypes.bool,
  logoUrl: PropTypes.string,
  name: PropTypes.string,
  lastName: PropTypes.string,
  documentType: PropTypes.string,
  document: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  showUploadLogo: PropTypes.bool,
  fnShowUploadLogo: PropTypes.func,
};

export default BasicInfo;
