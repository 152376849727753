import { FunctionComponent, ReactNode } from 'react';
import { Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as DgoIcon } from '../../../../assets/images/dlocalGo-icon.svg';
import { ReactComponent as ExchangeIcon } from '../../../../assets/icons/exchange-alt.svg';
import { createPortal } from 'react-dom';
import './styles.scss';

interface IntegrationDetailsProps {
  icon: JSX.Element;
  color: 'blue' | 'green' | 'violet' | 'orange' | 'red' | 'magenta';
  children: ReactNode;
  onClose: () => void;
}

const IntegrationDetails: FunctionComponent<IntegrationDetailsProps> = ({ icon, color, children, onClose }) =>
  createPortal(
    <div className="integration_details" onClick={onClose}>
      <Card className={`integration_details__content ${color}`} onClick={ev => ev.stopPropagation()}>
        <div className="integration_details__content__header">
          <div>
            <DgoIcon />
            <ExchangeIcon />
            {icon}
          </div>
          <IconButton className="integration_details__content__close" onClick={onClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </div>
        {children}
      </Card>
    </div>,
    window.document.body
  );

export default IntegrationDetails;
