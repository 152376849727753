import { FunctionComponent, MutableRefObject, useEffect } from 'react';
import l from '../../../../lang';
import Table from '../../../common/table';
import RadioButton from '../../../common/radio-button';
import Loading from '../../../common/loading';
import { Urls } from '../../../../config/urls';
import { useSettlements } from '../../../../store/settlements';

import './styles.scss';

interface SelectBeneficiaryProps {
  disabled?: boolean;
  value?: string;
  errorReference?: MutableRefObject<null>;
  externalError?: string;
  onSelect: (value: string) => void;
}

const SelectBeneficiary: FunctionComponent<SelectBeneficiaryProps> = ({
  disabled,
  value = '',
  onSelect,
  errorReference,
  externalError,
}) => {
  const {
    beneficiaries: { loading, data },
    getBeneficiaries,
  } = useSettlements();

  useEffect(() => {
    getBeneficiaries();
  }, []);

  return (
    <div className="select-beneficiary">
      {loading && <Loading fullScreen />}
      <div className="select-beneficiary__label">
        {data?.length === 0 ? (
          <div>{l('selectBeneficiary.withoutData.title')}</div>
        ) : (
          <div>{l('selectBeneficiary.titleWithData')}</div>
        )}
      </div>
      {data?.length > 0 && (
        <div className="select-beneficiary__table-container">
          <div className="select-beneficiary__table-label">{l('selectBeneficiary.title')}</div>
          <div className="select-beneficiary__table">
            <Table
              idxHiddenColumnsOnMobile={[1, 2, 3, 4]}
              maxHeight="230px"
              headers={[
                '',
                l('bankAccounts.country'),
                l('bankAccounts.name'),
                l('bankAccounts.documentType'),
                l('bankAccounts.document'),
                l('bankAccounts.bankName'),
                l('bankAccounts.bankAccount'),
              ]}
              body={
                data?.map((row, idx) => {
                  const item = [
                    <RadioButton
                      disabled={disabled}
                      name={`rb-beneficiary-${idx.toString()}`}
                      checked={value === `${row.id}`}
                      value={`${row.id}`}
                      onChange={() => onSelect(`${row.id}`)}
                    />,
                    <img
                      alt={`img-country-${row.country}`}
                      className="bank-accounts__country"
                      src={`${Urls.flags.replace(':code', row.country)}`}
                    />,
                    <span>{`${row.beneficiaryFirstName || ''} ${row.beneficiaryLastName || ''}`.trim()}</span>,
                    <span>{row.beneficiaryDocumentType}</span>,
                    <span>{row.beneficiaryDocument}</span>,
                    <span>{row.bankName}</span>,
                    <span>{row.bankAccount}</span>,
                  ];
                  return item;
                }) || null
              }
              emptyBody={undefined}
            />
          </div>
        </div>
      )}
      <div ref={errorReference} className="select-beneficiary__error">
        {externalError || <div />}
      </div>
    </div>
  );
};

export default SelectBeneficiary;
