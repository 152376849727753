import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import './styles.scss';
import Bugsnag from '@bugsnag/js';
import Loading from '../../components/common/loading';
import { sendConfirmEmail } from '../../store/email-validation/actions';
import { Routes } from '../../config/routes';
import { redirecTo } from '../../utils/index';
import MessageError from '../../components/common/message-error';
import config from '../../config';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ConfirmEmail = ({ loading, error, errorInfo, success, onConfirmEmail }) => {
  const queryParams = useQuery();
  useEffect(() => {
    const token = queryParams.get('token');
    if (token) {
      onConfirmEmail(token);
    } else {
      Bugsnag.notify(new Error('ConfirmEmail: Token is empty.'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="confirm-email">
      {loading && <Loading fullScreen noBackground />}
      {!loading && error && (
        <div className="confirm-email-error-container">
          <MessageError errorInfo={errorInfo} onClose={() => redirecTo(Routes.HOME)} />
        </div>
      )}
      {!loading && !error && success && <Redirect to={config.emailValidationOnSignUp ? Routes.LOGIN : Routes.HOME} />}
    </div>
  );
};

ConfirmEmail.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  success: PropTypes.bool,
  onConfirmEmail: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.emailValidation.confirm.loading,
  error: state.emailValidation.confirm.error,
  errorInfo: state.emailValidation.confirm.errorInfo,
  success: state.emailValidation.confirm.success,
});

const mapDispatchToProps = dispatch => ({
  onConfirmEmail: token => dispatch(sendConfirmEmail(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
