import l from '../../../../../../../lang';
import { Plugin, ChartOptions } from 'chart.js';
import { TransactionsByCountryData } from '../../../../../../../store/reports/interfaces.d';

export const chartColors = ['#2635C8', '#1759E7', '#006CFA', ' #0080FF', '#00A0FF', '#4CB2FF', '#87C8FF', '#B8DDFF'];
export const chartEmptyColor = '#F4F6F8';

export const getOptions = (
  data: TransactionsByCountryData[],
  emptyChart: boolean,
  isDesktop: boolean
): ChartOptions<'pie'> & { plugins: PluginEmptyPie } => ({
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: ctx => {
      return emptyChart ? 20 : 0;
    },
  },
  plugins: {
    emptyPie: {
      color: chartEmptyColor,
      width: 2,
      radiusDecrease: 0,
    },
    legend: {
      position: isDesktop && !emptyChart ? 'right' : 'bottom',
      labels: {
        usePointStyle: true,
        boxHeight: 6,
        boxWidth: 6,
      },
      fullSize: false,
    },
    datalabels: {
      color: '#ffffff',
      font: {
        size: 12,
        weight: 400,
      },
      formatter: value => {
        return value + '%';
      },
    },
    tooltip: {
      borderColor: '#ececec',
      borderWidth: 1,
      backgroundColor: '#fff',
      titleColor: '#454f5b',
      titleFont: {
        weight: '700',
        size: 12,
      },
      bodyColor: '#454f5b',
      padding: 15,
      usePointStyle: true,
      callbacks: {
        title: context => {
          return `${context[0].label} | ${context[0].parsed}%`;
        },
        label: context => {
          const number = data[context.dataIndex].count;
          return `${number} ${l(number == 1 ? 'reports.transactionCount' : 'reports.transactionsCount')}`;
        },
      },
    },
  },
});

interface PluginEmptyPie {
  emptyPie: { color: string; width: number; radiusDecrease: number };
}

export const plugin: Plugin = {
  id: 'emptyPie',
  afterDraw(chart, args, options) {
    const { datasets } = chart.data;
    const { color, width, radiusDecrease } = options;
    const hasData = datasets.some(dataset => dataset.data.length > 0);

    if (!hasData) {
      const {
        chartArea: { left, top, right, bottom },
        ctx,
      } = chart;
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const r = Math.min(right - left, bottom - top) / 2;

      ctx.beginPath();
      ctx.lineWidth = width || 2;
      ctx.strokeStyle = color || chartEmptyColor;
      ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.fillStyle = color || chartEmptyColor;
      ctx.fill();
    }
  },
};
