import Parser from 'html-react-parser';
import l from '../../../../lang';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import { ReactComponent as ShopifyIcon } from '../../../../assets/icons/shopify.svg';
import IntegrationDetails from '../integration-details';
import { Button } from '../../../common/button';
import { segmentIntegrationsShopifyInstall, segmentIntegrationsShopifyPageLinkDocs } from '../../../../utils/analytics';
import { openNewTabAndFocus, getLanguage } from '../../../../utils';
import { Urls } from '../../../../config/urls';
import ButtonLink from '../../../common/button-link';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationDetails icon={<ShopifyIcon />} color="green" onClose={() => setDetailsOpened(DetailsOpened.NONE)}>
      <div className="integration_details__title">{l('paymentIntegration.shopify.details.headline')}</div>
      <p>
        <span>
          {Parser(l('paymentIntegration.shopify.details.description'))}
          <ButtonLink
            text={l('paymentIntegration.shopify.details.link')}
            onClick={() => {
              segmentIntegrationsShopifyPageLinkDocs();
              openNewTabAndFocus(Urls.docs.shopifyIntegration[getLanguage()]);
            }}
          />
        </span>
      </p>
      <Button
        className="integration_details__button"
        onClick={() => {
          segmentIntegrationsShopifyInstall();
          openNewTabAndFocus(Urls.shopifyAddDP);
        }}
      >
        {l('paymentIntegration.shopify.details.button')}
      </Button>
    </IntegrationDetails>
  );
};
