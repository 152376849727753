import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Menu as MenuMui, MenuItem } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Avatar from '../../avatar';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  icon: {
    margin: 'auto 0 auto -10px',
    fontSize: 20,
    padding: 10,
  },
  menu: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  menuItem: {
    minWidth: 200,
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  menuItemIcon: {
    display: 'flex',
    marginRight: 10,
    fontSize: '10px',
    '& > svg': {
      fontSize: '20px',
    },
  },
}));

const AvatarMenu = ({ user, items }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const expandMoreRef = useRef(null);

  const handleClick = () => {
    setAnchorEl(expandMoreRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Avatar user={user} onClick={handleClick} />
      {items && (
        <>
          <ExpandMore ref={expandMoreRef} className={classes.icon} onClick={handleClick} />
          <MenuMui
            className={classes.menu}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {items.map((item, idx) => (
              <MenuItem
                key={`avatar-item-${idx.toString()}`}
                className={classes.menuItem}
                onClick={() => {
                  handleClose();
                  item.onClick();
                }}
              >
                <span className={classes.menuItemIcon}>{item.icon}</span>
                <span>{item.text}</span>
              </MenuItem>
            ))}
          </MenuMui>
        </>
      )}
    </div>
  );
};

AvatarMenu.propTypes = {
  user: PropTypes.string,
};

export default AvatarMenu;
