import { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import l from '../../../../lang';
import { settlementStatus } from '../../../../utils/settlements-utils';
import Filter from '../../../common/filter';
import Select from '../../../common/select';
import './styles.scss';

const SettlementFilter = ({ fnChangeFilters }) => {
  const initialValues = { status: '' };
  const [filter, setFilter] = useState(initialValues);
  const [appliedFilters, setAppliedFilters] = useState(0);

  const handleChangeFilter = filters => {
    setFilter(filters);

    let filterParam = '';
    let applied = 0;
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        filterParam += filterParam ? '&' : '';
        filterParam += `${_.snakeCase(key)}=${filters[key]}`;
        applied += 1;
      }
    });
    setAppliedFilters(applied);
    fnChangeFilters(filterParam);
  };

  return (
    <div className="settlement-filter">
      <Filter
        clearFilter={() => handleChangeFilter(initialValues)}
        appliedFilters={appliedFilters}
        items={[
          {
            title: (
              <span>
                <span className="settlement-filter__title">{l('settlements.status')}: </span>
                <span className="settlement-filter__summary">
                  {`${l(filter.status ? `settlements.status.${filter.status}` : 'common.all.m')}`}
                </span>
              </span>
            ),
            component: (
              <Select
                name="filter-status"
                label={l('settlements.filter.status')}
                defaultOptionText={l('common.all.m')}
                defaultOptionEnabled
                value={filter.status}
                size="lg"
                onChange={e => {
                  handleChangeFilter({ ...filter, status: e.target.value });
                }}
                options={settlementStatus()}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

SettlementFilter.propTypes = {
  fnChangeFilters: PropTypes.func,
};

export default SettlementFilter;
