import _ from 'lodash';
import { errorFieldsCode } from '../../utils/error-codes';
import { handleErrorInfo } from '../../utils';
import { CompanyInformationActionType } from './actions';
import { CompanyInformationState } from './interfaces';
import { ErrorFields } from '../interfaces';

const initialState: CompanyInformationState = {
  loading: false,
  error: false,
  errorInfo: undefined,
  errorFields: undefined,
  industries: undefined,
  documentTypesForCompany: {},
  documentTypesForNaturalPerson: {},
  success: false,
  showUpLevel: false,
  missingDocuments: undefined,
  uploadLogo: {
    loading: false,
    error: false,
    errorInfo: undefined,
    showModal: false,
    deleteSuccess: false,
    uploadSuccess: false,
    savedUrl: undefined,
  },
  allKycLevels: {
    loading: false,
    error: false,
    errorInfo: undefined,
    levels: [],
  },
  displayMessageKycStep1: false,
};

const CompanyInformationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CompanyInformationActionType.SEND_KYC: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        success: false,
        errorFields: null,
      };
    }
    case CompanyInformationActionType.SEND_KYC_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        success: true,
      };
    }
    case CompanyInformationActionType.SEND_KYC_ERROR: {
      const errorInfo = handleErrorInfo(CompanyInformationActionType.SEND_KYC_ERROR, action.error);
      let errorFields: ErrorFields = {};

      if (errorInfo && errorInfo.code === errorFieldsCode && errorInfo.errorFields) {
        errorInfo.errorFields.forEach((keyField: string) => {
          const err = { [keyField]: action.error.sentValues[keyField] };
          errorFields = { ...errorFields, ...err };
        });
      } else if (errorInfo?.inputError) {
        const err = {
          [errorInfo.inputError.name]: {
            value: action.error.sentValues[errorInfo.inputError.name],
            message: errorInfo.inputError.message,
          },
        };
        errorFields = { ...errorFields, ...err };
      }

      if (!_.isEmpty(errorFields) && !errorInfo?.inputError) {
        return {
          ...state,
          loading: false,
          error: false,
          errorInfo: undefined,
          errorFields,
        };
      }
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo,
        success: false,
        errorFields: errorInfo?.inputError ? errorFields : null,
      };
    }
    case CompanyInformationActionType.GET_DOCUMENT_TYPES_FOR_KYC: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        documentTypesForCompany: {},
        documentTypesForNaturalPerson: {},
      };
    }
    case CompanyInformationActionType.GET_DOCUMENT_TYPES_FOR_KYC_SUCCESS: {
      let documentTypesForCompany = {};
      let documentTypesForNaturalPerson = {};
      action.response.forEach((dt: { company: boolean; naturalPerson: boolean; documentType: string }) => {
        if (dt.company) {
          const documentType = { [dt.documentType]: dt.documentType };
          documentTypesForCompany = { ...documentTypesForCompany, ...documentType };
        }
        if (dt.naturalPerson) {
          const documentType = { [dt.documentType]: dt.documentType };
          documentTypesForNaturalPerson = { ...documentTypesForNaturalPerson, ...documentType };
        }
      });
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        documentTypesForCompany,
        documentTypesForNaturalPerson,
      };
    }
    case CompanyInformationActionType.GET_DOCUMENT_TYPES_FOR_KYC_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(CompanyInformationActionType.GET_DOCUMENT_TYPES_FOR_KYC_ERROR, action.error),
        documentTypesForCompany: {},
        documentTypesForNaturalPerson: {},
      };
    }
    case CompanyInformationActionType.SHOW_UPLOAD_LOGO: {
      return {
        ...state,
        uploadLogo: {
          ...state.uploadLogo,
          showModal: action.show,
          ...(action.show ? { error: false, errorInfo: undefined } : { deleteSuccess: false, uploadSuccess: false }),
        },
      };
    }
    case CompanyInformationActionType.DELETE_LOGO: {
      return {
        ...state,
        uploadLogo: {
          ...state.uploadLogo,
          loading: true,
          error: false,
          errorInfo: undefined,
          deleteSuccess: false,
        },
      };
    }
    case CompanyInformationActionType.DELETE_LOGO_SUCCESS: {
      return {
        ...state,
        uploadLogo: {
          ...state.uploadLogo,
          loading: false,
          error: false,
          errorInfo: undefined,
          deleteSuccess: true,
          savedUrl: undefined,
        },
      };
    }
    case CompanyInformationActionType.DELETE_LOGO_ERROR: {
      return {
        ...state,
        uploadLogo: {
          ...state.uploadLogo,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(CompanyInformationActionType.DELETE_LOGO_ERROR, action.error),
          deleteSuccess: false,
        },
      };
    }
    case CompanyInformationActionType.UPLOAD_LOGO: {
      return {
        ...state,
        uploadLogo: {
          ...state.uploadLogo,
          loading: true,
          error: false,
          errorInfo: undefined,
          uploadSuccess: false,
        },
      };
    }
    case CompanyInformationActionType.UPLOAD_LOGO_SUCCESS: {
      return {
        ...state,
        uploadLogo: {
          ...state.uploadLogo,
          loading: false,
          error: false,
          errorInfo: undefined,
          uploadSuccess: true,
          savedUrl: action.response.logoUrl,
        },
      };
    }
    case CompanyInformationActionType.UPLOAD_LOGO_ERROR: {
      return {
        ...state,
        uploadLogo: {
          ...state.uploadLogo,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(CompanyInformationActionType.UPLOAD_LOGO_ERROR, action.error),
          uploadSuccess: false,
        },
      };
    }
    case CompanyInformationActionType.CLEAN_LOGO_SUCCESS: {
      return {
        ...state,
        uploadLogo: {
          ...state.uploadLogo,
          uploadSuccess: false,
          deleteSuccess: false,
        },
      };
    }
    case CompanyInformationActionType.SHOW_UP_LEVEL: {
      return {
        ...state,
        showUpLevel: action.show,
        ...(action.show ? { error: false, errorInfo: undefined, success: false } : {}),
      };
    }
    case CompanyInformationActionType.GET_KYC_LEVELS: {
      return {
        ...state,
        allKycLevels: {
          ...state.allKycLevels,
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case CompanyInformationActionType.GET_KYC_LEVELS_SUCCESS: {
      return {
        ...state,
        allKycLevels: {
          ...state.allKycLevels,
          loading: false,
          levels: action.response,
        },
      };
    }
    case CompanyInformationActionType.GET_KYC_LEVELS_ERROR: {
      return {
        ...state,
        allKycLevels: {
          ...state.allKycLevels,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(CompanyInformationActionType.GET_KYC_LEVELS_ERROR, action.error),
        },
      };
    }

    case CompanyInformationActionType.GET_INDUSTRIES: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        industries: null,
      };
    }
    case CompanyInformationActionType.GET_INDUSTRIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        industries: action.response,
      };
    }
    case CompanyInformationActionType.GET_INDUSTRIES_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(CompanyInformationActionType.GET_INDUSTRIES_ERROR, action.error),
        industries: null,
      };
    }
    case CompanyInformationActionType.GET_MISSING_DOCUMENTS: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        missingDocuments: undefined,
      };
    }
    case CompanyInformationActionType.GET_MISSING_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        missingDocuments: action.response?.message?.split(',') || null,
      };
    }
    case CompanyInformationActionType.GET_MISSING_DOCUMENTS_ERROR: {
      return {
        ...state,
        loading: false,
        /* Do not show error if service fails, in this case all documents are requested.
        error: true,
        errorInfo: handleErrorInfo(GET_MISSING_DOCUMENTS_ERROR, action.error),
        missingDocuments: undefined,
        */
      };
    }
    case CompanyInformationActionType.DISPLAY_MESSAGE_KYC_STEP_1: {
      return {
        ...state,
        displayMessageKycStep1: action.payload,
      };
    }
    default:
      return state;
  }
};

export default CompanyInformationReducer;
