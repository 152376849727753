import l from '../../lang';
import { getLanguage } from '../../utils';

export const useSmartField = ({
  country,
  currency,
  totalAmount,
  onChangeError,
  onChangeBrand,
  onChangeInstallments,
  onChangeInstallmentsOptions,
}) => {
  const dlocal = window.dlocal(import.meta.env.REACT_APP_DLOCAL_KEY);
  const fields = dlocal.fields({
    locale: getLanguage(),
    country,
  });

  const card = fields.create('card', {
    style: {
      base: {
        fontSize: '16px',
        fontFamily: "'Inter UI medium', sans-serif",
        lineHeight: '18px',
        fontSmoothing: 'antialiased',
        fontWeight: '500',
        color: '#32325d',
        '::placeholder': {
          color: '#8a8a8a',
        },
        iconColor: 'rgba(0, 0, 0, 0.87)',
      },
    },
  });

  card.mount(document.getElementById('virtual-pos-card-field'));

  card.addEventListener('change', event => {
    if (event.error) {
      onChangeError(event.error.message);
    } else {
      onChangeError('');
    }
  });

  const buildInstallments = installmentsPlan => {
    const installments = [];

    const installmentsByBank = !!installmentsPlan.installments_by_bank;
    installmentsPlan.installments.forEach((plan, idx) => {
      const option = {
        id: `${plan.id}`,
        installments: `${plan.installments}`,
        installmentAmount: `${plan.installment_amount}`,
        totalAmount: `${plan.total_amount}`,
        installmentsByBank,
        // to select (value & displayName)
        value: `${plan.id}`,
        displayName: installmentsByBank
          ? `${plan.installments} ${
              plan.installments > 1
                ? l(`smartField.withInterest.prural${country === 'MX' ? '.MX' : ''}`)
                : l(`smartField.withInterest.singular${country === 'MX' ? '.MX' : ''}`)
            }`
          : `${plan.installments} ${l('common.of')} ${currency}` +
            ` ${plan.installment_amount} (${l('common.total')} : ${currency} ${plan.total_amount})`,
      };
      if (idx === 0) {
        onChangeInstallments(option);
      }

      installments.push(option);
    });
    return installments;
  };

  card.on('brand', event => {
    onChangeError('');
    onChangeInstallmentsOptions([]);
    onChangeInstallments({});
    if (event.brand) {
      onChangeBrand(event.brand);
      dlocal
        .createInstallmentsPlan(card, totalAmount, currency)
        .then(result => {
          onChangeInstallmentsOptions(buildInstallments(result.installments));
        })
        .catch(result => {
          onChangeInstallmentsOptions([]);
        });
    } else {
      onChangeBrand('');
    }
  });

  return { dlocal, card };
};
