import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import { FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '14px',
  },
}));

const RadioButton = ({ label, name, value, checked, disabled, onChange }) => {
  const styles = useStyles();

  return (
    <FormControlLabel
      label={label}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      classes={{
        label: styles.label,
      }}
      control={<Radio />}
    />
  );
};

RadioButton.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RadioButton;
