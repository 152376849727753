import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getTask, postTask } from '../../services';
import { Urls } from '../../config/urls';
import {
  PaymentIntegrationType,
  getIntegrationKeysSuccess,
  getIntegrationKeysError,
  getCurrenciesPISuccess,
  getCurrenciesPIError,
  setShopifyTestModeSuccess,
  setShopifyTestModeError,
} from './actions';
import { checkToken } from '../auth/sagas';
import { merchantMe } from '../dashboard/sagas';
import { PanelStore } from '../interfaces';

function* getIntegrationKeys() {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: Urls.api.integrationKeys.replace(':id', `${state.dashboard.merchantMe?.id || ''}`),
    token: state.auth.token,
  });

  if (response) {
    yield put(getIntegrationKeysSuccess(response));
  } else {
    yield put(getIntegrationKeysError(error));
  }
}

export function* getIntegrationKeysSaga() {
  yield takeLatest(PaymentIntegrationType.GET_INTEGRATION_KEYS, getIntegrationKeys);
}

function* getCurrenciesPI(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.currencies}?country=${action.country}`,
    token: state.auth.token,
  });

  if (response) {
    yield put(getCurrenciesPISuccess(response));
  } else {
    yield put(getCurrenciesPIError(error));
  }
}

export function* getCurrenciesPISaga() {
  yield takeLatest(PaymentIntegrationType.GET_CURRENCIES_PI, getCurrenciesPI);
}

export function* setTestMode(action: any) {
  yield* checkToken();

  const state: PanelStore.RootState = yield select();

  const { testMode } = action;
  const body = { testMode };
  const { response, error } = yield call(postTask, {
    url: Urls.api.shopifySetTestMode,
    body,
    token: state.auth.token,
  });

  yield* merchantMe();
  if (response) {
    yield put(setShopifyTestModeSuccess());
  } else {
    yield put(setShopifyTestModeError(error));
  }
}

export function* setShopifyTestModeSaga() {
  yield takeLatest(PaymentIntegrationType.SET_SHOPIFY_TEST_MODE, setTestMode);
}
