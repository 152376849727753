import { handleErrorInfo } from '../../utils';
import { PasswordResetActionType } from './actions';
import { PasswordResetState } from './interfaces';

const initialState: PasswordResetState = {
  loading: false,
  error: false,
  errorInfo: undefined,
  success: false,
  confirmSuccess: false,
};

const PasswordResetReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PasswordResetActionType.INIT_PASSWORD_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        success: false,
        confirmSuccess: false,
      };
    case PasswordResetActionType.SEND_PASSWORD_RESET: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        success: false,
      };
    }
    case PasswordResetActionType.SEND_PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        success: true,
      };
    }
    case PasswordResetActionType.SEND_PASSWORD_RESET_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(PasswordResetActionType.SEND_PASSWORD_RESET_ERROR, action.error),
        success: false,
      };
    }
    case PasswordResetActionType.CONFIRM_PASSWORD_RESET: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        confirmSuccess: false,
      };
    }
    case PasswordResetActionType.CONFIRM_PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: null,
        confirmSuccess: true,
      };
    }
    case PasswordResetActionType.CONFIRM_PASSWORD_RESET_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(PasswordResetActionType.CONFIRM_PASSWORD_RESET_ERROR, action.error),
        confirmSuccess: false,
      };
    }
    default:
      return state;
  }
};

export default PasswordResetReducer;
