import { FunctionComponent, ReactNode } from 'react';
import Dialog from '../dialog';

interface DialogConfirmCloseProps {
  onClose: (confirm: boolean) => void;
}

const DialogConfirmClose: FunctionComponent<DialogConfirmCloseProps> = ({ onClose, children }) => {
  return (
    <Dialog
      dividers={false}
      unpaddingFooter
      unpaddingContent
      type="xs"
      titleCenter
      handleClose={() => onClose(false)}
      body={children}
    />
  );
};

export default DialogConfirmClose;
