export const gtagOnboardingStep = (event: 'Step_1' | 'Step_2', type: 'Individual' | 'Company', email: string): void => {
  window.dataLayer.push({ event, type, email });
};

export const gtagSignUp = ({ email }: { email: string }): void => {
  window.dataLayer.push({
    event: 'signup',
    email,
  });
};

export const gtagSettlementRequest = ({ email }: { email: string }): void => {
  window.dataLayer.push({
    event: 'settlement_requested',
    email,
  });
};

export const gtagReportFirstTransaction = (id: string): void => {
  window.dataLayer.push([
    'event',
    'conversion',
    {
      send_to: 'AW-593905583/VXcrCIGdpr0CEK-PmZsC',
      transaction_id: id,
    },
  ]);
};

export function gaSetDimensionMerchantId(merchantId: string) {
  if (typeof window.ga !== 'undefined' && typeof window.ga.getAll === 'function') {
    const trackers = window.ga.getAll();
    trackers.forEach((tracker: any) => {
      window.ga(`${tracker.get('name')}.set`, 'dimension1', merchantId);
    });
  }
}
