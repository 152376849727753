import { CreatedPaymentLinkType } from '../../constants';
import { Values } from '../interfaces';

export enum PaymentLinkActionType {
  INIT_PAYMENT_LINK = 'PAYMENT_LINK/INIT_PAYMENT_LINK',
  CLEAN_ERROR_PAYMENT_LINK = 'PAYMENT_LINK/CLEAN_ERROR',
  GET_CURRENCIES = 'PAYMENT_LINK/GET_CURRENCIES',
  GET_CURRENCIES_SUCCESS = 'PAYMENT_LINK/GET_CURRENCIES_SUCCESS',
  GET_CURRENCIES_ERROR = 'PAYMENT_LINK/GET_CURRENCIES_ERROR',
  CLEAN_CURRENCIES = 'PAYMENT_LINK/CLEAN_CURRENCIES',
  GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK = 'PAYMENT_LINK/GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK',
  GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK_SUCCESS = 'PAYMENT_LINK/GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK_SUCCESS',
  GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK_ERROR = 'PAYMENT_LINK/GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK_ERROR',
  PAYMENT_LINK_RESULT = 'PAYMENT_LINK/PAYMENT_LINK_RESULT',
  CREATE_PAYMENT_LINK = 'PAYMENT_LINK/CREATE_PAYMENT_LINK',
  CREATE_PAYMENT_LINK_ERROR = 'PAYMENT_LINK/CREATE_PAYMENT_LINK_ERROR',
  CREATE_PAYMENT_LINK_SUCCESS = 'PAYMENT_LINK/CREATE_PAYMENT_LINK_SUCCESS',
  GET_EXISTING_CLIENTS = 'PAYMENT_LINK/GET_EXISTING_CLIENTS',
  GET_EXISTING_CLIENTS_SUCCESS = 'PAYMENT_LINK/GET_EXISTING_CLIENTS_SUCCESS',
  GET_EXISTING_CLIENTS_ERROR = 'PAYMENT_LINK/GET_EXISTING_CLIENTS_ERROR',
  GET_CREATED_ONE_TIME_PAYMENT_LINKS = 'PAYMENT_LINK/GET_CREATED_ONE_TIME_PAYMENT_LINKS',
  GET_CREATED_ONE_TIME_PAYMENT_LINKS_SUCCESS = 'PAYMENT_LINK/GET_CREATED_ONE_TIME_PAYMENT_LINKS_SUCCESS',
  GET_CREATED_ONE_TIME_PAYMENT_LINKS_ERROR = 'PAYMENT_LINK/GET_CREATED_ONE_TIME_PAYMENT_LINKS_ERROR',
  GET_CREATED_RECURRING_PAYMENT_LINKS = 'PAYMENT_LINK/GET_CREATED_RECURRING_PAYMENT_LINKS',
  GET_CREATED_RECURRING_PAYMENT_LINKS_SUCCESS = 'PAYMENT_LINK/GET_CREATED_RECURRING_PAYMENT_LINKS_SUCCESS',
  GET_CREATED_RECURRING_PAYMENT_LINKS_ERROR = 'PAYMENT_LINK/GET_CREATED_RECURRING_PAYMENT_LINKS_ERROR',
  CHANGE_STATUS_RECURRING_PAYMENT_LINK = 'PAYMENT_LINK/CHANGE_STATUS_RECURRING_PAYMENT_LINK',
  CHANGE_STATUS_RECURRING_PAYMENT_LINK_SUCCESS = 'PAYMENT_LINK/CHANGE_STATUS_RECURRING_PAYMENT_LINK_SUCCESS',
  CHANGE_STATUS_RECURRING_PAYMENT_LINK_ERROR = 'PAYMENT_LINK/CHANGE_STATUS_RECURRING_PAYMENT_LINK_ERROR',
  CHANGE_TAB_SELECTED = 'PAYMENT_LINK/CHANGE_TAB_SELECTED',
}

export const initPaymentLink = () => ({
  type: PaymentLinkActionType.INIT_PAYMENT_LINK,
});

export const cleanErrorPaymentLink = () => ({
  type: PaymentLinkActionType.CLEAN_ERROR_PAYMENT_LINK,
});

export const getCurrencies = (country: string) => ({
  type: PaymentLinkActionType.GET_CURRENCIES,
  country,
});

export const getCurrenciesSuccess = (response: any) => ({
  type: PaymentLinkActionType.GET_CURRENCIES_SUCCESS,
  response,
});

export const getCurrenciesError = (error: any) => ({
  type: PaymentLinkActionType.GET_CURRENCIES_ERROR,
  error,
});

export const cleanCurrencies = () => ({
  type: PaymentLinkActionType.CLEAN_CURRENCIES,
});

export const getDocumentTypesForPaymentLink = (country: string) => ({
  type: PaymentLinkActionType.GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK,
  country,
});

export const getDocumentTypesForPaymentLinkSuccess = (response: any) => ({
  type: PaymentLinkActionType.GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK_SUCCESS,
  response,
});

export const getDocumentTypesForPaymentLinkError = (error: any) => ({
  type: PaymentLinkActionType.GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK_ERROR,
  error,
});

export const paymentLinkResult = (show: boolean) => ({
  type: PaymentLinkActionType.PAYMENT_LINK_RESULT,
  show,
});

export const postCreatePaymentLink = (values: Values, isRecurring: boolean) => ({
  type: PaymentLinkActionType.CREATE_PAYMENT_LINK,
  values,
  isRecurring,
});

export const postCreatePaymentLinkSuccess = (response: any) => ({
  type: PaymentLinkActionType.CREATE_PAYMENT_LINK_SUCCESS,
  response,
});

export const postCreatePaymentLinkError = (error: any) => ({
  type: PaymentLinkActionType.CREATE_PAYMENT_LINK_ERROR,
  error,
});

export const getExistingClients = (page: number, country: string) => ({
  type: PaymentLinkActionType.GET_EXISTING_CLIENTS,
  page,
  country,
});

export const getExistingClientsSuccess = (response: any) => ({
  type: PaymentLinkActionType.GET_EXISTING_CLIENTS_SUCCESS,
  response,
});

export const getExistingClientsError = (error: any) => ({
  type: PaymentLinkActionType.GET_EXISTING_CLIENTS_ERROR,
  error,
});

export const getCreatedOneTimePaymentLinks = (page?: number, rowsPerPage?: number) => ({
  type: PaymentLinkActionType.GET_CREATED_ONE_TIME_PAYMENT_LINKS,
  page,
  rowsPerPage,
});

export const getCreatedOneTimePaymentLinksSuccess = (response: any) => ({
  type: PaymentLinkActionType.GET_CREATED_ONE_TIME_PAYMENT_LINKS_SUCCESS,
  response,
});

export const getCreatedOneTimePaymentLinksError = (error: any) => ({
  type: PaymentLinkActionType.GET_CREATED_ONE_TIME_PAYMENT_LINKS_ERROR,
  error,
});

export const getCreatedRecurringPaymentLinks = (page?: number, rowsPerPage?: number) => ({
  type: PaymentLinkActionType.GET_CREATED_RECURRING_PAYMENT_LINKS,
  page,
  rowsPerPage,
});

export const getCreatedRecurringPaymentLinksSuccess = (response: any) => ({
  type: PaymentLinkActionType.GET_CREATED_RECURRING_PAYMENT_LINKS_SUCCESS,
  response,
});

export const getCreatedRecurringPaymentLinksError = (error: any) => ({
  type: PaymentLinkActionType.GET_CREATED_RECURRING_PAYMENT_LINKS_ERROR,
  error,
});

export const changeStatusRecurringPaymentLink = (recurringLinkToken: string, enable: boolean) => ({
  type: PaymentLinkActionType.CHANGE_STATUS_RECURRING_PAYMENT_LINK,
  recurringLinkToken,
  enable,
});

export const changeStatusRecurringPaymentLinkSuccess = (response: any) => ({
  type: PaymentLinkActionType.CHANGE_STATUS_RECURRING_PAYMENT_LINK_SUCCESS,
  response,
});

export const changeStatusRecurringPaymentLinkError = (error: any) => ({
  type: PaymentLinkActionType.CHANGE_STATUS_RECURRING_PAYMENT_LINK_ERROR,
  error,
});

export const changeTabSelected = (tabSelected: CreatedPaymentLinkType) => ({
  type: PaymentLinkActionType.CHANGE_TAB_SELECTED,
  tabSelected,
});
