import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import { ReactComponent as UserIcon } from '../../../assets/icons/user-circle.svg';

const useStyles = makeStyles(theme => ({
  avatar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    columnGap: 12,
    filter: 'brightness(0) invert(1)',
    '@media (max-width:1024px)': {
      fontSize: 12,
      filter: 'brightness(1)',
    },
  },
  icon: {
    color: '#fff',
    height: 35,
    filter: 'brightness(0) invert(1)',
    '@media (max-width:1024px)': {
      width: 35,
      filter: 'brightness(1)',
    },
  },
}));

const Avatar = ({ user, onClick }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width:1024px)');
  
  return (
    <div className={classes.avatar} onClick={onClick}>
      <UserIcon className={classes.icon} />
      {isDesktop && user}
    </div>
  );
};

Avatar.propTypes = {
  user: PropTypes.string,
  size: PropTypes.number,
};

export default Avatar;
