import { useState, useRef, forwardRef, FunctionComponent } from 'react';
import { ButtonProps, IconButton, Popper } from '@mui/material';
import LigthbulbIcon from '../../../assets/icons/lightbulb.svg?url';
import './styles.scss';

const QuickGuideButton: FunctionComponent<ButtonProps> = forwardRef(({ onClick }, ref) => {
  return (
    <IconButton className="quick_guide__button" ref={ref} onClick={onClick}>
      <img src={LigthbulbIcon} />
    </IconButton>
  );
});

interface QuickGuideProps {
  open?: boolean;
  onToggle?: () => void;
}

const QuickGuide: FunctionComponent<QuickGuideProps> = ({ children, open = false, onToggle }) => {
  const quickGuideButtonRef = useRef(null);

  return (
    <>
      <QuickGuideButton ref={quickGuideButtonRef} onClick={onToggle} />
      <Popper
        open={open}
        anchorEl={quickGuideButtonRef?.current}
        sx={{ zIndex: 1 }} // https://github.com/mui/material-ui/issues/33730
      >
        {children}
      </Popper>
    </>
  );
};

export default QuickGuide;
