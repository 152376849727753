import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import { Refresh } from '@mui/icons-material';
import { sendEmailValidation } from '../../../store/email-validation/actions';
import Message from '../../common/message';
import Loading from '../../common/loading';
import { MessageTypes } from '../../../constants';
import l from '../../../lang';
import './styles.scss';
import ButtonCustom from '../../common/__deprecated__/button-custom';
import MessageError from '../../common/message-error';
import ButtonLink from '../../common/button-link';
import { realoadPage } from '../../../utils/index';
import TopDashboardSection from '../../common/top-dashboard-section';

const EmailValidation = ({ loading, error, success, email, resend, onSendEmailValidation }) => {
  return (
    <>
      <TopDashboardSection title={l('menu.subItem.EMAIL_VALIDATION')} subtitle={l('menu.subItem.EMAIL_VALIDATION.desc')} />
      <div className="panel email-validation-component">
        <div className="panel-body">
          {loading && (
            <div className="loading-container">
              <Loading />
            </div>
          )}
          {!loading && error && (
            <div className="email-validation-error">
              <MessageError iconClose={<Refresh />} onClose={() => realoadPage()} />
            </div>
          )}
          {!loading && !error && success && (
            <div className="email-validation-success">
              <Message
                type={MessageTypes.INFO}
                title={l('emailValidation.success.title')}
                body={
                  resend
                    ? Parser(l('emailValidation.success.resend.message', email))
                    : Parser(l('emailValidation.success.message', email))
                }
              />
              <br />
              <ButtonLink text={l('emailValidation.resend.btn')} onClick={() => onSendEmailValidation(true)} />
            </div>
          )}
          {!loading && !error && !success && (
            <div className="email-validation-container">
              <div className="email-validation-description">{Parser(l('emailValidation.description.message', email))}</div>
              <ButtonCustom
                name="send-email-validation-btn"
                text={l('emailValidation.send.btn')}
                onClick={() => onSendEmailValidation()}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

EmailValidation.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  email: PropTypes.string,
  resend: PropTypes.bool,
  onSendEmailValidation: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.emailValidation.loading,
  error: state.emailValidation.error,
  success: state.emailValidation.success,
  resend: state.emailValidation.resend,
  email: state.dashboard.userMe?.email,
});

const mapDispatchToProps = dispatch => ({
  onSendEmailValidation: resend => dispatch(sendEmailValidation(resend)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailValidation);
