import { SubscriptionsActionType } from './actions';
import { handleErrorInfo } from '../../utils';
import { SubscriptionsState } from './interfaces';
import { Currencies, ExchangeRates } from '../interfaces';

const initialState: SubscriptionsState = {
  loading: false,
  error: false,
  errorInfo: undefined,
  allSubscriptions: undefined,
  details: null,
  allPlans: undefined,
  createUpdatePlanSuccess: undefined,
  currencies: {},
  exchangeRates: {},
  planDetails: null,
  deactivateSubscription: {
    loading: false,
    error: false,
    errorInfo: undefined,
    deactivated: false,
  },
  deactivatePlan: {
    loading: false,
    error: false,
    errorInfo: undefined,
    deactivated: false,
  },
};

const SubscriptionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SubscriptionsActionType.GET_SUBSCRIPTIONS: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
      };
    }
    case SubscriptionsActionType.GET_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        allSubscriptions: {
          ...action.response,
        },
      };
    }
    case SubscriptionsActionType.GET_SUBSCRIPTIONS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(SubscriptionsActionType.GET_SUBSCRIPTIONS_ERROR, action.error),
        allSubscriptions: undefined,
      };
    }
    case SubscriptionsActionType.SET_SUBSCRIPTION_DETAILS: {
      return {
        ...state,
        details: action.subscription,
        deactivateSubscription: {
          ...state.deactivateSubscription,
          deactivated: false,
        },
      };
    }
    case SubscriptionsActionType.SHOW_SUBSCRIPTION_DETAILS_PLAN: {
      return {
        ...state,
        detailsPlan: action.plan,
      };
    }
    case SubscriptionsActionType.GET_PLANS: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
      };
    }
    case SubscriptionsActionType.GET_PLANS_SUCCESS: {
      return {
        ...state,
        loading: false,
        allPlans: {
          ...action.response,
        },
      };
    }
    case SubscriptionsActionType.GET_PLANS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(SubscriptionsActionType.GET_PLANS_ERROR, action.error),
        allPlans: undefined,
      };
    }
    case SubscriptionsActionType.GET_CURRENCIES: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        currencies: {},
        exchangeRates: {},
      };
    }
    case SubscriptionsActionType.GET_CURRENCIES_SUCCESS: {
      const currencies: Currencies = {};
      const exchangeRates: ExchangeRates = {};
      action.response.forEach((c: any) => {
        currencies[c.currency] = c.currency;
        exchangeRates[c.currency] = c.exchangeRate;
      });

      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        currencies,
        exchangeRates,
      };
    }
    case SubscriptionsActionType.GET_CURRENCIES_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(SubscriptionsActionType.GET_CURRENCIES_ERROR, action.error),
        currencies: {},
        exchangeRates: {},
      };
    }
    case SubscriptionsActionType.CLEAN_CURRENCIES: {
      return {
        ...state,
        currencies: {},
        exchangeRates: {},
      };
    }
    case SubscriptionsActionType.CLEAN_ERROR_SUBSCRIPTIONS: {
      return {
        ...state,
        error: false,
        errorInfo: undefined,
      };
    }
    case SubscriptionsActionType.CREATE_UPDATE_PLAN: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        createUpdatePlanSuccess: undefined,
      };
    }
    case SubscriptionsActionType.CREATE_UPDATE_PLAN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        createUpdatePlanSuccess: action.response,
      };
    }
    case SubscriptionsActionType.CREATE_UPDATE_PLAN_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(SubscriptionsActionType.CREATE_UPDATE_PLAN_ERROR, action.error),
        createUpdatePlanSuccess: undefined,
      };
    }
    case SubscriptionsActionType.CLEAR_CREATE_UPDATE_PLAN: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        createUpdatePlanSuccess: undefined,
      };
    }
    case SubscriptionsActionType.SET_PLAN_DETAILS: {
      return {
        ...state,
        planDetails: action.plan,
        deactivatePlan: {
          loading: false,
          error: false,
          errorInfo: undefined,
          deactivated: false,
        },
      };
    }
    case SubscriptionsActionType.DEACTIVATE_SUBSCRIPTION: {
      return {
        ...state,
        deactivateSubscription: {
          ...state.deactivateSubscription,
          loading: true,
          deactivated: false,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case SubscriptionsActionType.DEACTIVATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        allSubscriptions: {
          ...state.allSubscriptions,
          outdated: true,
        },
        deactivateSubscription: {
          ...state.deactivateSubscription,
          loading: false,
          deactivated: true,
        },
      };
    }
    case SubscriptionsActionType.DEACTIVATE_SUBSCRIPTION_ERROR: {
      return {
        ...state,
        deactivateSubscription: {
          ...state.deactivateSubscription,
          loading: false,
          deactivated: false,
          error: true,
          errorInfo: handleErrorInfo(SubscriptionsActionType.DEACTIVATE_SUBSCRIPTION_ERROR, action.error),
        },
      };
    }

    case SubscriptionsActionType.DEACTIVATE_PLAN: {
      return {
        ...state,
        deactivatePlan: {
          ...state.deactivatePlan,
          loading: true,
          deactivated: false,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case SubscriptionsActionType.DEACTIVATE_PLAN_SUCCESS: {
      return {
        ...state,
        allPlans: {
          ...state.allPlans,
          outdated: true,
        },
        deactivatePlan: {
          ...state.deactivatePlan,
          loading: false,
          deactivated: true,
        },
      };
    }
    case SubscriptionsActionType.DEACTIVATE_PLAN_ERROR: {
      return {
        ...state,
        deactivatePlan: {
          ...state.deactivatePlan,
          loading: false,
          deactivated: false,
          error: true,
          errorInfo: handleErrorInfo(SubscriptionsActionType.DEACTIVATE_PLAN_ERROR, action.error),
        },
      };
    }
    default:
      return state;
  }
};

export default SubscriptionsReducer;
