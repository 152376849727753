import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import MUIAutocomplete, { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import { ReactNode } from 'react';

interface AutocompleteOption {
  label: string;
  value: string;
  category?: string;
}

interface AutocompleteEvent {
  target: { value: string | null };
}

interface AutocompleteProps extends StandardTextFieldProps {
  options: AutocompleteOption[];
  groupBy?: (option: AutocompleteOption) => string;
  onChange?: (e: AutocompleteEvent) => void;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AutocompleteOption,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
}

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    fontSize: '14px',
  },
}));

const CustomAutocomplete = (props: AutocompleteProps) => {
  const { options, groupBy, onChange, renderOption, ...others } = props;
  return (
    <MUIAutocomplete
      disablePortal
      renderInput={params => <CustomTextField {...others} {...params} />}
      options={options}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      groupBy={groupBy}
      onChange={(event: any, newValue: AutocompleteOption | null) =>
        onChange && onChange({ target: { value: newValue?.value || null } })
      }
      renderOption={renderOption}
    />
  );
};

export const Autocomplete = styled(CustomAutocomplete)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
