import { getLanguage } from '../../../utils';
import { PrivacyPoliciesEN } from './en';
import { PrivacyPoliciesES } from './es';
import { PrivacyPoliciesPT } from './pt';

const PrivacyPolicies = () => {
  const lang = getLanguage();
  switch (lang) {
    case 'es':
      return <PrivacyPoliciesES />;
    case 'pt':
      return <PrivacyPoliciesPT />;
    default:
      return <PrivacyPoliciesEN />;
  }
};

export default PrivacyPolicies;
