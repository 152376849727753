import { FunctionComponent } from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

interface SelectEnvionmentProps {
  label: string;
  checked: boolean;
  disabled: boolean;
  onChange?: (checked: boolean) => void;
}

export const SwitchWithLabel: FunctionComponent<SelectEnvionmentProps> = ({ label, checked, disabled, onChange }) => (
  <FormGroup aria-label="position" row>
    <FormControlLabel
      labelPlacement="start"
      control={<Switch disabled={disabled} checked={checked} onChange={(_, checked) => onChange && onChange(checked)} />}
      label={label}
    />
  </FormGroup>
);
