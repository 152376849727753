import { PanelStore } from '../interfaces';

export namespace CompanyInformationSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.companyInformation.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.companyInformation.error;
  };
  export const getErrorInfo = (state: PanelStore.RootState) => {
    return state.companyInformation.errorInfo;
  };
  export const getErrorFields = (state: PanelStore.RootState) => {
    return state.companyInformation.errorFields;
  };
  export const getIndustries = (state: PanelStore.RootState) => {
    return state.companyInformation.industries;
  };
  export const getDocumentTypesForCompany = (state: PanelStore.RootState) => {
    return state.companyInformation.documentTypesForCompany;
  };
  export const getDocumentTypesForNaturalPerson = (state: PanelStore.RootState) => {
    return state.companyInformation.documentTypesForNaturalPerson;
  };
  export const getSuccess = (state: PanelStore.RootState) => {
    return state.companyInformation.success;
  };
  export const getShowUpLevel = (state: PanelStore.RootState) => {
    return state.companyInformation.showUpLevel;
  };
  export const getMissingDocuments = (state: PanelStore.RootState) => {
    return state.companyInformation.missingDocuments;
  };
  export const getUploadLogo = (state: PanelStore.RootState) => {
    return state.companyInformation.uploadLogo;
  };
  export const getAllKycLevels = (state: PanelStore.RootState) => {
    return state.companyInformation.allKycLevels;
  };
  export const getDisplayMessageKycStep1 = (state: PanelStore.RootState) => {
    return state.companyInformation.displayMessageKycStep1;
  };
}
