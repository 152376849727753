import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Parser from 'html-react-parser';
import Grid from '@mui/material/Grid';
import { CardContent, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { sendSignUp } from '../../store/sign-up/actions';
import { languages, getLanguage } from '../../utils/index';
import { Routes } from '../../config/routes';
import l from '../../lang';
import './styles.scss';
import icEmailCheck from '../../assets/images/emailCheck.svg?url';
import CheckBlue from '../../assets/icons/check-blue.svg?url';
import DollarCycleBlue from '../../assets/icons/dollar-cycle-blue.svg?url';
import CreditCardBlue from '../../assets/icons/credit-card-blue.svg?url';
import Loading from '../../components/common/loading';
import config from '../../config';
import useMerchantReferenceParams from '../../hooks/use-merchant-reference-param';
import { useCommon } from '../../store/common';
import Card from '@mui/material/Card';

const SignUpForm = lazy(() => import('../../components/sign-up-form'));
const LanguageSelect = lazy(() => import('../../components/language-select'));
const HelpButton = lazy(() => import('../../components/common/help-button'));
const Logo = lazy(() => import('../../components/common/logo'));

const SignUp = ({ loading, error, errorInfo, success, onSendSignUp }) => {
  const { changeLanguage } = useCommon();
  const merchantReference = useMerchantReferenceParams();
  const [emailEntered, setEmailEntered] = useState('');
  const handleSendSignUp = values => {
    setEmailEntered(values.email);
    onSendSignUp({ ...values, merchantReference });
  };

  return (
    <React.Suspense fallback={<Loading fullScreen />}>
      <div className="sign-up">
        <div className="sign-up-header-container">
          <div className="sign-up-header">
            <div className="language-select-container">
              <LanguageSelect languages={languages} selected={getLanguage()} onClick={changeLanguage} />
              <div className="help-button-container">
                <HelpButton />
              </div>
            </div>
            <div className="sign-up-logo">
              <Logo type="horizontal" />
            </div>
          </div>
        </div>
        <Grid container className="sign-up-boxes" alignItems="center" justify="center">
          {!success && (
            <Grid item xs={12} md={6}>
              <div className="sign-up-welcome">
                <h1>{Parser(l('signUp.intro'))}</h1>
                <div>
                  <ul>
                    <li>
                      <Card className={'signup-card'}>
                        <img src={CreditCardBlue} alt="checked" />
                        <div className="signup-card-content">
                          <p>
                            <span>{l('signUp.intro.1.title')}</span>
                          </p>
                          <p>{l('signUp.intro.1.subtitle')}</p>
                        </div>
                      </Card>
                    </li>
                    <li>
                      <Card className={'signup-card'}>
                        <img src={DollarCycleBlue} alt="checked" />
                        <div className="signup-card-content">
                          <p>
                            <span>{l('signUp.intro.2.title')}</span>
                          </p>
                          <p>{l('signUp.intro.2.subtitle')}</p>
                        </div>
                      </Card>
                    </li>
                    <li>
                      <Card className={'signup-card'}>
                        <img src={CheckBlue} alt="checked" />
                        <div className="signup-card-content">
                          <p>
                            <span>{l('signUp.intro.3.title')}</span>
                          </p>
                          <p>{l('signUp.intro.3.subtitle')}</p>
                        </div>
                      </Card>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <div className="panel sign-up-container">
              {loading && <Loading fullScreen />}
              {!success && <SignUpForm loading={loading} error={error} errorInfo={errorInfo} onSubmit={handleSendSignUp} />}
              {success && config.emailValidationOnSignUp && (
                <div className="sign-up-container__email-sent">
                  <img src={icEmailCheck} className="sign-up-container__email-sent__img" alt="ic-email-sent" />
                  <Typography variant="h5">{Parser(l('signUp.emailSent.message.title'))}</Typography>
                  <Typography variant="body1">{Parser(l('signUp.emailSent.message.desc', emailEntered))}</Typography>
                </div>
              )}
              {success && !config.emailValidationOnSignUp && <Redirect to={Routes.LOGIN} />}
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Suspense>
  );
};

SignUp.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  success: PropTypes.bool,
  onSendSignUp: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.signUp.loading,
  error: state.signUp.error,
  errorInfo: state.signUp.errorInfo,
  success: state.signUp.success,
});

const mapDispatchToProps = dispatch => ({
  onSendSignUp: values => dispatch(sendSignUp(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
