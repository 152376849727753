import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import './styles.scss';
import Avatar from '../../common/avatar';
import ChangePassword from './change-password';
import PersonalInfo from './personal-info';

const Profile = ({ onClose }) => {
  return (
    <div className="profile">
      <div className="profile__avatar">
        <Avatar size={80} />
      </div>
      <div className="profile__close">
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <PersonalInfo />
      <ChangePassword />
    </div>
  );
};

Profile.propTypes = {
  onClose: PropTypes.func,
};

export default Profile;
