import l from '../../lang';

export const settlementStatus = () => {
  return {
    PENDING: l('settlements.status.PENDING'),
    COMPLETED: l('settlements.status.COMPLETED'),
    CANCELLED: l('settlements.status.CANCELLED'),
    FAILED: l('settlements.status.FAILED'),
    DELIVERED: l('settlements.status.DELIVERED'),
    REJECTED: l('settlements.status.REJECTED'),
  };
};

export const accountTypesForSettlements = () => {
  return {
    CHECKING: l('accountType.CHECKING'),
    SAVING: l('accountType.SAVING'),
    SALARY: l('accountType.SALARY'),
    VISTA: l('accountType.VISTA'),
    MASTER: l('accountType.MASTER'),
  };
};
