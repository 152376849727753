import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Parser from 'html-react-parser';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Input from '../common/input';
import ButtonCustom from '../common/__deprecated__/button-custom';
import { commons as validations } from '../../utils/validations';
import { Routes } from '../../config/routes';
import { operatingCountriesForSelect } from '../../utils/common-utils';
import InputCheckbox from '../common/input-checkbox';
import Select from '../common/select';
import l from '../../lang';
import './styles.scss';
import MessageError from '../common/message-error';
import { scrollIntoView } from '../../utils';
import ButtonLink from '../common/button-link';
import TooltipIcon from '../common/tooltip-icon';
import Dialog from '../common/dialog';
import PrivacyPolicies from '../common/privacy-policies';
import TermsAndConditions from '../common/terms-and-conditions';
import { segmentSignUpPrivacyPolicy, segmentSignUpTc } from '../../utils/analytics';

const KEY_RECAPTCHA = import.meta.env.REACT_APP_RECAPTCHA_SITE_KEY_DLGO;
const ACTION = 'signup';

const initialValues = {
  name: '',
  email: '',
  password: '',
  country: '',
  company: '',
  functionalCurrency: '',
  marketingConsent: true,
  termsAndConditions: false,
};

const SignUpForm = ({ loading, error, errorInfo, onSubmit }) => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const functionalCurrencyRef = useRef(null);
  const marketingConsentRef = useRef(null);
  const companyTypeRef = useRef(null);
  const termsAndConditionsRef = useRef(null);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacyPolicies, setShowPrivacyPolicies] = useState(false);

  const history = useHistory();

  const [showError, setShowError] = useState(true);
  useEffect(() => loadReCaptcha(KEY_RECAPTCHA), []);

  const validate = values => {
    const errors = {};
    if (!values.email) {
      errors.email = l('form.input.required');
    } else if (!validations.emailValid(values.email)) {
      errors.email = l('form.email.invalid');
    }
    if (!values.password) {
      errors.password = l('form.input.required');
    } else if (!validations.passwordRequiredCharacters(values.password)) {
      errors.password = l('form.input.password.invalid');
    } else if (!validations.passwordLengthRequired(values.password)) {
      errors.password = l('form.input.password.invalidLength');
    }
    if (!values.name || !validations.nameValid(values.name)) {
      errors.name = l('form.input.required');
    }
    if (!values.country) {
      errors.country = l('form.input.required');
    }
    if (!values.termsAndConditions) {
      errors.termsAndConditions = l('form.input.termsAndConditions.invalid');
    }
    if (values.company === '') {
      errors.company = l('form.input.required');
    }
    return errors;
  };

  const onClickTermsConditionsHandler = () => {
    segmentSignUpTc();
    setShowTermsAndConditions(true);
  };

  const onClickPrivacyPoliciesHandler = () => {
    segmentSignUpPrivacyPolicy();
    setShowPrivacyPolicies(true);
  };

  return (
    <div className="sign-up-form">
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          setShowError(true);
          setSubmitting(true);
          if (window.grecaptcha) {
            window.grecaptcha.execute(KEY_RECAPTCHA, { action: ACTION }).then(token => {
              onSubmit({
                name: values.name,
                email: values.email,
                password: values.password,
                country: values.country,
                marketingConsent: values.marketingConsent,
                termsAndConditions: values.termsAndConditions,
                company: values.company,
                recaptcha: token,
              });
              setSubmitting(false);
            });
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <h1 className="signup-title">{l('signup.dlocalCreateAccount')}</h1>
            <div ref={emailRef}>
              <Input
                type="email"
                name="email"
                label={l('emailAddress.label')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                maxLength={100}
                disabled={loading}
                error={touched.email && errors.email}
              />
            </div>
            <div ref={nameRef}>
              <Input
                type="name"
                name="name"
                label={l('name.label')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                maxLength={100}
                disabled={loading}
                error={touched.name && errors.name}
                message={l('name.helpText')}
              />
            </div>
            <div ref={companyTypeRef} className="country-input">
              <Select
                name="company"
                label={l('companyType.label')}
                value={values.company}
                onChange={e => {
                  handleChange(e);
                }}
                optionsArray={[
                  { displayName: l('companyType.company'), value: true },
                  { displayName: l('companyType.physical'), value: false },
                ]}
                disabled={loading}
                error={touched.company && errors.company}
              />
              <div className="country-popup">
                <TooltipIcon text={Parser(l('companyType.tooltip'))} icon={<InfoIcon color="primary" />} />
              </div>
            </div>
            <div ref={functionalCurrencyRef} className="country-input">
              <Select
                name="country"
                label={l('businessCountry.label')}
                value={values.country}
                onChange={e => {
                  // Disable by default "marketingConsent" for these countries.
                  if (['BR', 'ES', 'US'].includes(e.target.value)) {
                    setFieldValue('marketingConsent', false);
                  }
                  handleChange(e);
                }}
                options={operatingCountriesForSelect()}
                disabled={loading}
                error={touched.country && errors.country}
              />
              <div className="country-popup">
                <TooltipIcon text={Parser(l('businessCountry.tooltip'))} icon={<InfoIcon color="primary" />} />
              </div>
            </div>
            <div ref={passwordRef}>
              <Input
                type="password"
                name="password"
                label={l('password.label')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                maxLength={50}
                disabled={loading}
                error={touched.password && errors.password}
                message={l('password.helpText')}
              />
            </div>
            <div className="marketing-consent" ref={marketingConsentRef}>
              <div className="marketing-consent-container">
                <InputCheckbox
                  type="checkbox"
                  name="marketingConsent"
                  label={l('marketingConsent.label')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.marketingConsent}
                  disabled={loading || !values.country}
                />
              </div>
              <div className="marketing-consent-container" ref={termsAndConditionsRef}>
                <InputCheckbox
                  type="checkbox"
                  name="termsAndConditions"
                  label={
                    <span>
                      <span>{l('signUp.termsAndConditions.label')} </span>
                      <ButtonLink text={l('signUp.termsAndConditions')} onClick={onClickTermsConditionsHandler} />
                      <span> {l('signUp.policy.label')} </span>
                      <ButtonLink text={l('signUp.policy')} onClick={onClickPrivacyPoliciesHandler} />
                    </span>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.termsAndConditions}
                  disabled={loading}
                  error={touched.termsAndConditions && errors.termsAndConditions}
                />
              </div>
            </div>
            <div>
              <div className="sign-up-error">
                {error && showError && <MessageError errorInfo={errorInfo} onClose={() => setShowError(false)} />}
              </div>
              <div className="btn-sign-up-container">
                <ButtonCustom
                  name="btn-sign-up"
                  type="submit"
                  text={l('signUp.register.btn')}
                  textLoading={l('form.sending')}
                  loading={isSubmitting || loading}
                  size="full-square"
                  variant="contained"
                  onClick={() => {
                    if (!_.isEmpty(errors)) {
                      if (errors.email) {
                        scrollIntoView(emailRef);
                      } else if (errors.password) {
                        scrollIntoView(passwordRef);
                      } else if (errors.name) {
                        scrollIntoView(nameRef);
                      } else if (errors.termsAndConditions) {
                        scrollIntoView(termsAndConditionsRef);
                      }
                    }
                  }}
                />
              </div>
              <div className="link-login">
                <span>{l('signUp.alreadyHaveAccount')} </span>
                <ButtonLink
                  text={l('login.btn')}
                  onClick={() => history.push(`${Routes.LOGIN}${window.document.location.search}`)}
                />
              </div>
            </div>
            <ReCaptcha sitekey={KEY_RECAPTCHA} action={ACTION} />
          </form>
        )}
      </Formik>
      <Dialog
        open={showPrivacyPolicies}
        type="lg"
        dividers={false}
        fullScreenMobile
        titleCenter
        handleClose={() => setShowPrivacyPolicies(false)}
        body={<PrivacyPolicies />}
      />
      <Dialog
        open={showTermsAndConditions}
        type="lg"
        dividers={false}
        fullScreenMobile
        titleCenter
        handleClose={() => setShowTermsAndConditions(false)}
        body={<TermsAndConditions />}
      />
    </div>
  );
};

SignUpForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default SignUpForm;
