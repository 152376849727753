import l from '../../../../../../../lang';
import { Plugin, ChartOptions } from 'chart.js';
import { TransactionsByPaymentMethodData } from '../../../../../../../store/reports/interfaces.d';

export const chartColors = ['#2635C8', '#1759E7', '#006CFA', ' #0080FF', '#00A0FF', '#4CB2FF', '#87C8FF', '#B8DDFF'];
export const chartEmptyColor = '#F4F6F8';

export const getOptions = (
  data: TransactionsByPaymentMethodData[],
  emptyChart: boolean
): ChartOptions<'doughnut'> & { plugins: PluginEmptyDoughnut } => ({
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 3 / 2,
  layout: {
    padding: ctx => {
      return emptyChart ? 20 : data.length === 1 ? 40 : 80;
    },
  },
  elements: {
    line: {
      fill: false,
      tension: 0.4,
    },
    point: {
      hoverRadius: 7,
      radius: 5,
    },
  },
  plugins: {
    emptyDoughnut: {
      color: chartEmptyColor,
      width: 2,
      radiusDecrease: 0,
    },
    legend: {
      display: emptyChart,
      position: 'bottom',
      labels: {
        usePointStyle: true,
        boxHeight: 6,
        boxWidth: 6,
      },
    },
    datalabels: {
      labels: {
        index: {
          align: 'end',
          anchor: 'end',
          textAlign: 'center',
          color: ctx => {
            return `${ctx.dataset.backgroundColor}`;
          },
          font: { size: 12 },
          formatter: (value, ctx) => {
            return ctx.chart.data.labels && `${ctx.chart.data.labels[ctx.dataIndex]}`.split(' ');
          },
          offset: 2,
        },
        value: {
          align: 'center',
          color: ctx => {
            return `#fff`;
          },
          formatter: (value, ctx) => {
            return `${value}%`;
          },
        },
      },
    },
    tooltip: {
      borderColor: '#ececec',
      borderWidth: 1,
      backgroundColor: '#fff',
      titleColor: '#454f5b',
      titleFont: {
        weight: '700',
        size: 12,
      },
      bodyColor: '#454f5b',
      padding: 15,
      usePointStyle: true,
      callbacks: {
        title: context => {
          return `${context[0].label} | ${context[0].parsed}%`;
        },
        label: context => {
          const number = data[context.dataIndex].count;
          return `${number} ${l(number == 1 ? 'reports.transactionCount' : 'reports.transactionsCount')}`;
        },
      },
    },
  },
});

interface PluginEmptyDoughnut {
  emptyDoughnut: { color: string; width: number; radiusDecrease: number };
}

export const plugin: Plugin = {
  id: 'emptyDoughnut',
  afterDraw(chart, args, options) {
    const { datasets } = chart.data;
    const { color, width, radiusDecrease } = options;
    const hasData = datasets.some(dataset => dataset.data.length > 0);

    if (!hasData) {
      const {
        chartArea: { left, top, right, bottom },
        ctx,
      } = chart;
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const r = Math.min(right - left, bottom - top) / 2;

      ctx.beginPath();
      ctx.lineWidth = width || 2;
      ctx.strokeStyle = color || chartEmptyColor;
      ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.fillStyle = color || chartEmptyColor;
      ctx.fill();
    }
  },
};
