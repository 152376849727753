import { Values } from '../interfaces';

export enum SignUpActionType {
  SEND_SIGN_UP = 'SIGN_UP/SEND_SIGN_UP',
  SENDING_SIGN_UP = 'SIGN_UP/SENDING_SIGN_UP',
  SIGN_UP_SUCCESS = 'SIGN_UP/SIGN_UP_SUCCESS',
  SIGN_UP_ERROR = 'SIGN_UP/SIGN_UP_ERROR',
}

export const sendSignUp = (values: Values) => ({
  type: SignUpActionType.SEND_SIGN_UP,
  values,
});

export const sendingSignUp = () => ({
  type: SignUpActionType.SENDING_SIGN_UP,
});

export const signUpSuccess = (response: any) => ({
  type: SignUpActionType.SIGN_UP_SUCCESS,
  response,
});

export const signUpError = (error: any) => ({
  type: SignUpActionType.SIGN_UP_ERROR,
  error,
});
