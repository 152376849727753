import { handleErrorInfo } from '../../utils';
import { SettlementsActionType } from './actions';
import { SettlementsState } from './interfaces';

const initialState: SettlementsState = {
  loading: true,
  error: false,
  errorInfo: undefined,
  allSettlements: undefined,
  showRequestSettlement: false,
  requestSuccess: false,
  beneficiaries: {
    loading: false,
    error: false,
    errorInfo: undefined,
    addBeneficiarySuccess: false,
    data: [],
  },
};

const SettlementsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SettlementsActionType.SHOW_REQUEST_SETTLEMENT: {
      return {
        ...state,
        showRequestSettlement: action.show,
        requestSuccess: action.show ? state.requestSuccess : false,
        error: false,
        errorInfo: undefined,
      };
    }
    case SettlementsActionType.GET_SETTLEMENTS: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
      };
    }
    case SettlementsActionType.GET_SETTLEMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: {},
        allSettlements: {
          ...action.response,
        },
      };
    }
    case SettlementsActionType.GET_SETTLEMENTS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: null,
        allSettlements: null,
      };
    }
    case SettlementsActionType.GET_BENEFICIARIES: {
      return {
        ...state,
        beneficiaries: {
          ...state.beneficiaries,
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case SettlementsActionType.GET_BENEFICIARIES_SUCCESS: {
      return {
        ...state,
        beneficiaries: {
          ...state.beneficiaries,
          loading: false,
          error: false,
          errorInfo: undefined,
          data: action.response,
        },
      };
    }
    case SettlementsActionType.GET_BENEFICIARIES_ERROR: {
      return {
        ...state,
        beneficiaries: {
          ...state.beneficiaries,
          loading: false,
          error: true,
          errorInfo: undefined,
          data: [],
        },
      };
    }
    case SettlementsActionType.REQUEST_SETTLEMENT: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        requestSuccess: false,
      };
    }
    case SettlementsActionType.REQUEST_SETTLEMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        requestSuccess: true,
      };
    }
    case SettlementsActionType.REQUEST_SETTLEMENT_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(SettlementsActionType.REQUEST_SETTLEMENT_ERROR, action.error),
        requestSuccess: false,
      };
    }
    default:
      return state;
  }
};

export default SettlementsReducer;
