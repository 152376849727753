import _ from 'lodash';
import { PaymentLinkActionType } from './actions';
import { errorFieldsCode, invalidCountryCode, invalidDocumentCode, invalidMinimumAmount } from '../../utils/error-codes';
import { handleErrorInfo } from '../../utils/index';
import { PaymentLinkState } from './interfaces';
import { Currencies, DocumentTypes, ErrorFields, ExchangeRates } from '../interfaces';
import { CreatedPaymentLinkType } from '../../constants';

const initialState: PaymentLinkState = {
  loading: false,
  error: false,
  errorInfo: undefined,
  errorFields: undefined,
  createSuccess: null,
  showResult: false,
  currencies: {},
  exchangeRates: {},
  documentTypes: {},
  tabSelected: CreatedPaymentLinkType.ONE_TIME,
  createdOneTime: {
    loading: false,
    error: false,
    errorInfo: undefined,
    allPaymentLinks: undefined,
  },
  createdRecurring: {
    loading: false,
    error: false,
    errorInfo: undefined,
    allPaymentLinks: undefined,
  },
  existingClients: {
    loading: false,
    error: false,
    errorInfo: undefined,
    clients: undefined,
  },
};

const PaymentLinkReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PaymentLinkActionType.INIT_PAYMENT_LINK: {
      return {
        ...state,
        error: false,
        errorInfo: undefined,
        currencies: {},
        documentTypes: {},
      };
    }
    case PaymentLinkActionType.CLEAN_ERROR_PAYMENT_LINK: {
      return {
        ...state,
        error: false,
        errorInfo: undefined,
      };
    }
    case PaymentLinkActionType.GET_CURRENCIES: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        currencies: {},
        exchangeRates: {},
      };
    }
    case PaymentLinkActionType.GET_CURRENCIES_SUCCESS: {
      const currencies: Currencies = {};
      const exchangeRates: ExchangeRates = {};
      action.response.forEach((c: any) => {
        currencies[c.currency] = c.currency;
        exchangeRates[c.currency] = c.exchangeRate;
      });

      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        currencies,
        exchangeRates,
      };
    }
    case PaymentLinkActionType.GET_CURRENCIES_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(PaymentLinkActionType.GET_CURRENCIES_ERROR, action.error),
        currencies: {},
        exchangeRates: {},
      };
    }
    case PaymentLinkActionType.CLEAN_CURRENCIES: {
      return {
        ...state,
        currencies: {},
        exchangeRates: {},
      };
    }
    case PaymentLinkActionType.PAYMENT_LINK_RESULT: {
      return {
        ...state,
        ...(action.show
          ? { showResult: true, createSuccess: state.createSuccess }
          : {
              showResult: false,
              createSuccess: null,
              currencies: {},
              documentTypes: {},
            }),
      };
    }
    case PaymentLinkActionType.GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        documentTypes: {},
      };
    }
    case PaymentLinkActionType.GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK_SUCCESS: {
      const documentTypes: DocumentTypes = {};
      action.response.forEach((dt: any) => {
        documentTypes[dt.documentType] = dt.documentType;
      });
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        documentTypes,
      };
    }
    case PaymentLinkActionType.GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(PaymentLinkActionType.GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK_ERROR, action.error),
        documentTypes: {},
      };
    }
    case PaymentLinkActionType.CREATE_PAYMENT_LINK: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        errorFields: undefined,
        createSuccess: null,
      };
    }
    case PaymentLinkActionType.CREATE_PAYMENT_LINK_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        createSuccess: {
          ...action.response,
        },
      };
    }
    case PaymentLinkActionType.CREATE_PAYMENT_LINK_ERROR: {
      let errorInfo = handleErrorInfo(PaymentLinkActionType.CREATE_PAYMENT_LINK_ERROR, action.error);
      const errorFields: ErrorFields = {};
      let error = true;

      if (errorInfo && errorInfo.code === errorFieldsCode && errorInfo.errorFields) {
        errorInfo.errorFields.forEach((field: string) => {
          errorFields[field] = action.error.sentValues[field];
        });
        error = false;
        errorInfo = undefined;
      } else if (errorInfo && errorInfo.code === invalidDocumentCode) {
        errorFields.clientDocument = action.error.sentValues.clientDocument;
      } else if (errorInfo && errorInfo.code === invalidCountryCode) {
        errorFields.country = action.error.sentValues.country;
      } else if (errorInfo && errorInfo.code === invalidMinimumAmount) {
        errorFields.amount = action.error.sentValues.amount;
      }

      return {
        ...state,
        loading: false,
        error,
        errorInfo,
        errorFields,
        createSuccess: undefined,
      };
    }
    case PaymentLinkActionType.GET_EXISTING_CLIENTS: {
      return {
        ...state,
        existingClients: {
          ...state.existingClients,
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case PaymentLinkActionType.GET_EXISTING_CLIENTS_SUCCESS: {
      return {
        ...state,
        existingClients: {
          ...state.existingClients,
          loading: false,
          clients: {
            ...action.response,
          },
        },
      };
    }
    case PaymentLinkActionType.GET_EXISTING_CLIENTS_ERROR: {
      return {
        ...state,
        existingClients: {
          ...state.existingClients,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(PaymentLinkActionType.GET_EXISTING_CLIENTS_ERROR, action.error),
          clients: undefined,
        },
      };
    }
    case PaymentLinkActionType.CHANGE_TAB_SELECTED: {
      return {
        ...state,
        tabSelected: action.tabSelected,
      };
    }
    case PaymentLinkActionType.GET_CREATED_ONE_TIME_PAYMENT_LINKS: {
      return {
        ...state,
        createdOneTime: {
          ...state.createdOneTime,
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case PaymentLinkActionType.GET_CREATED_ONE_TIME_PAYMENT_LINKS_SUCCESS: {
      return {
        ...state,
        createdOneTime: {
          ...state.createdOneTime,
          loading: false,
          allPaymentLinks: {
            ...action.response,
          },
        },
      };
    }
    case PaymentLinkActionType.GET_CREATED_ONE_TIME_PAYMENT_LINKS_ERROR: {
      return {
        ...state,
        createdOneTime: {
          ...state.createdOneTime,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(PaymentLinkActionType.GET_CREATED_ONE_TIME_PAYMENT_LINKS_ERROR, action.error),
          allPaymentLinks: null,
        },
      };
    }
    case PaymentLinkActionType.GET_CREATED_RECURRING_PAYMENT_LINKS: {
      return {
        ...state,
        createdRecurring: {
          ...state.createdRecurring,
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case PaymentLinkActionType.GET_CREATED_RECURRING_PAYMENT_LINKS_SUCCESS: {
      return {
        ...state,
        createdRecurring: {
          ...state.createdRecurring,
          loading: false,
          allPaymentLinks: {
            ...action.response,
          },
        },
      };
    }
    case PaymentLinkActionType.GET_CREATED_RECURRING_PAYMENT_LINKS_ERROR: {
      return {
        ...state,
        createdRecurring: {
          ...state.createdRecurring,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(PaymentLinkActionType.GET_CREATED_RECURRING_PAYMENT_LINKS_ERROR, action.error),
          allPaymentLinks: null,
        },
      };
    }
    case PaymentLinkActionType.CHANGE_STATUS_RECURRING_PAYMENT_LINK: {
      return {
        ...state,
        createdRecurring: {
          ...state.createdRecurring,
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case PaymentLinkActionType.CHANGE_STATUS_RECURRING_PAYMENT_LINK_SUCCESS: {
      const paymentLinkModified = action.response;
      const data = state.createdRecurring.allPaymentLinks?.data.map(pl =>
        paymentLinkModified.recurringLinkToken === pl.recurringLinkToken
          ? {
              ...pl,
              enabled: paymentLinkModified.enabled,
            }
          : pl
      );
      return {
        ...state,
        createdRecurring: {
          ...state.createdRecurring,
          loading: false,
          allPaymentLinks: {
            ...state.createdRecurring.allPaymentLinks,
            data,
          },
        },
      };
    }
    case PaymentLinkActionType.CHANGE_STATUS_RECURRING_PAYMENT_LINK_ERROR: {
      return {
        ...state,
        createdRecurring: {
          ...state.createdRecurring,
          loading: false,
          errorInfo: handleErrorInfo(PaymentLinkActionType.CHANGE_STATUS_RECURRING_PAYMENT_LINK_ERROR, action.error),
        },
      };
    }
    default:
      return state;
  }
};

export default PaymentLinkReducer;
