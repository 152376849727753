import { useEffect, useState } from 'react';
import l from '../../../lang';
import TopDashboardSection from '../../common/top-dashboard-section';
import ButtonMore from '../../common/button-more';
import AddNewBankAccount from './add-bank-account-forms/add-bank-account';
import ButtonLink from '../../common/button-link';
import AlertMessages from '../alert-messages';
import { segmentBankAccountsBaDetail } from '../../../utils/analytics';
import Card from '../../common/card';
import CardDescription from '../../common/card-description';
import { Button } from '../../common/button';
import CardInfo from '../../common/card-info';
import { useBankAccounts } from '../../../store/bank-accounts';
import { useDashboard } from '../../../store/dashboard';
import { BankAccount } from '../../../store/bank-accounts/interfaces';
import { Tooltip } from '../../common/tooltip';
import Loading from '../../common/loading';
import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';
import BankAccountPills from './BankAccountPills';
import BankAccountDetails from './bank-account-details';
import BankAccountEmpty from './bank-account-empty';
import BankAccountDeleteError from './bank-account-delete-error';
import BankAccountNewSuccess from './bank-account-new-success';

import './styles.scss';

const MAX_ACCOUNTS = 3;

const BankAccounts = () => {
  const {
    loading: allBankAccountsLoading,
    allBankAccounts,
    newBankAccount,
    deletedBankAccount,
    bankAccountResetChanges,
    getBankAccounts,
  } = useBankAccounts();
  const [isOpenBankAccount, setIsOpenBankAccount] = useState(false);
  const { success: newBankAccountSuccess, loading: newBankAccountLoading } = newBankAccount;
  const {
    success: deletedBankAccountSuccess,
    loading: deletedBankAccountLoading,
    error: deletedBankAccountError,
  } = deletedBankAccount;

  const { merchantMe } = useDashboard();
  const { kycLevel, validKYC } = merchantMe || {};
  const { level: currentKYC } = kycLevel || {};

  const [bankAccountDetail, setBankAccountDetail] = useState<BankAccount>();

  useEffect(() => {
    getBankAccounts();
  }, []);

  useEffect(() => {
    if (deletedBankAccountSuccess || newBankAccountSuccess) {
      getBankAccounts();
    }
  }, [deletedBankAccountSuccess, newBankAccountSuccess]);

  useEffect(() => {
    if (deletedBankAccountSuccess || deletedBankAccountError) {
      setBankAccountDetail(undefined);
    }
  }, [deletedBankAccountSuccess, deletedBankAccountError]);

  useEffect(() => {
    if (newBankAccountSuccess && isOpenBankAccount) {
      setIsOpenBankAccount(false);
    }
  }, [newBankAccountSuccess, bankAccountDetail]);

  const totalAccounts = allBankAccounts?.data?.length ?? 0;

  const isLoading = allBankAccountsLoading || deletedBankAccountLoading || newBankAccountLoading;

  return (
    <>
      <TopDashboardSection
        title={l('menu.item.BANK_ACCOUNTS')}
        componentBottom={
          <div className="bank_accounts__back_btn">
            {(isOpenBankAccount || bankAccountDetail) && (
              <ButtonLink
                type="secondary"
                text={
                  <>
                    <BackIcon /> {l('bankAccounts.backBtn')}
                  </>
                }
                onClick={() => {
                  setIsOpenBankAccount(false);
                  setBankAccountDetail(undefined);
                }}
              />
            )}
          </div>
        }
      />
      <AlertMessages />
      {isLoading && <Loading fullScreen />}
      {deletedBankAccountError && <BankAccountDeleteError onClose={() => bankAccountResetChanges()} />}
      {newBankAccountSuccess && <BankAccountNewSuccess onClose={() => bankAccountResetChanges()} />}
      {bankAccountDetail && <BankAccountDetails {...bankAccountDetail} />}
      {isOpenBankAccount && <AddNewBankAccount />}
      {!isOpenBankAccount && !bankAccountDetail && (
        <Card>
          <CardDescription>
            <div className="bank_accounts__description">
              <span>{l('bankAccounts.subheadline')}</span>
              <span>
                <Tooltip
                  title={totalAccounts >= MAX_ACCOUNTS ? l('bankAccounts.callToAction.tooltip.disabled') : ''}
                  enterTouchDelay={0}
                  classes={{
                    tooltip: 'bank_accounts__tooltip',
                    arrow: 'bank_accounts__tooltip__arrow',
                  }}
                >
                  <span>
                    <Button fullWidth onClick={() => setIsOpenBankAccount(true)} disabled={totalAccounts >= MAX_ACCOUNTS}>
                      {l('bankAccounts.callToAction')}
                    </Button>
                  </span>
                </Tooltip>
              </span>
            </div>
          </CardDescription>
          <div className="bank_accounts">
            {(Boolean(allBankAccounts?.data?.length) || currentKYC === 4) && validKYC ? (
              <div className="three-cards">
                {allBankAccounts?.data?.map(account => (
                  <CardInfo key={account.bankAccount}>
                    <div className="bank_accounts__more">
                      <ButtonMore
                        onClick={() => {
                          segmentBankAccountsBaDetail();
                          setBankAccountDetail(account);
                        }}
                      />
                    </div>
                    <BankAccountPills status={account.status} preferred={account.preferred} />
                    <div className="bank_accounts__name">{account.bankName}</div>
                    <div className="bank_accounts__number">
                      {l('bankAccounts.number')}
                      {account.bankAccount.slice(-4)}
                    </div>
                    {account.accountType ? (
                      <div className="bank_accounts__type">{l(`bankAccounts.type.${account.accountType}`)}</div>
                    ) : null}
                  </CardInfo>
                ))}
              </div>
            ) : (
              <BankAccountEmpty />
            )}
          </div>
        </Card>
      )}
    </>
  );
};

export default BankAccounts;
