import React from 'react';
import './styles.scss';

interface CardTitleProps {
  children: React.ReactNode;
}

const CardTitle = ({ children }: CardTitleProps) => <div className="card-title">{children}</div>;

export default CardTitle;
