import LinkIcon from '@mui/icons-material/LinkOutlined';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyIcon from '../../../../assets/icons/copy.svg?url';
import l from '../../../../lang';
import Input from '../../../common/input';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import ShareButtons from './share-buttons';
import './styles.scss';
import { segmentPaymentLinkLinkBoxCopyLink } from '../../../../utils/analytics';

interface ShareLinkProps {
  url: string;
}

const ShareLink = ({ url }: ShareLinkProps) => {
  return (
    <div className="share_link">
      <div className="share_link__subheadline">{l('paymentLink.shareLink.subheadline1')}</div>
      <div className="share_link__link">
        <div className="input">
          <Input name="copyLink" value={url} prefix={<LinkIcon color="primary" />} readOnly disabled />
        </div>
        <CopyToClipboard text={url} onCopy={() => segmentPaymentLinkLinkBoxCopyLink()}>
          <ButtonCustom
            name="btn-confirm"
            text={l('paymentLink.shareLink.copyLink')}
            color="primary"
            variant="contained"
            startIcon={<img className="share_link__img_copy" src={CopyIcon} />}
          />
        </CopyToClipboard>
      </div>
      <ShareButtons link={url} />
    </div>
  );
};

export default ShareLink;
