import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Refresh as RefreshIcon, EditOutlined as EditIcon } from '@mui/icons-material';
import Parser from 'html-react-parser';
import {
  getSelectedPaymentMethods,
  getAllPaymentMethods,
  postAddPaymentMethod,
  deletePaymentMethod,
  editCoverage,
  saveCoverage,
} from '../../../store/payment-methods/actions';
import Dialog from '../../common/dialog';
import PaymentMethodList from './payment-method-list';
import Loading from '../../common/loading';
import l from '../../../lang';
import './styles.scss';
import ButtonCustom from '../../common/__deprecated__/button-custom';
import MessageError from '../../common/message-error';
import { realoadPage } from '../../../utils/index';
import TopDashboardSection from '../../common/top-dashboard-section';
import Coverage from './coverage';
import { segmentPaymentMethodEditCoverage } from '../../../utils/analytics';

const PaymentMethods = ({
  loading,
  error,
  errorInfo,
  paymentMethodsGrouped,
  paymentMethodsSelected,
  allPaymentMethods,
  statusAdd,
  statusDelete,
  creditCardEnabled,
  showEditCoverage,
  onGetSelectedPaymentMethods,
  onGetAllPaymentMethods,
  onAddPaymentMethod,
  onDeletePaymentMethod,
  onEditCoverage,
  onSaveCoverage,
}) => {
  const [currentCountrySelection, setCurrentCountrySelection] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  useEffect(() => {
    onGetSelectedPaymentMethods();
    if (selectedCountries.length && !showEditCoverage) {
      onGetAllPaymentMethods(selectedCountries.join(','));
    }
  }, [showEditCoverage]);

  useEffect(() => {
    const currentSelection = Object.keys(paymentMethodsGrouped);
    if (currentSelection.length) {
      setCurrentCountrySelection(currentSelection);

      if (!selectedCountries.length) {
        onGetAllPaymentMethods(currentSelection.join(','));
      }
      setSelectedCountries(currentSelection);
    }
  }, [paymentMethodsGrouped]);

  return (
    <div>
      <TopDashboardSection
        title={l('menu.item.PAYMENT_METHODS.title')}
        subtitle={l('menu.item.PAYMENT_METHODS.desc')}
        componentBottom={
          !showEditCoverage && (
            <div className="btn-edit-coverage">
              <ButtonCustom
                type="submit"
                text={l('paymentMethod.editCoverage')}
                textLoading={l('form.sending')}
                size="full-square"
                startIcon={<EditIcon />}
                onClick={() => {
                  segmentPaymentMethodEditCoverage();
                  onEditCoverage(true)
                }}
              />
            </div>
          )
        }
      />
      <div className="panel payment-methods">
        {deleteConfirm && (
          <Dialog
            dividers={false}
            title={l('paymentMethod.confirm.title')}
            unpaddingFooter
            unpaddingContent
            body={<div className="confirm-body-container">{l('paymentMethod.confirm.body')}</div>}
            footer={
              <div className="confirm-footer-container">
                <ButtonCustom
                  text={l('common.cancel')}
                  size="half-left"
                  onClick={() => setDeleteConfirm(null)}
                />
                <ButtonCustom
                  name="btn-confirm"
                  text={l('common.confirm')}
                  variant="contained"
                  size="half-right"
                  onClick={() => {
                    setDeleteConfirm(null);
                    onDeletePaymentMethod(deleteConfirm);
                  }}
                />
              </div>
            }
          />
        )}
        {loading && (
          <div className="payment-methods__loading">
            <Loading />
          </div>
        )}
        {statusDelete.loading && <Loading fullScreen />}
        {error && (
          <div className="payment-methods__error">
            <MessageError errorInfo={errorInfo} iconClose={<RefreshIcon />} onClose={() => realoadPage()} />
          </div>
        )}
        {!loading && !error && (
          <>
            {showEditCoverage && (
              <>
                <div className="payment-methods__subtitle">{Parser(l('paymentMethods.coverageSubtitle'))}</div>
                <div className="payment-methods__desc">{Parser(l('paymentMethods.coverageDesc'))}</div>
                {currentCountrySelection.length && (
                  <Coverage
                    countriesWithPMs={currentCountrySelection}
                    cancelEdit={() => onEditCoverage(false)}
                    saveSelection={countries => {
                      setSelectedCountries(countries);
                      onSaveCoverage(currentCountrySelection, countries);
                    }}
                  />
                )}
              </>
            )}
            {!showEditCoverage && (
              <>
                <div className="payment-methods__subtitle">{Parser(l('paymentMethods.subtitle'))}</div>
                <div className="payment-methods__desc">{Parser(l('paymentMethods.desc'))}</div>
                <PaymentMethodList
                  loading={statusAdd.loading}
                  error={statusAdd.error}
                  selectedCountries={selectedCountries}
                  paymentMethodsSelected={paymentMethodsSelected}
                  allPaymentMethods={allPaymentMethods}
                  creditCardEnabled={creditCardEnabled}
                  addPaymentMethod={onAddPaymentMethod}
                  removePaymentMethod={id => setDeleteConfirm(id)}
                  onChangePage={(countries, page) => onGetAllPaymentMethods(countries, page)}
                  onChangeFilter={countries => onGetAllPaymentMethods(countries)}
                />
              </>
            )}
          </>
        )}
      </div>
      {!loading && !error && !showEditCoverage && (
        <div className="payment-methods__note">
          <div className="payment-methods__note-msg">{l('paymentMethod.note')}</div>
        </div>
      )}
    </div>
  );
};

PaymentMethods.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  paymentMethodsGrouped: PropTypes.object,
  paymentMethodsSelected: PropTypes.array,
  creditCardEnabled: PropTypes.bool,
  showEditCoverage: PropTypes.bool,
  onGetSelectedPaymentMethods: PropTypes.func,
  onGetAllPaymentMethods: PropTypes.func,
  onAddPaymentMethod: PropTypes.func,
  onDeletePaymentMethod: PropTypes.func,
  onSaveCoverage: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.paymentMethods.loading,
  error: state.paymentMethods.error,
  errorInfo: state.paymentMethods.errorInfo,
  paymentMethodsGrouped: state.paymentMethods.paymentMethodsGrouped,
  paymentMethodsSelected: state.paymentMethods.paymentMethodsSelected,
  allPaymentMethods: state.paymentMethods.allPaymentMethods,
  statusAdd: state.paymentMethods.statusAdd,
  statusDelete: state.paymentMethods.statusDelete,
  showEditCoverage: state.paymentMethods.showEditCoverage,
  creditCardEnabled: state.dashboard.merchantMe?.kycLevel.creditCardEnabled,
});

const mapDispatchToProps = dispatch => ({
  onGetSelectedPaymentMethods: () => dispatch(getSelectedPaymentMethods()),
  onGetAllPaymentMethods: (countryFilter, page) => dispatch(getAllPaymentMethods(countryFilter, page)),
  onAddPaymentMethod: ids => dispatch(postAddPaymentMethod(ids)),
  onDeletePaymentMethod: ids => dispatch(deletePaymentMethod(ids)),
  onEditCoverage: show => dispatch(editCoverage(show)),
  onSaveCoverage: (currentSelection, newSelection) => dispatch(saveCoverage(currentSelection, newSelection)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
