import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getTask, postTask } from '../../services';
import { Urls } from '../../config/urls';
import {
  VirtualPosActionType,
  getCurrenciesSuccess,
  getCurrenciesError,
  createPaymentSuccess,
  createPaymentError,
  getDocumentTypesSuccess,
  getDocumentTypesError,
  confirmPaymentSuccess,
  confirmPaymentError,
} from './actions';
import { checkToken } from '../auth/sagas';
import { CheckoutTypes } from '../../constants';
import { PanelStore } from '../interfaces';
import { PaymentMethod } from '../payment-methods/interfaces';
import { segmentVirtualPOSCreatePayment } from '../../utils/analytics';

function* getCurrencies(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.currencies}?country=${action.country}`,
    token: state.auth.token,
  });

  if (response) {
    yield put(getCurrenciesSuccess(response));
  } else {
    yield put(getCurrenciesError(error));
  }
}

export function* getCurrenciesVirtualPosSaga() {
  yield takeLatest(VirtualPosActionType.GET_CURRENCIES, getCurrencies);
}

function* getDocumentTypes(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.documentTypes}?country=${action.country}`,
    token: state.auth.token,
  });

  if (response) {
    yield put(getDocumentTypesSuccess(response));
  } else {
    yield put(getDocumentTypesError(error));
  }
}

export function* getDocumentTypesVirtualPosSaga() {
  yield takeLatest(VirtualPosActionType.GET_DOCUMENT_TYPES, getDocumentTypes);
}

function* getPaymentInfo(token: string) {
  const { response } = yield call(getTask, {
    url: Urls.checkoutApi.paymentInfo + token,
  });
  if (response) {
    const { paymentMethods } = yield response;
    return paymentMethods;
  }
  return undefined;
}

function* selectPaymentMethod(action: any): any {
  yield* checkToken();

  const { response } = yield call(getTask, {
    url: Urls.checkoutApi.dataRequired.replace(':id', action.paymentMethodId),
  });
  if (response) {
    return yield response;
  }
  return null;
}

function* createPayment(action: any) {
  yield* checkToken();

  const state: PanelStore.RootState = yield select();
  const { country, currency, amount } = action.values;
  const body = { subType: CheckoutTypes.VIRTUAL_POS, country, currency, amount };
  const { response, error } = yield call(postTask, {
    url: Urls.api.createOneTimePaymentLink,
    body,
    token: state.auth.token,
  });

  if (response) {
    const { url } = yield response;

    const token = url.split('/').pop();
    const paymentMethods: PaymentMethod[] = yield* getPaymentInfo(token);

    if (token && paymentMethods) {
      const clientData = yield* selectPaymentMethod({
        paymentMethodId: paymentMethods.filter(p => p.type === 'CREDIT_CARD')[0]?.id,
      });

      yield put(createPaymentSuccess({ createSuccess: { token, paymentMethods, country, currency, amount }, clientData }));
    } else {
      yield put(createPaymentError({ sentValues: body, appState: state }));
    }
  } else {
    yield put(createPaymentError({ ...error, sentValues: body, appState: state }));
  }
}

export function* createPaymentSaga() {
  yield takeLatest(VirtualPosActionType.CREATE_PAYMENT, createPayment);
}

function* confirmPayment(action: any) {
  yield* checkToken();

  const { values, paymentMethodId } = action;

  if (paymentMethodId) {
    yield* selectPaymentMethod({ paymentMethodId });
  }

  const state: PanelStore.RootState = yield select();
  const { response, error } = yield call(postTask, {
    url: Urls.checkoutApi.confirmPayment,
    body: values,
  });

  if (response) {
    segmentVirtualPOSCreatePayment({
      countryCode: values.country,
      amount: values.amount,
      currency: values.currency,
    });
    yield put(confirmPaymentSuccess(response));
  } else {
    segmentVirtualPOSCreatePayment({
      countryCode: values.country,
      amount: values.amount,
      currency: values.currency,
      error,
    });
    yield put(confirmPaymentError({ ...error, sentValues: values, appState: state }));
  }
}

export function* confirmPaymentSaga() {
  yield takeLatest(VirtualPosActionType.CONFIRM_PAYMENT, confirmPayment);
}
