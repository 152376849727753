import './styles.scss';
import Moment from 'react-moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import Parser from 'html-react-parser';
import l from '../../../../lang';
import ChipStatus from '../../../common/chip-status';
import { Urls } from '../../../../config/urls';
import { createDate } from '../../../../utils';
import { useTransactions } from '../../../../store/transactions';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../../common/loading';
import Card from '../../../common/card';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import { ReactComponent as CardIcon } from '../../../../assets/icons/card.svg';
import { ReactComponent as UserIcon } from '../../../../assets/icons/user-circle.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right-completed.svg';
import { Tooltip } from '../../../common/tooltip';
import { PaymentMethodTypes, PaymentStatus, SHOPIFY, SHOPIFY_TRANSPARENT } from '../../../../constants';
import { useDashboard } from '../../../../store/dashboard';
import { Button } from '../../../common/button';
import { Transaction } from '../../../../store/transactions/interfaces';
import Dialog from '../../../common/dialog';
import AddRefund from '../../refunds/add-refund';
import { useRefunds } from '../../../../store/refunds';
import { Routes } from '../../../../config/routes';
import TopDashboardSection from '../../../common/top-dashboard-section';
import ButtonLink from '../../../common/button-link';
import { ReactComponent as BackIcon } from '../../../../assets/icons/back.svg';
import MessageError from '../../../common/message-error';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info-unfilled.svg';
import TooltipIcon from '../../../common/tooltip-icon';
import { ReactComponent as ReturnIcon } from '../../../../assets/icons/return.svg';
import { functionalCurrenciesByCountry } from '../../../../config';
import {
  segmentTransactionsTransactionDetailCopyExternalId,
  segmentTransactionsTransactionDetailCopyInvoiceId,
} from '../../../../utils/analytics';

const TransactionDetail = () => {
  let { invoice_id: invoiceId } = useParams<{ invoice_id: string }>();
  const { merchantMe } = useDashboard();
  const { allTransactions, transactionDetail, setTransactionDetail, getTransactionDetail } = useTransactions();
  const { loading, data, error, errorInfo } = transactionDetail;
  const [wasCopied, setWasCopied] = useState(false);
  const [transactionToRefund, setTransactionToRefund] = useState<Transaction | null>();

  const history = useHistory();
  const {
    requestRefund: { success: requestRefundSucess },
  } = useRefunds();

  console.log({ data });
  const isCrossBorder = merchantMe?.country !== transactionDetail.data?.country;

  useEffect(() => {
    const trans = allTransactions?.data.find(t => t.invoiceId === invoiceId);
    if (trans?.invoiceId === invoiceId) {
      setTransactionDetail(trans);
    } else {
      getTransactionDetail(invoiceId);
    }
  }, []);

  const isShopify = data && [SHOPIFY, SHOPIFY_TRANSPARENT].includes(data.paymentSolutionType);
  return (
    <>
      <TopDashboardSection
        title={l('transactions.details')}
        componentBottom={
          <div className="transaction_detail__back_btn">
            <ButtonLink
              type="secondary"
              text={
                <>
                  <BackIcon /> {l('transactions.details.backBtn')}
                </>
              }
              onClick={() => history.push(Routes.TRANSACTIONS)}
            />
          </div>
        }
      />
      <div className="transaction_detail">
        <Card>
          {loading && <Loading />}
          {!loading && data && error && <MessageError errorInfo={errorInfo} />}
          {!loading && data && !error && (
            <>
              <div className="transaction_detail__card_section">
                <div className="transaction_detail__amount_paid">
                  <div className="transaction_detail__amount_paid__title">{l('transactions.amountPaid')}</div>
                  <div className="transaction_detail__amount_paid__value">
                    {data.currency} {data.localAmount}
                  </div>
                </div>
                <div className="transaction_detail__status">
                  <div className="transaction_detail__status__title">{l('transactions.status')}</div>
                  <div className="transaction_detail__status__value">
                    <ChipStatus status={data.status} text={l(`transactions.status.${data.status}`)} />
                  </div>
                </div>
                <div className="transaction_detail__divider" />
                <div className="transaction_detail__box_data transaction_detail__box_data__date">
                  <div className="transaction_detail__box_data__title">{l('transactions.date')}</div>
                  <div className="transaction_detail__box_data__value">
                    <Moment format="DD/MM/YYYY">{createDate(data.creationDate)}</Moment>
                    <span>, </span>
                    <Moment format="hh:mm:ss a">{createDate(data.creationDate)}</Moment>
                  </div>
                </div>
                <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
                  <div className="transaction_detail__box_data__title">{l('transactions.paymentSolutionType')}</div>
                  <div className="transaction_detail__box_data__value__one_line">
                    {l(`transactions.paymentSolutionType.${data.paymentSolutionType}`)}
                  </div>
                </div>
                <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
                  <div className="transaction_detail__box_data__title">{l('transactions.description')}</div>
                  <div className="transaction_detail__box_data__value__one_line">{data.description || '-'}</div>
                </div>
                <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
                  <div className="transaction_detail__box_data__title">{l('transactions.invoiceId')}</div>
                  <div className="transaction_detail__box_data__value">
                    <div className="transaction_detail__box_data__value__one_line">{data.invoiceId}</div>
                    <CopyToClipboard
                      text={data.invoiceId}
                      onCopy={() => {
                        segmentTransactionsTransactionDetailCopyInvoiceId({
                          link: data.invoiceId,
                        });
                        setWasCopied(true);
                      }}
                    >
                      <Tooltip
                        onMouseEnter={() => setWasCopied(false)}
                        title={l(wasCopied ? 'common.copied' : 'common.copy')}
                      >
                        <CopyIcon className="transaction_detail__box_data__value__icon" />
                      </Tooltip>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
                  <div className="transaction_detail__box_data__title">{l('transactions.externalId')}</div>
                  <div className="transaction_detail__box_data__value">
                    {data.externalId ? (
                      <>
                        <div className="transaction_detail__box_data__value__one_line">{data.externalId}</div>
                        <CopyToClipboard
                          text={data.externalId}
                          onCopy={() => {
                            segmentTransactionsTransactionDetailCopyExternalId({
                              link: data.externalId,
                            });
                            setWasCopied(true);
                          }}
                        >
                          <Tooltip
                            onMouseEnter={() => setWasCopied(false)}
                            title={l(wasCopied ? 'common.copied' : 'common.copy')}
                          >
                            <CopyIcon className="transaction_detail__box_data__value__icon" />
                          </Tooltip>
                        </CopyToClipboard>
                      </>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="transaction_detail__card_section">
                <div>
                  <div className="transaction_detail__title_section">
                    <div className="transaction_detail__title_section__text">
                      <CardIcon className="transaction_detail__title_section__icon" />
                      {l('transactions.paymentDetails')}
                    </div>
                    <div className="transaction_detail__title_section__btn">
                      {data.status === PaymentStatus.COMPLETED && (
                        <Tooltip
                          title={Parser(l(isShopify ? 'transactions.details.shopifyRefund.title' : ''))}
                          classes={{
                            tooltip: 'bank_accounts__tooltip',
                            arrow: 'bank_accounts__tooltip__arrow',
                          }}
                          placement="left-start"
                          enterTouchDelay={0}
                        >
                          <span>
                            <Button
                              textTransformNone
                              startIcon={<ReturnIcon />}
                              size="small"
                              variant="outlined"
                              disabled={isShopify}
                              onClick={() => {
                                const transactionToRefund = data;
                                setTransactionToRefund(transactionToRefund);
                              }}
                            >
                              {l('transactions.addRefund')}
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="transaction_detail__data_section">
                    <div className="transaction_detail__box_detail">
                      <div className="transaction_detail__box_detail__row">
                        <div className="transaction_detail__box_detail__row__label">
                          <div className="transaction_detail__box_detail__row__label__text">
                            {l('transactions.amountPaid')}
                          </div>
                          <TooltipIcon
                            placement="bottom"
                            text={l(
                              'transactions.details.amountPaid.tooltip',
                              data.currency,
                              (functionalCurrenciesByCountry as { [key: string]: string })[merchantMe?.country || ''] ||
                                '***'
                            )}
                            icon={<InfoIcon />}
                          />
                        </div>
                        <div className="transaction_detail__box_detail__row__value">
                          {data.currency} {data.localAmount}
                        </div>
                      </div>
                      <div className="transaction_detail__box_detail__row">
                        <div className="transaction_detail__box_detail__row__label">
                          <div className="transaction_detail__box_detail__row__label__text">
                            {l('transactions.processingFee')}
                          </div>
                          <TooltipIcon
                            placement="bottom"
                            text={l('transactions.details.processingFee.tooltip')}
                            icon={<InfoIcon />}
                          />
                        </div>
                        <div className="transaction_detail__box_detail__row__value">
                          {data.balanceCurrency} {data.balanceFee}
                        </div>
                      </div>
                      {isCrossBorder && (
                        <div className="transaction_detail__box_detail__row">
                          <div className="transaction_detail__box_detail__row__label">{l('transactions.exchangeRate')}</div>
                          <div className="transaction_detail__box_detail__row__value">
                            USD 1 <ArrowRightIcon /> {data.currency} {data.usdFxRate.toFixed(2)}
                          </div>
                        </div>
                      )}
                      <div className="transaction_detail__box_detail__divider" />
                      {isCrossBorder && data.balanceCurrency !== 'USD' && (
                        <div className="transaction_detail__box_detail__row">
                          <div className="transaction_detail__box_detail__row__label_bottom">
                            {l('transactions.amountInUSD')}
                          </div>
                          <div className="transaction_detail__box_detail__row__value_bottom">USD {data.usdAmount}</div>
                        </div>
                      )}
                      <div className="transaction_detail__box_detail__row">
                        <div className="transaction_detail__box_detail__row__label_bottom">
                          {l('transactions.amountReceived')}
                        </div>
                        <div className="transaction_detail__box_detail__row__value_bottom">
                          {data.balanceCurrency} {data.balanceAmount}
                        </div>
                      </div>
                    </div>

                    <div className="transaction_detail__table_detail transaction_detail__column_right">
                      <div className="transaction_detail__table_detail__row">
                        <div className="transaction_detail__table_detail__row__label">{l('transactions.country')}</div>
                        <div className="transaction_detail__table_detail__row__value">
                          <img
                            alt=""
                            className="transaction_detail__country__flag"
                            src={`${Urls.flags.replace(':code', data.country)}`}
                          />
                          <span>&nbsp;{l(`countryCode.${data.country}`)}</span>
                        </div>
                      </div>
                      <div className="transaction_detail__table_detail__row">
                        <div className="transaction_detail__table_detail__row__label">{l('transactions.paymentMethod')}</div>
                        <div className="transaction_detail__table_detail__row__value">
                          {data.paymentMethod}
                          {PaymentMethodTypes.CREDIT_CARD === data.paymentMethodType && data.ccLastDigits && (
                            <span>&nbsp;({data.ccLastDigits}) </span>
                          )}
                        </div>
                      </div>
                      {PaymentMethodTypes.CREDIT_CARD === data.paymentMethodType && (
                        <div className="transaction_detail__table_detail__row">
                          <div className="transaction_detail__table_detail__row__label">
                            {l(`transactions.installments${merchantMe?.country === 'MX' ? '.MX' : ''}`)}
                          </div>
                          <div className="transaction_detail__table_detail__row__value">{data.installments || '-'}</div>
                        </div>
                      )}
                      {data.txEmail && (
                        <div className="transaction_detail__table_detail__row">
                          <div className="transaction_detail__table_detail__row__label">{l('transactions.txEmail')}</div>
                          <div className="transaction_detail__table_detail__row__value">{data.txEmail}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="transaction_detail__card_section">
                <div>
                  <div className="transaction_detail__title_section">
                    <UserIcon className="transaction_detail__title_section__icon" />
                    {l('transactions.clientInformation')}
                  </div>
                  <div>
                    <div className="transaction_detail__column_left">
                      <div className="transaction_detail__table_detail">
                        <div className="transaction_detail__table_detail__row">
                          <div className="transaction_detail__table_detail__row__label">{l('transactions.clientName')}</div>
                          <div className="transaction_detail__table_detail__row__value">
                            {data.clientFirstName} {data.clientLastName}
                          </div>
                        </div>
                        <div className="transaction_detail__table_detail__row">
                          <div className="transaction_detail__table_detail__row__label">
                            {l('transactions.clientDocument')}
                          </div>
                          <div className="transaction_detail__table_detail__row__value">
                            {data.clientDocumentType} {data.clientDocument}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="transaction_detail__column_right">
                      <div className="transaction_detail__table_detail">
                        <div className="transaction_detail__table_detail__row">
                          <div className="transaction_detail__table_detail__row__label">{l('transactions.clientEmail')}</div>
                          <div className="transaction_detail__table_detail__row__value">{data.clientEmail}</div>
                        </div>
                        <div className="transaction_detail__table_detail__row">
                          <div className="transaction_detail__table_detail__row__label">{l('transactions.clientId')}</div>
                          <div className="transaction_detail__table_detail__row__value">{data.clientId}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {data.preChargebackForm?.comments && (
                <div className="transaction_detail__card_section">
                  <div>
                    <div className="transaction_detail__title_section">
                      <UserIcon className="transaction_detail__title_section__icon" />
                      {l('transactions.chargebackform')}
                    </div>
                    <div>
                      <div className="transaction_detail__column_left">
                        <div className="transaction_detail__table_detail">
                          <div className="transaction_detail__table_detail__row">
                            <div className="transaction_detail__table_detail__row__label">
                              {l('transactions.chargebackform.product')}
                            </div>
                            <div className="transaction_detail__table_detail__row__value">
                              {data.preChargebackForm.product}
                            </div>
                          </div>
                          <div className="transaction_detail__table_detail__row">
                            <div className="transaction_detail__table_detail__row__label">
                              {l('transactions.chargebackform.problem')}
                            </div>
                            <div className="transaction_detail__table_detail__row__value">
                              {data.preChargebackForm.problem}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="transaction_detail__column_right">
                        <div className="transaction_detail__table_detail">
                          <div className="transaction_detail__table_detail__row">
                            <div className="transaction_detail__table_detail__row__label">
                              {l('transactions.chargebackform.comments')}
                            </div>
                            <div className="transaction_detail__table_detail__row__value">
                              {data.preChargebackForm.comments}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </Card>
        {transactionToRefund && (
          <Dialog
            dividers={false}
            unpaddingContent
            fullScreenMobile
            type={transactionToRefund.paymentMethodType === PaymentMethodTypes.CREDIT_CARD ? 'sm' : 'md'}
            title={l('addRefund.title')}
            body={
              <AddRefund
                beneficiary={`${transactionToRefund.clientFirstName} ${transactionToRefund.clientLastName}`.trim()}
                country={transactionToRefund.country}
                transactionId={transactionToRefund.id}
                invoiceId={transactionToRefund.invoiceId}
                currency={transactionToRefund.currency}
                amount={transactionToRefund.localAmount}
                paymentMethodType={transactionToRefund.paymentMethodType}
              />
            }
            handleClose={() => {
              setTransactionToRefund(null);
              if (requestRefundSucess) {
                history.push(Routes.REFUNDS);
              }
            }}
          />
        )}
      </div>
    </>
  );
};

export default TransactionDetail;
