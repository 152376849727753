import { Values } from '../interfaces';

export enum RefundsActionType {
  GET_REFUNDS = 'REFUNDS/GET_REFUNDS',
  GET_REFUNDS_SUCCESS = 'REFUNDS/GET_REFUNDS_SUCCESS',
  GET_REFUNDS_ERROR = 'REFUNDS/GET_REFUNDS_ERROR',
  GET_BANKS = 'REFUNDS/GET_BANKS',
  GET_BANKS_SUCCESS = 'REFUNDS/GET_BANKS_SUCCESS',
  GET_BANKS_ERROR = 'REFUNDS/GET_BANKS_ERROR',
  REQUEST_REFUND = 'REFUNDS/REQUEST_REFUND',
  REQUEST_REFUND_SUCCESS = 'REFUNDS/REQUEST_REFUND_SUCCESS',
  REQUEST_REFUND_ERROR = 'REFUNDS/REQUEST_REFUND_ERROR',
  GET_PAYMENT_METHODS_FOR_REFUNDS = 'REFUNDS/GET_PAYMENT_METHODS_FOR_REFUNDS',
  GET_PAYMENT_METHODS_FOR_REFUNDS_SUCCESS = 'REFUNDS/GET_PAYMENT_METHODS_FOR_REFUNDS_SUCCESS',
  GET_PAYMENT_METHODS_FOR_REFUNDS_ERROR = 'REFUNDS/GET_PAYMENT_METHODS_FOR_REFUNDS_ERROR',
}

export const getRefunds = (page?: number, filter?: string) => ({
  type: RefundsActionType.GET_REFUNDS,
  page,
  filter,
});

export const getRefundsSuccess = (response: any) => ({
  type: RefundsActionType.GET_REFUNDS_SUCCESS,
  response,
});

export const getRefundsError = (error: any) => ({
  type: RefundsActionType.GET_REFUNDS_ERROR,
  error,
});

export const getBanks = (country: string) => ({
  type: RefundsActionType.GET_BANKS,
  country,
});

export const getBanksSuccess = (response: any) => ({
  type: RefundsActionType.GET_BANKS_SUCCESS,
  response,
});

export const getBanksError = (error: any) => ({
  type: RefundsActionType.GET_BANKS_ERROR,
  error,
});

export const sendRequestRefund = (values: Values) => ({
  type: RefundsActionType.REQUEST_REFUND,
  values,
});

export const sendRequestRefundSuccess = (response: any) => ({
  type: RefundsActionType.REQUEST_REFUND_SUCCESS,
  response,
});

export const sendRequestRefundError = (error: any) => ({
  type: RefundsActionType.REQUEST_REFUND_ERROR,
  error,
});

export const getPaymentMethodsForRefunds = (country: string) => ({
  type: RefundsActionType.GET_PAYMENT_METHODS_FOR_REFUNDS,
  country,
});

export const getPaymentMethodsForRefundsSuccess = (response: any) => ({
  type: RefundsActionType.GET_PAYMENT_METHODS_FOR_REFUNDS_SUCCESS,
  response,
});

export const getPaymentMethodsForRefundsError = (error: any) => ({
  type: RefundsActionType.GET_PAYMENT_METHODS_FOR_REFUNDS_ERROR,
  error,
});
