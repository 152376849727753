import { PanelStore } from '../interfaces';

export namespace AuthSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.auth.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.auth.error;
  };
  export const getErrorInfo = (state: PanelStore.RootState) => {
    return state.auth.errorInfo;
  };
  export const getToken = (state: PanelStore.RootState) => {
    return state.auth.token;
  };
  export const getTokenExpiration = (state: PanelStore.RootState) => {
    return state.auth.tokenExpiration;
  };
  export const getIsAuthenticated = (state: PanelStore.RootState) => {
    return state.auth.isAuthenticated;
  };
  export const getSessionStatus = (state: PanelStore.RootState) => {
    return state.auth.sessionStatus;
  };
  export const getLogout = (state: PanelStore.RootState) => {
    return state.auth.logout;
  };
  export const getCheckSessionCompleted = (state: PanelStore.RootState) => {
    return state.auth.checkSessionCompleted;
  };
  export const getAfterLoginSuccess = (state: PanelStore.RootState) => {
    return state.auth.afterLoginSuccess;
  };
}
