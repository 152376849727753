export enum PasswordResetActionType {
  INIT_PASSWORD_RESET = 'PASSWORD_RESET/INIT_PASSWORD_RESET',
  SEND_PASSWORD_RESET = 'PASSWORD_RESET/SEND_PASSWORD_RESET',
  SEND_PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET/SEND_PASSWORD_RESET_SUCCESS',
  SEND_PASSWORD_RESET_ERROR = 'PASSWORD_RESET/SEND_PASSWORD_RESET_ERROR',
  CONFIRM_PASSWORD_RESET = 'PASSWORD_RESET/CONFIRM_PASSWORD_RESET',
  CONFIRM_PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET/CONFIRM_PASSWORD_RESET_SUCCESS',
  CONFIRM_PASSWORD_RESET_ERROR = 'PASSWORD_RESET/CONFIRM_PASSWORD_RESET_ERROR',
}

export const initPasswordReset = () => ({
  type: PasswordResetActionType.INIT_PASSWORD_RESET,
});

export const sendPasswordReset = (email: string) => ({
  type: PasswordResetActionType.SEND_PASSWORD_RESET,
  email,
});

export const sendPasswordResetSuccess = (response: any) => ({
  type: PasswordResetActionType.SEND_PASSWORD_RESET_SUCCESS,
  response,
});

export const sendPasswordResetError = (error: any) => ({
  type: PasswordResetActionType.SEND_PASSWORD_RESET_ERROR,
  error,
});

export const confirmPaswordReset = (password: string, token: string) => ({
  type: PasswordResetActionType.CONFIRM_PASSWORD_RESET,
  password,
  token,
});

export const confirmPaswordResetSuccess = (response: any) => ({
  type: PasswordResetActionType.CONFIRM_PASSWORD_RESET_SUCCESS,
  response,
});

export const confirmPaswordResetError = (error: any) => ({
  type: PasswordResetActionType.CONFIRM_PASSWORD_RESET_ERROR,
  error,
});
