import { FunctionComponent, ReactNode } from 'react';
import { Card } from '@mui/material';
import { Button } from '../button';
import { ReactComponent as DocumentWarningImage } from '../../../assets/images/document-warning.svg';
import { ReactComponent as DocumentCheckedImage } from '../../../assets/images/document-checked.svg';

import './styles.scss';

export type CardConfirmLevel = 'warning' | 'error' | 'success';

interface CardConfirmProps {
  onClose: (confirm: boolean) => void;
  level: CardConfirmLevel;
  headline: ReactNode;
  description: ReactNode;
  cancelChildren?: ReactNode;
  confirmChildren: ReactNode;
}

const CardConfirm: FunctionComponent<CardConfirmProps> = ({
  onClose,
  level,
  headline,
  description,
  cancelChildren,
  confirmChildren,
}) => (
  <Card classes={{ root: `card_confirm__card ${level}` }} elevation={0}>
    {level === 'success' ? <DocumentCheckedImage /> : <DocumentWarningImage />}
    <div>{headline}</div>
    <div>{description}</div>
    <div className="card_confirm__card__button">
      {cancelChildren && (
        <Button variant="outlined" onClick={() => onClose(false)}>
          {cancelChildren}
        </Button>
      )}
      <Button onClick={() => onClose(true)}>{confirmChildren}</Button>
    </div>
  </Card>
);

export default CardConfirm;
