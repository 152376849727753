export enum ClientsActionType {
  GET_CLIENTS = 'CLIENTS/GET_CLIENTS',
  GET_CLIENTS_SUCCESS = 'CLIENTS/GET_CLIENTS_SUCCESS',
  GET_CLIENTS_ERROR = 'CLIENTS/GET_CLIENTS_ERROR',
}

export const getClients = (page?: number) => ({
  type: ClientsActionType.GET_CLIENTS,
  page,
});

export const getClientsSuccess = (response: any) => ({
  type: ClientsActionType.GET_CLIENTS_SUCCESS,
  response,
});

export const getClientsError = (error: any) => ({
  type: ClientsActionType.GET_CLIENTS_ERROR,
  error,
});
