import { takeLatest, put, call, select } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { postTask } from '../../services';
import {
  EmailValidationActionType,
  sendEmailValidationSuccess,
  sendEmailValidationError,
  sendConfirmEmailSuccess,
  sendConfirmEmailError,
} from './actions';
import { PanelStore } from '../interfaces';

function* sendEmailValidation() {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();
  const { response, error } = yield call(postTask, {
    url: Urls.api.emailValidation,
    body: {
      email: state.dashboard.userMe?.email,
    },
    token: state.auth.token,
  });

  if (response) {
    yield put(sendEmailValidationSuccess(response));
  } else {
    yield put(sendEmailValidationError(error));
  }
}

export function* sendEmailValidationSaga() {
  yield takeLatest(EmailValidationActionType.SEND_EMAIL_VALIDATION, sendEmailValidation);
}

function* sendConfirmEmail(action: any) {
  const { response, error } = yield call(postTask, {
    url: Urls.api.confirmEmail,
    body: { token: action.token },
  });

  if (response) {
    yield put(sendConfirmEmailSuccess(response));
  } else {
    yield put(sendConfirmEmailError(error));
  }
}

export function* sendConfirmEmailSaga() {
  yield takeLatest(EmailValidationActionType.CONFIRM_EMAIL, sendConfirmEmail);
}
