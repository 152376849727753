import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import './styles.scss';
import Input from '../../../common/input';
import Select from '../../../common/select';
import MessageError from '../../../common/message-error';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import l from '../../../../lang';
import { getBanks, sendRequestRefund } from '../../../../store/refunds/actions';
import SuccessfulAnimation from '../../../common/successful-animation';
import Loading from '../../../common/loading';
import { accountTypes } from '../../../../utils/refunds-utils';
import { MessageTypes, PaymentMethodTypes } from '../../../../constants';
import { scrollIntoView } from '../../../../utils';
import Message from '../../../common/message';

const AddRefund = ({
  loading,
  error,
  errorInfo,
  errorFields,
  banks,
  success,
  beneficiary,
  country,
  transactionId,
  invoiceId,
  currency,
  amount,
  paymentMethodType,
  fnGetBanks,
  fnRequestRefund,
}) => {
  const errorRef = useRef(null);

  useEffect(() => {
    fnGetBanks(country);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      scrollIntoView(errorRef);
    }
  }, [error]);

  const validate = values => {
    const errors = {};
    if (values.amount <= 0) {
      errors.amount = l('form.input.required');
    } else if (values.amount > amount) {
      errors.amount = l('addRefund.error.amountExceeds');
    }
    if (paymentMethodType !== PaymentMethodTypes.CREDIT_CARD) {
      if (!values.accountType) {
        errors.accountType = l('form.input.required');
      }
      if (!values.bankCode) {
        errors.bankCode = l('form.input.required');
      }
      if (!values.bankBranch) {
        errors.bankBranch = l('form.input.required');
      }
      if (!values.bankAccount) {
        errors.bankAccount = l('form.input.required');
      } else if (errorFields?.bankAccount === values.bankAccount) {
        errors.bankAccount = l('form.input.invalid');
      }
    }
    return errors;
  };

  return (
    <div className="add-refund">
      {success && (
        <div className="add-refund__success">
          <SuccessfulAnimation text={l('addRefund.success')} />
        </div>
      )}
      {!success && (
        <Formik
          initialValues={{
            amount,
            accountType: '',
            bankCode: '',
            bankAccount: '',
            bankBranch: '',
          }}
          validate={validate}
          onSubmit={values => {
            fnRequestRefund({
              transactionId,
              invoiceId,
              ...(paymentMethodType === PaymentMethodTypes.CREDIT_CARD
                ? {
                  amount: values.amount,
                }
                : {
                  amount: values.amount,
                  bankAccount: {
                    accountType: values.accountType,
                    bankCode: values.bankCode,
                    bankAccount: values.bankAccount,
                    bankBranch: values.bankBranch.trim() || undefined,
                  },
                }),
            });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="add-refund__form">
                  {paymentMethodType !== PaymentMethodTypes.CREDIT_CARD && (
                    <div className="add-refund__message">
                      <Message body={l('addRefund.timeToProcess')} type={MessageTypes.INFO} />
                    </div>
                  )}
                  <div className="add-refund__desc">{l('addRefund.desc')}</div>
                  <div className="add-refund__row">
                    <div className="add-refund__row__field add-refund__row__field--left">
                      <Input
                        type="number"
                        name="amount"
                        label={l('addRefund.amount')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.amount}
                        prefix={currency}
                        maxLength={100}
                        disabled={loading}
                        error={(touched.amount && errors.amount) || undefined}
                      />
                    </div>
                  </div>
                  {paymentMethodType !== PaymentMethodTypes.CREDIT_CARD && (
                    <>
                      <div className="add-refund__separator" />
                      <h1 className="add-refund__title">{l('addRefund.bankAccount.title')}</h1>
                      <div className="add-refund__row">
                        <div className="add-refund__row__field add-refund__row__field--left">
                          <Input name="beneficiary" label={l('addRefund.beneficiary')} value={beneficiary} disabled />
                        </div>
                        <div className="add-refund__row__field add-refund__row__field--right">
                          <Select
                            name="accountType"
                            label={l('addRefund.accountType')}
                            value={values.accountType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={accountTypes()}
                            disabled={loading}
                            error={errors.accountType && touched.accountType && errors.accountType}
                          />
                        </div>
                      </div>
                      <div className="add-refund__row">
                        <div className="add-refund__row__field add-refund__row__field--left">
                          <Select
                            name="bankCode"
                            label={l('addRefund.bankCode')}
                            value={values.bankCode ? `BANK_CODE_${values.bankCode}` : ''}
                            onChange={e => {
                              const code = e.target.value.replace('BANK_CODE_', '');
                              setFieldValue('bankCode', code);
                            }}
                            onBlur={handleBlur}
                            options={banks}
                            disabled={loading}
                            error={errors.bankCode && touched.bankCode && errors.bankCode}
                          />
                        </div>
                        <div className="add-refund__row__field add-refund__row__field--right">
                          <Input
                            name="bankAccount"
                            label={l('addRefund.bankAccount')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bankAccount}
                            maxLength={100}
                            disabled={loading}
                            message={l('addRefund.bankAccount.message')}
                            error={
                              (errors.bankAccount && touched.bankAccount && errors.bankAccount) ||
                              (errorFields?.bankAccount === values.bankAccount && l('form.input.invalid')) ||
                              ''
                            }
                          />
                        </div>
                      </div>
                      <div className="add-refund__row">
                        <div className="add-refund__row__field add-refund__row__field--left">
                          <Input
                            name="bankBranch"
                            label={l('addRefund.bankBranch')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bankBranch}
                            maxLength={100}
                            disabled={loading}
                            error={errors.bankBranch && touched.bankBranch && errors.bankBranch}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="add-refund__note-desc">
                    <div>{l('addRefund.noteRequiredfields')}</div>
                    <div>{l('addRefund.note')}</div>
                  </div>
                  {loading && <Loading fullScreen />}
                  <div ref={errorRef}>
                    {error && (
                      <div className="add-refund__error">
                        <MessageError errorInfo={errorInfo} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="add-refund__btn">
                  <ButtonCustom
                    size="full-bottom"
                    variant="contained"
                    type="submit"
                    text={l('addRefund.btn')}
                    textLoading={l('form.sending')}
                    loading={loading}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

AddRefund.propTypes = {
  beneficiary: PropTypes.string,
  country: PropTypes.string,
  transactionId: PropTypes.number,
  invoiceId: PropTypes.string,
  currency: PropTypes.string,
  amount: PropTypes.number,
  paymentMethodType: PropTypes.string,
  banks: PropTypes.object,
  success: PropTypes.bool,
  fnGetBanks: PropTypes.func,
  fnRequestRefund: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.refunds.requestRefund.loading,
  error: state.refunds.requestRefund.error,
  errorInfo: state.refunds.requestRefund.errorInfo,
  errorFields: state.refunds.requestRefund.errorFields,
  banks: state.refunds.requestRefund.banks,
  success: state.refunds.requestRefund.success,
});

const mapDispatchToProps = dispatch => ({
  fnGetBanks: country => dispatch(getBanks(country)),
  fnRequestRefund: values => dispatch(sendRequestRefund(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRefund);
