import Parser from 'html-react-parser';
import l from '../../../../lang';
import { ReactComponent as FolderExclamationIcon } from '../../../../assets/icons/folder-exclamation.svg';
import { ReactComponent as Redo } from '../../../../assets/icons/redo.svg';
import { Button } from '../../../common/button';
import './styles.scss';

interface ErrorRetryProps {
  onRetry: () => void;
}

const ErrorRetry = ({ onRetry }: ErrorRetryProps) => {
  return (
    <div className="error_retry">
      <FolderExclamationIcon />
      <div className="error_retry__message">{Parser(l('reports.error.message'))}</div>
      <Button
        startIcon={<Redo />}
        onClick={onRetry}
      >
        {Parser(l('reports.error.button'))}
      </Button>
    </div>
  );
};

export default ErrorRetry;