import { PanelStore } from '../interfaces';

export namespace RefundsSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.refunds.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.refunds.error;
  };
  export const getAllRefunds = (state: PanelStore.RootState) => {
    return state.refunds.allRefunds;
  };
  export const getRequestRefund = (state: PanelStore.RootState) => {
    return state.refunds.requestRefund;
  };
  export const getPaymentMethods = (state: PanelStore.RootState) => {
    return state.refunds.paymentMethods;
  };
}
