import './styles.scss';
import logoDlGO from '../../../assets/images/dlocalGo.svg?url';

import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <p className="c-legals__header">
        <img className="c-legals__logo" src={logoDlGO} alt="" />
        <span className="c-legals__title">Terms and Conditions for the use of Dlocal Services</span>
      </p>
      <div className="c-legals__main c-legals__content c-legals__content--terms js-legals__content o-section--b u-text">
        <h4 id="heading-1">1 Introduction</h4>
          <p>We are Dlocal LLP (<strong>“we”</strong>, <strong>“us”</strong> or <strong>“Dlocal”</strong> the <strong>“Processor”</strong>), a company registered in England and Wales and with our
          registered office at 4 King’s Bench Walk, Temple, London, EC4Y 7DL, United Kingdom.</p>
        <p>
          This Agreement and Term &amp; Conditions (together the <strong>“Agreement”</strong> or the “Terms”) is a contract
          between you (<strong>“you”</strong>, the <strong>“user”</strong> or the <strong>“Merchant”</strong>) and governs
          your use of Dlocal Direct Services.
        </p>
        <p>
          By using our services, you confirm that you accept these Agreement and that you agree to comply with it. If you do
          not agree to these Terms, you must not use Dlocal Services. Please note that you agree to be bound by these Terms
          and the other documents expressly referred to in it, from the time that you register to use the Services up until
          the time your Account is terminated.
        </p>
        <p>
          This is an important document which you must consider carefully when choosing whether to use the Dlocal Services.
          Please note the following risks of using Dlocal Services:
        </p>
        <ol>
          <li>
            Payments received in your Account may be reversed at a later time, for example, if a payment is subject to a
            Chargeback, Reversal, Claim or is otherwise invalidated. This means that a payment may be reversed from your
            Account after you have provided the sender the goods or services that were purchased.
          </li>
          <li>
            We may close, suspend, or limit your access to your Account or the Dlocal Services, and/or limit access to your
            funds if you violate this Agreement or any other agreement you enter into with Dlocal.
          </li>
          <li>
            There are other terms that may apply to you, which also apply to your use of our site such as the{' '}
            <a href="https://dlocalgo.com/en/legal/privacy-notice/" rel="noopener" target="_blank">
              Privacy Notice
            </a>{' '}
            and the{' '}
            <a href="https://dlocalgo.com/en/legal/cookies-policies/" rel="noopener" target="_blank">
              Cookie Policy
            </a>
            , which sets out information about the cookies on our site.
          </li>
          <li>
            You are solely responsible for understanding and complying with any and all laws, rules and regulations of your
            specific jurisdiction that may be applicable to you in connection with your use of the Dlocal Services, including
            but not limited to, those related to export or import activity, taxes or foreign currency transactions.
          </li>
        </ol>
        <p>
          We amend these Terms from time to time as set out in Clause c). Every time you wish to use our Services, please
          check these Terms to ensure you understand the terms which will apply at that time. These Terms were most recently
          updated in February 2024.
        </p>
        <h4 id="heading-2">2 Definitions</h4>
        <p>
          <strong>“Account”</strong> means the user’s account created after registration to use the Dlocal Services.
        </p>
        <p>
          <strong>“Available Amount”</strong> has the meaning specified in Clause 7 b (i).
        </p>
        <p>
          <strong>"Business Days"</strong> means any day other than: (i) a Saturday or Sunday; (ii) a holiday and/or; (iii) a
          day on which banking institutions are Authorized by law in the UK by a regulatory order to be closed.
        </p>
        <p>
          <strong>"Chargeback"</strong> means a circumstance whereby the Customer claims that the products and/or services
          were not received and therefore demands to be refunded the payment made for that product and/or service.
        </p>
        <p>
          <strong>“Customer”</strong> means the customers of the Merchant.
        </p>
        <p>
          <strong>“Deposit”</strong> has the meaning specified in Clause 7 a.
        </p>
        <p>
          <strong>"Dlocal Service"</strong> means the payment processing solution which enables users to pay for the purchase
          of goods or services: (i) directly from accounts held in selected Bank institutions; (ii) through cash payments;
          (iv) through credit or debit Cards; or (v) through other payment methods offered by the Processor.
        </p>
        <p>
          <span className="highlight">
            <strong>"Fees"</strong> means those amounts stated in Clause 8 of this Agreement.
          </span>
        </p>
        <p>
          <strong>"Information"</strong> means any Account information that you provide to us, including but not limited to
          personal information, financial information, or other information related to you or your business.
        </p>
        <p>
          <strong>“Intellectual Property Rights”</strong> means patents, rights to inventions, copyright and related rights,
          trademarks, trade names and domain names, rights in get-up, goodwill and the right to sue for passing off, unfair
          competition rights, rights in designs, rights in computer software, database rights, topography rights, rights to
          preserve the confidentiality of information (including know-how and trade secrets) and any other intellectual
          property rights, in each case whether registered or unregistered and including all applications (or rights to
          apply) for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all
          similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part
          of the world.
        </p>
        <p>
          <strong>"Merchant"</strong> and <strong>“Seller”</strong> are used interchangeably and mean a user who is selling
          goods and/or services and using Dlocal Services to receive payments.
        </p>
        <p>
          <strong>“Payment Processing Services”</strong> means the payment processing services provided by the Processor
          through Dlocal pursuant to these Terms &amp; Conditions.
        </p>
        <p>
          <strong>"Policy"</strong> or <strong>"Policies"</strong> means any Policy or other agreement between you and Dlocal
          that you entered in connection with your use of the Dlocal Services.
        </p>
        <p>
          <span className="highlight">
            <strong>"Restricted Activities"</strong> means those activities described in Clause 6 of this Agreement.
          </span>
        </p>
        <p>
          <strong>“Territory”</strong> means the territories that the Merchant shall use the Payment Processing Services.
        </p>
        <h4 id="heading-3">3 Payment Services and Eligibility</h4>
        <h3>
          3.1 <u>Payment Services</u>
        </h3>
        <p>
          The Merchant appoints and retains the Processor as its exclusive provider of Payment Processing Services in the
          Territory with respect to its Customers.
        </p>
        <p>The Merchant agrees to accept payment by Dlocal Services for its online sales.</p>
        <p>
          By using Dlocal Services, you will be able to accept online payments for the products and/or services offered in
          your website from end users.
        </p>
        <p>
          Dlocal Services is a payment processing solution which enables users to pay for the purchase of goods or services:
          (i) directly from accounts held in selected Bank institutions; (ii) through cash payments; (iv) through credit or
          debit Cards; (v) through other payment methods offered by the Processor;
        </p>
        <h3>
          3.2 <u>Eligibility</u>
        </h3>
        <p>
          You must be at least 18 years old to register on our website and to use our Services. We will not provide the
          Dlocal Services to you if you do not satisfy this age requirement. If you are under 18 years old, please do not
          attempt to use Dlocal Services.
        </p>
        <p>
          By using Dlocal Services, you warrant that you have the right, authority and capacity to enter into and be bound by
          these Terms.
        </p>
        <h3>
          3.3 <u>Merchant’s KYC Information</u>
        </h3>
        <ol>
          <li>
            <strong>Your contact information.</strong> It is your responsibility to keep your primary email address up to
            date so that Dlocal can communicate with you electronically. You understand and agree that if Dlocal sends you an
            electronic communication but you do not receive it because your primary email address on file is incorrect, out
            of date, blocked by your service provider, or you are otherwise unable to receive electronic communications,
            Dlocal will be deemed to have provided the communication to you effectively. Please note that if you use a spam
            filter that blocks or re-routes emails from senders not listed in your email address book, you must add Dlocal to
            your email address book so that you will be able to view the communications we send to you.
          </li>
          <li>
            <strong>Identity Verification.</strong> You authorize Dlocal, directly or through third parties, to make any
            inquiries we consider necessary to validate your identity. This may include asking you for further information or
            documentation, requiring you to provide a taxpayer or national identification number or pictures, requiring you
            to take steps to confirm ownership of your email address or financial instruments, ordering a credit report or
            verifying your Information against third party databases or through other sources.
          </li>
          <li>
            <strong>Supporting documentation.</strong> You are required to provide Dlocal with all documentation that may be
            requested to you from time to time in order to comply with international standards in terms of Anti-Money
            Laundry.
          </li>
          <li>
            User acknowledges that the{' '}
            <strong>KYC documentation requested by Dlocal is mandatory in order to use Dlocal Services.</strong> Until the
            KYC is complete, the User might have a limited capability of processing. You will be unable to receive your funds
            in your account until the KYC is completed.
          </li>
          <li>
            If the KYC is not successfully completed within 30 days from the opening of the account, the User will be unable
            to continue processing and the account shall be inactivated. Only once all the requested documentation has been
            provided, the User will be capable to request the transfer of the Funds and the close of the account or continue
            accepting payments through the account.
          </li>
        </ol>
        <h4 id="heading-4">4 Registration with Dlocal</h4>
          <p>In order to use Dlocal Services you must register and create an Account.</p>
          <p>We may request, at any time (via email, telephone or any other means), any information or documentation that can prove
          your identity, address and/or authenticity of the transactions. You agree to provide us with true, precise and complete
          information.</p>
          <p>Upon completing the registration process you will create an Account and receive a password, a username and an account
          number. You must not create more than one Account for your own use of the website or the Dlocal Services.</p>
          <p>Your Account is personal and shall not be transferred to any individual. In the event that another individual accesses
          the Account due to you having provided any of your account details, you shall be liable for this individual's access.
          You shall compensate us for all and any losses, damages, costs and expenses we may suffer as a result of any failure by
          you to keep your account details confidential.</p>
          <p>You are responsible for: (i) maintaining the confidentiality of the password and username; (ii) not permitting others to
          use your Account; (iii) not using the Accounts of other users; (iv) refraining from selling, trading, or otherwise
          transferring your Account to another party; (v) immediately notifying us via email at <a href="mailto:support@dlocalgo.com">support@dlocalgo.com</a> if you have
          any suspicion of unauthorized use of your password or Account or any other breach of security, including unauthorized
          use of your debit or credit card.</p>
          <p>You must not provide false account details, which include but are not limited to your name, email address, age or
          location. In the event that we have reasons to believe that you have provided us with false account details or with
          false documentation, we reserve the right to terminate the provision of our Services to you and restrict your access,
          suspend or cancel your Account without prior notice. You agree to hold us harmless against any falsehood or inaccuracy
          contained in the information you provide us. You warrant that the email address with which you register your Account is
          an active, valid email address controlled by you.</p>
        <h4 id="heading-5">5 Use of Dlocal Services</h4>
          <p>You are responsible for providing and paying for any means and equipment necessary to enable your access to the
          Services.</p>
          <p>You agree not to disguise, or interfere in any way with, the IP address of the device you are using to access the
          Services or otherwise take steps to prevent us from correctly identifying the actual IP Address of the device you are
          using whilst accessing the Services.</p>
          <p>You commit to comply with OFAC, United Nations, United Kingdom, European Union, and any applicable local sanctions
          regulations and acknowledges that payments related to jurisdictions or activities sanctioned by those bodies shall not
          be made in connection with these Terms.</p>
          <p>When using our Services, you warrant that you are in compliance with all relevant and applicable laws and regulations of
          whatever country you are physically located and with all Dlocal’s policies.</p>
          <p>You shall only use the Services for lawful purposes. You shall not use the Services to receive or transmit material that
          is obscene, harassing, and defamatory, in breach of confidentiality or violation of any Intellectual Property Rights.</p>
          <p>Without prejudice to the right of initiating legal proceedings or reporting the fact to the relevant authorities, in the
          event that we have reasons to believe that you are making any illegal and/or unauthorized use of the Services, we
          reserve the right to restrict your access, suspend or cancel your Account without prior notice. Illegal and/or
          unauthorized use of the Services includes but is not limited to:</p>
          <ol type="A">
          <li>using the Services without satisfying the age requirement of at least 18 years old; submitting false, inappropriate or
          inaccurate account details or personal data to us;</li>
          <li>registering and/or using more than one Account;</li>
          <li>engaging in any illegal and/or fraudulent activity;</li>
          <li>acting in a manner that is detrimental to the conduct of our business or which may result in legal liability for you, us
          or a third party;</li>
          <li>providing, creating or disseminating computer viruses; and</li>
          <li>Imposing surcharge or any other fee for accepting Dlocal Direct as a payment method without our prior written consent.
          You may charge a handling fee in connection with the sale of goods or services, as long as the handling fee is not
          higher than the handling fee you charge for non-Dlocal transactions.</li>
          </ol>
          <p>Dlocal will operate the website with the reasonable skill and care of an online service provider based on the relevant
          industry standard.</p>
          <p>Dlocal will use its reasonable endeavors to maintain the operation of the website and the Dlocal Services. Whenever it
          is necessary to temporarily suspend the Dlocal Services for operational or other reasons, Dlocal will use its reasonable
          endeavors to restore the website and re-establish the provision of the Dlocal Services.</p>
          <p>The user acknowledges and agrees that any payment processed via the Dlocal Services may be subject to limits and
          conditions imposed by the applicable legislation and any regulation authority, and that Dlocal reserves the right to
          restrict the maximum amount of any transaction processed by Dlocal accordingly.</p>
          <p>Dlocal may subcontract or delegate in any manner any or all of its obligations under this Agreement to any third party
          so as to enable or facilitate the provision of the Services to the user.</p>
          <p>The user accepts and authorizes the exchange rate used by Dlocal. Dlocal reserves the right to refuse the process
          transactions through the Dlocal Services where such transactions are not compliant with the applicable legislation, any
          regulation authority codes of practice for Dlocal's policies or operations standards. The user acknowledges and agrees
          that any transfer of funds made via the Dlocal Services have the sole purpose of allowing users to make payments in good
          faith in your websites, and that none of these payments shall be considered as a credit granted by Dlocal to the user.
          In the event that the Processor receives a complaint from a Customer, the Processor shall as soon as reasonably
          practicable refer the complaint to the Merchant where such complaint is in respect of products or services provided or
          to be provided by the Merchant, and in any event the Merchant shall reimburse the Processor for any cost it might incur
          as consequence of the Customer’s complaint including attorney’s fees. Merchant agrees that it will reimburse the
          Processor for the amount of any Refunds, Chargebacks, and that the Processor is entitled to deduct such Refunds and
          Chargebacks from the payments to be made to the Merchant. If, however, there are insufficient funds to cover any
          Refunds, Chargebacks, the Merchant shall pay such fines by wire transfer to the Processor no later than two (2) business
          days following the Transactions to which they apply.</p>
          <p>Dlocal shall be able to contact a Customer to confirm the veracity of a transaction and confirm receipt of product
          delivery.</p>
          <p>To complete biometric validations, Dlocal may request from Merchant to provide further documentation to validate
          documents and the identity of an involved person.</p>
        <h4 id="heading-6">6 Restricted Activities</h4>
          In connection with your use of Dlocal Services, you will not:
          <blockquote>Breach this Agreement, or any other Policy that you have agreed to with
          Dlocal; Violateany law, statute, ordinance, or regulation;</blockquote>
          <blockquote>Infringe Dlocal's or any third party's copyright, patent, trademark, trade secret or other intellectual property rights,
          or rights of pulicity or privacy;</blockquote>
          <blockquote>violate the list of restricted and prohibited activities attached here as Annex
          1; Sell counterfeit goods;</blockquote>
          <blockquote>Act in a manner that is defamatory, trade libelous, threatening or harassing to our employees, agents or other Users;</blockquote>
          <blockquote>Provide false, inaccurate or misleading Information;</blockquote>
          <blockquote>Engage in potentially fraudulent or suspicious activity and/or transactions; Refuse to cooperate in an investigation or
          provide confirmation of your identity or any
          Information you provide to us;</blockquote>
          <blockquote>Conduct your business or use the Dlocal Direct Services in a manner that results in or may result in complaints,
          Disputes, Claims, Reversals, Chargebacks, fees, fines, penalties and other liability to other Users, third parties or
          you;</blockquote>
          <blockquote>Use your Account or Dlocal Direct Services in a manner that Visa, MasterCard, American Express, Discover or any other
          electronic funds transfer network reasonably believes to be an abuse of the card system or a violation of card
          association or network rules; Facilitate any viruses, trojan horses, worms or other computer programming routines that
          may damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or Information;
          Use an anonymizing proxy; use any robot, spider, other automatic device, or manual process to monitor or copy our
          website without our prior written permission;</blockquote>
          <blockquote>Use any device, software or routine to bypass our robot exclusion headers, or interfere or
          attempt to interfere with our website;</blockquote>
          <blockquote>Take any action that may cause us to lose any of the services from our Internet service providers, payment processors,
          or other suppliers;</blockquote>
          <blockquote>seek to copy, data-mine, cache, reverse engineer, decompile, disassemble or otherwise extract data from Dlocal Direct
          except as otherwise agreed in this Agreement;</blockquote>
          <blockquote>obtain or claim any ownership in any software (or in any derivation or improvement)
          connected to Dlocal Direct or the Payment Processing Services;</blockquote>
          <blockquote>sub-license the use of Dlocal Direct to any person (other than its Customers); and/or create, write or develop any
          derivative software or any other software based on
          Dlocal Direct or the Payment Processing Services utilising the proprietary and Confidential Information of the Processor
          or a third-party licensor of the Processor. betting, including lottery tickets, casino gaming chips, off-track betting,
          memberships on gambling-related internet sites and wagers at races that service Territories residents or persons
          foreign exchange trading;</blockquote>
          <blockquote>bill payment services (meaning a service that allows debtors to pay their bills to
          utilities or other creditors);</blockquote>
          <blockquote>items that encourage, promote, facilitate or instruct others to engage in illegal activity to include, without
          limitation, drug trafficking, sex and human trafficking, arms trafficking, or laundering money;</blockquote>
          <blockquote>infringing or encouraging infringement of any intellectual property or any other proprietary right under the Applicable
          Legislation including, but not limited to: offering, providing, selling, furnishing making, having made any designer
          handbags, clothing and accessories, and consumer electronics;</blockquote>
          <blockquote>promoting hate, violence, racial intolerance, or the financial exploitation of a crime in any manner whether directly or
          indirectly;</blockquote>
          <blockquote>promoting, supporting or glorifying acts of violence or harm towards self or others Where payment is made by Dlocal
          Direct, the Merchant shall not permit any change in the delivery address after the Authorization Request has been sent
          to the Processor. To the maximum extent permitted by law, in no event shall the Processor be liable for any payments in
          excess of the Daily Transaction Limit.</blockquote>
        <h4 id="heading-7">7 Payments to the User</h4>
          <p>Merchant Deposits shall be withheld from the amounts processed by the User in order to cover Chargebacks and refunds
          (the <strong>“Deposit”</strong>). The Deposit will be equal to 5% of the amounts processed by Dlocal. Notwithstanding the above, the 10%
          is subject to change according to Dlocal’s assessment of the Merchant.</p>
          <p>Payments to the User shall be made in accordance with the following terms:</p>
          <blockquote>the amounts processed by the user less fees
          and any Refunds, Chargebacks and Deposits where applicable and any other amounts deductible (the <strong>“Available Amount”</strong>),
          will be available for payment to the User, within fourteen (14) days of the transaction, unless Dlocal’s assessment of
          the Merchant requires a change to shorten or expand such date, from when the transaction was made which shall be found
          in the pricing list. The Available Amount may also vary depending on the Merchant's shipping policy or its shipping times
          in which case the Payments may also vary.</blockquote>
          <p>In the event any funds due to the User are seized or made unavailable by an action of a law enforcement body, regulator
          or other third party, Dlocal shall use reasonable efforts, at User’s expense, to assist the User in securing release of
          such funds as soon as possible.</p>
          <p>The User shall as soon as practicable, verify the correctness and completeness of the payments and statements of account
          by the Processor. Complaints and objections about these payments may only be made in writing within a strict time limit
          of 8 (eight) weeks after receipt of the payment by the Merchant.</p>
        <h4 id="heading-8">8 Dlocal Services fees and taxes</h4>
          <p>The Service Fees and settlements are included in <a href="https://www.dlocalgo.com/pricinglist" target="_blank">this link</a>.</p>
          <p>The Processor may modify, with immediate effect, the Service Fees stated <a href="https://www.dlocalgo.com/pricinglist" target="_blank">in the pricing listed</a> in order to pass through
          an increase in costs due to a change in law, regulation, rule or member or association agreement or in the
          interpretation of Laws and regulations outside of the Processor’s control.</p>
          <p>Any changes to the Service Fees that are not contemplated in Clause 8 b) above, shall be applicable to the Merchant
          within 30 days of such change in the Service Fees. In the event the Merchant decides to activate a new means of payment,
          the Merchant shall accept new Terms and Conditions referred to that means of payment specifically.</p>
          <p>You acknowledge and accept that DLocal may, at its sole discretion, use third parties in order to provide any of the
          services described herein and does not handle your information and its security. For questions or claims about those
          matters, please contact ZENTAVO LLC, at support@zentavo.net.</p>
        <h4 id="heading-9">9 Intellectual property rights</h4>
          <p>The Intellectual Property Rights on Intellectual Property related to the provision of Services and the Website, its
          functionality and appearance, are either owned by or licensed to Dlocal.
          You are expressly prohibited from:</p>
          <blockquote>reproducing, copying, sharing, editing, transmitting, uploading, modifying, altering, incorporating into any other
          materials, removing, commercializing or using any Intellectual Property related to the provision of Services and the
          Website, its functionality and appearance;</blockquote>
          <blockquote>Doing anything which may be seen to take unfair advantage of the reputation and goodwill of the website the Services
          and/or Dlocal’s business in general; and Doing anything that could be considered an infringement of the Intellectual
          Property Rights owned and/or licensed to Dlocal without first obtaining the written permission of the owner of the
          Intellectual Property.</blockquote>
        <h4 id="heading-10">10 Data Protection</h4>
        <p>
          <span className="highlight">
            This Clause sets out the terms that apply when Personal Data is processed by Dlocal under these Terms and
            Conditions. The purpose of this Clause is to ensure such processing is conducted in accordance with applicable
            laws and with due respect for the rights and freedoms of individuals whose Personal Data is processed.
          </span>
        </p>
        <ol>
          <li>
            <strong>Definitions</strong>
              <blockquote>Data Controller: means the natural or legal person, public authority, agency or b) other body which, alone or jointly
              with others, determines the purposes and means of the Processing of Personal Data, or as otherwise defined in the Data
              Protection Legislation;</blockquote>
              <blockquote>Data Processor: means the person, entity or body which processes Personal Data on behalf of the Controller, or as
              otherwise defined in the Data Protection Legislation</blockquote>
              <blockquote>Data Protection Legislation: means any data protection legislation applicable to the processing of Personal Data by
              Dlocal including but not limited to the GDPR as amended and adopted by UK law by virtue of section 3 of the European
              Union (Withdrawal) Act 2018 (the “UK GDPR”);</blockquote>

              <blockquote>End Users’ Personal Data: means Personal Data of the Merchant’s individual end users Processed by Dlocal for the
              purposes of these Terms and Conditions; Personal Data: means any information relating to an identified or identifiable
              natural person (“Data Subject”); an identifiable natural person is one who can be identified, directly or indirectly, in
              particular by reference to an identifier such as a name, an identification number, location data, an online identifier
              or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social
              identity of that natural person, or as otherwise defined in the Data Protection Legislation;</blockquote>

              <blockquote>Process or Processing: means any operation or set of operations which is performed on Personal Data or on sets of
              Personal Data, whether or not by automated means, such as collection, recording, organisation, structuring, storage,
              adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making
              available, alignment or combination, restriction, erasure or destruction, or as otherwise defined in the Data Protection
              Legislation;</blockquote>
          </li>
          <li>
            <strong>Applicability and Scope</strong>
              <blockquote>Applicability. This lause will apply only to the extent that Dlocal processes, on behalf of the Merchant, Personal Data
              to which Applicable Data Protection Legislation applies.</blockquote>
              <blockquote>Scope. The subject matter of the data processing is the provision of the Services, and the processing will be carried
              out for the duration of these Terms and Conditions.</blockquote>
          </li>
          <li>
            <strong>Roles of the parties</strong>
              <blockquote>Dlocal as a Data Processor. The parties acknowledge and agree that regarding the processing of End Users’ Personal Data,
              Merchant acts as a data controller and Dlocal is a data processor. As such, Dlocal will process End Users’
              Personal Data in accordance with the Merchant’s instructions.</blockquote>
              <blockquote>Dlocal as an Independent Data Controller. Parties may process End Users’ Personal Data as Independent Controllers when
              they independently determine the purposes and means of processing End Users’ Personal Data. Under this scenario, Dlocal
              shall process End Users' Personal Data in accordance with the Privacy Notice which can be found at
              <a href="https://dlocalgo.com/en/legal/privacy-notice/" target="_blank">https://dlocalgo.com/en/legal/privacy-notice/</a>.</blockquote>
          </li>
          <li>
            <strong>Dlocal as a Data Processor – Processing End Users’ Data</strong>
              <blockquote>Parties agree that they shall comply with any legal requirements regarding the processing of Personal Data by data
              processors in line with any requirement set out under applicable Data Protection Legislation.</blockquote>
              <blockquote>Dlocal shall process the End Users’ Personal Data only in accordance with the Merchant’s instructions from time to time,
              and such instructions shall always in line with the Merchant’s rights and obligations under these Terms and Conditions;
              Dlocal shall notify the Merchant within three (3) Business Days if it receives a Data Subject Request and provide the
              Merchant with reasonable co-operation and assistance in relation to any Data Subject Request</blockquote>
              <blockquote>Dlocal shall ensure that only persons authorised by DLocal Process End
              Users’ Personal Data and that such persons are subject to binding obligations
              to maintain the confidentiality of End Users’ Personal Data;</blockquote>
              <blockquote>Dlocal shall without undue delay after discovering a Data Breach notify the Merchant about the Data Breach and and
              describe the likely consequences of the Data Breach in accordance with the information requirements set out under under
              applicable Data Protection Legislation;</blockquote>
              <blockquote>Dlocal shall implement and maintain appropriate technical and
              organisational measures when processing End users’ Personal Data;</blockquote>
              <blockquote>The Merchant provides a general authorization for Dlocal to engage onward sub-processors that is conditioned on the
              following requirements: (i) Dlocal will restrict the onward sub-processor’s access to End Users’ Data only to what is
              strictly necessary to provide the Services, and Dlocal will prohibit the sub-processor from processing the Personal Data
              for any other purpose. (ii) Dlocal agrees to impose contractual data protection obligations, including appropriate
              technical and organizational measures to protect personal data, on any sub-processor it appoints that require such
              sub-processor to protect End Users’ Data to the standard required by Applicable Data Protection Legislation; and (iii)
              Dlocal will remain liable and accountable for any breach of this Clause that is caused by an act or omission of its
              sub-processors.</blockquote>
              <blockquote>DLocal shall notify the Merchant of the additional or replacement Sub-Processor. If and when the Merchant objects to the
              additional or replacement Sub-Processor, the parties shall discuss the objection in good faith. In the event that the
              Merchant confirms its objection to the additional or replacement Sub-Processor, DLocal shall be entitled to terminate or
              suspend the Services affected by the Merchant’s objection upon giving reasonable notice to the Merchant. DLocal shall,
              upon receiving a notice from the Merchant, delete or return all the End Users’ Personal Data to the Merchant after the
              end of the provision of the Services relating to the Processing under these Terms and Conditions. The Merchant shall be
              responsible for arranging a back-up of such End User’s Personal Data before the end of the provision of the Services.
              In the event that DLocal is required by applicable laws to retain all or part of End Users’ Personal Data (e.g. for tax,
              regulatory compliance and/or accounting purposes) DLocal shall retain such data only for as long as it is required or needed.
              The provisions of this Schedule shall continue to apply to any End Users’ Personal Data retained by DLocal pursuant to
              this paragraph.</blockquote>

              <blockquote>DLocal shall use reasonable endeavours to make available to the Merchant all information necessary to demonstrate
              compliance with its obligations as a Data Processor under Data Protection Legislation and allow the Merchant or the
              Merchant’s designated auditors to carry out data protection compliance audits under confidentiality terms agreed by the
              parties acting reasonably and in good faith.</blockquote>

              <blockquote>In the event that DLocal transfers any End Users’ Personal Data to a country outside the EEA or the UK in respect of
              which the UK Government has not made a positive finding of Adequacy, DLocal shall ensure that there are adequate
              protection and appropriate safeguards in place for such transfer of End Users’ Personal Data in accordance with
              applicable Data Protection Legislation. Such adequate protection and appropriate safeguards may include entering into
              Standard Contractual Clauses with the data importer.</blockquote>
          </li>
          <li>
            <strong>Liability</strong>
              <blockquote>The Merchant acknowledges that, when DLocal acts as a ‘Processor’, is reliant on the Merchant for direction as to the
              extent to which DLocal is entitled to use and Process the End Users’ Personal Data. Consequently, DLocal shall not be
              liable for any loss or damage or for any claim (including but not limited to claims or complaints brought by a Data
              Subject or a regulator) arising from any action or omission by DLocal, to the extent that such action or omission
              resulted from the Merchant's instructions or it is based on inaccurate, incorrect or incomplete data, or information
              provided by the Merchant.</blockquote>
              <blockquote>Subject to paragraph i. above, DLocal shall indemnify the Merchant against
              all liabilities, costs, expenses, damages and losses suffered or incurred by
              the Merchant, arising from DLocal’s breaches of Data Protection Legislation.
              The Merchant shall indemnify DLocal against all liabilities, costs, expenses, damages and losses suffered or incurred by
              DLocal arising out of or in connection with Merchant’s breaches of Data Protection Legislation.</blockquote>
          </li>
        </ol>
        <h4 id="heading-11">11 Suspension of the Dlocal Services</h4>
          <p>In addition to Dlocal’s right to suspend the Dlocal Services as aforementioned, Dlocal reserves the right to, at any
          time, suspend the Dlocal Services for repairs, maintenance or improvements. In the event that Dlocal suspends the Dlocal
          Services in accordance with this Clause, Dlocal will use its reasonable endeavors to give prior notice to the users,
          unless the Dlocal Services are suspended due to suspicion of security breach or fraud. Where the Dlocal Services
          provided to you are suspended due to suspicion of security breach caused or fraud committed by you, Dlocal reserves the
          right to:</p>

          <blockquote>request that you provide information; and</blockquote>
          <blockquote>at its sole discretion, restrict your access, suspend or terminate your Account without provision of any notice.</blockquote>

          <p>In the event Dlocal detects a fraudulent activity: (i) funds shall be blocked for 60 days in Brazil, and 90 days in
          other territories; (ii) Dlocal shall request the Merchant to provide an invoice or proof of shipping in order to
          validate if the transactions were legitimate; (iii) by the end of the period, we retain 10% of the amount as a deposit,
          and settle the rest to the Merchant; (iv) shall use the Merchant balance to refund end-users that were prejudiced and
          complaint; and (v) might proactively refund the Customers.</p>
        <h4 id="heading-12">12 Term and termination</h4>
          <p>This Agreement shall commence on the date hereof and continue for an initial period of 24 (twenty-four) months (the
          “Initial Term”) with automatic 24 (twenty-four) months periods of renewal thereafter (the “Renewal Terms”), unless
          otherwise terminated pursuant to the terms hereof.</p>

          <p>In addition to any rights of termination provided hereunder: (i) any Party may terminate this Agreement at any time
          without cause upon 60 (sixty) calendar days prior written notice; and (ii) either Party may terminate this Agreement
          immediately should the other Party remain in breach of a material provision of this Agreement for more than ten (10)
          Business Days following notice thereof unless during the notice period the breach is cured to the Parties’ mutual
          satisfaction</p>

          <p>Where the Processor believes Merchant is acting illegally or not in compliance with the requirements set-out in this
          Agreement, the Processor shall, at its sole discretion, be entitled to terminate this Agreement immediately, or suspend
          or disconnect the Payment Processing Services to the Merchant (and its Customers) until such time as the Merchant
          satisfies the Processor, that it is in compliance with its obligations.</p>
        <h4 id="heading-13">13 Our right to vary these terms</h4>
          <p>We may revise these Terms from time to time. Every time you use Dlocal Services, the Terms in force at that time will
          apply to the provision of the Dlocal Services to you. We reserve the right to make changes to the website and the Dlocal
          Services from time to time without any prior notice to you provided that such changes have no material adverse effect on
          you.</p>
        <h4 id="heading-14">14 Liability and Indemnification</h4>
          <p><strong>Limitations of Liability.</strong> In no event shall we, our parent, subsidiaries and affiliates, our officers, directors,
          agents, employees or suppliers be liable for lost profits or any special, incidental or consequential damages (including
          without limitation damages for loss of data or loss of business) arising out of or in connection with our website,
          Dlocal services, or this agreement (however arising, including negligence) unless and to the extent prohibited by law
          our liability, and the liability of our parent, subsidiaries and affiliates, our officers, directors, agents, joint
          ventures, employees and suppliers, to you or any third parties in any circumstance is limited to the actual amount of
          direct damages. We do not in any way exclude or limit our liability for:</p>

          <blockquote>death or personal injury caused by our negligence;</blockquote>
          <blockquote>fraud or fraudulent misrepresentation; and</blockquote>
          <blockquote>any other liability which cannot be excluded or limited by applicable law.</blockquote>

          <p>You agree to indemnify and hold us harmless from any liabilities, claims, losses or demands made by any third party
          arising from your misuse of Dlocal Direct Services, which includes but is not limited to your breach of these Terms or a
          breach by you of any applicable law or rights of any third party. You also agree to fully cooperate in the defense of
          any claim that we may have to respond as a result of your misuse of the Services. We will make reasonable efforts to
          ensure that all transactions are processed in a timely manner. However, a number of factors may delay some transactions,
          many of which are beyond Dlocal’s control, including without limitation, the processing time, communication failures and
          delays in the banking system. Dlocal shall not be liable for any loss or damages arising from delay in processing
          transactions.</p>

          <p>We shall have no liability for defects on any goods or services sold to End Users and we shall not be liable for any
          charges, fees or other taxes in respect of such goods or services.</p>
        <h4 id="heading-15">15 Events outside our control</h4>
          <p>We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations
          under these Terms that is caused by any act or event beyond our reasonable control, including without limitation
          strikes, lock- outs or other industrial action by third parties, civil commotion, riot, invasion, terrorist attack or
          threat of terrorist attack, war (whether declared or not) or threat or preparation for war, fire, explosion, storm,
          flood, earthquake, subsidence, epidemic or other natural disaster, or failure of public or private telecommunications
          networks.</p>

          <p>In case such event takes place that affects the performance of our obligations under these Terms:</p>

          <blockquote>we will contact you as soon as reasonably possible to inform you; and
          our obligations under these Terms will be suspended and the time for performance of our obligations will be extended for
          the duration of such event.</blockquote>
        <h4 id="heading-16">16 Communications between us</h4>
          <p>When we refer, in these Terms, to "in writing", this will include e-mail.</p>
          <p>If you wish to contact us in writing for any reason, you can send us a pre-paid post to Dlocal LLP, 4 King ́s Bench
          Walk, London EC4Y 7DL, England, UK or email us at <a href="mailto:legal@dlocal.com">legal@dlocal.com</a></p>
          <p>If we have to contact you or give you notice in writing, we will do so by e-mail, phone or by pre-paid post to the
          address, phone number or email you provide to us as in your account details.</p>
          <p>The provisions of this Clause shall not apply to the service of any proceedings or other documents in any legal action.</p>
        <h4 id="heading-17">17 Governing law and jurisdiction</h4>
          <p>These Terms are governed by and construed in accordance with the laws of the United Kingdom.</p>
          <p>You and we both agree that the courts of London have exclusive jurisdiction to settle any dispute or claim that arises
          out of or in connection with these Terms (including non-contractual disputes or claims).</p>
        <h4 id="heading-18">18 Other important Terms</h4>
          <p>These Terms together with the documents referred to in it, contains the entire agreement between us for your use of the
          Services.</p>
          <p>If any part of these Terms is held unenforceable, that part shall be enforced to the maximum extent permitted by law so
          as to give effect to the intent of the parties, and the remaining part of these Terms shall continue in full force and
          effect.</p>

          <p>These terms are between you and us. No other person shall have any rights to enforce any of its terms, whether under the
          Contracts (Rights of Third Parties Act) 1999 or otherwise.</p>
          <p>We may transfer our rights and obligations under these Terms to another organization without notice to you, but this
          will not affect your rights or our obligations under these Terms.</p>

          <p>If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce our rights
          against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean
          that you do not have to comply with those obligations. If we waive a default by you, we will only do so in writing, and
          that will not mean that we will automatically waive any later default by you.</p>

          <p>In the event the Merchant has signed a Payment Processing Services Agreement and these Terms and Conditions, the Payment
          Processing Services Agreement shall prevail.</p>
        <h4 id="heading-19">Annex 1</h4>
        <h4 id="heading-19">Restricted and Prohibited Activities</h4>
        <figure className="table">
          <table>
            <thead>
              <tr>
                <th>Segment</th>
                <th>Service or Product</th>
                <th>Restricted/Prohibited</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={6}>Adult</td>
                <td>Adults’ goods that are not fetish products</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Adults’ goods that are fetish products</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Content: Books, magazines, audio, videos (online or DVD), websites, streaming services, and other content
                  formats, including of legal sexual nature (over legal age, etc.). Includes pornography and other obscene
                  materials; pay-per view, adult live chat features
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Dating Apps that promotes, directly or indirectly, sexual interaction between its users (sugar daddy, sugar
                  baby)
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Entertainment: Gentleman's clubs, topless bars, strip clubs, sexually oriented massage parlors, saunas,
                  escort agencies, or any sexually related services
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Sites offering any sexually related services such as prostitution, escorts, etc.</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td rowSpan={10}>Financial Businesses, Securities &amp; Trading</td>
                <td>
                  Unregulated and/or unlicensed: -Financial advisors -Insurance sales and services (life and non-life) -Money
                  transfer &amp; remittance businesses -Investment firms, or brokerage firms, banking, or insurance
                  institutions -E-wallet or e-money that can be monetized, re-sold or converted to physical and digital goods
                  and services or otherwise exit the virtual world -Provider or seller prepaid access/stored value -Other
                  Financial Services that require a license in the US and/or in the EEA or equivalent jurisdiction
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  • Virtual currencies • Cryptocurrency Exchange/Trader, as well crypto related services connected to
                  companies that facilitate the creation, sale, distribution, custody or exchange of crypto-currencies or
                  digital assets
                </td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>
                  Payment Service Providers that have as customers other Payment Service Providers (i.e., MSB that has as
                  customer other MSBs)
                </td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Digital assets, such as Non-Fungible Tokens, carbon credits</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Micro-lending, including, buy-now-pay-later/instalment payment methods</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Credit repair and credit protection business</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Debt repayment and collection agency, including fines or penalty collections of any kind</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Shell banks</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  High-risk securities such as: • contract for difference (CFD) • financial spread betting • initial coin
                  offering (ICO) • cryptocurrency options • trading and purchases • Forex currency options or forex currency
                  options • Binary options
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Security brokers /investments of any kind including the purchase of: • securities • currencies •
                  derivatives • commodities • shares • options • precious metals • other financial instruments
                </td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td rowSpan={4}>Intellectual Property (IP)</td>
                <td>
                  Counterfeit products, replicas or designed infringements of them including products designed to circumvent
                  IP
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Essay mills, paper mills where the intent is to falsely submit documents as their own work</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Products designed to circumvent copyright protection techniques or to otherwise facilitate the unlicensed
                  use of copyrighted material
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Unlicensed video and music streaming platforms (e.g., that does not hold rights of use and distribution as
                  required)
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td rowSpan={9}>Marketing practices</td>
                <td>Business investment opportunities operating as ‘get rich quick schemes’</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Business models that use ransom- or extortion like practices</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Business practices that are designed to evade excessive chargeback monitoring programs</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Deceptive marketing tactics related to disclosure, false or inaccurate claims, before/after claims,
                  endorsements, cancellation or refunds avoidance, pre-checked opt-ins, poorly disclosed negative options,
                  misappropriation of incentives, disproportionate rebates, sweepstakes
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Fake references and other services or products that foster deception</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Products or services that promote hate, violence, discrimination, terrorism, harassment or abuse in any
                  shape or form
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Pyramid selling or Ponzi schemes, chain letters or other financial scams</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Legal Multi-level marketing</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Inbound telemarketing for postcard or mailings</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td rowSpan={2}>Not-for-Profit</td>
                <td>Charities</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Crowdfunding</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td rowSpan={11}>Regulated (non- financial) products &amp; services</td>
                <td>Alcohol sales</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>
                  Drugs, and tools specifically intended for the production of drugs, drug paraphernalia, illegal drugs,
                  substances designed to mimic illegal drugs and / or other psychoactive products
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Illegal products or services or any service providing peripheral support of illegal activities, including
                  illegal gambling services
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Legal gambling or Game of Skill where participants receive cash or cash equivalents or prizes with/of
                  material value, including but not limited to: Casinos, poker, bingo, slot machines, betting, lotteries,
                  racing, fantasy sports, sportsbook, CFDs (e.g., non-ESMA compliant)
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Legally authorized Marijuana (manufacture and/or distribution) and related paraphernalia</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Products or services specifically offered or intended to be used to create, produce or grow drugs or drug
                  ingredients
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Pharmaceuticals, prescription medicine and medical devices (including animal pharmaceuticals) (excluding
                  domestic medical devices and objects, such as thermometer, blood pressure monitors, gloves, masks, etc.)
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Tobacco, smoking supplies, and general tobacco goods (including the facilitation, sale, or distribution of
                  tobacco goods)
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Trade of fireworks, flammable, or radioactive materials</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Extractive Industry (such as extraction or drilling of precious metals, oil, gas, geo-elements, etc.)
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Trade of weapons, ammunitions, military arms, explosive devices and firearm parts</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td rowSpan={1}>Technology</td>
                <td>
                  Decryption and descrambler products and services, devices designed to block, jam or interfere with cellular
                  and personal communications
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td rowSpan={27}>Other products &amp; services</td>
                <td>
                  Animals and wildlife products classified as endangered or protected (excluding pets and house animals)
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Art dealers and galleries</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Auction houses</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Bail bonds and bankruptcy lawyers</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Bidding fee and penny auctions</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Casual dating services</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Car and truck rental / sales / importers / car parts</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Certain social media business (e.g., click farms, sales of followers and likes)</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Cloud storage, VPN, file sharing, (high-risk) cyber lockers</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Governmental services, such as embassies or consulates</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>
                  Hazardous materials, including but not limited to: - Hydrofluoric acid - Products containing cyanide -
                  Prohibited ozone-depleting substances (ODS) - Nitric acid - Peptides, research chemicals - Bacteria
                  cultures or other products containing E-coli or Escherichia coli
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Health practitioners, private medical practices, e- doctors, dental/medical services, and facilities</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Human body parts or bodily fluids (excluding hair and teeth)</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Intravenous therapy (e.g., IV drip bar, vitamin infusions, hang over cures)</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>
                  Jewelry, watch, precious stones, metals and silverware stores (excl. non-luxury physical goods, such as
                  silver necklace of low-price range)
                </td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Key-entry telecom merchant</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Live-streaming Services with in-app/fiat currency, and with in-app/fiat donations functionality</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Loot box Games</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Mail order spouse or international match-making services (does not include casual dating)</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Medical, dental, ophthalmic and hospital equipment and supplies</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Medical benefit packages</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Nutraceuticals, food supplements and sports nutrition solutions</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Pawn shops</td>
                <td>Prohibited</td>
              </tr>
              <tr>
                <td>Political, religious or Social Campaigning</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Real estate services</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Timeshare and timeshare maintenance</td>
                <td>Restricted</td>
              </tr>
              <tr>
                <td>Veterinary practices</td>
                <td>Restricted</td>
              </tr>
            </tbody>
          </table>
        </figure>
      </div>
    </div>
  );
};

export default TermsAndConditions;
