import { Routes } from '../routes';
import { MenuCodes } from '../../constants';

export const items = [
  {
    [MenuCodes.HOME]: Routes.HOME,
    [MenuCodes.ACTIVATE_ACCOUNT]: Routes.ACTIVATE_ACCOUNT,
    [MenuCodes.REPORTS]: Routes.REPORTS,
  },
  {
    [MenuCodes.PAYMENT_LINK]: Routes.CREATED_PAYMENT_LINKS,
    [MenuCodes.SUBSCRIPTIONS]: Routes.SUBSCRIPTIONS,
    [MenuCodes.VIRTUAL_POS]: Routes.VIRTUAL_POS,
    [MenuCodes.PAYMENT_BUTTON]: Routes.PAYMENT_BUTTON,
    [MenuCodes.PAYMENT_INTEGRATION]: Routes.PAYMENT_INTEGRATION,
  },
  {
    [MenuCodes.TRANSACTIONS]: Routes.TRANSACTIONS,
    [MenuCodes.REFUNDS]: Routes.REFUNDS,
    [MenuCodes.SETTLEMENTS]: Routes.SETTLEMENTS,
    [MenuCodes.BANK_ACCOUNTS]: Routes.BANK_ACCOUNTS,
  },
  {
    [MenuCodes.COMPANY_INFORMATION]: Routes.COMPANY_INFORMATION,
    [MenuCodes.PAYMENT_METHODS]: Routes.PAYMENT_METHODS,
    [MenuCodes.CLIENTS]: Routes.CLIENTS,
  },
];

export const getMenuSelected = (key: string) => {
  switch (key) {
    case MenuCodes.TRANSACTION_DETAIL:
      return MenuCodes.TRANSACTIONS;
    case MenuCodes.CREATED_PAYMENT_LINKS:
    case MenuCodes.CREATE_ONE_TIME_PAYMENT_LINK:
    case MenuCodes.CREATE_RECURRING_PAYMENT_LINK:
      return MenuCodes.PAYMENT_LINK;
    case MenuCodes.SUBSCRIPTIONS_DETAIL:
    case MenuCodes.SUBSCRIPTIONS_PLANS_DETAILS:
    case MenuCodes.SUBSCRIPTIONS_PLANS_CREATE:
    case MenuCodes.SUBSCRIPTIONS_PLANS_EDIT:
      return MenuCodes.SUBSCRIPTIONS;
    default:
      return key;
  }
};
