import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select as SelectMui, FormControl, InputLabel, FormHelperText, OutlinedInput } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    '&.sm': {
      fontSize: '14px',
    },
    '&.md': {
      fontSize: '16px',
    },
    '&.lg': {
      width: '100%',
    },
  },
  select: {
    backgroundColor: '#ffffff9e',
    fontSize: '14px',
  },
  label: {
    fontSize: '14px',
  },
  selectDisabled: {
    backgroundColor: '#f1f1f19e',
    fontSize: '14px',
  },
}));

const Select = ({
  ref,
  label,
  name,
  value,
  required,
  defaultValue,
  disabled,
  options,
  defaultOptionText,
  optionsDisabled,
  optionsArray,
  optionsArrayDisabled,
  groupOptionsArray,
  groupOptionsArrayDisabled,
  onChange,
  onBlur,
  onFocus,
  onMouseOver,
  onClose,
  size,
  defaultOptionEnabled,
  error,
  message,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={"custom-select "+classes.formControl}
      error={!!error}
      disabled={disabled}
      size={size === 'sm' ? 'small' : undefined}
    >
      <InputLabel className={classes.label} shrink={!!defaultOptionText || undefined} htmlFor={`id-select-${name}`}>
        {label}
      </InputLabel>
      <SelectMui
        native
        ref={ref}
        label={label}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        onMouseOver={onMouseOver}
        onFocus={onFocus}
        onClose={onClose}
        defaultValue={defaultValue}
        notched={!!defaultOptionText || undefined}
        inputProps={{
          name,
          id: `id-select-${name}`,
          className: disabled ? classes.selectDisabled : classes.select,
        }}
      >
        <option value="" disabled={!defaultOptionEnabled}>
          {defaultOptionText}
        </option>
        {options &&
          Object.keys(options).map(key => {
            return (
              <option
                value={key}
                key={`option-${key}`}
                disabled={optionsDisabled && optionsDisabled.includes(key)}
                className="select-option"
              >
                {options[key]}
              </option>
            );
          })}
        {optionsArray &&
          optionsArray.map((op, idx) => {
            return (
              <option
                value={op.value}
                key={`option-${idx.toString()}-${op.value}`}
                disabled={optionsArrayDisabled && optionsArrayDisabled.includes(op.value)}
                className="select-option"
              >
                {op.displayName}
              </option>
            );
          })}
        {groupOptionsArray &&
          groupOptionsArray.map((goup, idx) => {
            return (
              <optgroup label={goup.label} key={`group-${idx.toString()}`}>
                {goup.options.map((op, idx) => {
                  return (
                    <option
                      value={op.value}
                      key={`option-${idx.toString()}-${op.value}`}
                      disabled={groupOptionsArrayDisabled && groupOptionsArrayDisabled.includes(op.value)}
                      className="select-option"
                    >
                      {op.displayName}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
      </SelectMui>
      {(error || message) && <FormHelperText>{error || message}</FormHelperText>}
    </FormControl>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  options: PropTypes.object,
  defaultOptionText: PropTypes.string,
  optionsDisabled: PropTypes.object,
  optionsArray: PropTypes.array,
  optionsArrayDisabled: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  size: PropTypes.string,
  defaultOptionEnabled: PropTypes.bool,
  error: PropTypes.string,
};

Select.defaultProps = {
  ref: undefined,
  groupOptionsArray: undefined,
  groupOptionsArrayDisabled: undefined,
  onFocus: undefined,
  onMouseOver: undefined,
  onClose: undefined,
  message: undefined,
};

export default Select;
