import { countryFilter } from '../../utils/payment-methods-utils';
import { handleErrorInfo } from '../../utils';
import { PaymentMethodsActionType } from './actions';
import { PaymentMethodsState } from './interfaces';

const initialState: PaymentMethodsState = {
  loading: true,
  error: false,
  errorInfo: undefined,
  paymentMethodsGrouped: {},
  paymentMethodsSelected: undefined,
  showEditCoverage: false,
  allPaymentMethods: {
    data: [],
    pagination: undefined,
    loading: false,
  },
  statusAdd: {
    loading: false,
    error: false,
    errorInfo: undefined,
  },
  statusDelete: {
    loading: false,
    error: false,
    errorInfo: undefined,
  },
};

const PaymentMethods = (state = initialState, action: any) => {
  switch (action.type) {
    case PaymentMethodsActionType.GET_SELECTED_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        paymentMethodsGrouped: countryFilter(action.response),
        paymentMethodsSelected: action.response,
      };
    }
    case PaymentMethodsActionType.GET_SELECTED_PAYMENT_METHOD_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(PaymentMethodsActionType.GET_SELECTED_PAYMENT_METHOD_ERROR, action.error),
      };
    }
    case PaymentMethodsActionType.GET_ALL_PAYMENT_METHODS: {
      return {
        ...state,
        allPaymentMethods: {
          ...state.allPaymentMethods,
          loading: true,
          error: false,
          errorInfo: undefined,
          data: [],
          pagination: {},
        },
      };
    }
    case PaymentMethodsActionType.GET_ALL_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        allPaymentMethods: {
          ...state.allPaymentMethods,
          loading: false,
          error: false,
          errorInfo: undefined,
          data: action.response.data,
          pagination: {
            totalElements: action.response.totalElements,
            totalPages: action.response.totalPages,
            page: action.response.page,
            numberOfElements: action.response.numberOfElements,
            size: action.response.size,
          },
        },
      };
    }
    case PaymentMethodsActionType.GET_ALL_PAYMENT_METHODS_ERROR: {
      return {
        ...state,
        allPaymentMethods: {
          ...state.allPaymentMethods,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(PaymentMethodsActionType.GET_ALL_PAYMENT_METHODS_ERROR, action.error),
          data: [],
          pagination: {},
        },
      };
    }
    case PaymentMethodsActionType.ADD_PAYMENT_METHOD: {
      return {
        ...state,
        statusAdd: {
          ...state.statusAdd,
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case PaymentMethodsActionType.ADD_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        statusAdd: {
          ...state.statusAdd,
          loading: false,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case PaymentMethodsActionType.ADD_PAYMENT_METHOD_ERROR: {
      return {
        ...state,
        statusAdd: {
          ...state.statusAdd,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(PaymentMethodsActionType.ADD_PAYMENT_METHOD_ERROR, action.error),
        },
      };
    }
    case PaymentMethodsActionType.DELETE_PAYMENT_METHOD: {
      return {
        ...state,
        statusDelete: {
          ...state.statusDelete,
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case PaymentMethodsActionType.DELETE_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        statusDelete: {
          ...state.statusDelete,
          loading: false,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case PaymentMethodsActionType.DELETE_PAYMENT_METHOD_ERROR: {
      return {
        ...state,
        statusDelete: {
          ...state.statusDelete,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(PaymentMethodsActionType.DELETE_PAYMENT_METHOD_ERROR, action.error),
        },
      };
    }
    case PaymentMethodsActionType.EDIT_COVERAGE: {
      return {
        ...state,
        showEditCoverage: action.show,
      };
    }
    case PaymentMethodsActionType.SAVE_COVERAGE: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
      };
    }
    case PaymentMethodsActionType.SAVE_COVERAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        showEditCoverage: false,
      };
    }
    case PaymentMethodsActionType.SAVE_COVERAGE_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(PaymentMethodsActionType.SAVE_COVERAGE_ERROR, action.error),
      };
    }
    default:
      return state;
  }
};

export default PaymentMethods;
