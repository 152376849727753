import { FunctionComponent, ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme: any) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    '&:before': {
      display: 'none',
    },
  },
}));

interface AccordionWrapperProps {
  header?: ReactNode;
  children?: ReactNode;
  isExpanded?: boolean;
  onChange?: (nextIsExpanded: boolean) => void;
}

const AccordionWrapper: FunctionComponent<AccordionWrapperProps> = ({ header, children, isExpanded, onChange }) => {
  const classes = useStyles();

  return (
    <Accordion
      className={classes.root}
      expanded={isExpanded}
      onChange={(_, nextIsExpanded) => onChange && onChange(nextIsExpanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {typeof header === 'string' ? <Typography variant="subtitle1">{header}</Typography> : header}
      </AccordionSummary>
      <AccordionDetails>
        {typeof children === 'string' ? <Typography variant="body1">{children}</Typography> : children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionWrapper;
