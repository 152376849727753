import l from '../../../../../lang';

export const hideFieldsByCountry: { [key: string]: string[] } = {
  AR: ['bankBranch', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  BO: ['bankBranch', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  BR: ['beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  CL: ['bankBranch', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  CO: ['bankBranch', 'beneficiaryCity', 'beneficiaryPhone'],
  CR: ['bankCode', 'bankBranch', 'beneficiaryCity', 'beneficiaryPhone'],
  EC: ['bankBranch', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  ES: ['accountType', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  GT: ['bankBranch', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  ID: ['bankBranch', 'beneficiaryAddress', 'beneficiaryCity'],
  KE: ['bankCode', 'accountType', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  MY: ['bankBranch', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  NG: ['accountType', 'bankBranch', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  MX: ['bankBranch', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  PA: ['bankBranch', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  PE: ['bankBranch', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  PY: ['bankBranch', 'beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
  US: ['beneficiaryCity', 'beneficiaryPhone'],
  UY: ['beneficiaryAddress', 'beneficiaryCity', 'beneficiaryPhone'],
};

export const showFieldsByCountry: { [key: string]: [string] } = {
  US: ['comments'],
};

export const bankAccountNumberLabels: { [key: string]: string } = {
  PE: 'CCI',
  MX: 'CLABE',
  AR: 'CBU / CVU',
  CR: 'IBAN',
  PY: 'SIPAP',
  ES: 'IBAN',
  NG: 'NUBAN',
};

export const bankBranchLabels: { [key: string]: string } = {
  ES: l('beneficiary.swiftCode'),
  US: l('beneficiary.routingNumber'),
  KE: l('beneficiary.swiftCode'),
};

export const commentsLabels: { [key: string]: string } = {
  US: l('beneficiary.swiftCode'),
};
