import { useDispatch, useSelector } from 'react-redux';
import { Values } from '../interfaces';
import { getBanks, getPaymentMethodsForRefunds, getRefunds, sendRequestRefund } from './actions';
import { RefundsSelectors } from './selectors';

export const useRefunds = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(RefundsSelectors.getLoading),
    error: useSelector(RefundsSelectors.getError),
    allRefunds: useSelector(RefundsSelectors.getAllRefunds),
    requestRefund: useSelector(RefundsSelectors.getRequestRefund),
    paymentMethods: useSelector(RefundsSelectors.getPaymentMethods),
    getRefunds: (page?: number, filter?: string) => dispatch(getRefunds(page, filter)),
    getBanks: (country: string) => dispatch(getBanks(country)),
    sendRequestRefund: (values: Values) => dispatch(sendRequestRefund(values)),
    getPaymentMethodsForRefunds: (country: string) => dispatch(getPaymentMethodsForRefunds(country)),
  };
};
