import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { MenuBook as MenuBookIcon } from '@mui/icons-material';
import ButtonLink from '../button-link';
import { openNewTabAndFocus } from '../../../utils';
import l from '../../../lang';

const LinkDocs = ({ textKey, url, withPadding = false }) => (
  <div className={`link-docs ${withPadding ? 'link-docs_with-padding' : ''}`}>
    <ButtonLink
      text={
        <span>
          <MenuBookIcon style={{ fontSize: 18, marginBottom: -4 }} /> {l(textKey)}
        </span>
      }
      onClick={() => openNewTabAndFocus(url)}
    />
  </div>
);

LinkDocs.propTypes = {
  textKey: PropTypes.string,
  url: PropTypes.string,
};

export default LinkDocs;
