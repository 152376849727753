import { useDispatch, useSelector } from 'react-redux';
import { getCurrenciesPI, getIntegrationKeys, setDetailsOpen as setDetailsOpened, setShopifyTestMode } from './actions';
import { DetailsOpened } from './interfaces';
import { PaymentIntegrationSelectors } from './selectors';

export const usePaymentIntegration = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(PaymentIntegrationSelectors.getLoading),
    error: useSelector(PaymentIntegrationSelectors.getError),
    errorInfo: useSelector(PaymentIntegrationSelectors.getErrorInfo),
    apiKey: useSelector(PaymentIntegrationSelectors.getApiKey),
    secretKey: useSelector(PaymentIntegrationSelectors.getSecretKey),
    currencies: useSelector(PaymentIntegrationSelectors.getCurrencies),
    exchangeRates: useSelector(PaymentIntegrationSelectors.getExchangeRates),
    shopifyIntegration: useSelector(PaymentIntegrationSelectors.getShopifyIntegration),
    detailsOpened: useSelector(PaymentIntegrationSelectors.getDetailsOpened),
    getIntegrationKeys: () => dispatch(getIntegrationKeys()),
    getCurrenciesPI: (country: string) => dispatch(getCurrenciesPI(country)),
    setShopifyTestMode: (testMode: boolean) => dispatch(setShopifyTestMode(testMode)),
    setDetailsOpened: (details: DetailsOpened) => dispatch(setDetailsOpened(details)),
  };
};
