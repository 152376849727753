import { handleErrorInfo } from '../../utils';
import { Currencies , ExchangeRates } from '../interfaces';
import { PaymentIntegrationType } from './actions';
import { DetailsOpened, PaymentIntegrationState } from './interfaces';

const initialState: PaymentIntegrationState = {
  loading: false,
  error: false,
  errorInfo: undefined,
  apiKey: '',
  secretKey: '',
  currencies: { USD: 'USD' },
  exchangeRates: {},
  shopifyIntegration: {
    loading: false,
    error: false,
  },
  detailsOpened: DetailsOpened.NONE,
};

const PaymentIntegration = (state = initialState, action: any) => {
  switch (action.type) {
    case PaymentIntegrationType.GET_INTEGRATION_KEYS: {
      return {
        ...state,
        loading: true,
        error: false,
        apiKey: '',
      };
    }
    case PaymentIntegrationType.GET_INTEGRATION_KEYS_SUCCESS: {
      const { apiKey, secretKey } = action.response;
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        apiKey,
        secretKey,
      };
    }
    case PaymentIntegrationType.GET_INTEGRATION_KEYS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(PaymentIntegrationType.GET_INTEGRATION_KEYS_ERROR, action.error),
        apiKey: '',
      };
    }
    case PaymentIntegrationType.GET_CURRENCIES_PI: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        currencies: {},
      };
    }
    case PaymentIntegrationType.GET_CURRENCIES_PI_SUCCESS: {
      const currencies: Currencies = {};
      const exchangeRates: ExchangeRates = {};

      action.response.forEach((c: { currency: string, exchangeRate: number }) => {
        currencies[c.currency] = c.currency;
        exchangeRates[c.currency] = c.exchangeRate;
      });
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        currencies,
        exchangeRates
      };
    }
    case PaymentIntegrationType.GET_CURRENCIES_PI_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(PaymentIntegrationType.GET_CURRENCIES_PI_ERROR, action.error),
        currencies: {},
      };
    }
    case PaymentIntegrationType.SET_SHOPIFY_TEST_MODE: {
      return {
        ...state,
        shopifyIntegration: {
          loading: true,
          error: false,
        },
      };
    }
    case PaymentIntegrationType.SET_SHOPIFY_TEST_MODE_SUCCESS: {
      return {
        ...state,
        shopifyIntegration: {
          loading: false,
          error: false,
        },
      };
    }
    case PaymentIntegrationType.SET_SHOPIFY_TEST_MODE_ERROR: {
      return {
        ...state,
        shopifyIntegration: {
          loading: false,
          error: true,
        },
      };
    }
    case PaymentIntegrationType.SET_DETAILS_OPENED: {
      return {
        ...state,
        detailsOpened: action.payload
      };
    }
    default:
      return state;
  }
};

export default PaymentIntegration;
