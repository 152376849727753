import React from 'react';
import { Switch as SwitchMUI } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: 36,
    height: 20,
    padding: 2,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      color: '#006CFA',
      padding: 5,
      '&.Mui-checked': {
        transform: 'translateX(14px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.2,
        },
      },
      '&.Mui-disabled': {
        opacity: 0.2,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#fff',
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 20 / 2,
      opacity: 1,
      backgroundColor: '#fff',
      border: `solid 1px ${theme.palette.primary.main}`,
    },
  },
}));

const Switch = ({ checked, disabled, onChange }) => {
  const classes = useStyles();
  return <SwitchMUI classes={classes} checked={checked} disabled={disabled} onChange={onChange} />;
};

export default Switch;
