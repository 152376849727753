import { JumioType } from './actions';
import { JumioState } from './interfaces';

const initialState: JumioState = {
  loading: false,
  url: undefined,
  status: undefined,
  limitReached: false,
  displayMessageKycStep1: false,
};

const Jumio = (state = initialState, action: { type: JumioType; payload: Partial<JumioState> }) => {
  switch (action.type) {
    case JumioType.START: {
      return {
        ...state,
        loading: true,
        url: undefined,
      };
    }
    case JumioType.START_SUCCESS: {
      return {
        ...state,
        loading: false,
        url: action.payload.url,
      };
    }
    case JumioType.START_ERROR: {
      return {
        ...state,
        loading: false,
        url: undefined,
      };
    }
    case JumioType.STATUS_UPDATED: {
      return {
        ...state,
        url: undefined,
        status: action.payload.status,
        tryCount: action.payload.tryCount ?? state.tryCount ?? 0,
        limitReached: action.payload.limitReached ?? state.limitReached,
      };
    }
    case JumioType.LOADING_STATUS: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default Jumio;
