import { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import _ from 'lodash';
import l from '../../../../../lang';
import './styles.scss';
import { commons as validations } from '../../../../../utils/validations';
import { allCountries } from '../../../../../config';
import { scrollIntoView } from '../../../../../utils';
import PaymentLinkDialog from '../../payment-link-dialog';
import CreateFormFields from './create-form-fields';
import { useDashboard } from '../../../../../store/dashboard';
import { usePaymentLink } from '../../../../../store/payment-link';

const CreatePaymentLinkForm = ({ isRecurring = false }) => {
  const { loading, error, errorInfo, errorFields, showResult, createSuccess, paymentLinkResult, postCreatePaymentLink } =
    usePaymentLink();
  const { merchantMe } = useDashboard();
  const [showClientData, setShowClientData] = useState(false);
  const [newClient, setNewClient] = useState(true);

  const errorCardRef = useRef(null);

  const countryRef = useRef(null);
  const currencyRef = useRef(null);
  const amountRef = useRef(null);
  const clientEmailRef = useRef(null);
  const sendEmailRef = useRef(null);
  const clientDocumentRef = useRef(null);

  const scrollToFormErrors = errors => {
    if (errors.country) {
      scrollIntoView(countryRef);
    } else if (errors.currency) {
      scrollIntoView(currencyRef);
    } else if (errors.amount) {
      scrollIntoView(amountRef);
    } else if (errors.clientEmail) {
      scrollIntoView(clientEmailRef);
    } else if (errors.sendEmail) {
      scrollIntoView(sendEmailRef);
    } else if (errors.clientDocument) {
      scrollIntoView(clientDocumentRef);
    }
  };

  useEffect(() => {
    if (error) {
      scrollIntoView(errorCardRef);
    }
  }, [error]);

  const initialValues = {
    country: '',
    currency: '',
    amount: 0,
    clientEmail: '',
    sendEmail: false,
    clientFirstName: '',
    clientLastName: '',
    clientDocumentType: '',
    clientDocument: '',
    description: '',
    expirationType: 'DAYS',
    expirationValue: '15',
    expirationEnabled: false,

    clientSelected: null,
    crossBorderData: {
      secondCurrency: '',
      secondAmount: 0,
    },
  };

  const validate = values => {
    const errors = {};
    if (!isRecurring && !values.country) {
      errors.country = l('form.input.required');
    } else if (errorFields?.country === values.country && !_.isEmpty(errorFields?.country) && errorInfo?.message) {
      errors.country = l(errorInfo.message);
    }
    if (!values.currency) {
      errors.currency = l('form.input.required');
    }
    if (values.amount <= 0) {
      errors.amount = l('paymentLink.amount.error');
    } else if (errorFields?.amount == values.amount && errorInfo?.message && errorInfo.messageArgs) {
      errors.amount = l(errorInfo.message, ...errorInfo.messageArgs);
    }
    if (errorFields?.clientDocument === values.clientDocument && !_.isEmpty(errorFields?.clientDocument)) {
      errors.clientDocument = l('paymentLink.document.error');
    }

    if (values.expirationEnabled) {
      if(!values.expirationType){
        errors.expirationType = l('form.input.required');
      }
      if(!values.expirationValue){
        errors.expirationValue = l('form.input.required');
      } else if (values.expirationValue < 5 && values.expirationType === 'MINUTES') {
        errors.expirationValue = l('expirationBox.minimumValue');
      }
    }

    function getEmailErrors({ clientEmail, sendEmail, clientSelected }, showClientData, newClient) {
      const emailErrors = {};
      if (sendEmail && (!showClientData || newClient) && !clientEmail) {
        emailErrors.clientEmail = l('form.input.required');
      }
      if ((!showClientData || newClient) && clientEmail && !validations.emailValid(clientEmail)) {
        emailErrors.clientEmail = l('form.email.invalid');
      }
      if (sendEmail && showClientData && !newClient) {
        if (!clientSelected) {
          emailErrors.clientSelected = l('paymentLink.existingClientError');
        }
        if (clientSelected && !clientSelected.email) {
          emailErrors.sendEmail = l('paymentLink.sendEmailError');
        }
      }
      return emailErrors;
    }
    const emailErrors = getEmailErrors(values, showClientData, newClient);

    return { ...errors, ...emailErrors };
  };

  const countries = () => {
    const countries = {};

    const allowedCountries = merchantMe?.allowLocalToLocal
      ? allCountries
      : allCountries.filter(co => co !== merchantMe?.country);

    allowedCountries.forEach(co => (countries[co] = l(`countryCode.${co}`)));

    return countries;
  };

  const handleSubmit = values => {
    const valuesToSend = {};
    Object.keys(values).forEach(key => {
      if (typeof values[key] === 'string') {
        if (values[key].trim() !== '') {
          valuesToSend[key] = values[key];
        }
      } else {
        valuesToSend[key] = values[key];
      }
    });

    const { country, currency, amount, description, clientEmail, sendEmail, expirationEnabled, expirationType, expirationValue } = valuesToSend;
    const commonValues = {
      country,
      currency,
      amount,
      description,
      clientEmail,
      sendEmail,
      expirationType: (expirationEnabled && expirationType) || null,
      expirationValue: (expirationEnabled && expirationValue) || null,
    };

    if (showClientData) {
      if (newClient) {
        const { clientFirstName, clientLastName, clientDocumentType, clientDocument } = valuesToSend;
        postCreatePaymentLink(
          {
            ...commonValues,
            clientFirstName,
            clientLastName,
            clientDocumentType,
            clientDocument,
            expirationType,
            expirationValue
          },
          isRecurring
        );
      } else {
        const { clientSelected } = valuesToSend;
        postCreatePaymentLink(
          {
            ...commonValues,
            merchantClientId: clientSelected.id,
          },
          isRecurring
        );
      }
    } else {
      postCreatePaymentLink(commonValues, isRecurring);
    }
  };

  useEffect(() => {
    if (createSuccess) {
      paymentLinkResult(true);
    }
  }, [createSuccess]);

  return (
    <>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        {formikProps => {
          const { values, resetForm } = formikProps;
          return (
            <>
              <CreateFormFields
                isRecurring={isRecurring}
                scrollToFormErrors={scrollToFormErrors}
                setShowClientData={setShowClientData}
                setNewClient={setNewClient}
                errorCardRef={errorCardRef}
                countryRef={countryRef}
                amountRef={amountRef}
                currencyRef={currencyRef}
                clientEmailRef={clientEmailRef}
                clientDocumentRef={clientDocumentRef}
                countries={countries}
                showClientData={showClientData}
                newClient={newClient}
                {...formikProps}
              />
              {showResult && (
                <PaymentLinkDialog
                  isRecurring={isRecurring}
                  url={createSuccess?.url ?? ''}
                  country={values.country}
                  currency={values.currency}
                  amount={values.amount}
                  description={values.description}
                  crossBorderData={values.crossBorderData}
                  onClose={() => {
                    resetForm();
                    paymentLinkResult(false);
                  }}
                />
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default CreatePaymentLinkForm;
