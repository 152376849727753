import { useDispatch, useSelector } from 'react-redux';
import { getClients } from './actions';
import { ClientsSelectors } from './selectors';

export const useClients = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(ClientsSelectors.getLoading),
    error: useSelector(ClientsSelectors.getError),
    errorInfo: useSelector(ClientsSelectors.getErrorInfo),
    allClients: useSelector(ClientsSelectors.getAllClients),
    getClients: (page?: number) => dispatch(getClients(page)),
  };
};
