import _ from 'lodash';
import l from '../../../../../lang';
import Filter from '../../../../common/filter';
import './styles.scss';
import { Filters } from '../../../../../store/interfaces';
import useComponentsFilter from '../use-components-filter';

interface TransactionFilterGroupedProps {
  appliedFilters: Filters;
  clearFilters: () => void;
  changeFilters: (filters: Filters) => void;
}

const TransactionFilterGrouped = ({ appliedFilters, clearFilters, changeFilters }: TransactionFilterGroupedProps) => {
  const { dateRangeFilter, countryFilter, paymentMethodsFilter, statusFilter } = useComponentsFilter({
    appliedFilters,
    changeFilters,
  });

  const activeFilters = Object.values(appliedFilters).filter(v => v)?.length || null;

  return (
    <div className="transaction_filter_grouped">
      <Filter
        clearFilter={clearFilters}
        appliedFilters={activeFilters}
        items={[
          {
            title: <span className="transaction_filter_grouped__title">{l('transactions.status')}</span>,
            component: statusFilter,
          },
          {
            title: <span className="transaction_filter_grouped__title">{l('transactions.country')}</span>,
            component: countryFilter,
          },
          {
            title: <span className="transaction_filter_grouped__title">{l('transactions.date')}</span>,
            component: dateRangeFilter,
          },
          {
            title: <span className="transaction_filter_grouped__title">{l('transactions.paymentMethod')}</span>,
            component: paymentMethodsFilter,
          },
        ]}
      />
    </div>
  );
};

export default TransactionFilterGrouped;
