import { Values } from '../interfaces';

export enum BankAccountsActionType {
  GET_BANK_ACCOUNTS = 'GET_BANK_ACCOUNTS',
  GET_BANK_ACCOUNTS_SUCCESS = 'GET_BANK_ACCOUNTS_SUCCESS',
  GET_BANK_ACCOUNTS_ERROR = 'GET_BANK_ACCOUNTS_ERROR',
  GET_BANKS_BY_COUNTRY = 'GET_BANKS_BY_COUNTRY',
  GET_BANKS_BY_COUNTRY_SUCCESS = 'GET_BANKS_BY_COUNTRY_SUCCESS',
  GET_BANKS_BY_COUNTRY_ERROR = 'GET_BANKS_BY_COUNTRY_ERROR',
  ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT',
  ADD_BANK_ACCOUNT_SUCCESS = 'ADD_BANK_ACCOUNT_SUCCESS',
  ADD_BANK_ACCOUNT_ERROR = 'ADD_BANK_ACCOUNT_ERROR',
  DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT',
  DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS',
  DELETE_BANK_ACCOUNT_ERROR = 'DELETE_BANK_ACCOUNT_ERROR',
  BANK_ACCOUNT_RESET_CHANGES = 'BANK_ACCOUNT_RESET_CHANGES',
}

export const getBankAccounts = (page?: number, statusFilter?: string) => ({
  type: BankAccountsActionType.GET_BANK_ACCOUNTS,
  page,
  statusFilter,
});

export const getBankAccountsSuccess = (response: any) => ({
  type: BankAccountsActionType.GET_BANK_ACCOUNTS_SUCCESS,
  response,
});

export const getBankAccountsError = (error: any) => ({
  type: BankAccountsActionType.GET_BANK_ACCOUNTS_ERROR,
  error,
});

export const getBanksByCountry = (country: string) => ({
  type: BankAccountsActionType.GET_BANKS_BY_COUNTRY,
  country,
});

export const getBanksByCountrySuccess = (response: any) => ({
  type: BankAccountsActionType.GET_BANKS_BY_COUNTRY_SUCCESS,
  response,
});

export const getBanksByCountryError = (error: any) => ({
  type: BankAccountsActionType.GET_BANKS_BY_COUNTRY_ERROR,
  error,
});

export const addBankAccount = (values: Values, isStep2?: boolean) => ({
  type: BankAccountsActionType.ADD_BANK_ACCOUNT,
  values,
  isStep2,
});

export const addBankAccountSuccess = (response: any) => ({
  type: BankAccountsActionType.ADD_BANK_ACCOUNT_SUCCESS,
  response,
});

export const addBankAccountError = (error: any) => ({
  type: BankAccountsActionType.ADD_BANK_ACCOUNT_ERROR,
  error,
});

export const deleteBankAccount = (id: number) => ({
  type: BankAccountsActionType.DELETE_BANK_ACCOUNT,
  id,
});

export const sendDeleteBankAccountSuccess = (id: number) => ({
  type: BankAccountsActionType.DELETE_BANK_ACCOUNT_SUCCESS,
  id,
});

export const sendDeleteBankAccountError = (error: any) => ({
  type: BankAccountsActionType.DELETE_BANK_ACCOUNT_ERROR,
  error,
});

export const deleteBankAccountReset = () => ({
  type: BankAccountsActionType.BANK_ACCOUNT_RESET_CHANGES,
});
