import { PanelStore } from '../interfaces';

export namespace JumioSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.jumio.loading;
  };
  export const getStatus = (state: PanelStore.RootState) => {
    return state.jumio.status;
  };
  export const getLimitReached = (state: PanelStore.RootState) => {
    return state.jumio.limitReached || false;
  };
  export const getTryCount = (state: PanelStore.RootState) => {
    return state.jumio.tryCount || 0;
  };
  export const getUrl = (state: PanelStore.RootState) => {
    return state.jumio.url;
  };
}
