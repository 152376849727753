import { ErrorAPI, Values } from '../interfaces';
import { Plan, Subscription } from './interfaces';

export enum SubscriptionsActionType {
  GET_SUBSCRIPTIONS = 'SUBSCRIPTIONS/GET_SUBSCRIPTIONS',
  GET_SUBSCRIPTIONS_SUCCESS = 'SUBSCRIPTIONS/GET_SUBSCRIPTIONS_SUCCESS',
  GET_SUBSCRIPTIONS_ERROR = 'SUBSCRIPTIONS/GET_SUBSCRIPTIONS_ERROR',
  SHOW_SUBSCRIPTION_DETAILS = 'SUBSCRIPTIONS/SHOW_SUBSCRIPTION_DETAILS',
  SHOW_SUBSCRIPTION_DETAILS_PLAN = 'SUBSCRIPTIONS/SHOW_SUBSCRIPTION_DETAILS_PLAN',
  GET_PLANS = 'SUBSCRIPTIONS/GET_PLANS',
  GET_PLANS_SUCCESS = 'SUBSCRIPTIONS/GET_PLANS_SUCCESS',
  GET_PLANS_ERROR = 'SUBSCRIPTIONS/GET_PLANS_ERROR',
  GET_CURRENCIES = 'PAYMENT_LINK/GET_CURRENCIES',
  GET_CURRENCIES_SUCCESS = 'PAYMENT_LINK/GET_CURRENCIES_SUCCESS',
  GET_CURRENCIES_ERROR = 'PAYMENT_LINK/GET_CURRENCIES_ERROR',
  CLEAN_CURRENCIES = 'PAYMENT_LINK/CLEAN_CURRENCIES',
  CLEAN_ERROR_SUBSCRIPTIONS = 'PAYMENT_LINK/CLEAN_ERROR_SUBSCRIPTIONS',
  CREATE_UPDATE_PLAN = 'SUBSCRIPTIONS/CREATE_UPDATE_PLAN',
  CREATE_UPDATE_PLAN_SUCCESS = 'SUBSCRIPTIONS/CREATE_UPDATE_PLAN_SUCCESS',
  CREATE_UPDATE_PLAN_ERROR = 'SUBSCRIPTIONS/CREATE_UPDATE_PLAN_ERROR',
  CLEAR_CREATE_UPDATE_PLAN = 'SUBSCRIPTIONS/CLEAR_CREATE_UPDATE_PLAN',
  SET_SUBSCRIPTION_DETAILS = 'SUBSCRIPTIONS/SET_SUBSCRIPTION_DETAILS',
  SET_PLAN_DETAILS = 'SUBSCRIPTIONS/SET_PLAN_DETAILS',
  DEACTIVATE_SUBSCRIPTION = 'SUBSCRIPTIONS/DEACTIVATE_SUBSCRIPTION',
  DEACTIVATE_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTIONS/DEACTIVATE_SUBSCRIPTION_SUCCESS',
  DEACTIVATE_SUBSCRIPTION_ERROR = 'SUBSCRIPTIONS/DEACTIVATE_SUBSCRIPTION_ERROR',
  DEACTIVATE_PLAN = 'SUBSCRIPTIONS/DEACTIVATE_PLAN',
  DEACTIVATE_PLAN_SUCCESS = 'SUBSCRIPTIONS/DEACTIVATE_PLAN_SUCCESS',
  DEACTIVATE_PLAN_ERROR = 'SUBSCRIPTIONS/DEACTIVATE_PLAN_ERROR',
}

export const getSubscriptions = (page?: number, rowsPerPage?: number) => ({
  type: SubscriptionsActionType.GET_SUBSCRIPTIONS,
  page,
  rowsPerPage,
});

export const getSubscriptionsSuccess = (response: any) => ({
  type: SubscriptionsActionType.GET_SUBSCRIPTIONS_SUCCESS,
  response,
});

export const getSubscriptionsError = (error: ErrorAPI) => ({
  type: SubscriptionsActionType.GET_SUBSCRIPTIONS_ERROR,
  error,
});

export const setSubscriptionDetails = (subscription: Subscription | null) => ({
  type: SubscriptionsActionType.SET_SUBSCRIPTION_DETAILS,
  subscription,
});

export const showSubscriptionDetailsPlan = (plan: Plan | null) => ({
  type: SubscriptionsActionType.SHOW_SUBSCRIPTION_DETAILS_PLAN,
  plan,
});

export const getPlans = (page?: number, rowsPerPage?: number) => ({
  type: SubscriptionsActionType.GET_PLANS,
  page,
  rowsPerPage,
});

export const getPlansSuccess = (response: any) => ({
  type: SubscriptionsActionType.GET_PLANS_SUCCESS,
  response,
});

export const getPlansError = (error: ErrorAPI) => ({
  type: SubscriptionsActionType.GET_PLANS_ERROR,
  error,
});

export const cleanErrorSubscriptions = () => ({
  type: SubscriptionsActionType.CLEAN_ERROR_SUBSCRIPTIONS,
});

export const getCurrencies = (country: string) => ({
  type: SubscriptionsActionType.GET_CURRENCIES,
  country,
});

export const getCurrenciesSuccess = (response: any) => ({
  type: SubscriptionsActionType.GET_CURRENCIES_SUCCESS,
  response,
});

export const getCurrenciesError = (error: any) => ({
  type: SubscriptionsActionType.GET_CURRENCIES_ERROR,
  error,
});

export const cleanCurrencies = () => ({
  type: SubscriptionsActionType.CLEAN_CURRENCIES,
});

export const createUpdatePlan = (body: Values) => ({
  type: SubscriptionsActionType.CREATE_UPDATE_PLAN,
  body,
});

export const createUpdatePlanSuccess = (response: any) => ({
  type: SubscriptionsActionType.CREATE_UPDATE_PLAN_SUCCESS,
  response,
});

export const createUpdatePlanError = (error: any) => ({
  type: SubscriptionsActionType.CREATE_UPDATE_PLAN_ERROR,
  error,
});

export const clearCreateUpdatePlan = () => ({
  type: SubscriptionsActionType.CLEAR_CREATE_UPDATE_PLAN,
});

export const setPlanDetails = (plan: Plan | null) => ({
  type: SubscriptionsActionType.SET_PLAN_DETAILS,
  plan,
});

export const deactivateSubscription = (subscriptionId: number) => ({
  type: SubscriptionsActionType.DEACTIVATE_SUBSCRIPTION,
  subscriptionId,
});

export const deactivateSubscriptionSuccess = (response: any) => ({
  type: SubscriptionsActionType.DEACTIVATE_SUBSCRIPTION_SUCCESS,
  response,
});

export const deactivateSubscriptionError = (error: ErrorAPI) => ({
  type: SubscriptionsActionType.DEACTIVATE_SUBSCRIPTION_ERROR,
  error,
});

export const deactivatePlan = (planId: number) => ({
  type: SubscriptionsActionType.DEACTIVATE_PLAN,
  planId,
});

export const deactivatePlanSuccess = (response: any) => ({
  type: SubscriptionsActionType.DEACTIVATE_PLAN_SUCCESS,
  response,
});

export const deactivatePlanError = (error: ErrorAPI) => ({
  type: SubscriptionsActionType.DEACTIVATE_PLAN_ERROR,
  error,
});
