import { useHistory } from 'react-router-dom';
import CardInfo from '../../common/card-info';
import l from '../../../lang';
import { ReactComponent as ClickIcon } from '../../../assets/icons/click.svg';
import ButtonLink from '../../common/button-link';
import { Routes } from '../../../config/routes';

import './styles.scss';

const BankAccountEmpty = () => {
  const history = useHistory();

  return (
    <div className="bank_accounts__empty">
      <CardInfo>
      <div className="bank_accounts__empty__content">
        <ClickIcon />
        <div>
          {l('bankAccounts.empty.desc1')}
          <ButtonLink text={l('bankAccounts.empty.link')} onClick={() => history.push(Routes.COMPANY_INFORMATION)} />
          {l('bankAccounts.empty.desc2')}
        </div>
        </div>
      </CardInfo>
    </div>
  );
};

export default BankAccountEmpty;
