// eslint-disable-next-line max-classes-per-file
export class commons {
  static nameValid = value => /^.{2,}$/i.test(value.trim());

  static emailValid = value => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);

  static passwordRequiredCharacters = value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).+$/.test(value);

  static passwordLengthRequired = value => /^(?=.{8,}$)\S+$/i.test(value);
}

export class kycValidations {
  static nameValid = value => /^.{2,}$/i.test(value.trim());

  static webSite = value =>
    /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/.test(value);

  static webSiteWihtoutHttp = value =>
    /^(?!:http(s)?:!\/\/)[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/.test(value);

  static httpsWebSite = value =>
    /^(?:https:\/\/)[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/.test(value);
}
