import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import l from '../../../../lang';
import UploadFileWithPreview from '../../../common/upload-file-with-preview';
import config from '../../../../config';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import ButtonLink from '../../../common/button-link';
import './styles.scss';
import MessageError from '../../../common/message-error';
import Loading from '../../../common/loading';
import { deleteLogo, uploadNewLogo, cleanSuccess, showUploadLogo } from '../../../../store/company-information/actions';
import SuccessfulAnimation from '../../../common/successful-animation';
import { updateMerchantMe } from '../../../../store/dashboard/actions';

const UploadLogo = ({
  loading,
  error,
  errorInfo,
  logoUrl,
  uploadSuccess,
  deleteSuccess,
  savedUrl,
  fnDeleteLogo,
  fnUploadLogo,
  fnCleanSuccess,
  fnUpdateMerchantMe,
  fnShowUploadLogo,
}) => {
  const [logo, setLogo] = useState(null);
  const [showUpload, setShowUpload] = useState(!logoUrl);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (uploadSuccess || deleteSuccess) {
        if (savedUrl) {
          setLogo(null);
        }
        setShowUpload(!savedUrl);
        fnUpdateMerchantMe({ logoUrl: savedUrl ? `${savedUrl}?hash=${uuidv4()}` : null });
        fnCleanSuccess();
      }
    }, 2000);
  }, [uploadSuccess, deleteSuccess, savedUrl, fnUpdateMerchantMe, fnCleanSuccess]);

  const viewSuccess = uploadSuccess || deleteSuccess;
  return (
    <div className="upload-logo">
      {loading && (
        <div className="upload-logo__loading">
          <Loading />
        </div>
      )}
      {viewSuccess && (
        <div className="upload-logo__success">
          <SuccessfulAnimation text={l(`${uploadSuccess ? 'uploadLogo.uploadSuccess' : 'uploadLogo.deleteSuccess'}`)} />
        </div>
      )}
      <div className={`upload-logo__body ${loading || viewSuccess ? 'upload-logo__body--hide' : ''}`}>
        {logoUrl && !showUpload && (
          <div className="upload-logo__loaded">
            <img key={logoUrl} className="upload-logo__loaded__img" src={logoUrl} alt="" />
            <div>
              <ButtonLink text={l('uploadLogo.update')} onClick={() => setShowUpload(true)} disabled={deleteConfirm} />
              <span> / </span>
              <ButtonLink text={l('uploadLogo.remove')} onClick={() => setDeleteConfirm(true)} />
            </div>
          </div>
        )}
        {showUpload && (
          <div className="upload-logo__upload-file">
            <UploadFileWithPreview
              preview
              description={l(
                'uploadLogo.allowedDesc',
                config.uploadFileLogoMaxSize,
                config.uploadFileSupportedExtensionsForImages.join(', ')
              )}
              supportedExtensions={config.uploadFileSupportedExtensionsForImages}
              maxSize={config.uploadFileMaxSize}
              onChange={value => setLogo(value)}
              disabled={loading}
            />
          </div>
        )}
        {error && (
          <div className="upload-logo__error">
            <MessageError errorInfo={errorInfo} />
          </div>
        )}
      </div>
      {showUpload && !viewSuccess && !loading && (
        <div className="confirm-footer-container upload-logo__buttons">
          <ButtonCustom
            text={l('common.cancel')}
            size="half-left"
            onClick={() => {
              setLogo(null);
              setShowUpload(false);
              if (!logoUrl) {
                fnShowUploadLogo(false);
              }
            }}
          />
          <ButtonCustom
            text={l('uploadLogo.saveLogo')}
            variant="contained"
            size="half-right"
            onClick={() => {
              fnUploadLogo(logo);
            }}
            disabled={!logo}
          />
        </div>
      )}
      {deleteConfirm && !viewSuccess && !loading && (
        <div className="upload-logo__remove">
          <div className="upload-logo__remove__desc">{l('uploadLogo.deleteConfirm')}</div>
          <div className="confirm-footer-container upload-logo__buttons">
            <ButtonCustom
              text={l('common.cancel')}
              size="half-left"
              onClick={() => {
                setDeleteConfirm(false);
              }}
            />
            <ButtonCustom
              name="btn-confirm"
              text={l('common.delete')}
              variant="contained"
              size="half-right"
              onClick={() => {
                setDeleteConfirm(false);
                fnDeleteLogo();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

UploadLogo.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  logoUrl: PropTypes.string,
  uploadSuccess: PropTypes.bool,
  deleteSuccess: PropTypes.bool,
  savedUrl: PropTypes.string,
  fnDeleteLogo: PropTypes.func,
  fnUploadLogo: PropTypes.func,
  fnUpdateMerchantMe: PropTypes.func,
  fnShowUploadLogo: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.companyInformation.uploadLogo.loading,
  error: state.companyInformation.uploadLogo.error,
  errorInfo: state.companyInformation.uploadLogo.errorInfo,
  uploadSuccess: state.companyInformation.uploadLogo.uploadSuccess,
  deleteSuccess: state.companyInformation.uploadLogo.deleteSuccess,
  savedUrl: state.companyInformation.uploadLogo.savedUrl,
  logoUrl: state.dashboard.merchantMe?.logoUrl,
});

const mapDispatchToProps = dispatch => ({
  fnDeleteLogo: () => dispatch(deleteLogo()),
  fnUploadLogo: logo => dispatch(uploadNewLogo(logo)),
  fnCleanSuccess: () => dispatch(cleanSuccess()),
  fnUpdateMerchantMe: newValues => dispatch(updateMerchantMe(newValues)),
  fnShowUploadLogo: show => dispatch(showUploadLogo(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadLogo);
