import { ReactNode } from 'react';
import './styles.scss';
import AvatarOptions from '../avatar-options';

interface TopDashboardSectionProps {
  title: string;
  subtitle?: string;
  component?: ReactNode;
  componentBottom?: ReactNode;
}

const TopDashboardSection = ({ title, subtitle, component, componentBottom }: TopDashboardSectionProps) => {
  return (
    <section style={{ width: '100%' }}>
      <div className="top-dashboard-section">
        <div className="top-dashboard-section__header">
          <div className="top-dashboard-section__header__title">{title}</div>
          <div className="top-dashboard-section__header__desc">{subtitle}</div>
        </div>
        {!!component && <div className="top-dashboard-section__component">{component}</div>}
        <div className="top-dashboard-section__avatar">
          <AvatarOptions />
        </div>
      </div>
      {!!componentBottom && <div className="top-dashboard-section__component-bottom">{componentBottom}</div>}
    </section>
  );
};

export default TopDashboardSection;
