import './styles.scss';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import { useTransactions } from '../../../../store/transactions';
import DialogExportTransactions from './dialog';
import l from '../../../../lang';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/file-download.svg';
import { segmentTransactionsDownloadReport } from '../../../../utils/analytics';

const ExportTransactions = () => {
  const { showExportTransactions } = useTransactions();
  return (
    <>
      <DialogExportTransactions />
      <div className="export_transactions__top_button">
        <ButtonCustom
          text={<span className="export_transactions__top_button__text">{l('exportTransactions.title')}</span>}
          onClick={() => {
            segmentTransactionsDownloadReport();
            showExportTransactions(true);
          }}
          variant="outlined"
          startIcon={<DownloadIcon className="export_transactions__top_button__icon" />}
          fullWidth
        />
      </div>
    </>
  );
};

export default ExportTransactions;
