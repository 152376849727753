import l from '../../../lang';
import { bankAccountNumberLabels, bankBranchLabels } from './add-bank-account-forms/bank-account-config';

export const getBankAccountLabel = (country: string) => {
  if (bankAccountNumberLabels[country]) {
    return bankAccountNumberLabels[country];
  }
  return l('beneficiary.bankAccount');
};

export const getBankAccountConfirmLabel = (country: string) => {
  if (bankAccountNumberLabels[country]) {
    return l('beneficiary.confirmAccount', bankAccountNumberLabels[country]);
  }
  return l('beneficiary.confirmBankAccount');
};

export const getBankBranchLabel = (country: string) => {
  if (bankBranchLabels[country]) {
    return bankBranchLabels[country];
  }
  return l('beneficiary.branch');
};
