import { useMemo } from 'react';
import useQuery from '../use-query';

export default function useMerchantReferenceParams() {
  const params = useQuery();
  return useMemo(() => {
    if (params.get('data')) {
      try {
        const merchantReference = JSON.parse(atob(params.get('data')));
        return merchantReference;
      } catch (e) {
        console.log('Error parsing extra data', e.message);
      }
    }
    return undefined;
  });
}
