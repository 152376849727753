import { FunctionComponent } from 'react';
import Parser from 'html-react-parser';
import l from '../../../../lang';
import { DialogConfirm, CardConfirm } from '../../../common/confirm';

interface BankAccountConfirmDeleteProps {
  onClose: (confirm: boolean) => void;
}

const BankAccountConfirmDelete: FunctionComponent<BankAccountConfirmDeleteProps> = ({ onClose }) => (
  <DialogConfirm onClose={onClose}>
    <CardConfirm
      onClose={onClose}
      headline={Parser(l('bankAccounts.delete.confirm.headline'))}
      description={Parser(l('bankAccounts.delete.confirm.subheadline'))}
      cancelChildren={l('bankAccounts.delete.confirm.cancel.button')}
      confirmChildren={l('bankAccounts.delete.confirm.confirmed.button')}
      level={'warning'}
    />
  </DialogConfirm>
);

export default BankAccountConfirmDelete;
