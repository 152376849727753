import l from '../../../../../../../lang';
import type { ChartOptions } from 'chart.js';
import { TransactionsByPaymentSolutionData } from '../../../../../../../store/reports/interfaces.d';

export const chartColors = ['#006CFA', '#008FFF', '#00A0FF', '#B8DDFF'];

export const getOptions = (data: TransactionsByPaymentSolutionData[]): ChartOptions<'bar'> => ({
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      borderColor: '#ececec',
      borderWidth: 1,
      backgroundColor: '#fff',
      titleColor: '#454f5b',
      titleFont: {
        weight: '700',
        size: 12,
      },
      bodyColor: '#454f5b',
      padding: 15,
      usePointStyle: true,
      callbacks: {
        title: context => {
          return `${context[0].label.replace(/,/g, ' ')} | ${context[0].parsed.y}%`;
        },
        label: context => {
          const number = data[context.dataIndex].count;
          return `${number} ${l(number == 1 ? 'reports.transactionCount' : 'reports.transactionsCount')}`;
        },
      },
    },
  },
  maintainAspectRatio: false,
  scales: {
    y: {
      min: 0,
      max: 100,
      stacked: true,
      ticks: {
        stepSize: 20,
        callback: value => {
          return `${value}%`;
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
});
