import l from '../../../../lang';
import Message from '../../../common/message';
import { MessageTypes } from '../../../../constants';
import ButtonLink from '../../../common/button-link';
import { useDashboard } from '../../../../store/dashboard';
import '../styles.scss';

const BlockedMessage = () => {
  const { merchantMe, userMe } = useDashboard();
  const { canOperate } = merchantMe || {};
  const { blacklisted, enabled } = userMe || {};

  return (
    (!canOperate && (blacklisted || !enabled) && (
      <div className="alert_message">
        <Message
          type={MessageTypes.WARNING}
          title={l('common.blockedReview.headline')}
          body={
            <span>
              <span>{l('common.blockedReview.subheadline')} </span>
              <ButtonLink text={l('common.blockedReview.subheadline.link')} onClick={() => window.Intercom('show')} />
            </span>
          }
        />
      </div>
    )) ||
    null
  );
};

export default BlockedMessage;
