import Parser from 'html-react-parser';
import l from '../../../../lang';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import { ReactComponent as ShopifyIcon } from '../../../../assets/icons/shopify.svg';
import IntegrationDetails from '../integration-details';
import { Button } from '../../../common/button';
import { segmentIntegrationsShopifyInstall, segmentIntegrationsShopifyPageLinkDocs } from '../../../../utils/analytics';
import { openNewTabAndFocus } from '../../../../utils';
import { Urls } from '../../../../config/urls';
import { SwitchWithLabel } from '../../../common/switch-with-label';
import { useTestModeHandler } from './useTestModeHandler';
import ButtonLink from '../../../common/button-link';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();
  const { testMode, onTestModeHandler } = useTestModeHandler();

  return (
    <IntegrationDetails icon={<ShopifyIcon />} color="green" onClose={() => setDetailsOpened(DetailsOpened.NONE)}>
      <div className="integration_details__title">
        {l('paymentIntegration.shopify.details.transparent.installed.headline')}
      </div>
      <div>{Parser(l('paymentIntegration.shopify.details.transparent.installed.description'))}</div>
      <div className="integration_details__switch">
        <SwitchWithLabel
          onChange={onTestModeHandler}
          label={l('paymentIntegration.shopify.environment.testing')}
          checked={testMode}
          disabled={false}
        />
      </div>
      <p>
        <span>{Parser(l('paymentIntegration.shopify.details.transparent.installed.description2'))} </span>
        <ButtonLink
          text={l('paymentIntegration.shopify.details.transparent.installed.link')}
          onClick={() => {
            segmentIntegrationsShopifyPageLinkDocs();
            openNewTabAndFocus(Urls.docs.shopifyTransparentIntegration);
          }}
        />
      </p>
      <div>{Parser(l('paymentIntegration.shopify.details.transparent.installed.description3'))}</div>
    </IntegrationDetails>
  );
};
