import { PanelStore } from '../interfaces';

export namespace PaymentLinkSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.paymentLink.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.paymentLink.error;
  };
  export const getErrorInfo = (state: PanelStore.RootState) => {
    return state.paymentLink.errorInfo;
  };
  export const getErrorFields = (state: PanelStore.RootState) => {
    return state.paymentLink.errorFields;
  };
  export const getCreateSuccess = (state: PanelStore.RootState) => {
    return state.paymentLink.createSuccess;
  };
  export const getShowResult = (state: PanelStore.RootState) => {
    return state.paymentLink.showResult;
  };
  export const getCurrencies = (state: PanelStore.RootState) => {
    return state.paymentLink.currencies;
  };
  export const getExchangeRates = (state: PanelStore.RootState) => {
    return state.paymentLink.exchangeRates;
  };
  export const getDocumentTypes = (state: PanelStore.RootState) => {
    return state.paymentLink.documentTypes;
  };
  export const getCreatedOneTimePaymentLinks = (state: PanelStore.RootState) => {
    return state.paymentLink.createdOneTime;
  };
  export const getCreatedRecurringPaymentLinks = (state: PanelStore.RootState) => {
    return state.paymentLink.createdRecurring;
  };
  export const getExistingClients = (state: PanelStore.RootState) => {
    return state.paymentLink.existingClients;
  };
  export const getTabSelected = (state: PanelStore.RootState) => {
    return state.paymentLink.tabSelected;
  };
}
