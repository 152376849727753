import { PanelStore } from '../interfaces';

export namespace TransactionsSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.transactions.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.transactions.error;
  };
  export const getAllTransactions = (state: PanelStore.RootState) => {
    return state.transactions.allTransactions;
  };
  export const getTransactionDetail = (state: PanelStore.RootState) => {
    return state.transactions.transactionDetail;
  };
  export const getExport = (state: PanelStore.RootState) => {
    return state.transactions.export;
  };
  export const getExportType = (state: PanelStore.RootState) => {
    return state.transactions.exportType;
  };
  export const getPaymentMethods = (state: PanelStore.RootState) => {
    return state.transactions.paymentMethods;
  };
}
