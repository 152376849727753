import { Children, FC, PropsWithChildren, ReactNode, cloneElement, createContext, useContext } from 'react';
import { useDashboard } from '../../store/dashboard';
import { MerchantFeatures } from '../../constants';

export type HasFeatureFunction = (feature: MerchantFeatures) => boolean;

export const FeatureContext = createContext<string[]>([]);

export const FeaturesProvider: FC<{ children: ReactNode }> = props => {
  const { merchantMe } = useDashboard();
  return <FeatureContext.Provider value={merchantMe?.features || []}>{props.children}</FeatureContext.Provider>;
};

export const FeatureToggle: FC<PropsWithChildren<{ feature: string }>> = props => {
  const { children, feature } = props;
  const features = useContext(FeatureContext);
  const toggledChildren = Children.map(children, child =>
    cloneElement(child as any, {
      featureToggleEnabled: features.includes(feature),
    })
  );
  return <>{toggledChildren}</>;
};

export const On: FC<PropsWithChildren<{ featureToggleEnabled?: boolean }>> = ({ children, featureToggleEnabled }) => (
  <>{featureToggleEnabled && children}</>
);

export const Off: FC<PropsWithChildren<{ featureToggleEnabled?: boolean }>> = ({ children, featureToggleEnabled }) => (
  <>{!featureToggleEnabled && children}</>
);

export function useFeature() {
  const features = useContext(FeatureContext);

  const hasFeature: HasFeatureFunction = (feature: MerchantFeatures): boolean => {
    return features.includes(feature);
  };

  return {
    hasFeature,
  };
}
