import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { ItemOnboardingMenu } from '../../../../onboarding-menu';
import { ValuesLevelOne } from '..';
import l from '../../../../../../lang';
import { useDashboard } from '../../../../../../store/dashboard';
import { useJumioIntegration } from '../../../../../../store/jumio';
import { JumioStatusType } from '../../../../../../store/jumio/interfaces';

interface FormObserverProps {
  onChangeOnboardingMenu: (menu: ItemOnboardingMenu[]) => void;
}

export const FormObserver = ({ onChangeOnboardingMenu }: FormObserverProps) => {
  const { companyWasFulfilled } = useDashboard();
  const { status: JumioStatus } = useJumioIntegration();
  const { values } = useFormikContext<ValuesLevelOne>();
  useEffect(() => {
    const {
      company,
      name,
      lastName,
      fantasyName,
      country,
      documentType,
      document,
      website,
      phone,
      subIndustryId,
      functionalCurrency,
    } = values;

    const isJumioSelected = companyWasFulfilled;

    const isCompanyCompleted = companyWasFulfilled || values.company !== null;
    const isCompanySelected = !isCompanyCompleted;
    const companyDataCompleted = companyWasFulfilled || (!!fantasyName && !!subIndustryId && !!website);
    const companyDataSelected = isCompanyCompleted && !companyDataCompleted;
    const legalInfoCompleted =
      companyWasFulfilled || (!!name && !!country && !!documentType && !!document && !!functionalCurrency && (!company ? !!lastName : true));
    const legalInfoSelected = companyDataCompleted && !legalInfoCompleted;
    const contactInfoCompleted = companyWasFulfilled || !!phone;
    const contactInfoSelected = legalInfoCompleted && !contactInfoCompleted;

    onChangeOnboardingMenu([
      { title: l('companyInformation.kycOneMenu.introduction'), completed: true, selected: false },
      { title: l('companyInformation.kycOneMenu.typeOfCompany'), completed: isCompanyCompleted, selected: isCompanySelected },
      {
        title: l('companyInformation.kycOneMenu.companyData'),
        subItems: [
          {
            title: l('companyInformation.basicInfo.commercialInformation'),
            completed: companyDataCompleted,
            selected: companyDataSelected,
          },
          {
            title: l('companyInformation.basicInfo.legalInformation'),
            completed: legalInfoCompleted,
            selected: legalInfoSelected,
          },
          {
            title: l('companyInformation.basicInfo.contactInformation'),
            completed: contactInfoCompleted,
            selected: contactInfoSelected,
          },
        ],
      },
      { title: l('companyInformation.kycOneMenu.jumio'), completed: false, selected: isJumioSelected },
    ]);
  }, [values]);
  return null;
};
