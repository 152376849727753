import { useHistory } from 'react-router-dom';
import Parser from 'html-react-parser';
import l from '../../../../lang';
import { Routes } from '../../../../config/routes';
import ButtonLink from '../../../common/button-link';
import CardTitle from '../../../common/card-title';
import Card from '../../../common/card';
import CardInfo from '../../../common/card-info';
import { segmentHomeKycStep2Complete, segmentHomeQuickLinkEditCountries } from '../../../../utils/analytics';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Button } from '../../../common/button';
import { Tooltip } from 'react-tooltip';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info-unfilled.svg';

interface FirstTimeCardsProps {
  shouldAnimationsRun: boolean;
  maxAmount: number;
}

const FirstTimeCards = ({ shouldAnimationsRun, maxAmount }: FirstTimeCardsProps) => {
  const history = useHistory();

  const onPaymentMethodsHandler = () => {
    segmentHomeQuickLinkEditCountries();
    history.push(Routes.PAYMENT_METHODS);
  };

  const onCompanyInformationHandler = () => {
    segmentHomeKycStep2Complete();
    history.push(`${Routes.COMPANY_INFORMATION}?kyc=2`);
  };

  return (
    <Card>
      <CardTitle>
        <span style={{ fontSize: '22px' }}>{Parser(l('home.readyMsg.firstTime'))}</span>
      </CardTitle>
      <div className={`two-cards ${shouldAnimationsRun ? 'card__animated' : ''}`}>
        <CardInfo bottomElement={<div className="card__accentuate-line" />}>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress variant="determinate" value={75} size={100} />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span className="circular-progress__text">75%</span>
              </Box>
            </Box>
            <div className="card__desc">{Parser(l('home.complete-profile.kyc2.desc'))}</div>
          </Box>
        </CardInfo>

        <CardInfo bottomElement={<div className="card__accentuate-line" />}>
          <div className="card__desc step2__desc">
            <div className="step2__desc_text">
              <div>
                <ButtonLink text={l('home.readyMsg.paso2.link')} onClick={onCompanyInformationHandler} />
                {Parser(l('home.readyMsg.paso2.desc'))}
              </div>
              <a id="clickable">
                <InfoIcon />
              </a>
              <Tooltip anchorSelect="#clickable" clickable place="right">
                <div className="benefits__desc">
                  <span>{l('home.readyMsg.step2.tooltip.title')}</span>
                  <ul>
                    <li>{l('home.readyMsg.step2.tooltip.item1')}</li>
                    <li>{l('home.readyMsg.step2.tooltip.item2')}</li>
                  </ul>
                  <span>
                    {l('home.readyMsg.step2.tooltip.desc1')}
                    <ButtonLink text={l('home.readyMsg.step2.tooltip.disable')} onClick={onPaymentMethodsHandler} />
                    {l('home.readyMsg.step2.tooltip.desc2')}
                  </span>
                </div>
              </Tooltip>
            </div>
            <Button onClick={onCompanyInformationHandler}>{l('home.readyMsg.step2.complete-now')}</Button>
          </div>
        </CardInfo>
      </div>
    </Card>
  );
};

export default FirstTimeCards;
