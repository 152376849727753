import React, { useState } from 'react';
import './styles.scss';
import LazyLoad from 'react-lazyload';
import { Check } from '@mui/icons-material';
import { Urls } from '../../../../config/urls';
import l from '../../../../lang';

const CountryList = ({ countriesInRegion = [], allSelectedCountries = [], onChange }) => {
  const [otherRegions] = useState(allSelectedCountries.filter(co => !countriesInRegion.includes(co)));
  const [selectedCountries, setSelectedCountries] = useState(
    countriesInRegion.filter(cwpm => allSelectedCountries.includes(cwpm))
  );

  const handleChange = newSelection => {
    setSelectedCountries(newSelection);
    onChange([...otherRegions, ...newSelection]);
  };

  return (
    <div className="country-list">
      <div className="country-list__box">
        <div
          className="country-list__country country-list__country--selected country-list__country--left"
          onClick={() => {
            if (countriesInRegion.length) {
              if (countriesInRegion.length === selectedCountries.length) {
                handleChange([]);
              } else {
                handleChange(countriesInRegion);
              }
            }
          }}
        >
          <div className="country-list__all-countries" />
          <div className="country-list__name">{l('paymentMethod.coverage.allCountries')}</div>
          {(!countriesInRegion.length || countriesInRegion.length === selectedCountries.length) && (
            <div className="country-list__check">
              <Check />
            </div>
          )}
        </div>
      </div>
      <div className="country-list__box" />
      {countriesInRegion.map((code, idx) => {
        const selected = selectedCountries.includes(code);
        return (
          <div className="country-list__box" key={code}>
            <div
              className={`country-list__country ${selected ? 'country-list__country--selected' : ''} ${
                idx % 2 === 0 ? 'country-list__country--left' : 'country-list__country--right'
              }`}
              onClick={() => {
                if (selected) {
                  handleChange(selectedCountries.filter(co => co !== code));
                } else {
                  handleChange([...selectedCountries, code]);
                }
              }}
            >
              <LazyLoad height={32} overflow>
                <img alt={`img-${code}`} className="country-list__flag" src={`${Urls.flags.replace(':code', code)}`} />
              </LazyLoad>
              <div className="country-list__name">{l(`countryCode.${code}`)}</div>
              {selected && (
                <div className="country-list__check">
                  <Check />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CountryList;
