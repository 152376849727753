import { PanelStore } from '../interfaces';

export namespace BankAccountsSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.bankAccounts.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.bankAccounts.error;
  };
  export const getErrorInfo = (state: PanelStore.RootState) => {
    return state.bankAccounts.errorInfo;
  };
  export const getAllBankAccounts = (state: PanelStore.RootState) => {
    return state.bankAccounts.allBankAccounts;
  };
  export const getNewBankAccount = (state: PanelStore.RootState) => {
    return state.bankAccounts.newBankAccount;
  };
  export const getDeletedBankAccount = (state: PanelStore.RootState) => {
    return state.bankAccounts.deletedBankAccount;
  };
}
