import { Link, Redirect, useHistory } from 'react-router-dom';
import { useSubscriptions } from '../../../../../store/subscriptions';
import './styles.scss';
import { Routes } from '../../../../../config/routes';
import TopDashboardSection from '../../../../common/top-dashboard-section';
import ButtonLink from '../../../../common/button-link';
import Card from '../../../../common/card';
import l from '../../../../../lang';
import { ReactComponent as BackIcon } from '../../../../../assets/icons/back.svg';
import { Button } from '../../../../common/button';
import { Close } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { createDate } from '../../../../../utils';
import { ReactComponent as AllCountriesIcon } from '../../../../../assets/icons/all-countries.svg';
import { Urls } from '../../../../../config/urls';
import { ReactComponent as UserIcon } from '../../../../../assets/icons/user-circle.svg';
import { ReactComponent as CardIcon } from '../../../../../assets/icons/card.svg';
import { useMediaQuery } from '@mui/material';
import Table from '../../../../common/table';
import ChipStatus from '../../../../common/chip-status';
import DialogSubscriptionCancel from '../../dialog-cancel';
import { ReactComponent as CalendarIcon } from '../../../../../assets/icons/calendar.svg';

const SubscriptionDetails = () => {
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const history = useHistory();
  const { details: data, deactivateSubscription, setSubscriptionDetails, setDeactivateSubscription } = useSubscriptions();
  const [showCancel, setShowCancel] = useState(false);

  useEffect(() => {
    if (!data) backToSubscriptions();
  }, []);

  const backToSubscriptions = () => {
    setSubscriptionDetails(null);
    history.push(Routes.SUBSCRIPTIONS);
  };

  const cancelButton = (fullWidth: boolean) => (
    <div className="subscription_details__header__cancel">
      <Button
        startIcon={<Close />}
        variant="outlined"
        textTransformNone
        color="error"
        fullWidth={fullWidth}
        onClick={() => setShowCancel(true)}
        disabled={deactivateSubscription.loading}
      >
        {l('subscriptions.details.cancel')}
      </Button>
    </div>
  );

  return (
    <>
      <TopDashboardSection
        title={l('subscriptions.details')}
        componentBottom={
          <div className="subscription_details__back_btn">
            <ButtonLink
              type="secondary"
              text={
                <>
                  <BackIcon /> {l('subscriptions.details.backBtn')}
                </>
              }
              onClick={backToSubscriptions}
            />
          </div>
        }
      />
      <div className="subscription_details">
        <Card>
          <div className="subscription_details__card_section">
            <div className="subscription_details__header">
              <div className="subscription_details__header__plan">
                <div className="subscription_details__header__plan__title">
                  {data?.plan.name}{' '}
                  {data?.active ? (
                    <span className="subscription_details__header__active">{l('subscriptions.active')}</span>
                  ) : (
                    <span className="subscription_details__header__inactive">{l('subscriptions.inactive')}</span>
                  )}
                </div>
                <div className="subscription_details__header__plan__desc">{data?.plan.description}</div>
              </div>

              {isDesktop && data?.active && cancelButton(false)}
            </div>
            <div className="subscription_details__divider" />
            <div className="subscription_details__box_data">
              <div className="subscription_details__box_data__title">{l('subscriptions.subscriptionDate')}</div>
              <div className="subscription_details__box_data__value">
                {data?.createdAt ? (
                  <>
                    <Moment format="DD/MM/YYYY">{createDate(data?.createdAt)}</Moment>
                    <span>,&nbsp;</span>
                    <Moment format="hh:mm:ss a">{createDate(data?.createdAt)}</Moment>
                  </>
                ) : (
                  '-'
                )}
              </div>
            </div>
            <div className="subscription_details__box_data subscription_details__box_data__border_left">
              <div className="subscription_details__box_data__title">{l('subscriptions.paymentFrequency')}</div>
              <div className="subscription_details__box_data__value">
                {data?.plan.frequencyValue ? (
                  <>
                    {data?.plan.frequencyValue}&nbsp;
                    {l(
                      `subscriptions.frequency.${data?.plan.frequencyType}${
                        (data?.plan.frequencyValue || 0) > 1 ? '.plural' : ''
                      }`
                    )}
                  </>
                ) : (
                  '-'
                )}
              </div>
            </div>
            <div className="subscription_details__box_data subscription_details__box_data__border_left">
              <div className="subscription_details__box_data__title">{l('subscriptions.subscribersCountry')}</div>
              <div className="subscription_details__box_data__value">
                {data?.country ? (
                  <span className="subscription_details__country">
                    <img
                      alt=""
                      className="subscription_details__country_flag"
                      src={`${Urls.flags.replace(':code', data.country)}`}
                    />
                    <span>&nbsp;{data.country}</span>
                  </span>
                ) : (
                  <span className="subscription_details__country">
                    <AllCountriesIcon height={18} width={18} />
                    <span>&nbsp;{l('subscriptions.allCountries')}</span>
                  </span>
                )}
              </div>
            </div>
            <div className="subscription_details__box_data subscription_details__box_data__border_left">
              <div className="subscription_details__box_data__title">{l('subscriptions.paymentMethod')}</div>
              <div className="subscription_details__box_data__value">{data?.paymentMethodName || '-'}</div>
            </div>
            {!isDesktop && data?.active && cancelButton(true)}
          </div>
          <div className="subscription_details__card_section">
            <div>
              <div className="subscription_details__title_section">
                <UserIcon className="subscription_details__title_section__icon" />
                {l('subscriptions.clientInformation')}
              </div>
              <div>
                <div className="subscription_details__column_left">
                  <div className="subscription_details__table_detail">
                    <div className="subscription_details__table_detail__row">
                      <div className="subscription_details__table_detail__row__label">{l('subscriptions.clientName')}</div>
                      <div className="subscription_details__table_detail__row__value">
                        {data?.clientFirstName} {data?.clientLastName}
                      </div>
                    </div>
                    <div className="subscription_details__table_detail__row">
                      <div className="subscription_details__table_detail__row__label">
                        {l('subscriptions.clientDocument')}
                      </div>
                      <div className="subscription_details__table_detail__row__value">
                        {data?.clientDocumentType} {data?.clientDocument}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="subscription_details__column_right">
                  <div className="subscription_details__table_detail">
                    <div className="subscription_details__table_detail__row">
                      <div className="subscription_details__table_detail__row__label">{l('subscriptions.clientEmail')}</div>
                      <div className="subscription_details__table_detail__row__value">{data?.clientEmail || '-'}</div>
                    </div>
                    <div className="subscription_details__table_detail__row">
                      <div className="subscription_details__table_detail__row__label">{l('subscriptions.clientId')}</div>
                      <div className="subscription_details__table_detail__row__value">{data?.clientId || '-'}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="subscription_details__card_section">
            <div>
              <div className="subscription_details__title_section">
                <CardIcon className="transaction_detail__title_section__icon" />
                {l('subscriptions.paymentDetails')}
                {data?.active && data?.scheduledDate && (
                  <div className="subscription_details__title_section__right">
                    {isDesktop && <CalendarIcon />}
                    <div className="subscription_details__title_section__right__text">
                      <span>{l('subscriptions.nextPaymentDate')}: </span>
                      <Moment format="DD/MM/YYYY">{createDate(data?.scheduledDate)}</Moment>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <Table
                  dense
                  emptyBody={<div></div>}
                  headers={[
                    <span className="subscription_details__executions__left">{l('subscriptions.executions.date')}</span>,
                    <span className="subscription_details__executions__left">
                      {l('subscriptions.executions.transactionID')}
                    </span>,
                    <span className="subscription_details__executions__left">
                      {l('subscriptions.executions.amountPaid')}
                    </span>,
                    <span className="subscription_details__executions__right">
                      {l('subscriptions.executions.amountReceived')}
                    </span>,
                    <span className="subscription_details__executions__left">
                      {l('subscriptions.executions.paymentStatus')}
                    </span>,
                  ]}
                  body={
                    data?.subscriptionExecutions.map(ex => {
                      return [
                        <span className="subscription_details__executions__left">
                          <Moment format="DD/MM/YYYY">{createDate(ex.createdAt)}</Moment>
                        </span>,
                        <div className="subscription_details__executions__left subscription_details__executions__one_line link">
                          <Link to={`/transactions/${ex.orderId}/detail`}>{ex.orderId}</Link>
                        </div>,
                        <span className="subscription_details__executions__right">
                          {ex.checkoutCurrency} {ex.amountPaid}
                        </span>,
                        <span className="subscription_details__executions__right">
                          {ex.balanceCurrency} {ex.amountReceived}
                        </span>,
                        <div>
                          <ChipStatus status={ex.status} text={l(`subscriptions.executionStatus.${ex.status}`)} />
                        </div>,
                      ];
                    }) || null
                  }
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
      {showCancel && (
        <DialogSubscriptionCancel
          cancelType="SUBSCRIPTION"
          onClose={confirm => {
            setShowCancel(false);
            if (confirm && data?.id) setDeactivateSubscription(data.id);
          }}
        />
      )}
      {deactivateSubscription?.deactivated && <Redirect to={Routes.SUBSCRIPTIONS} />}
    </>
  );
};

export default SubscriptionDetails;
