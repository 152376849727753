import { HomeActionType } from './actions';
import { HomeState } from './interfaces';

const initialState: HomeState = {
  loading: false,
  error: false,
  shouldAnimationsRun: true,
  balances: {
    loading: false,
    error: false,
    data: undefined,
  },
};

const HomeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case HomeActionType.GET_BALANCES: {
      return {
        ...state,
        balances: {
          ...state.balances,
          loading: true,
          error: false,
        },
      };
    }
    case HomeActionType.GET_BALANCES_SUCCESS: {
      return {
        ...state,
        balances: {
          ...state.balances,
          loading: false,
          error: false,
          data: {
            ...action.response,
          },
        },
      };
    }
    case HomeActionType.GET_BALANCES_ERROR: {
      return {
        ...state,
        balances: {
          ...state.balances,
          loading: false,
          error: true,
          data: undefined,
        },
      };
    }
    case HomeActionType.PUT_SHOULD_ANIMATIONS_RUN: {
      return {
        ...state,
        shouldAnimationsRun: action.shouldRun,
      };
    }
    default:
      return state;
  }
};

export default HomeReducer;
