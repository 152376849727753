import { useDispatch, useSelector } from 'react-redux';
import { initCheckCompleted, initLogin, sendLogin, sendLogout } from './actions';
import { AuthSelectors } from './selectors';

export const useAuth = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(AuthSelectors.getLoading),
    error: useSelector(AuthSelectors.getError),
    errorInfo: useSelector(AuthSelectors.getErrorInfo),
    token: useSelector(AuthSelectors.getToken),
    tokenExpiration: useSelector(AuthSelectors.getTokenExpiration),
    isAuthenticated: useSelector(AuthSelectors.getIsAuthenticated),
    sessionStatus: useSelector(AuthSelectors.getSessionStatus),
    logout: useSelector(AuthSelectors.getLogout),
    checkSessionCompleted: useSelector(AuthSelectors.getCheckSessionCompleted),
    afterLoginSuccess: useSelector(AuthSelectors.getAfterLoginSuccess),
    initLogin: () => dispatch(initLogin()),
    sendLogin: (email: string, password: string, merchantReference: string) =>
      dispatch(sendLogin(email, password, merchantReference)),
    sendLogout: () => dispatch(sendLogout()),
  };
};
