export const httpStatus = (response, responseText = false) => {
  if (response.status >= 200 && response.status < 300) {
    return response.text().then(text => (responseText ? { text: `${text}` } : text.length ? JSON.parse(text) : {}));
  }
  return response.text().then(text => {
    const error = {
      status: response.status,
      body: text.length ? JSON.parse(text) : {},
    };
    return Promise.reject(error);
  });
};
