import { useDispatch, useSelector } from 'react-redux';
import { Values } from '../interfaces';
import {
  cleanErrorPaymentLink,
  getCreatedOneTimePaymentLinks,
  getCreatedRecurringPaymentLinks,
  changeStatusRecurringPaymentLink,
  getCurrencies,
  cleanCurrencies,
  getDocumentTypesForPaymentLink,
  getExistingClients,
  initPaymentLink,
  paymentLinkResult,
  postCreatePaymentLink,
  changeTabSelected,
} from './actions';
import { PaymentLinkSelectors } from './selectors';
import { CreatedPaymentLinkType } from '../../constants';

export const usePaymentLink = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(PaymentLinkSelectors.getLoading),
    error: useSelector(PaymentLinkSelectors.getError),
    errorInfo: useSelector(PaymentLinkSelectors.getErrorInfo),
    errorFields: useSelector(PaymentLinkSelectors.getErrorFields),
    createSuccess: useSelector(PaymentLinkSelectors.getCreateSuccess),
    showResult: useSelector(PaymentLinkSelectors.getShowResult),
    currencies: useSelector(PaymentLinkSelectors.getCurrencies),
    exchangeRates: useSelector(PaymentLinkSelectors.getExchangeRates),
    documentTypes: useSelector(PaymentLinkSelectors.getDocumentTypes),
    createdOneTimePaymentLinks: useSelector(PaymentLinkSelectors.getCreatedOneTimePaymentLinks),
    createdRecurringPaymentLinks: useSelector(PaymentLinkSelectors.getCreatedRecurringPaymentLinks),
    existingClients: useSelector(PaymentLinkSelectors.getExistingClients),
    tabSelected: useSelector(PaymentLinkSelectors.getTabSelected),
    initPaymentLink: () => dispatch(initPaymentLink()),
    cleanErrorPaymentLink: () => dispatch(cleanErrorPaymentLink()),
    getCurrencies: (country: string) => dispatch(getCurrencies(country)),
    cleanCurrencies: () => dispatch(cleanCurrencies()),
    getDocumentTypesForPaymentLink: (country: string) => dispatch(getDocumentTypesForPaymentLink(country)),
    paymentLinkResult: (show: boolean) => dispatch(paymentLinkResult(show)),
    postCreatePaymentLink: (values: Values, isRecurring: boolean) => dispatch(postCreatePaymentLink(values, isRecurring)),
    getExistingClients: (page: number, country: string) => dispatch(getExistingClients(page, country)),
    getCreatedOneTimePaymentLinks: (page?: number, rowsPerPage?: number) =>
      dispatch(getCreatedOneTimePaymentLinks(page, rowsPerPage)),
    getCreatedRecurringPaymentLinks: (page?: number, rowsPerPage?: number) =>
      dispatch(getCreatedRecurringPaymentLinks(page, rowsPerPage)),
    changeStatusRecurringPaymentLink: (token: string, enable: boolean) =>
      dispatch(changeStatusRecurringPaymentLink(token, enable)),
    changeTabSelected: (tabSelected: CreatedPaymentLinkType) => dispatch(changeTabSelected(tabSelected)),
  };
};
