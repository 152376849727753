import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import _ from 'lodash';
import Parser from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Bugsnag from '@bugsnag/js';
import l from '../../../../lang';
import './styles.scss';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right-completed.svg';
import Input from '../../../common/input';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import Select from '../../../common/select';
import MessageError from '../../../common/message-error';
import { getCurrencies, getDocumentTypes, createPayment, confirmPayment } from '../../../../store/virtual-pos/actions';
import Message from '../../../common/message';
import TooltipIcon from '../../../common/tooltip-icon';
import { MessageTypes } from '../../../../constants';
import { allCountries, countriesWithoutCC, functionalCurrenciesByCountry } from '../../../../config';
import { scrollIntoView } from '../../../../utils';
import ButtonLink from '../../../common/button-link';
import { Routes } from '../../../../config/routes';
import InputData from '../../../common/input-data';
import SmartField from '../smart-fields';
import Loading from '../../../common/loading';
import { useDashboard } from '../../../../store/dashboard';
import CurrencySelector from '../../../common/currency-selector';
import AlertMessages from '../../alert-messages';

const CreatePaymentForm = ({
  loading,
  error,
  errorInfo,
  errorFields,
  currencies,
  exchangeRates,
  canOperate,
  clientData,
  paymentMethods,
  fraudReview,
  creditCardEnabled,
  maxTransactionAmountCc,
  fnGetCurrencies,
  fnGetDocumentTypes,
  fnCreatePayment,
  fnConfirmPayment,
}) => {
  const history = useHistory();
  const { userMe, merchantMe } = useDashboard();

  const [clientCurrency, setClientCurrency] = useState('');
  const [isCrossBorder, setIsCrossBorder] = useState(false);
  const [crossBorderSameCurrency, setCrossBorderSameCurrency] = useState(false);

  const [processing, setProcessing] = useState(false);

  const [keyContainer, setKeyContainer] = useState('');
  const [dlocalInstance, setDlocalInstance] = useState();
  const [cardInstance, setCardInstance] = useState();
  const [instalmentSelected, setInstalmentSelected] = useState('');
  const [cardError, setCardError] = useState('');
  const [brandDetected, setBrandDetected] = useState('');

  const errorRef = useRef(null);
  const countryRef = useRef(null);
  const currencyRef = useRef(null);
  const amountRef = useRef(null);
  const smartFieldRef = useRef(null);

  const warningCardsRef = useRef(false);
  const warningCardsContainerRef = useRef(false);

  const crossBorderCurrency = 'USD';

  const scrollToErrorFields = () => {
    if (errorFields.clientEmail) {
      scrollIntoView(clientEmailRef);
    } else if (errorFields.clientMobilePhone) {
      scrollIntoView(clientMobilePhoneRef);
    }
  };

  const scrollToFormErrors = errors => {
    if (errors.country) {
      scrollIntoView(countryRef);
    } else if (errors.currency) {
      scrollIntoView(currencyRef);
    } else if (errors.amount) {
      scrollIntoView(amountRef);
    }
  };

  useEffect(() => {
    if (errorFields) {
      setTimeout(() => scrollToErrorFields(), 250);
    }
    // eslint-disable-next-line
  }, [errorFields]);

  useEffect(() => {
    if (error) {
      scrollIntoView(errorRef);
    }
  }, [error]);

  const initialValues = {
    country: '',
    currency: '',
    amount: 0,

    clientFirstName: '',
    clientLastName: '',
    clientAddress: '',
    clientCity: '',
    clientBirthDate: '',
    clientMobilePhone: '',
    clientZipcode: '',
    clientEmail: '',
    clientDocumentType: '',
    clientDocument: '',
    clientState: '',
    crossBorderData: {
      secondCurrency: '',
      secondAmount: 0,
    },
  };

  const validate = values => {
    const errors = {};
    if (!values.country) {
      errors.country = l('form.input.required');
    }
    if (!values.currency) {
      errors.currency = l('form.input.required');
    }
    if (values.amount <= 0) {
      errors.amount = l('form.input.required');
    }
    if (clientData) {
      clientData.forEach(input => {
        if (!values[input.name]) {
          errors[input.name] = l('form.input.required');
        } else if (errorFields && errorFields[input.name] === values[input.name]) {
          errors[input.name] = l('form.input.invalid');
        } else if (input.regex) {
          const regex = new RegExp(input.regex, 'g');
          if (!regex.test(values[input.name])) {
            errors[input.name] = l('form.input.invalid');
          }
        }
      });
    }

    return errors;
  };

  const countries = () => {
    const countries = {};

    const allowedCountries = merchantMe?.allowLocalToLocal
      ? allCountries
      : allCountries.filter(co => co !== merchantMe?.country);

    allowedCountries.forEach(co => {
      if (!countriesWithoutCC.includes(co)) countries[co] = l(`countryCode.${co}`);
    });
    return countries;
  };

  const applyToFixed = (value: number) => {
    return value && value !== 0 ? value.toFixed(2) : value;
  };

  const getPaymentMethodId = (paymentMethods, brand, type) => {
    const debitBrands = { VI: 'VD', MC: 'MD', CL: 'CD' };
    const debitBrand = type === 'DEBIT' && debitBrands[brand];

    let idDebitResult;
    let idCCResult;
    paymentMethods.forEach(pm => {
      if (pm.code === debitBrand) {
        idDebitResult = pm.id;
      }
      if (pm.code === brand) {
        idCCResult = pm.id;
      }
    });
    return idDebitResult || idCCResult;
  };

  const handleSubmit = values => {
    setProcessing(true);

    const valuesToSend = {};
    Object.keys(values).forEach(key => {
      if (typeof values[key] === 'string') {
        if (values[key].trim() !== '') {
          valuesToSend[key] = values[key];
        }
      } else {
        valuesToSend[key] = values[key];
      }
    });

    const cardHolderName = `${valuesToSend.clientFirstName} ${valuesToSend.clientLastName}`;
    dlocalInstance
      .createToken(cardInstance, {
        name: cardHolderName,
      })
      .then(async result => {
        const { brand, type } = await dlocalInstance.getBinInformation(cardInstance);
        const { id, installments, installmentAmount, totalAmount, installmentsByBank } = instalmentSelected;
        setProcessing(false);

        fnConfirmPayment(
          {
            ...valuesToSend,
            paymentToken: result.token,
            installmentsId: id,
            installments: installments || 1,
            totalInstallmentsAmount: installmentAmount,
            totalAmount,
            installmentsByBank,
          },
          getPaymentMethodId(paymentMethods, brand, type)
        );
      })
      .catch(result => {
        setProcessing(false);

        Bugsnag.notify(new Error(`Create dlocal token error: ${result?.error?.message || 'Without message.'}`));
        if (result.error) {
          setCardError(result.error.message);
        }
      });
  };

  let creationDelay;
  const handlePaymentCreation = (values, withDelay = false) => {
    clearTimeout(creationDelay);
    creationDelay = setTimeout(
      () => {
        const { country, currency, amount } = values;
        if (country && currency && amount) {
          const valuesToSend = {};
          Object.keys(values).forEach(key => {
            if (typeof values[key] === 'string') {
              if (values[key].trim() !== '') {
                valuesToSend[key] = values[key];
              }
            } else {
              valuesToSend[key] = values[key];
            }
          });
          if (valuesToSend.amount) {
            setKeyContainer(`${country}-${currency}-${valuesToSend.amount}`);
          }
          fnCreatePayment(valuesToSend);
        }
      },
      withDelay ? 1000 : 0
    );
  };

  const formDisabled = !canOperate || fraudReview || loading || processing;

  const showWarningCards = show => {
    if (show && warningCardsRef.current !== show) {
      scrollIntoView(warningCardsContainerRef);
    }
    warningCardsRef.current = show;
    if (show) {
      return (
        <div className="create-payment-form__message">
          <Message
            type={MessageTypes.WARNING}
            body={
              <span>
                <span>{l('paymentLink.exceedsMaxTransactionAmountCc', maxTransactionAmountCc)} </span>
                <ButtonLink
                  type="primary"
                  text={l('paymentLink.exceedsMaxTransactionAmountCc.here')}
                  onClick={() => history.push(Routes.COMPANY_INFORMATION)}
                />
              </span>
            }
          />
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className="create-payment-form">
      {processing && <Loading fullScreen />}
      <Formik initialValues={initialValues} validate={validate} onSubmit={values => handleSubmit(values)}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          useEffect(() => {
            if (values.country === merchantMe?.country) {
              setIsCrossBorder(false);
              setCrossBorderSameCurrency(false);
              setDefaultCurrency(values.country, false);
            } else if (values.country) {
              setIsCrossBorder(true);
              setCrossBorderSameCurrency(!Object.keys(currencies).find(c => c !== crossBorderCurrency));
              setDefaultCurrency(values.country, true);
            }
          }, [currencies]);

          useEffect(() => {
            if (!values.country && values.amount === 0) setIsCrossBorder(false);
          }, [values.amount]);

          useEffect(() => {
            if (values.country && values.currency) {
              !values.crossBorderData.secondCurrency &&
                setFieldValue('crossBorderData', {
                  ...values.crossBorderData,
                  secondCurrency: values.currency,
                });
            }
          }, [values.currency]);

          const setDefaultCurrency = (value: string, isCrossBorder: boolean) => {
            if (currencies) {
              if (!isCrossBorder) {
                const defaultCurrency = functionalCurrenciesByCountry[value];
                handleOnChangeCurrency(defaultCurrency);
              } else {
                if (currencies['USD']) {
                  handleOnChangeCurrency('USD');
                } else {
                  const cs = Object.keys(currencies);
                  if (cs.length && !cs.filter(d => value === d).length) {
                    handleOnChangeCurrency(cs[0]);
                  }
                }
              }
            }
          };

          const handleOnChangeCurrency = (value: string) => {
            setFieldValue('currency', value);
            // cleans
            setFieldValue('amount', '');
            if (isCrossBorder) {
              setFieldValue('crossBorderData', {
                ...values.crossBorderData,
                secondCurrency: Object.keys(currencies).find(c => c !== value) || '',
                secondAmount: '',
              });
            }
          };

          return (
            <form onSubmit={handleSubmit}>
              <div className="create-payment-form__container">
                <AlertMessages />
                <div ref={warningCardsContainerRef}>
                  {showWarningCards(
                    creditCardEnabled && values.currency === 'USD' && values.amount > maxTransactionAmountCc
                  )}
                </div>
                <div className="create-payment-form__subtitle">{l('virtualPos.subtitle')}</div>
                <div>
                  <div className="create-payment-form__row">
                    <div ref={countryRef} className="create-payment-form__field create-payment-form__field--left">
                      <Select
                        name="country"
                        label={l('paymentLink.country')}
                        value={values.country}
                        onChange={e => {
                          fnGetCurrencies(e.target.value);
                          fnGetDocumentTypes(e.target.value);
                          setFieldValue('country', e.target.value);
                          handlePaymentCreation({ ...values, country: e.target.value });
                        }}
                        options={countries()}
                        disabled={formDisabled}
                        error={errors.country && touched.country && errors.country}
                      />
                    </div>
                    {!isCrossBorder && (
                      <div ref={amountRef} className="create-payment-form__field create-payment-form__field--right">
                        <Input
                          type="number"
                          name="amount"
                          label={l('paymentLink.amountWantToCharge')}
                          onChange={e => {
                            handleChange(e);
                            handlePaymentCreation({ ...values, amount: e.target.value }, true);
                          }}
                          onBlur={handleBlur}
                          value={values.amount}
                          disabled={formDisabled}
                          prefix={values.currency}
                          error={(touched.amount && errors.amount) || undefined}
                        />
                      </div>
                    )}
                  </div>
                  {isCrossBorder && (
                    <>
                      {!crossBorderSameCurrency && (
                        <div className="create-payment-form__row create-payment-form__currency-selector">
                          <CurrencySelector
                            firstCurrency={crossBorderCurrency}
                            secondCurrency={Object.keys(currencies).find(c => c !== crossBorderCurrency) || ''}
                            value={values.currency}
                            onChange={value => handleOnChangeCurrency(value)}
                            disabled={formDisabled}
                          />
                        </div>
                      )}
                      <div className="create-payment-form__row">
                        <div ref={amountRef} className="create-payment-form__field create-payment-form__field--left">
                          <Input
                            type="number"
                            name="amount"
                            label={l(
                              crossBorderCurrency === values.currency
                                ? 'paymentLink.amountWantToCharge'
                                : 'paymentLink.amountYourClientWillPay'
                            )}
                            onChange={e => {
                              const value = e.target.value;
                              if (crossBorderCurrency === values.currency) {
                                setFieldValue('crossBorderData', {
                                  ...values.crossBorderData,
                                  secondAmount: applyToFixed(value * exchangeRates[values.crossBorderData.secondCurrency]),
                                });
                              } else {
                                setFieldValue('crossBorderData', {
                                  ...values.crossBorderData,
                                  secondAmount: applyToFixed(value / exchangeRates[values.currency]),
                                });
                              }
                              setFieldValue('amount', value);
                              handlePaymentCreation({ ...values, amount: value }, true);
                            }}
                            onBlur={handleBlur}
                            value={values.amount}
                            disabled={formDisabled}
                            error={(touched.amount && errors.amount) || undefined}
                            prefix={values.currency}
                          />
                          {!crossBorderSameCurrency && (
                            <div>
                              <p className="create-payment-link-form__exchange_rate">
                                <span>{l('paymentLink.exchangeRate')}</span>
                                <span>
                                  &nbsp;{values.currency} {applyToFixed(exchangeRates[values.currency])}
                                </span>
                                <ArrowRightIcon />
                                <span>
                                  <span> {values.crossBorderData.secondCurrency} </span>
                                  {applyToFixed(exchangeRates[values.crossBorderData.secondCurrency])}
                                </span>
                              </p>
                            </div>
                          )}
                        </div>
                        {!crossBorderSameCurrency && (
                          <div
                            ref={amountRef}
                            className="create-payment-form__field create-payment-form__field--right create-payment-form__input-tooltip-container"
                          >
                            <Input
                              type="number"
                              name="second_amount"
                              label={l(
                                crossBorderCurrency === values.currency
                                  ? 'paymentLink.amountYourClientWillPay'
                                  : 'paymentLink.amountYouWillReceive'
                              )}
                              onChange={e => {
                                const value = e.target.value;
                                setFieldValue('crossBorderData', { ...values.crossBorderData, secondAmount: value });
                                if (crossBorderCurrency === values.crossBorderData.secondCurrency) {
                                  setFieldValue('amount', applyToFixed(value * exchangeRates[values.currency]));
                                  handlePaymentCreation(
                                    { ...values, amount: applyToFixed(value * exchangeRates[values.currency]) },
                                    true
                                  );
                                } else {
                                  setFieldValue(
                                    'amount',
                                    applyToFixed(value / exchangeRates[values.crossBorderData.secondCurrency])
                                  );
                                  handlePaymentCreation(
                                    {
                                      ...values,
                                      amount: applyToFixed(value / exchangeRates[values.crossBorderData.secondCurrency]),
                                    },
                                    true
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.crossBorderData.secondAmount}
                              disabled={formDisabled}
                              prefix={Object.keys(currencies).find(c => c !== values.currency) || ''}
                            />

                            <div>
                              <p className="create-payment-link-form__fx_message">
                                <span>
                                  {crossBorderCurrency !== values.currency ? (
                                    l('paymentLink.exchangeRate.fxMessage')
                                  ) : (
                                    <>&nbsp;</>
                                  )}
                                </span>
                              </p>
                            </div>

                            <div className="create-payment-form__input-tooltip">
                              <TooltipIcon
                                placement="right"
                                text={Parser(
                                  crossBorderSameCurrency
                                    ? l(
                                      'paymentLink.tooltip.sameCurrency',
                                      functionalCurrenciesByCountry[merchantMe?.country || ''] || '***'
                                    )
                                    : l(
                                      'currencySelector.tooltip',
                                      Object.keys(currencies).find(c => c !== crossBorderCurrency) || crossBorderCurrency,
                                      functionalCurrenciesByCountry[merchantMe?.country || ''] || '***'
                                    )
                                )}
                                icon={<InfoIcon color="primary" />}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {values.country && (
                    <div key={keyContainer} className="create-payment-form__row" ref={smartFieldRef}>
                      <SmartField
                        country={values.country}
                        currency={values.currency}
                        totalAmount={values.amount}
                        installmentsId={instalmentSelected?.id || ''}
                        installments={instalmentSelected?.installments}
                        error={cardError}
                        onChangeError={err => setCardError(err)}
                        onChangeBrand={b => setBrandDetected(b)}
                        onChangeInstallments={option => setInstalmentSelected(option)}
                        onChangeSmartFieldData={({ dlocal, card }) => {
                          setDlocalInstance(dlocal);
                          setCardInstance(card);
                        }}
                      />
                    </div>
                  )}
                  {clientData && (
                    <div className="create-payment-form__row">
                      {clientData?.map((item, idx) => (
                        <div
                          key={idx.toString()}
                          className={`create-payment-form__field create-payment-form__field--${idx % 2 === 0 ? 'left' : 'right'
                            }`}
                        >
                          <InputData
                            type={item.type}
                            name={item.name}
                            label={`${l(`${item.name === 'clientEmail' ? 'virtualpost' : 'paymentLink'}.${item.name}`)} ${item.name === 'clientEmail' ? '' : '*'}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values[item.name]}
                            optionsArray={item.values}
                            prefix={item.prefix}
                            mask={item.mask}
                            disabled={item.readOnly || formDisabled}
                            error={
                              (errors[item.name] && touched[item.name] && errors[item.name]) ||
                              (errorFields &&
                                errorFields[item.name] &&
                                errorFields[item.name] === values[item.name] &&
                                l('form.input.invalid')) ||
                              ''
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="create-payment-form__note">
                  <div>{l('paymentLink.noteRequiredfields')}</div>
                </div>
                <div ref={errorRef}>
                  {error && (
                    <div className="create-payment-form__error">
                      <MessageError errorInfo={errorInfo} />
                    </div>
                  )}
                </div>
              </div>
              <ButtonCustom
                name="create-payment"
                type="submit"
                text={l('virtualPos.create')}
                loading={formDisabled}
                size="full-bottom"
                variant="contained"
                onClick={() => {
                  if (!_.isEmpty(errors)) {
                    scrollToFormErrors(errors);
                  }
                }}
              />
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

CreatePaymentForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  currencies: PropTypes.object,
  clientData: PropTypes.array,
  paymentMethods: PropTypes.array,
  canOperate: PropTypes.bool,
  fraudReview: PropTypes.bool,
  creditCardEnabled: PropTypes.bool,
  maxTransactionAmountCc: PropTypes.number,
  fnGetCurrencies: PropTypes.func,
  fnGetDocumentTypes: PropTypes.func,
  fnCreatePayment: PropTypes.func,
  fnConfirmPayment: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.virtualPos.loading,
  error: state.virtualPos.error,
  errorInfo: state.virtualPos.errorInfo,
  errorFields: state.virtualPos.errorFields,
  currencies: state.virtualPos.currencies,
  exchangeRates: state.virtualPos.exchangeRates,
  paymentMethods: state.virtualPos.createSuccess?.paymentMethods,
  clientData: state.virtualPos.clientData,
  canOperate: state.dashboard.merchantMe?.canOperate,
  fraudReview: state.dashboard.merchantMe?.fraudReview,
  creditCardEnabled: state.dashboard.merchantMe?.kycLevel.creditCardEnabled,
  maxTransactionAmountCc: state.dashboard.merchantMe?.kycLevel.maxTransactionAmountCc,
});

const mapDispatchToProps = dispatch => ({
  fnGetCurrencies: country => dispatch(getCurrencies(country)),
  fnGetDocumentTypes: country => dispatch(getDocumentTypes(country)),
  fnCreatePayment: values => dispatch(createPayment(values)),
  fnConfirmPayment: (values, paymentMethodId) => dispatch(confirmPayment(values, paymentMethodId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePaymentForm);
