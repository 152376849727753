import translations from './translations';
import Translate from './translate';

const get = (key, ...args) => {
  return Translate(key, translations, ...args);
};

export const langKeyExists = key => {
  return translations[key] !== undefined;
};

export default get;
