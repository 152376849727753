import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getTask, patchTask, postTask } from '../../services';
import { Urls } from '../../config/urls';
import {
  getCurrenciesSuccess,
  getCurrenciesError,
  postCreatePaymentLinkSuccess,
  postCreatePaymentLinkError,
  getDocumentTypesForPaymentLinkSuccess,
  getDocumentTypesForPaymentLinkError,
  getExistingClientsSuccess,
  getExistingClientsError,
  getCreatedOneTimePaymentLinksSuccess,
  getCreatedOneTimePaymentLinksError,
  getCreatedRecurringPaymentLinksSuccess,
  getCreatedRecurringPaymentLinksError,
  PaymentLinkActionType,
  changeStatusRecurringPaymentLinkSuccess,
  changeStatusRecurringPaymentLinkError,
} from './actions';
import { checkToken } from '../auth/sagas';
import { CheckoutTypes } from '../../constants';
import { AuthSelectors } from '../auth/selectors';
import { PanelStore } from '../interfaces';
import { segmentPaymentLinkCreatePaymentLink } from '../../utils/analytics';

function* getCurrencies(action: any) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.currencies}?country=${action.country}`,
    token,
  });

  if (response) {
    yield put(getCurrenciesSuccess(response));
  } else {
    yield put(getCurrenciesError(error));
  }
}

export function* getCurrenciesSaga() {
  yield takeLatest(PaymentLinkActionType.GET_CURRENCIES, getCurrencies);
}

function* getDocumentTypesForPaymentLink(action: any) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.documentTypes}?country=${action.country}`,
    token,
  });

  if (response) {
    yield put(getDocumentTypesForPaymentLinkSuccess(response));
  } else {
    yield put(getDocumentTypesForPaymentLinkError(error));
  }
}

export function* getDocumentTypesForPaymentLinkSaga() {
  yield takeLatest(PaymentLinkActionType.GET_DOCUMENT_TYPES_FOR_PAYMENT_LINK, getDocumentTypesForPaymentLink);
}

function* createPaymentLink(action: any) {
  yield* checkToken();
  const { isRecurring, values } = action;
  const token: string = yield select(AuthSelectors.getToken);
  const state: PanelStore.RootState = yield select();

  const body = { subType: CheckoutTypes.LINK, ...values };
  const { response, error } = yield call(postTask, {
    url: isRecurring ? Urls.api.createRecurringPaymentLink : Urls.api.createOneTimePaymentLink,
    body,
    token,
  });

  if (response) {
    if (!isRecurring && action)
      segmentPaymentLinkCreatePaymentLink({
        amount: values.amount,
        currency: values.currency,
        productName: values.description,
        shareLink: values.sendEmail,
        clientEmail: Boolean(values.clientEmail),
        additionalClientInformation: Boolean(values.clientDocument || values.clientFirstName || values.clientLastName),
      });
    yield put(postCreatePaymentLinkSuccess(response));
  } else {
    if (!isRecurring) {
      segmentPaymentLinkCreatePaymentLink({
        amount: values.amount,
        currency: values.currency,
        productName: values.description,
        shareLink: values.sendEmail,
        clientEmail: Boolean(values.clientEmail),
        additionalClientInformation: Boolean(values.clientDocument || values.clientFirstName || values.clientLastName),
        error,
      });
    }
    yield put(postCreatePaymentLinkError({ ...error, sentValues: body, appState: state }));
  }
}

export function* createPaymentLinkSaga() {
  yield takeLatest(PaymentLinkActionType.CREATE_PAYMENT_LINK, createPaymentLink);
}

function* getExistingClients(action: any) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);
  const merchantId: string = yield select(state => state.dashboard.merchantMe?.id);

  let params = `?page=${action.page || 0}`;
  params += action.country ? `&country=${action.country}` : '';

  const { response, error } = yield call(getTask, {
    url: Urls.api.allClients.replace(':id', merchantId) + params,
    token,
  });

  if (response) {
    yield put(getExistingClientsSuccess(response));
  } else {
    yield put(getExistingClientsError(error));
  }
}

export function* getExistingClientsSaga() {
  yield takeLatest(PaymentLinkActionType.GET_EXISTING_CLIENTS, getExistingClients);
}

function* getCreatedOneTimePaymentLinks(action: any) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);

  const { response, error } = yield call(getTask, {
    url: Urls.api.allOneTimePaymentLinks + `?page=${action.page || 0}&page_size=${action.rowsPerPage || 10}`,
    token,
  });

  if (response) {
    yield put(getCreatedOneTimePaymentLinksSuccess(response));
  } else {
    yield put(getCreatedOneTimePaymentLinksError(error));
  }
}

export function* getCreatedOneTimePaymentLinksSaga() {
  yield takeLatest(PaymentLinkActionType.GET_CREATED_ONE_TIME_PAYMENT_LINKS, getCreatedOneTimePaymentLinks);
}

function* getCreatedRecurringPaymentLinks(action: any) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);

  const { response, error } = yield call(getTask, {
    url: Urls.api.allRecurringPaymentLinks + `?page=${action.page || 0}&page_size=${action.rowsPerPage || 10}`,
    token,
  });

  if (response) {
    yield put(getCreatedRecurringPaymentLinksSuccess(response));
  } else {
    yield put(getCreatedRecurringPaymentLinksError(error));
  }
}

export function* getCreatedRecurringPaymentLinksSaga() {
  yield takeLatest(PaymentLinkActionType.GET_CREATED_RECURRING_PAYMENT_LINKS, getCreatedRecurringPaymentLinks);
}

function* changeStatusRecurringPaymentLink(action?: { enable: boolean; recurringLinkToken: string }) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);
  const url = action?.enable ? Urls.api.enableRecurringPaymentLink : Urls.api.disableRecurringPaymentLink;

  const { response, error } = yield call(patchTask, {
    url: url.replace(':token', action?.recurringLinkToken || ''),
    token,
    body: {},
  });

  if (response) {
    yield put(changeStatusRecurringPaymentLinkSuccess(response));
  } else {
    yield put(changeStatusRecurringPaymentLinkError(error));
  }
}

export function* changeStatusRecurringPaymentLinkSaga() {
  yield takeLatest(PaymentLinkActionType.CHANGE_STATUS_RECURRING_PAYMENT_LINK, changeStatusRecurringPaymentLink);
}
