import { AuthState } from './auth/interfaces';
import { BankAccountsState } from './bank-accounts/interfaces';
import { ClientsState } from './clients/interfaces';
import { CompanyInformationState } from './company-information/interfaces';
import { DashboardState } from './dashboard/interfaces';
import { EmailValidationState } from './email-validation/interfaces';
import { HomeState } from './home/interfaces';
import { JumioState } from './jumio/interfaces';
import { PasswordResetState } from './password-reset/interfaces';
import { PaymentIntegrationState } from './payment-integration/interfaces';
import { PaymentLinkState } from './payment-link/interfaces';
import { PaymentMethodsState } from './payment-methods/interfaces';
import { ProfileState } from './profile/interfaces';
import { RefundsState } from './refunds/interfaces';
import { ReportsState } from './reports/interfaces.d';
import { SettlementsState } from './settlements/interfaces';
import { SignUpState } from './sign-up/interfaces';
import { SubscriptionsState } from './subscriptions/interfaces';
import { TransactionsState } from './transactions/interfaces';
import { VirtualPOSState } from './virtual-pos/interfaces';

export namespace PanelStore {
  export interface RootState {
    auth: AuthState;
    paymentLink: PaymentLinkState;
    bankAccounts: BankAccountsState;
    clients: ClientsState;
    companyInformation: CompanyInformationState;
    dashboard: DashboardState;
    emailValidation: EmailValidationState;
    home: HomeState;
    passwordReset: PasswordResetState;
    paymentIntegration: PaymentIntegrationState;
    paymentMethods: PaymentMethodsState;
    profile: ProfileState;
    refunds: RefundsState;
    reports: ReportsState;
    settlements: SettlementsState;
    signUp: SignUpState;
    transactions: TransactionsState;
    virtualPos: VirtualPOSState;
    jumio: JumioState;
    subscriptions: SubscriptionsState;
  }
}

// COMMONS
export interface Pagination {
  totalElements: number;
  totalPages: number;
  page: number;
  numberOfElements: number;
  size: number;
}

export interface Currencies {
  [key: string]: string;
}
export interface ExchangeRates {
  [key: string]: number;
}
export interface DocumentTypes {
  [key: string]: string;
}

export interface ErrorFields {
  [key: string]: string;
}

export interface Values {
  [key: string | number]: string | number | boolean | null;
}

export interface Filters {
  [filter: string]: string;
}

export enum ExportType {
  CSV,
  XLSX,
}

export interface ErrorAPI {
  status: number;
  body?: { errorCode?: number; errorFields?: string[] };
  /*
   * appState: In some cases, from sagas we pass the redux state for cases in
   * which an error needs data in the message to be displayed on the screen.
   */
  appState?: PanelStore.RootState;
}
