import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './styles.scss';
import { Edit as EditIcon, Info as InfoIcon } from '@mui/icons-material';
import { Formik } from 'formik';
import Input from '../../../common/input';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import MessageError from '../../../common/message-error';
import l from '../../../../lang';
import { changePassword, cleanProfileSuccess, openChangePassword } from '../../../../store/profile/actions';
import Loading from '../../../common/loading';
import SuccessfulAnimation from '../../../common/successful-animation';
import { commons as validations } from '../../../../utils/validations';
import { segmentUserMenuProfileChangePassword, segmentUserMenuProfilePasswordSave } from '../../../../utils/analytics';

const ChangePassword = ({
  loading,
  error,
  errorInfo,
  success,
  showChangePassword,
  fnChangePassword,
  fnCleanProfileSuccess,
  fnShowChangePassword,
}) => {
  useEffect(() => {
    if (showChangePassword) {
      fnShowChangePassword(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (success && showChangePassword) {
      setTimeout(() => {
        fnShowChangePassword(false);
        fnCleanProfileSuccess();
      }, 2000);
    }
    // eslint-disable-next-line
  }, [success]);

  return (
    <div className={`change-password ${(loading || success) && !showChangePassword ? 'change-password--hide' : ''}`}>
      {loading && (
        <div className="change-password__loading">
          <Loading />
        </div>
      )}
      {success && (
        <div className="change-password__success">
          <SuccessfulAnimation text={l('changePassword.updateSuccess')} />
        </div>
      )}
      <div className={`change-password__container ${loading || success ? 'change-password__container--hide' : ''}`}>
        <div className="change-password__section">
          <div className="change-password__section__title">{l('changePassword.title')}</div>
        </div>
        {!showChangePassword && (
          <div className="change-password__box">
            <ButtonCustom
              startIcon={<EditIcon />}
              text={l('changePassword.edit')}
              onClick={() => {
                segmentUserMenuProfileChangePassword();
                fnShowChangePassword(true);
              }}
            />
          </div>
        )}
        {showChangePassword && (
          <Formik
            initialValues={{ oldPassword: '', newPassword: '' }}
            validate={values => {
              const errors = {};
              if (values.oldPassword && values.oldPassword === values.newPassword) {
                errors.newPassword = l('changePassword.equalsError');
              }
              if (!values.oldPassword) {
                errors.oldPassword = l('form.input.required');
              } else if (!validations.passwordRequiredCharacters(values.oldPassword)) {
                errors.oldPassword = l('form.input.password.invalid');
              } else if (!validations.passwordLengthRequired(values.oldPassword)) {
                errors.oldPassword = l('form.input.password.invalidLength');
              }
              if (!values.newPassword) {
                errors.newPassword = l('form.input.required');
              } else if (!validations.passwordRequiredCharacters(values.newPassword)) {
                errors.newPassword = l('form.input.password.invalid');
              } else if (!validations.passwordLengthRequired(values.newPassword)) {
                errors.newPassword = l('form.input.password.invalidLength');
              }
              return errors;
            }}
            onSubmit={values => {
              const { oldPassword, newPassword } = values;
              fnChangePassword(oldPassword, newPassword);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="change-password__box change-password__box--left">
                  <Input
                    type="password"
                    name="oldPassword"
                    label={l('changePassword.oldPassword')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.oldPassword}
                    disabled={loading}
                    error={errors.oldPassword && touched.oldPassword && errors.oldPassword}
                  />
                </div>
                <div className="change-password__box change-password__box--right">
                  <Input
                    type="password"
                    name="newPassword"
                    label={l('changePassword.newPassword')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                    disabled={loading}
                    error={errors.newPassword && touched.newPassword && errors.newPassword}
                  />
                </div>
                <div className="change-password__desc">
                  <InfoIcon fontSize="small" className="change-password__desc__icon" />
                  {l('password.helpText')}
                </div>
                {error && (
                  <div className="change-password__error">
                    <MessageError errorInfo={errorInfo} />
                  </div>
                )}
                {!success && !loading && (
                  <div className="change-password__buttons">
                    <ButtonCustom
                      text={l('changePassword.cancel')}
                      name="btn-change-password-cancel"
                      size="half-left-square"
                      onClick={() => fnShowChangePassword(false)}
                    />
                    <ButtonCustom
                      type="submit"
                      name="btn-change-password-save"
                      text={l('changePassword.save')}
                      variant="contained"
                      size="half-right-square"
                      onClick={() => segmentUserMenuProfilePasswordSave()}
                    />
                  </div>
                )}
              </form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

ChangePassword.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  success: PropTypes.bool,
  showChangePassword: PropTypes.bool,
  fnChangePassword: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.profile.loading,
  error: state.profile.error,
  errorInfo: state.profile.errorInfo,
  success: state.profile.success,
  showChangePassword: state.profile.showChangePassword,
});

const mapDispatchToProps = dispatch => ({
  fnChangePassword: (oldPassword, newPassword) => dispatch(changePassword(oldPassword, newPassword)),
  fnCleanProfileSuccess: () => dispatch(cleanProfileSuccess()),
  fnShowChangePassword: show => dispatch(openChangePassword(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
