import { handleErrorInfo } from '../../utils';
import { ProfileActionType } from './actions';
import { ProfileState } from './interfaces';

const initialState: ProfileState = {
  loading: false,
  error: false,
  errorInfo: undefined,
  success: false,
  showEditPersonalInfo: false,
  showChangePassword: false,
};

const Profile = (state = initialState, action: any) => {
  switch (action.type) {
    case ProfileActionType.SAVE_PERSONAL_INFO: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        success: false,
      };
    }
    case ProfileActionType.SAVE_PERSONAL_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        success: true,
      };
    }
    case ProfileActionType.SAVE_PERSONAL_INFO_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(ProfileActionType.SAVE_PERSONAL_INFO_ERROR, action.error),
      };
    }
    case ProfileActionType.CLEAN_PROFILE_SUCCESS: {
      return {
        ...state,
        success: false,
      };
    }
    case ProfileActionType.CHANGE_PASSWORD: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        success: false,
      };
    }
    case ProfileActionType.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        success: true,
      };
    }
    case ProfileActionType.CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(ProfileActionType.CHANGE_PASSWORD_ERROR, action.error),
      };
    }
    case ProfileActionType.SHOW_EDIT_PERSONAL_INFO: {
      return {
        ...state,
        showEditPersonalInfo: action.show,
        showChangePassword: action.show ? false : state.showChangePassword,
        error: false,
      };
    }
    case ProfileActionType.SHOW_CHANGE_PASSWORD: {
      return {
        ...state,
        showChangePassword: action.show,
        showEditPersonalInfo: action.show ? false : state.showEditPersonalInfo,
        error: false,
      };
    }
    default:
      return state;
  }
};

export default Profile;
