import { useRef, useEffect, FunctionComponent, useState } from 'react';
import _ from 'lodash';
import Card from '../../../../common/card';
import CardDescription from '../../../../common/card-description';
import CardTitle from '../../../../common/card-title';
import l, { langKeyExists } from '../../../../../lang';
import BriefcaseIcon from '../../../../../assets/icons/briefcase.svg?url';
import CheckIcon from '../../../../../assets/icons/check-green.svg?url';
import FileIcon from '../../../../../assets/icons/file.svg?url';
import ButtonCustom from '../../../../common/__deprecated__/button-custom';
import { Formik, FormikErrors, FormikValues } from 'formik';
import Input from '../../../../common/input';
import MessageError from '../../../../common/message-error';
import Select from '../../../../common/select';
import { Tooltip } from '../../../../common/tooltip';
import { accountTypesForSettlements } from '../../../../../utils/settlements-utils';
import { scrollIntoView } from '../../../../../utils';
import { Autocomplete } from '../../../../common/autocomplete';
import {
  hideFieldsByCountry,
  showFieldsByCountry,
  bankAccountNumberLabels,
  bankBranchLabels,
  commentsLabels,
} from '../bank-account-config';
import Accordion from '../../../../common/accordion';
import { getBankAccountLabel, getBankAccountConfirmLabel, getBankBranchLabel } from '../../bank-account-helper';
import { useCompanyInfo } from '../../../../../store/company-information';
import { useDashboard } from '../../../../../store/dashboard';
import { useBankAccounts } from '../../../../../store/bank-accounts';
import UploadFile from '../../../../common/upload-file';
import { segmentKycFormL2UploadDocBank } from '../../../../../utils/analytics';
import config, { functionalCurrenciesByCountry } from '../../../../../config';

import './styles.scss';
import { needInternationalWireDetails } from '../../../../../utils/bank-account-utils';
import InputCountryPhone from '../../../../common/input-country-phone';

export type bankFormFields = {
  bankCode: string;
  bankName: string;
  accountType: string;
  bankAccount: string;
  bankAccountConfirm: string;
  bankBranch: string;
  beneficiaryAddress: string;
  beneficiaryCity: string;
  beneficiaryPhone: string;
  comments: string;
  bankAccountDocument: File | null;
  internationalWireDetails: File | null;
};

interface AddBankFormProps {
  handleSubmit: (values: bankFormFields) => void;
  submitLabel: string;
  title: string;
  description: string;
  bankAccountDocumentRequired?: boolean;
  FormObserver?: any;
}

const AddBankForm: FunctionComponent<AddBankFormProps> = ({
  handleSubmit,
  submitLabel,
  title,
  description,
  bankAccountDocumentRequired = false,
  FormObserver = undefined,
}) => {
  const { loading, error, errorInfo } = useCompanyInfo();
  const { merchantMe } = useDashboard();
  const { country, name, lastName, documentType, document } = merchantMe!;
  const { newBankAccount, getBankAccounts, getBanksByCountry, allBankAccounts } = useBankAccounts();

  const [isExpandedBankAccount, setIsExpandedBankAccount] = useState(true);
  const [isExpandedProofDocument, setIsExpandedProofDocument] = useState(true);

  const [internationalWireDetails, setInternationalWireDetails] = useState(false);

  const errorRef = useRef(null);

  useEffect(() => {
    getBankAccounts();
    getBanksByCountry(country);
  }, []);

  useEffect(() => {
    if (error) {
      scrollIntoView(errorRef);
    }
  }, [error]);

  const initialValues: bankFormFields = {
    bankCode: '',
    bankName: '',
    accountType: '',
    bankAccount: '',
    bankAccountConfirm: '',
    bankBranch: '',
    beneficiaryAddress: '',
    beneficiaryCity: '',
    beneficiaryPhone: '',
    comments: '',
    bankAccountDocument: null,
    internationalWireDetails: null,
  };

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<FormikValues> = {};
    if (bankAccountDocumentRequired && values.bankAccountDocument === null) {
      errors.bankAccountDocument = l('form.input.required');
      !isExpandedProofDocument && setIsExpandedProofDocument(true);
    }
    if (internationalWireDetails && bankAccountDocumentRequired && values.internationalWireDetails === null) {
      errors.internationalWireDetails = l('form.input.required');
      !isExpandedProofDocument && setIsExpandedProofDocument(true);
    }
    if (!hideFieldsByCountry[country]?.includes('bankCode') && !values.bankCode) {
      errors.bankCode = l('form.input.required');
      !isExpandedBankAccount && setIsExpandedBankAccount(true);
    }
    if (!values.bankAccount) {
      errors.bankAccount = l('form.input.required');
      !isExpandedBankAccount && setIsExpandedBankAccount(true);
    }
    if (!hideFieldsByCountry[country]?.includes('accountType')) {
      if (!values.accountType) {
        errors.accountType = l('form.input.required');
        !isExpandedBankAccount && setIsExpandedBankAccount(true);
      }
    }
    if (!values.bankAccountConfirm) {
      errors.bankAccountConfirm = l('form.input.required');
      !isExpandedBankAccount && setIsExpandedBankAccount(true);
    } else if (values.bankAccount !== values.bankAccountConfirm) {
      switch (country) {
        case 'ES':
          errors.bankAccountConfirm = l('beneficiary.notMatchIBAN');
          !isExpandedBankAccount && setIsExpandedBankAccount(true);
          break;
        default:
          errors.bankAccountConfirm = l('beneficiary.notMatchBankAccount');
          !isExpandedBankAccount && setIsExpandedBankAccount(true);
          break;
      }
    }
    if (!hideFieldsByCountry[country]?.includes('bankBranch') && !values.bankBranch) {
      errors.bankBranch = l('form.input.required');
      !isExpandedBankAccount && setIsExpandedBankAccount(true);
    }
    if (!hideFieldsByCountry[country]?.includes('beneficiaryAddress')) {
      if (!values.beneficiaryAddress) {
        errors.beneficiaryAddress = l('form.input.required');
        !isExpandedBankAccount && setIsExpandedBankAccount(true);
      }
    }
    if (!hideFieldsByCountry[country]?.includes('beneficiaryCity')) {
      if (!values.beneficiaryCity) {
        errors.beneficiaryCity = l('form.input.required');
        !isExpandedBankAccount && setIsExpandedBankAccount(true);
      }
    }
    if (!hideFieldsByCountry[country]?.includes('beneficiaryPhone')) {
      if (!values.beneficiaryPhone) {
        errors.beneficiaryPhone = l('form.input.required');
        !isExpandedBankAccount && setIsExpandedBankAccount(true);
      }
    }

    return errors;
  };

  const getBankAccountLabel = () => {
    if (bankAccountNumberLabels[country]) {
      return bankAccountNumberLabels[country];
    }
    return l('beneficiary.bankAccount');
  };

  const getBankAccountConfirmLabel = () => {
    if (bankAccountNumberLabels[country]) {
      return l('beneficiary.confirmAccount', bankAccountNumberLabels[country]);
    }
    return l('beneficiary.confirmBankAccount');
  };

  const getBankBranchLabel = () => {
    if (bankBranchLabels[country]) {
      return bankBranchLabels[country];
    }
    return l('beneficiary.branch');
  };

  const getCommentsLabel = () => {
    if (commentsLabels[country]) {
      return commentsLabels[country];
    }
    return '';
  };

  const preHandleSubmit = (values: FormikValues) => {
    const valuesToSend = {};
    Object.keys(values).forEach(key => {
      if (typeof values[key] === 'string') {
        if (values[key].trim() !== '') {
          // @ts-ignore
          valuesToSend[key] = values[key];
        }
      } else {
        // @ts-ignore
        valuesToSend[key] = values[key];
      }
    });
    // @ts-ignore
    handleSubmit(valuesToSend);
  };

  const formLoading = loading || newBankAccount.loading;

  return (
    <div className="add_bank_form__section">
      <Card>
        {title && <CardTitle>{title}</CardTitle>}
        {description && <CardDescription>{description}</CardDescription>}
      </Card>
      <Formik initialValues={initialValues} validate={validate} onSubmit={values => preHandleSubmit(values)}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          const onFileBankHandler = ({ file: value }: { file: File | null; base64: string | null }): void => {
            if (value !== null) {
              segmentKycFormL2UploadDocBank();
            }
            setFieldValue('bankAccountDocument', value);
          };

          const onFileInternationalWireDetailsHandler = ({
            file: value,
          }: {
            file: File | null;
            base64: string | null;
          }): void => {
            if (value !== null) {
              segmentKycFormL2UploadDocBank();
            }
            setFieldValue('internationalWireDetails', value);
          };

          return (
            <form onSubmit={handleSubmit}>
              {FormObserver}
              <div className="add_bank_form__subsection">
                <Accordion
                  header={
                    <div className="add_bank_form__title">
                      <img src={CheckIcon} alt="user" width={20} />
                      <span>{l('companyInformation.kycTwo.companyOwnerLegalInformation')}</span>
                    </div>
                  }
                >
                  <div className="add_bank_form__subtitle">
                    {l('companyInformation.kycTwo.companyOwnerLegalInformation.subheadline')}
                  </div>
                  <Input
                    name="beneficiaryName"
                    label={l('beneficiary.name')}
                    value={`${name || ''} ${lastName || ''}`.trim()}
                    disabled
                  />
                  <Input
                    name="country"
                    label={l('beneficiary.country')}
                    value={country ? l(`countryCode.${country}`) : ''}
                    disabled
                  />
                  <div className="add_bank_form__half_left">
                    <Input
                      name="beneficiaryDocumentType"
                      label={l('beneficiary.documentType')}
                      value={documentType || ''}
                      disabled
                    />
                  </div>
                  <div className="add_bank_form__half_right">
                    <Input name="beneficiaryDocument" label={l('beneficiary.document')} value={document || ''} disabled />
                  </div>
                </Accordion>
              </div>
              <div className="add_bank_form__subsection">
                <Accordion
                  header={
                    <div className="add_bank_form__title">
                      <img src={BriefcaseIcon} alt="user" />
                      <span>{l('companyInformation.kycTwo.bankAccountInformation')}</span>
                    </div>
                  }
                  isExpanded={isExpandedBankAccount}
                  onChange={() => setIsExpandedBankAccount(!isExpandedBankAccount)}
                >
                  <>
                    <div className="add_bank_form__subtitle">
                      {l(
                        'companyInformation.kycTwo.bankAccountInformation.subheadline',
                        functionalCurrenciesByCountry[country]
                      )}
                    </div>
                    {!hideFieldsByCountry[country]?.includes('bankCode') && (
                      <Autocomplete
                        name="bankCode"
                        label={l('beneficiary.bankCode')}
                        value={values.bankCode}
                        onBlur={handleBlur}
                        options={newBankAccount.banks.map(({ code, name }) => ({
                          label: name,
                          value: code,
                        }))}
                        onChange={e => {
                          const newValue = e.target.value;
                          const selectedBank = newBankAccount.banks.find(({ code }) => code === newValue);
                          if (selectedBank) {
                            const { code, name } = selectedBank;
                            setFieldValue('bankCode', code);
                            setFieldValue('bankName', name);
                            setInternationalWireDetails(Boolean(needInternationalWireDetails(country, code)));
                          } else {
                            setFieldValue('bankCode', '');
                            setFieldValue('bankName', '');
                          }
                        }}
                        disabled={_.isEmpty(newBankAccount.banks) || formLoading || newBankAccount.success}
                        error={Boolean(touched.bankCode && errors.bankCode)}
                        helperText={(touched.bankCode && errors.bankCode) || undefined}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <>
                              <span className="add_bank_form__bank_code">{option.value}</span>
                              <span className="add_bank_form__bank_label">{option.label}</span>
                            </>
                          </li>
                        )}
                      />
                    )}
                    {!hideFieldsByCountry[country]?.includes('accountType') && (
                      <Select
                        name="accountType"
                        label={l('beneficiary.accountType')}
                        value={values.accountType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={accountTypesForSettlements()}
                        disabled={formLoading || newBankAccount.success}
                        error={touched.accountType ? errors.accountType : null}
                      />
                    )}
                    <Input
                      name="bankAccount"
                      label={getBankAccountLabel()}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bankAccount}
                      maxLength={50}
                      disabled={formLoading || newBankAccount.success}
                      error={errors.bankAccount && touched.bankAccount && errors.bankAccount}
                    />
                    <Input
                      name="bankAccountConfirm"
                      label={getBankAccountConfirmLabel()}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bankAccountConfirm}
                      maxLength={50}
                      disabled={formLoading || newBankAccount.success}
                      error={errors.bankAccountConfirm && touched.bankAccountConfirm && errors.bankAccountConfirm}
                    />
                    {showFieldsByCountry[country]?.includes('comments') && (
                      <Input
                        name="comments"
                        label={getCommentsLabel()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.comments}
                        disabled={formLoading || newBankAccount.success}
                        error={errors.comments && touched.comments && errors.comments}
                      />
                    )}
                    {!hideFieldsByCountry[country]?.includes('bankBranch') && (
                      <Input
                        name="bankBranch"
                        label={getBankBranchLabel()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bankBranch}
                        maxLength={10}
                        disabled={formLoading || newBankAccount.success}
                        error={errors.bankBranch && touched.bankBranch && errors.bankBranch}
                      />
                    )}
                    {!hideFieldsByCountry[country]?.includes('beneficiaryAddress') && (
                      <Tooltip
                        title={l('beneficiary.address.tooltip')}
                        enterTouchDelay={0}
                        classes={{
                          tooltip: 'bank_accounts__tooltip',
                          arrow: 'bank_accounts__tooltip__arrow',
                        }}
                      >
                        <span>
                          <Input
                            name="beneficiaryAddress"
                            label={l('beneficiary.address')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.beneficiaryAddress}
                            maxLength={100}
                            disabled={formLoading || newBankAccount.success}
                            error={errors.beneficiaryAddress && touched.beneficiaryAddress && errors.beneficiaryAddress}
                          />
                        </span>
                      </Tooltip>
                    )}

                    {!hideFieldsByCountry[country]?.includes('beneficiaryCity') && (
                      <Input
                        name="beneficiaryCity"
                        label={l('beneficiary.city')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.beneficiaryCity}
                        maxLength={100}
                        disabled={formLoading || newBankAccount.success}
                        error={errors.beneficiaryCity && touched.beneficiaryCity && errors.beneficiaryCity}
                      />
                    )}
                    {!hideFieldsByCountry[country]?.includes('beneficiaryPhone') && (
                      <InputCountryPhone
                        type="number"
                        name="beneficiaryPhone"
                        label={l('beneficiary.phone')}
                        onChange={value => setFieldValue('beneficiaryPhone', value)}
                        onBlur={handleBlur}
                        value={values.beneficiaryPhone}
                        defaultCountry={country}
                        disabled={formLoading || newBankAccount.success}
                        error={(errors.beneficiaryPhone && touched.beneficiaryPhone && errors.beneficiaryPhone) || undefined}
                      />
                    )}
                  </>
                </Accordion>
              </div>
              {bankAccountDocumentRequired && (
                <div className="add_bank_form__subsection">
                  <Accordion
                    header={
                      <div className="add_bank_form__title">
                        <img src={FileIcon} alt="user" width={20} />
                        <span>
                          {langKeyExists(`companyInformation.kycFour.bankAccountDocument.${country}`)
                            ? l(`companyInformation.kycFour.bankAccountDocument.${country}`)
                            : l('companyInformation.kycTwo.bankAccountDocumentation')}
                        </span>
                      </div>
                    }
                    isExpanded={isExpandedProofDocument}
                    onChange={() => setIsExpandedProofDocument(!isExpandedProofDocument)}
                  >
                    <UploadFile
                      title=""
                      description={l('companyInformation.kycFour.bankAccountDocumentHelp')}
                      supportedExtensions={config.uploadFileSupportedExtensions}
                      maxSize={config.uploadFileMaxSize}
                      onChange={onFileBankHandler}
                      externalError={(touched.bankAccountDocument && errors.bankAccountDocument) || undefined}
                    >
                      <ul>
                        <li>{l('companyInformation.kycFour.bankAccountDocumentHelp.bullet1')}</li>
                        <li>{l('companyInformation.kycFour.bankAccountDocumentHelp.bullet2')}</li>
                        <li>{l('companyInformation.kycFour.bankAccountDocumentHelp.bullet3')}</li>
                      </ul>
                    </UploadFile>

                    {internationalWireDetails && (
                      <>
                        <br />
                        <br />
                        <UploadFile
                          title={'International Wire Details'}
                          supportedExtensions={config.uploadFileSupportedExtensions}
                          maxSize={config.uploadFileMaxSize}
                          onChange={onFileInternationalWireDetailsHandler}
                          externalError={(touched.internationalWireDetails && errors.internationalWireDetails) || undefined}
                        />
                      </>
                    )}
                  </Accordion>
                </div>
              )}
              <div ref={errorRef}>
                {newBankAccount.error && (
                  <div className="add_bank_form__message_error">
                    <MessageError errorInfo={newBankAccount.errorInfo} />
                  </div>
                )}
                {error && (
                  <div className="add_bank_form__message_error">
                    <MessageError errorInfo={errorInfo} />
                  </div>
                )}
              </div>
              <button id="send-data-kyc-2" type="submit" hidden />
            </form>
          );
        }}
      </Formik>
      <div className="add_bank_form__subsection">
        <ButtonCustom
          fullWidth
          text={submitLabel}
          onClick={() => window.document.getElementById('send-data-kyc-2')?.click()}
          loading={formLoading}
        />
      </div>
    </div>
  );
};

export default AddBankForm;
