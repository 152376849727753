import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { FormHelperText, InputAdornment, OutlinedInput, TextField, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Search as SearchIcon } from '@mui/icons-material';
import { Button } from '../button';
import l from '../../../lang';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: useTheme().spacing(2),
    '&.sm': {
      fontSize: '14px',
    },
    '&.md': {
      fontSize: '16px',
    },
    '&.lg': {
      width: '100%',
    },
  },
  label: {
    fontSize: '14px',
  },
  multiselect: {
    fontSize: '14px',
  },
  select: {
    backgroundColor: '#ffffff9e',
    fontSize: '14px',
  },
  selectDisabled: {
    backgroundColor: '#f1f1f19e',
    fontSize: '14px',
  },
  searchInput: {
    borderRadius: '8px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #ECECEC',
    },
  },
  header: {
    position: 'sticky',
    top: 0,
    background: '#fff',
    borderBottom: '1px solid #ECECEC',
    zIndex: 2,
  },
  paper: {
    borderRadius: '8px',
  },
  titleOptions: {
    padding: '10px 20px 0',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    maxWidth: '300px',
  },
  descriptionOptions: {
    padding: '10px 20px 0',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    maxWidth: '300px',
  },
  checkbox: {
    padding: '5px 9px 5px 0',
  },
  buttons: {
    position: 'sticky',
    backgroundColor: '#fff !important',
    bottom: 0,
    zIndex: 2,
  },
  cancel: {
    width: '49%',
    marginRight: '1%',
  },
  apply: {
    width: '49%',
    marginLeft: '1%',
  },
}));

const MultiSelect = ({
  value,
  label,
  name,
  disabled,
  options,
  withSearch,
  placeholderSearch,
  onChange,
  onBlur,
  size,
  error,
  titleOptions,
  descriptionOptions,
  withApplyBtn,
  maxHeightOptions,
  hideValue,
}) => {
  const classes = useStyles();

  const [selectionApplied, setSelectionApplied] = useState([]);
  const [selectionToApply, setSelectionToApply] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (value) {
      setSelectionApplied(value);
      setSelectionToApply(value);
    }
  }, [value]);

  const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  const displayedOptions = useMemo(() => options.filter(option => containsText(option.name, searchText)), [searchText]);

  const handleChange = event => {
    let clickedOptions = event.target.value;

    if (clickedOptions[clickedOptions.length - 1] === 'all') {
      clickedOptions = selectionToApply.length === options.length ? [] : options;
    }

    const idsSelected = [];
    const selected = [];

    clickedOptions.forEach(item => {
      // remove repeats
      if (item && clickedOptions.filter(e => e && e.id === item.id).length === 1) {
        idsSelected.push(item.id);
        selected.push(item);
      }
    });

    setSelectionToApply(selected);

    if (!withApplyBtn) {
      setSelectionApplied(selected);
      onChange(idsSelected.join(','));
    }
  };

  const applyChanges = () => {
    setSelectionApplied(selectionToApply);
    onChange(selectionToApply.map(item => item.id).join(','));
  };

  const cancelChanges = () => {
    setSelectionToApply(selectionApplied);
  };

  const clearAll = () => {
    setSelectionApplied([]);
    setSelectionToApply([]);
    onChange('');
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={classes.formControl}
      error={!!error}
      size={size === 'sm' ? 'small' : undefined}
    >
      <InputLabel htmlFor={`multiselect-${name}`} className={classes.label} {...(hideValue ? { shrink: false } : {})}>
        {label}
      </InputLabel>
      <Select
        id={`multiselect-${name}`}
        multiple
        label={label}
        className={disabled ? classes.selectDisabled : classes.select}
        value={selectionToApply}
        onChange={handleChange}
        onBlur={onBlur}
        renderValue={selected => (hideValue ? '' : selected.map(item => item.name).join(', '))}
        disabled={disabled}
        input={hideValue ? <OutlinedInput /> : undefined}
        inputProps={{
          className: classes.multiselect,
        }}
        MenuProps={{
          classes: {
            paper: classes.paper,
          },
          PaperProps: { sx: { maxHeight: maxHeightOptions } },
        }}
        onClose={() => selectionApplied.toString() !== selectionToApply.toString() && setSelectionToApply(selectionApplied)}
      >
        {withSearch && (
          <MenuItem className={classes.header}>
            <TextField
              size="small"
              variant="outlined"
              autoFocus
              placeholder={placeholderSearch}
              fullWidth
              value={searchText}
              InputProps={{
                classes: {
                  root: classes.searchInput,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={e => setSearchText(e.target.value)}
              onKeyDown={e => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </MenuItem>
        )}
        {titleOptions && <div className={classes.titleOptions}>{titleOptions}</div>}
        {descriptionOptions && <div className={classes.descriptionOptions}>{descriptionOptions}</div>}
        {options.length && displayedOptions.length === options.length && (
          <MenuItem key="all" value="all">
            <Checkbox
              className={classes.checkbox}
              checked={selectionToApply.length === displayedOptions.length}
              disabled={disabled}
            />
            <ListItemText primary={l('common.all.m')} />
          </MenuItem>
        )}
        {displayedOptions?.map(op => (
          <MenuItem key={op.id} value={op}>
            <Checkbox
              className={classes.checkbox}
              checked={selectionToApply.filter(item => item.id === op.id).length > 0}
              disabled={disabled}
            />
            <ListItemText primary={op.name} />
          </MenuItem>
        ))}
        {withApplyBtn && (
          <MenuItem className={classes.buttons}>
            <Button
              variant="text"
              className={classes.cancel}
              onClick={() =>
                JSON.stringify(selectionApplied) === JSON.stringify(selectionToApply) ? clearAll() : cancelChanges()
              }
              disabled={disabled || (!selectionApplied.length && !selectionToApply.length)}
            >
              {selectionApplied.length === 0 || JSON.stringify(selectionApplied) === JSON.stringify(selectionToApply)
                ? l('common.clearAll')
                : l('common.cancel')}
            </Button>
            <Button
              className={classes.apply}
              onClick={() => applyChanges()}
              disabled={disabled || JSON.stringify(selectionApplied) === JSON.stringify(selectionToApply)}
            >
              {l('common.apply')}
            </Button>
          </MenuItem>
        )}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

MultiSelect.propTypes = {
  value: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  withSearch: PropTypes.bool,
  placeholderSearch: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  size: PropTypes.string,
  error: PropTypes.string,
  maxHeightOptions: PropTypes.number,
  hideValue: PropTypes.bool,
};

MultiSelect.defaultProps = {
  titleOptions: undefined,
  descriptionOptions: undefined,
  withApplyBtn: false,
  maxHeightOptions: undefined,
  hideValue: false,
};

export default MultiSelect;
