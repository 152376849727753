import Bugsnag from '@bugsnag/js';
import l from '../../lang';

export const transationStatus = () => {
  return {
    PENDING: l('transactions.status.PENDING'),
    COMPLETED: l('transactions.status.COMPLETED'),
    CANCELLED: l('transactions.status.CANCELLED'),
    DECLINED: l('transactions.status.DECLINED'),
  };
};

export const prepareExportTransactionCSV = data => {
  let d = data;
  if (d) {
    try {
      // Replace text of columns
      d = d.replace('Id', l('transactions.id'));
      d = d.replace('Date', l('transactions.date'));
      d = d.replace('Invoice Id', l('transactions.invoiceId'));
      d = d.replace('Description', l('transactions.description'));
      d = d.replace('Client Id', l('transactions.clientId'));
      d = d.replace('Payment Method', l('transactions.paymentMethod'));
      d = d.replace('Country', l('transactions.country'));
      d = d.replace('Local Amount', l('transactions.amountPaid'));
      d = d.replace('Balance Amount (USD)', `${l('transactions.balanceAmount')} (USD)`);
      d = d.replace('Balance Fee (USD)', `${l('transactions.balanceFee')} (USD)`);
      d = d.replace('Status', l('transactions.status'));
      d = d.replace('Client First Name', l('transactions.clientFirstName'));
      d = d.replace('Client Last Name', l('transactions.clientLastName'));
      d = d.replace('Client Document Type', l('transactions.clientDocumentType'));
      d = d.replace('Client Document', l('transactions.clientDocument'));
      d = d.replace('Client Email', l('transactions.clientEmail'));

      // Replace all nulls
      d = d.replace(/null/g, ' - ');

      // Replace status values
      const status = transationStatus();
      Object.keys(status).forEach(key => {
        const regex = new RegExp(key, 'g');
        d = d.replace(regex, status[key]);
      });

      const columnsToRemove = [10, 17, 18]; // Starts at 0.
      const columnsAndRows = d.split(/\r\n|\n/g);

      let columnsFinal = '';
      columnsAndRows[0].split(',').forEach((column, idx) => {
        if (!columnsToRemove.includes(idx)) {
          if (columnsFinal) {
            columnsFinal += `,${column}`;
          } else {
            columnsFinal += column;
          }
        }
      });

      const handleRows = row => {
        let rowResponse = '';
        row.forEach((cell, idx) => {
          if (!columnsToRemove.includes(idx)) {
            if (rowResponse) {
              rowResponse += `,${cell}`;
            } else {
              rowResponse += cell;
            }
          }
        });
        return rowResponse;
      };

      let rowsFinal = '';
      columnsAndRows.forEach((row, idx) => {
        if (idx !== 0) {
          const r = handleRows(row.split(','));
          if (rowsFinal) {
            rowsFinal += `\n${r}`;
          } else {
            rowsFinal += r;
          }
        }
      });

      return `${columnsFinal}\n${rowsFinal}`;
    } catch (ex) {
      Bugsnag.notify(new Error(`Error in prepareExportTransactionCSV(): ${data}`));
    }
  }
  return data;
};
