import { FunctionComponent, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, FormControl, Input, InputBase, InputLabel, TextField } from '@mui/material';
import l from '../../../lang';
import CopyIcon from '../../../assets/icons/copy.svg?url';
import SecretVisibleIcon from '../../../assets/icons/eye.svg?url';
import SecretIcon from '../../../assets/icons/eye-slash.svg?url';
import { Tooltip } from '../tooltip';
import './styles.scss';

interface InputSecretProps {
  text: string;
  label?: string;
  onCopy?: () => void;
}

const InputSecret: FunctionComponent<InputSecretProps> = ({ text, label, onCopy }) => {
  const [isSecretVisible, setIsSecretVisible] = useState<boolean>(false);
  const onClickHandler = () => setIsSecretVisible(!isSecretVisible);
  const [wasCopied, setWasCopied] = useState(false);

  const onCopyHandler = () => {
    setWasCopied(true);
    if (onCopy) {
      onCopy();
    }
  };

  return (
    <>
      <div className="input_secret__label">{label}</div>
      <div className="input_secret">
        <InputBase
          className="input_secret__input"
          type={isSecretVisible ? 'text' : 'password'}
          value={text}
          readOnly
          disabled
        />
        <Button variant="contained" disableElevation onClick={onClickHandler}>
          <img src={isSecretVisible ? SecretVisibleIcon : SecretIcon} />
        </Button>
        <CopyToClipboard text={text} onCopy={onCopyHandler}>
          <Tooltip
            onMouseEnter={() => setWasCopied(false)}
            classes={{
              tooltip: `input_secret__tooltip ${wasCopied && ' copied'}`,
              arrow: `input_secret__tooltip__arrow ${wasCopied && ' copied'}`,
            }}
            title={l(wasCopied ? 'common.copied' : 'common.copy')}
          >
            <Button variant="contained" disableElevation>
              <img src={CopyIcon} />
            </Button>
          </Tooltip>
        </CopyToClipboard>
      </div>
    </>
  );
};

export default InputSecret;
