import l from '../../../../lang';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import IntegrationCard from '../integration-card';
import { ReactComponent as MagentoIcon } from '../../../../assets/icons/magento.svg';
import { segmentIntegrationsMenuMagento } from '../../../../utils/analytics';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationCard
      title={l('paymentIntegration.magento.title')}
      icon={<MagentoIcon />}
      color="orange"
      onDetails={() => {
        segmentIntegrationsMenuMagento();
        setDetailsOpened(DetailsOpened.MAGENTO);
      }}
      detailsText={l('paymentIntegration.card.link')}
    />
  );
};
