import { takeLatest, put, call, select } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { getTask } from '../../services';
import { ClientsActionType, getClientsSuccess, getClientsError } from './actions';
import { PanelStore } from '../interfaces';

function* getClients(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const params = `?page=${action.page || 0}`;

  const { response, error } = yield call(getTask, {
    url: Urls.api.allClients.replace(':id', `${state.dashboard.merchantMe?.id || ''}`) + params,
    token: state.auth.token,
  });

  if (response) {
    yield put(getClientsSuccess(response));
  } else {
    yield put(getClientsError(error));
  }
}

export function* getClientsSaga() {
  yield takeLatest(ClientsActionType.GET_CLIENTS, getClients);
}
