import l from '../../lang';
import { operatingCountries } from '../../config';

export const operatingCountriesForSelect = () => {
  const countries: { [key: string]: string } = {};
  operatingCountries.forEach((co: string) => {
    countries[co] = l(`countryCode.${co}`);
  });
  return countries;
};

export const operatingCountriesForAutocomplete = () => {
  return operatingCountries
    .map((co: string) => ({
      label: l(`countryCode.${co}`),
      value: co,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const setDefaultDocumentType = (
  documentTypes: object,
  key: string,
  value: any,
  setFieldValue: (key: string, value: any) => void
) => {
  if (documentTypes) {
    const docs = Object.keys(documentTypes);
    if (docs.length && !docs.filter(d => value === d).length) {
      setFieldValue(key, docs[0]);
    }
  }
};

export const selectLanguages = () => {
  return {
    en: l('common.language.en'),
    es: l('common.language.es'),
    pt: l('common.language.pt'),
  };
};

export const getTextValidCharacters = (text: string) => text.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ0-9\s.@#!%$‘&+*–'\-/=?^_`{|}~]/g, '');
