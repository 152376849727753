import { PanelStore } from '../interfaces';

export namespace HomeSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.home.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.home.error;
  };
  export const getShouldAnimationsRun = (state: PanelStore.RootState) => {
    return state.home.shouldAnimationsRun;
  };
  export const getBalances = (state: PanelStore.RootState) => {
    return state.home.balances;
  };
}
