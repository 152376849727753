import Parser from 'html-react-parser';
import l from '../../../../lang';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import { ReactComponent as ApiIcon } from '../../../../assets/icons/api.svg';
import {
  segmentIntegrationsApiIntegrationPageLinkDocs,
  segmentIntegrationsKeysCopyApiKey,
  segmentIntegrationsKeysCopySecretKey,
} from '../../../../utils/analytics';
import IntegrationDetails from '../integration-details';
import ButtonLink from '../../../common/button-link';
import { openNewTabAndFocus } from '../../../../utils';
import InputSecret from '../../../common/input-secret';
import { Urls } from '../../../../config/urls';

export default () => {
  const { apiKey, secretKey, setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationDetails icon={<ApiIcon />} color="blue" onClose={() => setDetailsOpened(DetailsOpened.NONE)}>
      <div className="integration_details__title">{l('paymentIntegration.api.details.headline')}</div>
      <p>
        <span>{Parser(l('paymentIntegration.api.details.description'))} </span>
        <ButtonLink
          text={l('paymentIntegration.api.details.link')}
          onClick={() => {
            segmentIntegrationsApiIntegrationPageLinkDocs();
            openNewTabAndFocus(Urls.docs.apiIntegration);
          }}
        />
      </p>
      <div className="integration__key">
        <div className="integration__key__title">{l('paymentIntegration.api.details.key.headline')}</div>
        <div>{Parser(l('paymentIntegration.api.details.key.description'))}</div>
        <InputSecret text={apiKey} label="API Key" onCopy={() => segmentIntegrationsKeysCopyApiKey()} />
        <InputSecret text={secretKey} label="Secret Key" onCopy={() => segmentIntegrationsKeysCopySecretKey()} />
      </div>
    </IntegrationDetails>
  );
};
