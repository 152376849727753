import { PanelStore } from '../interfaces';

export namespace SettlementsSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.settlements.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.settlements.error;
  };
  export const getErrorInfo = (state: PanelStore.RootState) => {
    return state.settlements.errorInfo;
  };
  export const getAllSettlements = (state: PanelStore.RootState) => {
    return state.settlements.allSettlements;
  };
  export const getShowRequestSettlement = (state: PanelStore.RootState) => {
    return state.settlements.showRequestSettlement;
  };
  export const getRequestSuccess = (state: PanelStore.RootState) => {
    return state.settlements.requestSuccess;
  };
  export const getBeneficiaries = (state: PanelStore.RootState) => {
    return state.settlements.beneficiaries;
  };
}
