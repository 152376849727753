import { handleErrorInfo } from '../../utils';
import { ErrorAPI } from '../interfaces';
import { ReportsActionType } from './actions';
import { PaymentVolumeResponse, ReportsState, TransactionsSummaryResponse } from './interfaces.d';

const initialState: ReportsState = {
  summary: {
    loading: false,
    error: false,
    balanceCurrency: '',
    tpv: {
      current: 0,
      previous: 0,
      diffPercentage: 0,
    },
    transactionCount: {
      current: 0,
      previous: 0,
      diffPercentage: 0,
    },
    transactionAverageAmount: {
      current: 0,
      previous: 0,
      diffPercentage: 0,
    },
  },
  paymentVolume: {
    loading: false,
    error: false,
    errorInfo: undefined,
    balanceCurrency: '',
    data: [],
  },
  transactionsByCountry: {
    loading: false,
    error: false,
    errorInfo: undefined,
    data: [],
  },
  transactionsByPaymentSolution: {
    loading: false,
    error: false,
    errorInfo: undefined,
    data: [],
  },
  transactionsByPaymentMethod: {
    loading: false,
    error: false,
    errorInfo: undefined,
    data: [],
  },
};

interface ReportsAction {
  type: ReportsActionType;
  payload?: TransactionsSummaryResponse & PaymentVolumeResponse;
  error?: ErrorAPI;
}

const ReportsReducer = (state = initialState, { type, payload, error }: ReportsAction) => {
  switch (type) {
    case ReportsActionType.REPORTS_TRANSACTIONS_SUMMARY: {
      return {
        ...state,
        summary: {
          ...state.summary,
          loading: true,
          error: false,
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_SUMMARY_SUCCESS: {
      return {
        ...state,
        summary: {
          ...state.summary,
          loading: false,
          error: false,
          balanceCurrency: payload?.balanceCurrency,
          tpv: {
            ...state.summary.tpv,
            current: payload?.tpv.current,
            previous: payload?.tpv.previous,
            diffPercentage: payload?.tpv.diffPercentage,
          },
          transactionCount: {
            ...state.summary.transactionCount,
            current: payload?.transactionCount.current,
            previous: payload?.transactionCount.previous,
            diffPercentage: payload?.transactionCount.diffPercentage,
          },
          transactionAverageAmount: {
            ...state.summary.transactionAverageAmount,
            current: payload?.transactionAverageAmount.current,
            previous: payload?.transactionAverageAmount.previous,
            diffPercentage: payload?.transactionAverageAmount.diffPercentage,
          },
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_SUMMARY_ERROR: {
      return {
        ...state,
        summary: {
          ...state.summary,
          loading: false,
          error: true,
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_PAYMENT_VOLUME: {
      return {
        ...state,
        paymentVolume: {
          ...state.paymentVolume,
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_PAYMENT_VOLUME_SUCCESS: {
      return {
        ...state,
        paymentVolume: {
          ...state.paymentVolume,
          loading: false,
          error: false,
          errorInfo: undefined,
          balanceCurrency: payload?.balanceCurrency,
          data: payload?.data,
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_PAYMENT_VOLUME_ERROR: {
      return {
        ...state,
        paymentVolume: {
          ...state.paymentVolume,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(ReportsActionType.REPORTS_TRANSACTIONS_PAYMENT_VOLUME_ERROR, error),
          balanceCurrency: '',
          data: [],
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_BY_COUNTRY: {
      return {
        ...state,
        transactionsByCountry: {
          ...state.transactionsByCountry,
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_BY_COUNTRY_SUCCESS: {
      return {
        ...state,
        transactionsByCountry: {
          ...state.transactionsByCountry,
          loading: false,
          error: false,
          errorInfo: undefined,
          data: payload?.data,
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_BY_COUNTRY_ERROR: {
      return {
        ...state,
        transactionsByCountry: {
          ...state.transactionsByCountry,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(ReportsActionType.REPORTS_TRANSACTIONS_BY_COUNTRY_ERROR, error),
          data: [],
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_SOLUTION: {
      return {
        ...state,
        transactionsByPaymentSolution: {
          ...state.transactionsByPaymentSolution,
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_SOLUTION_SUCCESS: {
      return {
        ...state,
        transactionsByPaymentSolution: {
          ...state.transactionsByPaymentSolution,
          loading: false,
          error: false,
          errorInfo: undefined,
          data: payload?.data,
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_SOLUTION_ERROR: {
      return {
        ...state,
        transactionsByPaymentSolution: {
          ...state.transactionsByPaymentSolution,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_SOLUTION_ERROR, error),
          data: [],
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_METHOD: {
      return {
        ...state,
        transactionsByPaymentMethod: {
          ...state.transactionsByPaymentMethod,
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        transactionsByPaymentMethod: {
          ...state.transactionsByPaymentMethod,
          loading: false,
          error: false,
          errorInfo: undefined,
          data: payload?.data,
        },
      };
    }
    case ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_METHOD_ERROR: {
      return {
        ...state,
        transactionsByPaymentMethod: {
          ...state.transactionsByPaymentMethod,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_METHOD_ERROR, error),
          data: [],
        },
      };
    }
    default:
      return state;
  }
};

export default ReportsReducer;
