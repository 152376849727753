import { DetailsOpened } from "./interfaces";

export enum PaymentIntegrationType {
  GET_INTEGRATION_KEYS = 'PAYMENT_INTEGRATION/GET_INTEGRATION_KEYS',
  GET_INTEGRATION_KEYS_SUCCESS = 'PAYMENT_INTEGRATION/GET_INTEGRATION_KEYS_SUCCESS',
  GET_INTEGRATION_KEYS_ERROR = 'PAYMENT_INTEGRATION/GET_INTEGRATION_KEYS_ERROR',
  GET_CURRENCIES_PI = 'PAYMENT_INTEGRATION/GET_CURRENCIES_PI',
  GET_CURRENCIES_PI_SUCCESS = 'PAYMENT_INTEGRATION/GET_CURRENCIES_PI_SUCCESS',
  GET_CURRENCIES_PI_ERROR = 'PAYMENT_INTEGRATION/GET_CURRENCIES_PI_ERROR',
  SET_SHOPIFY_TEST_MODE = 'PAYMENT_INTEGRATION/SET_SHOPIFY_TEST_MODE',
  SET_SHOPIFY_TEST_MODE_SUCCESS = 'PAYMENT_INTEGRATION/SET_SHOPIFY_TEST_MODE_SUCCESS',
  SET_SHOPIFY_TEST_MODE_ERROR = 'PAYMENT_INTEGRATION/SET_SHOPIFY_TEST_MODE_ERROR',
  SET_DETAILS_OPENED = 'PAYMENT_INTEGRATION/SET_DETAILS_OPENED',
}

export const getIntegrationKeys = () => ({
  type: PaymentIntegrationType.GET_INTEGRATION_KEYS,
});

export const getIntegrationKeysSuccess = (response: any) => ({
  type: PaymentIntegrationType.GET_INTEGRATION_KEYS_SUCCESS,
  response,
});

export const getIntegrationKeysError = (error: any) => ({
  type: PaymentIntegrationType.GET_INTEGRATION_KEYS_ERROR,
  error,
});

export const getCurrenciesPI = (country: string) => ({
  type: PaymentIntegrationType.GET_CURRENCIES_PI,
  country,
});

export const getCurrenciesPISuccess = (response: any) => ({
  type: PaymentIntegrationType.GET_CURRENCIES_PI_SUCCESS,
  response,
});

export const getCurrenciesPIError = (error: any) => ({
  type: PaymentIntegrationType.GET_CURRENCIES_PI_ERROR,
  error,
});

export const setShopifyTestMode = (testMode: boolean) => ({
  type: PaymentIntegrationType.SET_SHOPIFY_TEST_MODE,
  testMode,
});

export const setShopifyTestModeSuccess = () => ({
  type: PaymentIntegrationType.SET_SHOPIFY_TEST_MODE_SUCCESS,
});

export const setShopifyTestModeError = (error: any) => ({
  type: PaymentIntegrationType.SET_SHOPIFY_TEST_MODE_ERROR,
  error,
});

export const setDetailsOpen = (details: DetailsOpened) => ({
  type: PaymentIntegrationType.SET_DETAILS_OPENED,
  payload: details,
});
