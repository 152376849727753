import { MerchantFeatures } from '../../../../../constants';
import { HasFeatureFunction } from '../../../../../hooks/use-features';
import l, { langKeyExists } from '../../../../../lang';
import Parser from 'html-react-parser';

export interface InfoLabelProps {
  isCompany: boolean;
  country?: string;
  documentType?: string;
  name?: string;
  lastName?: string;
}

export default {
  header: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) => l('companyInformation.onboarding.title.part1'),

  subheader: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? l(physicalLabel(`companyInformation.onboarding.title.part2`, isCompany))
      : l('companyInformation.onboarding.title.part2'),

  title: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? l(physicalLabel(`companyInformation.kyc.companyForm.title`, isCompany))
      : l('companyInformation.kyc.companyForm.title'),

  subtitle: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? l(companyAndPhysicalLabel(`companyInformation.kyc.companyForm.description`))
      : l('companyInformation.kyc.companyForm.description'),

  companyNameOrBrand: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? l(physicalLabel(`companyInformation.kyc.companyNameOrBrand`, isCompany))
      : l('companyInformation.kyc.companyNameOrBrand'),

  subIndustry: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? l(companyAndPhysicalLabel('companyInformation.kyc.subIndustry'))
      : l('companyInformation.kyc.subIndustry'),

  websiteTooltip: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? l(companyAndPhysicalLabel('companyInformation.kyc.infopopup.website.1'))
      : l('companyInformation.kyc.infopopup.website.1'),

  nameTooltip: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    l(companyOrPhysicalLabel('companyInformation.kyc.infopopup.legalName', isCompany)),

  name: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    l(companyOrPhysicalLabel('companyInformation.kyc.name', isCompany)),

  countryTooltip: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    l(companyOrPhysicalLabel('companyInformation.kyc.infopopup.country', isCompany)),

  country: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? l(physicalLabel('companyInformation.kyc.companyOriginCountry', isCompany))
      : l('companyInformation.kyc.companyOriginCountry'),

  step2Headline: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? l(companyOrPhysicalLabel(`companyInformation.kycTwo.headline`, isCompany))
      : l('companyInformation.kycTwo.headline'),

  step2Subheadline: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? companyOrPhysicalLabel(`companyInformation.kycTwo.subheadline`, isCompany)
      : 'companyInformation.kycTwo.subheadline',

  step2IdentityDocumentHeadline: (hasFeature: HasFeatureFunction, { isCompany, name, lastName }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? l(physicalLabel('companyInformation.kycTwo.companyOwnerDocuments', isCompany), name, lastName)
      : l('companyInformation.kycTwo.companyOwnerDocuments'),

  step2IdentityDocument: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    l(companyAndPhysicalLabel('companyInformation.kycTwo.identityDocument')),

  step2IdentityDocumentHelp: (hasFeature: HasFeatureFunction, { isCompany, name, lastName }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? Parser(l(companyOrPhysicalLabel('companyInformation.kycTwo.identityDocumentHelp', isCompany), name, lastName))
      : l('companyInformation.kycTwo.identityDocumentHelp'),

  step2TaxCompliantDocumentHeadline: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    l(companyOrPhysicalLabel('companyInformation.kycTwo.companyDocuments', isCompany)),

  step2TaxCompliantDocument: (hasFeature: HasFeatureFunction, { isCompany, country }: InfoLabelProps) =>
    l(countryLabel(physicalLabel('companyInformation.kycTwo.taxCompliantDocument', isCompany), country as string)),

  step2TaxCompliantDocumentDescription: (
    hasFeature: HasFeatureFunction,
    { isCompany, documentType, name, lastName }: InfoLabelProps
  ) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? isCompany
        ? Parser(l(companyOrPhysicalLabel('companyInformation.kycTwo.taxCompliantDocumentHelp', isCompany), documentType))
        : Parser(
            l(
              companyOrPhysicalLabel('companyInformation.kycTwo.taxCompliantDocumentHelp', isCompany),
              name,
              lastName,
              documentType
            )
          )
      : '',
  step2ShareholdersDocument: (hasFeature: HasFeatureFunction, { country }: InfoLabelProps) =>
    l(countryLabel('companyInformation.kycTwo.shareholdersDocumentHelp', country as string)),

  step2ShareholdersDocumentHelp: (hasFeature: HasFeatureFunction, { country }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? Parser(
          l(countryLabel(companyAndPhysicalLabel('companyInformation.kycTwo.shareholdersDocumentHelp'), country as string))
        )
      : l('companyInformation.kycTwo.shareholdersDocumentHelp'),

  step2ProofOfAddressDocument: (hasFeature: HasFeatureFunction, { isCompany }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? l(companyAndPhysicalLabel('companyInformation.kycTwo.proofOfAddressDocument'))
      : l('companyInformation.kycTwo.proofOfAddressDocument'),

  step2ProofOfAddressDocumentHelp: (
    hasFeature: HasFeatureFunction,
    { isCompany, country, name, lastName }: InfoLabelProps
  ) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? Parser(l(companyOrPhysicalLabel('companyInformation.kycTwo.proofOfAddressDocumentHelp', isCompany), name, lastName))
      : isCompany
      ? l(countryLabel('companyInformation.kycTwo.proofOfAddressDocumentHelp', country as string))
      : l(countryLabel('companyInformation.kycTwo.proofOfAddressDocumentPhysicalHelp', country as string)),

  step2BankAccountDocumentation: (hasFeature: HasFeatureFunction, { isCompany, country }: InfoLabelProps) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? l(countryLabel('companyInformation.kycFour.bankAccountDocument', country as string))
      : l('companyInformation.kycTwo.bankAccountDocumentation'),

  step2BankAccountDocumentationHelp: (
    hasFeature: HasFeatureFunction,
    { isCompany, name, lastName, country }: InfoLabelProps
  ) =>
    hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
      ? Parser(l(companyOrPhysicalLabel('companyInformation.kycFour.bankAccountDocumentHelp', isCompany), name, lastName))
      : l(countryLabel('companyInformation.kycFour.bankAccountDocument', country as string)),
};

export function companyOrPhysicalLabel(key: string, isCompany: boolean) {
  return `${key}.${isCompany ? 'company' : 'physical'}`;
}

export function physicalLabel(key: string, isCompany: boolean) {
  console.log(isCompany);
  return `${key}${isCompany ? '' : '.physical'}`;
}

export function companyAndPhysicalLabel(key: string) {
  return `${key}.company/physical`;
}

export function countryLabel(key: string, country: string) {
  return langKeyExists(`${key}.${country}`) ? `${key}.${country}` : key;
}
