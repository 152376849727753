import { useHistory } from 'react-router-dom';

import Parser from 'html-react-parser';

import l from '../../../../lang';

import { Routes } from '../../../../config/routes';

import ButtonLink from '../../../common/button-link';
import CardTitle from '../../../common/card-title';
import Card from '../../../common/card';
import CardDescription from '../../../common/card-description';
import { segmentHomeKycStep2Complete } from '../../../../utils/analytics';

const ReminderStepTwo = () => {
  const history = useHistory();

  const onCompanyInformationHandler = () => {
    segmentHomeKycStep2Complete();
    history.push(Routes.COMPANY_INFORMATION);
  };

  return (
    <Card>
      <CardTitle>{l('home.reminder.title')}</CardTitle>
      <CardDescription>
        <ButtonLink text={l('home.reminder.link')} onClick={onCompanyInformationHandler} />
        {Parser(l('home.reminder.desc'))}
      </CardDescription>
    </Card>
  );
};

export default ReminderStepTwo;
