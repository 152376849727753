import { MenuCodes } from '../../constants';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as ReportsIcon } from '../../assets/icons/reports.svg';
import { ReactComponent as ActiveIcon } from '../../assets/icons/active.svg';
import { ReactComponent as PaymentMethodsIcon } from '../../assets/icons/payment-methods.svg';
import { ReactComponent as TransactionsIcon } from '../../assets/icons/transactions.svg';
import { ReactComponent as SettlementIcon } from '../../assets/icons/settlement.svg';
import { ReactComponent as PaymentLinkIcon } from '../../assets/icons/payment-link.svg';
import { ReactComponent as PaymentLinkCreateIcon } from '../../assets/icons/payment-link-create.svg';
import { ReactComponent as PaymentLinkTableIcon } from '../../assets/icons/payment-link-table.svg';
import { ReactComponent as VirtualPOSIcon } from '../../assets/icons/virtual-pos.svg';
import { ReactComponent as PaymentButtonIcon } from '../../assets/icons/payment-button.svg';
import { ReactComponent as PaymentIntegrationIcon } from '../../assets/icons/payment-integration.svg';
import { ReactComponent as CompanyInfoIcon } from '../../assets/icons/company-info.svg';
import { ReactComponent as RefundsIcon } from '../../assets/icons/refunds.svg';
import { ReactComponent as ClientsIcon } from '../../assets/icons/clients.svg';
import { ReactComponent as BankAccountsIcon } from '../../assets/icons/bank-accounts.svg';
import { ReactComponent as SubItemIcon } from '../../assets/icons/sub-item.svg';
import { ReactComponent as SubscriptionsIcon } from '../../assets/icons/subscriptions.svg';

export const icons = (key: string) => {
  switch (key) {
    case MenuCodes.HOME:
      return <HomeIcon />;
    case MenuCodes.REPORTS:
      return <ReportsIcon />;
    case MenuCodes.ACTIVATE_ACCOUNT:
      return <ActiveIcon />;
    case MenuCodes.TRANSACTIONS:
      return <TransactionsIcon />;
    case MenuCodes.PAYMENT_METHODS:
      return <PaymentMethodsIcon />;
    case MenuCodes.SETTLEMENTS:
      return <SettlementIcon />;
    case MenuCodes.PAYMENT_LINK:
      return <PaymentLinkIcon />;
    case MenuCodes.CREATED_PAYMENT_LINKS:
      return <PaymentLinkTableIcon />;
    case MenuCodes.CREATE_ONE_TIME_PAYMENT_LINK:
      return <PaymentLinkCreateIcon />;
    case MenuCodes.VIRTUAL_POS:
      return <VirtualPOSIcon />;
    case MenuCodes.PAYMENT_BUTTON:
      return <PaymentButtonIcon />;
    case MenuCodes.PAYMENT_INTEGRATION:
      return <PaymentIntegrationIcon />;
    case MenuCodes.COMPANY_INFORMATION:
      return <CompanyInfoIcon />;
    case MenuCodes.REFUNDS:
      return <RefundsIcon />;
    case MenuCodes.CLIENTS:
      return <ClientsIcon />;
    case MenuCodes.BANK_ACCOUNTS:
      return <BankAccountsIcon />;
    case MenuCodes.SUBSCRIPTIONS:
      return <SubscriptionsIcon />;
    default:
      return <SubItemIcon />;
  }
};
