import { Values } from '../interfaces';

export enum VirtualPosActionType {
  INIT = 'VIRTUAL_POS/INIT',
  GET_CURRENCIES = 'VIRTUAL_POS/GET_CURRENCIES',
  GET_CURRENCIES_SUCCESS = 'VIRTUAL_POS/GET_CURRENCIES_SUCCESS',
  GET_CURRENCIES_ERROR = 'VIRTUAL_POS/GET_CURRENCIES_ERROR',
  GET_DOCUMENT_TYPES = 'VIRTUAL_POS/GET_DOCUMENT_TYPES',
  GET_DOCUMENT_TYPES_SUCCESS = 'VIRTUAL_POS/GET_DOCUMENT_TYPES_SUCCESS',
  GET_DOCUMENT_TYPES_ERROR = 'VIRTUAL_POS/GET_DOCUMENT_TYPES_ERROR',
  CREATE_PAYMENT = 'VIRTUAL_POS/CREATE_PAYMENT',
  CREATE_PAYMENT_ERROR = 'VIRTUAL_POS/CREATE_PAYMENT_ERROR',
  CREATE_PAYMENT_SUCCESS = 'VIRTUAL_POS/CREATE_PAYMENT_SUCCESS',
  CONFIRM_PAYMENT = 'VIRTUAL_POS/CONFIRM_PAYMENT',
  CONFIRM_PAYMENT_ERROR = 'VIRTUAL_POS/CONFIRM_PAYMENT_ERROR',
  CONFIRM_PAYMENT_SUCCESS = 'VIRTUAL_POS/CONFIRM_PAYMENT_SUCCESS',
  PAYMENT_RESULT = 'VIRTUAL_POS/PAYMENT_RESULT',
}

export const init = () => ({
  type: VirtualPosActionType.INIT,
});

export const getCurrencies = (country: string) => ({
  type: VirtualPosActionType.GET_CURRENCIES,
  country,
});

export const getCurrenciesSuccess = (response: any) => ({
  type: VirtualPosActionType.GET_CURRENCIES_SUCCESS,
  response,
});

export const getCurrenciesError = (error: any) => ({
  type: VirtualPosActionType.GET_CURRENCIES_ERROR,
  error,
});

export const getDocumentTypes = (country: string) => ({
  type: VirtualPosActionType.GET_DOCUMENT_TYPES,
  country,
});

export const getDocumentTypesSuccess = (response: any) => ({
  type: VirtualPosActionType.GET_DOCUMENT_TYPES_SUCCESS,
  response,
});

export const getDocumentTypesError = (error: any) => ({
  type: VirtualPosActionType.GET_DOCUMENT_TYPES_ERROR,
  error,
});

export const createPayment = (values: Values) => ({
  type: VirtualPosActionType.CREATE_PAYMENT,
  values,
});

export const createPaymentSuccess = (response: any) => ({
  type: VirtualPosActionType.CREATE_PAYMENT_SUCCESS,
  response,
});

export const createPaymentError = (error: any) => ({
  type: VirtualPosActionType.CREATE_PAYMENT_ERROR,
  error,
});

export const confirmPayment = (values: Values, paymentMethodId: number) => ({
  type: VirtualPosActionType.CONFIRM_PAYMENT,
  values,
  paymentMethodId,
});

export const confirmPaymentSuccess = (response: any) => ({
  type: VirtualPosActionType.CONFIRM_PAYMENT_SUCCESS,
  response,
});

export const confirmPaymentError = (error: any) => ({
  type: VirtualPosActionType.CONFIRM_PAYMENT_ERROR,
  error,
});

export const paymentResult = (show: boolean) => ({
  type: VirtualPosActionType.PAYMENT_RESULT,
  show,
});
