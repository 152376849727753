import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LanguageSelect from '../../components/language-select';
import Logo from '../../components/common/logo';
import PasswordResetForm from '../../components/password-reset-form';
import { languages, getLanguage } from '../../utils/index';
import { Routes } from '../../config/routes';
import l from '../../lang';
import './styles.scss';
import HelpButton from '../../components/common/help-button';
import ButtonLink from '../../components/common/button-link';
import { initPasswordReset, sendPasswordReset, confirmPaswordReset } from '../../store/password-reset/actions';
import { useCommon } from '../../store/common';

const PasswordReset = ({
  requestNewPassword,
  loading,
  error,
  errorInfo,
  emailSent,
  confirmSuccess,
  fnInitPasswordReset,
  fnSendPasswordReset,
  fnConfirmPasswordReset,
}) => {
  const { changeLanguage } = useCommon();
  const [init, setInit] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (init && confirmSuccess) {
      history.push(Routes.LOGIN);
    }
    // eslint-disable-next-line
  }, [confirmSuccess]);

  useEffect(() => {
    fnInitPasswordReset();
    setInit(true);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="password-reset">
      <div className="home-content">
        <div className="help-button-container">
          <HelpButton />
        </div>
        <div className="form-content">
          <div className="language-container">
            <LanguageSelect languages={languages} selected={getLanguage()} onClick={changeLanguage} />
          </div>
          <Logo size="xl" type="vertical" />
          {emailSent && <div className="password-reset-desc -email-sent">{l('passwordReset.sent.message')}</div>}
          {((!requestNewPassword && !emailSent) || (requestNewPassword && !confirmSuccess)) && (
            <>
              <div className="password-reset-desc">
                {requestNewPassword ? l('passwordReset.confirmDesc') : l('passwordReset.desc')}
              </div>
              <PasswordResetForm
                requestNewPassword={requestNewPassword}
                error={error}
                errorInfo={errorInfo}
                loading={loading}
                fnSubmit={requestNewPassword ? fnConfirmPasswordReset : fnSendPasswordReset}
              />
            </>
          )}
          <div className="link-login">
            <ButtonLink text={l('passwordReset.back')} onClick={() => history.push(Routes.LOGIN)} />
          </div>
        </div>
      </div>
    </div>
  );
};

PasswordReset.propTypes = {
  requestNewPassword: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  emailSent: PropTypes.bool,
  confirmSuccess: PropTypes.bool,
  fnSendPasswordReset: PropTypes.func,
  fnConfirmPasswordReset: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.passwordReset.loading,
  error: state.passwordReset.error,
  errorInfo: state.passwordReset.errorInfo,
  emailSent: state.passwordReset.success,
  confirmSuccess: state.passwordReset.confirmSuccess,
});

const mapDispatchToProps = dispatch => ({
  fnInitPasswordReset: () => dispatch(initPasswordReset()),
  fnSendPasswordReset: email => dispatch(sendPasswordReset(email)),
  fnConfirmPasswordReset: (password, token) => dispatch(confirmPaswordReset(password, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
