import { useDispatch, useSelector } from 'react-redux';
import { ExportType, Filters } from '../interfaces';
import {
  exportTransactions,
  getPaymentMethodsForTrans,
  getPreChargebackForm,
  setTransactionDetail,
  getTransactionDetail,
  getTransactions,
  showExportTransactions,
} from './actions';
import { Transaction } from './interfaces';
import { TransactionsSelectors } from './selectors';

export const useTransactions = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(TransactionsSelectors.getLoading),
    error: useSelector(TransactionsSelectors.getError),
    allTransactions: useSelector(TransactionsSelectors.getAllTransactions),
    transactionDetail: useSelector(TransactionsSelectors.getTransactionDetail),
    export: useSelector(TransactionsSelectors.getExport),
    exportType: useSelector(TransactionsSelectors.getExportType),
    paymentMethods: useSelector(TransactionsSelectors.getPaymentMethods),
    getTransactions: (page?: number, filter?: Filters) => dispatch(getTransactions(page, filter)),
    setTransactionDetail: (data: Transaction) => dispatch(setTransactionDetail(data)),
    getTransactionDetail: (invoiceId: string) => dispatch(getTransactionDetail(invoiceId)),
    getPaymentMethodsForTrans: (country: string) => dispatch(getPaymentMethodsForTrans(country)),
    getPreChargebackForm: (invoiceId: string) => dispatch(getPreChargebackForm(invoiceId)),
    showExportTransactions: (show: boolean) => dispatch(showExportTransactions(show)),
    exportTransactions: (filter: string, type: ExportType) => dispatch(exportTransactions(filter, type)),
  };
};
