export enum JumioStatusType {
  UNKNOWN = 'UNKNOWN',
  NOT_EXECUTED = 'NOT_EXECUTED',
  PASSED = 'PASSED',
  REJECTED = 'REJECTED',
  WARNING = 'WARNING',
  PENDING = 'PENDING',
}

export interface JumioStatus {
  status?: JumioStatusType;
  limitReached?: boolean;
  tryCount?: number;
}

export interface JumioState extends JumioStatus {
  loading: boolean;
  url?: string;
  displayMessageKycStep1: boolean;
}

export interface StartResponse {
  url: string;
}
