import { useEffect } from 'react';
import l from '../../../../lang';
import CreateForm from './create-form';
import Loading from '../../../common/loading';
import TopDashboardSection from '../../../common/top-dashboard-section';
import ButtonLink from '../../../common/button-link';
import { Routes } from '../../../../config/routes';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../../assets/icons/back.svg';
import QuickGuideSteps from '../quick-guide-steps';
import './styles.scss';
import { usePaymentLink } from '../../../../store/payment-link';

interface CreatePaymentLinkProps {
  isRecurring?: boolean;
}

const CreatePaymentLink = ({ isRecurring }: CreatePaymentLinkProps) => {
  const { loading, initPaymentLink } = usePaymentLink();
  const history = useHistory();

  useEffect(() => {
    initPaymentLink();
  }, []);

  return (
    <div className="payment-link">
      {!isRecurring && <QuickGuideSteps />}
      <TopDashboardSection
        title={l('paymentLink.title')}
        componentBottom={
          <div className="payment-link__back_btn">
            <ButtonLink
              type="secondary"
              text={
                <>
                  <BackIcon /> {l('paymentLink.backBtn')}
                </>
              }
              onClick={() => history.push(Routes.CREATED_PAYMENT_LINKS)}
            />
          </div>
        }
      />
      {loading && <Loading fullScreen />}
      <CreateForm isRecurring={isRecurring} />
    </div>
  );
};

export default CreatePaymentLink;
