import { PanelStore } from '../interfaces';

export namespace SubscriptionsSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.subscriptions.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.subscriptions.error;
  };
  export const getErrorInfo = (state: PanelStore.RootState) => {
    return state.subscriptions.errorInfo;
  };
  export const getAllSubscriptions = (state: PanelStore.RootState) => {
    return state.subscriptions.allSubscriptions;
  };
  export const getDetails = (state: PanelStore.RootState) => {
    return state.subscriptions.details;
  };
  export const getAllPlans = (state: PanelStore.RootState) => {
    return state.subscriptions.allPlans;
  };
  export const getCurrencies = (state: PanelStore.RootState) => {
    return state.subscriptions.currencies;
  };
  export const getExchangeRates = (state: PanelStore.RootState) => {
    return state.paymentLink.exchangeRates;
  };
  export const createUpdatePlanSuccess = (state: PanelStore.RootState) => {
    return state.subscriptions.createUpdatePlanSuccess;
  };
  export const getPlanDetails = (state: PanelStore.RootState) => {
    return state.subscriptions.planDetails;
  };
  export const getDeactivateSubscription = (state: PanelStore.RootState) => {
    return state.subscriptions.deactivateSubscription;
  };
  export const getDeactivatePlan = (state: PanelStore.RootState) => {
    return state.subscriptions.deactivatePlan;
  };
}
