import './styles.scss';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';
import { getOptions } from './config';
import { useTheme } from '@mui/material';
import { getLanguage } from '../../../../../utils';
import l from '../../../../../lang';
import TooltipIcon from '../../../../common/tooltip-icon';
import { ReactComponent as HelpIcon } from '../../../../../assets/icons/question-circle.svg';
import { useReports } from '../../../../../store/reports';
import { useEffect } from 'react';
import Loading from '../../../../common/loading';
import ErrorRetry from '../../error-retry';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface PaymentVolumeProps {
  period: number;
}

const PaymentVolume = ({ period }: PaymentVolumeProps) => {
  const theme = useTheme();
  const { paymentVolume, getPaymentVolume } = useReports();
  const { loading, error, balanceCurrency, data } = paymentVolume;

  useEffect(() => {
    getPaymentVolume(period);
  }, [period]);

  const emptyChart = !data.length || (data.length === 1 && !balanceCurrency);
  const noTransactionLabel = [l('reports.transactionsBy.empty')];

  const chartData: ChartData<'line'> = {
    labels: !emptyChart ? data.map(item => moment(item.date).locale(getLanguage()).format('MMM DD')) : noTransactionLabel,
    datasets: [
      {
        data: !emptyChart ? data.map(item => item.amount) : [],
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
      },
    ],
  };

  return (
    <div className="payment_volume_over_time">
      <div className="payment_volume_over_time__title">
        <span>{l('reports.paymentVolume.title')}</span>
        <TooltipIcon
          placement="right"
          text={l('reports.paymentVolume.tooltip')}
          icon={<HelpIcon width={21} height={21} color="primary" />}
        />
      </div>
      <div className="payment_volume_over_time__chart">
        {error && <ErrorRetry onRetry={() => getPaymentVolume(period)} />}
        {loading && <Loading />}
        {!loading && !error && <Line options={getOptions(balanceCurrency, emptyChart)} data={chartData} />}
      </div>
    </div>
  );
};

export default PaymentVolume;
