import { Card } from '@mui/material';
import { FunctionComponent } from 'react';
import { ReactComponent as HelpIcon } from '../../../../../assets/icons/question-circle.svg';
import Loading from '../../../../common/loading';
import TooltipIcon from '../../../../common/tooltip-icon';
import ErrorRetry from '../../error-retry';
import './styles.scss';

interface DataCardProps {
  title: string;
  value: string;
  comparation: {
    type: 'up' | 'down' | 'equal';
    value: string;
    description: string;
  };
  tooltip: string;
  loading?: boolean;
  error?: boolean;
  onRetry: () => void;
}

const DataCard: FunctionComponent<DataCardProps> = ({ title, value, comparation, tooltip, loading, error, onRetry }) => (
  <Card classes={{ root: 'reports_data_card' }}>
    <div className="reports_data_card__header">
      <div className="reports_data_card__title">{title}</div>
      <TooltipIcon placement="right" text={tooltip} icon={<HelpIcon color="primary" width={21} height={21} />} />
    </div>
    {error && <ErrorRetry onRetry={onRetry} />}
    {loading && <Loading />}
    {!loading && !error && (
      <>
        <div className="reports_data_card__value">{value}</div>
        <div className="reports_data_card__comparation">
          {comparation.type === 'up' && <span className="reports_data_card__comparation__up">▲</span>}
          {comparation.type === 'down' && <span className="reports_data_card__comparation__down">▼</span>}
          {comparation.type === 'equal' && <span className="reports_data_card__comparation__equal">━</span>}
          {comparation.value}
          <span className="reports_data_card__comparation__description">{comparation.description}</span>
        </div>
      </>
    )}
  </Card>
);

export default DataCard;
