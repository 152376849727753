export enum HomeActionType {
  GET_BALANCES = 'HOME/GET_BALANCES',
  GET_BALANCES_SUCCESS = 'HOME/GET_BALANCES_SUCCESS',
  GET_BALANCES_ERROR = 'HOME/GET_BALANCES_ERROR',
  PUT_SHOULD_ANIMATIONS_RUN = 'HOME/PUT_SHOULD_ANIMATIONS_RUN',
}

export const getBalances = () => ({
  type: HomeActionType.GET_BALANCES,
});

export const getBalancesSuccess = (response: any) => ({
  type: HomeActionType.GET_BALANCES_SUCCESS,
  response,
});

export const getBalancesError = (error: any) => ({
  type: HomeActionType.GET_BALANCES_ERROR,
  error,
});

export const putShouldAnimationsRun = (shouldRun: boolean) => ({
  type: HomeActionType.PUT_SHOULD_ANIMATIONS_RUN,
  shouldRun,
});
