import { handleErrorInfo } from '../../utils';
import { EmailValidationActionType } from './actions';
import { EmailValidationState } from './interfaces';

const initialState: EmailValidationState = {
  loading: false,
  error: false,
  success: false,
  resend: false,
  confirm: {
    loading: false,
    error: false,
    errorInfo: undefined,
    success: false,
  },
};

const EmailValidationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case EmailValidationActionType.SEND_EMAIL_VALIDATION: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
        success: false,
        resend: action.resend || false,
      };
    }
    case EmailValidationActionType.SEND_EMAIL_VALIDATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        success: true,
      };
    }
    case EmailValidationActionType.SEND_EMAIL_VALIDATION_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: undefined,
        success: false,
      };
    }
    case EmailValidationActionType.CONFIRM_EMAIL: {
      return {
        ...state,
        confirm: {
          loading: true,
          error: false,
          errorInfo: undefined,
          success: false,
        },
      };
    }
    case EmailValidationActionType.CONFIRM_EMAIL_SUCCESS: {
      return {
        ...state,
        confirm: {
          loading: false,
          error: false,
          errorInfo: undefined,
          success: true,
        },
      };
    }
    case EmailValidationActionType.CONFIRM_EMAIL_ERROR: {
      return {
        ...state,
        confirm: {
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(EmailValidationActionType.CONFIRM_EMAIL_ERROR, action.error),
          success: false,
        },
      };
    }
    default:
      return state;
  }
};

export default EmailValidationReducer;
