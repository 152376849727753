import { useHistory } from 'react-router-dom';

import l from '../../../../lang';

import { Routes } from '../../../../config/routes';

import ButtonLink from '../../../common/button-link';
import CardTitle from '../../../common/card-title';
import Card from '../../../common/card';
import CardDescription from '../../../common/card-description';

interface OnboardingCardProps {
  shouldAnimationsRun: boolean;
}

const OnboardingCard = ({ shouldAnimationsRun }: OnboardingCardProps) => {
  const history = useHistory();

  return (
    <Card>
      <CardTitle>{l('home.onboarding.title')}</CardTitle>
      <CardDescription>
        {l('home.onboarding.desc')}
        <ButtonLink text={l('home.onboarding.link')} onClick={() => history.push(Routes.COMPANY_INFORMATION)} />
        {l('home.onboarding.desc2')}
      </CardDescription>
    </Card>
  );
};

export default OnboardingCard;
