import { IconButton } from '@mui/material';
import WhatsApp from '../../../../../assets/icons/social-media/whatsapp.svg?url';
import Instagram from '../../../../../assets/icons/social-media/instagram.svg?url';
import Facebook from '../../../../../assets/icons/social-media/facebook.svg?url';
import Mobile from '../../../../../assets/icons/social-media/mobile.svg?url';
import { isMobile } from 'react-device-detect';
import { redirecTo, openNewTabAndFocus } from '../../../../../utils';
import l from '../../../../../lang';
import './styles.scss';
import { Tooltip } from '../../../../common/tooltip';
import {
  segmentPaymentLinkLinkBoxShareMobile,
  segmentPaymentLinkLinkBoxShareWhatsApp,
  segmentPaymentLinkShareFacebook,
} from '../../../../../utils/analytics';

interface ShareButtonsProps {
  link: string;
}

const ShareButtons = ({ link }: ShareButtonsProps) => {
  const onWhatsAppHandler = () => {
    segmentPaymentLinkLinkBoxShareWhatsApp();
    if (isMobile) {
      redirecTo(`https://wa.me/?text=${l('paymentLink.shareLink')} ${link}`);
    } else {
      openNewTabAndFocus(`https://web.whatsapp.com/send?text=${l('paymentLink.shareLink')} ${link}`);
    }
  };

  const onFacebookHandler = () => {
    segmentPaymentLinkShareFacebook();
    openNewTabAndFocus(`http://www.facebook.com/sharer/sharer.php?u=${link}`);
  };

  const onMobileHandler = () => {
    segmentPaymentLinkLinkBoxShareMobile();
    navigator
      .share({
        title: l('paymentLink.shareLink'),
        url: `${link}`,
      })
      .then(() => {})
      .catch(err => {});
  };

  return (
    <div className="share-buttons">
      <div className="payment-link__share__subheadline">{l('paymentLink.shareLink.subheadline2')}</div>
      <div className="share-buttons__content">
        <div className="share-buttons__buton">
          <Tooltip title="Whatsapp">
            <IconButton onClick={onWhatsAppHandler}>
              <img src={WhatsApp} />
            </IconButton>
          </Tooltip>
        </div>
        <div className="share-buttons__buton">
          <Tooltip title="Facebook">
            <IconButton onClick={onFacebookHandler}>
              <img src={Facebook} />
            </IconButton>
          </Tooltip>
        </div>
        {
          // @ts-ignore
          navigator.share && (
            <div className="share-buttons__buton">
              <IconButton onClick={onMobileHandler}>
                <img src={Mobile} />
              </IconButton>
              <div className="share-buttons__desc">{l('paymentLink.shareLink.moreOptions')}</div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default ShareButtons;
