import l from '../../../../lang';
import IntegrationCard from '../integration-card';
import { ReactComponent as HostingerIcon } from '../../../../assets/icons/hostinger.svg';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import { segmentIntegrationsMenuHostinger } from '../../../../utils/analytics';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationCard
      title={l('paymentIntegration.hostinger.title')}
      icon={<HostingerIcon />}
      color="magenta"
      onDetails={() => {
        segmentIntegrationsMenuHostinger();
        setDetailsOpened(DetailsOpened.HOSTINGER);
      }}
      detailsText={l('paymentIntegration.card.link')}
    />
  );
};
