import { useEffect, useState } from 'react';
import { Refresh } from '@mui/icons-material';
import Loading from '../../../../common/loading';
import Table from '../../../../common/table';
import { Urls } from '../../../../../config/urls';
import l from '../../../../../lang';
import './styles.scss';
import MessageError from '../../../../common/message-error';
import { usePaymentLink } from '../../../../../store/payment-link';
import ChipStatus from '../../../../common/chip-status';
import CopyToClipboard from 'react-copy-to-clipboard';
import IconButton from '../../../../common/icon-button';
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copy.svg';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { PaymentStatus } from '../../../../../constants';
import { Tooltip } from '../../../../common/tooltip';
import { ReactComponent as EmptyIcon } from '../../../../../assets/icons/empty-payment-links.svg';
import ButtonLink from '../../../../common/button-link';
import { Routes } from '../../../../../config/routes';
import { segmentPaymentLinkLinkListCopyLink } from '../../../../../utils/analytics';

const CreatedOneTimePaymentLink = () => {
  const history = useHistory();

  const [wasCopied, setWasCopied] = useState(false);

  const { createdOneTimePaymentLinks, getCreatedOneTimePaymentLinks } = usePaymentLink();
  const { loading, error, allPaymentLinks } = createdOneTimePaymentLinks;

  useEffect(() => {
    getCreatedOneTimePaymentLinks();
  }, []);

  const dataExist = allPaymentLinks && allPaymentLinks?.data?.length > 0;

  return (
    <div className="created_one_time_payment_link">
      {loading && (
        <div className="created_one_time_payment_link__loading">
          <Loading />
        </div>
      )}
      {error && (
        <div className="created_one_time_payment_link__error">
          <MessageError iconClose={<Refresh />} onClose={() => getCreatedOneTimePaymentLinks()} />
        </div>
      )}
      {!loading && !error && (
        <div>
          <Table
            dense
            headers={[
              <span className="created_one_time_payment_link__left">{l('createdPaymentLink.creationDate')}</span>,
              <span className="created_one_time_payment_link__left">{l('createdPaymentLink.link')}</span>,
              <span className="created_one_time_payment_link__left">{l('createdPaymentLink.country')}</span>,
              <span className="created_one_time_payment_link__left">{l('createdPaymentLink.expirationDate')}</span>,
              <span className="created_one_time_payment_link__left">{l('createdPaymentLink.description')}</span>,
              <span className="created_one_time_payment_link__right">{l('createdPaymentLink.amount')}</span>,
              <span>{l('createdPaymentLink.paymentStatus')}</span>,
            ]}
            emptyBody={
              <div className="created_recurring_payment_link__no_data">
                <EmptyIcon />
                <div className="created_recurring_payment_link__no_data__title">
                  {l('createdPaymentLink.noOneTimePaymentLinks')}
                </div>
                <div>
                  <ButtonLink
                    text={l('createdPaymentLink.noPaymentLinkDesc.1')}
                    onClick={() => history.push(Routes.CREATE_ONE_TIME_PAYMENT_LINK)}
                  />
                  {l('createdPaymentLink.noPaymentLinkDesc.2')}
                </div>
              </div>
            }
            body={
              (dataExist &&
                allPaymentLinks?.data?.map(pl => [
                  <span className="created_one_time_payment_link__left">
                    {pl.creationDate ? <Moment format="DD/MM/YYYY">{pl.creationDate}</Moment> : '-'}
                  </span>,
                  <div className="created_one_time_payment_link__left created_one_time_payment_link__link">
                    <div className="created_one_time_payment_link__link__copy">
                      {pl.paymentStatus === PaymentStatus.PENDING && (
                        <CopyToClipboard
                          text={pl.paymentLink}
                          onCopy={() => {
                            segmentPaymentLinkLinkListCopyLink({
                              link: pl.paymentLink,
                            });
                            setWasCopied(true);
                          }}
                        >
                          <Tooltip
                            onMouseEnter={() => setWasCopied(false)}
                            classes={{
                              tooltip: `created_one_time_payment_link__tooltip ${wasCopied && ' copied'}`,
                              arrow: `created_one_time_payment_link__tooltip__arrow ${wasCopied && ' copied'}`,
                            }}
                            title={l(wasCopied ? 'createdPaymentLink.copiedLink' : 'createdPaymentLink.copyLink')}
                          >
                            <div>
                              <IconButton>
                                <CopyIcon />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </CopyToClipboard>
                      )}
                    </div>
                    <div className="created_one_time_payment_link__link__wrapper">
                      <div className="created_one_time_payment_link__link__text">
                        <span>{pl.paymentLink || '-'}</span>
                      </div>
                    </div>
                  </div>,
                  <span className="created_one_time_payment_link__left">
                    {pl.country ? (
                      <span className="created_one_time_payment_link__country">
                        <img
                          alt=""
                          className="created_one_time_payment_link__country_flag"
                          src={`${Urls.flags.replace(':code', pl.country)}`}
                        />
                        <span>&nbsp;{pl.country}</span>
                      </span>
                    ) : (
                      '-'
                    )}
                  </span>,
                  <span className="created_one_time_payment_link__left">
                    {pl.expirationDate ? <Moment format="DD/MM/YYYY">{pl.expirationDate}</Moment> : '-'}
                  </span>,
                  <span className="created_one_time_payment_link__left">{pl.description || '-'}</span>,
                  <span className="created_one_time_payment_link__right">
                    {pl.currency && pl.amount ? pl.currency + ' ' + pl.amount : '-'}
                  </span>,
                  <span>
                    {(pl.paymentStatus && (
                      <ChipStatus fullWidth status={pl.paymentStatus} text={l(`transactions.status.${pl.paymentStatus}`)} />
                    )) ||
                      '-'}
                  </span>,
                ])) ||
              null
            }
            rowsPerPageOptions={[10, 25, 50]}
            rowsLength={allPaymentLinks?.totalElements}
            rowsPerPage={allPaymentLinks?.size}
            page={allPaymentLinks?.page}
            handleChangePage={(event, newPage) => getCreatedOneTimePaymentLinks(newPage, allPaymentLinks?.size)}
            handleChangeRowsPerPage={event => getCreatedOneTimePaymentLinks(0, event.target.value)}
            maxHeight={undefined}
          />
        </div>
      )}
    </div>
  );
};

export default CreatedOneTimePaymentLink;
