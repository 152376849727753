import { useDispatch } from 'react-redux';
import { changeLanguage } from './actions';

export const useCommon = () => {
  const dispatch = useDispatch();

  return {
    changeLanguage: (lang: string) => dispatch(changeLanguage(lang)),
  };
};
