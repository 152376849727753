import PropTypes from 'prop-types';
import { Button as ButtonMui } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  button: {
    fontSize: 14,
    textTransform: 'none',
    '&.MuiButton-containedPrimary': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.MuiButton-outlinedSecondary': {
      border: '1px solid #fff',
    },
    '&.Mui-disabled': {
      border: 'none',
    },
    '&.error': {
      backgroundColor: '#EA1212',
      borderColor: '#EA1212',
      color: '#fff',
      '&:hover': {
        opacity: 0.8,
      },
    },
    '&.success': {
      backgroundColor: '#21a35b',
      borderColor: '#21a35b',
      color: '#fff',
      '&:hover': {
        opacity: 0.8,
      },
    },
    '&.pending': {
      backgroundColor: '#EB7300',
      borderColor: '#EB7300',
      color: '#fff',
      '&:hover': {
        opacity: 0.8,
      },
    },
    '&.disabled': {
      backgroundColor: '#637381',
      borderColor: '#637381',
      color: '#fff',
      '&:hover': {
        opacity: 0.8,
      },
    },
    '&.small': {
      borderRadius: '8px',
      padding: '3px 8px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
    },
    '&.small-full': {
      width: '100%',
      borderRadius: '4px',
      padding: '3px 8px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      letterSpacing: '2.4px',
      textTransform: 'uppercase',
    },
    '&.square': {
      borderRadius: '5px',
    },
    '&.full-square': {
      color: '#FFFFFF',
      width: 'auto',
      '@media (max-width:768px)': {
        width: '100%',
      },
      borderRadius: '5px',
    },
    '&.rounded': {
      backgroundColor: '#FFFFFF',
      borderRadius: '50px',
    },
    '&.full-rounded': {
      color: theme.palette.primary.main,
      backgroundColor: '#FFFFFF',
      width: '100%',
      borderRadius: '50px',
    },
    '&.half-rounded-left-icon': {
      color: theme.palette.primary.main,
      backgroundColor: '#FFFFFF',
      width: '100%',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      borderTopLeftRadius: '50px',
      borderBottomLeftRadius: '50px',
      minHeight: '36.5px',
      fontSize: 1,
      textAlign: 'center',
    },
    '&.half-rounded-right': {
      color: theme.palette.primary.main,
      backgroundColor: '#FFFFFF',
      width: '100%',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      borderTopRightRadius: '50px',
      borderBottomRightRadius: '50px',
    },
    '&.full-bottom': {
      zIndex: 100,
      color: '#FFFFFF',
      width: '100%',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    '&.half-right': {
      color: '#FFFFFF',
      width: '50%',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
    '&.half-left': {
      color: theme.palette.primary.main,
      background: '#FFFFFF',
      width: '50%',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&.half-right-square': {
      color: '#FFFFFF',
      width: '50%',
      borderRadius: 0,
    },
    '&.half-left-square': {
      color: theme.palette.primary.main,
      background: '#FFFFFF',
      width: '50%',
      borderRadius: 0,
    },
  },
}));

const ButtonCustom = ({
  ariaDescribedby,
  text,
  name,
  type = 'button',
  variant,
  color = 'primary',
  disabled = false,
  loading,
  textLoading,
  onClick,
  size,
  startIcon,
  endIcon,
  component,
  innerComponent,
  fullWidth,
}) => {
  const classes = useStyles();
  return (
    <ButtonMui
      aria-describedby={ariaDescribedby}
      variant={variant || (color === 'primary' ? 'contained' : color === 'secondary' ? 'outlined' : undefined)}
      color={color}
      id={name}
      key={`btn-${name}`}
      name={name}
      className={`${classes.button} ${size}`}
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      component={component}
      fullWidth={fullWidth}
      disableElevation
    >
      {!loading && <span>{text}</span>}
      {loading && (
        <div>
          {textLoading || text}
          <div className="loader" />
        </div>
      )}
      {innerComponent}
    </ButtonMui>
  );
};

ButtonCustom.propTypes = {
  ariaDescribedby: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  textLoading: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,
  component: PropTypes.string,
  innerComponent: PropTypes.object,
  fullWidth: PropTypes.bool,
};

export default ButtonCustom;
