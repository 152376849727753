import _ from 'lodash';
import l from '../../../../../../lang';
import Input from '../../../../../common/input';
import Select from '../../../../../common/select';

function NewClientFields({
  clientFirstName,
  clientLastName,
  clientDocumentType,
  clientDocument,
  clientDocumentRef,
  documentTypes,
  formDisabled,
  errors,
  touched,
  handleChange,
  handleBlur,
}) {
  return (
    <>
      <div className="create-payment-link-form__row">
        <div className="create-payment-link-form__field client-name">
          <Input
            name="clientFirstName"
            label={l('paymentLink.clientFirstName')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={clientFirstName}
            maxLength={128}
            disabled={formDisabled}
            error={touched.clientFirstName && errors.clientFirstName}
          />
        </div>
        <div className="create-payment-link-form__field client-last-name">
          <Input
            name="clientLastName"
            label={l('paymentLink.clientLastName')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={clientLastName}
            maxLength={128}
            disabled={formDisabled}
            error={touched.clientLastName && errors.clientLastName}
          />
        </div>
      </div>
      <div ref={clientDocumentRef} className="create-payment-link-form__row">
        <div className="create-payment-link-form__field document-type">
          <Select
            name="clientDocumentType"
            label={l('paymentLink.clientDocumentType')}
            value={clientDocumentType}
            onChange={handleChange}
            options={documentTypes}
            disabled={_.isEmpty(documentTypes) || formDisabled}
            ref={undefined}
            message={undefined}
            groupOptionsArray={undefined}
            groupOptionsArrayDisabled={undefined}
            onFocus={undefined}
            onMouseOver={undefined}
            onClose={undefined}
          />
        </div>
        <div className="create-payment-link-form__field document-number">
          <Input
            name="clientDocument"
            label={l('paymentLink.clientDocument')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={clientDocument}
            maxLength={30}
            disabled={!clientDocumentType || formDisabled}
            error={touched.clientDocument && errors.clientDocument}
          />
        </div>
      </div>
    </>
  );
}

export default NewClientFields;
