export enum ProfileActionType {
  SAVE_PERSONAL_INFO = 'PROFILE/SAVE_PERSONAL_INFO',
  SAVE_PERSONAL_INFO_SUCCESS = 'PROFILE/SAVE_PERSONAL_INFO_SUCCESS',
  SAVE_PERSONAL_INFO_ERROR = 'PROFILE/SAVE_PERSONAL_INFO_ERROR',
  CLEAN_PROFILE_SUCCESS = 'PROFILE/CLEAN_PROFILE_SUCCESS',
  CHANGE_PASSWORD = 'PROFILE/CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS = 'PROFILE/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR = 'PROFILE/CHANGE_PASSWORD_ERROR',
  SHOW_EDIT_PERSONAL_INFO = 'PROFILE/SHOW_EDIT_PERSONAL_INFO',
  SHOW_CHANGE_PASSWORD = 'PROFILE/SHOW_CHANGE_PASSWORD',
}

export const savePersonalInfo = (name: string, language: string, marketingConsent: boolean) => ({
  type: ProfileActionType.SAVE_PERSONAL_INFO,
  name,
  language,
  marketingConsent,
});

export const savePersonalInfoSuccess = (response: any) => ({
  type: ProfileActionType.SAVE_PERSONAL_INFO_SUCCESS,
  response,
});

export const savePersonalInfoError = (error: any) => ({
  type: ProfileActionType.SAVE_PERSONAL_INFO_ERROR,
  error,
});

export const cleanProfileSuccess = () => ({
  type: ProfileActionType.CLEAN_PROFILE_SUCCESS,
});

export const changePassword = (oldPassword: string, newPassword: string) => ({
  type: ProfileActionType.CHANGE_PASSWORD,
  oldPassword,
  newPassword,
});

export const changePasswordSuccess = (response: any) => ({
  type: ProfileActionType.CHANGE_PASSWORD_SUCCESS,
  response,
});

export const changePasswordError = (error: any) => ({
  type: ProfileActionType.CHANGE_PASSWORD_ERROR,
  error,
});

export const openEditPersonalInfo = (show: boolean) => ({
  type: ProfileActionType.SHOW_EDIT_PERSONAL_INFO,
  show,
});

export const openChangePassword = (show: boolean) => ({
  type: ProfileActionType.SHOW_CHANGE_PASSWORD,
  show,
});
