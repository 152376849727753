import Parser from 'html-react-parser';
import l from '../../../../lang';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import {
  segmentIntegrationsHostingerKeysCopyApiKey,
  segmentIntegrationsHostingerKeysCopySecretKey,
  segmentIntegrationsHostingerPageLinkDocs,
} from '../../../../utils/analytics';
import IntegrationDetails from '../integration-details';
import { ReactComponent as HostingerIcon } from '../../../../assets/icons/hostinger.svg';
import { getLanguage, openNewTabAndFocus } from '../../../../utils';
import InputSecret from '../../../common/input-secret';
import { Urls } from '../../../../config/urls';
import ButtonLink from '../../../common/button-link';

export default () => {
  const { apiKey, secretKey, setDetailsOpened } = usePaymentIntegration();

  const getDocumentationUrl = () => {
    const lang = getLanguage();
    switch (lang) {
      case 'es':
        return Urls.docs.hostingerIntegration.es;
      case 'pt':
        return Urls.docs.hostingerIntegration.pt;
      default:
        return Urls.docs.hostingerIntegration.en;
    }
  };

  return (
    <IntegrationDetails icon={<HostingerIcon />} color="magenta" onClose={() => setDetailsOpened(DetailsOpened.NONE)}>
      <div className="integration_details__title">{l('paymentIntegration.hostinger.details.headline')}</div>
      <p>
        <span>{Parser(l('paymentIntegration.hostinger.details.description'))} </span>
      </p>
      <p>
        <ButtonLink
          text={l('paymentIntegration.hostinger.details.follow')}
          onClick={() => {
            segmentIntegrationsHostingerPageLinkDocs();
            openNewTabAndFocus(getDocumentationUrl());
          }}
        />
        {l('paymentIntegration.hostinger.details.description2')}
      </p>
      <div className="integration__key">
        <div className="integration__key__title">{l('paymentIntegration.hostinger.details.key.headline')}</div>
        <div>{Parser(l('paymentIntegration.hostinger.details.key.description'))}</div>
        <InputSecret text={apiKey} label="API Key" onCopy={() => segmentIntegrationsHostingerKeysCopyApiKey()} />
        <InputSecret text={secretKey} label="Secret Key" onCopy={() => segmentIntegrationsHostingerKeysCopySecretKey()} />
      </div>
    </IntegrationDetails>
  );
};
