import { useEffect } from 'react';
import l from '../../../../../lang';
import Loading from '../../../../common/loading';
import { ItemOnboardingMenu } from '../../../onboarding-menu';
import { useCompanyInfo } from '../../../../../store/company-information';
import { useDashboard } from '../../../../../store/dashboard';
import { useJumioIntegration } from '../../../../../store/jumio';
import { JumioDialog } from '../../../jumio';
import { KYCLevel1Form } from './KYCLevel1Form';
import './styles.scss';
import { AccountCreatorRole } from '../../../../../constants';

export type ValuesLevelOne = {
  company: boolean | null;
  name: string;
  lastName: string;
  fantasyName: string;
  country: string;
  documentType: string;
  document: string;
  website: string;
  phone: string;
  subIndustryId: string;
  functionalCurrency: string;
  accountCreatorRole: AccountCreatorRole;
};

const KYCLevel1 = ({ setOnboardingMenu }: KYCLevel1Props) => {
  const { loading: companyLoading } = useCompanyInfo();
  const { companyWasFulfilled } = useDashboard();
  const { url, loading: jumioLoading, start: jumioStart } = useJumioIntegration();
  const loading = companyLoading || jumioLoading;

  const isJumioSelected = companyWasFulfilled;

  useEffect(() => {
    if (isJumioSelected) {
      jumioStart();
      setOnboardingMenu([
        { title: l('companyInformation.kycOneMenu.introduction'), completed: true, selected: false },
        { title: l('companyInformation.kycOneMenu.typeOfCompany'), completed: true, selected: false },
        {
          title: l('companyInformation.kycOneMenu.companyData'),
          subItems: [
            {
              title: l('companyInformation.basicInfo.commercialInformation'),
              completed: true,
              selected: false,
            },
            {
              title: l('companyInformation.basicInfo.legalInformation'),
              completed: true,
              selected: false,
            },
            {
              title: l('companyInformation.basicInfo.contactInformation'),
              completed: true,
              selected: false,
            },
          ],
        },
        { title: l('companyInformation.kycOneMenu.jumio'), completed: false, selected: true },
      ]);
    }
  }, [jumioStart, isJumioSelected]);

  return (
    <div className="kyc-level-1">
      {loading && <Loading fullScreen />}
      {!isJumioSelected && <KYCLevel1Form setOnboardingMenu={setOnboardingMenu} />}
      {!loading && isJumioSelected && url && <JumioDialog url={url} />}
    </div>
  );
};

export interface KYCLevel1Props {
  setOnboardingMenu: (menu: ItemOnboardingMenu[]) => void;
}

export default KYCLevel1;
