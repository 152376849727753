import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import MuiTablePagination from '@mui/material/TablePagination';
import l from '../../../lang';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    width: '100%',
  },
}));

const TablePagination = ({
  rowsPerPageOptions,
  rowsLength,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const classes = useStyles();

  return (
    <MuiTablePagination
      classes={classes}
      component="div"
      rowsPerPageOptions={rowsPerPageOptions}
      count={rowsLength}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count }) => {
        return `${from || 0}-${to || 0} ${l('common.of')} ${count || 0}`;
      }}
      labelRowsPerPage={`${l('common.rowsPerPage')}:`}
    />
  );
};

TablePagination.propTypes = {
  rowsPerPageOptions: PropTypes.array,
  rowsLength: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

export default TablePagination;
