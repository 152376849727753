import Parser from 'html-react-parser';
import l from '../../../../lang';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import {
  segmentIntegrationsPrestaShopInstall,
  segmentIntegrationsPrestaShopKeysCopyApiKey,
  segmentIntegrationsPrestaShopKeysCopySecretKey,
  segmentIntegrationsPrestaShopDownloadPlugin
} from '../../../../utils/analytics';
import IntegrationDetails from '../integration-details';
import { ReactComponent as PrestashopIcon } from '../../../../assets/icons/prestashop.svg';
import { getLanguage, openNewTabAndFocus } from '../../../../utils';
import InputSecret from '../../../common/input-secret';
import { Urls } from '../../../../config/urls';
import { Button } from '../../../common/button';

export default () => {
  const { apiKey, secretKey, setDetailsOpened } = usePaymentIntegration();

  const getDocumentationUrl = () => {
    const lang = getLanguage();
    switch (lang) {
      case 'es':
        return Urls.docs.prestaShopIntegration.es;
      case 'pt':
        return Urls.docs.prestaShopIntegration.pt;
      default:
        return Urls.docs.prestaShopIntegration.en;
    }
  };

  return (
    <IntegrationDetails icon={<PrestashopIcon />} color="red" onClose={() => setDetailsOpened(DetailsOpened.NONE)}>
      <div className="integration_details__title">{l('paymentIntegration.prestashop.details.headline')}</div>
      <p>
        <span>{Parser(l('paymentIntegration.prestashop.details.description'))} </span>
      </p>
      <div className="integration__key">
        <div className="integration__key__title">{l('paymentIntegration.prestashop.details.key.headline')}</div>
        <div>{Parser(l('paymentIntegration.prestashop.details.key.description'))}</div>
        <InputSecret text={apiKey} label="API Key" onCopy={() => segmentIntegrationsPrestaShopKeysCopyApiKey()} />
        <InputSecret text={secretKey} label="Secret Key" onCopy={() => segmentIntegrationsPrestaShopKeysCopySecretKey()} />
      </div>
      <Button
        className="integration_details__button "
        variant="outlined"
        onClick={() => {
          segmentIntegrationsPrestaShopDownloadPlugin();
          openNewTabAndFocus(Urls.prestaShopDownloadPlugin);
        }}
      >
        {l('paymentIntegration.prestashop.download')}
      </Button>
      <Button
        className="integration_details__button secondary"
        onClick={() => {
          segmentIntegrationsPrestaShopInstall();
          openNewTabAndFocus(getDocumentationUrl());
        }}
      >
        {l('paymentIntegration.prestashop.details.button')}
      </Button>
    </IntegrationDetails>
  );
};
