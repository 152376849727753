import { takeLatest, put, call, select } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { getTask, patchTask, postTask } from '../../services';
import {
  SubscriptionsActionType,
  getSubscriptionsSuccess,
  getSubscriptionsError,
  getPlansSuccess,
  getPlansError,
  getCurrenciesSuccess,
  getCurrenciesError,
  createUpdatePlanError,
  createUpdatePlanSuccess,
  deactivateSubscriptionSuccess,
  deactivateSubscriptionError,
  deactivatePlanSuccess,
  deactivatePlanError,
} from './actions';
import { PanelStore } from '../interfaces';
import { AuthSelectors } from '../auth/selectors';

function* getSubscriptions(action?: { page?: number; rowsPerPage?: number }) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const params = `?page=${action?.page || 0}&page_size=${action?.rowsPerPage || 10}`;

  const { response, error } = yield call(getTask, {
    url: Urls.api.allSubscriptions + params,
    token: state.auth.token,
  });

  if (response) {
    yield put(getSubscriptionsSuccess(response));
  } else {
    yield put(getSubscriptionsError(error));
  }
}

export function* getSubscriptionsSaga() {
  yield takeLatest(SubscriptionsActionType.GET_SUBSCRIPTIONS, getSubscriptions);
}

function* getPlans() {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: Urls.api.allPlans,
    token: state.auth.token,
  });

  if (response) {
    yield put(getPlansSuccess(response));
  } else {
    yield put(getPlansError(error));
  }
}

export function* getPlansSaga() {
  yield takeLatest(SubscriptionsActionType.GET_PLANS, getPlans);
}

function* getCurrencies(action: any) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.currencies}?country=${action.country}`,
    token,
  });

  if (response) {
    yield put(getCurrenciesSuccess(response));
  } else {
    yield put(getCurrenciesError(error));
  }
}

export function* getCurrenciesSaga() {
  yield takeLatest(SubscriptionsActionType.GET_CURRENCIES, getCurrencies);
}

function* createUpdatePlan(action: any) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);

  const { response, error } = yield call(action.body.id ? patchTask : postTask, {
    url: `${Urls.api.createUpdateSubscriptionPlan}`,
    body: action.body,
    token,
  });

  if (response) {
    yield put(createUpdatePlanSuccess(response));
  } else {
    yield put(createUpdatePlanError(error));
  }
}

export function* createUpdatePlanSaga() {
  yield takeLatest(SubscriptionsActionType.CREATE_UPDATE_PLAN, createUpdatePlan);
}

function* deactivateSubscription(action?: { subscriptionId?: number }) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(patchTask, {
    url: Urls.api.deactivateSubscription.replace(':subscriptionId', `${action?.subscriptionId}`),
    token: state.auth.token,
    body: {},
  });

  if (response) {
    yield put(deactivateSubscriptionSuccess(response));
  } else {
    yield put(deactivateSubscriptionError(error));
  }
}

export function* deactivateSubscriptionSaga() {
  yield takeLatest(SubscriptionsActionType.DEACTIVATE_SUBSCRIPTION, deactivateSubscription);
}

function* deactivatePlan(action?: { planId?: number }) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(patchTask, {
    url: Urls.api.deactivatePlan.replace(':planId', `${action?.planId}`),
    token: state.auth.token,
    body: {},
  });

  if (response) {
    yield put(deactivatePlanSuccess(response));
  } else {
    yield put(deactivatePlanError(error));
  }
}

export function* deactivatePlanSaga() {
  yield takeLatest(SubscriptionsActionType.DEACTIVATE_PLAN, deactivatePlan);
}
