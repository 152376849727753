import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import { Divider, useMediaQuery } from '@mui/material';
import { ReactComponent as FilterListIcon } from '../../../assets/icons/filter.svg';
import ButtonCustom from '../__deprecated__/button-custom';
import ButtonLink from '../button-link';
import ItemFilter from './item-filter';
import l from '../../../lang';
import Dialog from '../dialog';
import { Button } from '../button';
import { segmentRefundsFilter } from '../../../utils/analytics';

const useStyles = makeStyles(theme => ({
  filter: {
    display: 'flex',
  },
  button: {
    minWidth: 'auto',
    padding: '5px 10px',
    minHeight: 36,
    gap: 4,
  },
  customButton: {
    width: '100%',
  },
  withExtraBtn: {
    width: '70%',
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    minWidth: 350,
    '@media (max-width:768px)': {
      minWidth: 250,
    },
  },
  listSubheader: {
    display: 'flex',
    fontFamily: 'Avenir',
  },
  listSubheaderText: {
    fontSize: 16,
    width: '50%',
  },
  listSubheaderCleanFilter: {
    fontSize: 12,
    width: '50%',
    textAlign: 'right',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Filter = ({ appliedFilters, items = [], clearFilter, withExtraBtn, pillShaped }) => {
  const isTabletMobile = useMediaQuery('(max-width:768px)');
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(null);

  const handleClick = event => {
    segmentRefundsFilter();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openFilter = Boolean(anchorEl);
  const id = openFilter ? 'simple-popover' : undefined;

  const content = () => {
    return (
      <List
        component="nav"
        subheader={
          <ListSubheader component="div">
            <div className={classes.listSubheader}>
              <div className={classes.listSubheaderText}>{l('filter.title')}</div>
              {clearFilter && (
                <div className={classes.listSubheaderCleanFilter}>
                  <ButtonLink text={l('filter.cleanFilter')} onClick={clearFilter} />
                </div>
              )}
            </div>
          </ListSubheader>
        }
        className={classes.list}
      >
        {items.map((item, idx) => (
          <div key={`filter-key-item-${idx.toString()}`}>
            <Divider />
            <ItemFilter
              title={item.title}
              component={item.component}
              open={open === idx}
              fnHandleClickItem={() => {
                if (open === idx) {
                  setOpen(null);
                } else {
                  setOpen(idx);
                }
              }}
            />
            {items.length === idx + 1 && <Divider />}
          </div>
        ))}
      </List>
    );
  };

  return (
    <div className={classes.filter}>
      {pillShaped ? (
        <>
          {withExtraBtn}
          <div className={withExtraBtn ? classes.withExtraBtn : classes.customButton}>
            <ButtonCustom
              ariaDescribedby={id}
              name="filter"
              text={l('filter.btn', appliedFilters ? `(${appliedFilters})` : '')}
              size={withExtraBtn ? 'half-rounded-right' : 'full-rounded'}
              onClick={handleClick}
              startIcon={<FilterListIcon />}
            />
          </div>
        </>
      ) : (
        <Button variant="outlined" className={classes.button} onClick={handleClick}>
          <FilterListIcon />{!isTabletMobile && `${l('filter.btn', appliedFilters ? `(${appliedFilters})` : '')}`}
        </Button>
      )}
      <Dialog
        open={isTabletMobile && openFilter}
        dividers={false}
        unpaddingContent
        unpaddingFooter
        type="sm"
        handleClose={handleClose}
        body={content()}
      />
      <Popover
        id={id}
        open={!isTabletMobile && openFilter}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {content()}
      </Popover>
    </div>
  );
};

Filter.propTypes = {
  items: PropTypes.array,
  clearFilter: PropTypes.func,
  withExtraBtn: PropTypes.object,
  pillShaped: PropTypes.bool,
};

export default Filter;
