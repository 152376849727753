/* eslint-disable max-len */
const brandName = 'dLocal Go';

export default {
  'common.yes': {
    en: 'Yes',
    es: 'Si',
    pt: 'Sim',
  },
  'common.no': {
    en: 'No',
    es: 'No',
    pt: 'Não',
  },
  'common.total': {
    en: 'Total',
    es: 'Total',
    pt: 'Total',
  },
  'common.of': {
    en: 'of',
    es: 'de',
    pt: 'de',
  },
  'common.cancel': {
    en: 'Cancel',
    es: 'Cancelar',
    pt: 'Cancelar',
  },
  'common.clearAll': {
    en: 'Clear all',
    es: 'Limpiar todo',
    pt: 'Limpar filtros',
  },
  'common.apply': {
    en: 'Apply',
    es: 'Aplicar',
    pt: 'Aplicar',
  },
  'common.delete': {
    en: 'Delete',
    es: 'Eliminar',
    pt: 'Deletar',
  },
  'common.close': {
    en: 'Close',
    es: 'Cerrar',
    pt: 'Fechar',
  },
  'common.return': {
    en: 'Return',
    es: 'Volver',
    pt: 'Voltar',
  },
  'common.confirm': {
    en: 'Confirm',
    es: 'Confirmar',
    pt: 'Confirmar',
  },
  'common.previous': {
    en: 'previous',
    es: 'Anterior',
    pt: 'Anterior',
  },
  'common.next': {
    en: 'Next',
    es: 'Siguiente',
    pt: 'Seguinte',
  },
  'common.filter': {
    en: 'Filter',
    es: 'Filtro',
    pt: 'Filtro',
  },
  'common.default.option': {
    en: 'Select',
    es: 'Seleccionar',
    pt: 'Selecionar',
  },
  'common.all.m': {
    en: 'All',
    es: 'Todos',
    pt: 'Todos',
  },
  'common.all.f': {
    en: 'All',
    es: 'Todas',
    pt: 'Todas',
  },
  'common.others': {
    en: 'Others',
    es: 'Otros',
    pt: 'Outros',
  },
  'common.noMoreOptions': {
    en: 'No options available',
    es: 'No hay opciones disponibles',
    pt: 'Nenhuma opção disponível',
  },
  'common.language.en': {
    en: 'English',
    es: 'Inglés',
    pt: 'Inglês',
  },
  'common.language.es': {
    en: 'Spanish',
    es: 'Español',
    pt: 'Espanhol',
  },
  'common.language.pt': {
    en: 'Portuguese',
    es: 'Portugués',
    pt: 'Português',
  },
  'common.currency.USD': {
    en: 'US dollar (USD)',
    es: 'Dólar americano (USD)',
    pt: 'Dólar americano (USD)',
  },
  'common.currency.ARS': {
    en: 'Argentine peso (ARS)',
    es: 'Peso argentino (ARS)',
    pt: 'Peso argentino (ARS)',
  },
  'common.currency.BOB': {
    en: 'Bolivian (BOB)',
    es: 'Boliviano (BOB)',
    pt: 'Boliviano (BOB)',
  },
  'common.currency.BRL': {
    en: 'Brazilian real (BRL)',
    es: 'Real brasileño (BRL)',
    pt: 'Real (BRL)',
  },
  'common.currency.COP': {
    en: 'Colombian peso (COP)',
    es: 'Peso colombiano (COP)',
    pt: 'Peso colombiano (COP)',
  },
  'common.currency.EUR': {
    en: 'Euro (EUR)',
    es: 'Euro (EUR)',
    pt: 'Euro (EUR)',
  },
  'common.currency.IDR': {
    en: 'Rupiah (IDR)',
    es: 'Rupiah (IDR)',
    pt: 'Rupiah (IDR)',
  },
  'common.currency.KES': {
    en: 'Kenyan shilling (KES)',
    es: 'Chelín Keniano (KES)',
    pt: 'Xelim Queniano (KES)',
  },
  'common.currency.MXN': {
    en: 'Mexican peso (MXN)',
    es: 'Peso mexicano (MXN)',
    pt: 'Peso mexicano (MXN)',
  },
  'common.currency.MYR': {
    en: 'Malaysian Ringgit (MYR)',
    es: 'Malaysian Ringgit (MYR)',
    pt: 'Malaysian Ringgit (MYR)',
  },
  'common.currency.NGN': {
    en: 'Naira (NGN)',
    es: 'Naira (NGN)',
    pt: 'Naira (NGN)',
  },
  'common.currency.CLP': {
    en: 'Chilean peso (CLP)',
    es: 'Peso chileno (CLP)',
    pt: 'Peso chileno (CLP)',
  },
  'common.currency.PEN': {
    en: 'Peruvian sol (PEN)',
    es: 'Sol peruano (PEN)',
    pt: 'Sol peruano (PEN)',
  },
  'common.currency.PYG': {
    en: 'Paraguayan Guarani (PYG)',
    es: 'Guaraní Paraguayo (PYG)',
    pt: 'Guarani Paraguaio (PYG)',
  },
  'common.currency.UYU': {
    en: 'Uruguayan peso (UYU)',
    es: 'Peso uruguayo (UYU)',
    pt: 'Peso uruguaio (UYU)',
  },
  'common.currency.CRC': {
    en: 'Costa Rican Colon (CRC)',
    es: 'Colón costarricense (CRC)',
    pt: 'Cólon da Costa Rica (CRC)',
  },
  'common.currency.GTQ': {
    en: 'Quetzal (GTQ)',
    es: 'Quetzal (GTQ)',
    pt: 'Quetzal (GTQ)',
  },
  'common.fraudReview.headline': {
    en: 'We are reviewing your information.',
    es: 'Estamos revisando tu información.',
    pt: 'Estamos analisando suas informações.',
  },
  'common.fraudReview.subheadline': {
    en: "Until we finish verifying it, you won't be able to process payments or withdraw money. If you have any questions, ",
    es: 'Hasta que terminemos de verificarla, no podrás procesar pagos ni retirar dinero. Si tienes alguna duda, ',
    pt: 'Até terminarmos a verificação, você não poderá processar pagamentos ou sacar dinheiro. Se tiver qualquer dúvida, ',
  },
  'common.fraudReview.subheadline.settlement.enabled': {
    en: "Until we finish verifying it, you won't be able to process payments. If you have any questions, ",
    es: 'Hasta que terminemos de verificarla, no podrás procesar pagos. Si tienes alguna duda, ',
    pt: 'Até terminarmos a verificação, você não poderá processar pagamentos. Se tiver qualquer dúvida, ',
  },
  'common.fraudReview.subheadline.link': {
    en: 'contact us.',
    es: 'comunícate con nosotros.',
    pt: 'entre em contato conosco.',
  },
  'common.blockedReview.headline': {
    en: 'Your account was blocked.',
    es: 'Tu cuenta fue bloqueada.',
    pt: 'Sua conta foi bloqueada.',
  },
  'common.blockedReview.subheadline': {
    en: 'To unlock it and get back to managing your payments and withdrawals, ',
    es: 'Para desbloquearla y volver a gestionar tus pagos y retiros de dinero, ',
    pt: 'Para desbloquear e voltar a gerenciar os seus pagamentos e saques, ',
  },
  'common.blockedReview.subheadline.link': {
    en: 'contact us.',
    es: 'comunícate con nosotros.',
    pt: 'entre em contato conosco.',
  },
  'common.testMessage': {
    en: 'You are in a <b>test</b> environment.',
    es: 'Estás en un ambiente de <b>prueba</b>.',
    pt: 'Você está em um ambiente de <b>teste</b>.',
  },
  'common.kycMessage': {
    en: 'You only have one step left to finish setting up your dLocal Go account',
    es: 'Te falta solo un paso para terminar de configurar tu cuenta de dLocal Go',
    pt: 'Você só tem mais uma etapa para terminar de configurar sua conta dLocal Go',
  },
  'common.kycMessage.click': {
    en: 'Click here',
    es: 'Haz Click aquí',
    pt: 'Clique aqui',
  },
  'common.save': {
    en: 'Save',
    es: 'Guardar',
    pt: 'Salve',
  },
  'common.edit': {
    en: 'Edit',
    es: 'Editar',
    pt: 'Editar',
  },
  'common.rowsPerPage': {
    en: 'Rows per page',
    es: 'Filas por página',
    pt: 'Linhas por página',
  },
  'common.copy': {
    en: 'Copy',
    es: 'Copiar',
    pt: 'Copiar',
  },
  'common.copied': {
    en: 'Copied',
    es: 'Copiado',
    pt: 'Copiado',
  },
  'helpButton.text': {
    en: 'Help',
    es: 'Ayuda',
    pt: 'Ajuda',
  },
  'name.label': {
    en: 'Full name',
    es: 'Nombre completo',
    pt: 'Nome completo',
  },
  'name.helpText': {
    en: 'As it appears on your identity document.',
    es: 'Tal como figura en tu documento de identidad.',
    pt: 'Tal como consta no seu documento de identidade.',
  },
  'functionalCurrency.label': {
    en: 'Balance currency',
    es: 'Moneda de balance',
    pt: 'Moeda de operação',
  },
  'accountCreatorRole.label': {
    en: 'Role',
    es: 'Rol',
    pt: 'Cargo',
  },
  'accountCreatorName.label': {
    en: 'Full name',
    es: 'Nombre Completo',
    pt: 'Nome completo',
  },
  'emailAddress.label': {
    en: 'Email address',
    es: 'Correo electrónico',
    pt: 'E-mail',
  },
  'password.label': {
    en: 'Password',
    es: 'Contraseña',
    pt: 'Senha',
  },
  'password.helpText': {
    en: 'It must contain a minimum of 8 characters, including at least one uppercase letter, one lowercase letter and one number.',
    es: 'Debe contener un mínimo de 8 caracteres, incluyendo al menos una letra mayúscula, una letra minúscula y un número.',
    pt: 'Deve conter um mínimo de 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula e um número.',
  },
  'passwordVerify.label': {
    en: 'Password verify',
    es: 'Confirmación de contraseña',
    pt: 'Confirmação de senha',
  },
  'country.label': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'businessCountry.label': {
    en: 'Country of origin of the company',
    es: 'País de origen de su negocio',
    pt: 'País de origem da empresa',
  },
  'businessCountry.tooltip': {
    en: 'If you are a legal person, complete this field with the country where your company is legally registered.<br/><br/>If you are a natural person, put the country from where you sell your products.',
    es: 'Si eres una persona jurídica, completa este campo con el país donde tu negocio está legalmente registrada.<br/><br/>Si eres una persona física, pon el país desde donde vendes tus productos.',
    pt: 'Se você é uma pessoa jurídica, complete este campo com o país onde sua empresa está legalmente cadastrada.<br/><br/>Se você é uma pessoa física, coloque o país de onde vende seus produtos.',
  },
  'countries.label': {
    en: 'Countries',
    es: 'Países',
    pt: 'Países',
  },
  'searchCountries.label': {
    en: 'Search countries',
    es: 'Buscar países',
    pt: 'Encontrar países',
  },
  'searchPaymentMethods.label': {
    en: 'Search payment method',
    es: 'Buscar método de pago',
    pt: 'Encontrar método de pagamento',
  },
  'marketingConsent.label': {
    en: 'I agree to receive promotional and news emails.',
    es: 'Acepto recibir correos electrónicos promocionales y de noticias.',
    pt: 'Aceito receber e-mails promocionais e de notícias',
  },
  'companyType.label': {
    en: 'Account type',
    es: 'Tipo de cuenta',
    pt: 'Tipo de conta',
  },
  'companyType.tooltip': {
    en: 'The option you choose (natural or legal person) must be the same one you use for legal and tax matters.',
    es: 'La opción que elijas (persona física o jurídica) debe ser la misma que utilizas para asuntos legales y fiscales.',
    pt: 'A opção que escolher (pessoa física ou jurídica) deve ser a mesma que você utiliza para assuntos legais e fiscais.',
  },
  'companyType.company': {
    en: 'Company - Legal person',
    es: 'Empresa - Persona jurídica',
    pt: 'Empresa - Pessoa jurídica',
  },
  'companyType.physical': {
    en: 'Individual entrepreneur - Natural person',
    es: 'Emprendedor individual - Persona física',
    pt: 'Empreendedor Autônomo - Pessoa física',
  },
  'notFound.title': {
    en: "Something's wrong here...",
    es: 'Hay algo mal aquí...',
    pt: 'Há algo errado aqui...',
  },
  'notFound.subtitle': {
    en: "We can't find the page you're looking for. You can either return to the previous page or visit our homepage.",
    es: 'No podemos encontrar la página que estas buscando. Por favor regresa a la página anterior o visita nuestra página principal.',
    pt: 'Não conseguimos encontrar a página que você está procurando. Por favor, volte à página anterior ou visite nossa página principal.',
  },
  'notFound.GoHome.btn': {
    en: 'Go to home page',
    es: 'Ir a la página de inicio',
    pt: 'Vá para a página inicial',
  },
  'form.input.required': {
    en: 'This field is required.',
    es: 'Este campo es requerido.',
    pt: 'Este campo é obrigatório.',
  },
  'form.input.invalid': {
    en: 'Invalid value',
    es: 'Valor inválido',
    pt: 'Valor inválido',
  },
  'form.input.passwordsNotMatch': {
    en: 'Passwords entered do not match.',
    es: 'Las contraseñas ingresadas no coinciden.',
    pt: 'As senhas inseridas não correspondem.',
  },
  'form.email.invalid': {
    en: 'Invalid email address.',
    es: 'Correo electrónico inválido.',
    pt: 'E-mail inválido.',
  },
  'form.input.password.invalid': {
    en: 'Password must contain upper case, lower case and numbers.',
    es: 'La contraseña debe contener mayúsculas, minúsculas y números.',
    pt: 'A senha deve conter letras maiúsculas, minúsculas e números.',
  },
  'form.input.password.invalidLength': {
    en: 'The password must be at least 8 characters.',
    es: 'La contraseña debe tener al menos 8 caracteres.',
    pt: 'A senha deve ter pelo menos 8 caracteres.',
  },
  'form.input.termsAndConditions.invalid': {
    en: 'You must accept the terms and conditions.',
    es: 'Debe aceptar los términos y condiciones.',
    pt: 'Você deve aceitar os termos e condições.',
  },
  'form.loading': {
    en: 'Loading',
    es: 'Cargando',
    pt: 'Carregando',
  },
  'form.sending': {
    en: 'Sending',
    es: 'Enviando',
    pt: 'Enviando',
  },
  'msgError.login.default': {
    en: 'You have typed wrong credentials, or the user is either locked or inactive.',
    es: 'Ha escrito credenciales incorrectas o el usuario está bloqueado o inactivo.',
    pt: 'Você inseriu credenciais incorretas ou o usuário está bloqueado ou inativo.',
  },
  'msgError.login.wrong.password.headline': {
    en: 'Incorrect password',
    es: 'Contraseña incorrecta',
    pt: 'Senha incorreta',
  },
  'msgError.login.wrong.password.subheadline': {
    en: 'Please, re-enter it.<br />If you forgot your password, <a href="/password-reset">request a new one</a>.',
    es: 'Por favor, vuelve a introducirla.<br />Si olvidaste tu contraseña, <a href="/password-reset">solicita una nueva</a>.',
    pt: 'Por favor, digite-o novamente.<br />Se você esqueceu sua senha, <a href="/password-reset">solicite uma nova</a>.',
  },
  'msgError.login.block.headline': {
    en: 'Your account is blocked',
    es: 'Tu cuenta está bloqueada',
    pt: 'Sua conta está bloqueada',
  },
  'msgError.login.block.subheadline': {
    en: 'Please <button class="link" onclick="{0}">contact us</button> to reactivate it.',
    es: 'Por favor, <button class="link" onclick="{0}">contáctenos</button> para volver a activarla.',
    pt: 'Entre em <button class="link" onclick="{0}">contato conosco</button> para reativá-la.',
  },
  'msgError.login.emailNotVerified': {
    en: 'Email not verified. Please check that you received the verification email and follow its instructions.',
    es: 'Correo electrónico no verificado. Por favor, compruebe que recibió el correo electrónico de verificación y siga sus instrucciones.',
    pt: 'E-mail não verificado. Verifique se você recebeu o e-mail de verificação e siga suas instruções.',
  },
  'login.signUp.label': {
    en: 'Want to join us?',
    es: '¿Quieres unirte a nosotros?',
    pt: 'Deseja se juntar a nós?',
  },
  'login.passwordReset.label': {
    en: 'Forgot your password?',
    es: '¿Olvidaste tu contraseña?',
    pt: 'Esqueceu sua senha?',
  },
  'login.title': {
    en: 'Welcome',
    es: 'Bienvenido',
    pt: 'Bem-vindo',
  },
  'login.btn': {
    en: 'Login',
    es: 'Iniciar sesión',
    pt: 'Iniciar sessão',
  },
  'login.signUp': {
    en: 'Sign up',
    es: 'Regístrate',
    pt: 'Inscrever-se',
  },
  'signup.dlocalCreateAccount': {
    en: 'Create your account in dLocal Go',
    es: 'Crea tu cuenta en dLocal Go',
    pt: 'Crie sua conta em dLocal Go',
  },
  'signUp.personalInformation.label': {
    en: 'Personal information',
    es: 'Datos personales',
    pt: 'Dados pessoais',
  },
  'signUp.register.btn': {
    en: 'Create account',
    es: 'Crear cuenta',
    pt: 'Criar conta',
  },
  'signUp.alreadyHaveAccount': {
    en: 'Already have an account?',
    es: '¿Tenés una cuenta?',
    pt: 'Já tem uma conta?',
  },
  'signUp.functionalCurrency.description': {
    en: 'This value cannot be modified after the account is created. The selected currency will be used for balances and settlements.',
    es: 'Este valor no se puede modificar después de crear la cuenta. La moneda seleccionada se utilizará para saldos y liquidaciones.',
    pt: 'Este valor não pode ser modificado após a criação da conta. A moeda selecionada será usada para saldos e liquidações.',
  },
  'signUp.error': {
    en: 'Something went wrong. Please try again.',
    es: 'Algo no salió bien. Por favor, intenta nuevamente.',
    pt: 'Ocorreu um erro. Por favor, tente novamente.',
  },
  'msgError.signUp.emailAlreadyRegistered': {
    en: 'There is already a registered user with that email.',
    es: 'Ya existe un usuario registrado con ese email.',
    pt: 'Já existe um usuário registrado com esse e-mail.',
  },
  'signUp.termsAndConditions.label': {
    en: 'I accept the',
    es: 'Acepto los',
    pt: 'Eu aceito os',
  },
  'signUp.termsAndConditions': {
    en: 'terms and conditions',
    es: 'términos y condiciones',
    pt: 'termos e condições',
  },
  'signUp.policy.label': {
    en: 'and I agree with the',
    es: 'y estoy de acuerdo con las',
    pt: 'e concordo com as',
  },
  'signUp.policy': {
    en: 'privacy policies.',
    es: 'políticas de privacidad.',
    pt: 'políticas de privacidade.',
  },
  'signUp.termsAndConditions.title': {
    en: 'Terms and conditions',
    es: 'Términos y condiciones',
    pt: 'Termos e condições',
  },
  'signUp.successMessage.title': {
    en: 'Successful registration!',
    es: '¡Registro exitoso!',
    pt: 'Registro bem-sucedido!',
  },
  'signUp.successMessage.body': {
    en: 'To continue, login to your account.',
    es: 'Para continuar, ingrese a su cuenta.',
    pt: 'Para continuar, faça login na sua conta.',
  },
  'signUp.emailSent.message.title': {
    en: 'Verify your email.',
    es: 'Verifica tu correo electrónico.',
    pt: 'Verifique seu e-mail.',
  },
  'signUp.emailSent.message.desc': {
    en: '<br>Enter to <b>{0}</b> and verify your account in the email we sent you.',
    es: '<br>Ingresa a <b>{0}</b> y verifica tu cuenta en el correo que te enviamos.',
    pt: '<br>Entre em <b>{0}</b> e verifique sua conta no e-mail que lhe enviamos.',
  },
  'passwordReset.successMessage.title': {
    en: 'Successful password reset!',
    es: '¡Restablecimiento de contraseña exitoso!',
    pt: 'Senha redefinida com sucesso!',
  },
  'passwordReset.successMessage.body': {
    en: 'To continue, login to your account.',
    es: 'Para continuar, ingrese a su cuenta.',
    pt: 'Para continuar, faça login na sua conta.',
  },
  'signUp.goToLogin.btn': {
    en: 'Login',
    es: 'Iniciar sesión',
    pt: 'Iniciar sessão',
  },
  'signUp.intro': {
    en: 'Start offering <span>local payment methods</span> to your customers today',
    es: 'Comienza hoy a ofrecer <span>métodos de pagos locales</span> a tus clientes',
    pt: 'Comece a oferecer <span>métodos de pagamento locais</span> aos seus clientes hoje mesmo',
  },
  'signUp.intro.1.title': {
    en: "Don't lose sales",
    es: 'No pierdas ventas',
    pt: 'Não perca vendas',
  },
  'signUp.intro.1.subtitle': {
    en: 'Accept payments by card, transfer or cash.',
    es: 'Acepta pagos con tarjeta, transferencia o efectivo.',
    pt: 'Aceite pagamentos com cartão, transferência ou dinheiro.',
  },
  'signUp.intro.2.title': {
    en: 'Pay as you go',
    es: 'Paga por uso',
    pt: 'Pague por uso',
  },
  'signUp.intro.2.subtitle': {
    en: 'Charge from 1 USD and pay per successful transaction.',
    es: 'Cobra desde 1 USD y paga por transacción exitosa.',
    pt: 'Cobre a partir de 1 USD e pague por transação bem-sucedida.',
  },
  'signUp.intro.3.title': {
    en: 'Help your clients',
    es: 'Ayuda a tus clientes',
    pt: 'Ajude seus clientes',
  },
  'signUp.intro.3.subtitle': {
    en: "Accept payments in your customer's local currency.",
    es: 'Acepta pagos en la moneda local de tu cliente.',
    pt: 'Aceite pagamentos na moeda local do seu cliente.',
  },
  'menu.item.viewLess': {
    en: 'View less',
    es: 'Ver menos',
    pt: 'Ver menos',
  },
  'menu.item.HOME': {
    en: 'Home',
    es: 'Inicio',
    pt: 'Inicio',
  },
  'menu.item.HOME.desc': {
    en: 'Status of your current level and balances.',
    es: 'Estado de su nivel actual y balances.',
    pt: 'Status do seu nível e saldos atuais.',
  },
  'menu.item.REPORTS': {
    en: 'Reports',
    es: 'Reportes',
    pt: 'Relatórios',
  },
  'menu.item.ACTIVATE_ACCOUNT': {
    es: 'Configuración de cuenta',
    en: 'Account settings',
    pt: 'Configuração da conta',
  },
  'menu.item.PAYMENT_INTEGRATION': {
    en: 'Integrations',
    es: 'Integraciones',
    pt: 'Integrações',
  },
  'menu.item.PAYMENT_INTEGRATION.desc': {
    en: `Find the information you need to integrate your site with ${brandName} checkout.`,
    es: `Encuentra la información que necesitas para integrar tu sitio con el checkout de ${brandName}.`,
    pt: `Encontre as informações necessárias para integrar seu site ao checkout ${brandName}.`,
  },
  'menu.item.COMPANY_INFORMATION': {
    en: 'Company data',
    es: 'Datos de su negocio',
    pt: 'Dados da empresa',
  },
  'menu.item.COMPANY_INFORMATION.desc': {
    en: 'Information about your company and your account level.',
    es: 'Información de su negocio y del nivel de su cuenta.',
    pt: 'Informações sobre sua empresa e seu nível de conta.',
  },
  'menu.subItem.EMAIL_VALIDATION': {
    en: 'Email confirmation',
    es: 'Confirmación de correo electrónico',
    pt: 'Confirmação de e-mail',
  },
  'menu.subItem.EMAIL_VALIDATION.desc': {
    en: 'Please confirm your email.',
    es: 'Por favor, confirma tu correo electrónico.',
    pt: 'Por favor, confirme seu e-mail.',
  },
  'menu.item.PAYMENT_LINK': {
    en: 'Payment link',
    es: 'Link de pago',
    pt: 'Link de pagamento',
  },
  'menu.subItem.CREATE_PAYMENT_LINK': {
    en: 'Create a payment link',
    es: 'Crear link de pago',
    pt: 'Criar link de pagamento',
  },
  'menu.subItem.CREATED_PAYMENT_LINKS': {
    en: 'Your payments links',
    es: 'Tus links de pago',
    pt: 'Seus links de pagamento',
  },
  'menu.item.PAYMENT_METHODS': {
    en: 'Payment methods',
    es: 'Métodos de pago',
    pt: 'Métodos de pagamento',
  },
  'menu.item.PAYMENT_METHODS.title': {
    en: 'Select countries and payments methods',
    es: 'Selecciona países y métodos de pago',
    pt: 'Selecione os países e métodos de pagamento',
  },
  'menu.item.PAYMENT_METHODS.desc': {
    en: 'Choose where and how you want to operate.',
    es: 'Elige dónde y cómo quieres operar.',
    pt: 'Escolha onde e como você desejar operar.',
  },
  'menu.item.TRANSACTIONS': {
    en: 'Transactions',
    es: 'Transacciones',
    pt: 'Transações',
  },
  'menu.item.SETTLEMENTS': {
    en: 'Settlements',
    es: 'Retirar fondos',
    pt: 'Retirar fundos',
  },
  'menu.item.SETTLEMENTS.desc': {
    en: 'Information about your settlements.',
    es: 'Información de sus retiros.',
    pt: 'Informações sobre suas retiradas.',
  },
  'menu.item.VIRTUAL_POS': {
    en: 'Virtual POS',
    es: 'POS Virtual',
    pt: 'POS Virtual',
  },
  'menu.item.VIRTUAL_POS.desc': {
    en: 'Create a new payment here.',
    es: 'Cree un nuevo pago aquí.',
    pt: 'Crie um novo pagamento aqui.',
  },
  'menu.item.PAYMENT_BUTTON': {
    en: 'Payment button',
    es: 'Botón de pago',
    pt: 'Botão de pagamento',
  },
  'menu.item.REFUNDS': {
    en: 'Refunds',
    es: 'Reembolsos',
    pt: 'Reembolsos',
  },
  'menu.item.REFUNDS.desc': {
    en: 'Information about your refunds.',
    es: 'Información de sus reembolsos.',
    pt: 'Informações sobre seus reembolsos.',
  },
  'menu.item.CLIENTS': {
    en: 'Clients',
    es: 'Clientes',
    pt: 'Clientes',
  },
  'menu.item.CLIENTS.desc': {
    en: 'Information from your clients.',
    es: 'Información de sus clientes.',
    pt: 'Informações de seus clientes.',
  },
  'menu.item.BANK_ACCOUNTS': {
    en: 'My bank accounts',
    es: 'Mis cuentas bancarias',
    pt: 'Minhas contas bancárias',
  },
  'menu.item.SUBSCRIPTIONS': {
    en: 'Subscriptions',
    es: 'Suscripciones',
    pt: 'Assinaturas',
  },
  'genericError.title': {
    en: 'An error has occurred!',
    es: '¡Ha ocurrido un error!',
    pt: 'Ocorreu um erro!',
  },
  'genericError.message': {
    en: 'Something went wrong. Please try again later.',
    es: 'Algo no salió bien. Por favor, intenta nuevamente más tarde.',
    pt: 'Ocorreu um erro. Por favor, tente novamente mais tarde.',
  },
  'genericError.section.message': {
    en: 'We are unable to display this section at this time.',
    es: 'No podemos mostrar esta sección en este momento.',
    pt: 'Não podemos exibir esta seção no momento.',
  },
  'paymentMethods.coverageSubtitle': {
    en: 'Countries',
    es: 'Países',
    pt: 'Países',
  },
  'paymentMethods.coverageDesc': {
    en: 'Choose the countries in which you want to operate',
    es: 'Elige los países en los que quieras operar',
    pt: 'Escolha os países onde deseja operar',
  },
  'paymentMethods.coverageSave': {
    en: 'Save changes',
    es: 'Guardar cambios',
    pt: 'Salvar alterações',
  },
  'paymentMethod.editCoverage': {
    en: 'Edit coverage',
    es: 'Editar cobertura',
    pt: 'Editar cobertura',
  },
  'paymentMethod.coverage.allCountries': {
    en: 'All countries',
    es: 'Todos los países',
    pt: 'Todos os países',
  },
  'paymentMethod.confirmCoverage.title': {
    en: 'Do you want to confirm the changes?',
    es: '¿Desea confirmar los cambios?',
    pt: 'Deseja confirmar as alterações?',
  },
  'paymentMethod.confirmCoverage.msg': {
    en: 'You will be no longer operating in:',
    es: 'Dejarás de operar en:',
    pt: 'Você não estará mais operando em:',
  },
  'paymentMethods.subtitle': {
    en: 'Payment methods',
    es: 'Métodos de pago',
    pt: 'Métodos de pagamento',
  },
  'paymentMethods.desc': {
    en: 'Choose which payment method you want to offer in each country',
    es: 'Elige qué método de pago deseas ofrecer en cada país',
    pt: 'Escolha os métodos de pagamento que você deseja habilitar em cada país',
  },
  'paymentMethods.noSelection.title': {
    en: 'You have not selected any payment methods. Click below to start setting up payment methods for your checkout',
    es: 'No ha seleccionado ningún método de pago. Haga clic debajo para comenzar a agregar métodos de pago a su checkout',
    pt: 'Você não selecionou nenhum método de pagamento. Clique abaixo para começar a configurar as formas de pagamento para seu checkout',
  },
  'paymentMethods.addOtherCoutries.title': {
    en: 'Add payment methods',
    es: 'Agregar métodos de pago',
    pt: 'Adicionar métodos de pagamento',
  },
  'paymentMethod.details': {
    en: 'Payment method detail',
    es: 'Detalle de método de pago',
    pt: 'Detalhe da forma de pagamento',
  },
  'paymentMethod.description.type': {
    en: 'Type:',
    es: 'Tipo:',
    pt: 'Tipo:',
  },
  'paymentMethod.description.provider': {
    en: 'Provider:',
    es: 'Proveedor:',
    pt: 'Fornecedor:',
  },
  'paymentMethod.description.more': {
    en: 'See details',
    es: 'Ver detalles',
    pt: 'Veja detalhes',
  },
  'paymentMethodList.message.title': {
    en: 'You already have chosen the countries in which you want to operate!',
    es: '¡Ya tenés elegidos los países en los que querés operar!',
    pt: 'Você já selecionou os países onde deseja operar!',
  },
  'paymentMethodList.message.body': {
    en: 'Continue by choosing the payment methods you want to offer.',
    es: 'Continúa eligiendo los métodos de pago que deseas ofrecer.',
    pt: 'Continue selecionando os métodos de pagamento que deseja habilitar',
  },
  'countryCode.AR': {
    en: 'Argentina',
    es: 'Argentina',
    pt: 'Argentina',
  },
  'countryCode.BO': {
    en: 'Bolivia',
    es: 'Bolivia',
    pt: 'Bolívia',
  },
  'countryCode.BR': {
    en: 'Brazil',
    es: 'Brasil',
    pt: 'Brasil',
  },
  'countryCode.CL': {
    en: 'Chile',
    es: 'Chile',
    pt: 'Chile',
  },
  'countryCode.CO': {
    en: 'Colombia',
    es: 'Colombia',
    pt: 'Colômbia',
  },
  'countryCode.CR': {
    en: 'Costa Rica',
    es: 'Costa Rica',
    pt: 'Costa Rica',
  },
  'countryCode.DO': {
    en: 'Dominican Republic',
    es: 'República Dominicana',
    pt: 'República Dominicana',
  },
  'countryCode.EC': {
    en: 'Ecuador',
    es: 'Ecuador',
    pt: 'Equador',
  },
  'countryCode.ES': {
    en: 'Spain',
    es: 'España',
    pt: 'Espanha',
  },
  'countryCode.GT': {
    en: 'Guatemala',
    es: 'Guatemala',
    pt: 'Guatemala',
  },
  'countryCode.ID': {
    en: 'Indonesia',
    es: 'Indonesia',
    pt: 'Indonésia',
  },
  'countryCode.KE': {
    en: 'Kenya',
    es: 'Kenia',
    pt: 'Quênia',
  },
  'countryCode.MX': {
    en: 'Mexico',
    es: 'México',
    pt: 'México',
  },
  'countryCode.MY': {
    en: 'Malaysia',
    es: 'Malasia',
    pt: 'Malásia',
  },
  'countryCode.NG': {
    en: 'Nigeria',
    es: 'Nigeria',
    pt: 'Nigéria',
  },
  'countryCode.PA': {
    en: 'Panama',
    es: 'Panamá',
    pt: 'Panamá',
  },
  'countryCode.PE': {
    en: 'Peru',
    es: 'Perú',
    pt: 'Peru',
  },
  'countryCode.PY': {
    en: 'Paraguay',
    es: 'Paraguay',
    pt: 'Paraguai',
  },
  'countryCode.US': {
    en: 'United States',
    es: 'Estados Unidos',
    pt: 'Estados Unidos',
  },
  'countryCode.UY': {
    en: 'Uruguay',
    es: 'Uruguay',
    pt: 'Uruguai',
  },
  'region.LA': {
    en: 'Latin America',
    es: 'América Latina',
    pt: 'América Latina',
  },
  'region.AF_ME': {
    en: 'Africa and Middle East',
    es: 'África y Medio Oriente',
    pt: 'África e Oriente Médio',
  },
  'region.AS': {
    en: 'Asia',
    es: 'Asia',
    pt: 'Ásia',
  },
  'paymentMethod.creditCardDisabled': {
    en: 'You cannot offer <b>cards</b> as a form of payment at your current level. For more information about your current level and how to increase it click',
    es: 'No puedes ofrecer <b>tarjetas</b> como forma de pago en tu nivel actual. Para más información sobre tu nivel actual y como incrementarlo haz clic',
    pt: 'Você não pode oferecer <b>cartões</b> como forma de pagamento em seu nível atual. Para obter mais informações sobre o seu nível atual e como aumentá-lo, clique',
  },
  'paymentMethod.creditCardDisabled.here': {
    en: 'here.',
    es: 'aquí.',
    pt: 'aqui.',
  },
  'paymentMethod.table.title': {
    en: 'Add payment methods',
    es: 'Agregar métodos de pago',
    pt: 'Adicionar formas de pagamento',
  },
  'paymentMethod.confirm.title': {
    en: 'Confirm',
    es: 'Confirmar',
    pt: 'Confirmar',
  },
  'paymentMethod.confirm.body': {
    en: 'Do you want to stop offering the selected payment method?',
    es: '¿Desea dejar de ofrecer el método de pago seleccionado?',
    pt: 'Deseja deixar de oferecer o método de pagamento selecionado?',
  },
  'paymentMethod.action': {
    en: 'Action',
    es: 'Acción',
    pt: 'Ação',
  },
  'paymentMethod.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'paymentMethod.name': {
    en: 'Name',
    es: 'Nombre',
    pt: 'Nome',
  },
  'paymentMethod.type': {
    en: 'Type',
    es: 'Tipo',
    pt: 'Tipo',
  },
  'paymentMethod.commission': {
    en: 'Commission',
    es: 'Comisión',
    pt: 'Comissão',
  },
  'paymentMethod.provider': {
    en: 'Provider',
    es: 'Proveedor',
    pt: 'Fornecedor',
  },
  'paymentMethod.available': {
    en: 'Available',
    es: 'Disponible',
    pt: 'Disponível',
  },
  'paymentMethod.notAvailable': {
    en: 'Not available',
    es: 'No disponible',
    pt: 'Não disponível',
  },
  'paymentMethod.status': {
    en: 'Status',
    es: 'Estado',
    pt: 'Estado',
  },
  'paymentMethod.table.notAvailable': {
    en: 'Not available',
    es: 'No disponible',
    pt: 'Não disponível',
  },
  'paymentMethod.type.VOUCHER': {
    en: 'Cash',
    es: 'Efectivo',
    pt: 'Dinheiro',
  },
  'paymentMethod.type.BANK_TRANSFER': {
    en: 'Bank transfer',
    es: 'Transferencia bancaria',
    pt: 'Transferência bancária',
  },
  'paymentMethod.type.CREDIT_CARD': {
    en: 'Card',
    es: 'Tarjeta',
    pt: 'Cartão',
  },
  'paymentMethod.type.ONLINE_BANK_TRANSFER': {
    en: 'Online bank transfer',
    es: 'Transferencia bancaria en línea',
    pt: 'Transferência bancária online',
  },
  'paymentMethod.note': {
    en: '* Commissions do not include IVA.',
    es: '* Las comisiones no incluyen IVA.',
    pt: '* As comissões não incluem IVA.',
  },
  'subscriptionForm.subtitle': {
    en: 'Please, complete the following information to enter your payment method.',
    es: 'Por favor, complete la siguiente información para ingresar su método de pago.',
    pt: 'Por favor, preencha as seguintes informações para inserir sua forma de pagamento.',
  },
  'subscriptionForm.subtitleUpdate': {
    en: 'Please, fill out the following information to update your payment method.',
    es: 'Por favor, complete la siguiente información para actualizar su método de pago.',
    pt: 'Por favor, preencha as seguintes informações para atualizar sua forma de pagamento.',
  },
  'subscriptionForm.btn': {
    en: 'Subscribe',
    es: 'Suscribirse',
    pt: 'Inscrever-se',
  },
  'subscriptionForm.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'subscriptionForm.name': {
    en: 'Name',
    es: 'Nombre',
    pt: 'Nome',
  },
  'subscriptionForm.documentType': {
    en: 'Document type',
    es: 'Tipo de documento',
    pt: 'Tipo de documento',
  },
  'subscriptionForm.document': {
    en: 'Document',
    es: 'Documento',
    pt: 'Documento',
  },
  'smartField.cardField': {
    en: 'Credit card',
    es: 'Tarjeta de crédito',
    pt: 'Cartão de crédito',
  },
  'smartField.fees': {
    en: 'Fees to pay',
    es: 'Cuotas a pagar',
    pt: 'Parcelas a pagar',
  },
  'smartField.fees.MX': {
    en: 'Fees to pay',
    es: 'Meses a pagar',
    pt: 'Parcelas a pagar',
  },
  'smartField.defaultOptions': {
    en: 'Enter the card number first',
    es: 'Ingrese primero el número de tarjeta',
    pt: 'Insira o número do cartão primeiro',
  },
  'smartField.withInterest.singular': {
    en: ' installment - With interest of the issuing bank',
    es: ' cuota - Con interés del banco emisor.',
    pt: ' parcela - Com juros do banco emissor.',
  },
  'smartField.withInterest.prural': {
    en: ' installments - With interest of the issuing bank',
    es: ' cuotas - Con interés del banco emisor.',
    pt: ' parcelas - Com juros do banco emissor.',
  },
  'smartField.withInterest.singular.MX': {
    en: ' installment - With interest of the issuing bank',
    es: ' mes - Con interés del banco emisor.',
    pt: ' parcela - Com juros do banco emissor.',
  },
  'smartField.withInterest.prural.MX': {
    en: ' installments - With interest of the issuing bank',
    es: ' meses - Con interés del banco emisor.',
    pt: ' parcelas - Com juros do banco emissor.',
  },
  'paymentHistory.date': {
    en: 'Date',
    es: 'Fecha',
    pt: 'Data',
  },
  'paymentHistory.desc': {
    en: 'Description',
    es: 'Descripción',
    pt: 'Descrição',
  },
  'paymentHistory.period': {
    en: 'Period',
    es: 'Período',
    pt: 'Período',
  },
  'paymentHistory.paymentMethod': {
    en: 'Payment method',
    es: 'Método de pago',
    pt: 'Método de pagamento',
  },
  'paymentHistory.amount': {
    en: 'Amount',
    es: 'Monto',
    pt: 'Montante',
  },
  'companyInformation.levelOne.desc': {
    en: `Before you can accept payments with ${brandName}, we need to know more about you and your company. We collect this information to meet the requirements of regulatory authorities and our {0}.`,
    es: `Antes de que puedas aceptar pagos con ${brandName}, necesitamos saber más sobre ti y su negocio. Recogemos esta información para cumplir con los requisitos de las autoridades reguladoras y nuestros {0}.`,
    pt: `Antes de poder aceitar pagamentos com o ${brandName}, precisamos saber mais sobre você e sua empresa. Coletamos essas informações para atender aos requisitos das autoridades reguladoras e nossos {0}.`,
  },
  'companyInformation.needKnowMore.desc': {
    en: `Before you can continue accepting payments with <b>${brandName}</b>, we need to know more about you and your company. We collect this information to comply with the requirements of regulatory authorities and our {0}.`,
    es: `Antes de que puedas continuar aceptando pagos con <b>${brandName}</b>, necesitamos saber más sobre ti y su negocio. Recogemos esta información para cumplir con los requisitos de las autoridades reguladoras y nuestros {0}.`,
    pt: `Antes de continuar aceitando pagamentos com <b>${brandName}</b>, precisamos saber mais sobre você e sua empresa. Coletamos essas informações para cumprir os requisitos das autoridades regulatórias e de nossa {0}.`,
  },
  'companyInformation.levelTwo.btn': {
    en: 'Complete required information',
    es: 'Completar información requerida',
    pt: 'Preencha as informações necessárias',
  },
  'companyInformation.kyc.info': {
    en: '<b>IMPORTANT:</b> The data entered in this section will be used for withdrawals to your bank account.',
    es: '<b>IMPORTANTE:</b> Los datos ingresados en esta sección serán utilizados para los retiros a su cuenta bancaria.',
    pt: '<b>IMPORTANTE:</b> Os dados inseridos nesta seção serão usados ​​para saques em sua conta bancária.',
  },
  'companyInformation.kyc.required': {
    en: 'This field is required.',
    es: 'Este campo es requerido.',
    pt: 'Este campo é obrigatório.',
  },
  'companyInformation.kyc.invalid': {
    en: 'Invalid value',
    es: 'Valor inválido',
    pt: 'Valor inválido',
  },
  'companyInformation.kyc.documentAlreadyInUse': {
    en: 'Document registered in another account',
    es: 'Documento ingresado en otra cuenta',
    pt: 'Número de documento já registrado em outra conta',
  },
  'companyInformation.kyc.websiteAlreadyInUse': {
    en: 'Website registered into another account',
    es: 'Sitio web registrado en otra cuenta',
    pt: 'Site registrado em outra conta',
  },
  'companyInformation.kyc.tooShort': {
    en: 'The entered value is too short.',
    es: 'El valor ingresado es demasiado corto.',
    pt: 'O valor inserido é muito curto.',
  },
  'companyInformation.kyc.naturalPerson': {
    en: 'Natural person',
    es: 'Persona física',
    pt: 'Pessoa física',
  },
  'companyInformation.kyc.company': {
    en: 'Company',
    es: 'Persona jurídica',
    pt: 'Pessoa jurídica',
  },
  'companyInformation.kyc.lastName': {
    en: 'Last name',
    es: 'Apellido',
    pt: 'Sobrenome',
  },
  'companyInformation.kyc.name.company': {
    en: 'Entity name',
    es: 'Nombre legal de su negocio',
    pt: 'Nome da empresa',
  },
  'companyInformation.kyc.name.physical': {
    en: 'Name',
    es: 'Nombre',
    pt: 'Nome',
  },
  'companyInformation.kyc.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'companyInformation.kyc.documentType': {
    en: 'Document type',
    es: 'Tipo de documento',
    pt: 'Tipo de documento',
  },
  'companyInformation.kyc.documentTypes.SSN': {
    en: 'Last 4 digits of Social Security Number',
    es: 'Últimos 4 dígitos del número de seguridad social',
    pt: 'Últimos 4 dígitos do número de segurança social',
  },
  'companyInformation.kyc.document': {
    en: 'Document number',
    es: 'Número de documento',
    pt: 'Número de documento',
  },
  'companyInformation.kyc.phone': {
    en: 'Mobile phone',
    es: 'Teléfono móvil',
    pt: 'Telefone celular',
  },
  'companyInformation.kyc.subIndustry': {
    en: 'Industry',
    es: 'Industria',
    pt: 'Indústria',
  },
  'companyInformation.kyc.subIndustry.company/physical': {
    en: 'Industry',
    es: 'Industria o rubro',
    pt: 'Indústria ou categoria',
  },
  'companyInformation.kyc.subIndustryNotSupported': {
    en: 'Industry not supported',
    es: 'Industria no soportada',
    pt: 'Indústria não suportado',
  },
  'companyInformation.kyc.markets': {
    en: 'Markets you are interested in targeting with us',
    es: 'Mercados que le interesa trabajar con nosotros',
    pt: 'Mercados em que você está interessado em trabalhar conosco',
  },
  'companyInformation.kyc.operationYears': {
    en: 'Years in operation',
    es: 'Cantidad de años operando',
    pt: 'Número de anos de operação',
  },
  'companyInformation.kyc.website.placeholder': {
    en: 'www.my-company.com',
    es: 'www.my-company.com',
    pt: 'www.my-company.com',
  },
  'companyInformation.kyc.website': {
    en: 'Website',
    es: 'Sitio web',
    pt: 'Website',
  },
  'companyInformation.kyc.website.description': {
    en: "The <b>Website</b> entered will be used to verify the origin when using plugin integrations. If you don't have a website, enter your company/marketplace page on social media (Facebook, Instagram, Twitter, etc.). It is <b>NOT</b> allowed to use personal social networks as a website.",
    es: 'El <b>Sitio web</b> ingresado se utilizará para verificar el origen al usar integraciones de complementos. Si no tiene un sitio web, ingrese la página de su negocio/marketplace en redes sociales (Facebook, Instagram, Twitter, etc.). <b>NO</b> se permite utilizar redes sociales personales como sitio web.',
    pt: 'O <b>Site</b> inserido será usado para verificar a origem ao usar integrações de plug-ins. Se você não possui um site, insira a página da sua empresa/marketplace nas redes sociais (Facebook, Instagram, Twitter, etc.). <b>NÃO</b> é permitido o uso de redes sociais pessoais como website.',
  },
  'companyInformation.kyc.important': {
    en: 'Important',
    es: 'Importante',
    pt: 'Importante',
  },
  'companyInformation.kyc.warning.functionalCurrency': {
    en: 'dLocal Go displays on your balance sheet all transactions made in different countries in a single currency, which can be in dollars or in your local currency, according to your preference. <b>Please note that this selection cannot be changed later.</b>',
    es: 'dLocal Go expone en tu balance todas las ventas realizadas en los diferentes países en una única moneda, que puede ser en dólares o en tu moneda local, lo que tú prefieras. <b>Ten en cuenta que esta elección no podrá modificarse luego.</b>',
    pt: 'A dLocal Go unifica em seu saldo todas as vendas realizadas nos diversos países em uma única moeda, que pode ser em dólares ou em sua moeda local, conforme sua preferência. <b>Observe que esta escolha não pode ser alterada posteriormente.</b>',
  },
  'companyInformation.kyc.warning.country': {
    en: 'Country where the company is registered and where you will receive your withdrawals.',
    es: 'País donde se encuentra registrado su negocio y donde recibirás tus retiros.',
    pt: 'País onde sua empresa está registrada e da conta bancária onde você receberá seu dinheiro.',
  },

  'companyInformation.kyc.companyForm.title': {
    en: 'Upload your company data',
    es: 'Carga los datos de su negocio',
    pt: 'Carregue os dados de sua empresa',
  },
  'companyInformation.kyc.companyForm.title.physical': {
    en: 'Complete your basic information',
    es: 'Completa tus datos básicos',
    pt: 'Adicione seus dados básicos',
  },

  'companyInformation.kyc.companyForm.description': {
    en: "Complete the form with the commercial and legal information of your company. Don't worry, the process is quick and we will guide you at all times.",
    es: 'Completa el formulario con la información comercial y legal de su negocio. No te preocupes, el proceso es rápido y te guiaremos en todo momento.',
    pt: 'Complete o formulário com a informação comercial e legal de sua empresa. Não se preocupe, o processo é rápido e vamos guiá-lo a todo momento.',
  },

  'companyInformation.kyc.companyForm.description.company/physical': {
    en: "This step won't take you more than 5 minutes.",
    es: 'Este paso no te llevará mas de 5 minutos.',
    pt: 'Este passo não irá demorar mais do que 5 minutos.',
  },

  'companyInformation.kyc.companyForm.businessType': {
    en: 'Choose your type of company',
    es: 'Elige tu tipo de negocio',
    pt: 'Escolha seu tipo de empresa',
  },

  'companyInformation.kyc.companyForm.businessData': {
    en: 'Company data',
    es: 'Datos de su negocio',
    pt: 'Dados de sua empresa',
  },

  'companyInformation.kyc.companyForm.allDataRequired': {
    en: 'All fields are required.',
    es: 'Todos los campos son obligatorios.',
    pt: 'Todos os campos são necessários.',
  },

  'companyInformation.kyc.companyForm.businessInformation': {
    en: 'Commercial information',
    es: 'Información Comercial',
    pt: 'Informação Comercial',
  },

  'companyInformation.kyc.companyForm.legalInformation': {
    en: 'Legal information',
    es: 'Información Legal',
    pt: 'Informação Legal',
  },

  'companyInformation.kyc.companyForm.contactInformation': {
    en: 'Contact information',
    es: 'Datos de Contacto',
    pt: 'Dados de Contato',
  },
  'companyInformation.kyc.companyForm.dataWhoRegistersAccount': {
    en: 'Contact information',
    es: 'Datos de quien registra la cuenta',
    pt: 'Dados de Contato',
  },
  'companyInformation.kyc.companyForm.roles.EMPLOYEE': {
    en: 'Employee',
    es: 'Empleado',
    pt: 'Funcionário',
  },
  'companyInformation.kyc.companyForm.roles.OWNER': {
    en: 'Owner',
    es: 'Dueño',
    pt: 'Proprietário',
  },
  'companyInformation.kyc.companyForm.roles.PARTNER': {
    en: 'Partner',
    es: 'Socio',
    pt: 'Parceiro',
  },
  'companyInformation.kyc.companyForm.roles.LEGAL_REPRESENTATIVE': {
    en: 'Legal Representative',
    es: 'Representante legal',
    pt: 'Representante legal',
  },
  'companyInformation.kyc.companyForm.roles.OTHER': {
    en: 'Other',
    es: 'Otro',
    pt: 'Outro',
  },
  'companyInformation.kyc.companyForm.roles.CFO': {
    en: 'CFO',
    es: 'CFO',
    pt: 'CFO',
  },
  'companyInformation.kyc.companyForm.roles.CTO': {
    en: 'CTO',
    es: 'CTO',
    pt: 'CTO',
  },
  'companyInformation.kyc.companyForm.roles.CCO': {
    en: 'CCO',
    es: 'CCO',
    pt: 'CCO',
  },
  'companyInformation.kyc.companyForm.roles.CIO': {
    en: 'CIO',
    es: 'CIO',
    pt: 'CIO',
  },
  'companyInformation.kyc.companyForm.roles.CMO': {
    en: 'CMO',
    es: 'CMO',
    pt: 'CMO',
  },
  'companyInformation.kyc.companyForm.roles.FINANCIAL_OFFICER_MANAGER': {
    en: 'Financial officer manager',
    es: 'Director financiero',
    pt: 'Diretor financeiro',
  },
  'companyInformation.kyc.companyForm.roles.TECHNOLOGY_OFFICER_MANAGER': {
    en: 'Technology officer manager',
    es: 'Director de tecnología',
    pt: 'Diretor de tecnologia',
  },
  'companyInformation.kyc.companyForm.roles.COMMERCIAL_MANAGER': {
    en: 'Commercial manager',
    es: 'Director comercial',
    pt: 'Diretor comercial',
  },
  'companyInformation.kyc.companyForm.roles.DIRECTOR_OF_OPERATIONS': {
    en: 'Director of operations',
    es: 'Director de operaciones',
    pt: 'Diretor de operações',
  },
  'companyInformation.kyc.companyForm.roles.MARKETING_MANAGER': {
    en: 'Marketing manager',
    es: 'Responsable de marketing',
    pt: 'Responsável de marketing',
  },
  'companyInformation.kyc.companyForm.roles.ECOMMERCE_MANAGER': {
    en: 'E-commerce manager',
    es: 'Responsable de e-commerce',
    pt: 'Responsável  de e-commerce',
  },
  'companyInformation.kyc.companyForm.roles.LEGAL_MANAGER': {
    en: 'Legal manager',
    es: 'Responsable legal',
    pt: 'Responsável legal',
  },
  'companyInformation.kyc.companyForm.roles.PROJECT_MANAGER': {
    en: 'Project manager',
    es: 'Responsable de proyecto (Project manager)',
    pt: 'Responsável  de projeto (Project manager)',
  },
  'companyInformation.kyc.companyNameOrBrand': {
    en: 'Trade name or company brand',
    es: 'Nombre comercial o marca de su negocio',
    pt: 'Nome comercial ou marca da empresa',
  },
  'companyInformation.kyc.companyNameOrBrand.physical': {
    en: 'Trade name or business brand',
    es: 'Nombre comercial o marca de tu negocio',
    pt: 'Nome comercial ou marca da empresa',
  },

  'companyInformation.kyc.companyOriginCountry': {
    en: 'Country of origin of the company',
    es: 'País de origen de su negocio',
    pt: 'País de origem da empresa',
  },
  'companyInformation.kyc.companyOriginCountry.physical': {
    en: 'Country of origin of your business',
    es: 'País de origen de tu negocio',
    pt: 'País de origem do seu negócio',
  },

  'companyInformation.kyc.infopopup.businessName': {
    en: 'This is the name of your brand, with which your company is publicly recognized by your customers.<br />Your clients will see the name of your brand in the communications sent when paying you.',
    es: 'Este es el nombre de tu marca, con el que tu negocio es reconocido públicamente por tus clientes.<br />Tus clientes verán el nombre de tu marca en las comunicaciones que enviemos al momento del pago.',
    pt: 'Este é o nome de sua marca, com o qual sua empresa é reconhecida publicamente por seus clientes.<br />Os clientes verão o nome ou marca da sua empresa nas comunicações que enviamos para eles.',
  },

  'companyInformation.kyc.infopopup.website.1': {
    en: "URL from which you make your sales. If you don't have your own website, enter the marketplace or social networks of your business (Facebook, Instagram, Twitter, etc.).",
    es: 'URL desde la que realizas tus ventas. Si no tienes un sitio propio, ingresa el marketplace o redes sociales de tu negocio (Facebook, Instagram, Twitter, etc.).',
    pt: 'URL a partir da qual você realiza suas vendas. Se não tiver um site próprio, acesse o marketplace ou redes sociais de seu negócio (Facebook, Instagram, Twitter, etc.).',
  },

  'companyInformation.kyc.infopopup.website.1.company/physical': {
    en: 'URL from which you make your sales. Make sure the website is current and is the one you actually use to sell your products or services.',
    es: 'URL desde la que realizas tus ventas. Asegúrate de que el sitio web esté vigente y sea el que realmente usas para vender tus productos o servicios.',
    pt: 'URL a partir da qual você realiza suas vendas. Certifique-se de que o site está vigente e seja aquele que realmente usa para vender seus produtos e serviços.',
  },

  'companyInformation.kyc.infopopup.website.2': {
    en: 'You may not use a personal social network as a website for your company.',
    es: 'No puedes usar una red social personal como sitio web para tu negocio.',
    pt: 'Você não pode usar uma rede social pessoal como website para seu negócio.',
  },

  'companyInformation.kyc.infopopup.legalName.company': {
    en: 'Name that you use for formal, legal and tax uses in front of control authorities.',
    es: 'Nombre que empleas para usos formales, jurídicos e impositivos frente a organismos de control.',
    pt: 'Nome que você utiliza para usos formais, jurídicos e impositivos frente aos organismos de controle.',
  },

  'companyInformation.kyc.infopopup.countryCompany': {
    en: 'Country where the company is legally constituted.',
    es: 'País dónde está constituida legalmente su negocio.',
    pt: 'País onde está constituída legalmente a empresa.',
  },

  'companyInformation.kyc.infopopup.functionalCurrency': {
    en: 'This is the currency you will use to view your balances.<br><br>dLocal Go unifies local and international sales in a single currency. You can choose to see your money in your local currency or US Dollars.',
    es: 'Esta es la moneda que usarás para ver tus balances.<br><br>dLocal Go unifica las ventas locales e internacionales en una única moneda. Puedes elegir ver tu dinero en tu moneda local o US Dólar.',
    pt: 'Esta é a moeda que você usará para ver seus balanços.<br><br>dLocal Go unifica as vendas locais e internacionais numa única moeda. Você pode escolher ver seu dinheiro em sua moeda local ou USD Dólar.',
  },

  'companyInformation.kyc.infopopup.legalName.physical': {
    en: 'Name as it appears on your identity document.',
    es: 'Nombre tal como figura en tu documento de identidad.',
    pt: 'Nome que você utiliza para usos formais, jurídicos e impositivos frente aos organismos de controle.',
  },

  'companyInformation.kyc.infopopup.legalSurnamePerson': {
    en: 'Surname as it appears on your identity document.',
    es: 'Apellido tal como figura en tu documento de identidad.',
    pt: 'Nome que você utiliza para usos formais, jurídicos e impositivos frente aos organismos de controle.',
  },

  'companyInformation.kyc.infopopup.countryPerson': {
    en: 'Country from which you sell your products.',
    es: 'País desde el cual vendes tus productos.',
    pt: 'País onde está constituída legalmente a empresa.',
  },

  'companyInformation.kyc.infopopup.country.physical': {
    en: 'Country where you founded your business',
    es: 'País donde fundaste tu negocio',
    pt: 'País onde você fundou seu negócio',
  },

  'companyInformation.kyc.infopopup.country.company': {
    en: 'Country where the company is legally constituted.',
    es: 'País dónde está constituida legalmente su negocio.',
    pt: 'País onde está constituída legalmente a empresa.',
  },

  'companyInformation.kyc.infopopup.BR.CPF': {
    en: '',
    es: 'El CPF  está compuesto por 11 números, sin caracteres especiales y sin espacios.',
    pt: 'O CPF é composto por 11 dígitos, sem caracteres especiais e sem espaços.',
  },
  'companyInformation.kyc.infopopup.BR.CNPJ': {
    en: '',
    es: 'El CNPJ está compuesto por 14 números, sin caracteres especiales y sin espacios.',
    pt: 'O CNPJ é composto por 14 dígitos, sem caracteres especiais e sem espaços.',
  },
  'companyInformation.kyc.infopopup.CO.CC': {
    en: '',
    es: 'El CC o la Cédula de Ciudadanía está compuesto de 6 a 10 números, sin caracteres especiales y sin espacios.',
    pt: '',
  },
  'companyInformation.kyc.infopopup.CO.NIT': {
    en: '',
    es: 'El NIT o Número de Identificación Tributaria está compuesto de 10 a 11 números, sin caracteres especiales ni espacios.',
    pt: '',
  },
  'companyInformation.kyc.infopopup.CO.PASS': {
    en: '',
    es: 'El pasaporte puede tener hasta 12 caracteres alfanuméricos y no debe incluir caracteres especiales ni espacios.',
    pt: '',
  },
  'companyInformation.kyc.infopopup.CO.CE': {
    en: '',
    es: 'El CE o la Cédula de Extranjería puede tener hasta 12 caracteres alfanuméricos y no debe incluir caracteres especiales ni espacios.',
    pt: '',
  },
  'companyInformation.kyc.infopopup.CO.PEP': {
    en: '',
    es: 'El PEP o Permiso Especial de Permanencia puede tener hasta 15 caracteres alfanuméricos y no debe incluir caracteres especiales ni espacios.',
    pt: '',
  },
  'companyInformation.kyc.infopopup.accountCreatorName': {
    en: 'We need the information about who creates dLocal Go account, whether they are the owner of the company or not.',
    es: 'Necesitamos los datos personales de quien crea la cuenta de dLocal Go, sea o no el dueño de su negocio.',
    pt: 'Precisamos dos dados da pessoa que cria a conta dLocal Go, seja ou não o proprietário da empresa.',
  },
  'companyInformation.kycOneMenu.introduction': {
    en: 'Introduction',
    es: 'Introducción',
    pt: 'Introdução',
  },
  'companyInformation.kycOneMenu.typeOfCompany': {
    en: 'Type of company',
    es: 'Tipo de negocio',
    pt: 'Tipo de empresa',
  },
  'companyInformation.kycOneMenu.companyData': {
    en: 'Company data',
    es: 'Datos de su negocio',
    pt: 'Dados da empresa',
  },
  'companyInformation.kycOneMenu.jumio': {
    en: 'Identity verification',
    es: 'Verificación de identidad',
    pt: 'Verificação de identidade',
  },
  'companyInformation.kycTwoMenu.introduction': {
    en: 'Introduction',
    es: 'Introducción',
    pt: 'Introdução',
  },
  'companyInformation.kycTwoMenu.documentation': {
    en: 'Documentation',
    es: 'Documentación',
    pt: 'Documentação',
  },
  'companyInformation.kycTwoMenu.companyData': {
    en: 'Company data',
    es: 'Datos de su negocio',
    pt: 'Dados da empresa',
  },
  'companyInformation.kycTwoMenu.bankAccount': {
    en: 'Bank account',
    es: 'Cuenta bancaria',
    pt: 'Conta bancária',
  },
  'companyInformation.next': {
    en: 'CONTINUE',
    es: 'CONTINUAR',
    pt: 'CONTINUAR',
  },
  'companyInformation.send': {
    en: 'SAVE AND CONTINUE',
    es: 'GUARDAR Y CONTINUAR',
    pt: 'SALVAR E CONTINUAR',
  },
  'msgError.companyInformation.document': {
    en: 'The document entered is incorrect.',
    es: 'El documento ingresado es incorrecto.',
    pt: 'O documento digitado está incorreto.',
  },
  'msgError.companyInformation.countryIndicated': {
    en: 'The selected country is invalid for your functional currency.',
    es: 'El país seleccionado es inválido para su moneda operativa.',
    pt: 'O país selecionado é inválido para sua moeda operacional.',
  },
  'msgError.companyInformation.invalidDocument.headline': {
    en: 'The document entered is incorrect',
    es: 'El documento ingresado es incorrecto',
    pt: 'O documento digitado está incorreto',
  },
  'msgError.companyInformation.invalidDocument.subheadline': {
    en: 'Please enter a new one.',
    es: 'Por favor, ingresa uno nuevo.',
    pt: 'Por favor, insira um novo.',
  },
  'msgError.companyInformation.documentAlreadyInUse.headline': {
    en: 'This document was already registered in another account',
    es: 'Este documento ya fue registrado en otra cuenta',
    pt: 'Este número de documento já foi registrado em outra conta',
  },
  'msgError.companyInformation.documentAlreadyInUse.subheadline': {
    en: 'Please enter a new one.',
    es: 'Por favor, ingresa uno nuevo.',
    pt: 'Por favor, insira um novo.',
  },
  'msgError.companyInformation.websiteAlreadyInUse.headline': {
    en: 'This website was already registered into another account',
    es: 'Este sitio web ya fue registrado en otra cuenta',
    pt: 'Este site já foi registrado em outra conta',
  },
  'msgError.companyInformation.websiteAlreadyInUse.subheadline': {
    en: 'Please enter a different one.',
    es: 'Por favor, ingresa uno diferente.',
    pt: 'Insira um diferente.',
  },
  'companyInformation.kycLevel.desc': {
    en: 'In order to move to <b>level {0}</b> please provide the following documentation:',
    es: 'Para pasar al <b>nivel {0}</b>, proporcione la siguiente documentación:',
    pt: 'Para passar para o <b>nível {0}</b>, forneça a seguinte documentação:',
  },
  'companyInformation.kycLevel.success': {
    en: 'Data received correctly!',
    es: '¡Datos recibidos correctamente!',
    pt: 'Dados recebidos corretamente!',
  },
  'companyInformation.kycTwo.sectionOne.title': {
    en: 'Make the most of dLocal Go.',
    es: 'Aprovecha al máximo dLocal Go.',
    pt: 'Aproveite ao máximo dLocal Go.',
  },
  'companyInformation.kycTwo.sectionOne.subtitle': {
    en: 'Send your company documentation to process unlimited payments and withdraw the money from your sales.',
    es: 'Envía la documentación de tu negocio para procesar pagos sin límite y retirar el dinero de tus ventas.',
    pt: 'Envie a documentação de sua empresa para processar pagamentos sem limite e sacar o dinheiro de suas vendas.',
  },
  'companyInformation.kycTwo.headline': {
    en: 'Send your company documentation',
    es: 'Envía la documentación de tu negocio',
    pt: 'Envie a documentação de sua empresa',
  },
  'companyInformation.kycTwo.headline.company': {
    en: 'Send your company documentation',
    es: 'Envía la documentación de tu negocio',
    pt: 'Envie a documentação de sua empresa',
  },
  'companyInformation.kycTwo.headline.physical': {
    en: 'Send your documentation',
    es: 'Envía tu documentación',
    pt: 'Envie sua documentação',
  },
  'companyInformation.kycTwo.subheadline': {
    en: "Upload the documentation to be able to process unlimited payments. You're almost done!",
    es: 'Carga la documentación para poder procesar pagos sin límites. ¡Ya casi terminas!',
    pt: 'Carregue a documentação para poder processar pagamentos sem limites. Já quase você termina!',
  },
  'companyInformation.kycTwo.subheadline.company': {
    en: 'Upload the documents of {0} that you registered in dLocal Go, to receive unlimited payments and withdraw your money.',
    es: 'Sube los documentos de {0} que registraste en dLocal Go, para recibir pagos sin límite y retirar tu dinero.',
    pt: 'Carregue os documentos de {0} que você registrou na dLocal Go para receber pagamentos sem limites e retirar seu dinheiro.',
  },
  'companyInformation.kycTwo.subheadline.physical': {
    en: 'Upload your documents to receive unlimited payments and withdraw the money from your sales.',
    es: 'Sube tus documentos para recibir pagos sin límite y retirar el dinero de tus ventas.',
    pt: 'Carregue seus documentos para receber pagamentos sem limites e retirar seu dinheiro.',
  },
  'companyInformation.kycTwo.companyOwnerDocuments': {
    en: "Company owner's documents",
    es: 'Documentos del dueño del negocio',
    pt: 'Documentos do dono da empresa',
  },
  'companyInformation.kycTwo.companyOwnerDocuments.physical': {
    en: 'Documentation of {0} {1}',
    es: 'Documentación de {0} {1}',
    pt: 'Documentação de {0} {1}',
  },
  'companyInformation.kycTwo.identityDocumentHelp': {
    en: 'It must match the document rused to register the account. The document file must be current and the copy must be in color.',
    es: 'Debe coincidir con el documento registrado en la cuenta. El documento debe estar vigente y la copia debe ser a color.',
    pt: 'Deve corresponder com o número de documento usado durante o registro da conta. O documento deve estar vigente e a foto/cópia deve ser colorida.',
  },
  'companyInformation.kycTwo.identityDocumentHelp.company': {
    es: `El documento debe: <ul>
      <li>Pertenecer al socio principal o dueño del negocio.</li>
      <li>Tener vigencia mayor a seis meses.</li>
      <li>Estar en buenas condiciones, ser legible y con fotografía nítida.</li>
    </ul>`,
    en: `The document must: <ul>
      <li>Belong to the main partner or owner of the company.</li>
      <li>Be valid for more than six months.</li>
      <li>Be in good condition, be legible and with a clear photograph.</li>
    </ul>`,
    pt: `O documento deve: <ul>
      <li>Pertencer ao sócio principal ou ao dono da empresa.</li>
      <li>Ter vigência maior do que seis meses.</li>
      <li>Estar em boas condições, ser legível e com foto nítida.</li>
    </ul>`,
  },
  'companyInformation.kycTwo.identityDocumentHelp.physical': {
    en: `The document must: <ul>
      <li>Be under the name of {0} {1} and include an identification number.</li>
      <li>Be valid for more than six months.</li>
      <li>Be in good condition, be legible and with a clear photograph.</li>
    </ul>`,
    es: `El documento debe: <ul>
      <li>Estar a nombre de {0} {1} e incluir número de identificación.</li>
      <li>Tener vigencia mayor a seis meses.</li>
      <li>Estar en buenas condiciones, ser legible y con fotografía nítida.</li>
    </ul>`,
    pt: `O documento deve: <ul>
      <li>Estar em nome de  {0} {1} e incluir número de identificação.</li>
      <li>Ter vigência maior do que seis meses.</li>
      <li>Estar em boas condições, ser legível e com foto nítida.</li>
    </ul>`,
  },
  'companyInformation.kycTwo.identityDocument.company/physical': {
    en: 'Identity document',
    es: 'Documento de identidad',
    pt: 'Documento de identidade',
  },

  'companyInformation.kycTwo.tooltip': {
    en: '<b>Tips to upload your documentation:</b><br/><br/><li>Make sure the document is legible and has good definition.</li><li>Maximum document size: {0}MB.</li><li>Allowed extensions: {1}.</li>',
    es: '<b>Recomendaciones para subir tu documentación:</b><br/><br/><li>Asegúrate que el documento sea legible y tenga buena definición.</li><li>Tamaño máximo de los documentos: {0}MB.</li><li>Extensiones permitidas: {1}.</li>',
    pt: '<b>Recomendações para subir sua documentação:</b><br/><br/><li>Assegure-se de que o documento seja legível e tenha boa definição.</li><li>Tamanho máximo dos documentos: {0}MB.</li><li>Extensões permitidas: {1}.</li>',
  },
  'companyInformation.kycTwo.proofOfAddressDocument': {
    en: 'Proof of address of the owner of the company not older than 3 months.',
    es: 'Constancia de domicilio del dueño mayoritario del negocio no mayor a 3 meses de antigüedad.',
    pt: 'Comprovante de residência de domicílio do dono da empresa não superior a 3 meses de antiguidade.',
  },
  'companyInformation.kycTwo.proofOfAddressDocument.company/physical': {
    en: 'Proof of address (utility bill or bank statement)',
    es: 'Constancia de domicilio (factura de servicio o extracto bancario)',
    pt: 'Comprovante de endereço (fatura de serviço ou extrato bancário)',
  },
  'companyInformation.kycTwo.proofOfAddressDocumentHelp.company': {
    en: `The proof must:<ul>
      <li>Be in the name of the main partner or owner of the company.</li>
      <li>Be valid for more than six months.</li></ul>`,
    es: `La constancia debe:<ul>
      <li>Estar a nombre del socio principal o dueño del negocio.</li>
      <li>Tener validez dentro de los últimos 6 meses.</li></ul>`,
    pt: `O comprovante deve:<ul>
      <li>Estar em nome do sócio principal ou dono da empresa.</li>
      <li>Ter validade dentro dos últimos 6 meses.</li></ul>`,
  },
  'companyInformation.kycTwo.proofOfAddressDocumentHelp.physical': {
    en: `The proof must:<ul>
    <li>Be under the name of {0} {1}.</li>
    <li>Be valid for more than six months.</li></ul>`,
    es: `La constancia debe:<ul>
    <li>Estar a nombre de {0} {1}.</li>
    <li>Tener validez dentro de los últimos 6 meses.</li></ul>`,
    pt: `O comprovante deve:<ul>
    <li>Estar em nome de {0} {1}.</li>
    <li>Ter validade dentro dos últimos 6 meses.</li></ul>`,
  },
  'companyInformation.kycTwo.proofOfAddressDocumentHelp': {
    en: 'It must be under the name of the majority owner of the company. It can be a service (electricity, water, taxes).',
    es: 'Debe estar a nombre del dueño mayoritario del negocio. Puede ser un servicio (luz, agua, impuesto).',
    pt: 'Deve conter o nome do sócio majoritário da empresa. Pode ser uma conta de serviço (luz, água, impostos).',
  },
  'companyInformation.kycTwo.proofOfAddressDocumentHelp.NG': {
    en: 'It must be under the name of the majority owner of the company. It can be a service (electricity, water, taxes, rent agreement).',
    es: 'Debe estar a nombre del dueño mayoritario del negocio. Puede ser un servicio (luz, agua, impuesto).',
    pt: 'Deve conter o nome do sócio majoritário da empresa. Pode ser uma conta de serviço (luz, água, impostos).',
  },
  'companyInformation.kycTwo.proofOfAddressDocumentPhysical': {
    en: 'Proof of address of the owner of the company not older than 3 months.',
    es: 'Constancia de domicilio del dueño del negocio no mayor a 3 meses de antigüedad.',
    pt: 'Comprovante de residência de domicílio do dono da empresa não superior a 3 meses de antiguidade.',
  },
  'companyInformation.kycTwo.proofOfAddressDocumentPhysicalHelp': {
    en: 'It must be under the name of the owner of the company. It can be a service (electricity, water, taxes).',
    es: 'Debe estar a nombre del dueño del negocio. Puede ser un servicio (luz, agua, impuesto).',
    pt: 'Deve conter o nome do sócio da empresa. Pode ser uma conta de serviço (luz, água, impostos).',
  },
  'companyInformation.kycTwo.proofOfAddressDocumentPhysicalHelp.NG': {
    en: 'It must be under the name of the owner of the company. It can be a service (electricity, water, taxes, rent agreement).',
    es: 'Debe estar a nombre del dueño del negocio. Puede ser un servicio (luz, agua, impuesto).',
    pt: 'Deve conter o nome do sócio da empresa. Pode ser uma conta de serviço (luz, água, impostos).',
  },
  'companyInformation.kycTwo.companyDocuments.company': {
    en: 'Company documents',
    es: 'Documentos del negocio',
    pt: 'Documentos da empresa',
  },
  'companyInformation.kycTwo.companyDocuments.physical': {
    en: 'Tax registration document',
    es: 'Documento de registro tributario',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.MX': {
    en: 'Tax registration document',
    es: 'Constancia de Situación Fiscal del negocio emitido por SAT',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.CL': {
    en: 'Tax registration document',
    es: 'Constancia de RUT del negocio emitido por SII',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.AR': {
    en: 'Tax registration document',
    es: 'Constancia de CUIT',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.PE': {
    en: 'Tax registration document',
    es: 'Certificado Literal del Registro de Personas Jurídicas emitido por Sunarp',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.CO': {
    en: 'Tax registration document',
    es: 'Constancia de RUT emitido por la DIAN',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.CR': {
    en: 'Tax registration document',
    es: 'Documento de registro tributario',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.UY': {
    en: 'Tax registration document',
    es: 'Constancia de Registro Único Tributario (RUT) ',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.BO': {
    en: 'Tax registration document',
    es: 'Constancia de Número de Identificación Tributaria (NIT)',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.EC': {
    en: 'Tax registration document',
    es: 'Constancia de Registro Único del Contribuyente (RUC)',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.PY': {
    en: 'Tax registration document',
    es: 'Constancia de Registro Único del Contribuyente (RUC)',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.ES': {
    en: 'Tax registration document',
    es: 'Constancia de Código de Identificación Fiscal (CIF)',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.BR': {
    en: 'Tax registration document',
    es: 'Documento de registro tributario',
    pt: 'Cadastro nacional da pessoa jurídica (CNPJ)',
  },
  'companyInformation.kycTwo.taxCompliantDocument.US': {
    en: 'Tax registration document',
    es: 'Documento de registro tributario',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.NG': {
    en: 'Taxpayer identification number (TIN) emited by FIRS-Federal Inland Revenue Service',
    es: 'Documento de registro tributario',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.KE': {
    en: 'Taxpayer Registration Certificate emited by Kenya Revenue Authority',
    es: 'Documento de registro tributario',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocumentHelp.MX': {
    en: 'Official document that gathers all the data of a taxpayer such as RFC, tax regime, economic activities, etc.',
    es: 'Documento oficial que reúne todos los datos de un contribuyente como RFC, régimen fiscal, actividades económicas, etc.',
    pt: 'Documento oficial que reúne todos os dados de um contribuinte como RFC, regime tributário, atividades econômicas, etc.',
  },
  'companyInformation.kycTwo.taxCompliantDocumentHelp.CL': {
    en: 'Single Tax Role Document issued by the Internal Revenue Service.',
    es: 'Documento del Rol Único Tributario emitido por Servicio de Impuestos Internos.',
    pt: 'Documento Único de Função Fiscal emitido pela Receita Federal.',
  },
  'companyInformation.kycTwo.taxCompliantDocumentHelp.BR': {
    en: 'Tax registration document',
    es: 'Documento de registro tributario',
    pt: 'Comprovante de Inscrição e Situação Cadastral',
  },

  'companyInformation.kycTwo.taxCompliantDocumentHelp.company': {
    en: 'The document must match the {0} number you registered in dLocal Go.',
    es: 'El documento debe coincidir con el número de {0} que registraste en dLocal Go.',
    pt: 'O documento deve coincidir com o número de {0} que você registrou na dLocal Go.',
  },

  'companyInformation.kycTwo.taxCompliantDocumentHelp.physical': {
    en: `The document must: <ul>
      <li>Be under the name of {0} {1}</li>
      <li>Match the {2} number you registered in dLocal Go.</li>
    </ul>`,
    es: `El documento debe: <ul>
      <li>Estar a nombre de {0} {1}</li>
      <li>Coincidir con el número de {2} que registraste en dLocal Go.</li>
    </ul>`,
    pt: `O documento deve: <ul>
      <li>Estar em nome de {0} {1}</li>
      <li>Coincidir com o número de {2} que você registrou na dLocal Go.</li>
    </ul>`,
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.MX': {
    en: 'Tax registration document',
    es: 'Constancia de Situación Fiscal emitido por SAT',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.CL': {
    en: 'Tax registration document',
    es: 'Constancia de RUT emitido por SSI',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.AR': {
    en: 'Tax registration document',
    es: 'Constancia de CUIL emitido por AFIP',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.PE': {
    en: 'Tax registration document',
    es: 'Certificado literal de partida emitido por Sunarp',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.CO': {
    en: 'Tax registration document',
    es: 'Constancia de RUT o NIT emitido por la DIAN',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.CR': {
    en: 'Tax registration document',
    es: 'Documento de registro tributario',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.UY': {
    en: 'Tax registration document',
    es: 'Constancia de Registro Único Tributario (RUT)',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.BO': {
    en: 'Tax registration document',
    es: 'Constancia de Número de Identificación Tributaria (NIT)',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.EC': {
    en: 'Tax registration document',
    es: 'Constancia de Registro Único del Contribuyente (RUC)',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.PY': {
    en: 'Tax registration document',
    es: 'Constancia de Registro Único del Contribuyente (RUC)',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.ES': {
    en: 'Tax registration document',
    es: 'Constancia de Número de Identificación Fiscal (NIF)',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.BR': {
    en: 'Tax registration document',
    es: 'Documento de registro tributario',
    pt: 'Cadastro de Pessoas Físicas (CPF)',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.US': {
    en: 'Tax registration document',
    es: 'Documento de registro tributario',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.NG': {
    en: 'Taxpayer identification number (TIN) emited by FIRS-Federal Inland Revenue Service.',
    es: 'Documento de registro tributario',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical.KE': {
    en: 'PIN Certificate emited by Kenya Revenue Authority.',
    es: 'Documento de registro tributario',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.taxCompliantDocument.physical': {
    en: 'Tax registration document',
    es: 'Documento de registro tributario',
    pt: 'Documento de registro tributário',
  },
  'companyInformation.kycTwo.shareholdersDocument.MX': {
    en: 'Articles of incorporation',
    es: 'Boleta de inscripción de la sociedad',
    pt: 'Ata constitutiva da sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocument.CL': {
    en: 'Articles of incorporation',
    es: 'Certificado de Inscripción ante el Registro de Comercio',
    pt: 'Ata constitutiva da sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocument.AR': {
    en: 'Articles of incorporation',
    es: 'Constitución de Sociedad presentado ante IGJ',
    pt: 'Ata constitutiva da sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocument.PE': {
    en: 'Articles of incorporation',
    es: 'Estatuto de constitución del negocio',
    pt: 'Ata constitutiva da sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocument.CO': {
    en: 'Articles of incorporation',
    es: 'Certificado de existencia y representación legal emitido por la cámara de comercio',
    pt: 'Ata constitutiva da sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocument.CR': {
    en: 'Articles of incorporation',
    es: 'Inscripción en el Registro de Personas Jurídicas en el Registro Nacional de la República de Costa Rica',
    pt: 'Ata constitutiva da sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocument.ES': {
    en: 'Articles of incorporation',
    es: 'Acta de constitución',
    pt: 'Ata constitutiva da sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocument.BR': {
    en: 'Articles of incorporation',
    es: 'Acta de constitución',
    pt: 'Contrato de sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocument.NG': {
    en: 'Certificate of incorporation',
    es: 'Acta de constitución',
    pt: 'Contrato de sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocument.KE': {
    en: 'Certificate of registration',
    es: 'Acta de constitución',
    pt: 'Contrato de sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocumentHelp.MX': {
    en: 'Articles of incorporation',
    es: 'Documento generado por el sistema del Registro Público de Comercio al acta constitutivo de la sociedad',
    pt: 'Ata constitutiva da sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocumentHelp.AR': {
    en: 'Articles of incorporation',
    es: 'Instrumento Constitutivo presentando ante la Inspección General de Justicia',
    pt: 'Ata constitutiva da sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocumentHelp.BR': {
    en: 'Articles of incorporation',
    es: 'Instrumento Constitutivo presentando ante la Inspección General de Justicia',
    pt: 'É a certidão de nascimento da empresa',
  },
  'companyInformation.kycTwo.shareholdersDocumentHelp.NG': {
    en: '',
    es: 'Instrumento Constitutivo presentando ante la Inspección General de Justicia',
    pt: 'É a certidão de nascimento da empresa',
  },
  'companyInformation.kycTwo.shareholdersDocumentHelp.KE': {
    en: '',
    es: 'Instrumento Constitutivo presentando ante la Inspección General de Justicia',
    pt: 'É a certidão de nascimento da empresa',
  },
  'companyInformation.kycTwo.shareholdersDocumentHelp': {
    en: 'Articles of incorporation',
    es: 'Acta constitutiva de la sociedad',
    pt: 'Ata constitutiva da sociedade',
  },
  'companyInformation.kycTwo.shareholdersDocumentHelp.company/physical': {
    en: `The document must: <ul>
      <li>Be a copy of the original document and include all pages.</li>
      <li>Contain the personal information of the partners or owners of the company, including the document of the main partner or owner of the company.</li>
    </ul>`,
    es: `El documento debe: <ul>
      <li>Ser copia del documento original e incluir todas las páginas.</li>
      <li>Contener los datos personales de los socios o dueños del negocio, incluyendo el documento del socio principal o dueño del negocio..</li>
    </ul>`,
    pt: `O documento deve: <ul>
      <li>Ser uma cópia do documento original e conter todas as páginas.</li>
      <li>Conter os dados pessoais dos sócios e do sono da empresa, incluindo o documento do sócio principal ou dono da empresa.</li>
    </ul>`,
  },
  'companyInformation.kycTwo.sectionTwo.title': {
    en: 'Fill in your bank account details',
    es: 'Completa los datos de tu cuenta bancaria',
    pt: 'Complete os dados de sua conta bancária',
  },
  'companyInformation.kycTwo.sectionTwo.subtitle': {
    en: 'Here we will send you the money from your sales.',
    es: 'Aquí enviaremos el dinero de tus ventas.',
    pt: 'Aqui enviaremos o dinheiro de suas vendas.',
  },
  'companyInformation.kycTwo.companyOwnerLegalInformation': {
    en: "Company owner's legal information",
    es: 'Información legal del dueño del negocio',
    pt: 'Informação legal do dono da empresa',
  },
  'companyInformation.kycTwo.companyOwnerLegalInformation.subheadline': {
    en: 'This information is about the owner of the dLocal Go account and cannot be edited.',
    es: 'Esta información es sobre el propietario de la cuenta dLocal Go y no se puede editar.',
    pt: 'Esta informação é sobre o proprietário da conta dLocal Go e não pode ser editada.',
  },
  'companyInformation.kycTwo.bankAccountInformation': {
    en: 'Bank account information',
    es: 'Información de tu cuenta bancaria',
    pt: 'Informação de sua conta bancária',
  },
  'companyInformation.kycTwo.bankAccountInformation.subheadline': {
    en: 'You can only withdraw your money in your local currency. Add a bank account in {0}',
    es: 'Solo puedes retirar tu dinero en tu moneda local. Agrega una cuenta bancaria en {0}',
    pt: 'Você só pode sacar seu dinheiro em sua moeda local. Adicione uma conta bancária em {0}',
  },
  'companyInformation.kycTwo.bankAccountDocumentation.title': {
    en: 'Bank account documentation',
    es: 'Documentación de tu cuenta bancaria',
    pt: 'Documentação de sua conta bancária',
  },
  'companyInformation.kycTwo.bankAccountDocumentation': {
    en: 'Proof of bank account',
    es: 'Comprobante de cuenta bancaria',
    pt: 'Comprovante de conta bancaria',
  },
  'companyInformation.kycTwo.error.upload.title': {
    en: 'An error occurred while uploading.',
    es: 'Ocurrió un error en la carga.',
    pt: 'Ocorreu um erro durante o upload.',
  },
  'companyInformation.kycTwo.error.upload.message': {
    en: 'Please, refresh the page and upload the files again.',
    es: 'Por favor, actualiza la página y vuelve a cargar los documentos.',
    pt: 'Atualize a página e recarregue os documentos.',
  },
  'companyInformation.kycFour.bankAccountDocument.MX': {
    en: 'Proof of bank account.',
    es: 'Certificado de titularidad bancaria.',
    pt: 'Comprovante de conta bancaria.',
  },
  'companyInformation.kycFour.bankAccountDocument.PE': {
    en: 'Proof of bank account.',
    es: 'Hoja bancaria.',
    pt: 'Comprovante de conta bancaria.',
  },
  'companyInformation.kycFour.bankAccountDocument.CO': {
    en: 'Proof of bank account.',
    es: 'Certificado bancario.',
    pt: 'Comprovante de conta bancaria.',
  },
  'companyInformation.kycFour.bankAccountDocument.CR': {
    en: 'Proof of bank account.',
    es: 'Constancia de cuenta bancaria.',
    pt: 'Comprovante de conta bancaria.',
  },
  'companyInformation.kycFour.bankAccountDocument.EC': {
    en: 'Proof of bank account.',
    es: 'Certificado bancario.',
    pt: 'Comprovante de conta bancaria.',
  },
  'companyInformation.kycFour.bankAccountDocument.ES': {
    en: 'Proof of bank account.',
    es: 'Certificado de titularidad bancaria.',
    pt: 'Comprovante de conta bancaria.',
  },
  'companyInformation.kycFour.bankAccountDocument.NG': {
    en: 'Proof of Bank Verification Number (BVN).',
    es: 'Certificado de titularidad bancaria.',
    pt: 'Comprovante de conta bancaria.',
  },
  'companyInformation.kycFour.bankAccountDocument.KE': {
    en: 'Bank account statement.',
    es: 'Certificado de titularidad bancaria.',
    pt: 'Comprovante de conta bancaria.',
  },
  'companyInformation.kycFour.bankAccountDocument': {
    en: "It can be a bank statement or letter from the bank in PDF format. Remember that you can download the document from your bank's website.",
    es: 'Puede ser un estado de cuenta, extracto bancario o carta del banco en formato PDF. Recuerda que puedes descargar el documento desde el sitio web de tu banco.',
    pt: 'Pode ser um extrato de conta, extrato bancário ou carta do banco em formato PDF. Lembre-se de que você pode baixar o documento no site do seu banco.',
  },
  'companyInformation.kycFour.bankAccountDocumentHelp': {
    en: 'It must contain the name of the beneficiary and bank account number. It must match the beneficiary of the entered bank account.',
    es: 'Debe contener el nombre del titular y número de la cuenta bancaria, y coincidir con el titular de la cuenta bancaria ingresada.',
    pt: 'Deve conter o nome do titular e o número da conta bancária. Deve corresponder/coincidir ao titular da conta inserida.',
  },
  'companyInformation.kycFour.bankAccountDocumentHelp.bullet1': {
    en: 'Make sure the document is legible and has good definition.',
    es: 'Asegúrate de que el documento sea legible y tenga buena definición.',
    pt: 'Certifique-se de que o documento esteja legível e tenha boa definição.',
  },
  'companyInformation.kycFour.bankAccountDocumentHelp.bullet2': {
    en: 'Maximum document size: 10 MB',
    es: 'Tamaño máximo del documento: 10 MB',
    pt: 'Tamanho máximo do documento: 10 MB',
  },
  'companyInformation.kycFour.bankAccountDocumentHelp.bullet3': {
    en: 'Allowed extensions: pdf, png, jpg, doc, docx.',
    es: 'Extensiones permitidas: pdf, png, jpg, doc, docx.',
    pt: 'Extensões permitidas: pdf, png, jpg, doc, docx.',
  },
  'companyInformation.kycFour.bankAccountDocumentHelp.company': {
    en: `The proof must:<ul>
      <li>Include the name of the company {0}</li>
      <li>Include bank account number.</li>
      <li>Be issued by the bank (it cannot be a screenshot from the app).</li></ul>
  `,
    es: `El comprobante debe:<ul>
      <li>Incluir el nombre del negocio {0}</li>
      <li>Incluir número de la cuenta bancaria.</li>
      <li>Ser emitido por el banco (No puede ser una captura de pantalla de la aplicación).</li></ul>
    `,
    pt: `O comprovante deve:<ul>
      <li>Incluir o nome da empresa {0}</li>
      <li>Incluir o número da conta bancária.</li>
      <li>Ser emitido pelo banco (Não pode ser uma captura de tela ou do aplicativo).</li></ul>
  `,
  },
  'companyInformation.kycFour.bankAccountDocumentHelp.physical': {
    en: `The proof must:<ul>
      <li>Include the name of the company {0}</li>
      <li>Include bank account number.</li>
      <li>Be issued by the bank (it cannot be a screenshot from the app).</li></ul>
    `,
    es: `El comprobante debe:<ul>
      <li>Estar a nombre de {0} {1}</li>
      <li>Incluir número de la cuenta bancaria.</li>
      <li>Ser emitido por el banco (No puede ser una captura de pantalla de la aplicación).</li></ul>
    `,
    pt: `O comprovante deve:<ul>
      <li>Estar em nome de {0} {1}</li>
      <li>Incluir o número da conta bancária.</li>
      <li>Ser emitido pelo banco (Não pode ser uma captura de tela ou do aplicativo).</li></ul>
  `,
  },
  'companyInformation.level': {
    en: 'Level {0}',
    es: 'Nivel {0}',
    pt: 'Nível {0}',
  },
  'companyInformation.maxTransactions': {
    en: 'Maximum number of transactions: <b>{0}</b>.',
    es: 'Cantidad máxima de transacciones: <b>{0}</b>.',
    pt: 'Número máximo de transações: <b>{0}</b>.',
  },
  'companyInformation.maxTransactionAmount': {
    en: 'Maximum transaction amount: <b>USD {0}</b>.',
    es: 'Monto máximo de transacción: <b>USD {0}</b>.',
    pt: 'Valor máximo da transação: <b>USD {0}</b>.',
  },
  'companyInformation.maxTransactionAmount.withoutLimit': {
    en: 'Maximum transaction amount: <b>Unlimited</b>.',
    es: 'Monto máximo de transacción: <b>Sin límite</b>.',
    pt: 'Valor máximo da transação: <b>Sem limite</b>.',
  },
  'companyInformation.requiredTransactions': {
    en: 'You must complete at least {0} successful transactions before you can level up.',
    es: 'Debes completar al menos {0} transacciones exitosas para que puedas subir de nivel.',
    pt: 'Você deve concluir pelo menos {0} transações bem-sucedidas para poder subir de nível.',
  },
  'companyInformation.creditCardEnabled': {
    en: 'Enabled cards: <b>{0}</b>.',
    es: 'Tarjetas habilitadas: <b>{0}</b>.',
    pt: 'Cartões habilitados: <b>{0}</b>.',
  },
  'companyInformation.numberRequiredTransactions': {
    en: 'Number of transactions required to move to the next level: <b>{0}</b>.',
    es: 'Cantidad de transacciones requeridas para subir al siguiente nivel: <b>{0}</b>.',
    pt: 'Quantidade de transações necessárias para subir ao próximo nível: <b>{0}</b>.',
  },
  'companyInformation.increaseLevel': {
    en: 'Increase level',
    es: 'Subir de nivel',
    pt: 'Subir de nível',
  },
  'companyInformation.kycReview.headline': {
    en: 'We received your information',
    es: 'Recibimos tu información',
    pt: 'Recebemos sua informação',
  },
  'companyInformation.kycReview.subheadline': {
    en: 'In less than 24 business hours, we will finish verifying your documents so that you can process unlimited payments and withdraw your money.',
    es: 'En menos de 24 hs hábiles, terminaremos de verificar tus documentos para que puedas procesar pagos sin límite y retirar tu dinero.',
    pt: 'Em menos de 24 horas úteis, terminaremos de verificar seus documentos para que você possa processar pagamentos sem limite e sacar seu dinheiro.',
  },
  'companyInformation.kycReview.headline.website': {
    en: 'We are almost done!',
    es: '¡Ya casi terminamos!',
    pt: 'Estamos quase terminando!',
  },
  'companyInformation.kycReview.subheadline.website': {
    en: 'Please, complete the pending information from step 1.',
    es: 'Por favor, completa la información pendiente del paso 1.',
    pt: 'Por favor, preencha as informações pendentes da etapa 1.',
  },
  'companyInformation.kycReview.headline.industry': {
    en: 'We cannot offer you our services.',
    es: 'No podemos ofrecerte nuestros servicios.',
    pt: 'Não podemos te oferecer nossos serviços',
  },
  'companyInformation.kycReview.subheadline.industry': {
    en: 'The industry of your business is not compatible with dLocal Go.',
    es: 'La industria de tu negocio no es compatible con dLocal Go.',
    pt: 'A indústria do seu negócio não é compatível com a dLocal Go',
  },
  'companyInformation.requiredData.title': {
    en: 'Required data',
    es: 'Datos requeridos',
    pt: 'Dados necessários',
  },
  'companyInformation.requiredData': {
    en: 'Enter required data',
    es: 'Ingresar datos requeridos',
    pt: 'Insira os dados necessários',
  },
  'companyInformation.upLevel': {
    en: 'Up to level {0}',
    es: 'Subir a nivel {0}',
    pt: 'Chegue ao nível {0}',
  },
  'companyInformation.moreInfo': {
    en: 'More information',
    es: 'Más información',
    pt: 'Mais informação',
  },
  'companyInformation.basicInfo.title': {
    en: 'Basic information',
    es: 'Información básica',
    pt: 'Informação básica',
  },
  'companyInformation.basicInfo.commercialInformation': {
    en: 'Commercial information',
    es: 'Información Comercial',
    pt: 'Informação Comercial',
  },
  'companyInformation.basicInfo.legalInformation': {
    en: 'Legal information',
    es: 'Información Legal',
    pt: 'Informação legal',
  },
  'companyInformation.basicInfo.contactInformation': {
    en: 'Contact information',
    es: 'Datos de contacto',
    pt: 'Dados de contato',
  },
  'companyInformation.basicInfo.name': {
    en: 'Name',
    es: 'Nombre',
    pt: 'Nome',
  },
  'companyInformation.basicInfo.documentType': {
    en: 'Document type',
    es: 'Tipo de documento',
    pt: 'Tipo de documento',
  },
  'companyInformation.basicInfo.document': {
    en: 'Document',
    es: 'Documento',
    pt: 'Documento',
  },
  'companyInformation.basicInfo.phone': {
    en: 'Phone',
    es: 'Teléfono',
    pt: 'Telefone',
  },
  'companyInformation.basicInfo.website': {
    en: 'Website',
    es: 'Sitio web',
    pt: 'Website',
  },
  'companyInformation.basicInfo.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'companyInformation.basicInfo.logo': {
    en: 'Company logo',
    es: 'Logo del negocio',
    pt: 'Logotipo da empresa',
  },
  'companyInformation.basicInfo.uploadLogo': {
    en: 'Upload',
    es: 'Subir',
    pt: 'Carregar',
  },
  'companyInformation.basicInfo.modifyLogo': {
    en: 'Modify',
    es: 'Modificar',
    pt: 'Modificar',
  },
  'companyInformation.headline.step1': {
    en: 'Account settings',
    es: 'Configuración de cuenta',
    pt: 'Configuração da conta',
  },
  'companyInformation.headlineCard.step1': {
    en: 'Tell us about your business',
    es: 'Cuéntanos sobre tu negocio',
    pt: 'Conte-nos sobre o seu negócio ',
  },
  'companyInformation.headlineCard.step1.company': {
    en: 'Tell us about your business',
    es: 'Cuéntanos sobre tu negocio',
    pt: 'Conte-nos sobre o seu negócio ',
  },
  'companyInformation.headlineCard.step1.physical': {
    en: 'Tell us about yourself',
    es: 'Cuéntanos sobre ti',
    pt: 'Conte-nos sobre você',
  },
  'companyInformation.subheadlineCard.step1': {
    en: 'In order for you to accept payments with dLocal Go, we need to know more about you and your business. We request this data to comply with the requirements of regulatory authorities and our ',
    es: 'Para que puedas aceptar pagos con dLocal Go, necesitamos saber más sobre ti y tu negocio. Solicitamos estos datos para cumplir con los requisitos de las autoridades reguladoras y nuestros ',
    pt: 'Para que você possa aceitar pagamentos com dLocal Go, precisamos saber mais sobre você e sua empresa. Solicitamos estes dados para cumprir com os requisitos das autoridades reguladoras e nossos ',
  },
  'companyInformation.subheadlineCard.step1.company': {
    en: 'In order for you to accept payments with dLocal Go, we need to know more about you and your business. We request this data to comply with the requirements of regulatory authorities and our ',
    es: 'Para que puedas aceptar pagos con dLocal Go, necesitamos saber más sobre ti y tu negocio. Solicitamos estos datos para cumplir con los requisitos de las autoridades reguladoras y nuestros ',
    pt: 'Para que você possa aceitar pagamentos com dLocal Go, precisamos saber mais sobre você e sua empresa. Solicitamos estes dados para cumprir com os requisitos das autoridades reguladoras e nossos ',
  },
  'companyInformation.subheadlineCard.step1.physical': {
    en: 'In order for you to accept payments with dLocal Go, we need to know more about you. We request this information to comply with regulatory requirements and our ',
    es: 'Para que puedas aceptar pagos con dLocal Go, necesitamos saber más sobre ti. Solicitamos estos datos para cumplir con los requisitos de las autoridades reguladoras y nuestros ',
    pt: 'Para que você possa aceitar pagamentos com dLocal Go, precisamos saber mais sobre você. Solicitamos estes dados para cumprir com os requisitos das autoridades reguladores e nostros ',
  },
  'companyInformation.headline.step2': {
    en: 'Company data',
    es: 'Datos de tu negocio',
    pt: 'Dados da empresa',
  },
  'companyInformation.headlineCard.step2': {
    en: 'Finish your account setup in a few minutes',
    es: 'Finaliza la configuración de tu cuenta en pocos minutos',
    pt: 'Finalize a configuração de sua conta em alguns minutos',
  },
  'companyInformation.subheadlineCard.step2.part1': {
    en: 'We request this data to comply with the requirements of regulatory authorities and our ',
    es: 'Solicitamos estos datos para cumplir con los requisitos de las autoridades reguladoras y nuestros ',
    pt: 'Solicitamos-lhe estes dados para cumprir com os requisitos das autoridades reguladoras e nossos ',
  },
  'companyInformation.subheadlineCard.step2.part2': {
    en: "We will guide you through the process, don't worry!",
    es: 'Te guiaremos en el proceso, ¡no te preocupes!',
    pt: 'Vamos guiá-lo no processo, não se preocupe!',
  },
  'companyInformation.headlineCard.stepsCompleted': {
    en: 'You have completed your account setup successfully.',
    es: 'Completaste la configuración de tu cuenta con éxito.',
    pt: '',
  },
  'companyInformation.subheadlineCard.stepsCompleted': {
    en: 'We approved the documentation you sent us. Now you can charge without limit and withdraw the money from your sales whenever you want.',
    es: 'Aprobamos la documentación que nos enviaste. Ya puedas facturar sin límite y retirar el dinero de tus ventas cuando quieras.',
    pt: '',
  },
  'companyInformation.termsAndConditions': {
    en: 'terms and conditions',
    es: 'términos y condiciones',
    pt: 'termos e condições',
  },
  'companyInformation.step1': {
    en: 'Step 1',
    es: 'Paso 1',
    pt: 'Passo 1',
  },
  'companyInformation.steps.tag.complete': {
    en: 'Complete',
    es: 'Completar',
    pt: 'Completar',
  },
  'companyInformation.steps.tag.completed': {
    en: 'Completed',
    es: 'Completo',
    pt: 'Completo',
  },
  'companyInformation.steps.tag.pending': {
    en: 'Pending',
    es: 'Pendiente',
    pt: 'Pendente',
  },
  'companyInformation.steps.tag.reviewInformation.website': {
    en: 'Review information',
    es: 'Revisar información',
    pt: 'Revisar a informação',
  },
  'companyInformation.steps.tag.reviewInformation.industry': {
    en: 'Industry not supported',
    es: 'Industria no compatible',
    pt: 'Indústria não suportada',
  },
  'companyInformation.steps.tag.pendingApproval': {
    en: 'Pending approval',
    es: 'Pendiente de aprobación',
    pt: 'Pendente de aprovação',
  },
  'companyInformation.step1.headline.complete': {
    en: 'Upload your company data',
    es: 'Carga los datos de tu negocio',
    pt: 'Carregue os dados de sua empresa',
  },
  'companyInformation.step1.headline.complete.company/physical': {
    en: 'Complete the basic information',
    es: 'Completa los datos básicos',
    pt: 'Adicone os dados básicos',
  },
  'companyInformation.step1.subheadline.complete': {
    en: 'We will ask you for basic information to learn about your business.',
    es: 'Te pediremos la información básica para conocer de tu negocio.',
    pt: 'Pedir-lhe-emos informações básicas para conhecer o seu negócio.',
  },
  'companyInformation.step1.subheadline.complete.load-data': {
    en: 'Upload data',
    es: 'Cargar datos',
    pt: 'Carregar informações',
  },
  'companyInformation.step1.estimated-time': {
    en: 'Estimated time',
    es: 'Tiempo estimado',
    pt: 'Tempo estimado',
  },
  'companyInformation.step1.estimated-time.minutes': {
    en: 'minutes',
    es: 'minutos',
    pt: 'minutos',
  },
  'companyInformation.step1.subheadline.complete.company': {
    en: 'We will ask you for the most relevant commercial and legal information about your company. You will not have to submit any documentation.',
    es: 'Te pediremos la información básica para conocer de tu negocio. No tendrás que presentar documentación.',
    pt: 'Pediremos as informações comerciais e legais mais relevantes da sua empresa. Não será necessário apresentar documentação.',
  },
  'companyInformation.step1.subheadline.complete.physical': {
    en: "We'll ask you for commercial and legal information about you and your business. You will not have to submit any documentation.",
    es: 'Te pediremos información comercial y legal sobre ti y tu negocio. No tendrás que presentar documentación.',
    pt: 'Pediremos informações comerciais e legais sobre seu negócio. Não é necessário apresentar documentação.',
  },
  'companyInformation.step1.content.complete': {
    en: 'This step will enable you to receive payments.',
    es: 'Este paso te habilitará a recibir pagos.',
    pt: 'Este passo o habilitará para receber pagamentos.',
  },
  'companyInformation.step1.content.complete.company': {
    en: 'This step will enable your company to receive payments.',
    es: 'Este paso habilitará a tu negocio a recibir pagos.',
    pt: 'Este passo irá habilitar sua empresa a receber pagamentos.',
  },
  'companyInformation.step1.content.complete.physical': {
    en: 'This step will enable your business to receive payments.',
    es: 'Este paso habilitará a tu negocio a recibir pagos.',
    pt: 'Este passo irá habilitar seu negócio a receber pagamentos.',
  },
  'companyInformation.step1.button.complete': {
    en: 'COMPLETE',
    es: 'COMPLETAR',
    pt: 'COMPLETAR',
  },
  'companyInformation.step2.headline.complete': {
    en: 'Send your documentation',
    es: 'Envía tu documentación',
    pt: 'Envie sua documentação',
  },
  'companyInformation.step2.subheadline.complete': {
    en: 'We will ask you for documents that prove the identity of the owner of the company, his address, the tax registry, the articles of incorporation of the company (only if you are a legal entity), and bank account information.',
    es: 'Te pediremos documentos que acrediten la identidad del dueño del negocio, su domicilio, el registro tributario, el acta constitutiva de la sociedad (solo si eres persona jurídica), y datos de la cuenta bancaria.',
    pt: 'Solicitaremos documentos que comprovem a identidade do proprietário da empresa, seu endereço, o registro fiscal, os estatutos da empresa (somente se você for uma pessoa jurídica) e dados bancários.',
  },
  'companyInformation.step2.subheadline.complete.company/physical': {
    en: 'We will ask you for documentation and the details of the bank account where you want to receive your money.',
    es: 'Te pediremos documentación y los datos de la cuenta bancaria donde quieres recibir tu dinero.',
    pt: 'Pediremos a documentação e os dados da conta bancária onde deseja receber seu dinheiro.',
  },
  'companyInformation.step2.content.complete': {
    en: 'This step will enable you to <b>withdraw money from your sales and process unlimited payments.</b>',
    es: 'Este paso te habilitará a <b>retirar el dinero de tus ventas y procesar pagos sin límite.</b>',
    pt: 'Este passo irá habilitar a <b>retirada do dinheiro das suas vendas e o processamento de pagamentos sem limites.</b>',
  },

  'companyInformation.step2.subheadline.completeFields.company': {
    en: `About the <b>company</b>:
    <ul><li>{0}</li>
    <li>{1}</li>
    <li>Proof and details of the bank account</li></ul>
    About the <b>main partner or owner of the company</b>:
    <ul><li>{2}</li>
    <li>Proof of address</li></ul>`,
    es: `Sobre el <b>negocio</b>:
    <ul><li>{0}</li>
    <li>{1}</li>
    <li>Comprobante y datos de la cuenta bancaria</li></ul>
    Sobre el <b>socio principal o dueño del negocio</b>:
    <ul><li>{2}</li>
    <li>Constancia de domicilio</li></ul>`,
    pt: `Sobre a <b>empresa</b>:
    <ul><li>{0}</li>
    <li>{1}</li>
    <li>Comprovante e dados da conta bancária</li></ul>
    Sobre o <b>sócio principal ou dono da empresa</b>:
    <ul><li>{2}</li>
    <li>Comprovante de endereço</li></ul>`,
  },
  'companyInformation.step2.subheadline.completeFields.physical': {
    en: `<ul><li>Identity document</li>
    <li>Proof of address</li>
    <li>{0}</li>
    <li>Proof and details of the bank account</li></ul>`,
    es: `<ul><li>Documento de identidad</li>
    <li>Constancia de domicilio</li>
    <li>{0}</li>
    <li>Comprobante y datos de la cuenta bancaria</li></ul>`,
    pt: `<ul><li>Documento de identidade</li>
    <li>Comprovante de endereço</li>
    <li>{0}</li>
    <li>Comprovante e dados da sua conta bancária</li></ul>`,
  },
  'companyInformation.step2.headline.pendingApproval': {
    en: 'Send your documentation',
    es: 'Envía tu documentación',
    pt: 'Envie sua documentação',
  },
  'companyInformation.step2.subheadline.pendingApproval': {
    en: 'We are reviewing the documentation you sent us.',
    es: 'Estamos validando la documentación que nos enviaste.',
    pt: 'Estamos revisando a documentação que você nos enviou.',
  },
  'companyInformation.step2.content.pendingApproval': {
    en: 'Once we validate the information, you will be able <b>to process unlimited payments and withdraw the money from your sales.</b>',
    es: 'Una vez validada la información, podrás <b>facturar sin límite y retirar el dinero de tus ventas.</b>',
    pt: 'Assim que validarmos as informações, você poderá <b>processar pagamentos ilimitados e sacar o dinheiro das suas vendas.</b>',
  },
  'companyInformation.step2.button.complete': {
    en: 'COMPLETE',
    es: 'COMPLETAR',
    pt: 'COMPLETAR',
  },
  'companyInformation.step1.headline.completed': {
    en: 'Company data',
    es: 'Datos de tu negocio',
    pt: 'Dados da empresa',
  },
  'companyInformation.step1.headline.completed.company': {
    en: 'Your company data has been verified',
    es: 'Los datos de tu negocio están verificados',
    pt: 'Os dados da sua empresa estão verificados',
  },
  'companyInformation.step1.headline.completed.physical': {
    en: 'Your data has been verified',
    es: 'Tus datos están verificados',
    pt: 'Seus dados estão verificados',
  },
  'companyInformation.step1.subheadline.completed': {
    en: 'Your information is already verified. You are ready to start processing payments.',
    es: 'Tu información ya está verificada. Estás listo para comenzar a procesar pagos.',
    pt: 'Sua informação já está verificada.<br>Você está pronto para começar a processar os pagamentos.',
  },
  'companyInformation.step1.subheadline.completed.company/physical': {
    en: 'You can now start receiving payments with any of the dLocal Go payment solutions.',
    es: 'Ya puedes empezar a recibir pagos con cualquiera de las soluciones de cobro de dLocal Go.',
    pt: 'Você já pode começar a receber pagamentos com quaisquer soluções de cobrança da dLocal Go.',
  },
  'companyInformation.step1.content.completed': {
    en: 'You can now receive payments and process up to <b>USD {0}.</b>',
    es: 'Ya puedes recibir pagos y procesar hasta <b>USD {0}.</b>',
    pt: 'Você já pode receber pagamentos e processar até USD <b>USD {0}.</b>',
  },
  'companyInformation.step1.content.completed.company/physical': {
    en: 'Sell and process up to <b>USD {0}.</b>',
    es: 'Vende y procesa hasta <b>USD {0}.</b>',
    pt: 'Venda e processe até <b>USD {0}.</b>',
  },
  'companyInformation.step1.headline.reviewInformation.website': {
    en: 'We are waiting for your answer',
    es: 'Estamos esperando tu respuesta',
    pt: 'Estamos aguardando sua resposta',
  },
  'companyInformation.step1.subheadline.reviewInformation.website': {
    en: "We couldn't verify your website. We sent you an email to finalize this validation. We await your reply.",
    es: 'No pudimos verificar tu sitio web. Te enviamos un mail para completar esta validación. Aguardamos tu respuesta.',
    pt: 'Não pudemos verificar seu website. Enviamos-lhe um e-mail para completar esta validação. Aguardamos sua resposta.',
  },
  'companyInformation.step1.headline.reviewInformation.industry': {
    en: 'Your business industry is not supported',
    es: 'La industria de tu negocio no es compatible',
    pt: 'A indústria do seu negócio não é suportada',
  },
  'companyInformation.step1.subheadline.reviewInformation.industry': {
    en: 'We cannot offer our services to you because your business industry is not allowed in our Terms and Conditions.',
    es: 'No podemos ofrecerte nuestros servicios porque la industria de tu negocio no está permitida en nuestros Términos y condiciones.',
    pt: 'Não podemos oferecer nossos serviços a você porque a indústria do seu negócio não é permitida em nossos Termos e Condições.',
  },
  'companyInformation.step2': {
    en: 'Step 2',
    es: 'Paso 2',
    pt: 'Passo 2',
  },
  'companyInformation.success.headline': {
    en: 'Congratulations! You completed step 1',
    es: '¡Felicitaciones! Completaste el paso 1',
    pt: 'Parabéns! Você completou o passo 1',
  },
  'companyInformation.success.subheadline': {
    en: 'Now you can start selling as you want.',
    es: 'Ya puedes empezar a vender de la forma que quieras.',
    pt: 'Você já pode começar a vender da forma que quiser.',
  },
  'companyInformation.onboarding.title.part1': {
    en: 'Account Settings',
    es: 'Configuración de cuenta',
    pt: 'Configuração da conta',
  },
  'companyInformation.onboarding.title.part2': {
    en: 'Company data',
    es: 'Datos de tu negocio',
    pt: 'Dados de sua empresa',
  },
  'companyInformation.onboarding.title.part2.physical': {
    en: 'Company data',
    es: 'Datos de tu negocio',
    pt: 'Dados do seu negócio',
  },

  'uploadLogo.allowedDesc': {
    en: 'Maximum size <b>{0}MB</b>.<br/>Allowed extensions: <b>{1}</b>.',
    es: 'Tamaño máximo <b>{0}MB</b>.<br/>Extensiones permitidas: <b>{1}</b>.',
    pt: 'Tamanho máximo <b>{0}MB</b>.<br/>Extensões permitidas: <b>{1}</b>.',
  },
  'uploadLogo.desc': {
    en: 'This image will be shown to your clients on the payment screen.',
    es: 'Esta imagen será mostrada a sus clientes en la pantalla de pago.',
    pt: 'Esta imagem será mostrada aos seus clientes na tela de pagamento.',
  },
  'uploadLogo.remove': {
    en: 'Remove',
    es: 'Eliminar',
    pt: 'Retirar',
  },
  'uploadLogo.update': {
    en: 'Update',
    es: 'Actualizar',
    pt: 'Atualizar',
  },
  'uploadLogo.saveLogo': {
    en: 'Save logo',
    es: 'Guardar logo',
    pt: 'Salvar logotipo',
  },
  'uploadLogo.deleteConfirm': {
    en: 'Are you sure you want to delete it?',
    es: '¿Estás seguro de que quieres eliminarlo?',
    pt: 'Tem certeza que deseja deletar?',
  },
  'uploadLogo.uploadSuccess': {
    en: 'Logo saved successfully!',
    es: '¡Logotipo guardado correctamente!',
    pt: 'Logotipo salvo com sucesso!',
  },
  'uploadLogo.deleteSuccess': {
    en: 'Logo removed successfully!',
    es: '¡Logotipo eliminado correctamente!',
    pt: 'Logotipo removido com sucesso!',
  },
  'transactions.headline': {
    en: 'Your transactions',
    es: 'Tus transacciones',
    pt: 'Suas transações',
  },
  'transactions.subheadline': {
    en: 'Find all the sales you have made, their status and the most important details.',
    es: 'Encuentra todas las ventas que has realizado, su estado y los detalles más importantes.',
    pt: 'Encontre todas as vendas que você realizou, seu status e os detalhes mais importantes.',
  },
  'transactions.searchBar': {
    en: 'Search transactions',
    es: 'Buscar transacciones',
    pt: 'Pesquisar transações',
  },
  'transactions.searchBar.helpText': {
    en: 'Search for a transaction by the name of your client, their email or the total amount they have paid you.',
    es: 'Busca una transacción por el nombre de tu cliente, su e-mail o el monto total que te ha pagado.',
    pt: 'Pesquise uma transação pelo nome do seu cliente, seu e-mail ou o valor total que ele pagou a você.',
  },
  'transactions.details': {
    en: 'Transaction details',
    es: 'Detalles de la transacción',
    pt: 'Detalhes da transação',
  },
  'transactions.details.backBtn': {
    en: 'Back to transactions',
    es: 'Volver a transacciones',
    pt: 'Voltar para transações',
  },
  'transactions.details.amountPaid.tooltip': {
    en: 'Your client was charged in {0}, you´ll receive {1}.',
    es: 'Tu cliente pagó en {0}, tu recibirás {1}.',
    pt: 'Seu cliente foi cobrado em {0}, você receberá {1}.',
  },
  'transactions.details.processingFee.tooltip': {
    en: 'dLocal Go processing fee according to payment methods.',
    es: 'Costo de procesamiento de dLocal Go según los métodos de pago.',
    pt: 'Taxa de processamento da dLocal Go de acordo com o método de pagamento.',
  },
  'transactions.details.shopifyRefund.title': {
    en: 'Log in to Shopify to make a refund.',
    es: 'Ingresa a Shopify para realizar un reembolso.',
    pt: 'Faça login na Shopify para fazer um reembolso.',
  },
  'transactions.noTransaction': {
    en: 'You have no transactions yet.',
    es: 'Aún no tienes transacciones.',
    pt: 'Você ainda não tem transações.',
  },
  'transactions.noTransaction.found': {
    en: 'We haven’t found any matches for your searched transaction.<br />Please, try again.',
    es: 'No hemos encontrado coincidencias para la transacción buscada.<br />Por favor, inténtalo de nuevo.',
    pt: 'Não encontramos nenhuma correspondência para sua transação pesquisada.<br />Por favor, tente novamente.',
  },
  'transactions.id': {
    en: 'ID',
    es: 'ID',
    pt: 'ID',
  },
  'transactions.createDate': {
    en: 'Creation date',
    es: 'Fecha de creación',
    pt: 'Data de criação',
  },
  'transactions.name': {
    en: 'Client name',
    es: 'Nombre del cliente',
    pt: 'Nome do cliente',
  },
  'transactions.date': {
    en: 'Date',
    es: 'Fecha',
    pt: 'Data',
  },
  'transactions.time': {
    en: 'Time',
    es: 'Hora',
    pt: 'Hora',
  },
  'transactions.invoiceId': {
    en: 'Transaction ID',
    es: 'ID de transacción',
    pt: 'ID da transação',
  },
  'transactions.paymentSolutionType': {
    en: 'Payment solution',
    es: 'Solución de cobro',
    pt: 'Solução de pagamento',
  },
  'transactions.paymentSolutionType.SUBSCRIPTION': {
    en: 'Subscription',
    es: 'Suscripción',
    pt: 'Assinatura',
  },
  'transactions.paymentSolutionType.SHOPIFY': {
    en: 'Shopify',
    es: 'Shopify',
    pt: 'Shopify',
  },
  'transactions.paymentSolutionType.PRESTASHOP': {
    en: 'Prestashop',
    es: 'Prestashop',
    pt: 'Prestashop',
  },
  'transactions.paymentSolutionType.WOO_COMMERCE': {
    en: 'WooCommerce',
    es: 'WooCommerce',
    pt: 'WooCommerce',
  },
  'transactions.paymentSolutionType.MAGENTO': {
    en: 'Magento',
    es: 'Magento',
    pt: 'Magento',
  },
  'transactions.paymentSolutionType.SHOPIFY_TRANSPARENT': {
    en: 'Shopify',
    es: 'Shopify',
    pt: 'Shopify',
  },
  'transactions.paymentSolutionType.PAYMENT_LINK': {
    en: 'Payment link',
    es: 'Link de pago',
    pt: 'Link de pagamento',
  },
  'transactions.paymentSolutionType.LINK': {
    en: 'Payment link',
    es: 'Link de pago',
    pt: 'Link de pagamento',
  },
  'transactions.paymentSolutionType.RECURRING_LINK': {
    en: 'Recurrent Payment link',
    es: 'Link de pago recurrente',
    pt: 'Link de pagamento recorrente',
  },
  'transactions.paymentSolutionType.PAYMENT_BUTTON': {
    en: 'Payment button',
    es: 'Botón de pago',
    pt: 'Botão de pagamento',
  },
  'transactions.paymentSolutionType.BUY_BUTTON': {
    en: 'Payment button',
    es: 'Botón de pago',
    pt: 'Botão de pagamento',
  },
  'transactions.paymentSolutionType.BUTTON': {
    en: 'Payment button',
    es: 'Botón de pago',
    pt: 'Botão de pagamento',
  },
  'transactions.paymentSolutionType.DGO_API': {
    en: 'API',
    es: 'API',
    pt: 'API',
  },
  'transactions.paymentSolutionType.DP_API': {
    en: 'API',
    es: 'API',
    pt: 'API',
  },
  'transactions.paymentSolutionType.VIRTUAL_POS': {
    en: 'Virtual POS',
    es: 'POS Virtual',
    pt: 'POS Virtual',
  },
  'transactions.paymentSolutionType.OTHERS': {
    en: 'Others',
    es: 'Otros',
    pt: 'Outros',
  },
  'transactions.description': {
    en: 'Description',
    es: 'Descripción',
    pt: 'Descrição',
  },
  'transactions.externalId': {
    en: 'External ID',
    es: 'ID externo',
    pt: 'ID externo',
  },
  'transactions.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'transactions.paymentMethod': {
    en: 'Payment method',
    es: 'Método de pago',
    pt: 'Forma de pagamento',
  },
  'transactions.balanceAmount': {
    en: 'Total balance amount',
    es: 'Monto total de balance',
    pt: 'Valor total de balance',
  },
  'transactions.balanceFee': {
    en: 'Fee',
    es: 'Comisión',
    pt: 'Taxas',
  },
  'transactions.clientId': {
    en: 'Client ID ',
    es: 'ID de cliente',
    pt: 'ID do cliente',
  },
  'transactions.clientName': {
    en: 'Client name',
    es: 'Nombre del cliente',
    pt: 'Nome do cliente',
  },
  'transactions.clientFirstName': {
    en: 'Client first name',
    es: 'Nombre del cliente',
    pt: 'Nome do cliente',
  },
  'transactions.clientLastName': {
    en: 'Client last name',
    es: 'Apellido del cliente',
    pt: 'Sobrenome do cliente',
  },
  'transactions.clientDocumentType': {
    en: 'Client document type',
    es: 'Tipo del documento del cliente',
    pt: 'Tipo de documento do cliente',
  },
  'transactions.clientDocument': {
    en: 'Client document',
    es: 'Documento del cliente',
    pt: 'Documento do cliente',
  },
  'transactions.clientEmail': {
    en: 'Client email',
    es: 'Correo electrónico del cliente',
    pt: 'E-mail do cliente',
  },
  'transactions.installments': {
    en: 'Installments',
    es: 'Cuotas',
    pt: 'Parcelas',
  },
  'transactions.installments.MX': {
    en: 'Installments',
    es: 'Meses',
    pt: 'Parcelas',
  },
  'transactions.amountPaid': {
    en: 'Amount paid',
    es: 'Monto pagado',
    pt: 'Valor pago',
  },
  'transactions.document': {
    en: 'Client document',
    es: 'No. documento',
    pt: 'Número do documento',
  },
  'transactions.txEmail': {
    en: 'Transaction email',
    es: 'Correo de la transacción',
    pt: 'E-mail de transação',
  },
  'transactions.processingFee': {
    en: 'Processing Fee',
    es: 'Tarifa de procesamiento',
    pt: 'Taxa de processamento',
  },
  'transactions.exchangeRate': {
    en: 'Exchange rate',
    es: 'Tipo de cambio',
    pt: 'Taxa de câmbio',
  },
  'transactions.amountReceived': {
    en: 'Amount received',
    es: 'Monto recibido',
    pt: 'Valor recebido',
  },
  'transactions.client_sent_chargeback_form': {
    en: 'Client sent chargeback form',
    es: 'El cliente envió formulario de contracargo',
    pt: 'O cliente enviou o formulário de chargeback',
  },
  'transactions.amountInUSD': {
    en: 'Amount in USD',
    es: 'Monto en USD',
    pt: 'Valor em USD',
  },
  'transactions.paymentDetails': {
    en: 'Payment details',
    es: 'Detalles del pago',
    pt: 'Detalhes do pagamento',
  },
  'transactions.clientInformation': {
    en: 'Client information',
    es: 'Información del cliente',
    pt: 'Informação ao cliente',
  },
  'transactions.chargebackform': {
    en: 'Chargeback Submission Form',
    es: 'Formulario de presentación de contracargo',
    pt: 'Formulário de apresentação de chargeback',
  },
  'transactions.chargebackform.product': {
    en: 'Product',
    es: 'Producto',
    pt: 'Produto',
  },
  'transactions.chargebackform.problem': {
    en: 'Problem',
    es: 'Problema',
    pt: 'Problema',
  },
  'transactions.chargebackform.comments': {
    en: 'Comments',
    es: 'Comentarios',
    pt: 'Comentários',
  },
  'transactions.filter.status': {
    en: 'Payment status',
    es: 'Estado del pago',
    pt: 'Status do pagamento',
  },
  'transactions.filter.status.titleOptions': {
    en: 'Filter by payment status',
    es: 'Filtrar por estado del pago',
    pt: 'Filtrar por status de pagamento',
  },
  'transactions.filter.creationDate': {
    en: 'Creation date',
    es: 'Fecha de creación',
    pt: 'Data de criação',
  },
  'transactions.filter.creationDate.titleOptions': {
    en: 'Filter by creation date',
    es: 'Filtrar por fecha de creación',
    pt: 'Filtrar por data de criação',
  },
  'transactions.filter.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'transactions.filter.country.titleOptions': {
    en: 'Filter by country',
    es: 'Filtrar por país',
    pt: 'Filtrar por país',
  },
  'transactions.filter.paymentMethod': {
    en: 'Payment method',
    es: 'Método de pago',
    pt: 'Forma de pagamento',
  },
  'transactions.filter.paymentMethod.titleOptions': {
    en: 'Filter by payment method',
    es: 'Filtrar por método de pago',
    pt: 'Filtrar por forma de pagamento',
  },
  'transactions.filter.paymentMethod.descriptionOptions': {
    en: 'First, filter by country and then by payment method.',
    es: 'Primero, filtra por país y luego por método de pago.',
    pt: 'Primeiro, filtre por país e depois por forma de pagamento.',
  },
  'transactions.filter.paymentMethod.desc': {
    en: 'First, you must filter by country.',
    es: 'Primero, debes filtrar por país.',
    pt: 'Primeiro, você deve filtrar por país.',
  },
  'transactions.filter.appliedFilters': {
    en: 'Applied filters',
    es: 'Filtros aplicados',
    pt: 'Filtros aplicados',
  },
  'transactions.filter.clearFilters': {
    en: 'Clear all',
    es: 'Limpiar todo',
    pt: 'Limpar tudo',
  },
  'transactions.filter.chip.from': {
    en: 'From',
    es: 'Desde',
    pt: 'A partir de',
  },
  'transactions.filter.chip.to': {
    en: 'To',
    es: 'Hasta',
    pt: 'Até',
  },
  'transactions.status': {
    en: 'Payment status',
    es: 'Estado del pago',
    pt: 'Status do pagamento',
  },
  'transactions.status.CREATED': {
    en: 'Created',
    es: 'Creada',
    pt: 'Criado',
  },
  'transactions.status.COMPLETED': {
    en: 'Completed',
    es: 'Completada',
    pt: 'Concluído',
  },
  'transactions.status.PENDING': {
    en: 'Pending',
    es: 'Pendiente',
    pt: 'Pendente',
  },
  'transactions.status.CANCELLED': {
    en: 'Cancelled',
    es: 'Cancelada',
    pt: 'Cancelada',
  },
  'transactions.status.DECLINED': {
    en: 'Declined',
    es: 'Rechazada',
    pt: 'Rejeitado',
  },
  'transactions.status.EXPIRED': {
    en: 'Expired',
    es: 'Expirado',
    pt: 'Expirado',
  },
  'transactions.addRefund': {
    en: 'Refund',
    es: 'Reembolsar',
    pt: 'Reembolsar',
  },
  'exportTransactions.title': {
    en: 'Download report',
    es: 'Descargar reporte',
    pt: 'Baixar relatório',
  },
  'exportTransactions.open': {
    en: 'Export',
    es: 'Exportar',
    pt: 'Exportar',
  },
  'exportTransactions.exportBtn': {
    en: 'Download',
    es: 'Descargar',
    pt: 'Baixar',
  },
  'exportTransactions.range': {
    en: 'Select date range',
    es: 'Seleccione el rango de fechas',
    pt: 'Selecione o intervalo de datas',
  },
  'exportTransactions.successMessage': {
    en: 'If the file download does not start, check that your browser has not blocked it.',
    es: 'Si la descarga del archivo no se inicia, verifique que su navegador no lo haya bloqueado.',
    pt: 'Se o download do arquivo não iniciar, verifique se seu navegador não o bloqueou.',
  },
  'exportTransactions.downloadAgain': {
    en: 'You can try to download it again by clicking ',
    es: 'Puedes intentar descargarlo nuevamente haciendo clic ',
    pt: 'Você pode tentar baixá-lo novamente clicando ',
  },
  'exportTransactions.downloadAgain.here': {
    en: 'here.',
    es: 'aquí.',
    pt: 'aqui.',
  },
  'exportTransactions.invalidDates': {
    en: 'The selected dates are invalid.',
    es: 'Las fechas seleccionadas son inválidas.',
    pt: 'As datas selecionadas são inválidas.',
  },
  'exportTransactions.rangeError': {
    en: 'The date range cannot be greater than 31 days.',
    es: 'El rango de fechas no puede ser mayor a 31 días.',
    pt: 'O intervalo de datas não pode ser superior a 31 dias.',
  },
  'exportTransactions.empty': {
    en: 'No data to download found.',
    es: 'No se encontraron datos para descargar.',
    pt: 'Nenhum dado para download encontrado.',
  },
  'exportTransactions.otherFilters': {
    en: 'Optional filters',
    es: 'Filtros opcionales',
    pt: 'Filtros opcionais',
  },
  'home.welcome.title': {
    en: 'Hi, {0}',
    es: 'Hola, {0}',
    pt: 'Oi, {0}',
  },
  'home.onboarding.title': {
    en: 'Setting up your account is now faster',
    es: 'Configurar tu cuenta ahora es más rápido',
    pt: 'Configurar sua conta agora é mais rápido',
  },
  'home.onboarding.desc': {
    en: 'We simplified the data request in just ',
    es: 'Simplificamos la solicitud de datos en solo ',
    pt: 'Simplificamos o pedido de dados em apenas ',
  },
  'home.onboarding.link': {
    en: '2 steps',
    es: '2 pasos',
    pt: '2 passos',
  },
  'home.onboarding.desc2': {
    en: ' so you can start selling as soon as possible.',
    es: ' para que empieces a vender cuanto antes.',
    pt: ' para que você possa começar a vender o mais rápido possível.',
  },
  'home.reminder.title': {
    en: 'Make the most of dLocal Go.',
    es: 'Aprovecha al máximo dLocal Go.',
    pt: 'Faça o máximo com dLocal Go.',
  },
  'home.reminder.link': {
    en: 'Complete step 2',
    es: 'Completa el paso 2',
    pt: 'Complete o passo 2',
  },
  'home.reminder.desc': {
    en: ' of your account setup to <b>process payments without limits</b> and <b>withdraw money from your account</b> whenever you want.',
    es: ' de la configuración de tu cuenta para <b>procesar pagos sin límite</b> y <b>retirar el dinero de tu cuenta</b> cuando quieras.',
    pt: ' da configuração da sua conta para <b>processar pagos sem limites</b> e <b>retirar dinheiro da sua conta</b> sempre que quiser.',
  },
  'home.reminder.limit.title': {
    en: 'You are reaching your billing limit',
    es: 'Estás llegando a tu límite de facturación',
    pt: 'Você está chegando ao seu limite de faturamento',
  },
  'home.reminder.limit.desc': {
    en: 'To be able to bill without limit and withdraw the money from your sales, you must ',
    es: 'Para poder facturar sin límite y retirar el dinero de tus ventas, debes ',
    pt: 'Para poder faturar sem limite e retirar o dinheiro das suas vendas, você deve ',
  },
  'home.reminder.limit.link': {
    es: 'completar el paso 2',
    en: 'complete step 2',
    pt: 'completar o passo 2',
  },
  'home.reminder.limit.desc2': {
    es: ' de la configuración de tu cuenta.',
    en: ' of your account setup.',
    pt: ' da configuração da sua conta.',
  },
  'home.product.payment.link': {
    en: 'Payment link',
    es: 'Link de pago',
    pt: 'Link de pagamento',
  },
  'home.product.payment.first-steps': {
    en: 'Getting started with dLocal Go',
    es: 'Primeros pasos en dLocal Go',
    pt: 'Primeiros passos com dLocal Go',
  },
  'home.product.payment.link.desc': {
    en: 'The simplest way to start getting paid. Create a payment link in 1 minute and share it with your clients.',
    es: 'La forma más simple de empezar a cobrar. Crea un link de pago en 1 minuto y compártelo con tus clientes.',
    pt: 'A maneira mais simples de começar a receber pagamentos. Crie um link de pagamento em 1 minuto e compartilhe com seus clientes.',
  },

  'home.product.payment.first-steps.desc': {
    en: 'Check out our getting started guide to make using dLocal Go a breeze.',
    es: 'Visita nuestra guía de primeros pasos para que empezar a usar dLocal Go sea simple.',
    pt: 'Confira nosso guia de introdução para facilitar o uso do dLocal Go.',
  },
  'home.product.payment.link.link': {
    en: 'Create payment link',
    es: 'Crear link de pago',
    pt: 'Criar link de pagamento',
  },
  'home.product.payment.commissions': {
    en: 'Pay per use',
    es: 'Pago por uso',
    pt: 'Pague por uso',
  },
  'home.product.payment.commissions.desc': {
    en: 'Learn about the payment methods available in each country and the transaction fee.',
    es: 'Conoce los métodos de pago disponibles en cada país y la comisión por transacciones.',
    pt: 'Conheça os métodos de pagamento disponíveis em cada país e a taxa de transação.',
  },
  'home.product.payment.commissions.link': {
    en: 'Check payment methods',
    es: 'Ver métodos de pago',
    pt: 'Veja formas de pagamento',
  },
  'home.product.payment.first-steps.link': {
    en: 'Start today',
    es: 'Empezar hoy',
    pt: 'Começa hoje',
  },
  'home.product.link': {
    es: 'Conoce los productos',
    en: 'Know our products',
    pt: 'Conheça nossos produtos',
  },
  'home.product.desc': {
    es: 'Ofrece métodos de pago locales y deja de perder ventas',
    en: 'Offer local payment methods and stop losing sales',
    pt: 'Ofereça métodos de pagamento locais e pare de perder vendas',
  },
  'home.balances.title': {
    en: 'Balances',
    es: 'Balances',
    pt: 'Saldos',
  },
  'home.balance.availableBalance': {
    en: 'Available balance',
    es: 'Saldo disponible',
    pt: 'Saldo disponível',
  },
  'balance.availableBalance.desc': {
    en: 'Available amount to transfer to your bank account.',
    es: 'Cantidad disponible para transferir a su cuenta bancaria.',
    pt: 'Montante disponível para transferência para sua conta bancária.',
  },
  'home.balance.inTransitBalance': {
    en: 'In transit',
    es: 'En tránsito',
    pt: 'Em trânsito',
  },
  'balance.inTransitBalance.desc': {
    en: 'Sum of all completed transactions that have not yet cleared the settlement period ({0} days for local payment methods and {1} days for cards).',
    es: 'Suma de todas las transacciones completadas que aún no han cumplido el período de liquidación ({0} días para métodos de pago locales y {1} días para tarjetas).',
    pt: 'Soma de todas as transações concluídas que ainda não completaram o período de liquidação ({0} dias para métodos de pagamento locais e {1} dias para cartões).',
  },
  'home.balance.currentBalance': {
    en: 'Current balance',
    es: 'Saldo actual',
    pt: 'Saldo atual',
  },
  'balance.currentBalance.desc': {
    en: 'Sum of available and in transit balances.',
    es: 'Suma de saldos disponibles y en tránsito.',
    pt: 'Soma dos saldos disponíveis e em trânsito.',
  },
  'home.balance.totalAccumulated': {
    en: 'Total accumulated',
    es: 'Total acumulado',
    pt: 'Total acumulado',
  },
  'balance.totalAccumulated.desc': {
    en: 'All-time accumulated total from all completed transactions minus all pending and completed settlements.',
    es: 'Total acumulado de todas las transacciones completadas menos todas las liquidaciones pendientes y completadas.',
    pt: 'Total acumulado de todas as transações concluídas menos todas as liquidações pendentes e concluídas.',
  },
  'home.balance.lastUpdate': {
    en: 'Last update:',
    es: 'Última actualización:',
    pt: 'Última atualização:',
  },
  'home.readyMsg.subtitle': {
    en: 'Grow your business with dLocal Go',
    es: 'Hacé crecer tu negocio con dLocal Go',
    pt: 'Expanda seus negócios com dLocal Go',
  },
  'home.readyMsg.firstTime': {
    en: 'You are just <span class="text-dgo-primary text-dgo-boldest">one step</span> away from using the full potential of dLocal Go.',
    es: 'Te falta <span class="text-dgo-primary text-dgo-boldest">solo un paso</span> para utilizar todo el potencial de dLocal Go.',
    pt: 'Você está a apenas <span class="text-dgo-primary text-dgo-boldest">um passo</span> de usar todo o potencial do dLocal Go.',
  },
  'home.readyMsg.countries.title': {
    en: 'Countries and payment methods available',
    es: 'Países y medios de pago disponibles',
    pt: 'Países e métodos de pagamento disponíveis',
  },
  'home.complete-profile.kyc1.desc': {
    en: 'Complete your profile to be able to accept payments',
    es: 'Completa tu perfil para poder empezar a cobrar',
    pt: 'Complete seu perfil para começar a receber pagamentos',
  },
  'home.complete-profile.kyc2.desc': {
    en: 'Complete your profile to improve your experience',
    es: 'Completa tu perfil para mejorar tu experiencia',
    pt: 'Complete seu perfil para melhorar sua experiência',
  },
  'home.readyMsg.countries.foot.part1': {
    en: 'If you wish, ',
    es: 'Si lo deseas, ',
    pt: 'Se o desejas, ',
  },
  'home.readyMsg.countries.foot.link': {
    en: 'you can disable',
    es: 'puedes desactivar',
    pt: 'você pode desativar',
  },
  'home.readyMsg.countries.foot.part2': {
    en: ' a country or form of payment.',
    es: ' un país o forma de pago.',
    pt: ' um país ou forma de pagamento.',
  },
  'home.readyMsg.paso2.title': {
    en: 'Process payments up to USD {0}.',
    es: 'Procesa pagos hasta USD {0}.',
    pt: 'Processar pagamentos até USD {0}.',
  },
  'home.readyMsg.paso2.link': {
    en: 'Complete step 2',
    es: 'Completa el paso 2',
    pt: 'Complete o passo 2',
  },
  'home.readyMsg.paso2.desc': {
    en: ' to process payments without limit and withdraw the money from your sales.',
    es: ' para procesar pagos sin límite y retirar el dinero de tus ventas.',
    pt: ' para processar pagamentos sem limite e retirar o dinheiro das suas vendas.',
  },
  'home.readyMsg.step2.tooltip.title': {
    en: 'Your current benefits',
    es: 'Tus beneficios actuales',
    pt: 'Seus benefícios atuais',
  },
  'home.readyMsg.step2.tooltip.item1': {
    en: 'Process payments up to USD 3000.',
    es: 'Procesa pagos hasta USD 3000.',
    pt: 'Processe pagamentos de até US$ 3.000.',
  },
  'home.readyMsg.step2.tooltip.item2': {
    en: 'Countries and payment methods available.',
    es: 'Países y medios de pago disponibles.',
    pt: 'Países e métodos de pagamento disponíveis.',
  },
  'home.readyMsg.step2.tooltip.desc1': {
    en: 'You can now sell in the region you want, with all the payment methods your clients use. If you wish, ',
    es: 'Ya puedes vender en la región que quieras, con todos los métodos de pago que usan tus clientes. Si lo deseas, ',
    pt: 'Agora você pode vender na região que desejar, com todos os métodos de pagamento que seus clientes utilizam. Se o desejas, ',
  },
  'home.readyMsg.step2.tooltip.disable': {
    en: 'you can disable',
    es: 'puedes desactivar',
    pt: 'você pode desativar',
  },
  'home.readyMsg.step2.tooltip.desc2': {
    en: ' a country or payment method.',
    es: ' un país o forma de pago.',
    pt: ' um país ou método de pagamento.',
  },
  'home.readyMsg.step2.complete-now': {
    en: 'Complete now',
    es: 'Completar Ahora',
    pt: 'Conclua agora',
  },
  'balances.noData': {
    en: 'There is no information to display.',
    es: 'No hay información para mostrar.',
    pt: 'Não há informações a serem exibidas.',
  },
  'emailValidation.description.message': {
    en: 'By clicking on the button below, we will send a confirmation email to <b>{0}</b>.',
    es: 'Haciendo clic en el siguiente botón, enviarémos un correo de confirmación a <b>{0}</b>.',
    pt: 'Ao clicar no botão abaixo, enviaremos um e-mail de confirmação para <b>{0}</b>.',
  },
  'emailValidation.send.btn': {
    en: 'Send email',
    es: 'Enviar correo electrónico',
    pt: 'Enviar e-mail',
  },
  'emailValidation.resend.btn': {
    en: 'Resend email',
    es: 'Reenviar correo electrónico',
    pt: 'Reenviar e-mail',
  },
  'emailValidation.success.title': {
    en: 'Email sent!',
    es: '¡Correo enviado!',
    pt: 'E-mail enviado!',
  },
  'emailValidation.success.message': {
    en: 'A confirmation email has been sent to <b>{0}</b>.',
    es: 'Un correo de confirmación ha sido enviado a <b>{0}</b>.',
    pt: 'Um e-mail de confirmação foi enviado para <b>{0}</b>.',
  },
  'emailValidation.success.resend.message': {
    en: 'Another confirmation email has been sent to <b>{0}</b>.',
    es: 'Otro correo de confirmación ha sido enviado a <b>{0}</b>.',
    pt: 'Outro e-mail de confirmação foi enviado para <b>{0}</b>.',
  },
  'msgError.emailValidation.invalidEmail': {
    en: 'There is no user with this email',
    es: 'No existe usuario con este email',
    pt: 'Não há usuário com esse e-mail',
  },
  'msgError.emailValidation.tokenExpired': {
    en: 'Token expired.',
    es: 'Token expirado.',
    pt: 'Token expirado.',
  },
  'settlements.details': {
    en: 'Settlement detail',
    es: 'Detalle de retiros',
    pt: 'Detalhe de retiros',
  },
  'settlements.status.PENDING': {
    en: 'Pending',
    es: 'Pendiente',
    pt: 'Pendente',
  },
  'settlements.status.COMPLETED': {
    en: 'Completed',
    es: 'Completada',
    pt: 'Concluído',
  },
  'settlements.status.CANCELLED': {
    en: 'Cancelled',
    es: 'Cancelada',
    pt: 'Cancelada',
  },
  'settlements.status.FAILED': {
    en: 'Failed',
    es: 'Fallido',
    pt: 'Falhou',
  },
  'settlements.status.DELIVERED': {
    en: 'Delivered',
    es: 'Entregado',
    pt: 'Entregue',
  },
  'settlements.status.REJECTED': {
    en: 'Rejected',
    es: 'Rechazado',
    pt: 'Rejeitado',
  },
  'settlements.goto.bankAccounts.desc1': {
    en: 'If you want to add a new account, go to the',
    es: 'Si quieres agregar una nueva cuenta, dirígete a la sección',
    pt: 'Se você deseja adicionar uma nova conta, vá para a seção',
  },
  'settlements.goto.bankAccounts.link': {
    en: 'My bank accounts',
    es: 'Mis cuentas bancarias',
    pt: 'Minhas contas bancárias',
  },
  'settlements.goto.bankAccounts.desc2': {
    en: 'section.',
    es: '.',
    pt: '.',
  },
  'accountType.CHECKING': {
    en: 'Checking account',
    es: 'Cuenta corriente',
    pt: 'Conta corrente',
  },
  'accountType.SAVING': {
    en: 'Savings account',
    es: 'Cuenta de ahorro',
    pt: 'Conta poupança',
  },
  'accountType.SALARY': {
    en: 'Salary account',
    es: 'Cuenta de salario',
    pt: 'Conta salário',
  },
  'accountType.VISTA': {
    en: 'Vista',
    es: 'Vista',
    pt: 'Vista',
  },
  'accountType.MASTER': {
    en: 'Master',
    es: 'Master',
    pt: 'Master',
  },
  'settlements.button': {
    en: 'CREATE SETTLEMENT',
    es: 'CREAR RETIRO',
    pt: 'RETIRAR FUNDOS',
  },
  'settlements.noSettlements': {
    en: 'You have no settlements yet',
    es: 'No tienes ninguna liquidación aún',
    pt: 'Você ainda não tem liquidações',
  },
  'settlements.noSettlements.desc': {
    en: 'Before you can request settlements, we need to know more about you and your company. To complete the required information click',
    es: 'Antes de que puedas solicitar liquidaciones, necesitamos saber más sobre ti y tu negocio. Para completar la información requerida haz clic',
    pt: 'Antes que você possa solicitar liquidações, precisamos saber mais sobre você e sua empresa. Para preencher as informações necessárias clique',
  },
  'settlements.noSettlements.kycReviewDesc': {
    en: 'Before you can request settlements, we need to review the information submitted. For more information, click here',
    es: 'Antes de que puedes solicitar liquidaciones, necesitamos revisar la información enviada. Para más información haz clic',
    pt: 'Antes que você possa solicitar liquidações, precisamos revisar as informações enviadas. Para mais informações, clique',
  },
  'settlements.noSettlements.here': {
    en: 'here.',
    es: 'aquí.',
    pt: 'aqui.',
  },
  'settlements.creationDate': {
    en: 'Creation date',
    es: 'Fecha de creación',
    pt: 'Data de criação',
  },
  'settlements.date': {
    en: 'Date',
    es: 'Fecha',
    pt: 'Data',
  },
  'settlements.time': {
    en: 'Time',
    es: 'Hora',
    pt: 'Hora',
  },
  'settlements.id': {
    en: 'ID',
    es: 'ID',
    pt: 'ID',
  },
  'settlements.amountRequested': {
    en: 'Amount requested',
    es: 'Monto solicitado',
    pt: 'Quantidade solicitada',
  },
  'settlements.fee': {
    en: 'Fee',
    es: 'Tarifa',
    pt: 'Taxa',
  },
  'settlements.bank': {
    en: 'Bank',
    es: 'Banco',
    pt: 'Banco',
  },
  'settlements.paymentDate': {
    en: 'Payment date',
    es: 'Fecha de pago',
    pt: 'Data de pagamento',
  },
  'settlements.status': {
    en: 'Status',
    es: 'Estado',
    pt: 'Estado',
  },
  'settlements.filter.status': {
    en: 'Select state',
    es: 'Seleccione estado',
    pt: 'Selecione o estado',
  },
  'requestSettlement.title': {
    en: 'Request settlement',
    es: 'Solicitar liquidación',
    pt: 'Solicitar liquidação',
  },
  'requestSettlement.success': {
    en: 'Settlement request created successfully!',
    es: '¡Solicitud de liquidación creada con éxito!',
    pt: 'Solicitação de liquidação criada com sucesso!',
  },
  'requestSettlement.desc': {
    en: 'The minimum amount to request a settlement without additional charges is USD {0}, if you request a lower amount a charge of USD {1} or the equivalent in your functional currency will be charged.',
    es: 'El monto mínimo para solicitar una liquidación sin cargos adicionales es de USD {0}, si solicita un monto menor se cobrará un cargo de USD {1} o el equivalente en su moneda operativa.',
    pt: 'O valor mínimo para solicitar uma liquidação sem encargos adicionais é de USD {0}, se você solicitar um valor inferior, será cobrada uma taxa de USD {1} ou o equivalente em sua moeda operacional.',
  },
  'beneficiary.isRequired': {
    en: 'You must select a beneficiary.',
    es: 'Debe seleccionar un beneficiario.',
    pt: 'Você deve selecionar um beneficiário.',
  },
  'requestSettlement.sendRequest': {
    en: 'Send request',
    es: 'Enviar petición',
    pt: 'Enviar pedido',
  },
  'requestSettlement.amount': {
    en: 'Amount',
    es: 'Monto',
    pt: 'Valor',
  },
  'requestSettlement.amount.noAvailable': {
    en: 'You have no available balance.',
    es: 'No tienes saldo disponible.',
    pt: 'Você não tem saldo disponível.',
  },
  'requestSettlement.withdrawalsDisabled': {
    en: 'Withdrawals are disabled for this account.',
    es: 'Los retiros están deshabilitados para esta cuenta.',
    pt: 'As retiradas estão desativadas para esta conta.',
  },
  'requestSettlement.confirmMessage': {
    en: 'Since the request amount is less than USD {0}, a charge of <b>USD {1}</b> or the equivalent in your operating currency will be charged.',
    es: 'Dado que el monto de la solicitud es inferior a USD {0}, se cobrará un cargo de <b>USD {1}</b> o el equivalente en su moneda operativa.',
    pt: 'Como o valor da solicitação é inferior a USD {0}, será cobrada uma taxa de <b>USD {1}</b> ou o equivalente em sua moeda operacional.',
  },
  'msgError.settlementInProcess': {
    en: 'You already have a settlement request in process.',
    es: 'Ya tiene una solicitud de liquidación en proceso.',
    pt: 'Você já tem uma solicitação de liquidação em andamento.',
  },
  'msgError.amountExceedsBalance': {
    en: 'The indicated amount exceeds the available balance.',
    es: 'El monto indicado supera el balance disponible',
    pt: 'O valor indicado excede o saldo disponível',
  },
  'msgError.paymentProcessorError': {
    en: 'Could not generate settlement request in payment processor.',
    es: 'No se pudo generar la solicitud de liquidación en el procesador de pagos.',
    pt: 'Não foi possível gerar a solicitação de liquidação no processador de pagamentos.',
  },
  'selectBeneficiary.withoutData.title': {
    en: 'Please, enter a bank account.',
    es: 'Por favor, ingrese una cuenta bancaria.',
    pt: 'Por favor, insira uma conta bancária.',
  },
  'selectBeneficiary.titleWithData': {
    en: 'Select an existing bank account or enter a new one.',
    es: 'Seleccione una cuenta bancaria existente o ingresa una nueva.',
    pt: 'Selecione uma conta bancária existente ou insira uma nova.',
  },
  'selectBeneficiary.title': {
    en: 'Existing bank accounts',
    es: 'Cuentas bancarias existentes',
    pt: 'Contas bancárias existentes',
  },
  'beneficiary.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'beneficiary.name': {
    en: 'Legal entity name',
    es: 'Nombre legal del negocio',
    pt: 'Razão social',
  },
  'beneficiary.documentType': {
    en: 'Document type',
    es: 'Tipo de documento',
    pt: 'Tipo de documento',
  },
  'beneficiary.document': {
    en: 'Document',
    es: 'Documento',
    pt: 'Documento',
  },
  'beneficiary.phone': {
    en: 'Phone',
    es: 'Teléfono',
    pt: 'Telefone',
  },
  'beneficiary.address': {
    en: 'Address',
    es: 'Dirección',
    pt: 'Endereço',
  },
  'beneficiary.address.tooltip': {
    en: 'Address from where you registered your bank account.',
    es: 'Dirección desde donde registraste tu cuenta bancaria.',
    pt: 'Endereço de onde você registrou sua conta bancária.',
  },
  'beneficiary.city': {
    en: 'City',
    es: 'Ciudad',
    pt: 'Cidade',
  },
  'beneficiary.bankAccountLabel': {
    en: 'Bank account',
    es: 'Cuenta bancaria',
    pt: 'Conta  bancária',
  },
  'beneficiary.accountType': {
    en: 'Account type',
    es: 'Tipo de cuenta',
    pt: 'Tipo de conta',
  },
  'beneficiary.bankCode': {
    en: 'Bank',
    es: 'Banco',
    pt: 'Banco',
  },
  'beneficiary.bankAccount': {
    en: 'Bank account number',
    es: 'Número de cuenta bancaria',
    pt: 'Numero de conta bancária',
  },
  'beneficiary.confirmBankAccount': {
    en: 'Confirm bank account number',
    es: 'Confirmar número de cuenta bancaria',
    pt: 'Confirmar numero de conta bancária',
  },
  'beneficiary.notMatchBankAccount': {
    en: 'Bank account number entered do not match.',
    es: 'Número de cuenta bancaria ingresada no coincide.',
    pt: 'Numero de conta bancária inserida não corresponde.',
  },
  'beneficiary.branch': {
    en: 'Branch',
    es: 'Sucursal',
    pt: 'Agência',
  },
  'beneficiary.swiftCode': {
    en: 'SWIFT/BIC Code',
    es: 'Código SWIFT/BIC',
    pt: 'Código SWIFT/BIC',
  },
  'beneficiary.routingNumber': {
    en: 'Routing number',
    es: 'Routing number',
    pt: 'Routing number',
  },
  'beneficiary.confirmIBAN': {
    en: 'Confirm IBAN',
    es: 'Confirmar IBAN',
    pt: 'Confirmar IBAN',
  },
  'beneficiary.confirmAccount': {
    en: 'Confirm {0}',
    es: 'Confirmar {0}',
    pt: 'Confirmar {0}',
  },
  'beneficiary.notMatchIBAN': {
    en: 'IBAN entered do not match.',
    es: 'IBAN ingresados no coinciden.',
    pt: 'IBAN inseridas não correspondem.',
  },
  'beneficiary.noteRequiredfields': {
    en: '(*) Required fields.',
    es: '(*) Campos obligatórios.',
    pt: '(*) Campos obrigatórios.',
  },
  'msgError.addBeneficiary.invalidData': {
    en: 'The data entered contains errors, please check them.',
    es: 'Los datos ingresados ​​contienen errores, por favor verifíquelos.',
    pt: 'Os dados inseridos contêm erros, verifique-os.',
  },
  'msgError.addBeneficiary.document': {
    en: 'The document entered is incorrect.',
    es: 'El documento ingresado es incorrecto.',
    pt: 'O documento digitado está incorreto.',
  },
  'msgError.addBeneficiary.countryIndicated': {
    en: 'The selected country is invalid.',
    es: 'El país seleccionado es inválido.',
    pt: 'O país selecionado é inválido.',
  },
  'msgError.addBeneficiary.bankAccountNumber': {
    en: 'The bank account number entered is invalid.',
    es: 'El número de cuenta bancaria ingresado es inválido.',
    pt: 'O número da conta bancária inserido é inválido.',
  },
  'msgError.addBeneficiary.bankAccountBranch': {
    en: 'The entered branch is invalid.',
    es: 'La sucursal ingresada es inválida.',
    pt: 'A filial inserida é inválida.',
  },
  'msgError.addBeneficiary.bankAccountType': {
    en: 'The type of bank account entered is invalid.',
    es: 'El tipo de cuenta bancaria ingresado es inválido.',
    pt: 'O tipo de conta bancária inserido é inválido.',
  },
  'msgError.addBeneficiary.alreadyExists.headline': {
    en: 'There is a bank account already registered with this number.',
    es: 'Ya existe una cuenta bancaria registrada con este número.',
    pt: 'Já existe uma conta bancária cadastrada com este número.',
  },
  'msgError.addBeneficiary.alreadyExists.subheadline': {
    en: 'Delete the existing account and create a new one.',
    es: 'Elimina esta cuenta y crea una nueva.',
    pt: 'Exclua a conta existente e crie uma nova.',
  },
  'paymentLink.backBtn': {
    en: 'Back to payment links',
    es: 'Volver a links de pago',
    pt: 'Voltar para links de pagamento',
  },
  'paymentLink.title': {
    en: 'Payment link',
    es: 'Link de pago',
    pt: 'Link de pagamento',
  },
  'paymentLink.subtitle': {
    en: 'Create a new payment link',
    es: 'Crear un nuevo link de pago',
    pt: 'Criar um novo link de pagamento',
  },
  'paymentLink.recurring.subtitle': {
    en: 'Create a new recurring payment link',
    es: 'Crear un nuevo link de pago recurrente',
    pt: 'Criar um novo link de pagamento recorrente',
  },
  'paymentLink.noteRequiredfields': {
    en: '(*) Required fields.',
    es: '(*) Campos obligatórios.',
    pt: '(*) Campos obrigatórios.',
  },
  'paymentLink.noteDesc': {
    en: 'NOTE: If you check <b>Send Mail</b>, an email with the created payment link will automatically be sent to your client.',
    es: 'NOTA: Si marca <b>Enviar correo electrónico</b>, se enviará automáticamente a su cliente un correo electrónico con el link de pago creado.',
    pt: 'NOTA: Se você marcar <b>Enviar e-mail</b>, um e-mail com o link de pagamento criado será automaticamente enviado ao seu cliente.',
  },
  'paymentLink.sendEmailError': {
    en: 'The selected client does not have an associated email.',
    es: 'El cliente seleccionado no tiene un email asociado.',
    pt: 'O cliente selecionado não possui um e-mail associado.',
  },
  'paymentLink.existingClientError': {
    en: 'Please select a client.',
    es: 'Por favor, seleccione un cliente.',
    pt: 'Por favor, selecione um cliente.',
  },
  'paymentLink.existingClients.noClients': {
    en: 'You have no clients yet. Add them in the "New client" tab.',
    es: 'No tienes clientes aún. Agrégalos en la pestaña "Nuevo cliente".',
    pt: 'Você ainda não tem clientes. Adicione-os na guia "Novo cliente".',
  },
  'paymentLink.existingClients.noClientsCountry': {
    en: 'You don\'t have clients in the selected country yet. Add them in the "New client" tab.',
    es: 'Aún no tienes clientes en el país seleccionado. Agrégalos en la pestaña "Nuevo cliente".',
    pt: 'Você ainda não tem clientes no país selecionado. Adicione-os na guia "Novo cliente".',
  },
  'paymentLink.exceedsMaxTransactionAmountCc': {
    en: 'The amount you deposited exceeds the maximum of USD {0} that you are allowed in your account settings. To process unlimited payments, ',
    es: 'El monto que ingresaste supera el máximo de USD {0} que tienes permitido en la configuración de tu cuenta. Para procesar pagos sin límites, ',
    pt: 'O valor inserido excede o máximo de USD {0} permitido no nível atual da sua conta. Para processar pagamentos ilimitados, ',
  },
  'paymentLink.exceedsMaxTransactionAmountCc.here': {
    en: 'complete step 2 account settings.',
    es: 'completa el paso 2 de configuración.',
    pt: 'conclua o passo 2 da configuração de conta.',
  },
  'paymentLink.country': {
    en: "Buyer's country",
    es: 'País del comprador',
    pt: 'País do comprador',
  },
  'paymentLink.country.tooltip': {
    en: 'Country from where your customer will pay for the payment link.',
    es: 'País desde donde tu cliente pagará el link de pago.',
    pt: 'País de onde seu cliente pagará pelo link de pagamento.',
  },
  'paymentLink.country.error': {
    en: 'You have no payment methods configured for this country.',
    es: 'No tienes ningún método de pago configurado para este país.',
    pt: 'Você não tem métodos de pagamento configurados para este país.',
  },
  'paymentLink.currency': {
    en: 'Currency',
    es: 'Moneda',
    pt: 'Moeda',
  },
  'paymentLink.currency.tooltip': {
    en: 'Remember that your client will always pay in their local currency.',
    es: 'Recuerda que tu cliente siempre pagará en su moneda local.',
    pt: 'Lembre-se de que seu cliente sempre pagará na moeda local.',
  },
  'paymentLink.amount': {
    en: 'Amount',
    es: 'Total',
    pt: 'Total',
  },
  'currencySelector.title': {
    en: 'Currency to fix transaction',
    es: 'Moneda para fijar la transacción',
    pt: 'Moeda para corrigir a transação',
  },
  'currencySelector.tooltip': {
    en: 'Your client will pay in {0}.<br/>You will receive {1}.',
    es: 'Tu cliente pagará en {0}.<br/>Tu recibirás {1}.',
    pt: 'Seu cliente pagará em {0}.<br/>Você receberá {1}.',
  },
  'paymentLink.tooltip.sameCurrency': {
    en: 'Take in account that international transactions are fixed in USD. You will receive {0}.',
    es: 'Ten en cuenta que las transacciones internacionales son fijadas en USD. Tu recibirás {0}.',
    pt: 'Tenha em conta que as transacções internacionais são fixadas em USD. Você receberá {0}.',
  },
  'paymentLink.amountWantToCharge': {
    en: 'You want to charge',
    es: 'Tú quieres cobrar',
    pt: 'Você quer cobrar',
  },
  'paymentLink.amountYouWillReceive': {
    en: 'You will receive',
    es: 'Tu recibirás',
    pt: 'Você receberá',
  },
  'paymentLink.amountYourClientWillPay': {
    en: 'Your client will pay',
    es: 'Tu cliente pagará',
    pt: 'Seu cliente pagará',
  },
  'paymentLink.exchangeRate': {
    en: 'Exchange rate:',
    es: 'Tipo de cambio:',
    pt: 'Taxa de câmbio:',
  },
  'paymentLink.exchangeRate.fxMessage': {
    en: 'Slight FX variations may apply.',
    es: 'Pueden aplicarse pequeñas variaciones de FX.',
    pt: 'Pequenas variações de FX podem ser aplicadas.',
  },
  'paymentLink.amount.error': {
    en: 'This amount is less than the minimum allowed (USD 1)',
    es: 'Este monto es inferior al mínimo permitido (USD 1)',
    pt: 'Este valor é inferior ao mínimo permitido (USD 1)',
  },
  'paymentLink.description': {
    en: 'Name of product or service (Optional)',
    es: 'Nombre del producto o servicio (Opcional)',
    pt: 'Nome do produto ou serviço (Opcional)',
  },
  'paymentLink.description.tooltip': {
    en: 'Your clients will see this name when opening the payment link.',
    es: 'Tus clientes verán este nombre al abrir el link de pago.',
    pt: 'Seus clientes verão esse nome ao abrir o link de pagamento.',
  },
  'paymentLink.clientEmail': {
    en: 'Client e-mail (Optional)',
    es: 'Email del cliente (Opcional)',
    pt: 'E-mail do cliente (opcional)',
  },
  'virtualpost.clientEmail': {
    en: 'Client e-mail',
    es: 'Email del cliente',
    pt: 'E-mail do cliente',
  },
  'paymentLink.sendEmail': {
    en: 'Share the payment link with my client by email',
    es: 'Compartir el link de pago con mi cliente por email',
    pt: 'Compartilhar o link de pagamento com meu cliente por e-mail',
  },
  'paymentLink.addClientData': {
    en: 'Add more client information (Optional) ',
    es: 'Agregar más información del cliente (Opcional)',
    pt: 'Adicionar mais informações do cliente (opcional)',
  },
  'paymentLink.selectClient': {
    en: 'Select an already saved client or create a new one.',
    es: 'Seleccionar un cliente ya guardado o crear uno nuevo.',
    pt: 'Selecionar um cliente já salvo ou criar um novo.',
  },
  'paymentLink.newClient': {
    en: 'New client',
    es: 'Nuevo cliente',
    pt: 'Novo cliente',
  },
  'paymentLink.existingClient': {
    en: 'Clients',
    es: 'Clientes',
    pt: 'Clientes',
  },
  'paymentLink.create': {
    en: 'CREATE PAYMENT LINK',
    es: 'CREAR LINK DE PAGO',
    pt: 'CRIAR LINK DE PAGAMENTO',
  },
  'paymentLink.create.recurring': {
    en: 'CREATE RECURRING PAYMENT LINK',
    es: 'CREAR LINK DE PAGO RECURRENTE',
    pt: 'CRIAR LINK DE PAGAMENTO RECORRENTE',
  },
  'paymentLink.clientFirstName': {
    en: 'First name',
    es: 'Nombre',
    pt: 'Nome',
  },
  'paymentLink.clientLastName': {
    en: 'Last name',
    es: 'Apellido',
    pt: 'Sobrenome',
  },
  'paymentLink.clientDocumentType': {
    en: 'Document type',
    es: 'Tipo de documento',
    pt: 'Tipo de documento',
  },
  'paymentLink.clientDocument': {
    en: 'Document number',
    es: 'Número de documento',
    pt: 'Número do Documento',
  },
  'paymentLink.document.error': {
    en: 'The document entered is incorrect',
    es: 'El documento ingresado es incorrecto',
    pt: 'O documento digitado está incorreto',
  },
  'paymentLink.shareLink.headline': {
    en: 'Payment link ready! Share it with your client',
    es: '¡Link de pago listo! Compártelo con tu cliente',
    pt: 'Link de pagamento pronto! Compartilhe com seu cliente',
  },
  'paymentLink.shareLink.recurring.headline': {
    en: 'Recurring payment link ready! Share it with your clients',
    es: '¡Link de pago recurrente listo! Compártelo con tus clientes',
    pt: 'Link de pagamento recorrente pronto! Compartilhe com seus clientes',
  },
  'paymentLink.shareLink.copyLink': {
    en: 'Copy link',
    es: 'Copiar link',
    pt: 'Copiar link',
  },
  'paymentLink.shareLink.field1': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'paymentLink.shareLink.field2': {
    en: 'Amount',
    es: 'Total',
    pt: 'Total',
  },
  'paymentLink.shareLink.field3': {
    en: 'Description',
    es: 'Descripción',
    pt: 'Descrição',
  },
  'paymentLink.shareLink.subheadline1': {
    en: 'Copy and share this link',
    es: 'Copia y pega este link',
    pt: 'Copie e cole este link',
  },
  'paymentLink.shareLink.subheadline2': {
    en: 'Or share this link via',
    es: 'O comparte el link a través de',
    pt: 'Ou compartilhe o link através',
  },
  'paymentLink.shareLink': {
    en: 'Enter the link, choose your payment method, and done!',
    es: 'Ingresa al link, elige tu forma de pago, ¡y listo!',
    pt: 'Insira o link, escolha sua forma de pagamento, e pronto!',
  },
  'paymentLink.shareLink.whatsapp': {
    en: 'WhatsApp',
    es: 'WhatsApp',
    pt: 'WhatsApp',
  },
  'paymentLink.shareLink.facebook': {
    en: 'Facebook',
    es: 'Facebook',
    pt: 'Facebook',
  },
  'paymentLink.shareLink.moreOptions': {
    en: 'More options',
    es: 'Mas opciones',
    pt: 'Mais opções',
  },
  'paymentLink.quick.guide.tab': {
    en: 'QUICK GUIDE',
    es: 'GUÍA RÁPIDA',
    pt: 'GUIA RÁPIDO',
  },
  'paymentLink.quick.guide.headline': {
    en: 'How to create a payment link',
    es: 'Cómo crear un link de pago',
    pt: 'Como criar um link de pagamento',
  },
  'paymentLink.quick.guide.paragraph.1': {
    en: 'Fill in the country where you will collect the payment, the currency and the price of your product or service.',
    es: 'Ingresa el país donde cobrarás el pago, la moneda y el monto de tu producto o servicio.',
    pt: 'Preencha o país onde irá receber o pagamento, a moeda e o preço do seu produto ou serviço.',
  },
  'paymentLink.quick.guide.paragraph.2': {
    en: 'If you want to, fill in the name of your product or service and the data of your client.',
    es: 'Si quieres, ingresa el nombre de tu producto o servicio y los datos de tu cliente.',
    pt: 'Se desejar, preencha o nome do seu produto ou serviço e os dados do seu cliente.',
  },
  'paymentLink.quick.guide.paragraph.3': {
    en: "Share the link with your clients by WhatsApp, email or social networks and that's it!",
    es: 'Comparte el link con tus clientes por whatsapp, mail o redes sociales ¡y listo!',
    pt: 'Compartilhe o link com seus clientes por WhatsApp, e-mail ou redes sociais e pronto!',
  },
  'paymentLink.quick.guide.paragraph.4': {
    en: 'You can check the payment status of your links from the payment link panel.',
    es: 'Puedes revisar el estado del pago de tus links desde el panel de Link de pago.',
    pt: 'Você pode verificar o status de pagamento de seus links no painel de links de pagamento.',
  },
  'msgError.createPaymentLink.invalidData': {
    en: 'The data entered is invalid.',
    es: 'Los datos ingresados son inválidos.',
    pt: 'Os dados inseridos são inválidos.',
  },
  'msgError.createPaymentLink.currencyNotSupported': {
    en: 'Currency not supported in the selected country.',
    es: 'Moneda no soportada en el país seleccionado.',
    pt: 'Moeda não suportada no país selecionado.',
  },
  'msgError.createPaymentLink.invoiceAlreadyExists': {
    en: 'An invoice with the entered ID already exists (in created or completed state).',
    es: 'Ya existe una factura con el ID ingresado (en estado creado o completado).',
    pt: 'Já existe uma fatura com o ID inserido (em estado criado ou concluído).',
  },
  'msgError.createPaymentLink.transactionLimitReached': {
    en: 'The allowed transaction limit has been reached.',
    es: 'Se alcanzó el límite de transacciones permitido.',
    pt: 'O limite de transação permitido foi atingido.',
  },
  'msgError.createPaymentLink.billingLimitReached': {
    en: 'The allowed billing amount has been reached.',
    es: 'Se alcanzó el monto de facturación permitido.',
    pt: 'O valor de faturamento permitido foi atingido.',
  },
  'msgError.createPaymentLink.transactionAmountExceeded': {
    en: 'This amount you deposited exceeds the maximum of USD {0} that you are allowed in your account settings. To process unlimited payments, <a href="{1}">complete step 2 account settings.</a>',
    es: 'El monto que ingresaste super el máximo de USD {0} que tienes permitido en la configuración de tu cuenta. Para procesar pagos sin límites, <a href="{1}"">completa el paso 2 de configuración.</a>',
    pt: 'O valor inserido excede o máximo de USD {0} permitido no nível atual da sua conta.Para processar pagamentos ilimitados, <a href="{1}">conclua o passo 2 da configuração de conta.</a>',
  },
  'msgError.createPaymentLink.amountLessThanAllowed': {
    en: 'The amount entered is less than the minimum allowed (USD {0}).',
    es: 'El monto ingresado es menor que el mínimo permitido (USD {0}).',
    pt: 'O valor inserido é menor que o mínimo permitido (USD {0}).',
  },
  'msgError.createPaymentLink.accountIsNotOperational': {
    en: 'Your account is not operational.',
    es: 'Tu cuenta no está operativa.',
    pt: 'Sua conta não está operacional.',
  },
  'msgError.createPaymentLink.noPaymentMethodConfigured': {
    en: 'It does not have any configured and/or active payment method for the indicated country.',
    es: 'No tiene ningún método de pago configurado y/o activo para el país indicado.',
    pt: 'Não possui nenhum método de pagamento configurado e/ou ativo para o país indicado.',
  },
  'msgError.createPaymentLink.amountExceedsAllowedForCards': {
    en: 'The amount entered exceeds the maximum allowed for cards, and you do not have any local payment method configured and/or active for the indicated country. Please configure local payment methods for this country.',
    es: 'El monto ingresado excede el máximo permitido para tarjetas, y no tiene ningún método de pago local configurado y/o activo para el país indicado. Por favor, configura métodos de pagos locales para este país.',
    pt: 'O valor informado excede o máximo permitido para cartões, e você não possui nenhum meio de pagamento local configurado e/ou ativo para o país indicado. Configure os métodos de pagamento locais para este país.',
  },
  'msgError.createPaymentLink.clientEmailNotEntered': {
    en: 'The email cannot be sent because it was not entered.',
    es: 'No se puede enviar el correo electrónico porque no se ingresó.',
    pt: 'O e-mail não pode ser enviado porque não foi inserido.',
  },
  'msgError.createPaymentLink.duplicatedClientId': {
    en: 'The data entered is already associated with another client ID.',
    es: 'Los datos ingresados ya están asociados a otro ID de cliente.',
    pt: 'Os dados inseridos já estão associados a outro ID de cliente.',
  },
  'createdPaymentLink.subheadline': {
    en: 'Find all the payment links you have created, their status and the most important details.',
    es: 'Encuentra todos los links de pago que creaste, su estado y los detalles más importantes.',
    pt: 'Encontre todos os links de pagamento que você criou, seu status e os detalhes mais importantes.',
  },
  'createdPaymentLink.redirectBtn': {
    en: 'CREATE PAYMENT LINK',
    es: 'CREAR LINK DE PAGO',
    pt: 'CRIAR LINK DE PAGAMENTO',
  },
  'createdPaymentLink.oneTime': {
    en: 'One-time payment link',
    es: 'Link de pago único',
    pt: 'Link de pagamento único',
  },
  'createdPaymentLink.recurring': {
    en: 'Recurring payment link',
    es: 'Link de pago recurrente',
    pt: 'Link de pagamento recorrente',
  },
  'createdPaymentLink.oneTimeLinks': {
    en: 'One-time payment links',
    es: 'Links de pago único',
    pt: 'Links de pagamento único',
  },
  'createdPaymentLink.recurringLinks': {
    en: 'Recurring payment links',
    es: 'Links de pago recurrente',
    pt: 'Links de pagamento recorrente',
  },
  'createdPaymentLink.link': {
    en: 'Payment link',
    es: 'Link de pago',
    pt: 'Link de pagamento',
  },
  'createdPaymentLink.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'createdPaymentLink.creationDate': {
    en: 'Creation date',
    es: 'Fecha de creación',
    pt: 'Data de criação',
  },
  'createdPaymentLink.expirationDate': {
    en: 'Expiration date',
    es: 'Fecha de expiración',
    pt: 'Data de validade',
  },
  'createdPaymentLink.description': {
    en: 'Description',
    es: 'Descripción',
    pt: 'Descrição',
  },
  'createdPaymentLink.amount': {
    en: 'Amount',
    es: 'Monto',
    pt: 'Total',
  },
  'createdPaymentLink.paymentStatus': {
    en: 'Payment status',
    es: 'Status del pago',
    pt: 'Status do pagamento',
  },
  'createdPaymentLink.status': {
    en: 'Status',
    es: 'Estado',
    pt: 'Status',
  },
  'createdPaymentLink.action': {
    en: 'Action',
    es: 'Acción',
    pt: 'Ação',
  },
  'createdPaymentLink.status.active': {
    en: 'Active',
    es: 'Activo',
    pt: 'Ativo',
  },
  'createdPaymentLink.status.inactive': {
    en: 'Inactive',
    es: 'Inactivo',
    pt: 'Inativo',
  },
  'createdPaymentLink.more.activate': {
    en: 'Activate',
    es: 'Activar',
    pt: 'Ativar',
  },
  'createdPaymentLink.more.deactivate': {
    en: 'Deactivate',
    es: 'Desactivar',
    pt: 'Desativar',
  },
  'createdPaymentLink.share': {
    en: 'Share',
    es: 'Compartir',
    pt: 'Compartilhar',
  },
  'createdPaymentLink.noOneTimePaymentLinks': {
    en: 'No payment links created yet',
    es: 'No hay links de pagos creados aún',
    pt: 'Nenhum link de pagamento criado ainda',
  },
  'createdPaymentLink.noRecurringPaymentLinks': {
    en: 'You don’t have recurring payment links created yet',
    es: 'Aún no creaste links de pago recurrentes',
    pt: 'Você ainda não criou links de pagamento recorrentes',
  },
  'createdPaymentLink.noPaymentLinkDesc.1': {
    en: 'Create a link',
    es: 'Crea un link',
    pt: 'Crie um link',
  },
  'createdPaymentLink.noPaymentLinkDesc.2': {
    en: ' and collect payments easily',
    es: ' y recibe pagos fácilmente',
    pt: ' e receba pagamentos facilmente',
  },
  'createdPaymentLink.copyLink': {
    en: 'Copy link',
    es: 'Copiar link',
    pt: 'Copiar link',
  },
  'createdPaymentLink.copiedLink': {
    en: 'Copied',
    es: 'Copiado',
    pt: 'Copiado',
  },
  'virtualPos.create': {
    en: 'Create payment',
    es: 'Crear pago',
    pt: 'Criar pagamento',
  },
  'virtualPos.subtitle': {
    en: 'To create a new payment, complete the following information.',
    es: 'Para crear un nuevo pago, complete la siguiente información.',
    pt: 'Para criar um novo pagamento, preencha as seguintes informações.',
  },
  'virtualPos.currency.message': {
    en: 'Remember that payment is always made in the local currency of the selected country.',
    es: 'Recuerda que el pago siempre se efectúa en moneda local del país seleccionado.',
    pt: 'Lembre-se que o pagamento é sempre feito na moeda local do país selecionado.',
  },
  'virtualPos.title': {
    en: 'Ready!',
    es: '¡Listo!',
    pt: 'Pronto!',
  },
  'virtualPos.clientData.creditCard': {
    en: 'Credit card',
    es: 'Tarjeta de crédito',
    pt: 'Cartão de crédito',
  },
  'virtualPos.clientData.title': {
    en: 'Enter your client data',
    es: 'Ingrese los datos de su cliente',
    pt: 'Insira seus dados de cliente',
  },
  'virtualPos.clientData.confirmPayment': {
    en: 'Confirm payment',
    es: 'Confirmar pago',
    pt: 'Confirme o pagamento',
  },
  'virtualPos.paymentCreated': {
    en: 'The new payment has been created successfully, you can see its details in your transaction list.',
    es: 'El nuevo pago se ha creado con éxito, puede ver sus detalles en su lista de transacciones.',
    pt: 'O novo pagamento foi criado com sucesso, você pode ver seus detalhes na sua lista de transações.',
  },
  'paymentIntegration.card.link': {
    en: 'View Integration',
    es: 'Ver Integración',
    pt: 'Ver Integração',
  },
  'paymentIntegration.card.commingSoon': {
    en: 'COMING SOON',
    es: 'PRÓXIMAMENTE',
    pt: 'EM BREVE',
  },
  'paymentIntegration.api.title': {
    en: 'API Integration',
    es: 'Integración por API',
    pt: 'Integração por API',
  },
  'paymentIntegration.api.details.headline': {
    en: 'Install dLocal Go in your site',
    es: 'Instala dLocal Go en tu sitio',
    pt: 'Instale a dLocal Go no seu site',
  },
  'paymentIntegration.api.details.description': {
    en: "Integrate your site with dLocal Go through our REST API. No matter what platform you're on or what language is implemented, the integration is super flexible.<br /><br />For more information on how to integrate, ",
    es: 'Integra tu sitio con dLocal Go a través de nuestra API REST. No importa en qué plataforma estés o el lenguaje implementado, la integración es súper flexible.<br /><br />Para más información de cómo realizar la integración, ',
    pt: 'Integre seu site com dLocal Go através de nossa API REST. Não importa em qual plataforma você está ou qual idioma está implementado, a integração é super flexível.<br /><br />Para obter mais informações sobre como integrar, ',
  },
  'paymentIntegration.api.details.link': {
    en: 'visit our documentation.',
    es: 'visita nuestra documentación.',
    pt: 'visite nossa documentação.',
  },
  'paymentIntegration.api.details.key.headline': {
    en: 'Integrations keys',
    es: 'Claves de integración',
    pt: 'Chaves de integração',
  },
  'paymentIntegration.api.details.key.description': {
    en: 'The integration keys are the necessary credentials to integrate with dLocal Go.<br /><br />There are two keys per account:',
    es: 'Las claves de integración son las credenciales necesarias para realizar tu integración con dLocal Go.<br /><br />Existen dos claves por cuenta:',
    pt: 'As chaves de integração são as credenciais necessárias para a integração com dLocal Go.<br /><br />Existem duas chaves por conta:',
  },
  'paymentIntegration.shopify.title': {
    en: 'Shopify',
    es: 'Shopify',
    pt: 'Shopify',
  },
  'paymentIntegration.shopify.details.headline': {
    en: 'Install dLocal Go in your Shopify Store',
    es: 'Instala dLocal Go en tu tienda de Shopify',
    pt: 'Instale a dLocal Go em sua loja da Shopify',
  },
  'paymentIntegration.shopify.details.description': {
    en: 'Use dLocal Go to accept payments in your Shopify store. Just click the button below to add dLocal Go payment app to your store and start accepting payments easily, quickly and safely!<br /><br />For more information on how to integrate, ',
    es: 'Usa dLocal Go para aceptar pagos en tu tienda Shopify. Solo haz click en el botón para agregar la aplicación de pago dLocal Go a tu tienda ¡y empieza a aceptar pagos de manera fácil, rápida y segura!<br /><br />Para más información de cómo realizar la integración, ',
    pt: 'Use a dLocal Go para aceitar pagamentos em sua loja da Shopify. Basta clicar no botão para adicionar o aplicativo de pagamento dLocal Go à sua loja e começar a aceitar pagamentos de forma fácil, rápida e segura!<br /><br />Para obter mais informações sobre como integrar, ',
  },
  'paymentIntegration.shopify.details.link': {
    en: 'visit our documentation.',
    es: 'visita nuestra documentación.',
    pt: 'visite nossa documentação.',
  },
  'paymentIntegration.shopify.details.button': {
    en: 'Install dLocal Go in Shopify',
    es: 'Instalar dLocal Go en Shopify',
    pt: 'Instalar dLocal Go em Shopify',
  },
  'paymentIntegration.shopify.details.transparent.description': {
    en: 'Use dLocal Go to accept payments in your Shopify store, offering a transparent experience to your brazilian customers and a redirect experience to your international customers.<br /><br /> Just click the button below to add dLocal Go to your store and start accepting payments easily, quickly and safely!<br /><br />For more information on how to integrate, ',
    es: '',
    pt: 'Use dLocal Go para aceitar pagamentos em sua loja Shopify, oferecendo uma experiência transparente para seus clientes brasileiros e uma experiência de redirecionamento para seus clientes internacionais.<br /><br /> Basta clicar no botão abaixo para adicionar a dLocal Go à sua loja e começar a aceitar pagamentos de forma fácil, rápida e segura!<br /><br />Para mais informações sobre como integrar, ',
  },
  'paymentIntegration.shopify.details.transparent.link': {
    en: 'visit our documentation.',
    es: '',
    pt: 'visite nossa documentação.',
  },
  'paymentIntegration.shopify.details.transparent.installed.headline': {
    en: 'You already have dLocal Go installed on your Shopify Store',
    es: '',
    pt: 'Você já tem a dLocal Go instalada em sua loja da Shopify.',
  },
  'paymentIntegration.shopify.details.transparent.installed.description': {
    en: 'If you want to make a test payment before going live, enable test mode.',
    es: '',
    pt: 'Se você quiser fazer um pagamento de teste antes de começar a vender, habilite o modo de teste.',
  },
  'paymentIntegration.shopify.details.transparent.installed.toggle': {
    en: 'test mode toggle',
    es: '',
    pt: 'test mode toggle',
  },
  'paymentIntegration.shopify.details.transparent.installed.description2': {
    en: 'Make sure test mode is enabled in your Shopify dashboard by following',
    es: '',
    pt: 'Certifique-se de que o modo de teste esteja ativado em seu painel da Shopify seguindo',
  },
  'paymentIntegration.shopify.details.transparent.installed.link': {
    en: ' these steps.',
    es: '',
    pt: ' estas etapas.',
  },
  'paymentIntegration.shopify.details.transparent.installed.description3': {
    en: "After you check the test mode, don't forget to deactivate it to be able to go live.",
    es: '',
    pt: 'Depois de verificar o modo de teste, não se esqueça de desativá-lo para poder processar pagamentos reais.',
  },
  'paymentIntegration.woo.title': {
    en: 'WooCommerce',
    es: 'WooCommerce',
    pt: 'WooCommerce',
  },
  'paymentIntegration.woo.details.headline': {
    en: 'Install dLocal Go in your WooCommerce Store',
    es: 'Instala dLocal Go en su tienda de WooCommerce',
    pt: 'Instale o dLocal Go em sua loja WooCommerce',
  },
  'paymentIntegration.woo.details.description': {
    en: 'Install dLocal Go checkout in your WooCommerce store and start receiving payments today.',
    es: 'Instala el checkout de dLocal Go en tu tienda WooCommerce y comienza a recibir pagos hoy mismo.',
    pt: 'Instale o checkout dLocal Go em sua loja WooCommerce e comece a receber pagamentos hoje.',
  },
  'paymentIntegration.woo.details.link': {
    en: 'Follow the step by step guide',
    es: 'Sigue la guía paso a paso',
    pt: '	Siga o passo-a-passo',
  },
  'paymentIntegration.woo.details.description2': {
    en: ' and your site will be ready to sell safely.',
    es: ' y tu sitio ya estará listo para vender de forma segura.',
    pt: ' e o seu site estará pronto para vender forma segura.',
  },
  'paymentIntegration.woo.details.link2': {
    en: 'Click here',
    es: 'Haz click aquí',
    pt: 'Clique aqui',
  },
  'paymentIntegration.woo.details.description3': {
    en: ' if you want to test the plugin.',
    es: ' si quieres probar el plugin.',
    pt: ' se quiser testar o plugin.',
  },
  'paymentIntegration.woo.details.key.headline': {
    en: 'Integration key',
    es: 'Clave de integración',
    pt: 'Chave de integração',
  },
  'paymentIntegration.woo.details.key.description': {
    en: 'Integration keys are credentials you need to integrate with dLocal Go.<br /><br />There are two keys per account:',
    es: 'La clave de integración es la credencial necesaria para realizar tu integración con dLocal Go.<br /><br />Existen dos claves por cuenta:',
    pt: 'As chaves de integração são credenciais que você precisa para integrar com dLocal Go.<br /><br />Existem duas chaves por conta:',
  },
  'paymentIntegration.woo.details.button': {
    en: 'Install dLocal Go in WooCommerce',
    es: 'Instalar dLocal Go en WooCommerce',
    pt: 'Instalar dLocal Go em WooCommerce',
  },
  'paymentIntegration.magento.title': {
    en: 'Magento',
    es: 'Magento',
    pt: 'Magento',
  },
  'paymentIntegration.magento.details.headline': {
    en: 'Install dGo in your Magento Store',
    es: 'Instala dGo en su tienda de Magento',
    pt: 'Instale o dLocal Go em sua loja Magento',
  },
  'paymentIntegration.magento.details.description': {
    en: 'Install dLocal Go checkout in your Magento store and start receiving payments today.',
    es: 'Instala el checkout de dLocal Go en tu tienda Magento y comienza a recibir pagos hoy mismo.',
    pt: 'Instale o checkout dLocal Go em sua loja Magento e comece a receber pagamentos hoje.',
  },
  'paymentIntegration.magento.details.link': {
    en: 'Follow the three installation steps',
    es: 'Sigue los tres pasos de instalación',
    pt: 'Siga as três etapas de instalação',
  },
  'paymentIntegration.magento.details.description2': {
    en: ' and your site will be ready to sell safely.',
    es: ' y tu sitio ya estará listo para vender de forma segura.',
    pt: ' e seu site estará pronto para vender com segurança.',
  },
  'paymentIntegration.magento.details.key.headline': {
    en: 'Integration key',
    es: 'Clave de integración',
    pt: 'Chave de integração',
  },
  'paymentIntegration.magento.details.key.description': {
    en: 'The integration keys are the necessary credentials to integrate with dLocal Go.<br /><br />There are two keys per account:',
    es: 'Las claves de integración son las credenciales necesarias para realizar tu integración con dLocal Go.<br /><br />Existen dos claves por cuenta:',
    pt: 'As chaves de integração são as credenciais necessárias para a integração com dLocal Go.<br /><br />Existem duas chaves por conta:',
  },
  'paymentIntegration.magento.details.button': {
    en: 'Install dLocal Go in Magento',
    es: 'Instalar dLocal Go en Magento',
    pt: 'Instalar dLocal Go em Magento',
  },
  'paymentIntegration.prestashop.title': {
    en: 'Presta Shop',
    es: 'Presta Shop',
    pt: 'Presta Shop',
  },
  'paymentIntegration.prestashop.details.headline': {
    en: 'Install dLocal Go in your PrestaShop store',
    es: 'Instala dLocal Go en tu tienda de PrestaShop',
    pt: 'Instale a dLocal Go em sua loja PrestaShop',
  },
  'paymentIntegration.prestashop.details.key.headline': {
    en: 'Integration key',
    es: 'Clave de integración',
    pt: 'Chave de integração',
  },
  'paymentIntegration.prestashop.details.description': {
    en: 'Install dLocal Go checkout in your Presta Shop store and start receiving payments today.',
    es: 'Instala el checkout de dLocal Go en tu tienda Presta Shop y comienza a recibir pagos hoy mismo.',
    pt: 'Instale o checkout dLocal Go em sua loja PrestaShop e comece a receber pagamentos hoje.',
  },
  'paymentIntegration.prestashop.details.key.description': {
    en: 'The integration keys are the necessary credentials to integrate with dLocal Go.<br /><br />There are two keys per account:',
    es: 'Las claves de integración son las credenciales necesarias para realizar tu integración con dLocal Go.<br /><br />Existen dos claves por cuenta:',
    pt: 'As chaves de integração são as credenciais necessárias para a integração com dLocal Go.<br /><br />Existem duas chaves por conta:',
  },
  'paymentIntegration.prestashop.download': {
    en: 'Download plugin',
    es: 'Descargar plugin',
    pt: 'Baixar plugin',
  },
  'paymentIntegration.prestashop.details.button': {
    en: 'Install dLocal Go in PrestaShop',
    es: 'Instalar dLocal Go en PrestaShop',
    pt: 'Instalar dLocal Go em PrestaShop',
  },
  'paymentIntegration.hostinger.title': {
    en: 'Hostinger Website Builder',
    es: 'Hostinger Website Builder',
    pt: 'Hostinger Website Builder',
  },
  'paymentIntegration.hostinger.details.headline': {
    en: 'Install dLocal Go in your Hostinger Website Builder eCommerce store',
    es: 'Instala dLocal Go en tu tienda eCommerce de Hostinger Website Builder',
    pt: 'Instale o dLocal Go na sua loja de comércio eletrônico Hostinger Website Builder',
  },
  'paymentIntegration.hostinger.details.key.headline': {
    en: 'Integration key',
    es: 'Clave de integración',
    pt: 'Chave de integração',
  },
  'paymentIntegration.hostinger.details.description': {
    en: 'Install dLocal Go checkout in your Hostinger Website Builder eCommerce store and start receiving payments today.',
    es: 'Instala el checkout de dLocal Go en tu tienda eCommerce Hostinger Website Builder y comienza a recibir pagos hoy mismo.',
    pt: 'Instale o checkout dLocal Go em sua loja de comércio eletrônico Hostinger Website Builder e comece a receber pagamentos hoje mesmo.',
  },
  'paymentIntegration.hostinger.details.follow': {
    en: 'Follow the step by step guide',
    es: 'Sigue la guía paso a paso',
    pt: 'Siga o passo-a-passo',
  },
  'paymentIntegration.hostinger.details.description2': {
    en: ' and your site will be ready to sell safely.',
    es: ' y su sitio estará listo para venderse de forma segura.',
    pt: ' e seu site estará pronto para vender com segurança.',
  },
  'paymentIntegration.hostinger.details.key.description': {
    en: 'The integration keys are the necessary credentials to integrate with dLocal Go.<br /><br />There are two keys per account:',
    es: 'Las claves de integración son las credenciales necesarias para realizar tu integración con dLocal Go.<br /><br />Existen dos claves por cuenta:',
    pt: 'As chaves de integração são as credenciais necessárias para a integração com dLocal Go.<br /><br />Existem duas chaves por conta:',
  },
  'paymentIntegration.moreInfo': {
    en: 'For more information on how to integrate, visit',
    es: 'Para más información de cómo realizar la integración, visite',
    pt: 'Para obter mais informações sobre como integrar, visite',
  },
  'paymentIntegration.moreInfo.ourDocumentation': {
    en: 'our documentation.',
    es: 'nuestra documentación.',
    pt: 'nossa documentação.',
  },
  'paymentIntegration.commingSoon': {
    en: 'COMING SOON',
    es: 'PRÓXIMAMENTE',
    pt: 'EM BREVE',
  },
  'paymentIntegration.desc': {
    en: `In this section you will find the necessary information to integrate with <b>${brandName}</b>.`,
    es: `En esta sección encontrará la información necesaria para realizar su integración con <b>${brandName}</b>.`,
    pt: `Nesta seção, você encontrará as informações necessárias para a integração com <b>${brandName}</b>.`,
  },
  'paymentIntegration.keysDesc': {
    en: `The integration keys are the necessary credentials to integrate with ${brandName}. There are two keys per account:`,
    es: `Las claves de integración son las credenciales necesarias para realizar su integración con ${brandName}. Existen dos claves por cuenta:`,
    pt: `As chaves de integração são as credenciais necessárias para a integração com ${brandName}. Existem duas chaves por conta:`,
  },
  'paymentIntegration.keysDesc.1': {
    en: '<b>API key</b>: Required for integration using our API and e-commerce integrations.',
    es: '<b>API key</b>: Necesaria para la integración utilizando nuestra API e integraciones de e-commerce.',
    pt: '<b>API key</b>: necessária para integração usando nossa API e integrações de comércio eletrônico.',
  },
  'paymentIntegration.keysDesc.2': {
    en: '<b>Secret key</b>: Required for API integration.',
    es: '<b>Secret key</b>: Necesaria para la integración por API.',
    pt: '<b>Secret key</b>: Necessária para integração com API.',
  },
  'paymentIntegration.apiKeyLabel': {
    en: 'API key',
    es: 'API key',
    pt: 'API key',
  },
  'paymentIntegration.secretKeyLabel': {
    en: 'Secret key',
    es: 'Secret key',
    pt: 'Secret key',
  },
  'paymentIntegration.createPaymentButton': {
    en: 'Create payment button',
    es: 'Crear botón de pago',
    pt: 'Criar botão de pagamento',
  },
  'paymentIntegration.copyApiKey': {
    en: 'Copy API key',
    es: 'Copiar API key',
    pt: 'Copiar API key',
  },
  'paymentIntegration.copySecretKey': {
    en: 'Copy Secret key',
    es: 'Copiar Secret key',
    pt: 'Copiar Secret key',
  },
  'paymentIntegration.addToShopify': {
    en: 'Shopify',
    es: 'Shopify',
    pt: 'Shopify',
  },
  'paymentIntegration.shopify.desc': {
    en: `Use ${brandName} to accept payments in your Shopify store. Just click the button below to add ${brandName} to your store and start accepting payments easily, quickly and safely!`,
    es: `Use ${brandName} para aceptar pagos en su tienda de Shopify. Simplemente haga click en el botón de abajo para agregar ${brandName} a su tienda y comenzar a aceptar pagos de forma sencilla, rápida y segura!`,
    pt: `Use ${brandName} para aceitar pagamentos em sua loja Shopify. Basta clicar no botão abaixo para adicionar ${brandName} à sua loja e começar a aceitar pagamentos de forma fácil, rápida e segura!`,
  },
  'paymentIntegration.shopify.desc.transparent': {
    es: 'Instala el checkout de dLocal Go en tu tienda Shopify y comienza a recibir pagos hoy mismo.',
    en: 'Install dLocal Go checkout in your Shopify store and start accepting payments today.',
    pt: 'Instale o checkout dLocal Go na sua loja Shopify e comece a receber pagamentos hoje.',
  },
  'paymentIntegration.shopify.desc.installed.transparent': {
    en: 'You already have dLocal Go installed on your Shopify store.<br/><br/>If you want to make a test payment before going live, enable test mode.',
    es: 'Ya tienes instalado dLocal Go en tu tienda Shopify.<br/><br/>Si quieres hacer un pago de prueba antes de ir a producción, activa el modo de prueba.',
    pt: 'Você já tem o dLocal Go instalado em sua loja da Shopify.<br/><br/>Se você quiser fazer um pagamento de teste antes de entrar no ar, habilite o modo de teste.',
  },
  'paymentIntegration.moreInfo.ourDocumentation.transparent': {
    en: 'Follow the installation steps',
    es: 'Sigue los pasos de instalación',
    pt: 'Siga as etapas de instalação',
  },
  'paymentIntegration.moreInfo.transparent': {
    en: ' and your site will be ready to sell safely.',
    es: ' y tu sitio ya estará listo para vender de forma segura.',
    pt: ' e seu site estará pronto para vender com segurança.',
  },
  'paymentIntegration.shopify.test.docs.text.installed.transparent': {
    en: 'Also make sure test mode is enabled in your Shopify dashboard by following',
    es: 'También asegúrate que el modo de prueba esté activado en tu panel de Shopify siguiendo',
    pt: 'Além disso, certifique-se de que o modo de teste esteja ativado em seu painel da Shopify seguindo',
  },
  'paymentIntegration.shopify.test.docs.installed.transparent': {
    en: 'these steps.',
    es: 'Estos pasos.',
    pt: 'estas etapas.',
  },
  'paymentIntegration.shopify.test.reminder.installed.transparent': {
    en: "After you check the test mode, don't forget to deactivate it to be able to go live.",
    es: 'Después de activar el modo de prueba, no olvides desactivarlo para poder ir a producción.',
    pt: 'Depois de verificar o modo de teste, não se esqueça de desativá-lo para poder processar pagamentos reais.',
  },
  'paymentIntegration.shopify.add': {
    en: `Install ${brandName} in Shopify`,
    es: `Instalar ${brandName} en Shopify`,
    pt: `Instale o ${brandName} no Shopify`,
  },
  'paymentIntegration.shopify.environment.testing': {
    en: 'Test mode',
    es: 'Modo de prueba',
    pt: 'Modo de teste',
  },
  'paymentIntegration.addToMagento': {
    en: 'Magento',
    es: 'Magento',
    pt: 'Magento',
  },
  'paymentIntegration.magento.desc': {
    en: `Install ${brandName} checkout in your Magento store and start receiving payments today.`,
    es: `Instala el checkout de ${brandName} en tu tienda Magento y comienza a recibir pagos hoy mismo.`,
    pt: `Instale o checkout ${brandName} em sua loja Magento e comece a receber pagamentos hoje.`,
  },
  'paymentIntegration.magento.link': {
    en: 'Follow the three installation steps',
    es: 'Sigue los tres pasos de instalación',
    pt: 'Siga as três etapas de instalação',
  },
  'paymentIntegration.magento.desc2': {
    en: ' and your site will be ready to sell safely.',
    es: ' y tu sitio ya estará listo para vender de forma segura.',
    pt: ' e seu site estará pronto para vender com segurança.',
  },
  'paymentIntegration.magento.add': {
    en: `Install ${brandName} in Magento`,
    es: `Instalar ${brandName} en Magento`,
    pt: `Instalar ${brandName} em Magento`,
  },
  'paymentIntegration.addToWooCommerce': {
    en: 'WooCommerce',
    es: 'WooCommerce',
    pt: 'WooCommerce',
  },
  'paymentIntegration.woocommerce.desc': {
    en: `Install ${brandName} checkout in your WooCommerce store and start receiving payments today.`,
    es: `Instala el checkout de ${brandName} en tu tienda WooCommerce y comienza a recibir pagos hoy mismo.`,
    pt: `Instale o checkout ${brandName} em sua loja WooCommerce e comece a receber pagamentos hoje.`,
  },
  'paymentIntegration.woocommerce.link': {
    en: 'Follow the three installation steps',
    es: 'Sigue los tres pasos de instalación',
    pt: 'Siga as três etapas de instalação',
  },
  'paymentIntegration.woocommerce.desc2': {
    en: ' and your site will be ready to sell safely.',
    es: ' y tu sitio ya estará listo para vender de forma segura.',
    pt: ' e seu site estará pronto para vender com segurança.',
  },
  'paymentIntegration.woocommerce.add': {
    en: `Install ${brandName} in WooCommerce`,
    es: `Instalar ${brandName} en WooCommerce`,
    pt: `Instalar ${brandName} em WooCommerce`,
  },
  'paymentIntegration.apiIntegration': {
    en: 'API integration',
    es: 'Integración por API',
    pt: 'Integração por API',
  },
  'paymentIntegration.apiIntegration.desc': {
    en: `If you have programming knowledge or access to a development team, you can integrate through our API REST. The integration through API offers greater flexibility so that you can integrate your system with ${brandName}, no matter what platform it is on or in what language it is implemented.`,
    es: `Si tiene conocimientos de programación o acceso a un equipo de desarrollo, puedes integrarte a través de nuestra API REST. La integración mediante API ofrece mayor flexibilidad para que puedas integrar tu sistema con ${brandName}, sin importar en qué plataforma se encuentre o en que lenguaje esté implementado.`,
    pt: `Se você tem conhecimento de programação ou acesso a uma equipe de desenvolvimento, pode integrar através de nossa API REST. A integração através de API oferece maior flexibilidade para que você possa integrar seu sistema com o ${brandName}, não importando em que plataforma esteja ou em que idioma esteja implementado.`,
  },
  'uploadFile.label': {
    en: 'UPLOAD FILE',
    es: 'SUBIR ARCHIVO',
    pt: 'SUBIR ARQUIVO',
  },
  'uploadFile.sizeExceeds': {
    en: 'The attached file size exceeds the maximum allowed.',
    es: 'El tamaño del archivo adjunto supera el máximo permitido.',
    pt: 'O tamanho do arquivo anexado excede o máximo permitido.',
  },
  'uploadFile.notSupported': {
    en: 'Format not supported.',
    es: 'Formato no soportado.',
    pt: 'Formato não suportado.',
  },
  'uploadFile.error': {
    en: 'Error loading the file. Try again.',
    es: 'Error al cargar el archivo. Inténtalo de nuevo.',
    pt: 'Erro ao carregar o arquivo. Tente novamente.',
  },
  'uploadFile.noFiles': {
    en: 'No files have been uploaded.',
    es: 'No se han cargado archivos.',
    pt: 'Nenhum arquivo foi carregado.',
  },
  'uploadFile.desc': {
    en: 'Maximum size {0}MB.<br/>Allowed extensions: <b>{1}</b>.',
    es: 'Tamaño máximo {0}MB.<br/>Extensiones permitidas: <b>{1}</b>.',
    pt: 'Tamanho máximo {0}MB.<br/>Extensões permitidas: <b>{1}</b>.',
  },
  'uploadFile.success': {
    en: 'The document was uploaded successfully.',
    es: 'El documento fue subido correctamente.',
    pt: 'O documento foi carregado com sucesso.',
  },
  'refunds.status.CREATED': {
    en: 'Created',
    es: 'Creado',
    pt: 'Criado',
  },
  'refunds.details': {
    en: 'Refunds detail',
    es: 'Detalle de reembolso',
    pt: 'Detalhe de reembolso',
  },
  'refunds.status.PENDING': {
    en: 'Pending',
    es: 'Pendiente',
    pt: 'Pendente',
  },
  'refunds.status.COMPLETED': {
    en: 'Completed',
    es: 'Completado',
    pt: 'Concluído',
  },
  'refunds.status.CANCELLED': {
    en: 'Cancelled',
    es: 'Cancelado',
    pt: 'Cancelado',
  },
  'refunds.status.DECLINED': {
    en: 'Declined',
    es: 'Declinado',
    pt: 'Recusado',
  },
  'refunds.status.REJECTED': {
    en: 'Rejected',
    es: 'Rechazado',
    pt: 'Rejeitado',
  },
  'refunds.noRefunds': {
    en: 'You have no refunds yet',
    es: 'No tienes ningún reembolso aún',
    pt: 'Você ainda não tem reembolsos',
  },
  'refunds.id': {
    en: 'ID',
    es: 'ID',
    pt: 'ID',
  },
  'refunds.createDate': {
    en: 'Creation date',
    es: 'Fecha de creación',
    pt: 'Data de criação',
  },
  'refunds.date': {
    en: 'Date',
    es: 'Fecha',
    pt: 'Data',
  },
  'refunds.time': {
    en: 'Time',
    es: 'Hora',
    pt: 'Hora',
  },
  'refunds.transactionId': {
    en: 'Transaction ID',
    es: 'ID de transacción',
    pt: 'ID de transação',
  },
  'refunds.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'refunds.paymentMethod': {
    en: 'Payment method',
    es: 'Método de pago',
    pt: 'Forma de pagamento',
  },
  'refunds.localAmount': {
    en: 'Local amount',
    es: 'Monto local',
    pt: 'Valor local',
  },
  'refunds.balanceAmount': {
    en: 'Balance',
    es: 'Balance',
    pt: 'Saldo',
  },
  'refunds.balanceFee': {
    en: 'Fee',
    es: 'Comisión',
    pt: 'Taxa',
  },
  'refunds.status': {
    en: 'Status',
    es: 'Estado',
    pt: 'Estado',
  },
  'refunds.filter.status': {
    en: 'Select state',
    es: 'Seleccione estado',
    pt: 'Selecione o estado',
  },
  'refunds.filter.country': {
    en: 'Select country',
    es: 'Seleccione país',
    pt: 'Selecione o pais',
  },
  'refunds.filter.paymentMethod': {
    en: 'Select payment method',
    es: 'Seleccione un método de pago',
    pt: 'Selecione uma forma de pagamento',
  },
  'refunds.filter.paymentMethod.desc': {
    en: 'First, you must filter by country.',
    es: 'Primero, debes filtrar por país.',
    pt: 'Primeiro, você deve filtrar por país.',
  },
  'addRefund.title': {
    en: 'Request refund',
    es: 'Solicitar reembolso',
    pt: 'Pedido de reembolso',
  },
  'addRefund.error.amountExceeds': {
    en: 'The entered value exceeds the transaction amount.',
    es: 'El valor ingresado supera el monto de la transacción.',
    pt: 'O valor inserido excede o valor da transação.',
  },
  'addRefund.desc': {
    en: 'Please complete the following information to create the refund request.',
    es: 'Por favor, complete la siguiente información para crear la solicitud de reembolso.',
    pt: 'Por favor, complete as seguintes informações para criar a solicitação de reembolso.',
  },
  'addRefund.timeToProcess': {
    en: 'Refunds for bank transfers and cash payments can take up to 7 business days to process.',
    es: 'Los reembolsos por transferencias bancarias y pagos en efectivo pueden demorar hasta 7 días hábiles en procesarse.',
    pt: 'Reembolsos para transferências bancárias e pagamentos em dinheiro podem levar até 7 dias úteis para serem processados.',
  },
  'addRefund.accountType': {
    en: 'Account type *',
    es: 'Tipo de cuenta *',
    pt: 'Tipo de conta *',
  },
  'addRefund.bankAccount.title': {
    en: 'Bank account',
    es: 'Cuenta bancaria',
    pt: 'Conta bancária',
  },
  'addRefund.amount': {
    en: 'Amount to refund *',
    es: 'Monto a reembolsar *',
    pt: 'Valor à reembolsar *',
  },
  'addRefund.beneficiary': {
    en: 'Name of the Beneficiary',
    es: 'Nombre del beneficiario',
    pt: 'Nome do beneficiário',
  },
  'addRefund.bankCode': {
    en: 'Bank *',
    es: 'Banco *',
    pt: 'Banco *',
  },
  'addRefund.bankAccount': {
    en: 'Bank account number *',
    es: 'Número de cuenta bancaria *',
    pt: 'Numero de conta bancária *',
  },
  'addRefund.bankAccount.message': {
    en: 'The owner of the account entered must coincide with the beneficiary.',
    es: 'El titular de la cuenta ingresada debe coincidir con el beneficiario.',
    pt: 'O titular da conta inserida deve coincidir com o beneficiário.',
  },
  'addRefund.bankBranch': {
    en: 'Branch *',
    es: 'Sucursal *',
    pt: 'Agência *',
  },
  'addRefund.btn': {
    en: 'Send request',
    es: 'Enviar solicitud',
    pt: 'Enviar solicitação',
  },
  'addRefund.success': {
    en: 'Successful refund request!',
    es: '¡Solicitud de reembolso exitosa!',
    pt: 'Solicitação de reembolso bem-sucedida!',
  },
  'addRefund.note': {
    en: 'Note: Refund fees apply.',
    es: 'Nota: Se aplican tarifas de reembolso.',
    pt: 'Nota: Taxas de reembolso aplicáveis.',
  },
  'addRefund.noteRequiredfields': {
    en: '(*) Required fields.',
    es: '(*) Campos obligatórios.',
    pt: '(*) Campos obrigatórios.',
  },
  'msgError.requestRefund.dataProvided': {
    en: 'It was not possible to create the refund with the data provided.',
    es: 'No fue posible crear el reembolso con los datos proporcionados.',
    pt: 'Não foi possível fazer o reembolso com os dados fornecidos.',
  },
  'msgError.requestRefund.amountEntered': {
    en: 'The amount entered exceeds the available balance in the selected transaction.',
    es: 'El monto ingresado excede el saldo disponible en la transacción seleccionada.',
    pt: 'O valor inserido excede o saldo disponível na transação selecionada.',
  },
  'msgError.requestRefund.insufficientBalance': {
    en: 'Insufficient available balance.',
    es: 'Saldo disponible insuficiente.',
    pt: 'Saldo disponível insuficiente.',
  },
  'msgError.requestRefund.amountLess': {
    en: 'The refund amount is less than the minimum allowed.',
    es: 'El monto del reembolso es menor que el mínimo permitido.',
    pt: 'O valor do reembolso é inferior ao mínimo permitido.',
  },
  'passwordReset.desc': {
    en: "Enter the email address associated with your account and we'll send you a link to reset your password.",
    es: 'Introduce la dirección de correo electrónico asociada a tu cuenta y te enviaremos un link para restablecer tu contraseña.',
    pt: 'Informe o e-mail associado à sua conta para receber um link de redefinição de senha.',
  },
  'passwordReset.back': {
    en: 'Return to sign in',
    es: 'Volver a inicio de sesión',
    pt: 'Voltar para o login',
  },
  'passwordReset.btn': {
    en: 'Continue',
    es: 'Continuar',
    pt: 'Continuar',
  },
  'passwordReset.sent.message': {
    en: 'Check to see if you have received an email with instructions to reset your password.',
    es: 'Comprueba si has recibido un correo electrónico con las instrucciones para restablecer la contraseña.',
    pt: 'Verifique se recebeu um e-mail com instruções para redefinir sua senha.',
  },
  'passwordReset.confirmDesc': {
    en: 'Reset your account password.',
    es: 'Restablece la contraseña de tu cuenta.',
    pt: 'Redefina a senha da sua conta.',
  },
  'msgError.passwordReset.waitToResend': {
    en: "Didn't you get the email? Look in your spam folder or wait a few minutes and try again.",
    es: '¿No recibiste el correo electrónico? Mira en tu carpeta de correo no deseado o espera unos minutos e intenta nuevamente.',
    pt: 'Você não recebeu o e-mail? Procure na sua pasta de spam ou espere alguns minutos e tente novamente.',
  },
  'msgError.passwordReset.invalidOrExpired': {
    en: 'Token is invalid or expired.',
    es: 'Token inválido o expirado.',
    pt: 'Token inválido ou expirado.',
  },
  'newPassword.label': {
    en: 'New Password',
    es: 'Nueva contraseña',
    pt: 'Nova senha',
  },
  'createPaymentBtn.desc': {
    en: 'Get paid using a payment button in your website in two simple steps:',
    es: 'Reciba pagos mediante un botón de pago en su sitio web en dos simples pasos:',
    pt: 'Receba pagamentos usando um botão de pagamento em seu site em duas etapas simples:',
  },
  'createPaymentBtn.desc.stepOne': {
    en: '1. Fill in the required information and click the <b>Create</b> button.',
    es: '1. Complete la información requerida y haga clic en el botón <b>Crear</b>.',
    pt: '1. Preencha as informações necessárias e clique no botão <b>Criar</b>.',
  },
  'createPaymentBtn.desc.stepTwo': {
    en: '2. Copy the generated code and paste it into the HTML of your page.',
    es: '2. Copie el código generado y péguelo en el HTML de su página.',
    pt: '2. Copie o código gerado e cole-o no HTML da sua página.',
  },
  'createPaymentBtn.amount': {
    en: 'Amount *',
    es: 'Monto *',
    pt: 'Valor *',
  },
  'createPaymentBtn.text': {
    en: 'Button text',
    es: 'Texto del botón',
    pt: 'Texto do botão',
  },
  'createPaymentBtn.text.placeholder': {
    en: 'Default text: Pay',
    es: 'Texto predeterminado: Pagar',
    pt: 'Texto padrão: Pagar',
  },
  'createPaymentBtn.currency': {
    en: 'Currency *',
    es: 'Moneda *',
    pt: 'Moeda *',
  },
  'createPaymentBtn.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'createPaymentBtn.langBtn': {
    en: 'Button text language',
    es: 'Idioma del texto del botón',
    pt: 'Idioma do texto do botão',
  },
  'createPaymentBtn.langBtn.auto': {
    en: 'Browser language browser',
    es: 'Idioma del navegador',
    pt: 'Idioma do navegador',
  },
  'createPaymentBtn.withBrand': {
    en: 'Marca',
    es: 'Brand',
    pt: 'Marca',
  },
  'createPaymentBtn.noteRequiredfields': {
    en: '(*) Required fields.',
    es: '(*) Campos obligatórios.',
    pt: '(*) Campos obrigatórios.',
  },
  'createPaymentBtn.btn': {
    en: 'Create',
    es: 'Crear',
    pt: 'Criar',
  },
  'createPaymentBtn.copyCode': {
    en: 'Copy code',
    es: 'Copiar código',
    pt: 'Copiar código',
  },
  'createPaymentBtn.forDevelopers': {
    en: 'Note: If you have programming knowledge you can also create our button manually, for more information visit',
    es: 'Nota: Si tienes conocimiento en programación también puedes crear nuestro botón de forma manual, para más información visita',
    pt: 'Nota: Se você tiver conhecimento de programação, você também pode criar nosso botão manualmente, para mais informações visite',
  },
  'createPaymentBtn.forDevelopers.clickHere': {
    en: 'our documentation.',
    es: 'nuestra documentación.',
    pt: 'nossa documentação.',
  },
  'personalInfo.title': {
    en: 'Personal information',
    es: 'Información personal',
    pt: 'Informação pessoal',
  },
  'personalInfo.name': {
    en: 'User name',
    es: 'Nombre de usuario',
    pt: 'Nome do usuário',
  },
  'personalInfo.email': {
    en: 'Email',
    es: 'Correo electrónico',
    pt: 'E-mail',
  },
  'personalInfo.language': {
    en: 'Language',
    es: 'Idioma',
    pt: 'Língua',
  },
  'personalInfo.marketingConsent': {
    en: 'Agree to receive news and promotions',
    es: 'Acepta recibir noticias y promociones',
    pt: 'Aceite receber novidades e promoções',
  },
  'personalInfo.cancel': {
    en: 'Cancel',
    es: 'Cancelar',
    pt: 'Cancelar',
  },
  'personalInfo.save': {
    en: 'Save',
    es: 'Guardar',
    pt: 'Salve',
  },
  'personalInfo.edit': {
    en: 'Edit',
    es: 'Editar',
    pt: 'Editar',
  },
  'profile.updateSuccess': {
    en: 'Data updated successfully!',
    es: '¡Datos actualizados con éxito!',
    pt: 'Dados atualizados com sucesso!',
  },
  'avatarOptions.profile': {
    en: 'Profile',
    es: 'Perfil',
    pt: 'Perfil',
  },
  'avatarOptions.logout': {
    en: 'Logout',
    es: 'Cerrar sesión',
    pt: 'Sair',
  },
  'avatarOptions.help': {
    en: 'Help',
    es: 'Ayuda',
    pt: 'Ajuda',
  },
  'avatarOptions.FAQ': {
    en: 'Help Center',
    es: 'Ayuda',
    pt: 'Ajuda',
  },
  'changePassword.title': {
    en: 'Security',
    es: 'Seguridad',
    pt: 'Segurança',
  },
  'changePassword.edit': {
    en: 'Change Password',
    es: 'Cambiar contraseña',
    pt: 'Mudar senha',
  },
  'changePassword.equalsError': {
    en: 'The password entered must be different from the current one.',
    es: 'La contraseña ingresada debe ser diferente a la actual',
    pt: 'A senha inserida deve ser diferente da atual.',
  },
  'changePassword.oldPassword': {
    en: 'Current password',
    es: 'Contraseña actual',
    pt: 'Senha atual',
  },
  'changePassword.newPassword': {
    en: 'New Password',
    es: 'Nueva contraseña',
    pt: 'Nova senha',
  },
  'changePassword.cancel': {
    en: 'Cancel',
    es: 'Cancelar',
    pt: 'Cancelar',
  },
  'changePassword.save': {
    en: 'Save',
    es: 'Guardar',
    pt: 'Salve',
  },
  'changePassword.updateSuccess': {
    en: 'Password updated successfully!',
    es: '¡Contraseña actualizada con éxito!',
    pt: 'Senha atualizada com sucesso!',
  },
  'msgError.changePassword': {
    en: 'The current password entered is incorrect.',
    es: 'La contraseña actual ingresada es incorrecta.',
    pt: 'A senha atual inserida está incorreta.',
  },
  'clients.details': {
    en: 'client detail',
    es: 'Detalle del cliente',
    pt: 'Detalhe do cliente',
  },
  'clients.noClients': {
    en: 'You have no clients yet',
    es: 'No tienes clientes aún',
    pt: 'Você ainda não tem clientes',
  },
  'clients.merchantClientId': {
    en: 'ID',
    es: 'ID',
    pt: 'ID',
  },
  'clients.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'clients.firstName': {
    en: 'First name',
    es: 'Nombre',
    pt: 'Nome',
  },
  'clients.lastName': {
    en: 'Last name',
    es: 'Apellido',
    pt: 'Sobrenome',
  },
  'clients.fullName': {
    en: 'Full name',
    es: 'Nombre completo',
    pt: 'Nome Completo',
  },
  'clients.email': {
    en: 'Client e-mail',
    es: 'Email del cliente',
    pt: 'Email do cliente',
  },
  'clients.documentType': {
    en: 'Document type',
    es: 'Tipo de documento',
    pt: 'Tipo de documento',
  },
  'clients.document': {
    en: 'Document',
    es: 'Documento',
    pt: 'Documento',
  },
  'bankAccounts.backBtn': {
    en: 'Back to my bank accounts',
    es: 'Volver a mis cuentas bancarias',
    pt: 'Voltar para minhas contas bancárias',
  },
  'bankAccounts.subheadline': {
    en: 'Review and manage the bank accounts where you want to receive the money from your sales.',
    es: 'Revisa y gestiona las cuentas bancarias donde quieres recibir el dinero de tus ventas.',
    pt: 'Revise e gerencie as contas bancárias onde deseja receber o dinheiro de suas vendas.',
  },
  'bankAccounts.details': {
    en: 'Bank account detail',
    es: 'Detalle de cuenta bancaria',
    pt: 'Detalhe da conta bancária',
  },
  'bankAccounts.empty.desc1': {
    en: 'To withdraw money from your sales, fill in your bank account details in the ',
    es: 'Para retirar el dinero de tus ventas, completa los datos de tu cuenta bancaria en la sección ',
    pt: 'Para retirar o dinheiro de suas vendas, preencha os dados de sua conta bancária na seção ',
  },
  'bankAccounts.empty.link': {
    en: 'Company data',
    es: 'Datos de su negocio',
    pt: 'Dados da empresa',
  },
  'bankAccounts.empty.desc2': {
    en: ' section.',
    es: ' .',
    pt: ' .',
  },
  'bankAccounts.callToAction': {
    en: '+ ADD A BANK ACCOUNT',
    es: '+ AGREGAR UNA CUENTA BANCARIA',
    pt: '+ ADICIONE UMA CONTA BANCÁRIA',
  },
  'bankAccounts.callToAction.tooltip.disabled': {
    en: 'You exceeded the maximum number of bank accounts allowed. To add a new one, please delete an existing account.',
    es: 'Superaste el número máximo de cuentas bancarias permitidas. Para agregar una nueva, elimina una cuenta existente.',
    pt: 'Você excedeu o número máximo de contas bancárias permitidas. Para adicionar uma nova, exclua uma conta existente.',
  },
  'bankAccounts.status.APPROVED': {
    en: 'Active',
    es: 'Activo',
    pt: 'Ativo',
  },
  'bankAccounts.status.IN_REVIEW': {
    en: 'In review',
    es: 'En revisión',
    pt: 'Em revisão',
  },
  'bankAccounts.status.REJECTED': {
    en: 'Rejected',
    es: 'Rechazado',
    pt: 'Rejeitado',
  },
  'bankAccounts.status.tooltip.APPROVED': {
    en: "We've verified your bank account information. You can receive the money from your sales here.",
    es: 'Ya verificamos la información de tu cuenta bancaria. Puedes recibir el dinero de tus ventas aquí.',
    pt: 'Verificamos as informações da sua conta bancária. Você pode receber o dinheiro de suas vendas aqui.',
  },
  'bankAccounts.status.tooltip.IN_REVIEW': {
    en: "We're verifying your bank account information.We'll let you know when we're done.",
    es: 'Estamos verificando la información de tu cuenta bancaria. Te avisaremos cuando hayamos finalizado.',
    pt: 'Estamos verificando as informações da sua conta bancária. Avisaremos quando terminarmos.',
  },
  'bankAccounts.status.tooltip.REJECTED': {
    en: "We couldn't approve your bank account. Please delete this account and add a new one.",
    es: 'No pudimos aprobar tu cuenta bancaria. Elimina esta cuenta y agrega una nueva.',
    pt: 'Não foi possível aprovar sua conta bancária. Exclua esta conta e adicione uma nova.',
  },
  'bankAccounts.status.tooltip.starred': {
    en: 'This is the bank account you submitted in step 2 of your account setup.',
    es: 'Esta es la cuenta bancaria que cargaste en el paso 2 de la configuración de tu cuenta.',
    pt: 'Esta é a conta bancária que você enviou no passo 2 da configuração de sua conta.',
  },
  'bankAccounts.number': {
    en: 'Bank account number: Ending in ',
    es: 'Número de cuenta bancaria: termina en ',
    pt: 'Número da conta bancária: terminado em ',
  },
  'bankAccounts.type.CHECKING': {
    en: 'Checking account',
    es: 'Cuenta corriente',
    pt: 'Conta corrente',
  },
  'bankAccounts.type.SAVING': {
    en: 'Savings account',
    es: 'Cuenta de ahorro',
    pt: 'Conta poupança ',
  },
  'bankAccounts.type.SALARY': {
    en: 'Salary account',
    es: 'Cuenta de salario',
    pt: 'Conta salário',
  },
  'bankAccounts.type.VISTA': {
    en: 'Vista',
    es: 'Vista',
    pt: 'Vista',
  },
  'bankAccounts.type.MASTER': {
    en: 'Master',
    es: 'Master',
    pt: 'Master',
  },
  'bankAccounts.starred': {
    en: 'Starred',
    es: 'Favorito',
    pt: 'Preferido',
  },
  'bankAccounts.noAccounts': {
    en: 'To withdraw money from your sales, you must fill in your bank account details in the ',
    es: 'Para retirar dinero de tus ventas, debes completar los datos de tu cuenta bancaria en la sección ',
    pt: 'Para retirar o dinheiro da suas vendas, você deve (preencher/completar) os dados de sua conta bancária na seção ',
  },
  'bankAccounts.noAccounts.link': {
    en: 'Company data',
    es: 'Datos de su negocio',
    pt: 'Dados da empresa',
  },
  'bankAccounts.noAccounts2': {
    en: ' section.',
    es: '.',
    pt: '.',
  },
  'bankAccounts.delete': {
    en: 'DELETE BANK ACCOUNT',
    es: 'ELIMINAR CUENTA BANCARIA',
    pt: 'EXCLUIR CONTA BANCÁRIA',
  },
  'bankAccounts.delete.confirm.headline': {
    en: 'Are you sure you want to delete this bank account?',
    es: '¿Estás seguro de que quieres eliminar esta cuenta bancaria?',
    pt: 'Tem certeza de que deseja excluir esta conta bancária?',
  },
  'bankAccounts.delete.confirm.subheadline': {
    en: 'You will no longer have this account to withdraw the money from your sales.',
    es: 'Ya no tendrás disponible esta cuenta para retirar el dinero de tus ventas.',
    pt: 'Você não terá mais esta conta para sacar o dinheiro de suas vendas.',
  },
  'bankAccounts.delete.confirm.cancel.button': {
    en: 'CANCEL',
    es: 'CANCELAR',
    pt: 'CANCELAR',
  },
  'bankAccounts.delete.confirm.confirmed.button': {
    en: 'CONTINUE',
    es: 'CONTINUAR',
    pt: 'CONTINUAR',
  },
  'bankAccounts.delete.error.headline': {
    en: "We couldn't delete your bank account",
    es: 'No se pudo eliminar tu cuenta bancaria',
    pt: 'Não foi possível excluir sua conta bancária',
  },
  'bankAccounts.delete.error.subheadline': {
    en: 'An error occurred during the process. Please try deleting your account later.',
    es: 'Ocurrió un error en el proceso. Por favor, intenta eliminar tu cuenta más tarde.',
    pt: 'Ocorreu um erro durante o processo. Por favor, tente excluir sua conta mais tarde.',
  },
  'bankAccounts.delete.error.718.headline': {
    en: "At the moment, you can't delete this bank account",
    es: 'Por el momento, no puedes eliminar esta cuenta bancaria',
    pt: 'No momento, você não pode excluir esta conta bancária',
  },
  'bankAccounts.delete.error.718.subheadline': {
    en: "You have a pending withdrawal request. Once the withdrawal is completed, you'll be able to delete this account.",
    es: 'Tienes un retiro de dinero en proceso. Una vez que el retiro se complete, podrás eliminar esta cuenta.',
    pt: 'Você tem uma solicitação de saque pendente. Assim que o saque for concluído, você poderá excluir esta conta.',
  },
  'bankAccounts.new.sucess.headline': {
    en: "We're verifying your bank account information",
    es: 'Estamos verificando la información de tu cuenta bancaria',
    pt: 'Estamos verificando as informações da sua conta bancária',
  },
  'bankAccounts.new.sucess.subheadline': {
    en: "Approval is pending until we're done with the review.This will take us 1 business day.",
    es: 'La aprobación está pendiente hasta que terminemos con la revisión. Esto nos llevará 1 día hábil.',
    pt: 'A aprovação está pendente até terminarmos a revisão. Isso nos levará 1 dia útil.',
  },
  'bankAccounts.new.sucess.button': {
    en: 'CONTINUE',
    es: 'CONTINUAR',
    pt: 'CONTINUAR',
  },
  'bankAccounts.________': {
    en: '',
    es: '',
    pt: '',
  },
  'bankAccounts.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'bankAccounts.name': {
    en: 'Name',
    es: 'Nombre',
    pt: 'Nome',
  },
  'bankAccounts.documentType': {
    en: 'Document type',
    es: 'Tipo de documento',
    pt: 'Tipo de documento',
  },
  'bankAccounts.document': {
    en: 'Document',
    es: 'Documento',
    pt: 'Documento',
  },
  'bankAccounts.bankName': {
    en: 'Bank',
    es: 'Banco',
    pt: 'Banco',
  },
  'bankAccounts.bankAccount': {
    en: 'Bank account number',
    es: 'Número de cuenta bancaria',
    pt: 'Numero de conta bancária',
  },
  'bankAccounts.accountType': {
    en: 'Account type',
    es: 'Tipo de cuenta',
    pt: 'Tipo de conta',
  },
  'bankAccounts.bankBranch': {
    en: 'Branch',
    es: 'Sucursal',
    pt: 'Agência',
  },
  'bankAccounts.phone': {
    en: 'Phone',
    es: 'Teléfono',
    pt: 'Telefone',
  },
  'bankAccounts.address': {
    en: 'Address',
    es: 'Dirección',
    pt: 'Endereço',
  },
  'bankAccounts.city': {
    en: 'City',
    es: 'Ciudad',
    pt: 'Cidade',
  },
  'bankAccounts.IBAN': {
    en: 'IBAN',
    es: 'IBAN',
    pt: 'IBAN',
  },
  'bankAccounts.addBankAccount': {
    en: 'ADD BANK ACCOUNT',
    es: 'AGREGAR CUENTA BANCARIA',
    pt: 'ADICIONAR CONTA BANCÁRIA',
  },
  'filter.btn': {
    en: 'Filter {0}',
    es: 'Filtrar {0}',
    pt: 'Filtrar {0}',
  },
  'filter.title': {
    en: 'Filter by:',
    es: 'Filtrar por:',
    pt: 'Filtrar por:',
  },
  'filter.cleanFilter': {
    en: 'Clean filters',
    es: 'Limpiar filtros',
    pt: 'Filtros limpos',
  },
  'datepicker.from': {
    en: 'From',
    es: 'Desde',
    pt: 'A partir de',
  },
  'datepicker.to': {
    en: 'To',
    es: 'Hasta',
    pt: 'Até',
  },
  'datepicker.placeholder': {
    en: 'dd/mm/yyyy',
    es: 'dd/mm/aaaa',
    pt: 'dd/mm/aaaa',
  },
  'selectDateRange.today': {
    en: 'Today',
    es: 'Hoy',
    pt: 'Hoje',
  },
  'selectDateRange.lastWeek': {
    en: 'Last week',
    es: 'Semana pasada',
    pt: 'Semana Anterior',
  },
  'selectDateRange.lastMonth': {
    en: 'Last month',
    es: 'Mes pasado',
    pt: 'Mês passado',
  },
  'selectDateRange.specificDateRange': {
    en: 'Specific date range',
    es: 'Intervalo de fechas específico',
    pt: 'Intervalo de datas específico',
  },
  'linkDocs.levels': {
    en: 'What documents must I present',
    es: 'Qué documentos debo presentar',
    pt: 'Que documentos devo apresentar',
  },
  'linkDocs.integrations': {
    en: `How to integrate my website to ${brandName}?`,
    es: `¿Cómo integrar mi sitio web a ${brandName}?`,
    pt: `Como faço para integrar meu site ao ${brandName}?`,
  },
  'linkDocs.paymentLink': {
    en: 'How to charge with a payment link?',
    es: '¿Cómo cobrar con link de pago?',
    pt: 'Como cobrar com link de pagamento?',
  },
  localCurrencyWarning: {
    en: 'Your client will always pay in their local currency.',
    es: 'Tu cliente pagará siempre en su moneda local.',
    pt: 'Seu cliente sempre pagará na moeda local dele.',
  },
  localCurrencyInfo: {
    en: "Submit the amount in USD, your client will pay in their local currency and you'll receive it in {0}.",
    es: 'Ingresa el monto en USD, tu cliente pagará en moneda local, y tu recibirás en {0}.',
    pt: 'Insira o valor em USD, seu cliente pagará na moeda local dele, e você receberá {0}.',
  },
  'reports.headline': {
    en: 'Reports',
    es: 'Reportes',
    pt: 'Relatórios',
  },
  'reports.subheadline': {
    en: 'Your business metrics',
    es: 'Tus métricas de negocio',
    pt: 'Suas métricas de negócios',
  },
  'reports.description': {
    en: 'Track your latest sales and compare the most important indicators.',
    es: 'Realiza un seguimiento de tus últimas ventas y compara los indicadores más importantes.',
    pt: 'Acompanhe suas últimas vendas e compare os indicadores mais importantes.',
  },
  'reports.tag': {
    en: 'Last {0} days',
    es: 'Últimos {0} días',
    pt: 'Últimos {0} dias',
  },
  'reports.card1.headline': {
    en: 'Payment volume',
    es: 'Volumen de ventas',
    pt: 'Volume de vendas',
  },
  'reports.card1.tooltip': {
    en: 'Amount of money you received from your clients in the last 7 days.',
    es: 'Cantidad de dinero que recibiste de tus clientes en los últimos 7 días.',
    pt: 'Quantidade de dinheiro que você recebeu de seus clientes nos últimos 7 dias.',
  },
  'reports.card1.description': {
    en: '・Last week it was {0} {1}',
    es: '・La semana pasada fue {0} {1}',
    pt: '・Semana passada foi {0} {1}',
  },
  'reports.card2.headline': {
    en: 'Number of transactions',
    es: 'Número de transacciones',
    pt: 'Número de transações',
  },
  'reports.card2.tooltip': {
    en: 'Number of approved payments you had in the last 7 days.',
    es: 'Número de pagos aprobados que tuviste en los últimos 7 días.',
    pt: 'Número de pagamentos aprovados que você teve nos últimos 7 dias.',
  },
  'reports.card2.description': {
    en: '・Last week it was {0}',
    es: '・La semana pasada fue {0}',
    pt: '・Semana passada foi {0}',
  },
  'reports.card3.headline': {
    en: 'Average ticket',
    es: 'Ticket promedio',
    pt: 'Ticket médio',
  },
  'reports.card3.tooltip': {
    en: 'The average ticket is the result of your payment volume divided by your number of transactions in the last 7 days.',
    es: 'El ticket promedio es el resultado del volumen de ventas dividido por el número de transacciones en los últimos 7 días.',
    pt: 'O ticket médio é o resultado do volume de vendas dividido pelo número de transações nos últimos 7 dias.',
  },
  'reports.card3.description': {
    en: '・Last week it was {0} {1}',
    es: '・La semana pasada fue {0} {1}',
    pt: '・Semana passada foi {0} {1}',
  },
  'reports.error.message': {
    en: 'Your business information couldn’t be loaded.<br />Please reload the page.',
    es: 'No se pudo cargar la información de tu negocio.<br />Por favor, actualiza la página.',
    pt: 'Não foi possível carregar as informações da sua empresa.<br />Por favor, atualize a página.',
  },
  'reports.error.button': {
    en: 'Reload',
    es: 'Actualizar',
    pt: 'Atualizar',
  },
  'reports.paymentVolume.title': {
    en: 'Payment volume',
    es: 'Volumen de ventas',
    pt: 'Volume de vendas',
  },
  'reports.paymentVolume.tooltip': {
    en: 'Amount of money you received from your clients in the last 7 days.',
    es: 'Cantidad de dinero que recibiste de tus clientes en los últimos 7 días.',
    pt: 'Quantidade de dinheiro que você recebeu de seus clientes nos últimos 7 dias.',
  },
  'reports.transactionCount': {
    en: 'transaction',
    es: 'transacción',
    pt: 'transação',
  },
  'reports.transactionsCount': {
    en: 'transactions',
    es: 'transacciones',
    pt: 'transações',
  },
  'reports.transactionsByCountry.title': {
    en: 'Transactions by country',
    es: 'Transacciones por país',
    pt: 'Transações por país',
  },
  'reports.transactionsByCountry.tooltip': {
    en: 'Your percentage of sales distributed by country.',
    es: 'Tu porcentaje de ventas distribuido por país.',
    pt: 'Sua porcentagem de vendas distribuídas por país.',
  },
  'reports.transactionsBy.empty': {
    en: 'No transactions',
    es: 'Sin transacciones',
    pt: 'Sem transações',
  },
  'reports.transactionsByPaymentSolution.title': {
    en: 'Transactions by payment solution',
    es: 'Transacciones por solución de cobro',
    pt: 'Transações por solução de pagamento',
  },
  'reports.transactionsByPaymentSolution.tooltip': {
    en: 'Your percentage of sales distributed by payment solution.',
    es: 'Tu porcentaje de ventas distribuido por solución de cobro.',
    pt: 'Sua porcentagem de vendas distribuída por solução de pagamento.',
  },
  'reports.transactionsByPaymentSolution.type.SHOPIFY': {
    en: 'Shopify',
    es: 'Shopify',
    pt: 'Shopify',
  },
  'reports.transactionsByPaymentSolution.type.PAYMENT_LINK': {
    en: 'Payment link',
    es: 'Link de pago',
    pt: 'Link de pagamento',
  },
  'reports.transactionsByPaymentSolution.type.PAYMENT_BUTTON': {
    en: 'Payment button',
    es: 'Botón de pago',
    pt: 'Botão de pagamento',
  },
  'reports.transactionsByPaymentSolution.type.VIRTUAL_POS': {
    en: 'Virtual POS',
    es: 'POS Virtual',
    pt: 'POS Virtual',
  },
  'reports.transactionsByPaymentSolution.type.SUBSCRIPTION': {
    en: 'Subscription',
    es: 'Suscripción',
    pt: 'Assinatura',
  },
  'reports.transactionsByPaymentSolution.type.OTHERS': {
    en: 'Others',
    es: 'Otros',
    pt: 'Outros',
  },
  'reports.transactionsByPaymentMethod.title': {
    en: 'Transactions by payment method',
    es: 'Transacciones por método de pago',
    pt: 'Transações por forma de pagamento',
  },
  'reports.transactionsByPaymentMethod.tooltip': {
    en: 'Your percentage of sales distributed by payment method.',
    es: 'Tu porcentaje de ventas distribuido por método de pago.',
    pt: 'Sua porcentagem de vendas distribuída por forma de pagamento.',
  },
  'kyc_step1.message.completed.headline': {
    en: 'Congratulations!<br />You completed step 1',
    es: '¡Felicitaciones!<br />Completaste el paso 1',
    pt: 'Parabéns!<br />Você concluiu o passo 1',
  },
  'kyc_step1.message.completed.subheadline': {
    en: 'Now you can start selling how you want, where you want.',
    es: 'Ahora puedes empezar a vender como quieras, donde quieras.',
    pt: 'Agora, você pode começar a vender como e onde quiser.',
  },
  'kyc_step1.message.completed.button': {
    en: 'CONTINUE',
    es: 'CONTINUAR',
    pt: 'CONTINUAR',
  },
  'kyc_step1.message.warning.headline': {
    en: "We couldn't verify your identity",
    es: 'No pudimos verificar tu identidad',
    pt: 'Não foi possível verificar sua identidade',
  },
  'kyc_step1.message.warning.subheadline': {
    en: 'Please complete the step 1 of your account setup again.',
    es: 'Por favor, vuelve a completar el paso 1 de configuración de tu cuenta.',
    pt: 'Conclua o passo 1 da configuração da sua conta novamente.',
  },
  'kyc_step1.message.warning.button': {
    en: 'CONTINUE',
    es: 'CONTINUAR',
    pt: 'CONTINUAR',
  },
  'companyInformation.steps.tag.retryValidation': {
    en: 'Pending',
    es: 'Pendiente',
    pt: 'Pendiente',
  },
  'companyInformation.step1.headline.retryValidation': {
    en: 'Re-verify your identity',
    es: 'Vuelve a verificar tu identidad',
    pt: 'Verifique novamente sua identidade',
  },
  'companyInformation.step1.subheadline.retryValidation': {
    en: 'You will be asked to submit an image of your ID document, which will be checked to ensure it is legitimate',
    es: 'Se le pedirá que envíe una imagen de su documento de identidad, que se verificará para asegurarnos de que es legítima y no se ha alterado ni manipulado.',
    pt: 'Ser-lhe-á solicitado, preencher uma imagem do seu documento de identificação, que será verificado para garantir que é legítimo e não foi alterado ou manipulado.',
  },
  'companyInformation.step1.content.retryValidation': {
    en: 'This step will enable you to receive payments.',
    es: 'Este paso te habilitará a recibir pagos.',
    pt: 'Este passo te habilitará a receber pagamentos.',
  },
  'companyInformation.step1.button.retryValidation': {
    en: 'COMPLETE VERIFICATION',
    es: 'COMPLETAR VERIFICACIÓN',
    pt: 'FINALIZAR VERIFICAÇÃO',
  },
  'companyInformation.steps.tag.missingValidation': {
    en: 'Pending',
    es: 'Pendiente',
    pt: 'Pendiente',
  },
  'companyInformation.step1.headline.missingValidation': {
    en: 'Verify your identity',
    es: 'Verifica tu identidad',
    pt: 'Verifique sua identidade',
  },
  'companyInformation.step1.subheadline.missingValidation': {
    en: 'You will be asked to submit an image of your ID document, which will be checked to ensure it is legitimate',
    es: 'Se le pedirá que envíe una imagen de su documento de identidad, que se verificará para asegurarnos de que es legítima y no se ha alterado ni manipulado.',
    pt: 'Ser-lhe-á solicitado, preencher uma imagem do seu documento de identificação, que será verificado para garantir que é legítimo e não foi alterado ou manipulado.',
  },
  'companyInformation.step1.content.missingValidation': {
    en: 'This step will enable you to receive payments.',
    es: 'Este paso te habilitará a recibir pagos.',
    pt: 'Este passo te habilitará a receber pagamentos.',
  },
  'companyInformation.step1.button.missingValidation': {
    en: 'COMPLETE VERIFICATION',
    es: 'COMPLETAR VERIFICACIÓN',
    pt: 'FINALIZAR VERIFICAÇÃO',
  },
  'companyInformation.steps.tag.errorValidation': {
    en: 'Pending',
    es: 'Pendiente',
    pt: 'Pendiente',
  },
  'companyInformation.step1.headline.errorValidation': {
    en: 'Contact us to verify your identity',
    es: 'Contáctanos para verificar tu identidad',
    pt: 'Entre em contato conosco para verificar a sua identidade',
  },
  'companyInformation.step1.subheadline.errorValidation': {
    en: 'There was a problem verifying your data. Contact us so we can help you.',
    es: 'Hubo un problema en el proceso de verificación de tus datos. Escríbenos para que podamos ayudarte.',
    pt: 'Ocorreu um problema ao verificar seus dados. Escreva-nos para que possamos ajudá-lo.',
  },
  'companyInformation.step1.link.errorValidation': {
    en: 'Contact support',
    es: 'Contactar a soporte',
    pt: 'Contatar suporte',
  },
  'companyInformation.message.confirm.leave.headline': {
    en: 'Do you want to leave the setup process?',
    es: '¿Aceptas salir del proceso?',
    pt: 'Deseja sair do processo de configuração?',
  },
  'companyInformation.message.confirm.leave.subheadline': {
    en: 'This step is necessary for us to verify your identity and allow you to receive payments.',
    es: 'Este paso es necesario para que podamos verificar tu identidad y permitirte recibir pagos.',
    pt: 'Este passo é necessário para verificarmos sua identidade e permitir que você receba pagamentos.',
  },
  'companyInformation.message.confirm.leave.cancel.button': {
    en: 'STAY',
    es: 'CANCELAR',
    pt: 'FICAR',
  },
  'companyInformation.message.confirm.leave.confirmed.button': {
    en: 'LEAVE',
    es: 'ACEPTAR',
    pt: 'SAIR',
  },
  'expirationBox.headline': {
    en: 'Set expiration date',
    es: 'Establecer fecha de expiración',
    pt: 'Definir data de expiração',
  },
  'expirationBox.headline.optional': {
    en: '(Optional)',
    es: '(Opcional)',
    pt: '(Opcional)',
  },
  'expirationBox.subheadline': {
    en: 'Disable this link on a specific date. By default, it will expire in 15 days.',
    es: 'Deshabilita este link en una fecha específica. Por defecto, expirará en 15 días.',
    pt: 'Desative este link numa data específica. Por padrão, expirará em 15 dias.',
  },
  'expirationBox.expirationDate': {
    en: 'Expiration date',
    es: 'Fecha de expiración',
    pt: 'Data de expiração',
  },
  'expirationBox.minimumValue': {
    en: 'The minimum expiration time is 5 minutes',
    es: 'El tiempo mínimo de expiración son 5 minutos',
    pt: 'O tempo mínimo de expiração é de 5 minutos',
  },
  'expirationBox.DAYS': {
    en: 'Days',
    es: 'Días',
    pt: 'Dias',
  },
  'expirationBox.HOURS': {
    en: 'Hours',
    es: 'Horas',
    pt: 'Horas',
  },
  'expirationBox.MINUTES': {
    en: 'Minutes',
    es: 'Minutos',
    pt: 'Minutos',
  },
  'expirationBox.MONTHS': {
    en: 'Month',
    es: 'Mes',
    pt: 'Mês',
  },
  'expirationBox.YEARS': {
    en: 'Year',
    es: 'Año',
    pt: 'Ano',
  },
  'subscriptions.subheadline': {
    en: 'Find all your subscribers, the plans you created, and their status.',
    es: 'Encuentra todos los suscriptores que tienes, los planes que creaste y su estado.',
    pt: 'Encontre todos os seus assinantes, os planos que você criou e o status deles.',
  },
  'subscriptions.createPlan': {
    en: 'Create a plan',
    es: 'Crear un plan',
    pt: 'Criar um plano',
  },
  'subscriptions.subscriptors': {
    en: 'Subscribers',
    es: 'Suscriptores',
    pt: 'Assinantes',
  },
  'subscriptions.plans': {
    en: 'Plans',
    es: 'Planes',
    pt: 'Planos',
  },
  'subscriptions.startDate': {
    en: 'Subscription start date',
    es: 'Inicio de suscripción',
    pt: 'Início da assinatura',
  },
  'subscriptions.clientName': {
    en: 'Client name',
    es: 'Nombre del cliente',
    pt: 'Nome do cliente',
  },
  'subscriptions.planName': {
    en: 'Plan name',
    es: 'Nombre del plan',
    pt: 'Nome do plano',
  },
  'subscriptions.country': {
    en: 'Country',
    es: 'País',
    pt: 'País',
  },
  'subscriptions.allCountries': {
    en: 'All countries',
    es: 'Todos los países',
    pt: 'Todos os países',
  },
  'subscriptions.amountToCharge': {
    en: 'Amount to charge',
    es: 'Monto a cobrar',
    pt: 'Valor a cobrar',
  },
  'subscriptions.status': {
    en: 'Subscription status',
    es: 'Estado de la suscripción',
    pt: 'Status da assinatura',
  },
  'subscriptions.lastPaymentStatus': {
    en: 'Last payment status',
    es: 'Estado de último pago',
    pt: 'Estado do último pagamento',
  },
  'subscriptions.executionStatus.PENDING': {
    en: 'Pending',
    es: 'Pendiente',
    pt: 'Pendente',
  },
  'subscriptions.executionStatus.COMPLETED': {
    en: 'Completed',
    es: 'Completada',
    pt: 'Concluído',
  },
  'subscriptions.executionStatus.DECLINED': {
    en: 'Declined',
    es: 'Rechazada',
    pt: 'Rejeitado',
  },
  'subscriptions.active': {
    en: 'Active',
    es: 'Activa',
    pt: 'Ativa',
  },
  'subscriptions.inactive': {
    en: 'Inactive',
    es: 'Inactiva',
    pt: 'Inativa',
  },
  'subscriptions.seeDetails': {
    en: 'See details',
    es: 'Ver detalles',
    pt: 'Ver detalhes',
  },
  'subscriptions.seeEdit': {
    en: 'Edit plan',
    es: 'Editar plan',
    pt: 'Editar plano',
  },
  'subscriptions.emptySubscriptions.title': {
    en: 'You don´t have subscribers yet',
    es: 'Aún no tienes suscriptores',
    pt: 'Você ainda não tem assinantes',
  },
  'subscriptions.emptySubscriptions.body': {
    en: 'Create or share an existing plan and collect payments easily.',
    es: 'Crea o comparte un plan existente y recibe pagos fácilmente.',
    pt: 'Crie ou compartilhe um plano existente e receba pagamentos facilmente.',
  },
  'subscriptions.details': {
    en: 'Subscriptions details',
    es: 'Detalles de las suscripciones',
    pt: 'Detalhes das assinaturas',
  },
  'subscriptions.details.subheadline': {
    en: 'Subscriptions details',
    es: 'Detalles de las suscripciones',
    pt: 'Detalhes das assinaturas',
  },
  'subscriptions.details.backBtn': {
    en: 'Back to your subscriptions',
    es: 'Volver a tus suscripciones',
    pt: 'Voltar para suas assinaturas',
  },
  'subscriptions.details.cancel': {
    en: 'Cancel subscription',
    es: 'Cancelar suscripción',
    pt: 'Cancelar assinatura',
  },
  'subscriptions.details.cancelPlan': {
    en: 'Cancel plan',
    es: 'Cancelar plan',
    pt: 'Cancelar plano',
  },
  'subscriptions.details.activePlan': {
    en: 'Active plan',
    es: 'Plan activo',
    pt: 'Plano ativo',
  },
  'subscriptions.details.inactivePlan': {
    en: 'Inactive plan',
    es: 'Plan inactivo',
    pt: 'Plano inativo',
  },
  'subscriptions.subscriptionDate': {
    en: 'Subscription date',
    es: 'Fecha de la suscripción',
    pt: 'Data de assinatura',
  },
  'subscriptions.paymentFrequency': {
    en: 'Payment frequency',
    es: 'Frecuencia de cobro',
    pt: 'Frequência de pagamento',
  },
  'subscriptions.frequency.DAILY': {
    en: 'Day',
    es: 'Día',
    pt: 'Dia',
  },
  'subscriptions.frequency.DAILY.plural': {
    en: 'Days',
    es: 'Días',
    pt: 'Dias',
  },
  'subscriptions.frequency.WEEKLY': {
    en: 'Week',
    es: 'Semana',
    pt: 'Semana',
  },
  'subscriptions.frequency.WEEKLY.plural': {
    en: 'Weeks',
    es: 'Semanas',
    pt: 'Semanas',
  },
  'subscriptions.frequency.MONTHLY': {
    en: 'Month',
    es: 'Mes',
    pt: 'Mes',
  },
  'subscriptions.frequency.MONTHLY.plural': {
    en: 'Months',
    es: 'Meses',
    pt: 'Meses',
  },
  'subscriptions.frequency.YEARLY': {
    en: 'Year',
    es: 'Año',
    pt: 'Ano',
  },
  'subscriptions.frequency.YEARLY.plural': {
    en: 'Years',
    es: 'Años',
    pt: 'Anos',
  },
  'subscriptions.frequency.QUARTERLY': {
    en: 'Quarterly',
    es: 'Trimestral',
    pt: 'Trimestral',
  },
  'subscriptions.frequency.SEMI_ANNUALLY': {
    en: 'Semi-annual',
    es: 'Semestral',
    pt: 'Semestral',
  },
  'subscriptions.frequency.ANNUALLY': {
    en: 'Yearly',
    es: 'Anual',
    pt: 'Anual',
  },
  'subscriptions.frequency.CUSTOM': {
    en: 'Custom',
    es: 'Perzonalizado',
    pt: 'Personalizado',
  },
  'subscriptions.customFrequency.WEEKLY': {
    en: 'Weekly',
    es: 'Semanal',
    pt: 'Semanal',
  },
  'subscriptions.customFrequency.MONTHLY': {
    en: 'Monthly',
    es: 'Mensual',
    pt: 'Mensal',
  },
  'subscriptions.subscribersCountry': {
    en: "Subscribers' country",
    es: 'País de los suscriptores',
    pt: 'País dos assinantes',
  },
  'subscriptions.paymentMethod': {
    en: 'Payment method',
    es: 'Método de pago',
    pt: 'Método de pagamento',
  },
  'subscriptions.clientInformation': {
    en: 'Client information',
    es: 'Información del cliente',
    pt: 'Informações do cliente',
  },
  'subscriptions.clientEmail': {
    en: 'Client email',
    es: 'Email del cliente',
    pt: 'E-mail do cliente',
  },
  'subscriptions.clientDocument': {
    en: 'Client document',
    es: 'Documento del cliente',
    pt: 'Documento do cliente',
  },
  'subscriptions.clientId': {
    en: 'Client id',
    es: 'ID del cliente',
    pt: 'ID do cliente',
  },
  'subscriptions.paymentDetails': {
    en: 'Payment details',
    es: 'Detalles del pago',
    pt: 'Detalhes do pagamento',
  },
  'subscriptions.nextPaymentDate': {
    en: 'Next payment date',
    es: 'Próxima fecha de pago',
    pt: 'Próxima data de pagamento',
  },
  'subscriptions.executions.date': {
    en: 'Date',
    es: 'Fecha',
    pt: 'Data',
  },
  'subscriptions.executions.transactionID': {
    en: 'Transaction ID',
    es: 'ID de transacción',
    pt: 'ID da transação',
  },
  'subscriptions.executions.amountPaid': {
    en: 'Amount paid',
    es: 'Total pagado',
    pt: 'Valor pago',
  },
  'subscriptions.executions.amountReceived': {
    en: 'Amount received',
    es: 'Total recibido',
    pt: 'Valor recebido',
  },
  'subscriptions.executions.paymentStatus': {
    en: 'Payment status',
    es: 'Estado del pago',
    pt: 'Status do pagamento',
  },
  'subscriptions.plan.subscriptions': {
    es: 'Suscripciones',
    pt: 'Assinaturas',
    en: 'Subscriptions',
  },
  'subscriptions.plan.backToSubscriptions': {
    es: 'Volver a tus suscripciones',
    pt: 'Voltar para suas assinaturas',
    en: 'Back to your subscriptions',
  },
  'subscriptions.plan.createPlan': {
    es: 'Crear un nuevo plan',
    pt: 'Criar um novo plano',
    en: 'Create a new plan',
  },
  'subscriptions.plan.editPlan': {
    es: 'Editar tu plan de suscripción',
    pt: 'Editar seu plano de assinatura',
    en: 'Edit your subscription plan',
  },
  'subscriptions.plan.createPlanDescription': {
    es: 'Un plan te permitirá empezar a recibir pagos de tus clientes de forma recurrente.',
    pt: 'Um plano permitirá que você comece a receber pagamentos dos seus clientes de forma recorrente.',
    en: 'A plan will allow you to start receiving payments from your clients on a recurring basis.',
  },
  'subscriptions.plan.editPlanDescription': {
    es: 'Aquí puedes actualizar la información de tu plan de suscripción.',
    pt: 'Here you can update your subscription information.',
    en: 'Aqui você pode atualizar as informações da sua assinatura.',
  },
  'subscriptions.plan.card1.cardTitle': {
    es: 'Completa el nombre del producto o servicio que quieres ofrecer',
    pt: 'Preencha o nome do produto ou serviço que você deseja oferecer.',
    en: 'Fill in the name of the product or service you want to offer.',
  },
  'subscriptions.plan.card1.planName': {
    es: 'Nombre del plan',
    pt: 'Nome do plano',
    en: 'Plan name',
  },
  'subscriptions.plan.card1.planNameTooltip': {
    es: 'Tus clientes verán este nombre cuando realicen el pago de la suscripción.',
    pt: 'Seus clientes verão este nome quando fizerem o pagamento da assinatura.',
    en: 'Your customers will see this name when they make the subscription payment.',
  },
  'subscriptions.plan.card1.planDescription': {
    es: 'Descripción',
    pt: 'Descrição',
    en: 'Description',
  },
  'subscriptions.plan.card1.planDescriptionTooltip': {
    es: 'Tus clientes verán esta descripción cuando realicen el pago de la suscripción.',
    pt: 'Seus clientes verão essa descrição quando fizerem o pagamento da assinatura.',
    en: 'Your clients will see this description when they make the subscription payment.',
  },
  'subscriptions.plan.card2.cardTitle': {
    es: 'Agrega los detalles del pago',
    pt: 'Adicione os detalhes de pagamento',
    en: 'Add the payment details',
  },
  'subscriptions.plan.card2.paymentDetailsDescription': {
    es: 'Tu cliente solo podrá pagarte con tarjeta de débito o crédito.',
    pt: 'Seu cliente só poderá pagar com cartão de débito ou crédito.',
    en: 'Your client will only be able to pay you with a debit or credit card.',
  },
  'subscriptions.plan.card2.subscribersCountry': {
    es: 'País de los suscriptores',
    pt: 'País dos assinantes',
    en: "Subscribers' country",
  },
  'subscriptions.plan.card2.subscribersCountryTooltip': {
    es: 'Si la suscripción es para todos los países, el cliente podrá elegir su país de origen al momento del pago.',
    pt: 'Se a assinatura for para todos os países, o cliente poderá escolher seu país de origem no momento do pagamento.',
    en: 'If the subscription is for all countries, the client will be able to choose their country of origin at the time of payment.',
  },
  'subscriptions.plan.card2.chargeAmount': {
    es: 'Cantidad que deseas cobrar.',
    pt: 'Valor que deseja cobrar.',
    en: 'You want to charge',
  },
  'subscriptions.plan.card3.cardTitle': {
    es: 'Define la frecuencia de pago de la suscripción',
    pt: 'Defina a frequência de pagamento da assinatura.',
    en: 'Define the payment frequency of the subscription.',
  },
  'subscriptions.plan.card3.frequency': {
    es: 'Frecuencia',
    pt: 'Frequência',
    en: 'Frequency',
  },
  'subscriptions.plan.summaryCard.cardTitle': {
    es: 'Resumen del plan',
    pt: 'Resumo do plano',
    en: 'Plan summary',
  },
  'subscriptions.plan.summaryCard.planName': {
    es: 'Nombre del plan',
    pt: 'Nome do plano',
    en: 'Plan name',
  },
  'subscriptions.plan.summaryCard.planDescription': {
    es: 'Descripción',
    pt: 'Descrição',
    en: 'Description',
  },
  'subscriptions.plan.summaryCard.subscribersCountry': {
    es: 'País de los suscriptores',
    pt: 'País dos assinantes',
    en: "Subscribers' country",
  },
  'subscriptions.plan.summaryCard.paymentAmount': {
    es: 'Tu cliente pagará',
    pt: 'Seu cliente irá pagar',
    en: 'Your client will pay',
  },
  'subscriptions.plan.summaryCard.paymentFrequency': {
    es: 'Frecuencia de cobro',
    pt: 'Frequência de pagamento',
    en: 'Payment frequency',
  },
  'subscriptions.plan.reviewPlan': {
    es: 'Revisar plan',
    pt: 'Rever o plano',
    en: 'Review plan',
  },
  'subscriptions.plan.confirmPlanDetails.goBack': {
    es: 'Volver a editar el plan',
    pt: 'Voltar para editar o plano',
    en: 'Back to edit the plan',
  },
  'subscriptions.plan.confirmPlanDetails.pageTitle': {
    es: 'Confirmar detalles del plan',
    pt: 'Confirmar detalhes do plano',
    en: 'Confirm plan details',
  },
  'subscriptions.plan.confirmPlanDetails.pageDescription': {
    es: 'Por favor, revisa la información antes de crear el plan.',
    pt: 'Por favor, revise as informações antes de criar o plano.',
    en: 'Please review the information before creating the plan.',
  },
  'subscriptions.plan.confirmUpdatePlanDetails.pageDescription': {
    es: 'Por favor, revisa los detalles antes de actualizar el plan.',
    pt: 'Por favor, revise os detalhes antes de atualizar o plano.',
    en: 'Please review the details before updating the plan.',
  },
  'subscriptions.plan.confirmPlanDetails.createPlan': {
    es: 'CREAR PLAN',
    pt: 'CRIAR PLANO',
    en: 'CREATE PLAN',
  },
  'subscriptions.plan.confirmUpdatePlanDetails.updatePlan': {
    es: 'ACTUALIZAR PLAN',
    pt: 'ATUALIZAR PLANO',
    en: 'UPDATE PLAN',
  },
  'subscriptions.plan.confirmPlanDetails.cancel': {
    es: 'CANCELAR',
    pt: 'CANCELAR',
    en: 'CANCEL',
  },
  'subscriptions.plan.sharePage.pageTitle': {
    es: '¡Plan de suscripción listo! Compártelo con tus clientes',
    pt: 'Plano de assinatura pronto! Compartilhe com seus clientes',
    en: 'Subscription plan ready! Share it with your clients',
  },
  'subscriptions.plan.sharePage.pageDescription': {
    es: 'Copia y comparte este link del plan',
    pt: 'Copie e compartilhe este link do plano',
    en: 'Copy and share this plan link',
  },
  'subscriptions.plan.sharePage.copyLink': {
    es: 'COPIAR LINK',
    pt: 'COPIAR LINK',
    en: 'COPY LINK',
  },
  'subscriptions.plan.sharePage.shareVia': {
    es: 'O comparte este link del plan a través de',
    pt: 'Ou compartilhe este link do plano através de',
    en: 'Or share this plan link via',
  },
  'subscriptions.plan.updateSubscription.pageTitle': {
    es: 'Editar tu plan de suscripción',
    pt: 'Editar seu plano de assinatura',
    en: 'Edit your subscription plan',
  },
  'subscriptions.plan.updateSubscription.pageDescription': {
    es: 'Aquí puedes actualizar la información de tu plan de suscripción.',
    pt: 'Aqui você pode atualizar as informações do seu plano de assinatura.',
    en: 'Here you can update your subscription plan information.',
  },
  'subscriptions.plan.updateSubscription.reviewPlan': {
    es: 'REVISAR PLAN',
    pt: 'REVISAR PLANO',
    en: 'REVIEW PLAN',
  },
  'subscriptions.plan.updateSubscription.confirmPlanDetails.pageTitle': {
    es: 'Confirmar detalles del plan',
    pt: 'Confirmar detalhes do plano',
    en: 'Confirm plan details',
  },
  'subscriptions.plan.updateSubscription.confirmPlanDetails.pageDescription': {
    es: 'Por favor, revisa los detalles antes de actualizar el plan.',
    pt: 'Por favor, revise os detalhes antes de atualizar o plano.',
    en: 'Please review the details before updating the plan.',
  },
  'subscriptions.plan.updateSubscription.confirmPlanDetails.updatePlan': {
    es: 'ACTUALIZAR PLAN',
    pt: 'ATUALIZAR PLANO',
    en: 'UPDATE PLAN',
  },
  'subscriptions.plan.updateSubscription.confirmPlanDetails.cancel': {
    es: 'CANCELAR',
    pt: 'CANCELAR',
    en: 'CANCEL',
  },
  'subscriptions.plans.empty.title': {
    en: "You don't have plans created yet",
    es: 'Aún no tienes planes creados',
    pt: 'Você ainda não tem planos criados',
  },
  'subscriptions.plans.empty.body': {
    en: 'Create a plan and collect payments easily.',
    es: 'Crea un plan y recibe pagos fácilmente.',
    pt: 'Crie um plano e receba pagamentos facilmente.',
  },
  'subscriptions.plans.active': {
    en: 'Active',
    es: 'Activo',
    pt: 'Ativo',
  },
  'subscriptions.plans.inactive': {
    en: 'Inactive',
    es: 'Inactivo',
    pt: 'Inativo',
  },
  'subscriptions.plans.amount': {
    en: 'Your client will pay',
    es: 'Tu cliente pagará',
    pt: 'Seu cliente vai pagar',
  },
  'subscriptions.plans.country': {
    en: "Subscribers' country",
    es: 'País de los suscriptores',
    pt: 'País dos assinantes',
  },
  'subscriptions.plans.details': {
    en: 'Plan details',
    es: 'Detalles del plan',
    pt: 'Detalhes do plano',
  },
  'subscriptions.plans.details.backBtn': {
    en: 'Back to plans',
    es: 'Volver a mis planes',
    pt: 'Voltar aos meus planos',
  },
  'subscriptions.plans.details.backEdit': {
    en: 'Back to edit the plan',
    es: 'Volver a editar el plan',
    pt: 'Voltar para editar o plano',
  },
  'subscriptions.plans.details.cancel': {
    en: 'Cancel plan',
    es: 'Cancelar plan',
    pt: 'Cancelar plano',
  },
  'subscriptions.plans.details.edit': {
    en: 'Edit plan',
    es: 'Editar plan',
    pt: 'Editar plano',
  },
  'subscriptions.plans.details.share': {
    en: 'Share plan',
    es: 'Compartir plan',
    pt: 'Compartilhar plano',
  },
  'subscriptions.plans.details.date': {
    en: 'Plan date',
    es: 'Fecha del plan',
    pt: 'Data do plano',
  },
  'subscriptions.plans.details.paymentFrequency': {
    en: 'Payment frequency',
    es: 'Frecuencia de cobro',
    pt: 'Frequência de pagamento',
  },
  'subscriptions.plans.details.subscribers': {
    en: 'Subscribers',
    es: 'Suscriptores',
    pt: 'Assinantes',
  },
  'subscriptions.cancel.title': {
    en: 'Are you sure you want to cancel this subscription?',
    es: '¿Estás seguro de que quieres cancelar esta suscripción?',
    pt: 'Tem certeza de que deseja cancelar esta assinatura?',
  },
  'subscriptions.cancel.body1': {
    en: 'By cancelling the subscription your customer will no longer be charged. Please note that this action is irreversible.',
    es: 'Al cancelar la suscripción, ya no le cobrarás a tu cliente. Ten en cuenta que esta acción es irreversible.',
    pt: 'Ao cancelar a assinatura, seu cliente não será mais cobrado. Por favor, note que esta ação é irreversível.',
  },
  'subscriptions.cancel.body2': {
    en: "You'll still be able to view the payment status of that subscriber from the Subscribers tab.",
    es: 'Podrás seguir viendo el estado de pagos de ese suscriptor desde la pestaña Suscriptores.',
    pt: 'Você ainda poderá visualizar o status de pagamento desse assinante na guia Assinantes.',
  },
  'subscriptions.cancel.confirm': {
    en: 'YES, CANCEL THE SUBSCRIPTION',
    es: 'SÍ, CANCELAR LA SUSCRIPCIÓN',
    pt: 'SIM, CANCELAR A ASSINATURA',
  },
  'subscriptions.cancel.no': {
    en: 'NO, THANKS',
    es: 'NO, GRACIAS',
    pt: 'NÃO, OBRIGADO',
  },
  'subscriptions.plans.cancel.title': {
    en: 'Are you sure you want to cancel this plan?',
    es: '¿Seguro que quieres cancelar este plan?',
    pt: 'Tem certeza de que deseja cancelar este plano?',
  },
  'subscriptions.plans.cancel.body1': {
    en: 'By cancelling the plan all active subscribers in this plan will no longer be charged.',
    es: 'Al cancelarlo, dejarás de cobrarle a todos los suscriptores activos de este plan.',
    pt: 'Ao cancelar o plano, todos os assinantes ativos neste plano não serão mais cobrados.',
  },
  'subscriptions.plans.cancel.body2': {
    en: "You'll still be able to view your inactive plan from the Plans tab.",
    es: 'Podrás seguir viendo a tu plan inactivo desde la pestaña Planes.',
    pt: 'Você ainda poderá visualizar seu plano inativo na guia Planos.',
  },
  'subscriptions.plans.cancel.confirm': {
    en: 'YES, CANCEL THE PLAN',
    es: 'SI, CANCELAR EL PLAN',
    pt: 'SIM, CANCELAR O PLANO',
  },
  'subscriptions.planSummary.title': {
    en: 'Plan summary',
    es: 'Resumen del plan',
    pt: 'Resumo do plano',
  },
};
