import { ReactNode } from 'react';
import './styles.scss';

interface CardInfoProps {
  children: ReactNode;
  bottomElement?: JSX.Element;
  height?: string;
  underhanded?: Boolean;
}

const CardInfo = ({ children, bottomElement, height = 'auto', underhanded = false }: CardInfoProps) => (
  <div className={`card-info ${underhanded ? 'card-info--underhanded' : ''}`} style={{ height }}>
    <div className="card-info__children">{children}</div>
    {!!bottomElement && <div className="card-info__bottom_element">{bottomElement}</div>}
  </div>
);

export default CardInfo;
