import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import l from '../../../lang';
import OnboardingCard from './onboarding-card';
import FirstTimeCards from './first-time-cards';
import ReminderStepTwo from './reminder-step-two';
import ReminderStepTwoLimitReached from './reminder-step-two-limit-reached';
import ProductCards from './product-cards';
import Balances from './balances';
import TopDashboardSection from '../../common/top-dashboard-section';
import AlertMessages from '../alert-messages';
import { useDashboard } from '../../../store/dashboard';
import { useHome } from '../../../store/home';
import { DialogSuccessKycStep1 } from './dialog-kyc-step1';
import { useCompanyInfo } from '../../../store/company-information';
import './styles.scss';
import 'animate.css/animate.min.css';
import { FeatureToggle, Off, On } from '../../../hooks/use-features';

const Home = () => {
  const { balances, getBalances, shouldAnimationsRun = true, putShouldAnimationsRun } = useHome();
  const { displayMessageKycStep1, setDisplayMessageKycStep1 } = useCompanyInfo();
  const history = useHistory();
  const { userMe, merchantMe } = useDashboard();

  const { canOperate, kycLevel, kycReview, completedTransactions = 0, createdCheckouts = 0 } = merchantMe || {};
  const { level = 0, maxAmount = 0 } = kycLevel || {};

  useEffect(() => {
    getBalances();
  }, []);

  useEffect(() => {
    history.replace({ pathname: '/home' });
  }, [history]);

  // Running animations only once
  shouldAnimationsRun && setTimeout(() => putShouldAnimationsRun(false), 1000);

  const isFirstTime = createdCheckouts === 0 && completedTransactions === 0;

  // Limit is reached when the user has reached 90% of the total amount of transactions. (u$s 2.700  of u$s 3.000)
  const limitReached = maxAmount * 0.9 <= (balances?.data?.totalAccumulated || 0);

  return (
    <div className="home-component">
      <TopDashboardSection title={l('home.welcome.title', userMe?.name?.split(' ')[0] || '')} />

      <AlertMessages />
      {!canOperate && <OnboardingCard shouldAnimationsRun={shouldAnimationsRun} />}

      {canOperate && level < 4 && <FirstTimeCards shouldAnimationsRun={shouldAnimationsRun} maxAmount={maxAmount} />}

      {completedTransactions > 0 && level < 4 && !limitReached && <ReminderStepTwo />}

      {completedTransactions > 0 && level < 4 && limitReached && <ReminderStepTwoLimitReached />}

      {completedTransactions > 0 && <Balances balances={balances} />}

      {<ProductCards shouldAnimationsRun={shouldAnimationsRun && Boolean(canOperate) && !isFirstTime} />}

      {displayMessageKycStep1 && canOperate && level === 1 && !kycReview && (
        <DialogSuccessKycStep1 onClose={() => setDisplayMessageKycStep1(false)} />
      )}
    </div>
  );
};

export default Home;
