import { takeLatest, put, call, select } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { deleteTask, getTask, patchTask, postTask, uploadTask } from '../../services';
import {
  BankAccountsActionType,
  getBankAccountsSuccess,
  getBankAccountsError,
  getBanksByCountrySuccess,
  getBanksByCountryError,
  addBankAccountSuccess,
  addBankAccountError,
  sendDeleteBankAccountSuccess,
  sendDeleteBankAccountError,
} from './actions';
import { PanelStore } from '../interfaces';
import { segmentBankAccountsAddBankAccount, segmentKycFormL2BankSubmit } from '../../utils/analytics';
import { BankAccount, BankAccountStatus } from './interfaces';

function* getBankAccounts() {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: Urls.api.allBankAccounts,
    token: state.auth.token,
  });

  if (response) {
    yield put(getBankAccountsSuccess(response));
  } else {
    yield put(getBankAccountsError(error));
  }
}

export function* getBankAccountsSaga() {
  yield takeLatest(BankAccountsActionType.GET_BANK_ACCOUNTS, getBankAccounts);
}

function* getBanksByCountry(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.banks}?country=${action.country}`,
    token: state.auth.token,
  });

  if (response) {
    yield put(getBanksByCountrySuccess(response));
  } else {
    yield put(getBanksByCountryError(error));
  }
}

export function* getBanksByCountrySaga() {
  yield takeLatest(BankAccountsActionType.GET_BANKS_BY_COUNTRY, getBanksByCountry);
}

function* addBankAccount(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { bankAccountDocument } = action.values;

  const { response, error } = yield call(postTask, {
    url: bankAccountDocument ? Urls.api.addBeneficiaryWithDocument : Urls.api.addBeneficiary,
    body: {
      ...action.values,
    },
    token: state.auth.token,
    format: bankAccountDocument ? 'form' : 'json',
  });

  if (!error && response) {
    if (action.isStep2) {
      segmentKycFormL2BankSubmit();
    }
    segmentBankAccountsAddBankAccount({});
    yield put(addBankAccountSuccess(response));
  } else {
    segmentBankAccountsAddBankAccount({ error });
    yield put(addBankAccountError(error));
    if (action.isStep2) {
      segmentKycFormL2BankSubmit({
        error,
      });
    }
  }
}

export function* addBankAccountSaga() {
  yield takeLatest(BankAccountsActionType.ADD_BANK_ACCOUNT, addBankAccount);
}

function* deleteBankAccount(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { id } = action;
  const { response, error } = yield call(deleteTask, {
    url: `${Urls.api.deleteBankAccount}/${id}`,
    token: state.auth.token,
  });

  if (response && !error) {
    yield put(sendDeleteBankAccountSuccess(response));
  } else {
    yield put(sendDeleteBankAccountError(error));
  }
}

export function* deleteBankAccountSaga() {
  yield takeLatest(BankAccountsActionType.DELETE_BANK_ACCOUNT, deleteBankAccount);
}
