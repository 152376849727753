import l from '../../../../lang';
import Message from '../../../common/message';
import { MessageTypes } from '../../../../constants';
import ButtonLink from '../../../common/button-link';
import { useDashboard } from '../../../../store/dashboard';
import '../styles.scss';

const FraudMessage = () => {
  const { merchantMe } = useDashboard();
  const { canOperate, fraudReview, settlementEnabled } = merchantMe || {};

  const subheadline = settlementEnabled
    ? 'common.fraudReview.subheadline.settlement.enabled'
    : 'common.fraudReview.subheadline';

  return (
    (!canOperate && fraudReview && (
      <div className="alert_message">
        <Message
          type={MessageTypes.WARNING}
          title={l('common.fraudReview.headline')}
          body={
            <span>
              <span>{l(subheadline)} </span>
              <ButtonLink text={l('common.fraudReview.subheadline.link')} onClick={() => window.Intercom('show')} />
            </span>
          }
        />
      </div>
    )) ||
    null
  );
};

export default FraudMessage;
