import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import _ from 'lodash';
import Parser from 'html-react-parser';
import { v4 as uuidv4 } from 'uuid';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMediaQuery } from '@mui/material';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import Select from '../../../common/select';
import Input from '../../../common/input';
import './styles.scss';
import l from '../../../../lang';
import { getTextValidCharacters, selectLanguages } from '../../../../utils/common-utils';
import MessageError from '../../../common/message-error';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right-completed.svg';
import Loading from '../../../common/loading';
import TooltipIcon from '../../../common/tooltip-icon';
import Message from '../../../common/message';
import { CheckoutTypes, MessageTypes } from '../../../../constants';
import { allCountries, functionalCurrenciesByCountry } from '../../../../config';
import ButtonLink from '../../../common/button-link';
import { openNewTabAndFocus } from '../../../../utils';
import { Urls } from '../../../../config/urls';
import {
  segmentIntegrationsPayButtonCeatePayButton,
  segmentIntegrationsPayButtonCopyCode,
} from '../../../../utils/analytics';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { useDashboard } from '../../../../store/dashboard';
import { segmentIntegrationsPayButtonCreatePayButtonPayload } from '../../../../utils/analytics/segment.d';
import AlertMessages from '../../alert-messages';
import BlockedMessage from '../../alert-messages/blocked-message';
import CurrencySelector from '../../../common/currency-selector';

interface FieldValues {
  currency: string;
  amount: number | string;
  country: string;
  lang: string;
  text: string;
}

const CreatePaymentBtn = () => {
  const { loading, error, errorInfo, apiKey, currencies, getCurrenciesPI, exchangeRates } = usePaymentIntegration();
  const { merchantMe } = useDashboard();
  const [crossBorderSameCurrency, setCrossBorderSameCurrency] = useState(false);
  const [isCrossBorder, setIsCrossBorder] = useState(false);

  const { canOperate, fraudReview } = merchantMe || {};

  const isTabletMobile = useMediaQuery('(max-width:768px)');
  const [generatedScript, setGeneratedScript] = useState('');

  const crossBorderCurrency = 'USD';

  const setDefaultCurrency = (value: string, setFieldValue: (fieldName: string, fieldValue: string) => void) => {
    if (currencies) {
      const cs = Object.keys(currencies);
      if (cs.length && !cs.filter(d => value === d).length) {
        setFieldValue('currency', cs[0]);
      }
    }
  };

  const countries = () => {
    const countries: { [key: string]: string } = {};

    const allowedCountries = merchantMe?.allowLocalToLocal
      ? allCountries
      : allCountries.filter(co => co !== merchantMe?.country);

    allowedCountries.forEach(co => {
      countries[co] = l(`countryCode.${co}`);
    });
    return countries;
  };

  const validate = (values: FieldValues) => {
    const errors: Partial<FieldValues> = {};
    if ((values.amount ?? 0) <= 0) {
      errors.amount = l('form.input.required');
    }
    if (!values.currency) {
      errors.currency = l('form.input.required');
    }
    return errors;
  };

  const createScript = (values: FieldValues) => {
    const aux: segmentIntegrationsPayButtonCreatePayButtonPayload = {
      countryCode: values.country,
      currency: values.currency,
      amount: values.amount,
      buttonText: values.text,
    };
    segmentIntegrationsPayButtonCeatePayButton(aux);

    const id = uuidv4();
    let str =
      import.meta.env.REACT_APP_ENV === 'production'
        ? // eslint-disable-next-line max-len
          '<script data-reference-id=":referenceId">(function(){const z=!!window.DlocalGo,s=z?document.querySelector(\'script[src="https://static.dlocalgo.com/dlocalgo.min.js"]\'):document.createElement("script");z||(s.src="https://static.dlocalgo.com/dlocalgo.min.js",s.async=!0,document.body.appendChild(s));s.addEventListener("load",()=>{const e=document.querySelector(\'script[data-reference-id=":referenceId"]\'),t=e.parentNode,n="dp-btn-:referenceId",c=document.createElement("div");c.id=n,t.insertBefore(c,e);new DlocalGo(":apiKey").createCheckout(n,{subType:":subType",country::country,currency:":currency",amount:":amount",lang:":lang",text:":text"})});})()</script>'
        : // eslint-disable-next-line max-len
          '<script data-reference-id=":referenceId">(function(){const z=!!window.DlocalGo,s=z?document.querySelector(\'script[src="https://static.dlocalgo.com/dlocalgo.min.js"]\'):document.createElement("script");z||(s.src="https://static.dlocalgo.com/dlocalgo.min.js",s.async=!0,document.body.appendChild(s));s.addEventListener("load",()=>{const e=document.querySelector(\'script[data-reference-id=":referenceId"]\'),t=e.parentNode,n="dp-btn-:referenceId",c=document.createElement("div");c.id=n,t.insertBefore(c,e);new DlocalGo(":apiKey", true).createCheckout(n,{subType:":subType",country::country,currency:":currency",amount:":amount",lang:":lang",text:":text"})});})()</script>';

    str = str.replace(/:subType/g, CheckoutTypes.BUTTON);
    str = str.replace(/:referenceId/g, id);
    str = str.replace(/:apiKey/g, apiKey);
    str = str.replace(/:country/g, `"${values.country || ''}"`);
    str = str.replace(/:currency/g, values.currency);
    str = str.replace(/:amount/g, values.amount?.toString() ?? '');
    str = str.replace(/:lang/g, values.lang);
    str = str.replace(/:text/g, values.text);

    return str;
  };

  const applyToFixed = (value: number) => {
    return value && value !== 0 ? value.toFixed(2) : value;
  };

  const formDisabled = !canOperate || fraudReview || loading;

  return (
    <div className="create-payment-btn">
      <Formik
        initialValues={{
          currency: 'USD',
          amount: '',
          country: '',
          lang: '',
          text: '',
          crossBorderData: {
            secondCurrency: '',
            secondAmount: 0,
          },
        }}
        validate={validate}
        onSubmit={values => {
          const script = createScript(values);
          setGeneratedScript(script);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          useEffect(() => {
            if (values.country === merchantMe?.country) {
              setIsCrossBorder(false);
              setCrossBorderSameCurrency(false);
              setDefaultCurrency(values.country, false);
            } else if (values.country) {
              setIsCrossBorder(true);
              setCrossBorderSameCurrency(!Object.keys(currencies).find(c => c !== crossBorderCurrency));
              setDefaultCurrency(values.country, true);
            }
          }, [currencies]);

          useEffect(() => {
            if (!values.country && parseFloat(values.amount) === 0) setIsCrossBorder(false);
          }, [values.amount]);

          useEffect(() => {
            if (values.country && values.currency) {
              !values.crossBorderData.secondCurrency &&
                setFieldValue('crossBorderData', {
                  ...values.crossBorderData,
                  secondCurrency: values.currency,
                });
            }
          }, [values.currency]);

          const setDefaultCurrency = (value: string, isCrossBorder: boolean) => {
            if (currencies) {
              if (!isCrossBorder) {
                const defaultCurrency = (functionalCurrenciesByCountry as { [key: string]: string })[value];
                handleOnChangeCurrency(defaultCurrency);
              } else {
                if (currencies['USD']) {
                  handleOnChangeCurrency('USD');
                } else {
                  const cs = Object.keys(currencies);
                  if (cs.length && !cs.filter(d => value === d).length) {
                    handleOnChangeCurrency(cs[0]);
                  }
                }
              }
            }
          };

          const handleOnChangeCurrency = (value: string) => {
            setFieldValue('currency', value);
            // cleans
            setFieldValue('amount', '');
            if (isCrossBorder) {
              setFieldValue('crossBorderData', {
                ...values.crossBorderData,
                secondCurrency: Object.keys(currencies).find(c => c !== value) || '',
                secondAmount: '',
              });
            }
          };

          return (
            <form onSubmit={handleSubmit}>
              <div className="create-payment-btn__title">{l('localCurrencyWarning')}</div>
              <div className="create-payment-btn__desc__step">{Parser(l('createPaymentBtn.desc.stepOne'))}</div>

              <div className="create-payment-btn__row">
                <div className="create-payment-btn__field create-payment-btn__field--left">
                  <Select
                    name="country"
                    label={l('createPaymentBtn.country')}
                    value={values.country}
                    onChange={e => {
                      if (e.target.value) {
                        getCurrenciesPI(e.target.value);
                      } else {
                        setFieldValue('currency', 'USD');
                      }
                      setFieldValue('country', e.target.value);
                    }}
                    disabled={formDisabled}
                    options={countries()}
                    defaultOptionEnabled
                    defaultOptionText={l('common.all.m')}
                    error={(touched.country && errors.country) || undefined}
                  />
                </div>
                {!isCrossBorder && (
                  <div className="create-payment-btn__field create-payment-btn__field--right">
                    <Input
                      type="number"
                      name="amount"
                      label={l('paymentLink.amount')}
                      onChange={e => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={values.amount}
                      disabled={formDisabled}
                      error={(touched.amount && errors.amount) || undefined}
                      prefix={values.currency}
                    />
                  </div>
                )}
              </div>
              {isCrossBorder && (
                <>
                  {!crossBorderSameCurrency && (
                    <div className="create-payment-btn__row create-payment-btn__currency-selector">
                      <CurrencySelector
                        firstCurrency={crossBorderCurrency}
                        secondCurrency={Object.keys(currencies).find(c => c !== crossBorderCurrency) || ''}
                        value={values.currency}
                        onChange={value => handleOnChangeCurrency(value)}
                        disabled={formDisabled}
                      />
                    </div>
                  )}
                  <div className="create-payment-btn__row">
                    <div className="create-payment-btn__field create-payment-btn__field--left">
                      <Input
                        type="number"
                        name="amount"
                        label={l(
                          crossBorderCurrency === values.currency
                            ? 'paymentLink.amountWantToCharge'
                            : 'paymentLink.amountYourClientWillPay'
                        )}
                        onChange={e => {
                          const value = e.target.value;
                          if (crossBorderCurrency === values.currency) {
                            setFieldValue('crossBorderData', {
                              ...values.crossBorderData,
                              secondAmount: applyToFixed(value * exchangeRates[values.crossBorderData.secondCurrency]),
                            });
                          } else {
                            setFieldValue('crossBorderData', {
                              ...values.crossBorderData,
                              secondAmount: applyToFixed(value / exchangeRates[values.currency]),
                            });
                          }
                          setFieldValue('amount', value);
                        }}
                        onBlur={handleBlur}
                        value={values.amount}
                        disabled={formDisabled}
                        error={(touched.amount && errors.amount) || undefined}
                        prefix={values.currency}
                      />
                      {!crossBorderSameCurrency && (
                        <div>
                          <p className="create-payment-link-form__exchange_rate">
                            <span>{l('paymentLink.exchangeRate')}</span>
                            <span>
                              &nbsp;{values.currency} {applyToFixed(exchangeRates[values.currency])}
                            </span>
                            <ArrowRightIcon />
                            <span>
                              <span> {values.crossBorderData.secondCurrency} </span>
                              {applyToFixed(exchangeRates[values.crossBorderData.secondCurrency])}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                    {!crossBorderSameCurrency && (
                      <div className="create-payment-btn__field create-payment-btn__field--right create-payment-btn__input-tooltip-container">
                        <Input
                          type="number"
                          name="second_amount"
                          label={l(
                            crossBorderCurrency === values.currency
                              ? 'paymentLink.amountYourClientWillPay'
                              : 'paymentLink.amountYouWillReceive'
                          )}
                          onChange={e => {
                            const value = e.target.value;
                            setFieldValue('crossBorderData', { ...values.crossBorderData, secondAmount: value });
                            if (crossBorderCurrency === values.crossBorderData.secondCurrency) {
                              setFieldValue('amount', applyToFixed(value * exchangeRates[values.currency]));
                            } else {
                              setFieldValue(
                                'amount',
                                applyToFixed(value / exchangeRates[values.crossBorderData.secondCurrency])
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.crossBorderData.secondAmount}
                          disabled={formDisabled}
                          prefix={Object.keys(currencies).find(c => c !== values.currency) || ''}
                        />

                        <div>
                          <p className="create-payment-link-form__fx_message">
                            <span>
                              {crossBorderCurrency !== values.currency ? (
                                l('paymentLink.exchangeRate.fxMessage')
                              ) : (
                                <>&nbsp;</>
                              )}
                            </span>
                          </p>
                        </div>

                        <div className="create-payment-btn__input-tooltip">
                          <TooltipIcon
                            placement="right"
                            text={Parser(
                              crossBorderSameCurrency
                                ? l(
                                    'paymentLink.tooltip.sameCurrency',
                                    (functionalCurrenciesByCountry as { [key: string]: string })[
                                      merchantMe?.country || ''
                                    ] || '***'
                                  )
                                : l(
                                    'currencySelector.tooltip',
                                    Object.keys(currencies).find(c => c !== crossBorderCurrency) || crossBorderCurrency,
                                    (functionalCurrenciesByCountry as { [key: string]: string })[
                                      merchantMe?.country || ''
                                    ] || '***'
                                  )
                            )}
                            icon={<InfoIcon color="primary" />}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}

              <div className="create-payment-btn__row">
                <div className="create-payment-btn__field create-payment-btn__field--left">
                  <Input
                    type="text"
                    name="text"
                    label={l('createPaymentBtn.text')}
                    placeholder={l('createPaymentBtn.text.placeholder')}
                    onChange={e => setFieldValue('text', getTextValidCharacters(e.target.value))}
                    onBlur={handleBlur}
                    value={values.text}
                    maxLength={50}
                    disabled={formDisabled}
                    error={(touched.text && errors.text) || undefined}
                  />
                </div>
                {!values.text && (
                  <div className="create-payment-btn__field create-payment-btn__field--right">
                    <Select
                      name="lang"
                      label={l('createPaymentBtn.langBtn')}
                      value={values.lang}
                      onChange={handleChange}
                      defaultOptionEnabled
                      defaultOptionText={l('createPaymentBtn.langBtn.auto')}
                      options={selectLanguages()}
                      disabled={formDisabled}
                      error={(touched.lang && errors.lang) || undefined}
                    />
                  </div>
                )}
              </div>
              <div className="create-payment-btn__note-desc">
                <div>{l('createPaymentBtn.noteRequiredfields')}</div>
              </div>
              {loading && <Loading fullScreen />}
              {error && (
                <div className="create-payment-btn__error">
                  <MessageError errorInfo={errorInfo} />
                </div>
              )}
              <div className="create-payment-btn__submit">
                <ButtonCustom
                  type="submit"
                  text={l('createPaymentBtn.btn')}
                  textLoading={l('form.sending')}
                  variant="contained"
                  size={`${isTabletMobile ? 'full-square' : 'square'}`}
                  disabled={error || !apiKey || formDisabled}
                />
              </div>
              <div className="create-payment-btn__desc__step">{Parser(l('createPaymentBtn.desc.stepTwo'))}</div>
              <div className="create-payment-btn__result">
                <Input name="result" value={generatedScript} disabled multiline rows={4} />
                <div className="create-payment-btn__result__btn">
                  <CopyToClipboard text={generatedScript} onCopy={() => segmentIntegrationsPayButtonCopyCode()}>
                    <ButtonCustom
                      name="button-copy"
                      text={l('createPaymentBtn.copyCode')}
                      variant="contained"
                      size={`${isTabletMobile ? 'full-square' : 'square'}`}
                      disabled={generatedScript.trim() === ''}
                    />
                  </CopyToClipboard>
                </div>
              </div>
              <div className="create-payment-btn__note">
                <span>{Parser(l('createPaymentBtn.forDevelopers'))} </span>
                <ButtonLink
                  text={l('createPaymentBtn.forDevelopers.clickHere')}
                  onClick={() => openNewTabAndFocus(Urls.docs.paymentButton)}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreatePaymentBtn;
