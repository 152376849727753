import { useDispatch, useSelector } from 'react-redux';
import { Values } from '../interfaces';
import { getMerchantMe, initDashboard, updateMerchantMe, updateUserMe } from './actions';
import { DashboardSelectors } from './selectors';

export const useDashboard = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(DashboardSelectors.getLoading),
    error: useSelector(DashboardSelectors.getError),
    merchantMe: useSelector(DashboardSelectors.getMerchantMe),
    userMe: useSelector(DashboardSelectors.getUserMe),
    companyWasFulfilled: useSelector(DashboardSelectors.getCompanyWasFulfilled),
    initDashboard: (menuSelected?: string) => dispatch(initDashboard(menuSelected)),
    getMerchantMe: () => dispatch(getMerchantMe()),
    updateMerchantMe: (newValues: Values) => dispatch(updateMerchantMe(newValues)),
    updateUserMe: (newValues: Values) => dispatch(updateUserMe(newValues)),
  };
};
