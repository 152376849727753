import { ReactNode } from 'react';
import './styles.scss';

interface CardPaymentProps {
  children: ReactNode;
  bottomElement?: JSX.Element;
  height?: string;
  underhanded?: Boolean;
}

const CardPayment = ({ children, bottomElement, underhanded = false }: CardPaymentProps) => (
  <div className={`card-payment ${underhanded ? 'card-payment--underhanded' : ''}`}>
    <div className="card-payment__children">{children}</div>
    {!!bottomElement && <div className="card-payment__bottom_element">{bottomElement}</div>}
  </div>
);

export default CardPayment;
