import CompanyInformation from '../company-information';
import { DialogWarningKycStep1 } from '../home/dialog-kyc-step1';
import { useDashboard } from '../../../store/dashboard';
import { useCompanyInfo } from '../../../store/company-information';

const ActivateAccount = () => {
  const { displayMessageKycStep1, setDisplayMessageKycStep1, showUpLevel } = useCompanyInfo();
  const { merchantMe } = useDashboard();
  const { canOperate, kycReview, kycLevel } = merchantMe || {};
  const { level = 0 } = kycLevel || {};

  return (
    <>
      <CompanyInformation />
      {!showUpLevel && displayMessageKycStep1 && !canOperate && level === 1 && !kycReview && (
        <DialogWarningKycStep1 onClose={() => setDisplayMessageKycStep1(false)} />
      )}
    </>
  );
};

export default ActivateAccount;
