import React from 'react';
import PropTypes from 'prop-types';
import Input from '../input';
import Select from '../select';
import { InputType } from '../../../constants';

const InputData = ({
  type,
  name,
  label,
  placeholder,
  onChange,
  onBlur,
  value,
  defaultValue,
  optionsArray,
  prefix,
  mask,
  disabled,
  error,
}) => {
  switch (type) {
    case InputType.COMBO_BOX: {
      return (
        <Select
          styleClass="-md"
          name={name}
          label={label}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          optionsArray={optionsArray}
          disabled={disabled}
          error={error}
        />
      );
    }
    case InputType.DATE:
    case InputType.NUMBER:
    case InputType.PHONE:
    case InputType.TEXT:
    default: {
      return (
        <Input
          name={name}
          label={label}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          prefix={prefix}
          mask={mask}
          disabled={disabled}
          error={error}
        />
      );
    }
  }
};

InputData.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  prefix: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  optionsArray: PropTypes.array,
  mask: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

export default InputData;
