import './styles.scss';
import l from '../../../../lang';
import { Urls } from '../../../../config/urls';
import { Client } from '../../../../store/clients/interfaces';

interface ClientDetailProps {
  detail: Client;
}

const ClientDetail = ({ detail }: ClientDetailProps) => {
  return (
    <div className="client-detail">
      <div>
        <div className="client-detail__item">
          <div className="client-detail__subtitle">{l('clients.country')}</div>
          <div>
            <img
              alt={`img-country-${detail.country}`}
              className="client-detail__country"
              src={`${Urls.flags.replace(':code', detail.country)}`}
            />
          </div>
        </div>
        <div className="client-detail__item">
          <div className="client-detail__subtitle">{l('clients.merchantClientId')}</div>
          <div>{detail.merchantClientId || '-'}</div>
        </div>
      </div>
      <div>
        <div className="client-detail__item">
          <div className="client-detail__subtitle">{l('clients.firstName')}</div>
          <div>{detail.firstName || '-'}</div>
        </div>
        <div className="client-detail__item">
          <div className="client-detail__subtitle">{l('clients.lastName')}</div>
          <div>{detail.lastName || '-'}</div>
        </div>
      </div>
      <div>
        <div className="client-detail__item">
          <div className="client-detail__subtitle">{l('clients.documentType')}</div>
          <div>{detail.documentType || '-'}</div>
        </div>
        <div className="client-detail__item">
          <div className="client-detail__subtitle">{l('clients.document')}</div>
          <div>{detail.document || '-'}</div>
        </div>
      </div>
      <div>
        <div className="client-detail__item client-detail__item--full">
          <div className="client-detail__subtitle">{l('clients.email')}</div>
          <div>{detail.email || '-'}</div>
        </div>
      </div>
    </div>
  );
};

export default ClientDetail;
