import _ from 'lodash';
import { handleErrorInfo } from '../../utils';
import { invalidBankAccountCode } from '../../utils/error-codes';
import { ErrorFields } from '../interfaces';
import { PaymentMethod } from '../payment-methods/interfaces';
import { RefundsActionType } from './actions';
import { RefundsState, Banks } from './interfaces';

const initialState: RefundsState = {
  loading: true,
  error: false,
  allRefunds: undefined,
  requestRefund: {
    loading: false,
    error: false,
    errorInfo: undefined,
    errorFields: undefined,
    banks: {},
    success: false,
  },
  paymentMethods: {
    loading: false,
    error: false,
    errorInfo: undefined,
    data: {},
  },
};

const RefundsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RefundsActionType.GET_REFUNDS: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case RefundsActionType.GET_REFUNDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        allRefunds: {
          ...action.response,
        },
      };
    }
    case RefundsActionType.GET_REFUNDS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        allRefunds: null,
      };
    }
    case RefundsActionType.GET_BANKS: {
      return {
        ...state,
        requestRefund: {
          ...state.requestRefund,
          loading: true,
          error: false,
          errorInfo: undefined,
          banks: {},
          success: false,
        },
      };
    }
    case RefundsActionType.GET_BANKS_SUCCESS: {
      const banks: Banks = {};
      action.response.forEach((b: { code: string; name: string }) => {
        banks[`BANK_CODE_${b.code}`] = b.name;
      });
      return {
        ...state,
        requestRefund: {
          ...state.requestRefund,
          loading: false,
          error: false,
          errorInfo: undefined,
          banks,
        },
      };
    }
    case RefundsActionType.GET_BANKS_ERROR: {
      return {
        ...state,
        requestRefund: {
          ...state.requestRefund,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(RefundsActionType.GET_BANKS_ERROR, action.error),
          banks: {},
        },
      };
    }
    case RefundsActionType.REQUEST_REFUND: {
      return {
        ...state,
        requestRefund: {
          ...state.requestRefund,
          loading: true,
          error: false,
          errorInfo: undefined,
          errorFields: undefined,
        },
      };
    }
    case RefundsActionType.REQUEST_REFUND_SUCCESS: {
      return {
        ...state,
        requestRefund: {
          ...state.requestRefund,
          loading: false,
          error: false,
          errorInfo: undefined,
          success: true,
        },
      };
    }
    case RefundsActionType.REQUEST_REFUND_ERROR: {
      const errorInfo = handleErrorInfo(RefundsActionType.REQUEST_REFUND_ERROR, action.error);
      const errorFields: ErrorFields = {};

      if (errorInfo && errorInfo.code === invalidBankAccountCode) {
        errorFields.bankAccount = action.error.sentValues.bankAccount.bankAccount;
      }
      if (!_.isEmpty(errorFields)) {
        return {
          ...state,
          requestRefund: {
            ...state.requestRefund,
            loading: false,
            error: false,
            errorFields,
          },
        };
      }

      return {
        ...state,
        requestRefund: {
          ...state.requestRefund,
          loading: false,
          error: true,
          errorInfo,
        },
      };
    }
    case RefundsActionType.GET_PAYMENT_METHODS_FOR_REFUNDS: {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: true,
          error: false,
          errorInfo: undefined,
          data: {},
        },
      };
    }
    case RefundsActionType.GET_PAYMENT_METHODS_FOR_REFUNDS_SUCCESS: {
      const data: { [key: number]: string } = {};
      action.response.data.forEach((pm: PaymentMethod) => {
        data[pm.id] = pm.name;
      });
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: false,
          error: false,
          errorInfo: undefined,
          data,
        },
      };
    }
    case RefundsActionType.GET_PAYMENT_METHODS_FOR_REFUNDS_ERROR: {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(RefundsActionType.GET_PAYMENT_METHODS_FOR_REFUNDS_ERROR, action.error),
        },
      };
    }
    default:
      return state;
  }
};

export default RefundsReducer;
