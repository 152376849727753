export enum SettlementsActionType {
  GET_SETTLEMENTS = 'SETTLEMENTS/GET_SETTLEMENTS',
  GET_SETTLEMENTS_SUCCESS = 'SETTLEMENTS/GET_SETTLEMENTS_SUCCESS',
  GET_SETTLEMENTS_ERROR = 'SETTLEMENTS/GET_SETTLEMENTS_ERROR',
  SHOW_REQUEST_SETTLEMENT = 'SETTLEMENTS/SHOW_REQUEST_SETTLEMENT',
  GET_BENEFICIARIES = 'SETTLEMENTS/GET_BENEFICIARIES',
  GET_BENEFICIARIES_SUCCESS = 'SETTLEMENTS/GET_BENEFICIARIES_SUCCESS',
  GET_BENEFICIARIES_ERROR = 'SETTLEMENTS/GET_BENEFICIARIES_ERROR',
  REQUEST_SETTLEMENT = 'SETTLEMENTS/REQUEST_SETTLEMENT',
  REQUEST_SETTLEMENT_SUCCESS = 'SETTLEMENTS/REQUEST_SETTLEMENT_SUCCESS',
  REQUEST_SETTLEMENT_ERROR = 'SETTLEMENTS/REQUEST_SETTLEMENT_ERROR',
}

export const getSettlements = (page: number, filter: string) => ({
  type: SettlementsActionType.GET_SETTLEMENTS,
  page,
  filter,
});

export const getSettlementsSuccess = (response: any) => ({
  type: SettlementsActionType.GET_SETTLEMENTS_SUCCESS,
  response,
});

export const getSettlementsError = (error: any) => ({
  type: SettlementsActionType.GET_SETTLEMENTS_ERROR,
  error,
});

export const changeShowRequestSettlement = (show: boolean) => ({
  type: SettlementsActionType.SHOW_REQUEST_SETTLEMENT,
  show,
});

export const getBeneficiaries = () => ({
  type: SettlementsActionType.GET_BENEFICIARIES,
});

export const getBeneficiariesSuccess = (response: any) => ({
  type: SettlementsActionType.GET_BENEFICIARIES_SUCCESS,
  response,
});

export const getBeneficiariesError = (error: any) => ({
  type: SettlementsActionType.GET_BENEFICIARIES_ERROR,
  error,
});

export const postRequestSettlement = (beneficiaryId: string) => ({
  type: SettlementsActionType.REQUEST_SETTLEMENT,
  beneficiaryId,
});

export const postRequestSettlementSuccess = (response: any) => ({
  type: SettlementsActionType.REQUEST_SETTLEMENT_SUCCESS,
  response,
});

export const postRequestSettlementError = (error: any) => ({
  type: SettlementsActionType.REQUEST_SETTLEMENT_ERROR,
  error,
});
