import { useDashboard } from '../../../../store/dashboard';
import ShopifyDetailsNonTransparent from './shopify-details-non-transparent';
import ShopifyDetailsTransparent from './shopify-details-transparent';
import ShopifyDetailsTransparentInstalled from './shopify-details-transparent-installed';

export default () => {
  const { merchantMe } = useDashboard();
  const isShopifyInstalled = Boolean(merchantMe?.hasShopifyReference);
  const hasTransparentIntegration = merchantMe?.country === 'BR';

  return (
    <>
      {!hasTransparentIntegration && <ShopifyDetailsNonTransparent />}
      {hasTransparentIntegration && !isShopifyInstalled && <ShopifyDetailsTransparent />}
      {hasTransparentIntegration && isShopifyInstalled && <ShopifyDetailsTransparentInstalled />}
    </>
  );
};
