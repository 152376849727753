import l from '../../lang';

export const refundStatus = () => {
  return {
    CREATED: l('refunds.status.CREATED'),
    PENDING: l('refunds.status.PENDING'),
    COMPLETED: l('refunds.status.COMPLETED'),
    CANCELLED: l('refunds.status.CANCELLED'),
    REJECTED: l('refunds.status.REJECTED'),
    DECLINED: l('refunds.status.DECLINED'),
  };
};

export const accountTypes = () => {
  return {
    CHECKING: l('accountType.CHECKING'),
    SAVING: l('accountType.SAVING'),
    SALARY: l('accountType.SALARY'),
    VISTA: l('accountType.VISTA'),
    MASTER: l('accountType.MASTER'),
  };
};
