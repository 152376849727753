import './styles.scss';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import type { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import l from '../../../../../../lang';
import TooltipIcon from '../../../../../common/tooltip-icon';
import { ReactComponent as HelpIcon } from '../../../../../../assets/icons/question-circle.svg';
import { useReports } from '../../../../../../store/reports';
import { useEffect } from 'react';
import ErrorRetry from '../../../error-retry';
import Loading from '../../../../../common/loading';
import { chartColors, getOptions } from './config';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface TransactionsByPaymentSolutionProps {
  period: number;
}

const TransactionsByPaymentSolution = ({ period }: TransactionsByPaymentSolutionProps) => {
  const { transactionsByPaymentSolution, getTransactionsByPaymentSolution } = useReports();
  const { loading, error, data } = transactionsByPaymentSolution;

  useEffect(() => {
    getTransactionsByPaymentSolution(period);
  }, [period]);

  const emptyChart = !data.length || (data.length === 1 && !data[0].type);
  const noTransactionLabel = [l('reports.transactionsBy.empty')];

  const chartData: ChartData<'bar'> = {
    labels: !emptyChart
      ? data.map(item =>
          item.type === 'OTHERS'
            ? l('common.others')
            : l(`reports.transactionsByPaymentSolution.type.${item.type}`).split(' ')
        )
      : noTransactionLabel,
    datasets: [
      {
        data: !emptyChart ? data.map(item => item.percentage) : [],
        backgroundColor: chartColors,
        maxBarThickness: 80,
      },
    ],
  };

  return (
    <div className="transactions_by_payment_solution">
      <div className="transactions_by_payment_solution__title">
        <span>{l('reports.transactionsByPaymentSolution.title')}</span>
        <TooltipIcon
          placement="right"
          text={l('reports.transactionsByPaymentSolution.tooltip')}
          icon={<HelpIcon width={21} height={21} color="primary" />}
        />
      </div>
      <div className="transactions_by_payment_solution__chart">
        {error && <ErrorRetry onRetry={() => getTransactionsByPaymentSolution(period)} />}
        {loading && <Loading />}
        {!loading && !error && <Bar height={300} options={getOptions(data)} data={chartData} />}
      </div>
    </div>
  );
};

export default TransactionsByPaymentSolution;
