import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import Loading from '../../common/loading';
import Table from '../../common/table';
import { Urls } from '../../../config/urls';
import l from '../../../lang';
import './styles.scss';
import ChipStatus from '../../common/chip-status';
import { createDate } from '../../../utils/index';
import { Routes } from '../../../config/routes';
import TopDashboardSection from '../../common/top-dashboard-section';
import ButtonMore from '../../common/button-more';
import Card from '../../common/card';
import CardDescription from '../../common/card-description';
import { Empty, EmptyNoFound } from './empty';
import TransactionFilter from './filter';
import { useMediaQuery } from '@mui/material';
import ExportTransactions from './export';
import { useTransactions } from '../../../store/transactions';
import { Filters } from '../../../store/interfaces';
import AlertMessages from '../alert-messages';
import { segmentTransactionsTransactionDetail } from '../../../utils/analytics';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';

const Transactions = () => {
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const { loading, error, allTransactions, getTransactions } = useTransactions();

  const history = useHistory();

  const initFilters = { search_by: '', status: '', country: '', from: '', to: '', paymentMethodId: '' };
  const [filter, setFilter] = useState<Filters>(initFilters);

  useEffect(() => {
    getTransactions(0, filter);
  }, [filter]);

  const hasFilter = Object.values(filter).some(value => value !== '');
  const hasTransactions = allTransactions && allTransactions?.data?.length > 0;

  const EmptyBody = () => {
    if (!error && !hasTransactions && !hasFilter) {
      return <Empty />;
    }
    if (error || !hasTransactions) {
      return <EmptyNoFound />;
    }
    return null;
  };

  return (
    <div className="transactions">
      <TopDashboardSection title={l('menu.item.TRANSACTIONS')} />
      <AlertMessages />
      <Card>
        <CardDescription>{l('transactions.subheadline')}</CardDescription>
        <div className={isDesktop ? '' : 'transactions__export__btn'}>{<ExportTransactions />}</div>
        <div className="transactions__content">
          <div className="transactions__filter_wrapper">
            <TransactionFilter
              loading={loading}
              appliedFilters={filter}
              clearFilters={() => setFilter(initFilters)}
              changeFilters={filters => setFilter(filters)}
            />
          </div>
          {loading && (
            <div className="transactions__loading">
              <Loading />
            </div>
          )}
          {!loading && (
            <div className="transactions__table">
              <Table
                withBtnMenu
                emptyBody={<EmptyBody />}
                headers={[
                  <span className="transactions__column__left">{l('transactions.createDate')}</span>,
                  <span className="transactions__column__left">{l('transactions.invoiceId')}</span>,
                  <span className="transactions__column__left">{l('transactions.country')}</span>,
                  <span className="transactions__column__left">{l('transactions.name')}</span>,
                  <span className="transactions__column__left">{l('transactions.paymentMethod')}</span>,
                  <span className="transactions__column__right transactions__title">{l('transactions.amountPaid')}</span>,
                  <span className="transactions__column__right transactions__title">
                    {l('transactions.amountReceived')}
                  </span>,
                  <span>{l('transactions.status')}</span>,
                ]}
                body={
                  (hasTransactions &&
                    !error &&
                    allTransactions?.data?.map(trans => {
                      return [
                        <span className="transactions__column__left">
                          <Moment format="DD/MM/YYYY">{createDate(trans.creationDate)}</Moment>
                        </span>,
                        <span className="transactions__column__left transactions__column__invoice">
                          <span>{trans.invoiceId}</span>
                          {trans.preChargebackForm?.comments && (
                            <Tooltip title={l('transactions.client_sent_chargeback_form')}>
                              <ErrorIcon className="transactions__column__invoice__icon" />
                            </Tooltip>
                          )}
                        </span>,
                        <span className="transactions__column__left transactions__country">
                          <img
                            alt=""
                            className="transactions__country__flag"
                            src={`${Urls.flags.replace(':code', trans.country)}`}
                          />
                          &nbsp;
                          <span>{l(`countryCode.${trans.country}`)}</span>
                        </span>,
                        <span className="transactions__column__left">
                          {trans.clientFirstName} {trans.clientLastName}
                        </span>,
                        <span className="transactions__column__left">{trans.paymentMethod}</span>,
                        <span className="transactions__column__right">
                          {trans.currency} {trans.localAmount}
                        </span>,
                        <span className="transactions__column__right">
                          {trans.balanceCurrency} {trans.balanceAmount}
                        </span>,
                        <div>
                          <ChipStatus status={trans.status} text={l(`transactions.status.${trans.status}`)} />
                        </div>,
                        <ButtonMore
                          onClick={() => {
                            segmentTransactionsTransactionDetail({
                              externalId: trans.externalId,
                            });
                            history.push(Routes.TRANSACTION_DETAIL.replace(':invoice_id', trans.invoiceId));
                          }}
                        />,
                      ];
                    })) ||
                  null
                }
                rowsPerPageOptions={[allTransactions?.size]}
                rowsLength={allTransactions?.totalElements}
                rowsPerPage={allTransactions?.size}
                page={allTransactions?.page}
                handleChangePage={(event, newPage) => {
                  getTransactions(newPage, filter);
                }}
              />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Transactions;
