import TopDashboardSection from '../../common/top-dashboard-section';
import l from '../../../lang';
import Card from '../../common/card';
import CardTitle from '../../common/card-title';
import CardDescription from '../../common/card-description';
import DataCards from './data-cards';
import './styles.scss';
import PaymentVolume from './charts/payment-volume';
import TransactionsBy from './charts/transactions-by';

export default () => {
  const period = 7;
  return (
    <>
      <TopDashboardSection title={l('reports.headline')} />
      <Card>
        <CardTitle>{l('reports.subheadline')}</CardTitle>
        <CardDescription>{l('reports.description')}</CardDescription>
        <div className="reports_pill">{l('reports.tag', period)}</div>
        <DataCards period={period} />
        <PaymentVolume period={period} />
        <TransactionsBy period={period} />
      </Card>
    </>
  );
};
