
import { createTheme } from '@mui/material/styles';

export default createTheme({
  typography: {
    fontFamily: 'Roboto',
  },
  palette: {
    primary: {
      main: '#006cfa',
      light: 'rgba(0, 108, 250, 0.05)',
      dark: '#062EAE',
    },
    secondary: {
      main: '#2F8600',
    },
    text: {
      primary: '#595959',
    },
  },
});
