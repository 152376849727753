import Input from '../input';
import l from '../../../lang';
import './styles.scss';

const getNowDate = () => {
  const now = new Date();
  let month = `${now.getMonth() + 1}`;
  month = month.length === 1 ? `0${month}` : month;
  return `${now.getFullYear()}-${month}-${now.getDate()}`;
};

interface DateRangePickerProps {
  fromDate: string;
  toDate: string;
  changeFrom: (value: string) => void;
  changeTo: (value: string) => void;
  size?: 'small' | 'normal';
  error?: string;
  vertical?: boolean;
  disabled?: boolean;
}

const DateRangePicker = ({
  fromDate,
  toDate,
  changeFrom,
  changeTo,
  size,
  disabled,
  error,
  vertical,
}: DateRangePickerProps) => {
  const handleMaxDate = (date: string) => {
    const nowString = getNowDate();
    if (date) {
      const now = new Date(nowString);
      const d = new Date(date);
      if (d < now) {
        return date;
      }
    }
    return nowString;
  };

  return (
    <div className="date_range_picker">
      <div className={`date_range_picker__inputs ${vertical ? 'date_range_picker__inputs__vertical' : ''}`}>
        <div className={`date_range_picker__from ${vertical ? 'date_range_picker__from__vertical' : ''}`}>
          <Input
            shrink
            name="fromDate"
            label={l('datepicker.from')}
            placeholder={l('datepicker.placeholder')}
            type="date"
            value={fromDate}
            minDate="2020-02-03" // The project started
            maxDate={handleMaxDate(toDate)}
            onChange={e => changeFrom(e.target.value)}
            size={size}
            error={!!error}
            disabled={disabled}
          />
        </div>
        <div className={`date_range_picker__to ${vertical ? 'date_range_picker__to__vertical' : ''}`}>
          <Input
            shrink
            name="toDate"
            label={l('datepicker.to')}
            placeholder={l('datepicker.placeholder')}
            type="date"
            value={toDate}
            minDate={fromDate}
            maxDate={getNowDate()}
            onChange={e => changeTo(e.target.value)}
            size={size}
            error={!!error}
            disabled={disabled}
          />
        </div>
      </div>
      {error && <div className="date_range_picker__error">{error}</div>}
    </div>
  );
};

export default DateRangePicker;
