import { useState, useEffect, useRef } from 'react';
import Parser from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import l from '../../../../lang';
import Input from '../../../common/input';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import SelectBeneficiary from '../select-beneficiary';
import Loading from '../../../common/loading';
import MessageError from '../../../common/message-error';
import SuccessfulAnimation from '../../../common/successful-animation';
import Message from '../../../common/message';
import { MessageTypes } from '../../../../constants';
import { scrollIntoView } from '../../../../utils';
import Dialog from '../../../common/dialog';
import config from '../../../../config';
import ButtonLink from '../../../common/button-link';
import { Routes } from '../../../../config/routes';
import { useSettlements } from '../../../../store/settlements';
import { useDashboard } from '../../../../store/dashboard';
import { useHome } from '../../../../store/home';
import { gtagSettlementRequest } from '../../../../utils/analytics';

import './styles.scss';

const RequestSettlement = () => {
  const { merchantMe, userMe } = useDashboard();
  const { functionalCurrency, settlementEnabled, settlementFeeEnabled } = merchantMe || {};

  const { loading, error, errorInfo, requestSuccess, postRequestSettlement } = useSettlements();

  const { balances, getBalances } = useHome();
  const availableBalance = balances.data?.availableBalance;
  const loadingBalances = balances.loading;

  const [showErrors, setShowErrors] = useState(false);
  const [amount, setAmount] = useState(0);
  const [beneficiaryId, setBeneficiaryId] = useState('');
  const errorRef = useRef(null);
  const beneficiariesRef = useRef(null);
  const [confirmMessage, setConfirmMessage] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getBalances();
  }, []);

  useEffect(() => {
    if (availableBalance) {
      setAmount(availableBalance);
    }
    // eslint-disable-next-line
  }, [availableBalance]);

  useEffect(() => {
    if (error) {
      scrollIntoView(errorRef);
    }
  }, [error]);

  const onGoToBankAccountsHandler = () => {
    history.push(Routes.BANK_ACCOUNTS);
  };

  return (
    <div className="request-settlement">
      {(loading || loadingBalances) && <Loading fullScreen />}
      {requestSuccess && (
        <div className="request-settlement__success">
          <SuccessfulAnimation text={l('requestSettlement.success')} />
        </div>
      )}
      {!requestSuccess && (
        <>
          <div className="request-settlement__container">
            {amount > 0 && !settlementEnabled && (
              <div className="request-settlement__kyc-review">
                <Message type={MessageTypes.WARNING} body={l('requestSettlement.withdrawalsDisabled')} />
              </div>
            )}
            {settlementFeeEnabled && (
              <div className="request-settlement__desc">
                {l('requestSettlement.desc', config.minWithdrawalUSD, config.chargeWithdrawalUSD)}
              </div>
            )}
            <div className="request-settlement__amount">
              <Input
                type="number"
                name="amount"
                label={l('requestSettlement.amount')}
                onChange={e => setAmount(e.target.value)}
                prefix={functionalCurrency}
                value={amount}
                disabled
                error={showErrors && amount <= 0 ? l('requestSettlement.amount.noAvailable') : null}
              />
            </div>
            <SelectBeneficiary
              disabled={!settlementEnabled}
              value={beneficiaryId}
              onSelect={val => setBeneficiaryId(val)}
              errorReference={beneficiariesRef}
              externalError={showErrors && amount > 0 && !beneficiaryId ? l('beneficiary.isRequired') : null}
            />
            <div>
              {l('settlements.goto.bankAccounts.desc1')}{' '}
              <ButtonLink text={l('settlements.goto.bankAccounts.link')} onClick={onGoToBankAccountsHandler} />{' '}
              {l('settlements.goto.bankAccounts.desc2')}
            </div>
            <div ref={errorRef}>
              {error && (
                <div className="request-settlement__error">
                  <MessageError errorInfo={errorInfo} />
                </div>
              )}
            </div>
          </div>
          <div className="request-settlement__btn">
            <ButtonCustom
              text={l('requestSettlement.sendRequest')}
              loading={loading}
              size="full-bottom"
              variant="contained"
              disabled={(amount > 0 && !settlementEnabled) || loading}
              onClick={() => {
                setShowErrors(true);
                gtagSettlementRequest({ email: userMe?.email });
                if (amount && amount > 0 && beneficiaryId) {
                  if (settlementFeeEnabled && functionalCurrency === 'USD' && amount < config.minWithdrawalUSD) {
                    setConfirmMessage(true);
                  } else {
                    postRequestSettlement(beneficiaryId);
                  }
                } else {
                  scrollIntoView(beneficiariesRef);
                }
              }}
            />
          </div>
        </>
      )}
      <Dialog
        open={confirmMessage}
        dividers={false}
        title={l('paymentMethod.confirm.title')}
        unpaddingFooter
        unpaddingContent
        body={
          <div className="confirm-body-container">
            {Parser(l('requestSettlement.confirmMessage', config.minWithdrawalUSD, config.chargeWithdrawalUSD))}
          </div>
        }
        footer={
          <div className="confirm-footer-container">
            <ButtonCustom text={l('common.cancel')} size="half-left" onClick={() => setConfirmMessage(false)} />
            <ButtonCustom
              name="btn-confirm"
              text={l('common.confirm')}
              variant="contained"
              size="half-right"
              onClick={() => {
                setConfirmMessage(false);
                postRequestSettlement(beneficiaryId);
              }}
            />
          </div>
        }
      />
    </div>
  );
};

export default RequestSettlement;
