import { useEffect, useState } from 'react';
import { Refresh } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import Loading from '../../common/loading';
import Table from '../../common/table';
import icClients from '../../../assets/icons/clients.svg?url';
import { Urls } from '../../../config/urls';
import l from '../../../lang';
import './styles.scss';
import MessageError from '../../common/message-error';
import TopDashboardSection from '../../common/top-dashboard-section';
import Dialog from '../../common/dialog';
import ClientDetail from './detail';
import ButtonMore from '../../common/button-more';
import { useClients } from '../../../store/clients';
import { Client } from '../../../store/clients/interfaces';

const Clients = () => {
  const isTabletMobile = useMediaQuery('(max-width:768px)');
  const [clientDetail, setClientDetail] = useState<Client>();
  const { loading, error, allClients, getClients } = useClients();

  useEffect(() => {
    getClients();
  }, []);

  const dataExist = allClients && allClients?.data?.length > 0;

  return (
    <>
      <TopDashboardSection title={l('menu.item.CLIENTS')} subtitle={l('menu.item.CLIENTS.desc')} />
      <div className={`panel ${dataExist && !loading ? 'panel--no-bg' : ''} clients`}>
        <div className={`clients__container ${dataExist ? 'clients__container--data-exist' : ''}`}>
          {loading && (
            <div className="clients__loading">
              <Loading />
            </div>
          )}
          {error && (
            <div className="clients__error">
              <MessageError iconClose={<Refresh />} onClose={() => getClients()} />
            </div>
          )}
          {!loading && !error && (
            <div className="clients__table">
              <Table
                idxHiddenColumnsOnMobile={[4, 5, 6]}
                withBtnMenu={isTabletMobile}
                headers={[
                  l('clients.country'),
                  l('clients.merchantClientId'),
                  l('clients.firstName'),
                  l('clients.lastName'),
                  l('clients.email'),
                  l('clients.documentType'),
                  l('clients.document'),
                ]}
                body={
                  (dataExist &&
                    allClients?.data?.map(client => {
                      const item = [
                        <img
                          alt={`img-country-${client.country}`}
                          className="clients__country"
                          src={`${Urls.flags.replace(':code', client.country)}`}
                        />,
                        <span>{client.merchantClientId || '-'}</span>,
                        <span>{client.firstName || '-'}</span>,
                        <span>{client.lastName || '-'}</span>,
                        <span>{client.email || '-'}</span>,
                        <span>{client.documentType || '-'}</span>,
                        <span>{client.document || '-'}</span>,
                      ];
                      if (isTabletMobile) {
                        item.push(<ButtonMore onClick={() => setClientDetail(client)} />);
                      }
                      return item;
                    })) ||
                  null
                }
                rowsPerPageOptions={[allClients?.size]}
                rowsLength={allClients?.totalElements}
                rowsPerPage={allClients?.size}
                page={allClients?.page}
                handleChangePage={(event, newPage) => {
                  getClients(newPage);
                }}
                maxHeight={undefined}
                emptyBody={undefined}
              />
              {clientDetail && (
                <Dialog
                  dividers={false}
                  unpaddingFooter
                  unpaddingContent
                  fullScreenMobile
                  titleCenter
                  title={l('clients.details')}
                  handleClose={() => setClientDetail(undefined)}
                  body={<ClientDetail detail={clientDetail} />}
                />
              )}
              {!dataExist && (
                <div className="clients__no-clients">
                  <div>
                    <img className="clients__no-clients__img" src={icClients} alt="img-no-clients" />
                    <div>{l('clients.noClients')}</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Clients;
