const webConfig = {} as any;

if (typeof window !== 'undefined') {
  webConfig.releaseStage = import.meta.env.REACT_APP_ENV;
  webConfig.appVersion = import.meta.env.REACT_APP_VERSION;
}

if (typeof process !== 'undefined') {
  webConfig.releaseStage = process.env.REACT_APP_ENV;
  webConfig.appVersion = process.env.REACT_APP_VERSION;
}

export default {
  apiKey: '6602124fbd55359635fe29c5a11bd7a5',
  enabledReleaseStages: ['staging', 'production'],
  releaseStage: webConfig.releaseStage,
  appVersion: webConfig.appVersion || 'local',
};
