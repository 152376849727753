import { useEffect } from 'react';
import l from '../../../lang';
import { usePaymentIntegration } from '../../../store/payment-integration';
import TopDashboardSection from '../../common/top-dashboard-section';
import CreatePaymentBtn from './create-payment-btn';
import './styles.scss';
import AlertMessages from '../alert-messages';

const PaymentButton = () => {
  const { getIntegrationKeys, apiKey } = usePaymentIntegration();

  useEffect(() => {
    if (apiKey.length === 0) {
      getIntegrationKeys();
    }
  }, []);

  return (
    <>
      <TopDashboardSection title={l('menu.item.PAYMENT_BUTTON')} />
      <AlertMessages />
      <div className="panel payment-button">
        <CreatePaymentBtn />
      </div>
    </>
  );
};

export default PaymentButton;
