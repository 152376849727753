import { FC } from 'react';
import l from '../../../../lang';
import { Urls } from '../../../../config/urls';
import Dialog from '../../../common/dialog';
import { useMediaQuery } from '@mui/material';
import './styles.scss';
import { useDashboard } from '../../../../store/dashboard';
import ShareLink from '../share-link';

interface PaymentLinkDialogProps {
  isRecurring: boolean;
  url: string;
  country: string;
  currency: string;
  amount: number;
  description: string;
  crossBorderData?: { secondCurrency: string; secondAmount: number };
  onClose: () => void;
}

const PaymentLinkDialog: FC<PaymentLinkDialogProps> = ({
  isRecurring,
  url,
  country,
  currency,
  amount,
  description,
  crossBorderData,
  onClose,
}) => {
  const isTabletMobile = useMediaQuery('(max-width:768px)');
  const { merchantMe } = useDashboard();
  const crossBorderCurrency = 'USD';

  const isCrossBorder = merchantMe?.country !== country;

  const descriptionBox = (
    <div className="payment_link_dialog__share__card">
      <div className="payment_link_dialog__share__card__headline">{l('paymentLink.shareLink.field3')}</div>
      <div className="payment_link_dialog__share__card__content">{description || ' - '}</div>
    </div>
  );

  return (
    <Dialog
      dividers={false}
      title={
        <p className="payment_link_dialog__share__headline">
          {isRecurring ? l('paymentLink.shareLink.recurring.headline') : l('paymentLink.shareLink.headline')}
        </p>
      }
      unpaddingFooter
      fullScreenMobile
      handleClose={onClose}
      type="sm"
      body={
        <div className="payment_link_dialog__share">
          <div className="payment_link_dialog__share__wrapper">
            <div className="payment_link_dialog__share__cards">
              <div className="payment_link_dialog__share__card">
                <div className="payment_link_dialog__share__card__headline">{l('paymentLink.shareLink.field1')}</div>
                <div className="payment_link_dialog__share__card__content">
                  <div className="payment_link_dialog__share__card__country">
                    {country ? (
                      <>
                        <img alt="" src={`${Urls.flags.replace(':code', country)}`} />
                        <span>{l(`countryCode.${country}`)}</span>
                      </>
                    ) : (
                      <span>{l(`common.all.m`)}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="vertical_line" />
              <div className="payment_link_dialog__share__card">
                <div className="payment_link_dialog__share__card__headline">
                  {isCrossBorder && crossBorderData && crossBorderData?.secondCurrency !== currency ? (
                    <>
                      {country ? (
                        <>
                          <div className="payment_link_dialog__share__card__headline__half_left">
                            {l(
                              crossBorderCurrency === currency
                                ? 'paymentLink.amountWantToCharge'
                                : 'paymentLink.amountYourClientWillPay'
                            )}
                          </div>

                          <div className="payment_link_dialog__share__card__headline__half_right">
                            {l(
                              crossBorderCurrency === currency
                                ? 'paymentLink.amountYourClientWillPay'
                                : 'paymentLink.amountYouWillReceive'
                            )}
                          </div>
                        </>
                      ) : (
                        <span>
                          {l(
                            crossBorderCurrency === currency
                              ? 'paymentLink.amountWantToCharge'
                              : 'paymentLink.amountYourClientWillPay'
                          )}
                        </span>
                      )}
                    </>
                  ) : (
                    <span>{l('paymentLink.shareLink.field2')}</span>
                  )}
                </div>
                <div className="payment_link_dialog__share__card__content">
                  {isCrossBorder && crossBorderData && crossBorderData?.secondCurrency !== currency ? (
                    <>
                      {country ? (
                        <>
                          <div className="payment_link_dialog__share__card__content__half_left">
                            {currency} {amount}
                          </div>
                          <div className="payment_link_dialog__share__card__content__half_right">
                            {crossBorderData?.secondCurrency} {crossBorderData?.secondAmount}
                          </div>
                        </>
                      ) : (
                        <div>
                          {currency} {amount}
                        </div>
                      )}
                    </>
                  ) : (
                    <span>
                      {currency} {amount}
                    </span>
                  )}
                </div>
              </div>
              {!isTabletMobile && (
                <>
                  <div className="vertical_line" />
                  {descriptionBox}
                </>
              )}
            </div>
            {isTabletMobile && <>{descriptionBox}</>}
          </div>
          <ShareLink url={url} />
        </div>
      }
      footer={<div className="payment_link_dialog__share__footer" />}
    />
  );
};

export default PaymentLinkDialog;
