import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { InputBase } from '@mui/material';
import { Search as SearchIcon, Close as ClearIcon } from '@mui/icons-material';
import { Tooltip } from '../tooltip';
import { getTextValidCharacters } from '../../../utils/common-utils';
import './styles.scss';
import { Button } from '../button';
import SelectMenu from '../select-menu';
import l from '../../../lang';
interface InputSearchProps {
  value?: string;
  placeholder?: string;
  isLoading?: boolean;
  helpText?: string;
  onSearch?: (type: string, value: string) => void;
}

const searchByFilters = [
  { value: 'transaction_id', label: l('transactions.invoiceId') },
  { value: 'tx_email', label: l('transactions.txEmail') },
  { value: 'client_name', label: l('transactions.name') },
  { value: 'client_email', label: l('transactions.clientEmail') },
  { value: 'document', label: l('transactions.document') },
  { value: 'total_amount', label: l('transactions.amountPaid') },
];

const defaultSearchByFilter = searchByFilters[0];

const InputSearch = ({ value, placeholder, isLoading = true, helpText = '', onSearch = () => {} }: InputSearchProps) => {
  const [text, setText] = useState<string>('');
  const [lastSearch, setLastSearch] = useState<string>();
  const [isOpenHelp, setIsOpenHelp] = useState(false);
  const [searchBy, setSearchByFilter] = useState(defaultSearchByFilter.value);
  const searchRef = useRef(null);

  useEffect(() => {
    if (value || (value === '' && value !== text)) {
      const finalValue = getTextValidCharacters(value);
      setText(finalValue);
      setLastSearch(finalValue);
    }
  }, [value]);

  const onSearchHandler = () => {
    if (isLoading || text === lastSearch) return;
    onSearch(searchBy, text);
    setLastSearch(text);
    setIsOpenHelp(false);
  };

  const onClearHandler = () => {
    if (isLoading) return;
    setLastSearch(undefined);
    setText('');
    onSearch(searchBy, '');
  };

  const onKeyDownHandler = (e: any) => {
    if (e.key === 'Enter') {
      onSearchHandler();
    }
  };

  const onTextChangeHandler = (e: ChangeEvent<HTMLInputElement>) => setText(getTextValidCharacters(e.currentTarget.value));

  const ButtonSearch = () => (
    <Button variant="contained" onClick={onSearchHandler} disableElevation>
      <SearchIcon />
    </Button>
  );
  const ButtonClear = () => (
    <Button variant="contained" onClick={onClearHandler} disableElevation>
      <ClearIcon />
    </Button>
  );
  const ButtonAction = () => (text.length > 0 && text === lastSearch ? <ButtonClear /> : <ButtonSearch />);

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      classes={{
        tooltip: 'input_search__tooltip',
        arrow: 'input_search__tooltip__arrow',
      }}
      open={isOpenHelp}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={helpText}
    >
      <div ref={searchRef} className="input_search">
        <SelectMenu
          defaultOptionEnabled={true}
          className="input_search__select"
          value={searchBy}
          optionsArray={searchByFilters}
          onChange={e => {
            setSearchByFilter(e.target.value as string);
          }}
          input={<InputBase />}
        />
        <InputBase
          placeholder={placeholder}
          value={text}
          onChange={onTextChangeHandler}
          onKeyDown={onKeyDownHandler}
          onFocus={() => setIsOpenHelp(text.length === 0)}
          onBlur={() => setTimeout(() => setIsOpenHelp(false), 100)}
          className="input_search__input"
        />
        <ButtonAction />
      </div>
    </Tooltip>
  );
};

export default InputSearch;
