import l from '../../lang';
import { PaymentMethodTypes } from '../../constants';

export const types = type => {
  switch (type) {
    case PaymentMethodTypes.VOUCHER:
      return l('paymentMethod.type.VOUCHER');
    case PaymentMethodTypes.BANK_TRANSFER:
      return l('paymentMethod.type.BANK_TRANSFER');
    case PaymentMethodTypes.CREDIT_CARD:
      return l('paymentMethod.type.CREDIT_CARD');
    case PaymentMethodTypes.ONLINE_BANK_TRANSFER:
      return l('paymentMethod.type.ONLINE_BANK_TRANSFER');
    default:
      return type;
  }
};

export const countryFilter = paymentMethods => {
  const data = {};
  paymentMethods.forEach(pm => {
    if (!data[pm.country]) {
      data[pm.country] = [];
    }
    data[pm.country].push(pm);
  });
  return data;
};

export const countryName = countryCode => {
  if (l(`countryCode.${countryCode}`)) {
    return l(`countryCode.${countryCode}`);
  }
  return countryCode;
};

export const getCommissions = data => {
  const percentageFee = `${data.percentageFee ? `${data.percentageFee}%` : ''}`;
  const fixedFee = `${data.fixedFee ? `USD ${data.fixedFee}` : ''}`;
  return percentageFee + (percentageFee && fixedFee ? ' + ' : '') + fixedFee;
};
