import { FunctionComponent } from 'react';
import Parser from 'html-react-parser';
import l from '../../../lang';
import { DialogConfirm, CardConfirm } from '../../common/confirm';

interface BankAccountNewSuccessProps {
  onClose: (confirm: boolean) => void;
}

const BankAccountNewSuccess: FunctionComponent<BankAccountNewSuccessProps> = ({ onClose }) => (
  <DialogConfirm onClose={onClose}>
    <CardConfirm
      onClose={onClose}
      headline={Parser(l('bankAccounts.new.sucess.headline'))}
      description={Parser(l('bankAccounts.new.sucess.subheadline'))}
      confirmChildren={l('bankAccounts.new.sucess.button')}
      level={'success'}
    />
  </DialogConfirm>
);

export default BankAccountNewSuccess;
