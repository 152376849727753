import { ErrorAPI } from '../interfaces';
import {
  TransactionsSummaryResponse,
  PaymentVolumeResponse,
  TransactionsByCountryResponse,
  TransactionsByPaymentSolutionResponse,
  TransactionsByPaymentMethodResponse,
} from './interfaces.d';

export enum ReportsActionType {
  REPORTS_TRANSACTIONS_SUMMARY = 'REPORTS_TRANSACTIONS/SUMMARY',
  REPORTS_TRANSACTIONS_SUMMARY_SUCCESS = 'REPORTS_TRANSACTIONS/SUMMARY_SUCCESS',
  REPORTS_TRANSACTIONS_SUMMARY_ERROR = 'REPORTS_TRANSACTIONS/SUMMARY_ERROR',
  REPORTS_TRANSACTIONS_PAYMENT_VOLUME = 'REPORTS_TRANSACTIONS/PAYMENT_VOLUME',
  REPORTS_TRANSACTIONS_PAYMENT_VOLUME_SUCCESS = 'REPORTS_TRANSACTIONS/PAYMENT_VOLUME_SUCCESS',
  REPORTS_TRANSACTIONS_PAYMENT_VOLUME_ERROR = 'REPORTS_TRANSACTIONS/PAYMENT_VOLUME_ERROR',
  REPORTS_TRANSACTIONS_BY_COUNTRY = 'REPORTS_TRANSACTIONS/BY_COUNTRY',
  REPORTS_TRANSACTIONS_BY_COUNTRY_SUCCESS = 'REPORTS_TRANSACTIONS/BY_COUNTRY_SUCCESS',
  REPORTS_TRANSACTIONS_BY_COUNTRY_ERROR = 'REPORTS_TRANSACTIONS/BY_COUNTRY_ERROR',
  REPORTS_TRANSACTIONS_BY_PAYMENT_SOLUTION = 'REPORTS_TRANSACTIONS/BY_PAYMENT_SOLUTION',
  REPORTS_TRANSACTIONS_BY_PAYMENT_SOLUTION_SUCCESS = 'REPORTS_TRANSACTIONS/BY_PAYMENT_SOLUTION_SUCCESS',
  REPORTS_TRANSACTIONS_BY_PAYMENT_SOLUTION_ERROR = 'REPORTS_TRANSACTIONS/BY_PAYMENT_SOLUTION_ERROR',
  REPORTS_TRANSACTIONS_BY_PAYMENT_METHOD = 'REPORTS_TRANSACTIONS/BY_PAYMENT_METHOD',
  REPORTS_TRANSACTIONS_BY_PAYMENT_METHOD_SUCCESS = 'REPORTS_TRANSACTIONS/BY_PAYMENT_METHOD_SUCCESS',
  REPORTS_TRANSACTIONS_BY_PAYMENT_METHOD_ERROR = 'REPORTS_TRANSACTIONS/BY_PAYMENT_METHOD_ERROR',
}

export const reportsGetTransactionsSummary = (days: number) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_SUMMARY,
  days,
});

export const reportsGetTransactionsSummarySuccess = (payload: TransactionsSummaryResponse) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_SUMMARY_SUCCESS,
  payload,
});

export const reportsGetTransactionsSummaryError = (error: ErrorAPI) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_SUMMARY_ERROR,
  error,
});

export const reportsGetPaymentVolume = (days: number) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_PAYMENT_VOLUME,
  days,
});

export const reportsGetPaymentVolumeSuccess = (payload: PaymentVolumeResponse) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_PAYMENT_VOLUME_SUCCESS,
  payload,
});

export const reportsGetPaymentVolumeError = (error: ErrorAPI) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_PAYMENT_VOLUME_ERROR,
  error,
});

export const reportsGetTransactionsByCountry = (days: number) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_BY_COUNTRY,
  days,
});

export const reportsGetTransactionsByCountrySuccess = (payload: TransactionsByCountryResponse) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_BY_COUNTRY_SUCCESS,
  payload,
});

export const reportsGetTransactionsByCountryError = (error: ErrorAPI) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_BY_COUNTRY_ERROR,
  error,
});

export const reportsGetTransactionsByPaymentSolution = (days: number) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_SOLUTION,
  days,
});

export const reportsGetTransactionsByPaymentSolutionSuccess = (payload: TransactionsByPaymentSolutionResponse) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_SOLUTION_SUCCESS,
  payload,
});

export const reportsGetTransactionsByPaymentSolutionError = (error: ErrorAPI) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_SOLUTION_ERROR,
  error,
});

export const reportsGetTransactionsByPaymentMethod = (days: number) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_METHOD,
  days,
});

export const reportsGetTransactionsByPaymentMethodSuccess = (payload: TransactionsByPaymentMethodResponse) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_METHOD_SUCCESS,
  payload,
});

export const reportsGetTransactionsByPaymentMethodError = (error: ErrorAPI) => ({
  type: ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_METHOD_ERROR,
  error,
});
