import { MessageTypes } from '../../constants';
import { BankAccountsActionType } from '../../store/bank-accounts/actions';
import { PaymentLinkActionType } from '../../store/payment-link/actions';
import { AuthActionType } from '../../store/auth/actions';
import { CompanyInformationActionType } from '../../store/company-information/actions';
import { Routes } from '../../config/routes';
import { EmailValidationActionType } from '../../store/email-validation/actions';
import { PasswordResetActionType } from '../../store/password-reset/actions';
import { ProfileActionType } from '../../store/profile/actions';
import { RefundsActionType } from '../../store/refunds/actions';
import { SettlementsActionType } from '../../store/settlements/actions';
import { SignUpActionType } from '../../store/sign-up/actions';
import { TransacionesActionType } from '../../store/transactions/actions';
import { VirtualPosActionType } from '../../store/virtual-pos/actions';
import { PanelStore } from '../../store/interfaces';

export const errorFieldsCode = 103;
export const invalidDocumentCode = 201;
export const invalidMinimumAmount = 306;
export const invalidCountryCode = 308;
export const invalidBankAccountCode = 707;
export const defaultMsgGettingData = ' _ ';

export interface ErrorInfo {
  level: MessageTypes;
  code?: number;
  title?: string;
  message?: string;
  messageArgs?: string | number;
}

const getData = (value: string | number) => {
  if (value) {
    return value;
  }
  return defaultMsgGettingData;
};

export const errorCodes = (state?: PanelStore.RootState) => {
  return {
    GENERIC_ERROR: {
      level: MessageTypes.ERROR,
      title: 'genericError.title',
      message: 'genericError.message',
    },
    [AuthActionType.LOGIN_ERROR]: {
      105: {
        level: MessageTypes.ERROR,
        title: 'msgError.login.block.headline',
        message: 'msgError.login.block.subheadline',
        messageArgs: ['window.openWidgetHelp()'],
        align: 'center',
      },
      202: {
        level: MessageTypes.ERROR,
        title: 'msgError.login.wrong.password.headline',
        message: 'msgError.login.wrong.password.subheadline',
        align: 'center',
      },
      209: {
        level: MessageTypes.ERROR,
        message: 'msgError.login.emailNotVerified',
      },
      default: {
        level: MessageTypes.ERROR,
        message: 'msgError.login.default',
      },
    },
    [SignUpActionType.SIGN_UP_ERROR]: {
      204: {
        level: MessageTypes.ERROR,
        message: 'msgError.signUp.emailAlreadyRegistered',
      },
    },
    [CompanyInformationActionType.SEND_KYC_ERROR]: {
      [errorFieldsCode]: {
        code: errorFieldsCode,
      },
      [invalidDocumentCode]: {
        level: MessageTypes.ERROR,
        title: 'msgError.companyInformation.invalidDocument.headline',
        message: 'msgError.companyInformation.invalidDocument.subheadline',
        inputError: { name: 'document', message: 'companyInformation.kyc.invalid' },
      },
      309: {
        level: MessageTypes.ERROR,
        message: 'msgError.companyInformation.countryIndicated',
      },
      319: {
        level: MessageTypes.ERROR,
        title: 'msgError.companyInformation.documentAlreadyInUse.headline',
        message: 'msgError.companyInformation.documentAlreadyInUse.subheadline',
        inputError: { name: 'document', message: 'companyInformation.kyc.documentAlreadyInUse' },
      },
      320: {
        level: MessageTypes.ERROR,
        title: 'msgError.companyInformation.websiteAlreadyInUse.headline',
        message: 'msgError.companyInformation.websiteAlreadyInUse.subheadline',
        inputError: { name: 'website', message: 'companyInformation.kyc.websiteAlreadyInUse' },
      },
    },
    [EmailValidationActionType.CONFIRM_EMAIL_ERROR]: {
      203: {
        level: MessageTypes.ERROR,
        message: 'msgError.emailValidation.invalidEmail',
      },
      205: {
        level: MessageTypes.ERROR,
        message: 'msgError.emailValidation.tokenExpired',
      },
    },
    [SettlementsActionType.REQUEST_SETTLEMENT_ERROR]: {
      703: {
        level: MessageTypes.ERROR,
        message: 'msgError.settlementInProcess',
      },
      704: {
        level: MessageTypes.ERROR,
        message: 'msgError.amountExceedsBalance',
      },
      705: {
        level: MessageTypes.ERROR,
        message: 'msgError.paymentProcessorError',
      },
    },
    [BankAccountsActionType.ADD_BANK_ACCOUNT_ERROR]: {
      103: {
        level: MessageTypes.ERROR,
        message: 'msgError.addBeneficiary.invalidData',
      },
      201: {
        level: MessageTypes.ERROR,
        message: 'msgError.addBeneficiary.document',
      },
      701: {
        level: MessageTypes.ERROR,
        message: 'msgError.addBeneficiary.countryIndicated',
      },
      [invalidBankAccountCode]: {
        level: MessageTypes.ERROR,
        message: 'msgError.addBeneficiary.bankAccountNumber',
      },
      708: {
        level: MessageTypes.ERROR,
        message: 'msgError.addBeneficiary.bankAccountBranch',
      },
      709: {
        level: MessageTypes.ERROR,
        message: 'msgError.addBeneficiary.bankAccountType',
      },
      712: {
        level: MessageTypes.ERROR,
        title: 'msgError.addBeneficiary.alreadyExists.headline',
        message: 'msgError.addBeneficiary.alreadyExists.subheadline',
      },
    },
    [BankAccountsActionType.DELETE_BANK_ACCOUNT_ERROR]: {
      718: {
        level: MessageTypes.ERROR,
        message: 'bankAccounts.delete.error.718',
      },
    },
    [VirtualPosActionType.CREATE_PAYMENT_ERROR]: {
      [errorFieldsCode]: {
        code: errorFieldsCode,
      },
      903: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.currencyNotSupported',
      },
      303: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.transactionLimitReached',
      },
      304: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.billingLimitReached',
      },
      305: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.transactionAmountExceeded',
        messageArgs: [
          getData(state?.dashboard?.merchantMe?.kycLevel?.maxTransactionAmount || 0),
          getData(state?.dashboard?.merchantMe?.kycLevel?.maxTransactionAmountCc || 0),
          Routes.COMPANY_INFORMATION,
        ],
      },
      306: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.amountLessThanAllowed',
        messageArgs: [getData(state?.dashboard?.merchantMe?.kycLevel?.minTransactionAmount || 0)],
      },
      307: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.accountIsNotOperational',
      },
      308: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.noPaymentMethodConfigured',
      },
      316: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.amountExceedsAllowedForCards',
      },
      900: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.clientEmailNotEntered',
      },
      1602: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.duplicatedClientId',
      },
    },
    [VirtualPosActionType.CONFIRM_PAYMENT_ERROR]: {
      [errorFieldsCode]: {
        code: errorFieldsCode,
      },
      [invalidDocumentCode]: {
        code: invalidDocumentCode,
      },
    },
    [PaymentLinkActionType.CREATE_PAYMENT_LINK_ERROR]: {
      [errorFieldsCode]: {
        code: errorFieldsCode,
      },
      [invalidDocumentCode]: {
        code: invalidDocumentCode,
      },
      903: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.currencyNotSupported',
      },
      904: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.invoiceAlreadyExists',
      },
      303: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.transactionLimitReached',
      },
      304: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.billingLimitReached',
      },
      305: {
        level: MessageTypes.WARNING,
        message: 'msgError.createPaymentLink.transactionAmountExceeded',
        messageArgs: [
          getData(state?.dashboard?.merchantMe?.kycLevel?.maxTransactionAmount || 0),
          Routes.COMPANY_INFORMATION,
        ],
      },
      [invalidMinimumAmount]: {
        code: invalidMinimumAmount,
        message: 'msgError.createPaymentLink.amountLessThanAllowed',
        messageArgs: [getData(state?.dashboard?.merchantMe?.kycLevel?.minTransactionAmount || 0)],
      },
      307: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.accountIsNotOperational',
      },
      [invalidCountryCode]: {
        code: invalidCountryCode,
        message: 'msgError.createPaymentLink.noPaymentMethodConfigured',
      },
      316: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.amountExceedsAllowedForCards',
      },
      900: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.clientEmailNotEntered',
      },
      1602: {
        level: MessageTypes.ERROR,
        message: 'msgError.createPaymentLink.duplicatedClientId',
      },
    },
    [RefundsActionType.REQUEST_REFUND_ERROR]: {
      [invalidBankAccountCode]: {
        code: invalidBankAccountCode,
        level: MessageTypes.ERROR,
        message: 'msgError.addBeneficiary.bankAccountNumber',
      },
      601: {
        level: MessageTypes.ERROR,
        message: 'msgError.requestRefund.dataProvided',
      },
      605: {
        level: MessageTypes.ERROR,
        message: 'msgError.requestRefund.amountEntered',
      },
      606: {
        level: MessageTypes.ERROR,
        message: 'msgError.requestRefund.insufficientBalance',
      },
      607: {
        level: MessageTypes.ERROR,
        message: 'msgError.requestRefund.amountLess',
      },
    },
    [PasswordResetActionType.SEND_PASSWORD_RESET_ERROR]: {
      206: {
        level: MessageTypes.ERROR,
        message: 'msgError.passwordReset.waitToResend',
      },
    },
    [PasswordResetActionType.CONFIRM_PASSWORD_RESET_ERROR]: {
      205: {
        level: MessageTypes.ERROR,
        message: 'msgError.passwordReset.invalidOrExpired',
      },
    },
    [ProfileActionType.CHANGE_PASSWORD_ERROR]: {
      202: {
        level: MessageTypes.ERROR,
        message: 'msgError.changePassword',
      },
    },
    [TransacionesActionType.GET_PAYMENT_METHODS_FOR_TRANSACTIONS_ERROR]: {
      default: {
        level: MessageTypes.ERROR,
        message: 'genericError.message',
      },
    },
    [RefundsActionType.GET_PAYMENT_METHODS_FOR_REFUNDS_ERROR]: {
      default: {
        level: MessageTypes.ERROR,
        message: 'genericError.message',
      },
    },
  };
};
