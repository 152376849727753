export enum PaymentMethodsActionType {
  GET_SELECTED_PAYMENT_METHOD = 'PAYMENT_METHODS/GET_SELECTED_PAYMENT_METHOD',
  GET_SELECTED_PAYMENT_METHOD_SUCCESS = 'PAYMENT_METHODS/GET_SELECTED_PAYMENT_METHOD_SUCCESS',
  GET_SELECTED_PAYMENT_METHOD_ERROR = 'PAYMENT_METHODS/GET_SELECTED_PAYMENT_METHOD_ERROR',
  GET_ALL_PAYMENT_METHODS = 'PAYMENT_METHODS/GET_ALL_PAYMENT_METHODS',
  GET_ALL_PAYMENT_METHODS_SUCCESS = 'PAYMENT_METHODS/GET_ALL_PAYMENT_METHODS_SUCCESS',
  GET_ALL_PAYMENT_METHODS_ERROR = 'PAYMENT_METHODS/GET_ALL_PAYMENT_METHODS_ERROR',
  ADD_PAYMENT_METHOD = 'PAYMENT_METHODS/ADD_PAYMENT_METHOD',
  ADD_PAYMENT_METHOD_SUCCESS = 'PAYMENT_METHODS/ADD_PAYMENT_METHOD_SUCCESS',
  ADD_PAYMENT_METHOD_ERROR = 'PAYMENT_METHODS/ADD_PAYMENT_METHOD_ERROR',
  DELETE_PAYMENT_METHOD = 'PAYMENT_METHODS/DELETE_PAYMENT_METHOD',
  DELETE_PAYMENT_METHOD_SUCCESS = 'PAYMENT_METHODS/DELETE_PAYMENT_METHOD_SUCCESS',
  DELETE_PAYMENT_METHOD_ERROR = 'PAYMENT_METHODS/DELETE_PAYMENT_METHOD_ERROR',
  EDIT_COVERAGE = 'PAYMENT_METHODS/EDIT_COVERAGE',
  SAVE_COVERAGE = 'PAYMENT_METHODS/SAVE_COVERAGE',
  SAVE_COVERAGE_SUCCESS = 'PAYMENT_METHODS/SAVE_COVERAGE_SUCCESS',
  SAVE_COVERAGE_ERROR = 'PAYMENT_METHODS/SAVE_COVERAGE_ERROR',
}

export const getSelectedPaymentMethods = (response: any) => ({
  type: PaymentMethodsActionType.GET_SELECTED_PAYMENT_METHOD,
  response,
});

export const getSelectedPaymentMethodsSuccess = (response: any) => ({
  type: PaymentMethodsActionType.GET_SELECTED_PAYMENT_METHOD_SUCCESS,
  response,
});

export const getSelectedPaymentMethodsError = (error: any) => ({
  type: PaymentMethodsActionType.GET_SELECTED_PAYMENT_METHOD_ERROR,
  error,
});

export const getAllPaymentMethods = (coutryFilter: string, page: number) => ({
  type: PaymentMethodsActionType.GET_ALL_PAYMENT_METHODS,
  coutryFilter,
  page,
});

export const getAllPaymentMethodsSuccess = (response: any) => ({
  type: PaymentMethodsActionType.GET_ALL_PAYMENT_METHODS_SUCCESS,
  response,
});

export const getAllPaymentMethodsError = (error: any) => ({
  type: PaymentMethodsActionType.GET_ALL_PAYMENT_METHODS_ERROR,
  error,
});

export const postAddPaymentMethod = (paymentMethodIds: number[]) => ({
  type: PaymentMethodsActionType.ADD_PAYMENT_METHOD,
  paymentMethodIds,
});

export const postAddPaymentMethodSuccess = (response: any) => ({
  type: PaymentMethodsActionType.ADD_PAYMENT_METHOD_SUCCESS,
  response,
});

export const postAddPaymentMethodError = (error: any) => ({
  type: PaymentMethodsActionType.ADD_PAYMENT_METHOD_ERROR,
  error,
});

export const deletePaymentMethod = (paymentMethodIds: number[]) => ({
  type: PaymentMethodsActionType.DELETE_PAYMENT_METHOD,
  paymentMethodIds,
});

export const deletePaymentMethodSuccess = (response: any) => ({
  type: PaymentMethodsActionType.DELETE_PAYMENT_METHOD_SUCCESS,
  response,
});

export const deletePaymentMethodError = (error: any) => ({
  type: PaymentMethodsActionType.DELETE_PAYMENT_METHOD_ERROR,
  error,
});

export const editCoverage = (show: boolean) => ({
  type: PaymentMethodsActionType.EDIT_COVERAGE,
  show,
});

export const saveCoverage = (currentSelection: string[], newSelection: string[]) => ({
  type: PaymentMethodsActionType.SAVE_COVERAGE,
  currentSelection,
  newSelection,
});

export const saveCoverageSuccess = (response: any) => ({
  type: PaymentMethodsActionType.SAVE_COVERAGE_SUCCESS,
  response,
});

export const saveCoverageError = (error: any) => ({
  type: PaymentMethodsActionType.SAVE_COVERAGE_ERROR,
  error,
});
