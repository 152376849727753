import { call, put, takeLatest, select } from 'redux-saga/effects';
import { Urls } from '../../config/urls';
import { getTask, postTask } from '../../services';
import {
  PaymentMethodsActionType,
  getSelectedPaymentMethodsSuccess,
  getSelectedPaymentMethodsError,
  getAllPaymentMethodsSuccess,
  getAllPaymentMethodsError,
  postAddPaymentMethodSuccess,
  postAddPaymentMethodError,
  deletePaymentMethodSuccess,
  deletePaymentMethodError,
  saveCoverageSuccess,
  saveCoverageError,
} from './actions';
import { checkToken } from '../auth/sagas';
import { PanelStore } from '../interfaces';

function* getPaymentMethodsSelected() {
  const state: PanelStore.RootState = yield select();
  const { response, error } = yield call(getTask, {
    url: Urls.api.paymentMethods.replace(':id', `${state.dashboard.merchantMe?.id || ''}`),
    token: state.auth.token,
  });
  if (response) {
    yield put(getSelectedPaymentMethodsSuccess(response));
  } else {
    yield put(getSelectedPaymentMethodsError(error));
  }
}

function* initPaymentMethods() {
  yield* checkToken();
  yield* getPaymentMethodsSelected();
}

function* allPaymentMethods(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  let params = '?include_deleted=0';
  const { coutryFilter, page } = action;
  if (coutryFilter) params += `&countries=${coutryFilter}`;
  if (page) params += `&page=${page}`;

  const { response, error } = yield call(getTask, {
    url: Urls.api.allPaymentMethods + params,
    token: state.auth.token,
  });

  if (response) {
    yield put(getAllPaymentMethodsSuccess(response));
  } else {
    yield put(getAllPaymentMethodsError(error));
  }
}

export function* allPaymentMethodsSaga() {
  yield takeLatest(PaymentMethodsActionType.GET_ALL_PAYMENT_METHODS, allPaymentMethods);
}

export function* initPaymentMethodsSaga() {
  yield takeLatest(PaymentMethodsActionType.GET_SELECTED_PAYMENT_METHOD, initPaymentMethods);
}

function* addPaymentMethod(action: any) {
  yield* checkToken();
  const { paymentMethodIds } = action;
  const state: PanelStore.RootState = yield select();
  const { response, error } = yield call(postTask, {
    url: Urls.api.addPaymentMethods,
    body: {
      paymentMethodIds,
    },
    token: state.auth.token,
  });

  if (response) {
    yield getPaymentMethodsSelected();
    yield put(postAddPaymentMethodSuccess(response));
  } else {
    yield put(postAddPaymentMethodError(error));
  }
}

export function* addPaymentMethodSaga() {
  yield takeLatest(PaymentMethodsActionType.ADD_PAYMENT_METHOD, addPaymentMethod);
}

function* deletePaymentMethod(action: any) {
  yield* checkToken();
  const { paymentMethodIds } = action;
  const state: PanelStore.RootState = yield select();
  const { response, error } = yield call(postTask, {
    url: Urls.api.deletePaymentMethods,
    body: {
      paymentMethodIds,
    },
    token: state.auth.token,
  });

  if (response) {
    yield getPaymentMethodsSelected();
    yield put(deletePaymentMethodSuccess(response));
  } else {
    yield put(deletePaymentMethodError(error));
  }
}

export function* deletePaymentMethodSaga() {
  yield takeLatest(PaymentMethodsActionType.DELETE_PAYMENT_METHOD, deletePaymentMethod);
}

function* addCountriesPMs(countries: string[]): any {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();
  return yield call(postTask, {
    url: Urls.api.addCountriesPaymentMethods,
    body: {
      countries,
    },
    token: state.auth.token,
  });
}

function* deleteCountriesPMs(countries: string[]): any {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();
  return yield call(postTask, {
    url: Urls.api.deleteCountriesPaymentMethods,
    body: {
      countries,
    },
    token: state.auth.token,
  });
}

function* saveCoverage(action: any) {
  yield* checkToken();

  const { currentSelection, newSelection } = action;

  const countriesToAdd = newSelection.filter((c: string) => !currentSelection.includes(c));
  const countriesToDelete = currentSelection.filter((c: string) => !newSelection.includes(c));

  let err;
  if (countriesToAdd.length && !err) {
    const { error } = yield addCountriesPMs(countriesToAdd);
    if (error) {
      err = error;
    }
  }

  if (countriesToDelete.length && !err) {
    const { error } = yield deleteCountriesPMs(countriesToDelete);
    if (error) {
      err = error;
    }
  }

  if (err) {
    yield put(saveCoverageError(err));
  } else {
    yield put(saveCoverageSuccess({}));
  }
}

export function* saveCoverageSaga() {
  yield takeLatest(PaymentMethodsActionType.SAVE_COVERAGE, saveCoverage);
}
