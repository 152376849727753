import { FunctionComponent, ReactNode } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  tooltip: {
    color: '#454F5B',
    backgroundColor: '#fff',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    border: '1px solid #ECECEC',
    boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.04)',
    borderRadius: '4px',
  },
  iconButton: {
    '&.--reduced-padding': {
      padding: 2,
    },
  },
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: '#ffffff',
  },
}));

interface TooltipIconProps {
  text: NonNullable<ReactNode>;
  icon: ReactNode;
  reducedPadding?: boolean;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}
const TooltipIcon: FunctionComponent<TooltipIconProps> = ({ text, icon, reducedPadding = false, placement = 'top' }) => {
  const classes = useStyles();
  return (
    <Tooltip title={text} classes={classes} placement={placement} arrow enterTouchDelay={0}>
      <IconButton className={`${classes.iconButton} ${reducedPadding ? '--reduced-padding' : ''}`}>{icon}</IconButton>
    </Tooltip>
  );
};

export default TooltipIcon;
