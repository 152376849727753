import { FunctionComponent, ReactElement } from 'react';
import Parser from 'html-react-parser';
import l from '../../../../../../lang';
import CardStep from '..';
import { StatusKYCStepTwo } from '../../enums/StatusKYCStepTwo';
import { CardStyle } from '../../enums/CardStyle';
import ContentStep from '../../content-steps';
import ButtonCustom from '../../../../../common/__deprecated__/button-custom';
import { segmentKycStep2CompleteB } from '../../../../../../utils/analytics';
import { useDashboard } from '../../../../../../store/dashboard';
import { MerchantFeatures } from '../../../../../../constants';
import { HasFeatureFunction, useFeature } from '../../../../../../hooks/use-features';
import { StatusKYCStepOne } from '../../enums/StatusKYCStepOne';
import { companyOrPhysicalLabel, countryLabel, physicalLabel } from '../../../kyc-levels/config/labels';

interface CardProps {
  cardStyle: CardStyle;
  tag: string;
  headline: string;
  subheadline: string | JSX.Element | JSX.Element[];
  content: string | JSX.Element | JSX.Element[];
  bottomElement: ReactElement;
  disabled: boolean;
}

const getCardPropsFromStatusKYCStepTwo = (
  statusKycStepTwo: StatusKYCStepTwo,
  statusKycStepOne: StatusKYCStepOne,
  isBlocked: boolean,
  hanlderOnClickTwo: () => void,
  hasFeature: HasFeatureFunction,
  company: boolean,
  country: string,
  documentType: string
): CardProps => {
  let cardStyle = CardStyle.WARNING;
  let tag = '';
  let headline = '';
  let subheadline: string | JSX.Element | JSX.Element[] = '';
  let content:
    | string
    | JSX.Element
    | JSX.Element[]
    | React.DetailedReactHTMLElement<{}, HTMLElement>
    | Array<React.DetailedReactHTMLElement<{}, HTMLElement>> = '';
  let bottomElement = <></>;
  let disabled = true;

  switch (statusKycStepTwo) {
    case StatusKYCStepTwo.REJECTED_BY_WEBSITE:
      cardStyle = CardStyle.DISABLE;
      tag = l('companyInformation.steps.tag.pendingApproval');
      headline = l('companyInformation.step2.headline.pendingApproval');
      subheadline = l('companyInformation.step2.subheadline.pendingApproval');
      content = Parser(l('companyInformation.step2.content.pendingApproval'));
      bottomElement = <div className="info_steps__disabled-line" />;
      break;
    case StatusKYCStepTwo.REJECTED_BY_INDUSTRY:
      cardStyle = CardStyle.DISABLE;
      tag = l('companyInformation.steps.tag.pendingApproval');
      headline = l('companyInformation.step2.headline.pendingApproval');
      subheadline = l('companyInformation.step2.subheadline.pendingApproval');
      content = '';
      bottomElement = <div className="info_steps__disabled-line" />;
      break;
    case StatusKYCStepTwo.COMPLETED:
      cardStyle = CardStyle.SUCCESS;
      tag = l('companyInformation.steps.tag.completed');
      headline = l('companyInformation.step2.headline.completed');
      subheadline = l('companyInformation.step2.subheadline.completed');
      content = Parser(l('companyInformation.step2.content.completed'));
      bottomElement = <div className="info_steps__pending-line" />;
      break;
    case StatusKYCStepTwo.REJECTED_BY_DOCUMENTATION:
    case StatusKYCStepTwo.ENABLED:
      cardStyle = CardStyle.MAIN;
      tag = l('companyInformation.steps.tag.complete');
      headline = l('companyInformation.step2.headline.complete');
      subheadline = l('companyInformation.step2.subheadline.complete');

      if (hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)) {
        subheadline =
          statusKycStepOne === StatusKYCStepOne.COMPLETED
            ? Parser(
                l(
                  companyOrPhysicalLabel(`companyInformation.step2.subheadline.completeFields`, company),
                  l(countryLabel(physicalLabel(`companyInformation.kycTwo.taxCompliantDocument`, company), country)),
                  l(countryLabel('companyInformation.kycTwo.shareholdersDocumentHelp', country)),
                  documentType
                )
              )
            : Parser(l('companyInformation.step2.subheadline.complete.company/physical'));
      }

      content = Parser(l('companyInformation.step2.content.complete'));
      bottomElement = !isBlocked ? (
        <ButtonCustom
          size="small-full"
          text={l('companyInformation.step2.button.complete')}
          onClick={hanlderOnClickTwo}
          component={undefined}
          innerComponent={undefined}
          fullWidth={undefined}
        />
      ) : (
        <div className="info_steps__disabled-line" />
      );
      disabled = isBlocked;
      break;
    case StatusKYCStepTwo.PENDING:
      cardStyle = CardStyle.DISABLE;
      tag = l('companyInformation.steps.tag.pending');
      headline = l('companyInformation.step2.headline.complete');
      subheadline = hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE)
        ? l(`companyInformation.step2.subheadline.complete.company/physical`)
        : l('companyInformation.step2.subheadline.complete');

      content = Parser(l('companyInformation.step2.content.complete'));
      bottomElement = <div className="info_steps__disabled-line" />;
      break;
    case StatusKYCStepTwo.PENDING_APPROVAL:
      cardStyle = CardStyle.WARNING;
      tag = l('companyInformation.steps.tag.pendingApproval');
      headline = l('companyInformation.step2.headline.pendingApproval');
      subheadline = l('companyInformation.step2.subheadline.pendingApproval');
      content = Parser(l('companyInformation.step2.content.pendingApproval'));
      bottomElement = <div className="info_steps__pending-line" />;
      break;
  }

  return {
    tag,
    headline,
    cardStyle,
    subheadline,
    content,
    bottomElement,
    disabled,
  };
};

interface CardStepProps {
  statusKycStep2: StatusKYCStepTwo;
  statusKycStepOne: StatusKYCStepOne;
  onClick: (step: number) => void;
  limitStepOne: number;
}

export const CardStepTwo: FunctionComponent<CardStepProps> = ({ statusKycStep2, statusKycStepOne, onClick }) => {
  const onClickTwo = (segmentOption: number) => {
    segmentKycStep2CompleteB(segmentOption);
    onClick(2);
  };
  const { userMe, merchantMe } = useDashboard();
  const { hasFeature } = useFeature();

  const { blacklisted, enabled } = userMe || {};
  const { company, country, documentType } = merchantMe || {};

  const stepOneCardProps = getCardPropsFromStatusKYCStepTwo(
    statusKycStep2,
    statusKycStepOne,
    blacklisted || !enabled,
    () => onClickTwo(2),
    hasFeature,
    company as boolean,
    country as string,
    documentType as string
  );

  return (
    <CardStep
      cardStyle={stepOneCardProps.cardStyle}
      title={l('companyInformation.step2')}
      onClick={() => onClickTwo(2)}
      tag={stepOneCardProps.tag}
      disabled={stepOneCardProps.disabled}
    >
      {statusKycStepOne !== StatusKYCStepOne.ENABLED && (
        <ContentStep
          headline={stepOneCardProps.headline}
          subheadline={stepOneCardProps.subheadline}
          content={stepOneCardProps.content}
          bottomElement={stepOneCardProps.bottomElement}
        />
      )}
    </CardStep>
  );
};
