import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getMissingDocuments, sendKYC, changeShowUpLevel } from '../../../../../store/company-information/actions';
import SectionDocs from './section-docs';
import Loading from '../../../../common/loading';
import { ItemOnboardingMenu } from '../../../onboarding-menu';
import AddBankAccountOnboarding from '../../../bank-accounts/add-bank-account-forms/add-bank-account-onboarding';
import './styles.scss';

interface KYCLevel2State {
  companyInformation: {
    loading: boolean;
    error: boolean;
    errorInfo: object;
    missingDocuments: any;
    success: boolean;
  };
  dashboard: {
    merchantMe: {
      name: string;
      lastName: string;
      company: any;
      country: string;
      documentType: string;
    };
  };
}

interface KYCLevel2StateProps {
  loading: boolean;
  error: boolean;
  errorInfo: object;
  missingDocuments: any;
  isCompany: boolean;
  companySuccess: boolean;
  country: string;
  name: string;
  lastName: string;
  documentType: string;
}

interface KYCLevel2Dispatch {
  getMissingDocuments: () => void;
  sendData: (values: any) => void;
  changeShowUpLevel: (show: boolean) => void;
}

interface KYCLevel2Props extends KYCLevel2StateProps, KYCLevel2Dispatch {
  setOnboardingMenu: (menu: ItemOnboardingMenu[]) => void;
  changeShowUpLevel: (show: boolean) => void;
}

const KYCLevel2: FC<KYCLevel2Props> = ({
  loading,
  error,
  errorInfo,
  isCompany,
  companySuccess,
  missingDocuments,
  country,
  getMissingDocuments,
  sendData,
  setOnboardingMenu,
  changeShowUpLevel,
  name,
  lastName,
  documentType,
}): JSX.Element => {
  const [step, setStep] = useState(1);

  const [menuSectionOne, setMenuSectionOne] = useState<ItemOnboardingMenu[]>([]);
  const [menuSectionTwo, setMenuSectionTwo] = useState<ItemOnboardingMenu[]>([]);

  useEffect(() => {
    getMissingDocuments();
  }, []);

  useEffect(() => {
    // Skip step 1 if only document from step 2 is missing.
    if (missingDocuments && missingDocuments.length === 1 && missingDocuments.includes('bankAccountDocument')) {
      setStep(2);
    }
  }, [missingDocuments]);

  useEffect(() => {
    setOnboardingMenu(menuSectionOne.concat(menuSectionTwo));
  }, [menuSectionOne, menuSectionTwo]);

  useEffect(() => {
    if (companySuccess) {
      changeShowUpLevel(false);
    }
  }, [companySuccess]);

  return (
    <div className="kyc-level-2">
      {loading && <Loading fullScreen />}
      <div style={{ display: step === 1 ? 'block' : 'none' }}>
        <AddBankAccountOnboarding
          displayed={step === 1}
          onChangeMenu={(menu: ItemOnboardingMenu[]) => setMenuSectionOne(menu)}
          onClickNext={() => setStep(2)}
        />
      </div>
      <div style={{ display: step === 2 ? 'block' : 'none' }}>
        <SectionDocs
          error={error}
          errorInfo={errorInfo}
          displayed={step === 2}
          isCompany={isCompany}
          missingDocuments={missingDocuments}
          onClickNext={values => sendData({ ...values })}
          onChangeMenu={(menu: ItemOnboardingMenu[]) => setMenuSectionTwo(menu)}
          country={country}
          name={name}
          lastName={lastName}
          documentType={documentType}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: KYCLevel2State): KYCLevel2StateProps => ({
  loading: state.companyInformation.loading,
  error: state.companyInformation.error,
  errorInfo: state.companyInformation.errorInfo,
  missingDocuments: state.companyInformation.missingDocuments,
  isCompany: state.dashboard.merchantMe?.company,
  name: state.dashboard.merchantMe?.name,
  lastName: state.dashboard.merchantMe?.lastName,
  documentType: state.dashboard.merchantMe?.documentType,
  companySuccess: state.companyInformation?.success,
  country: state.dashboard.merchantMe?.country,
});

const mapDispatchToProps = (dispatch: Function): KYCLevel2Dispatch => ({
  getMissingDocuments: () => dispatch(getMissingDocuments()),
  sendData: (values: any) => dispatch(sendKYC(values, 4)),
  changeShowUpLevel: show => dispatch(changeShowUpLevel(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KYCLevel2);
