import './styles.scss';
import { Button } from '../button';
import l from '../../../lang';

interface CurrencySelectorProps {
  disabled: boolean;
  firstCurrency: string;
  secondCurrency: string;
  value: string;
  onChange: (newValue: string) => void;
}

const CurrencySelector = ({ disabled, firstCurrency, secondCurrency, value, onChange }: CurrencySelectorProps) => {
  return (
    <>
      <div className="currency_selector">
        <div className="currency_selector__title">{l('currencySelector.title')}</div>
        <div className="currency_selector__btn_currency">
          <Button
            className={`currency_selector__btn_currency__left ${
              value === firstCurrency ? 'currency_selector__btn_currency__left__selected' : ''
            }`}
            variant="outlined"
            onClick={() => onChange(firstCurrency)}
            disabled={disabled || !firstCurrency}
          >
            {firstCurrency || '***'}
          </Button>
          <Button
            className={`currency_selector__btn_currency__right ${
              value === secondCurrency ? 'currency_selector__btn_currency__right__selected' : ''
            }`}
            variant="outlined"
            onClick={() => onChange(secondCurrency)}
            disabled={disabled || !secondCurrency}
          >
            {secondCurrency || '***'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CurrencySelector;
