import { FunctionComponent } from 'react';
import Parser from 'html-react-parser';
import l from '../../../lang';
import { DialogConfirm, CardConfirm } from '../../common/confirm';

interface DialogLeaveOnboardingProps {
  onClose: (confirm: boolean) => void;
}
const DialogLeaveOnboarding: FunctionComponent<DialogLeaveOnboardingProps> = ({ onClose }) => (
  <DialogConfirm onClose={onClose}>
    <CardConfirm
      onClose={onClose}
      headline={Parser(l('companyInformation.message.confirm.leave.headline'))}
      description={Parser(l('companyInformation.message.confirm.leave.subheadline'))}
      cancelChildren={l('companyInformation.message.confirm.leave.cancel.button')}
      confirmChildren={l('companyInformation.message.confirm.leave.confirmed.button')}
      level={'warning'}
    />
  </DialogConfirm>
);

export default DialogLeaveOnboarding;
