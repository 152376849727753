import React from 'react';
import { Link } from 'react-router-dom';
import { HTTPCodes } from '../../constants';
import { Routes } from '../../config/routes';
import baseNotFoundImg from '../../assets/images/baseNotFoundImg.svg?url';
import l from '../../lang';
import './styles.scss';

const NotFound = () => (
  <div className="not-found">
    <h1 className="not-found-code">{HTTPCodes.NOT_FOUND}</h1>
    <div className="not-found-portal">
      <img src={baseNotFoundImg} alt="" className="not-found-image" />
      <div className="circle-1" />
      <div className="circle-2" />
      <div className="circle-3" />
      <div className="circle-4" />
      <div className="circle-5" />
    </div>
    <div className="not-found-title">{l('notFound.title')}</div>
    <p className="not-found-subtitle">{l('notFound.subtitle')}</p>
    <div className="not-found-buttons">
      <Link to={Routes.HOME} className="btn">
        {l('notFound.GoHome.btn')}
      </Link>
    </div>
  </div>
);

export default NotFound;
