import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  link: {
    fontStyle: 'normal',
    fontWeight: 500,
    verticalAlign: 'initial',
    fontSize: '1em',
    '&.primary': {
      color: theme.palette.primary.main,
    },
    '&.secondary': {
      color: '#ffffff',
    },
  },
}));

const ButtonLink = ({ type = 'primary', text, disabled, onClick }) => {
  const classes = useStyles();
  return (
    <Link className={`${classes.link} ${type}`} component="button" type="button" onClick={onClick} disabled={disabled}>
      {!!text && text}
    </Link>
  );
};

ButtonLink.propTypes = {
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ButtonLink;
