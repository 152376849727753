import { useDispatch, useSelector } from 'react-redux';
import { Values } from '../interfaces';
import {
  getDocumentTypesForKYC,
  getIndustries,
  sendKYC,
  changeShowUpLevel,
  showUploadLogo,
  deleteLogo,
  uploadNewLogo,
  cleanSuccess,
  getKycLevels,
  getMissingDocuments,
  setDisplayMessageKycStep1 as setDisplayMessageKycStep1Action,
} from './actions';
import { CompanyInformationSelectors } from './selectors';
import { useCallback } from 'react';

export const useCompanyInfo = () => {
  const dispatch = useDispatch();

  const setDisplayMessageKycStep1 = useCallback(
    (displayed: boolean) => dispatch(setDisplayMessageKycStep1Action(displayed)),
    [dispatch]
  );

  return {
    loading: useSelector(CompanyInformationSelectors.getLoading),
    error: useSelector(CompanyInformationSelectors.getError),
    errorInfo: useSelector(CompanyInformationSelectors.getErrorInfo),
    errorFields: useSelector(CompanyInformationSelectors.getErrorFields),
    industries: useSelector(CompanyInformationSelectors.getIndustries),
    documentTypesForCompany: useSelector(CompanyInformationSelectors.getDocumentTypesForCompany),
    documentTypesForNaturalPerson: useSelector(CompanyInformationSelectors.getDocumentTypesForNaturalPerson),
    success: useSelector(CompanyInformationSelectors.getSuccess),
    showUpLevel: useSelector(CompanyInformationSelectors.getShowUpLevel),
    missingDocuments: useSelector(CompanyInformationSelectors.getMissingDocuments),
    uploadLogo: useSelector(CompanyInformationSelectors.getUploadLogo),
    allKycLevels: useSelector(CompanyInformationSelectors.getAllKycLevels),
    displayMessageKycStep1: useSelector(CompanyInformationSelectors.getDisplayMessageKycStep1),
    setDisplayMessageKycStep1,
    sendKYC: (values: Values, kycLevel: number) => dispatch(sendKYC(values, kycLevel)),
    getDocumentTypesForKYC: (country: string) => dispatch(getDocumentTypesForKYC(country)),
    getIndustries: () => dispatch(getIndustries()),
    showUploadLogo: (show: boolean) => dispatch(showUploadLogo(show)),
    changeShowUpLevel: (show: boolean) => dispatch(changeShowUpLevel(show)),
    deleteLogo: () => dispatch(deleteLogo()),
    uploadNewLogo: (logo: any) => dispatch(uploadNewLogo(logo)),
    cleanSuccess: () => dispatch(cleanSuccess()),
    getKycLevels: () => dispatch(getKycLevels()),
    getMissingDocuments: () => dispatch(getMissingDocuments()),
  };
};
