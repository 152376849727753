import { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { ReactComponent as FileUploadIcon } from '../../../assets/icons/file-upload.svg';
import { ReactComponent as FileErrorIcon } from '../../../assets/icons/file-error.svg';
import { ReactComponent as FileCheckIcon } from '../../../assets/icons/file-check.svg';
import { ReactComponent as CancelOutlineIcon } from '../../../assets/icons/cancel-outlined.svg';
import ButtonCustom from '../__deprecated__/button-custom';
import l from '../../../lang';

import './styles.scss';

interface UploadFileProps {
  title: string;
  description?: string | JSX.Element | JSX.Element[];
  supportedExtensions: string[];
  maxSize: number;
  disabled?: boolean;
  externalError?: string;
  onChange: (params: { file: File | null; base64: string | null }) => void;
}

const UploadFile: React.FunctionComponent<UploadFileProps> = ({
  title,
  supportedExtensions,
  maxSize,
  disabled,
  externalError,
  onChange,
  description,
  children,
}) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (externalError && !error) {
      setError(externalError);
    }
  }, [externalError]);

  const toBase64 = (f: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = err => reject(err);
    });

  const handleOnChange = async (files: FileList | null) => {
    setLoading(true);
    setError('');
    setFile(null);
    onChange({ file: null, base64: null });

    const f = files && files[0];
    if (f) {
      const fileExt = /[^.]+$/.exec(`${f.name}`.toLowerCase());
      if (f.size && f.size / 1e6 > maxSize) {
        setError(l('uploadFile.sizeExceeds'));
      } else if (supportedExtensions.filter(ext => ext === (fileExt && fileExt[0])).length === 0) {
        setError(l('uploadFile.notSupported'));
      } else {
        const result = await toBase64(f).catch(e => Error(e));
        if (typeof result === 'string') {
          setFile(f);
          onChange({ base64: result, file: f });
        } else {
          setError(l('uploadFile.error'));
          Bugsnag.notify(new Error('Error to convert the file in base64.'));
        }
      }
    } else {
      setError(l('uploadFile.error'));
    }
    setLoading(false);
  };

  const handleRemove = () => {
    setError('');
    setFile(null);
    setInputValue('');
    onChange({ file: null, base64: null });
  };

  return (
    <div className="upload-file">
      <div className={`upload-file_content ${error ? 'upload-file_content_error' : ''}`}>
        <div className="upload-file_content_box">
          <div className="upload-file_content_icon">
            {error ? <FileErrorIcon /> : file ? <FileCheckIcon /> : <FileUploadIcon />}
          </div>
          {file ? (
            <div className="upload-file_content_desc upload-file_content_desc_success">
              {file.name}{' '}
              {file && (
                <div className="upload-file_content_desc_success_clean">
                  <CancelOutlineIcon onClick={() => handleRemove()} />
                </div>
              )}
            </div>
          ) : (
            <div className="upload-file_content_body">
              <div className="upload-file_content_body_title">{title}</div>
              {description && <div className="upload-file_content_body_desc">{description}</div>}
            </div>
          )}
        </div>
        {!file && (
          <div className="upload-file_content_btn">
            <ButtonCustom
              fullWidth
              text={l('uploadFile.label')}
              color="primary"
              variant="outlined"
              size="square"
              disabled={loading || disabled}
              innerComponent={
                <input
                  type="file"
                  value={inputValue}
                  accept={supportedExtensions.map((t, i) => `.${t}${i + 1 > supportedExtensions.length ? ', ' : ''}`).join()}
                  style={{ display: 'none' }}
                  onChange={e => {
                    setInputValue(e.target.value);
                    handleOnChange(e.target.files);
                  }}
                />
              }
              component="label"
            />
          </div>
        )}
        {children}
      </div>
      {error && <div className="upload-file_content_desc upload-file_content_desc_error">{error}</div>}
      {file && <div className="upload-file_content_success_desc">{l('uploadFile.success')}</div>}
    </div>
  );
};

export default UploadFile;
