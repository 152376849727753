import { StatusSession } from '../../constants';
import { handleErrorInfo } from '../../utils';
import { AuthActionType } from './actions';
import { AuthState } from './interfaces';

const initialState: AuthState = {
  loading: false,
  error: false,
  errorInfo: undefined,
  token: undefined,
  tokenExpiration: undefined,
  isAuthenticated: false,
  sessionStatus: StatusSession.UNDEFINED,
  logout: false,
  checkSessionCompleted: false,
  afterLoginSuccess: false,
};

const AuthReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AuthActionType.INIT_LOGIN: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
      };
    }
    case AuthActionType.INIT_CHECK_COMPLETED: {
      return {
        ...state,
        checkSessionCompleted: true,
      };
    }
    case AuthActionType.SENDING_LOGIN: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
      };
    }
    case AuthActionType.LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
        token: action.response.token,
        tokenExpiration: action.response.tokenExpiration,
        isAuthenticated: true,
        sessionStatus: StatusSession.LOGGED_IN,
        afterLoginSuccess: true,
      };
    }
    case AuthActionType.LOGIN_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(AuthActionType.LOGIN_ERROR, action.error),
      };
    }
    case AuthActionType.REFRESH_TOKEN: {
      return {
        ...state,
        token: action.response.token,
        tokenExpiration: action.response.tokenExpiration,
        isAuthenticated: true,
        sessionStatus: StatusSession.LOGGED_IN,
      };
    }
    case AuthActionType.TOKEN_CHECKED: {
      return {
        ...state,
      };
    }
    case AuthActionType.SEND_LOGOUT: {
      return {
        ...state,
        logouting: true,
      };
    }
    case AuthActionType.LOGOUT_SUCCESS:
      return {
        ...state,
        token: undefined,
        tokenExpiration: undefined,
        isAuthenticated: false,
        sessionStatus: StatusSession.LOGGED_OUT,
      };
    case AuthActionType.REFRESH_TOKEN_ERROR:
    case AuthActionType.LOGOUT_ERROR: {
      return {
        ...state,
        token: undefined,
        tokenExpiration: undefined,
        isAuthenticated: false,
        sessionStatus: StatusSession.INVALID,
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;
