import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setShopifyTestMode } from '../../../../store/payment-integration/actions';
import { State } from '../../../../store/state';

export const useTestModeHandler = () => {
  const testMode = useSelector((state: State) => Boolean(state.dashboard.merchantMe?.shopifyReference?.testMode));
  const shopifyIntegrationIsLoading = useSelector((state: State) => state.paymentIntegration.shopifyIntegration.loading);
  const dispatch = useDispatch();

  const [nextTestMode, setNextTestMode] = useState<boolean>(testMode);

  useEffect(() => {
    if (nextTestMode !== testMode) {
      dispatch(setShopifyTestMode(nextTestMode));
    }
  }, [nextTestMode, dispatch]);

  const onTestModeHandler = (next: boolean) => {
    if (!shopifyIntegrationIsLoading) {
      setNextTestMode(next);
    }
  };

  return {
    testMode: shopifyIntegrationIsLoading ? nextTestMode : testMode,
    onTestModeHandler: shopifyIntegrationIsLoading ? undefined : onTestModeHandler,
  };
};
