import l from '../../../../lang';
import DataCard from './data-card';
import './styles.scss';
import { useReports } from '../../../../store/reports';
import { useEffect } from 'react';

const getType = (current: number, previous: number) => {
  if (previous < current) {
    return 'up';
  }
  if (previous > current) {
    return 'down';
  }
  return 'equal';
};

interface DataCardsProps {
  period: number;
}

const DataCards = ({ period }: DataCardsProps) => {
  const { summary, getTransactionsSummary } = useReports();
  const { loading, error, balanceCurrency, tpv, transactionCount, transactionAverageAmount } = summary;

  useEffect(() => {
    getTransactionsSummary(7);
  }, []);

  return (
    <div className="reports_data_cards">
      <DataCard
        loading={loading}
        error={error}
        title={l('reports.card1.headline')}
        value={`${balanceCurrency} ${tpv.current}`}
        comparation={{
          value: `${tpv.diffPercentage}%`,
          description: l('reports.card1.description', balanceCurrency, [tpv.previous]),
          type: getType(tpv.current, tpv.previous),
        }}
        tooltip={l('reports.card1.tooltip')}
        onRetry={() => getTransactionsSummary(period)}
      />
      <DataCard
        loading={loading}
        error={error}
        title={l('reports.card2.headline')}
        value={`${transactionCount.current}`}
        comparation={{
          value: `${transactionCount.diffPercentage}%`.toString(),
          description:
            l('reports.card2.description', [transactionCount.previous]) +
            ' ' +
            (transactionCount.previous === 1 ? l('reports.transactionCount') : l('reports.transactionsCount')),
          type: getType(transactionCount.current, transactionCount.previous),
        }}
        tooltip={l('reports.card2.tooltip')}
        onRetry={() => getTransactionsSummary(period)}
      />
      <DataCard
        loading={loading}
        error={error}
        title={l('reports.card3.headline')}
        value={`${balanceCurrency} ${transactionAverageAmount.current}`}
        comparation={{
          value: `${transactionAverageAmount.diffPercentage}%`,
          description: l('reports.card3.description', balanceCurrency, [transactionAverageAmount.previous]),
          type: getType(transactionAverageAmount.current, transactionAverageAmount.previous),
        }}
        tooltip={l('reports.card3.tooltip')}
        onRetry={() => getTransactionsSummary(period)}
      />
    </div>
  );
};

export default DataCards;
