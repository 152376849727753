import { takeLatest, select, put, call } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { patchTask } from '../../services';
import {
  ProfileActionType,
  savePersonalInfoSuccess,
  savePersonalInfoError,
  changePasswordSuccess,
  changePasswordError,
} from './actions';
import { PanelStore } from '../interfaces';

function* savePersonalInfo(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { name, language, marketingConsent } = action;
  const { response, error } = yield call(patchTask, {
    url: Urls.api.userUpdate,
    body: { name, language, marketingConsent },
    token: state.auth.token,
  });

  if (response) {
    yield put(savePersonalInfoSuccess(response));
  } else {
    yield put(savePersonalInfoError(error));
  }
}

export function* savePersonalInfoSaga() {
  yield takeLatest(ProfileActionType.SAVE_PERSONAL_INFO, savePersonalInfo);
}

function* changePassword(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { oldPassword, newPassword } = action;
  const { response, error } = yield call(patchTask, {
    url: Urls.api.changePassword,
    body: { oldPassword, newPassword },
    token: state.auth.token,
  });

  if (response) {
    yield put(changePasswordSuccess(response));
  } else {
    yield put(changePasswordError(error));
  }
}

export function* changePasswordSaga() {
  yield takeLatest(ProfileActionType.CHANGE_PASSWORD, changePassword);
}
