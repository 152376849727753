import { FunctionComponent, ReactElement } from 'react';
import { useMediaQuery } from '@mui/material';
import CardInfo from '../../../../common/card-info';
import './styles.scss';

interface ContentStepProps {
  headline: string;
  subheadline: string | JSX.Element | JSX.Element[];
  content: string | JSX.Element | JSX.Element[];
  bottomElement: ReactElement;
}
const ContentStep: FunctionComponent<ContentStepProps> = ({ headline, subheadline, content, bottomElement }) => {
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isDesktopLg = useMediaQuery('(min-width:1200px)');
  return (
    <div className="info_steps">
      <CardInfo
        height={`${isDesktopLg ? '220px' : isDesktop ? '250px' : 'auto'}`}
        bottomElement={!content && bottomElement ? bottomElement : undefined}
      >
        <div className="info_steps__title">{headline}</div>
        <div className="info_steps__desc">{subheadline}</div>
      </CardInfo>
      {!!content && (
        <CardInfo height={`${isDesktop || isDesktopLg ? '140px' : 'auto'}`} underhanded bottomElement={bottomElement}>
          <div className="info_steps__desc">{content}</div>
        </CardInfo>
      )}
    </div>
  );
};

export default ContentStep;
