import { BankAccountsActionType } from './actions';
import { handleErrorInfo } from '../../utils';
import { BankAccountsState, Bank } from './interfaces';

const initialState: BankAccountsState = {
  loading: true,
  error: false,
  errorInfo: undefined,
  allBankAccounts: undefined,
  newBankAccount: {
    loading: false,
    error: false,
    errorInfo: undefined,
    banks: [],
    success: false,
  },
  deletedBankAccount: {
    loading: false,
    error: false,
    errorInfo: undefined,
    success: undefined,
  },
};

const BankAccounts = (state = initialState, action: any) => {
  switch (action.type) {
    case BankAccountsActionType.GET_BANK_ACCOUNTS: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: null,
      };
    }
    case BankAccountsActionType.GET_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        allBankAccounts: {
          data: action.response,
        },
      };
    }
    case BankAccountsActionType.GET_BANK_ACCOUNTS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(BankAccountsActionType.GET_BANK_ACCOUNTS_ERROR, action.error),
        allBankAccounts: null,
      };
    }
    case BankAccountsActionType.GET_BANKS_BY_COUNTRY: {
      return {
        ...state,
        newBankAccount: {
          ...state.newBankAccount,
          loading: true,
          error: false,
          errorInfo: undefined,
          success: false,
        },
      };
    }
    case BankAccountsActionType.GET_BANKS_BY_COUNTRY_SUCCESS: {
      const banks: Bank[] = action.response;
      return {
        ...state,
        newBankAccount: {
          ...state.newBankAccount,
          loading: false,
          error: false,
          errorInfo: undefined,
          banks,
          success: false,
        },
      };
    }
    case BankAccountsActionType.GET_BANKS_BY_COUNTRY_ERROR: {
      return {
        ...state,
        newBankAccount: {
          ...state.newBankAccount,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(BankAccountsActionType.GET_BANKS_BY_COUNTRY_ERROR, action.error),
          banks: [],
        },
      };
    }
    case BankAccountsActionType.ADD_BANK_ACCOUNT: {
      return {
        ...state,
        newBankAccount: {
          ...state.newBankAccount,
          loading: true,
          error: false,
          errorInfo: undefined,
          success: false,
        },
      };
    }
    case BankAccountsActionType.ADD_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        newBankAccount: {
          ...state.newBankAccount,
          loading: false,
          error: false,
          errorInfo: undefined,
          success: true,
        },
      };
    }
    case BankAccountsActionType.ADD_BANK_ACCOUNT_ERROR: {
      return {
        ...state,
        newBankAccount: {
          ...state.newBankAccount,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(BankAccountsActionType.ADD_BANK_ACCOUNT_ERROR, action.error),
        },
      };
    }
    case BankAccountsActionType.DELETE_BANK_ACCOUNT: {
      return {
        ...state,
        deletedBankAccount: {
          loading: true,
          error: false,
          errorInfo: undefined,
        },
      };
    }
    case BankAccountsActionType.DELETE_BANK_ACCOUNT_SUCCESS: {
      const { id } = action;
      const data = state.allBankAccounts?.data?.filter(ba => ba.id !== id);
      return {
        ...state,
        allBankAccounts: data,
        deletedBankAccount: {
          loading: false,
          error: false,
          errorInfo: undefined,
          success: true,
        },
      };
    }
    case BankAccountsActionType.DELETE_BANK_ACCOUNT_ERROR: {
      return {
        ...state,
        deletedBankAccount: {
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(BankAccountsActionType.DELETE_BANK_ACCOUNT_ERROR, action.error),
          success: false,
        },
      };
    }
    case BankAccountsActionType.BANK_ACCOUNT_RESET_CHANGES: {
      return {
        ...state,
        newBankAccount: {
          ...state.newBankAccount,
          loading: false,
          error: false,
          errorInfo: undefined,
          success: undefined,
        },
        deletedBankAccount: {
          loading: false,
          error: false,
          errorInfo: undefined,
          success: undefined,
        },
      };
    }
    default:
      return state;
  }
};

export default BankAccounts;
