import { takeLatest, put, call, select } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { getTask, postTask } from '../../services';
import {
  SettlementsActionType,
  getSettlementsSuccess,
  getSettlementsError,
  getBeneficiariesSuccess,
  getBeneficiariesError,
  postRequestSettlementSuccess,
  postRequestSettlementError,
} from './actions';
import { PanelStore } from '../interfaces';

function* getSettlements(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  let params = `?page=${action.page || 0}`;
  params += action.filter ? `&${action.filter}` : '';

  const { response, error } = yield call(getTask, {
    url: Urls.api.allSettlements + params,
    token: state.auth.token,
  });

  if (response) {
    yield put(getSettlementsSuccess(response));
  } else {
    yield put(getSettlementsError(error));
  }
}

export function* getSettlementsSaga() {
  yield takeLatest(SettlementsActionType.GET_SETTLEMENTS, getSettlements);
}

function* getBeneficiaries() {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: Urls.api.allBankAccounts,
    token: state.auth.token,
  });

  if (response) {
    const isAccountEnabled = (beneficiary: any) => beneficiary.status === 'APPROVED';
    yield put(getBeneficiariesSuccess(response.filter(isAccountEnabled)));
  } else {
    yield put(getBeneficiariesError(error));
  }
}

export function* getBeneficiariesSaga() {
  yield takeLatest(SettlementsActionType.GET_BENEFICIARIES, getBeneficiaries);
}

function* requestSettlement(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();
  const { response, error } = yield call(postTask, {
    url: Urls.api.requestSettlement,
    body: {
      merchantBankAccountId: action.beneficiaryId,
    },
    token: state.auth.token,
  });

  if (response) {
    yield put(postRequestSettlementSuccess(response));
  } else {
    yield put(postRequestSettlementError(error));
  }
}

export function* requestSettlementSaga() {
  yield takeLatest(SettlementsActionType.REQUEST_SETTLEMENT, requestSettlement);
}
