import MUITooltip, { TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/system';

const DefaultTooltip = (props: TooltipProps) => {
  const { children, ...others } = props;
  return (
    <MUITooltip arrow {...others}>
      {children}
    </MUITooltip>
  );
};

export const Tooltip = styled(DefaultTooltip)(theme => ({}));
