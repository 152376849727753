import { takeLatest, put, call, select } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { getCSVTask, getTask, getXlsxTask } from '../../services';
import {
  TransacionesActionType,
  getTransactionsSuccess,
  getTransactionsError,
  getPaymentMethodsForTransSuccess,
  getPaymentMethodsForTransError,
  exportTransactionsSuccess,
  exportTransactionsError,
  getTransactionDetailSuccess,
  getTransactionDetailError,
  getPreChargebackFormSuccess,
  getPreChargebackFormError,
} from './actions';
import { ExportType, PanelStore } from '../interfaces';
import _ from 'lodash';

function* getTransactions(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  let params = `?page=${action.page || 0}`;

  const filters = action.filter;
  let filterParam = '';
  if (filters) {
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        filterParam += filterParam ? '&' : '';
        filterParam += `${_.snakeCase(key)}=${filters[key]}`;
      }
    });
  }

  params += filterParam ? `&${filterParam}` : '';

  const { response, error } = yield call(getTask, {
    url: Urls.api.allTransactions + params,
    token: state.auth.token,
  });

  if (response) {
    yield put(getTransactionsSuccess(response));
  } else {
    yield put(getTransactionsError(error));
  }
}

export function* getTransactionsSaga() {
  yield takeLatest(TransacionesActionType.GET_TRANSACTIONS, getTransactions);
}

function* getTransactionPreChargebackForm(action?: { invoiceId: string }) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const merchantId = String(state.dashboard.merchantMe?.id);
  const preChargebackFormUrl = new URL(Urls.api.preChargebackForm);
  preChargebackFormUrl.searchParams.append('invoice_id', action?.invoiceId ?? '');
  preChargebackFormUrl.searchParams.append('merchant_id', merchantId ?? '');

  const { response, error } = yield call(getTask, {
    url: preChargebackFormUrl.toString(),
    token: state.auth.token,
  });

  if (response) {
    yield put(getPreChargebackFormSuccess(response));
  } else {
    yield put(getPreChargebackFormError(error));
  }
}

export function* getPreChargebackFormSaga() {
  yield takeLatest(TransacionesActionType.GET_PRE_CHARGEBACK_FORM, getTransactionPreChargebackForm);
}

function* getTransactionDetail(action?: { invoiceId: string }) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.allTransactions}?transaction_id=${action?.invoiceId}`,
    token: state.auth.token,
  });

  if (response && response.data?.length > 0) {
    yield put(getTransactionDetailSuccess(response.data[0]));
    yield put({ type: TransacionesActionType.GET_PRE_CHARGEBACK_FORM, invoiceId: action?.invoiceId });
  } else {
    yield put(getTransactionDetailError(error));
  }
}

export function* getTransactionDetailSaga() {
  yield takeLatest(TransacionesActionType.GET_TRANSACTION_DETAIL, getTransactionDetail);
}

function* getPaymentMethodsForTrans(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.allPaymentMethods}?include_deleted=1&page_size=50&country=${action.country}`,
    token: state.auth.token,
  });

  if (response) {
    yield put(getPaymentMethodsForTransSuccess(response));
  } else {
    yield put(getPaymentMethodsForTransError(error));
  }
}

export function* getPaymentMethodsForTransSaga() {
  yield takeLatest(TransacionesActionType.GET_PAYMENT_METHODS_FOR_TRANSACTIONS, getPaymentMethodsForTrans);
}

function* exportTransactions(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const params = action.filter ? `?${action.filter}` : '';
  const exportType: ExportType = action.exportType;

  const { task, url }: { task: typeof getCSVTask; url: string } = {
    [ExportType.CSV]: {
      task: getCSVTask,
      url: Urls.api.exportTransactions,
    },
    [ExportType.XLSX]: {
      task: getXlsxTask,
      url: Urls.api.exportTransactionsExcel,
    },
  }[exportType];

  const { response, error } = yield call(task, {
    url: url + params,
    token: state.auth.token,
  });

  if (response) {
    yield put(exportTransactionsSuccess(response));
  } else {
    yield put(exportTransactionsError(error));
  }
}

export function* exportTransactionsSaga() {
  yield takeLatest(TransacionesActionType.EXPORT_TRANSACTIONS, exportTransactions);
}
