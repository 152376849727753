import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Parser from 'html-react-parser';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SignUpForm from '../../components/sign-up-form';
import { sendSignUp } from '../../store/sign-up/actions';
import LanguageSelect from '../../components/language-select';
import { languages, getLanguage } from '../../utils/index';
import Logo from '../../components/common/logo';
import { Routes } from '../../config/routes';
import l from '../../lang';
import './styles.scss';
import HelpButton from '../../components/common/help-button';
import icEmailCheck from '../../assets/images/emailCheck.svg?url';
import Loading from '../../components/common/loading';
import config from '../../config';
import useMerchantReferenceParams from '../../hooks/use-merchant-reference-param';
import { useCommon } from '../../store/common';

const SignUpPlugin = ({ loading, error, errorInfo, success, onSendSignUp }) => {
  const { changeLanguage } = useCommon();
  const merchantReference = useMerchantReferenceParams();
  const [emailEntered, setEmailEntered] = useState('');
  const handleSendSignUp = values => {
    setEmailEntered(values.email);
    onSendSignUp({ ...values, merchantReference });
  };

  return (
    <div className="sign-up">
      <Grid container className="sign-up-boxes" alignItems="center" justify="center">
        <Grid item xs={12} md={6}>
          <div className="panel sign-up-container">
            {loading && <Loading fullScreen />}
            {!success && <SignUpForm loading={loading} error={error} errorInfo={errorInfo} onSubmit={handleSendSignUp} />}
            {success && config.emailValidationOnSignUp && (
              <div className="sign-up-container__email-sent">
                <img src={icEmailCheck} className="sign-up-container__email-sent__img" alt="ic-email-sent" />
                <Typography variant="h5">{Parser(l('signUp.emailSent.message.title'))}</Typography>
                <Typography variant="body1">{Parser(l('signUp.emailSent.message.desc', emailEntered))}</Typography>
              </div>
            )}
            {success && !config.emailValidationOnSignUp && <Redirect to={Routes.LOGIN} />}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignUpPlugin.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  success: PropTypes.bool,
  onSendSignUp: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.signUp.loading,
  error: state.signUp.error,
  errorInfo: state.signUp.errorInfo,
  success: state.signUp.success,
});

const mapDispatchToProps = dispatch => ({
  onSendSignUp: values => dispatch(sendSignUp(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPlugin);
