import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { ItemOnboardingMenu } from '../../../onboarding-menu';
import { bankFormFields } from '../add-bank-form';
import l from '../../../../../lang';

interface FormObserverProps {
  displayed: boolean;
  areBankAccountsAdded: boolean;
  missingDocuments: string[] | null;
  hideFields?: string[];
  onChangeOnboardingMenu: (menu: ItemOnboardingMenu[]) => void;
}

export const FormObserver = ({
  displayed,
  areBankAccountsAdded,
  missingDocuments,
  hideFields,
  onChangeOnboardingMenu,
}: FormObserverProps) => {
  const { values } = useFormikContext<bankFormFields>();
  useEffect(() => {
    const {
      bankCode,
      bankName,
      accountType,
      bankAccount,
      bankAccountConfirm,
      bankBranch,
      beneficiaryAddress,
      beneficiaryCity,
      beneficiaryPhone,
    } = values;

    const bankAccountInfoCompeted =
      !!bankCode &&
      !!bankName &&
      !!bankAccount &&
      !!bankAccountConfirm &&
      !!bankBranch &&
      (!hideFields?.includes('accountType') ? !!accountType : true) &&
      (!hideFields?.includes('beneficiaryAddress') ? !!beneficiaryAddress : true) &&
      (!hideFields?.includes('beneficiaryCity') ? !!beneficiaryCity : true);
    !hideFields?.includes('beneficiaryPhone') ? !!beneficiaryPhone : true;

    const bankAccountInfoSelected = displayed && !bankAccountInfoCompeted;

    onChangeOnboardingMenu([
      { title: l('companyInformation.kycTwoMenu.introduction'), completed: true, selected: false },
      {
        title: l('companyInformation.kycTwoMenu.bankAccount'),
        subItems: [
          {
            title: l('companyInformation.kycTwo.companyOwnerLegalInformation'),
            completed: true,
            selected: false,
          },
          {
            title: l('companyInformation.kycTwo.bankAccountInformation'),
            completed: bankAccountInfoCompeted,
            selected: bankAccountInfoSelected,
            hide: areBankAccountsAdded,
          },
        ],
      },
    ]);
  }, [values, missingDocuments, displayed]);
  return null;
};
