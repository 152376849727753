import l from '../../../../lang';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import IntegrationCard from '../integration-card';
import { ReactComponent as ShopifyIcon } from '../../../../assets/icons/shopify.svg';
import { segmentIntegrationsMenuShopify } from '../../../../utils/analytics';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationCard
      title={l('paymentIntegration.shopify.title')}
      icon={<ShopifyIcon />}
      color="green"
      onDetails={() => {
        segmentIntegrationsMenuShopify();
        setDetailsOpened(DetailsOpened.SHOPIFY);
      }}
      detailsText={l('paymentIntegration.card.link')}
    />
  );
};
