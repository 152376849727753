import { useEffect, useRef } from 'react';
import Parser from 'html-react-parser';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import Card from '../../../../../common/card';
import CardDescription from '../../../../../common/card-description';
import CardTitle from '../../../../../common/card-title';
import l, { langKeyExists } from '../../../../../../lang';
import UserIcon from '../../../../../../assets/icons/user.svg?url';
import BuildingIcon from '../../../../../../assets/icons/building.svg?url';
import '../styles.scss';
import config from '../../../../../../config';
import UploadFile from '../../../../../common/upload-file';
import ButtonCustom from '../../../../../common/__deprecated__/button-custom';
import { Formik, FormikErrors, FormikValues } from 'formik';
import { FormObserver } from './form-observer';
import { ItemOnboardingMenu } from '../../../../onboarding-menu';
import FileIcon from '../../../../../../assets/icons/file.svg?url';
import MessageError from '../../../../../common/message-error';
import { scrollIntoView } from '../../../../../../utils';
import {
  segmentKycFormL2UploadDocAddress,
  segmentKycFormL2UploadDocBank,
  segmentKycFormL2UploadDocCompanyIncorporation,
  segmentKycFormL2UploadDocCompanyTax,
  segmentKycFormL2UploadDocOwner,
} from '../../../../../../utils/analytics';
import { FeatureToggle, Off, useFeature } from '../../../../../../hooks/use-features';
import { MerchantFeatures } from '../../../../../../constants';
import labels from '../../config/labels';
import { useBankAccounts } from '../../../../../../store/bank-accounts';
import { needInternationalWireDetails } from '../../../../../../utils/bank-account-utils';

export type ValuesLevelTwoStepDocs = {
  bankAccountDocument: string | undefined;
  identityDocument: string | undefined;
  proofOfAddressDocument: string | undefined;
  taxCompliantDocument: string | undefined;
  shareholdersDocument: string | undefined;
  internationalWireDetails: string | undefined;
};

interface SectionOneProps {
  error: boolean;
  errorInfo: object;
  displayed: boolean;
  isCompany: boolean;
  missingDocuments: string[];
  country: string;
  name: string;
  lastName: string;
  documentType: string;
  onClickNext: (values: ValuesLevelTwoStepDocs) => void;
  onChangeMenu: (menu: ItemOnboardingMenu[]) => void;
}

const SectionDocs = ({
  error,
  errorInfo,
  displayed,
  isCompany,
  missingDocuments,
  onClickNext,
  onChangeMenu,
  country,
  name,
  lastName,
  documentType,
}: SectionOneProps): JSX.Element => {
  const errorRef = useRef(null);
  const { hasFeature } = useFeature();
  const { allBankAccounts, getBankAccounts } = useBankAccounts();

  useEffect(() => {
    if (displayed && allBankAccounts?.data?.length === 0) {
      getBankAccounts();
    }
  }, [displayed, allBankAccounts?.data?.length]);

  useEffect(() => {
    if (error) {
      scrollIntoView(errorRef);
    }
  }, [error]);

  const docsRequired = {
    bankAccountDocument: !missingDocuments || missingDocuments?.includes('bankAccountDocument'),
    identityDocument: !missingDocuments || missingDocuments?.includes('identityDocument'),
    proofOfAddressDocument: !missingDocuments || missingDocuments?.includes('proofOfAddressDocument'),
    taxCompliantDocument: !missingDocuments || missingDocuments?.includes('taxCompliantDocument'),
    shareholdersDocument: !missingDocuments || missingDocuments?.includes('shareholdersDocument'),
    internationalWireDetails: needInternationalWireDetails(country, allBankAccounts?.data[0]?.bankCode),
  };

  const initialValues: ValuesLevelTwoStepDocs = {
    bankAccountDocument: undefined,
    identityDocument: undefined,
    proofOfAddressDocument: undefined,
    taxCompliantDocument: undefined,
    shareholdersDocument: undefined,
    internationalWireDetails: undefined,
  };

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<FormikValues> = {};
    if (docsRequired.bankAccountDocument) {
      if (!values.bankAccountDocument) {
        errors.bankAccountDocument = l('form.input.required');
      }
    }

    if (docsRequired.identityDocument) {
      if (!values.identityDocument) {
        errors.identityDocument = l('form.input.required');
      }
    }

    if (docsRequired.proofOfAddressDocument) {
      if (!values.proofOfAddressDocument) {
        errors.proofOfAddressDocument = l('form.input.required');
      }
    }

    if (docsRequired.taxCompliantDocument) {
      if (!values.taxCompliantDocument) {
        errors.taxCompliantDocument = l('form.input.required');
      }
    }
    if (docsRequired.shareholdersDocument) {
      if (isCompany && !values.shareholdersDocument) {
        errors.shareholdersDocument = l('form.input.required');
      }
    }

    if (docsRequired.internationalWireDetails) {
      if (isCompany && !values.internationalWireDetails) {
        errors.internationalWireDetails = l('form.input.required');
      }
    }

    return errors;
  };

  return (
    <div>
      <FeatureToggle feature={MerchantFeatures.ONBOARD_BY_COMPANY_TYPE}>
        <Off>
          <div className="kyc-level-2_section">
            <Card>
              <CardTitle>{l('companyInformation.kycTwo.sectionOne.title')}</CardTitle>
              <CardDescription>{l('companyInformation.kycTwo.sectionOne.subtitle')}</CardDescription>
            </Card>
          </div>
        </Off>
      </FeatureToggle>
      <div className="kyc-level-2_section">
        <p className="kyc-level-2_tooltip_fixed">
          <LightbulbOutlinedIcon color="primary" fontSize={'large'}></LightbulbOutlinedIcon>
          {Parser(
            l('companyInformation.kycTwo.tooltip', config.uploadFileMaxSize, config.uploadFileSupportedExtensions.join(', '))
          )}
        </p>

        <Formik initialValues={initialValues} validate={validate} onSubmit={values => onClickNext(values)}>
          {({ errors, touched, handleSubmit, setFieldValue }) => {
            const onFileBankHandler = ({ file: value }: { file: File | null; base64: string | null }): void => {
              if (value !== null) {
                segmentKycFormL2UploadDocBank();
              }
              setFieldValue('bankAccountDocument', value);
            };

            const onFileDocumentHandler = ({ file: value }: { file: File | null; base64: string | null }): void => {
              if (value !== null) {
                segmentKycFormL2UploadDocOwner();
              }
              setFieldValue('identityDocument', value);
            };

            const onFileAddressHandler = ({ file: value }: { file: File | null; base64: string | null }): void => {
              if (value !== null) {
                segmentKycFormL2UploadDocAddress();
              }
              setFieldValue('proofOfAddressDocument', value);
            };

            const onFileTaxHandler = ({ file: value }: { file: File | null; base64: string | null }): void => {
              if (value !== null) {
                segmentKycFormL2UploadDocCompanyTax();
              }
              setFieldValue('taxCompliantDocument', value);
            };

            const onFileShareHoldersHandler = ({ file: value }: { file: File | null; base64: string | null }): void => {
              if (value !== null) {
                segmentKycFormL2UploadDocCompanyIncorporation();
              }
              setFieldValue('shareholdersDocument', value);
            };

            const onFileInternationalWireDetailsHandler = ({
              file: value,
            }: {
              file: File | null;
              base64: string | null;
            }): void => {
              setFieldValue('internationalWireDetails', value);
            };

            return (
              <form onSubmit={handleSubmit}>
                <FormObserver
                  displayed={displayed}
                  isCompany={isCompany}
                  missingDocuments={missingDocuments}
                  onChangeOnboardingMenu={onChangeMenu}
                />
                <Card>
                  <CardTitle>{labels.step2Headline(hasFeature, { isCompany })}</CardTitle>
                  <CardDescription>{l(labels.step2Subheadline(hasFeature, { isCompany }), name)}</CardDescription>
                  {docsRequired.bankAccountDocument && (
                    <>
                      <div className="kyc-level-2_title">
                        <img src={FileIcon} alt="user" />
                        <span>{l('companyInformation.kycTwo.bankAccountDocumentation.title')}</span>
                      </div>
                      <UploadFile
                        title={labels.step2BankAccountDocumentation(hasFeature, { isCompany })}
                        description={labels.step2BankAccountDocumentationHelp(hasFeature, { isCompany, name, lastName })}
                        supportedExtensions={config.uploadFileSupportedExtensions}
                        maxSize={config.uploadFileMaxSize}
                        onChange={onFileBankHandler}
                        externalError={(touched.bankAccountDocument && errors.bankAccountDocument) || undefined}
                      />
                      {docsRequired.internationalWireDetails && (
                        <>
                          <br />
                          <br />
                          <UploadFile
                            title={'International Wire Details'}
                            supportedExtensions={config.uploadFileSupportedExtensions}
                            maxSize={config.uploadFileMaxSize}
                            onChange={onFileInternationalWireDetailsHandler}
                            externalError={
                              (touched.internationalWireDetails && errors.internationalWireDetails) || undefined
                            }
                          />
                        </>
                      )}
                    </>
                  )}
                  {(docsRequired.identityDocument || docsRequired.proofOfAddressDocument) && (
                    <div className="kyc-level-2_title">
                      <img src={UserIcon} alt="user" />
                      <span>{labels.step2IdentityDocumentHeadline(hasFeature, { isCompany, name, lastName })}</span>
                    </div>
                  )}
                  {docsRequired.identityDocument && (
                    <>
                      <UploadFile
                        title={labels.step2IdentityDocument(hasFeature, { isCompany, documentType })}
                        description={labels.step2IdentityDocumentHelp(hasFeature, { isCompany, name, lastName })}
                        supportedExtensions={config.uploadFileSupportedExtensions}
                        maxSize={config.uploadFileMaxSize}
                        onChange={onFileDocumentHandler}
                        externalError={
                          (errors.identityDocument && touched.identityDocument && errors.identityDocument) || undefined
                        }
                      />
                      <br />
                      <br />
                    </>
                  )}
                  {docsRequired.proofOfAddressDocument && (
                    <UploadFile
                      title={labels.step2ProofOfAddressDocument(hasFeature, { isCompany })}
                      description={labels.step2ProofOfAddressDocumentHelp(hasFeature, {
                        isCompany,
                        country,
                        name,
                        lastName,
                      })}
                      supportedExtensions={config.uploadFileSupportedExtensions}
                      maxSize={config.uploadFileMaxSize}
                      onChange={onFileAddressHandler}
                      externalError={(touched.proofOfAddressDocument && errors.proofOfAddressDocument) || undefined}
                    />
                  )}
                  {(docsRequired.taxCompliantDocument || (docsRequired.shareholdersDocument && isCompany)) && (
                    <div className="kyc-level-2_title">
                      <img src={BuildingIcon} alt="user" />
                      <span>{labels.step2TaxCompliantDocumentHeadline(hasFeature, { isCompany })}</span>
                    </div>
                  )}
                  {docsRequired.taxCompliantDocument && (
                    <UploadFile
                      title={labels.step2TaxCompliantDocument(hasFeature, { isCompany, country })}
                      description={labels.step2TaxCompliantDocumentDescription(hasFeature, {
                        isCompany,
                        documentType,
                        name,
                        lastName,
                      })}
                      supportedExtensions={config.uploadFileSupportedExtensions}
                      maxSize={config.uploadFileMaxSize}
                      onChange={onFileTaxHandler}
                      externalError={(touched.taxCompliantDocument && errors.taxCompliantDocument) || undefined}
                    />
                  )}
                  {docsRequired.shareholdersDocument && isCompany && (
                    <>
                      <br />
                      <br />
                      <UploadFile
                        title={labels.step2ShareholdersDocument(hasFeature, { isCompany, country })}
                        description={labels.step2ShareholdersDocumentHelp(hasFeature, { isCompany, country })}
                        supportedExtensions={config.uploadFileSupportedExtensions}
                        maxSize={config.uploadFileMaxSize}
                        onChange={onFileShareHoldersHandler}
                        externalError={(touched.shareholdersDocument && errors.shareholdersDocument) || undefined}
                      />
                    </>
                  )}
                  <div ref={errorRef}>
                    {error && (
                      <div className="kyc-level-2_message_error">
                        <MessageError
                          errorInfo={
                            errorInfo ?? {
                              level: 'error',
                              title: l('companyInformation.kycTwo.error.upload.title'),
                              message: l('companyInformation.kycTwo.error.upload.message'),
                            }
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="kyc-level-2_send_btn">
                    {/* @ts-ignore */}
                    <ButtonCustom type="submit" fullWidth text={l('companyInformation.send')} />
                  </div>
                </Card>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SectionDocs;
