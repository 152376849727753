import { useHistory } from 'react-router-dom';
import logoDlGo from '../../../assets/images/dlocalGo-icon.svg?url';
import logoVerticalDlGo from '../../../assets/images/dlocalGo.svg?url';
import { Routes } from '../../../config/routes';
import './styles.scss';

interface LogoProps {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  type?: 'vertical' | 'horizontal';
  goHomeDisabled?: boolean;
}

const Logo = ({ size, type, goHomeDisabled: goHomeDisabled }: LogoProps) => {
  const history = useHistory();
  return (
    <div
      className={`logo ${goHomeDisabled ? 'logo_go_home_disabled' : ''}`}
      onClick={() => {
        if (!goHomeDisabled) history.push(Routes.HOME);
      }}
    >
      <img
        src={(type === 'vertical' && logoVerticalDlGo) || (type === 'horizontal' && logoVerticalDlGo) || logoDlGo}
        className={`logo_img ${size ? `logo_img_${size.toLowerCase()}` : ''}`}
        width={128}
        height={28}
        alt="logo"
      />
    </div>
  );
};

export default Logo;
