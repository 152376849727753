import Bugsnag from '@bugsnag/js';
import { v4 as uuidv4 } from 'uuid';
import { setCookieLanguage, getLanguage, realoadPage } from '../../utils';
import { DashboardActionType } from './actions';
import { DashboardState } from './interfaces';

const initialState: DashboardState = {
  loading: true,
  error: false,
  merchantMe: undefined,
  userMe: undefined,
};

const Dashboard = (state = initialState, action: any) => {
  switch (action.type) {
    case DashboardActionType.INIT_DASHBOARD: {
      return {
        ...state,
        loading: true,
      };
    }
    case DashboardActionType.INIT_DASHBOARD_FINISHED: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case DashboardActionType.GET_MERCHANT_ME_SUCCESS: {
      const hashId = uuidv4();
      const { id } = action.response;

      Bugsnag.addMetadata('merchantMe', {
        id,
      });

      return {
        ...state,
        error: false,
        merchantMe: {
          ...action.response,
          logoUrl: action.response.logoUrl ? `${action.response.logoUrl}?hash=${hashId}` : null,
        },
      };
    }
    case DashboardActionType.GET_MERCHANT_ME_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case DashboardActionType.GET_USER_ME_SUCCESS: {
      const currentLanguage = getLanguage();
      const { language, email, name } = action.response;

      Bugsnag.setUser(email, name);

      if (currentLanguage && language && currentLanguage !== language) {
        setCookieLanguage(language);
        realoadPage();
        return {
          ...state,
        };
      }
      return {
        ...state,
        error: false,
        userMe: {
          ...action.response,
        },
      };
    }
    case DashboardActionType.GET_USER_ME_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case DashboardActionType.UPDATE_MERCHANT_ME: {
      return {
        ...state,
        merchantMe: {
          ...state.merchantMe,
          ...action.newValues,
        },
      };
    }
    case DashboardActionType.UPDATE_USER_ME: {
      const { language } = action.newValues;
      if (state.userMe?.language !== language) {
        setCookieLanguage(language);
      }
      return {
        ...state,
        userMe: {
          ...state.userMe,
          ...action.newValues,
        },
      };
    }
    default:
      return state;
  }
};

export default Dashboard;
