import { all } from 'redux-saga/effects';
import {
  initPaymentMethodsSaga,
  allPaymentMethodsSaga,
  addPaymentMethodSaga,
  deletePaymentMethodSaga,
  saveCoverageSaga,
} from './payment-methods/sagas';
import { initLoginSaga, sendLoginSaga, sendLogoutSaga } from './auth/sagas';
import { sendSignUpSaga } from './sign-up/sagas';
import {
  initDashboardSaga,
  getUserMeSaga,
  tokenCheckedSaga,
  getMerchantMeSaga,
  initDashboardFinishSaga,
} from './dashboard/sagas';
import {
  getDocumentTypesForKYCSaga,
  sendKYCSaga,
  deleteLogoSaga,
  uploadLogoSaga,
  getKycLevelsSaga,
  getIndustriesSaga,
  getMissingDocumentsSaga,
} from './company-information/sagas';
import {
  getTransactionsSaga,
  getTransactionDetailSaga,
  getPaymentMethodsForTransSaga,
  exportTransactionsSaga,
  getPreChargebackFormSaga,
} from './transactions/sagas';
import { getBalancesSaga } from './home/sagas';
import { sendEmailValidationSaga, sendConfirmEmailSaga } from './email-validation/sagas';
import { getSettlementsSaga, getBeneficiariesSaga, requestSettlementSaga } from './settlements/sagas';
import {
  getCurrenciesSaga,
  getDocumentTypesForPaymentLinkSaga,
  createPaymentLinkSaga,
  getExistingClientsSaga,
  getCreatedOneTimePaymentLinksSaga,
  getCreatedRecurringPaymentLinksSaga,
  changeStatusRecurringPaymentLinkSaga,
} from './payment-link/sagas';
import {
  getCurrenciesVirtualPosSaga,
  getDocumentTypesVirtualPosSaga,
  createPaymentSaga,
  confirmPaymentSaga,
} from './virtual-pos/sagas';
import { getIntegrationKeysSaga, getCurrenciesPISaga, setShopifyTestModeSaga } from './payment-integration/sagas';
import { getRefundsSaga, getBanksSaga, sendRequestRefundSaga, getPaymentMethodsForRefundsSaga } from './refunds/sagas';
import {
  reportsGetTransactionsByPaymentMethodSaga,
  reportsGetTransactionsByPaymentSolutionSaga,
  reportsGetPaymentVolumeSaga,
  reportsGetTransactionsByCountrySaga,
  reportsTransactionsSummarySaga,
} from './reports/sagas';
import { sendPasswordResetSaga, confirmPasswordResetSaga } from './password-reset/sagas';
import { savePersonalInfoSaga, changePasswordSaga } from './profile/sagas';
import { getClientsSaga } from './clients/sagas';
import {
  getBankAccountsSaga,
  getBanksByCountrySaga,
  addBankAccountSaga,
  deleteBankAccountSaga,
} from './bank-accounts/sagas';
import { jumioRefreshStatusSaga, jumioStartSaga } from './jumio/sagas';
import {
  deactivatePlanSaga,
  deactivateSubscriptionSaga,
  getPlansSaga,
  getSubscriptionsSaga,
  createUpdatePlanSaga,
} from './subscriptions/sagas';

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(() => resolve(true), ms));
}

export default function* rootSaga() {
  yield all([
    initLoginSaga(),
    sendLoginSaga(),
    sendSignUpSaga(),
    sendLogoutSaga(),
    initDashboardSaga(),
    getUserMeSaga(),
    tokenCheckedSaga(),
    getMerchantMeSaga(),
    initDashboardFinishSaga(),
    initPaymentMethodsSaga(),
    getDocumentTypesForKYCSaga(),
    sendKYCSaga(),
    deleteLogoSaga(),
    uploadLogoSaga(),
    getKycLevelsSaga(),
    allPaymentMethodsSaga(),
    addPaymentMethodSaga(),
    deletePaymentMethodSaga(),
    saveCoverageSaga(),
    getTransactionsSaga(),
    getTransactionDetailSaga(),
    getPreChargebackFormSaga(),
    getPaymentMethodsForTransSaga(),
    exportTransactionsSaga(),
    getBalancesSaga(),
    sendEmailValidationSaga(),
    sendConfirmEmailSaga(),
    getSettlementsSaga(),
    getBeneficiariesSaga(),
    requestSettlementSaga(),
    getCurrenciesSaga(),
    getDocumentTypesForPaymentLinkSaga(),
    createPaymentLinkSaga(),
    getExistingClientsSaga(),
    getCreatedOneTimePaymentLinksSaga(),
    getCreatedRecurringPaymentLinksSaga(),
    changeStatusRecurringPaymentLinkSaga(),
    getIntegrationKeysSaga(),
    reportsTransactionsSummarySaga(),
    reportsGetPaymentVolumeSaga(),
    reportsGetTransactionsByCountrySaga(),
    reportsGetTransactionsByPaymentSolutionSaga(),
    reportsGetTransactionsByPaymentMethodSaga(),
    getRefundsSaga(),
    getBanksSaga(),
    sendRequestRefundSaga(),
    getPaymentMethodsForRefundsSaga(),
    sendPasswordResetSaga(),
    confirmPasswordResetSaga(),
    getCurrenciesPISaga(),
    savePersonalInfoSaga(),
    changePasswordSaga(),
    getClientsSaga(),
    getBankAccountsSaga(),
    getBanksByCountrySaga(),
    addBankAccountSaga(),
    deleteBankAccountSaga(),
    getCurrenciesVirtualPosSaga(),
    getDocumentTypesVirtualPosSaga(),
    createPaymentSaga(),
    confirmPaymentSaga(),
    getIndustriesSaga(),
    getMissingDocumentsSaga(),
    setShopifyTestModeSaga(),
    jumioStartSaga(),
    jumioRefreshStatusSaga(),
    getSubscriptionsSaga(),
    getPlansSaga(),
    createUpdatePlanSaga(),
    deactivateSubscriptionSaga(),
    deactivatePlanSaga(),
  ]);
}
