import React, { useState } from 'react';
import './styles.scss';
import { useMediaQuery } from '@mui/material';
import AccordionMenuBody from './AccordionMenuBody';
import AccordionMenuItem from './AccordionMenuItem';

const AccordionMenu = ({ items = [] }) => {
  const isTabletMobile = useMediaQuery('(max-width:768px)');
  const [open, setOpen] = useState(items[0]);

  return (
    <div className="accordion-menu">
      <div className="accordion-menu__items">
        {items.map(item => (
          <div key={`${item.id}`}>
            <AccordionMenuItem
              key={item.title}
              title={item.title}
              onClick={() => {
                if (isTabletMobile && item.id === open?.id) {
                  setOpen(null);
                } else {
                  setOpen(item);
                }
              }}
              open={open?.id === item.id}
            />
            {isTabletMobile && open?.id === item.id && <AccordionMenuBody content={open.content} />}
          </div>
        ))}
      </div>
      {!isTabletMobile && open && <AccordionMenuBody key={open.id} content={open.content} />}
    </div>
  );
};

export default AccordionMenu;
