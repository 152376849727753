import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import Input from '../common/input';
import ButtonCustom from '../common/__deprecated__/button-custom';
import { commons as validations } from '../../utils/validations';
import l from '../../lang';
import './styles.scss';
import ButtonLink from '../common/button-link';
import { Routes } from '../../config/routes';
import MessageError from '../common/message-error';

const initialValues = { email: '', password: '' };

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = l('form.input.required');
  } else if (!validations.emailValid(values.email)) {
    errors.email = l('form.email.invalid');
  }
  if (!values.password) {
    errors.password = l('form.input.required');
  }

  return errors;
};

const LoginForm = ({ loading, error, errorInfo, onSubmit }) => {
  const history = useHistory();
  const [showError, setShowError] = useState(true);
  return (
    <div className="login-form">
      {error && showError && (
        <div className="login-error">
          <MessageError error={error} errorInfo={errorInfo} onClose={() => setShowError(false)} />
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={values => {
          setShowError(true);
          onSubmit(values.email, values.password);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Input
              type="email"
              name="email"
              label={l('emailAddress.label')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled={loading}
              error={errors.email && touched.email && errors.email}
            />
            <Input
              type="password"
              name="password"
              label={l('password.label')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              disabled={loading}
              error={errors.password && touched.password && errors.password}
            />
            <div className="link-password-reset">
              <ButtonLink text={l('login.passwordReset.label')} onClick={() => history.push(Routes.PASSWORD_RESET)} />
            </div>
            <div className="btn-login-container">
              <ButtonCustom
                type="submit"
                text={l('login.btn')}
                textLoading={l('form.sending')}
                loading={loading}
                size="full-square"
                variant="contained"
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

LoginForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default LoginForm;
