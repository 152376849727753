import { call, put, takeLatest } from 'redux-saga/effects';
import { postTask } from '../../services';
import { Urls } from '../../config/urls';
import { getLanguage } from '../../utils';
import { SignUpActionType, sendingSignUp, signUpSuccess, signUpError } from './actions';
import { gtagSignUp, segmentSignUp } from '../../utils/analytics';
import collectTrackerData from '../../utils/collect-tracker-data';

function* sendSignUp(action: any) {
  yield put(sendingSignUp());
  const country = action.values.country;
  const language = getLanguage();
  const { response, error } = yield call(postTask, {
    url:
      Urls.api.signUp +
      (action.values.recaptcha && action.values.recaptcha !== '' ? `?recaptcha=${action.values.recaptcha}` : ''),
    body: {
      name: action.values.name,
      email: action.values.email,
      password: action.values.password,
      country,
      marketingConsent: action.values.marketingConsent,
      language,
      merchantReference: action.values.merchantReference,
      company: action.values.company,
      termsAndConditionsConsent: action.values.termsAndConditions,
      ...collectTrackerData(),
    },
  });

  if (response) {
    gtagSignUp({ email: action.values.email });
    segmentSignUp({
      countryCode: country,
      language,
    });
    yield put(signUpSuccess(response));
  } else {
    segmentSignUp({
      countryCode: country,
      language,
      error,
    });
    yield put(signUpError(error));
  }
}

export function* sendSignUpSaga() {
  yield takeLatest(SignUpActionType.SEND_SIGN_UP, sendSignUp);
}
