import { FunctionComponent, ReactNode } from 'react';
import './styles.scss';

interface CardProps{
  children: ReactNode;
}

const Card: FunctionComponent<CardProps> = ({ children }) => <div className='card'>{children}</div>;

export default Card;
