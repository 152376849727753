import l from '../../../../lang';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import IntegrationCard from '../integration-card';
import { ReactComponent as WooIcon } from '../../../../assets/icons/woo.svg';
import { segmentIntegrationsMenuWoocommerce } from '../../../../utils/analytics';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationCard
      title={l('paymentIntegration.woo.title')}
      icon={<WooIcon />}
      color="violet"
      onDetails={() => {
        segmentIntegrationsMenuWoocommerce();
        setDetailsOpened(DetailsOpened.WOO);
      }}
      detailsText={l('paymentIntegration.card.link')}
    />
  );
};
