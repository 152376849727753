import React from 'react';
import PropTypes from 'prop-types';
import { Select as SelectMui, FormControl, FormHelperText, SelectProps, Theme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';

export type SelectMenuProps = SelectProps & {
  optionsArray?: Array<{ value: string; label: string }>;
  optionsArrayDisabled?: Array<string>;
  size?: 'sm' | 'md' | 'lg';
  error?: boolean;
  helperText?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  message?: string;
  input?: React.ReactElement<any, any>;
};

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    fontSize: '14px',
    padding: '5px 10px',
  },
  menu: {
    fontSize: '14px',
    borderBottom: '0.4px solid #C4CDD5',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}));

const SelectMenu = ({
  ref,
  label,
  name,
  value,
  required,
  defaultValue,
  disabled,
  optionsArray,
  optionsArrayDisabled,
  onChange,
  onBlur,
  onFocus,
  onMouseOver,
  onClose,
  size,
  error,
  message,
  className = '',
  input,
}: SelectMenuProps) => {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={className}
      error={!!error}
      disabled={disabled}
      size={size === 'sm' ? 'small' : undefined}
    >
      <SelectMui
        ref={ref}
        label={label}
        value={value}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        onMouseOver={onMouseOver}
        onFocus={onFocus}
        onClose={onClose}
        defaultValue={defaultValue}
        input={input}
        inputProps={{
          name,
          id: `id-select-${name}`,
          className: classes.select,
        }}
      >
        {optionsArray &&
          optionsArray.map((op, idx) => {
            return (
              <MenuItem
                value={op.value}
                key={`option-${idx.toString()}-${op.value}`}
                disabled={optionsArrayDisabled && optionsArrayDisabled.includes(op.value)}
                className={classes.menu}
              >
                {op.label}
              </MenuItem>
            );
          })}
      </SelectMui>
      {(error || message) && <FormHelperText>{error || message}</FormHelperText>}
    </FormControl>
  );
};

SelectMenu.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  options: PropTypes.object,
  optionsArrayDisabled: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  size: PropTypes.string,
  defaultOptionEnabled: PropTypes.bool,
  error: PropTypes.string,
};

SelectMenu.defaultProps = {
  ref: undefined,
  onFocus: undefined,
  onMouseOver: undefined,
  onClose: undefined,
  message: undefined,
};

export default SelectMenu;
