import Parser from 'html-react-parser';
import l from '../../../../lang';
import FolderExclamationIcon from '../../../../assets/icons/folder-exclamation.svg?url';
import FolderMinusIcon from '../../../../assets/icons/folder-minus.svg?url';
import './styles.scss';

export const Empty = () => (
  <div className="empty__no_transactions">
    <img src={FolderMinusIcon} alt="" />
    {Parser(l('transactions.noTransaction'))}
  </div>
);

export const EmptyNoFound = () => (
  <div className="empty__no_transactions">
    <img src={FolderExclamationIcon} alt="" />
    {Parser(l('transactions.noTransaction.found'))}
  </div>
);
