import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  iconButton: {
    '@media (max-width:768px)': {
      padding: '5px',
    },
  },
  typography: {
    padding: theme.spacing(2),
    maxWidth: 350,
    fontSize: '14px',
  },
}));

const Popup = ({ text, icon }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopup = event => {
    setAnchorEl(event.currentTarget);
  };

  const closePopup = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton className={classes.iconButton} onClick={openPopup}>
        {icon}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>{text}</Typography>
      </Popover>
    </>
  );
};

Popup.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.object,
};

export default Popup;
