import { PanelStore } from '../interfaces';

export namespace PaymentIntegrationSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.paymentIntegration.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.paymentIntegration.error;
  };
  export const getErrorInfo = (state: PanelStore.RootState) => {
    return state.paymentIntegration.errorInfo;
  };
  export const getApiKey = (state: PanelStore.RootState) => {
    return state.paymentIntegration.apiKey;
  };
  export const getSecretKey = (state: PanelStore.RootState) => {
    return state.paymentIntegration.secretKey;
  };
  export const getCurrencies = (state: PanelStore.RootState) => {
    return state.paymentIntegration.currencies;
  };
  export const getExchangeRates = (state: PanelStore.RootState) => {
    return state.paymentIntegration.exchangeRates;
  };
  export const getShopifyIntegration = (state: PanelStore.RootState) => {
    return state.paymentIntegration.shopifyIntegration;
  };
  export const getDetailsOpened = (state: PanelStore.RootState) => {
    return state.paymentIntegration.detailsOpened;
  };
}
