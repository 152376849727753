const domain = import.meta.env.REACT_APP_API_DOMAIN_DLGO;
const docsDomain = 'docs.dlocalgo.com';
const checkoutDomain = import.meta.env.REACT_APP_API_CHECKOUT_DOMAIN_DLGO;

export const Urls = {
  api: {
    login: `${domain}/v1/auth/authenticate`,
    logout: `${domain}/v1/auth/logout`,
    refreshLogin: `${domain}/v1/auth/refresh_token`,
    signUp: `${domain}/v1/users`,
    userMe: `${domain}/v1/users/me`,
    merchantMe: `${domain}/v1/merchants/me`,
    companyInformation: `${domain}/v1/merchants/:id/company`,
    companyInformationV2: `${domain}/v2/merchants/:id/company`,
    paymentMethods: `${domain}/v1/merchants/:id/payment_methods`,
    allPaymentMethods: `${domain}/v1/payment_methods/all`,
    addPaymentMethods: `${domain}/v1/payment_methods/enable`,
    deletePaymentMethods: `${domain}/v1/payment_methods/disable`,
    addCountriesPaymentMethods: `${domain}/v1/payment_methods/enable/country`,
    deleteCountriesPaymentMethods: `${domain}/v1/payment_methods/disable/country`,
    paymentInfo: `${domain}/v1/payment_infos`,
    industries: `${domain}/v1/merchant_industries`,
    missingDocuments: `${domain}/v2/merchants/:merchantId/missing_documents`,
    subscriptionPrices: `${domain}/v1/subscription_prices`,
    subscriptionPayments: `${domain}/v1/subscription_payments/all`,
    cancelSubscription: `${domain}/v1/payment_infos/cancel`,
    allTransactions: `${domain}/v1/transactions/search`,
    preChargebackForm: `${domain}/v1/transactions/pre-chargeback-form`,
    exportTransactions: `${domain}/v1/transactions/export`,
    exportTransactionsExcel: `${domain}/v1/transactions/export/excel`,
    balances: `${domain}/v1/balances`,
    emailValidation: `${domain}/v1/users/send_validation_email`,
    confirmEmail: `${domain}/v1/users/confirm_validation_email`,
    allSettlements: `${domain}/v1/settlements/all`,
    allBankAccounts: `${domain}/v1/settlements/bank_account/all`,
    deleteBankAccount: `${domain}/v1/settlements/bank_account`,
    documentTypes: `${domain}/v1/document_types`,
    validateDocument: `${domain}/v1/document_types/validate`,
    addBeneficiary: `${domain}/v1/settlements/bank_account`,
    addBeneficiaryWithDocument: `${domain}/v1/settlements/bank_account/with_document`,
    requestSettlement: `${domain}/v1/settlements`,
    currencies: `${domain}/v1/currencies`,
    createOneTimePaymentLink: `${domain}/v1/checkout/direct`,
    createRecurringPaymentLink: `${domain}/v1/checkout/recurring`,
    enableRecurringPaymentLink: `${domain}/v1/checkout/recurring/:token/enable`,
    disableRecurringPaymentLink: `${domain}/v1/checkout/recurring/:token/disable`,
    integrationKeys: `${domain}/v1/merchants/:id/integration_details`,
    allRefunds: `${domain}/v1/refunds/all`,
    banks: `${domain}/v1/banks`,
    requestRefunds: `${domain}/v1/refunds`,
    sendPasswordReset: `${domain}/v1/users/send_password_reset_email`,
    confirmPasswordReset: `${domain}/v1/users/confirm_password_reset_email`,
    uploadLogo: `${domain}/v1/merchants/:id/logo`,
    deleteLogo: `${domain}/v1/merchants/:id/logo`,
    userUpdate: `${domain}/v1/users/update`,
    changePassword: `${domain}/v1/users/update_password`,
    kycLevels: `${domain}/v1/merchants/kyc_levels`,
    allClients: `${domain}/v1/merchants/:id/clients`,
    allOneTimePaymentLinks: `${domain}/v1/payment_links/all`,
    allRecurringPaymentLinks: `${domain}/v1/checkout/recurring/all`,
    shopifySetTestMode: `${domain}/v1/merchants/shopify/testmode`,
    jumioKycFlow: `${domain}/v2/merchants/:id/kyc_flow`,
    reportsTransactionsSummary: `${domain}/v1/merchant_metrics/:days/header`,
    reportsTransactionsPaymentVolume: `${domain}/v1/merchant_metrics/:days/payment_volume`,
    reportsTransactionsByCountry: `${domain}/v1/merchant_metrics/:days/transactions_by_countries`,
    reportsTransactionsByPaymentSolution: `${domain}/v1/merchant_metrics/:days/transactions_by_payment_solution`,
    reportsTransactionsByPaymentMethod: `${domain}/v1/merchant_metrics/:days/transactions_by_payment_method`,
    createPlan: `${domain}/v1/subscription/plan`,
    allPlans: `${domain}/v1/subscription/plan/all`,
    allSubscriptions: `${domain}/v1/subscription/all`,
    createUpdateSubscriptionPlan: `${domain}/v1/subscription/plan`,
    deactivateSubscription: `${domain}/v1/subscription/:subscriptionId/deactivate`,
    deactivatePlan: `${domain}/v1/subscription/plan/:planId/deactivate`,
  },
  checkoutApi: {
    confirmPayment: `${checkoutDomain}/v1/checkout/confirm`,
    paymentInfo: `${checkoutDomain}/v1/checkout/`,
    dataRequired: `${checkoutDomain}/v1/checkout/payment_method/:id`,
  },
  paymentMethodsSquare: 'https://static.directopago.com/payment_method/square/:code.svg',
  flags: 'https://static.directopago.com/flags/:code.svg',
  faq: {
    es: 'https://intercom.help/dlocalgo/es/',
    en: 'https://intercom.help/dlocalgo/en/',
    pt: 'https://intercom.help/dlocalgo/pt-BR/',
  },
  magentoAddDP: 'https://marketplace.magento.com/dlocal-d-local-go.html',
  shopifyAddDP: 'https://accounts.shopify.com/store-login?redirect=settings%2Fpayments%2Falternative-providers%2F8617985',
  woocommercePlugin: 'https://wordpress.org/plugins/dlocal-go-payments-for-woocommerce/',
  prestaShopDownloadPlugin: 'https://static.dlocalgo.com/plugins/prestashop/dlocalgo.zip',
  docs: {
    firstSteps: {
      es: `https://helpcenter.dlocalgo.com/es/articles/9421580-primeros-pasos-en-dlocal-go`,
      pt: `https://helpcenter.dlocalgo.com/pt-BR/articles/9421580-primeiros-passos-no-dlocal-go`,
      en: `https://helpcenter.dlocalgo.com/en/articles/9421580-getting-started-with-dlocal-go`,
    },
    shopifyIntegration: {
      es: `https://intercom.help/dlocalgo/es/articles/7336476-guia-de-instalacion-del-checkout-redirect-en-shopify`,
      pt: `https://intercom.help/dlocalgo/pt-BR/articles/7336476-guia-de-instalacao-do-checkout-transparente-da-shopify`,
      en: `https://intercom.help/dlocalgo/en/articles/7336476-shopify-installation-guide`,
    },
    shopifyTransparentIntegration: `https://${docsDomain}/v/pt`,
    magentoIntegration: {
      es: `https://intercom.help/dlocalgo/es/articles/7435866-guia-de-instalacion-del-checkout-redirect-en-magento`,
      en: `https://intercom.help/dlocalgo/en/articles/7435866-magento-checkout-redirect-installation-guide`,
      pt: `https://intercom.help/dlocalgo/en/articles/7435866-magento-checkout-redirect-installation-guide`,
    },
    woocommerceIntegration: {
      es: `https://intercom.help/dlocalgo/es/articles/7336479-guia-de-instalacion-del-checkout-redirect-en-woocommerce`,
      en: `https://intercom.help/dlocalgo/en/articles/7336479-woocommerce-checkout-redirect-installation-guide`,
      pt: `https://intercom.help/dlocalgo/pt-BR/articles/7336479-guia-de-instalacao-do-checkout-redirect-na-woocommerce`,
    },
    woocommerceIntegrationTest: {
      es: `https://intercom.help/dlocalgo/es/articles/7336479-guia-de-instalacion-del-checkout-redirect-en-woocommerce#h_7a58c36aeb`,
      en: `https://intercom.help/dlocalgo/en/articles/7336479-woocommerce-checkout-redirect-installation-guidet#h_da2e690964`,
      pt: `https://intercom.help/dlocalgo/pt-BR/articles/7336479-guia-de-instalacao-do-checkout-redirect-na-woocommerce#h_beae6a4323`,
    },
    prestaShopIntegration: {
      es: `https://intercom.help/dlocalgo/es/articles/7336480-guia-de-instalacion-del-checkout-redirect-en-prestashop`,
      en: `https://intercom.help/dlocalgo/en/articles/7336480-prestashop-installation-guide`,
      pt: `https://intercom.help/dlocalgo/pt-BR/articles/7336480-guia-de-instalacao-do-checkout-redirect-na-prestashop`,
    },
    hostingerIntegration: {
      es: `https://helpcenter.dlocalgo.com/es/articles/9265796-integracion-de-dlocal-go-en-hstore-de-hostinger`,
      en: `https://helpcenter.dlocalgo.com/en/articles/9265796-integration-of-dlocal-go-in-hostinger-s-hstore`,
      pt: `https://helpcenter.dlocalgo.com/pt-BR/articles/9265796-integracao-do-dlocalgo-a-hstore-da-hostinger`,
    },
    apiIntegration: `https://${docsDomain}/integration-api/`,
    levels: {
      es: `https://helpcenter.dlocalgo.com/es/articles/6960204-que-informacion-debo-dar`,
      pt: `https://helpcenter.dlocalgo.com/pt-BR/articles/6960204-qual-a-documentacao-que-preciso-fornecer`,
      en: `https://helpcenter.dlocalgo.com/en/articles/6960204-required-documentation`,
    },
    integrations: `https://${docsDomain}/integraciones`,
    paymentLink: `https://${docsDomain}/cobrar-con-link-de-pago`,
    paymentButton: `https://intercom.help/dlocalgo/es/collections/3963107-boton-de-pago`,
  },
};
