import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSmartField } from '../../../../hooks/use-smart-field';
import l from '../../../../lang';
import './styles.scss';
import Select from '../../../common/select';

const SmartField = ({
  disabled,
  country,
  currency,
  totalAmount,
  installmentsId,
  installments,
  error,
  installmentsDisabled,
  onChangeError,
  onChangeBrand,
  onChangeInstallments,
  onChangeSmartFieldData,
}) => {
  const [installmentsOptions, setInstallmentsOptions] = useState([]);
  const [scripLoaded, setScripLoaded] = useState(false);
  useEffect(() => {
    const scriptDLocal = document.createElement('script');
    scriptDLocal.src = import.meta.env.REACT_APP_DLOCAL;
    scriptDLocal.async = true;
    document.body.appendChild(scriptDLocal);
    scriptDLocal.addEventListener('load', () => {
      setScripLoaded(true);
    });
    return () => {
      document.body.removeChild(scriptDLocal);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (scripLoaded) {
      const fetchData = async () => {
        onChangeInstallments({});
        const { dlocal, card } = useSmartField({
          country,
          currency,
          totalAmount,
          onChangeError,
          onChangeBrand,
          onChangeInstallments,
          onChangeInstallmentsOptions: setInstallmentsOptions,
        });
        onChangeSmartFieldData({ dlocal, card });
      };
      fetchData();
    }
    // eslint-disable-next-line
  }, [scripLoaded]);

  return (
    <div className="smart-field">
      <div className="box-container box-container--left">
        <label
          htmlFor="virtual-pos-card-field"
          className={`label-card-field ${error ? 'label-card-field--with-error' : ''} ${
            disabled ? 'label-card-field--disabled' : ''
          }`}
        >
          {l('smartField.cardField')}
        </label>
        <div
          id="virtual-pos-card-field"
          className={`card-field ${error ? 'card-field--with-error' : ''} ${disabled ? 'card-field--disabled' : ''}`}
        />
        <div id="card-errors" className="smart-field-error" role="alert">
          {error}
        </div>
      </div>
      {!installmentsDisabled && (
        <div className="box-container box-container--right">
          <Select
            name="installments"
            label={l(`smartField.fees${country === 'MX' ? '.MX' : ''}`)}
            defaultOptionText={installments ? l('common.default.option') : l('smartField.defaultOptions')}
            disabled={installmentsOptions?.length < 1}
            value={installmentsId}
            optionsArray={installmentsOptions}
            onChange={e => onChangeInstallments(installmentsOptions.filter(op => op.value === e.target.value)[0])}
          />
        </div>
      )}
    </div>
  );
};

SmartField.propTypes = {
  country: PropTypes.string,
  currency: PropTypes.string,
  totalAmount: PropTypes.number,
  installmentsId: PropTypes.string,
  installments: PropTypes.string,
  error: PropTypes.string,
  installmentsDisabled: PropTypes.bool,
  onChangeError: PropTypes.func,
  onChangeBrand: PropTypes.func,
  onChangeInstallments: PropTypes.func,
  onChangeSmartFieldData: PropTypes.func,
};

export default SmartField;
