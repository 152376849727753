import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import l from '../../../lang';
import './styles.scss';
import Loading from '../../common/loading';
import { getSettlements, changeShowRequestSettlement } from '../../../store/settlements/actions';
import Table from '../../common/table';
import icSettlements from '../../../assets/icons/settlement.svg?url';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import Dialog from '../../common/dialog';
import RequestSettlement from './request-settlement';
import MessageError from '../../common/message-error';
import ChipStatus from '../../common/chip-status';
import { realoadPage, createDate } from '../../../utils/index';
import TopDashboardSection from '../../common/top-dashboard-section';
import SettlementDetail from './detail';
import ButtonLink from '../../common/button-link';
import { Routes } from '../../../config/routes';
import ButtonMore from '../../common/button-more';
import SettlementFilter from './settlement-filter';
import Card from '../../common/card';
import { Button } from '../../common/button';
import CardDescription from '../../common/card-description';
import AlertMessages from '../alert-messages';

const Settlements = ({
  loading,
  error,
  errorInfo,
  allSettlements,
  showRequestSettlement,
  currentKYC,
  kycReview,
  settlementEnabled,
  fnGetSettlements,
  fnShowRequestSettlement,
}) => {
  const history = useHistory();
  const [filter, setFilter] = useState('');
  const [settlementDetail, setSettlementDetail] = useState(null);

  useEffect(() => {
    // First time
    fnGetSettlements();
  }, [fnGetSettlements]);

  const dataExist = allSettlements?.data?.length > 0;
  const disabledSettlements = !settlementEnabled && currentKYC === 1;

  return (
    <>
      <TopDashboardSection title={l('menu.item.SETTLEMENTS')} />
      <AlertMessages />
      <Card>
        <CardDescription>{l('menu.item.SETTLEMENTS.desc')}</CardDescription>
        <div className="settlements__options">
          {!disabledSettlements && (
            <>
              <Button onClick={() => fnShowRequestSettlement(true)} startIcon={<PlusIcon />}>
                {l('settlements.button')}
              </Button>
              <SettlementFilter
                fnChangeFilters={filterParam => {
                  setFilter(filterParam);
                  fnGetSettlements(0, filterParam);
                }}
              />
            </>
          )}
        </div>
        <div className={`settlements`}>
          <div className={`settlements__container ${dataExist ? 'settlements__container--data-exist' : ''}`}>
            {loading && (
              <div className="settlements__loading">
                <Loading />
              </div>
            )}
            {error && (
              <div className="settlements__error">
                <MessageError errorInfo={errorInfo} iconClose={<RefreshIcon />} onClose={() => realoadPage()} />
              </div>
            )}
            {!loading && !error && (
              <div className="settlements__table">
                <Table
                  idxHiddenColumnsOnMobile={[3, 5]}
                  withBtnMenu
                  headers={[
                    l('settlements.creationDate'),
                    l('settlements.id'),
                    l('settlements.amountRequested'),
                    l('settlements.fee'),
                    l('settlements.bank'),
                    l('settlements.paymentDate'),
                    l('settlements.status'),
                  ]}
                  body={
                    (dataExist &&
                      allSettlements?.data?.map(settlement => {
                        return [
                          <Moment format="DD/MM/YYYY">{createDate(settlement.date)}</Moment>,
                          <span>{settlement.id}</span>,
                          <span>
                            {settlement.currency} {settlement.requestedAmount}
                          </span>,
                          <span>
                            {settlement.currency} {settlement.settlementFee}
                          </span>,
                          <span>{settlement.bankName}</span>,
                          <span>
                            {settlement.paymentDate ? (
                              <Moment format="DD/MM/YYYY hh:mm:ss a">{createDate(settlement.paymentDate)}</Moment>
                            ) : (
                              ' - '
                            )}
                          </span>,
                          <span>
                            <ChipStatus status={settlement.status} text={l(`settlements.status.${settlement.status}`)} />
                          </span>,
                          <ButtonMore onClick={() => setSettlementDetail(settlement)} />,
                        ];
                      })) ||
                    null
                  }
                  rowsPerPageOptions={[allSettlements?.size]}
                  rowsLength={allSettlements?.totalElements}
                  rowsPerPage={allSettlements?.size}
                  page={allSettlements?.page}
                  handleChangePage={(event, newPage) => {
                    fnGetSettlements(newPage, filter);
                  }}
                />
                {settlementDetail && (
                  <Dialog
                    dividers={false}
                    titleCenter
                    title={l('settlements.details')}
                    handleClose={() => setSettlementDetail(null)}
                    body={<SettlementDetail detail={settlementDetail} />}
                  />
                )}
                {!dataExist && (
                  <div className="settlements__no-settlements">
                    <div>
                      <img className="settlements__no-settlements__img" src={icSettlements} alt="img-no-settlements" />
                      <div>{l('settlements.noSettlements')}</div>
                      {disabledSettlements && (
                        <div className="settlements__no-settlements__desc">
                          {kycReview ? (
                            <span>{l('settlements.noSettlements.kycReviewDesc')} </span>
                          ) : (
                            <span>{l('settlements.noSettlements.desc')} </span>
                          )}
                          <ButtonLink
                            text={l('settlements.noSettlements.here')}
                            onClick={() => history.push(Routes.COMPANY_INFORMATION)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {showRequestSettlement && (
            <Dialog
              dividers={false}
              fullScreenMobile
              unpaddingContent
              type="md"
              title={l('requestSettlement.title')}
              body={<RequestSettlement />}
              handleClose={() => {
                fnGetSettlements();
                fnShowRequestSettlement(false);
              }}
            />
          )}
        </div>
      </Card>
    </>
  );
};

Settlements.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  allSettlements: PropTypes.object,
  showRequestSettlement: PropTypes.bool,
  currentKYC: PropTypes.number,
  kycReview: PropTypes.bool,
  settlementEnabled: PropTypes.bool,
  fnGetSettlements: PropTypes.func,
  fnShowRequestSettlement: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.settlements.loading,
  error: state.settlements.error,
  errorInfo: state.settlements.errorInfo,
  allSettlements: state.settlements.allSettlements,
  showRequestSettlement: state.settlements.showRequestSettlement,
  currentKYC: state.dashboard.merchantMe?.kycLevel.level,
  kycReview: state.dashboard.merchantMe?.kycReview,
  settlementEnabled: state.dashboard.merchantMe?.settlementEnabled,
});

const mapDispatchToProps = dispatch => ({
  fnGetSettlements: (page, filter) => dispatch(getSettlements(page, filter)),
  fnShowRequestSettlement: show => dispatch(changeShowRequestSettlement(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settlements);
