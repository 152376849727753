import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { initDashboard, getMerchantMe } from '../../store/dashboard/actions';
import Menu from '../../components/dashboard/menu';
import MenuComponents from './menu-components';
import Loading from '../../components/common/loading';
import { MenuCodes } from '../../constants';
import { Routes } from '../../config/routes';
import { redirecTo } from '../../utils/index';
import { getMenuSelected, items } from '../../config/menu-items';
import './styles.scss';
import MessageError from '../../components/common/message-error';
import { useHistory } from 'react-router-dom';
import { gtagReportFirstTransaction, segmentIdentify, gaSetDimensionMerchantId } from '../../utils/analytics';

const Dashboard = ({
  loading,
  error,
  merchantMe,
  canOperate,
  paymentMethodsIsRequired,
  menuSelected,
  logouting,
  paymentMethodsSelected,
  language,
  fnInitDashboard,
  fnMerchantMe,
}) => {
  const history = useHistory();
  const [redirectActivateAccount, setRedirectActivateAccount] = useState(true);

  useEffect(() => {
    if (!window.Intercom && window.initIntercom) {
      window.initIntercom();
    }
  }, []);

  useEffect(() => {
    fnInitDashboard();
  }, [fnInitDashboard]);

  useEffect(() => {
    if (paymentMethodsSelected) {
      if (
        (paymentMethodsIsRequired && paymentMethodsSelected.length === 1) ||
        (!paymentMethodsIsRequired && paymentMethodsSelected.length === 0)
      ) {
        fnMerchantMe();
      }
    }
  }, [fnMerchantMe, paymentMethodsIsRequired, paymentMethodsSelected]);

  useEffect(() => {
    if (merchantMe?.id) {
      gaSetDimensionMerchantId(merchantMe.id.toString());
    }
    if (merchantMe?.completedTransactions > 0 && !localStorage.getItem('gtagReportFirstTransaction')) {
      localStorage.setItem('gtagReportFirstTransaction', "true");
      gtagReportFirstTransaction(merchantMe.id);
    }
  }, [merchantMe]);

  useEffect(() => {
    if (!_.isEmpty(merchantMe) && redirectActivateAccount) {
      setRedirectActivateAccount(false);
    }
    if (!_.isEmpty(merchantMe)) {
      segmentIdentify(merchantMe.id, merchantMe.country);
    }
  }, [merchantMe]);

  const menufilter = items => {
    const filteredItems = [];
    items.forEach(group => {
      const filteredGroup = {};
      Object.keys(group).forEach(key => {
        switch (key) {
          case MenuCodes.ACTIVATE_ACCOUNT: {
            if (!canOperate) {
              filteredGroup[key] = group[key];
            }
            break;
          }
          default:
            filteredGroup[key] = group[key];
        }
      });
      filteredItems.push(filteredGroup);
    });
    return filteredItems;
  };

  return (
    <div className="dashboard" key={language}>
      {(loading || logouting) && (
        <div className="panel dashboard-loading-container">
          <Loading fullScreen noBackground={!logouting} />
        </div>
      )}
      {!loading && error && (
        <div className="dashboard-error-container">
          <MessageError onClose={() => redirecTo(Routes.LOGIN)} />
        </div>
      )}
      {!loading && !error && (
        <Menu
          items={menufilter(items)}
          selected={getMenuSelected(menuSelected)}
          body={MenuComponents[menuSelected]}
          canOperate={canOperate}
        />
      )}
    </div>
  );
};

Dashboard.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  merchantMe: PropTypes.object,
  canOperate: PropTypes.bool,
  paymentMethodsIsRequired: PropTypes.bool,
  creditCardEnabled: PropTypes.bool,
  emailValidationIsRequired: PropTypes.bool,
  menuSelected: PropTypes.string.isRequired,
  logouting: PropTypes.bool,
  subscriptionStatusChanges: PropTypes.bool,
  companyInformationSuccess: PropTypes.bool,
  paymentMethodsSelected: PropTypes.array,
  language: PropTypes.string,
  fnInitDashboard: PropTypes.func,
  fnMerchantMe: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.dashboard.loading,
  error: state.dashboard.error,
  merchantMe: state.dashboard.merchantMe,
  canOperate: state.dashboard.merchantMe?.canOperate,
  paymentMethodsIsRequired: !state.dashboard.merchantMe?.hasPaymentMethods,
  creditCardEnabled: state.dashboard.merchantMe?.kycLevel?.creditCardEnabled,
  emailValidationIsRequired: !state.dashboard.userMe?.emailConfirmed,
  logouting: state.auth.logouting,
  paymentMethodsSelected: state.paymentMethods.paymentMethodsSelected,
  language: state.dashboard.userMe?.language,
});

const mapDispatchToProps = dispatch => ({
  fnInitDashboard: menuSelected => dispatch(initDashboard(menuSelected)),
  fnMerchantMe: () => dispatch(getMerchantMe()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
