import { ReactNode } from 'react';
import './styles.scss';

interface CardDescriptionProps {
  children: ReactNode;
}

const CardDescription = ({ children }: CardDescriptionProps) => <div className="card-description">{children}</div>;

export default CardDescription;
