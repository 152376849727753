import { takeLatest, put, call, select } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { deleteTask, getTask, patchTask, uploadTask } from '../../services';
import {
  CompanyInformationActionType,
  getDocumentTypesForKYCSuccess,
  getDocumentTypesForKYCError,
  sendKYCSuccess,
  sendKYCError,
  deleteLogoSuccess,
  deleteLogoError,
  uploadNewLogoSuccess,
  uploadNewLogoError,
  getKycLevelsSuccess,
  getKycLevelsError,
  getIndustriesSuccess,
  getIndustriesError,
  getMissingDocumentsSuccess,
  getMissingDocumentsError,
} from './actions';
import { getMerchantMe } from '../dashboard/actions';
import { gtagOnboardingStep, segmentKycFormL2DocsSubmit, segmentOnboardingStep } from '../../utils/analytics';
import { PanelStore } from '../interfaces';

function* getDocumentTypesForKYC(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.documentTypes}?country=${action.country}`,
    token: state.auth.token,
  });

  if (response) {
    yield put(getDocumentTypesForKYCSuccess(response));
  } else {
    yield put(getDocumentTypesForKYCError(error));
  }
}

export function* getDocumentTypesForKYCSaga() {
  yield takeLatest(CompanyInformationActionType.GET_DOCUMENT_TYPES_FOR_KYC, getDocumentTypesForKYC);
}

function* sendKYC(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { website } = action.values;

  const body = {
    ...action.values,
    website: website?.trim() === '' ? null : website,
    kycLevel: action.kycLevel,
  };

  const url = body.kycLevel === 4 ? Urls.api.companyInformationV2 : Urls.api.companyInformation;
  const { response, error } = yield call(body.kycLevel === 4 ? uploadTask : patchTask, {
    url: url.replace(':id', `${state.dashboard.merchantMe?.id || ''}`),
    body,
    token: state.auth.token,
  });
  yield put(getMerchantMe());

  const event = body.kycLevel === 4 ? 'Step_2' : 'Step_1';
  const type = body.company || state.dashboard.merchantMe?.company ? 'company' : 'individual';

  if (response) {
    const email = state.dashboard.userMe?.email || '';
    gtagOnboardingStep(event, type === 'company' ? 'Company' : 'Individual', email);
    if (event === 'Step_1') {
      segmentOnboardingStep({
        companyType: type,
        website: body.website,
        industry: body.subIndustryId,
      });
    }
    if (event === 'Step_2') {
      segmentKycFormL2DocsSubmit();
    }
    yield put(sendKYCSuccess());
  } else {
    if (event === 'Step_1') {
      segmentOnboardingStep({
        companyType: type,
        website: body.website,
        industry: body.subIndustryId,
        error,
      });
    }
    if (event === 'Step_2') {
      segmentKycFormL2DocsSubmit({
        error,
      });
    }
    yield put(sendKYCError({ ...error, sentValues: body }));
  }
}

export function* sendKYCSaga() {
  yield takeLatest(CompanyInformationActionType.SEND_KYC, sendKYC);
}

function* deleteLogo() {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(deleteTask, {
    url: Urls.api.deleteLogo.replace(':id', `${state.dashboard.merchantMe?.id || ''}`),
    token: state.auth.token,
  });

  if (response) {
    yield put(deleteLogoSuccess(response));
  } else {
    yield put(deleteLogoError(error));
  }
}

export function* deleteLogoSaga() {
  yield takeLatest(CompanyInformationActionType.DELETE_LOGO, deleteLogo);
}

function* uploadLogo(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();
  const { response, error } = yield call(patchTask, {
    url: Urls.api.uploadLogo.replace(':id', `${state.dashboard.merchantMe?.id || ''}`),
    body: {
      logo: action.logo,
    },
    token: state.auth.token,
  });

  if (response) {
    yield put(uploadNewLogoSuccess(response));
  } else {
    yield put(uploadNewLogoError(error));
  }
}

export function* uploadLogoSaga() {
  yield takeLatest(CompanyInformationActionType.UPLOAD_LOGO, uploadLogo);
}

function* getKycLevels() {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();
  const { response, error } = yield call(getTask, {
    url: Urls.api.kycLevels,
    token: state.auth.token,
  });

  if (response) {
    yield put(getKycLevelsSuccess(response));
  } else {
    yield put(getKycLevelsError(error));
  }
}

export function* getKycLevelsSaga() {
  yield takeLatest(CompanyInformationActionType.GET_KYC_LEVELS, getKycLevels);
}

function* getIndustries() {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: Urls.api.industries,
    token: state.auth.token,
  });

  if (response) {
    yield put(getIndustriesSuccess(response));
  } else {
    yield put(getIndustriesError(error));
  }
}

export function* getIndustriesSaga() {
  yield takeLatest(CompanyInformationActionType.GET_INDUSTRIES, getIndustries);
}

function* getMissingDocuments() {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: Urls.api.missingDocuments.replace(':merchantId', `${state.dashboard.merchantMe?.id || ''}`),
    token: state.auth.token,
  });

  if (response) {
    yield put(getMissingDocumentsSuccess(response));
  } else {
    yield put(getMissingDocumentsError(error));
  }
}

export function* getMissingDocumentsSaga() {
  yield takeLatest(CompanyInformationActionType.GET_MISSING_DOCUMENTS, getMissingDocuments);
}
