import MUIChip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

const DefaultChip = (props: ChipProps) => {
  return <MUIChip deleteIcon={<CloseIcon />} variant="outlined" {...props} />;
};

export const Chip = styled(DefaultChip)(({ theme }) => ({
  height: '24px',
  fontSize: '13px',
  lineHeight: '18px',
  background: theme.palette.primary.light,
  border: `1px solid ${theme.palette.primary.light}`,
  borderRadius: '4px',
  color: theme.palette.primary.main,
  '& .MuiChip-deleteIcon': {
    color: theme.palette.primary.main,
    width: '18px',
    height: '18px',
    ':hover': {
      color: theme.palette.primary.main,
      opacity: '0.6',
    },
  },
}));
