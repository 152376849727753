import { MouseEvent, ReactNode, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ButtonMore, { ButtonMoreProps } from '../button-more';

interface ButtonMoreMenuProps extends ButtonMoreProps {
  items: { content: string | ReactNode; onClick: () => void; hidden?: boolean }[];
}

const ButtonMoreMenu = (props: ButtonMoreMenuProps) => {
  const { id, items, onClick, ...others } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ButtonMore
        id={id}
        onClick={e => {
          handleClick(e);
          onClick && onClick(e);
        }}
      />
      <Menu
        id="button-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items
          .filter(i => !i.hidden)
          .map((item, idx) => (
            <MenuItem
              key={idx}
              onClick={e => {
                handleClose();
                item.onClick();
              }}
            >
              {item.content}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default ButtonMoreMenu;
