import { defaultLang, getLanguage } from '../../utils/index';

const translate = (key, translations, ...args) => {
  const lang = getLanguage();
  const params = args;
  const code = lang || defaultLang;
  const translation = translations[key];
  if (translation !== undefined) {
    let message = translation[code] || translation[defaultLang];
    if (params.length > 0) {
      for (let i = 0; i < params.length; i += 1) {
        message = message.replace(`{${i}}`, params[i]);
      }
    }
    return message;
  }
  return key;
};

export default translate;
