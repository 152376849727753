import { takeLatest, put, call, select } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { getTask, postTask } from '../../services';
import {
  RefundsActionType,
  getRefundsSuccess,
  getRefundsError,
  getBanksSuccess,
  getBanksError,
  sendRequestRefundSuccess,
  sendRequestRefundError,
  getPaymentMethodsForRefundsSuccess,
  getPaymentMethodsForRefundsError,
} from './actions';
import { PanelStore } from '../interfaces';

function* getRefunds(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  let params = `?page=${action.page || 0}`;
  params += action.filter ? `&${action.filter}` : '';

  const { response, error } = yield call(getTask, {
    url: Urls.api.allRefunds + params,
    token: state.auth.token,
  });

  if (response) {
    yield put(getRefundsSuccess(response));
  } else {
    yield put(getRefundsError(error));
  }
}

export function* getRefundsSaga() {
  yield takeLatest(RefundsActionType.GET_REFUNDS, getRefunds);
}

function* getBanks(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.banks}?country=${action.country}`,
    token: state.auth.token,
  });

  if (response) {
    yield put(getBanksSuccess(response));
  } else {
    yield put(getBanksError(error));
  }
}

export function* getBanksSaga() {
  yield takeLatest(RefundsActionType.GET_BANKS, getBanks);
}

function* sendRequestRefund(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();
  const body = { ...action.values };
  const { response, error } = yield call(postTask, {
    url: Urls.api.requestRefunds,
    body,
    token: state.auth.token,
  });

  if (response) {
    yield put(sendRequestRefundSuccess(response));
  } else {
    yield put(sendRequestRefundError({ ...error, sentValues: body }));
  }
}

export function* sendRequestRefundSaga() {
  yield takeLatest(RefundsActionType.REQUEST_REFUND, sendRequestRefund);
}

function* getPaymentMethodsForRefunds(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.allPaymentMethods}?include_deleted=1&page_size=50&country=${action.country}`,
    token: state.auth.token,
  });

  if (response) {
    yield put(getPaymentMethodsForRefundsSuccess(response));
  } else {
    yield put(getPaymentMethodsForRefundsError(error));
  }
}

export function* getPaymentMethodsForRefundsSaga() {
  yield takeLatest(RefundsActionType.GET_PAYMENT_METHODS_FOR_REFUNDS, getPaymentMethodsForRefunds);
}
