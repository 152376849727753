import React from 'react';
import './styles.scss';

/* interface TableInfoProps {
  title: ReactNode | string;
  rows: { label: String, value: string }[];
} */

const TableInfo = ({ title, rows = {} }) => {
  return (
    <div className="table_info">
      <div className="table_info_title">{title}</div>
      <div className="table_info_content">
        {rows.map((row, idx) => (
          <div key={`${row.label}`} className={`table_info_row ${idx ? 'table_info_row_border_top' : ''}`}>
            <div className="table_info_row_label">{row.label}</div>
            <div className="table_info_row_value">{row.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableInfo;
