export const Routes = {
  LOGIN: '/',
  SIGN_UP: '/signup',
  SIGN_UP_PLUGIN: '/plugin/signup',
  HOME: '/home',
  REPORTS: '/reports',
  ACTIVATE_ACCOUNT: '/activate-account',
  EMAIL_VALIDATION: '/email-validation',
  CONFIRM_EMAIL: '/user/confirm_email',
  PAYMENT_METHODS: '/payment-methods',
  TRANSACTIONS: '/transactions',
  TRANSACTION_DETAIL: '/transactions/:invoice_id/detail',
  SETTLEMENTS: '/settlements',
  PAYMENT_LINK: '/payment-link',
  CREATE_ONE_TIME_PAYMENT_LINK: '/payment-link/create',
  CREATE_RECURRING_PAYMENT_LINK: '/payment-link/create/recurring',
  CREATED_PAYMENT_LINKS: '/payment-link/created',
  VIRTUAL_POS: '/virtual-pos',
  PAYMENT_BUTTON: '/payment-button',
  PAYMENT_INTEGRATION: '/payment-integration',
  NOT_FOUND: '/not-found',
  COMPANY_INFORMATION: '/company-information',
  REFUNDS: '/refunds',
  PASSWORD_RESET: '/password-reset',
  USER_PASSWORD_RESET: '/user/password-reset',
  CLIENTS: '/clients',
  BANK_ACCOUNTS: '/bank-accounts',
  SUBSCRIPTIONS: '/subscriptions',
  SUBSCRIPTIONS_PLANS: '/subscriptions/plans',
  SUBSCRIPTIONS_DETAIL: '/subscriptions/detail',
  SUBSCRIPTIONS_PLANS_DETAILS: '/subscriptions/plans/details',
  SUBSCRIPTIONS_PLANS_CREATE: '/subscriptions/plans/create',
  SUBSCRIPTIONS_PLANS_EDIT: '/subscriptions/plans/:id/edit',
};
