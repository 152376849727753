import { FormHelperText, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { countryPhoneCodes } from '../../../config';
import { Urls } from '../../../config/urls';
import l from '../../../lang';
import './styles.scss';

interface InputCountryPhoneProps {
  type: string;
  name: string;
  label: string;
  value: string;
  defaultCountry: string;
  disabled?: boolean;
  error?: string;
  onChange: (value: string) => void;
  onBlur?: (...args: any[]) => void;
}

const InputCountryPhone = ({
  type,
  name,
  label,
  defaultCountry,
  value,
  disabled,
  error,
  onChange,
  onBlur,
}: InputCountryPhoneProps) => {
  const [country, setCountry] = useState(defaultCountry);
  const codes: { [key: string]: string } = countryPhoneCodes;

  useEffect(() => {
    if (!value) handleOnChangeCountry(defaultCountry);
  }, [defaultCountry]);

  const handleOnChange = (value: string, country: string) => {
    onChange(value ? `${codes[country]}${value}` : value);
  };

  const handleOnChangeCountry = (newCountry: string) => {
    setCountry(newCountry);
    handleOnChange(getValueWithoutCode(value, country), newCountry);
  };

  const getValueWithoutCode = (value: string, country: string) => {
    return value.substring(`${country ? codes[country] : ''}`.length, value.length);
  };

  const getFlag = (code: string) => (
    <img className="input_country_phone__flag" src={Urls.flags.replace(':code', code)} alt="" />
  );

  return (
    <div className="input_country_phone">
      <div className='input_country_phone__container"'>
        <Select
          className="input_country_phone__select"
          value={country}
          name="code-country"
          onChange={e => handleOnChangeCountry(e.target.value)}
          disabled={disabled}
          renderValue={(code: string) => {
            return <div className="input_country_phone__flag_selected">{getFlag(code)}</div>;
          }}
          error={!!error}
        >
          {Object.keys(codes).map(code => (
            <MenuItem value={code} key={code}>
              {getFlag(code)}
              <div className="input_country_phone__option__country">{l(`countryCode.${code}`)}</div>
              <div className="input_country_phone__option__code">{codes[code]}</div>
            </MenuItem>
          ))}
        </Select>
        <TextField
          className="input_country_phone__text_field"
          type={type}
          name={name}
          label={label}
          onChange={e => handleOnChange(e.target.value, country)}
          onBlur={onBlur}
          value={getValueWithoutCode(value, country)}
          disabled={disabled}
          error={!!error}
          inputProps={{
            maxLength: 20,
          }}
          InputProps={{
            startAdornment: country && <InputAdornment position="start">{<span>{codes[country]}</span>}</InputAdornment>,
          }}
        />
      </div>
      {error && <FormHelperText className="input_country_phone__error">{error}</FormHelperText>}
    </div>
  );
};
export default InputCountryPhone;
