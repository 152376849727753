import React from 'react';
import Parser from 'html-react-parser';
import './styles.scss';
import l from '../../../lang';

const TestMessage = () => {
  if (import.meta.env.REACT_APP_ENV !== 'production') {
    return <div className="test-message">{Parser(l('common.testMessage'))}</div>;
  }
  return <></>;
};

export default TestMessage;
