import './styles.scss';
import logoDlGO from '../../../../assets/images/dlocalGo.svg?url';

export const PrivacyPoliciesES = () => {
  return (
    <div className="privacy_policies_es">
      <div className="privacy_policies_es__header">
        <span className="privacy_policies_es__title">Políticas de Privacidad</span>
        <img className="privacy_policies_es__logo" src={logoDlGO} alt="" />
      </div>
      <div className="privacy_policies_es__content">
        {/* CONTENT EXPORTED DIRECTLY FROM GOOGLE DOC */}
        <div className="c3 doc-content">
          <p className="c32">
            <span className="c2">&Uacute;ltima Actualizaci&oacute;n: </span>
            <span className="c0">8 de junio de 2022.</span>
          </p>
          <p className="c32">
            <span className="c30">E</span>
            <span className="c30">ste es el aviso de privacidad de dLocal LLP</span>
          </p>
          <ol className="c14 lst-kix_list_6-0 start" start={1}>
            <li className="c16 li-bullet-0">
              <h1 id="h.wgl8hxd4a3jd" style={{ display: 'inline' }}>
                <span className="c24">Introducci&oacute;n</span>
              </h1>
            </li>
          </ol>
          <p className="c18">
            <span className="c0">
              Su privacidad es muy importante para nosotros. Estamos comprometidos con la protecci&oacute;n de
            </span>
          </p>
          <p className="c18">
            <span className="c0">
              sus Datos Personales, y el prop&oacute;sito de este Aviso de Privacidad es informarle sobre la forma
            </span>
          </p>
          <p className="c18">
            <span className="c0">
              en que tratamos sus Datos Personales, incluyendo referencias a qu&eacute; datos tratamos, c&oacute;mo, por
            </span>
          </p>
          <p className="c18">
            <span className="c0">
              qu&eacute; y durante cu&aacute;nto tiempo, junto con informaci&oacute;n sobre sus derechos como Titular de los
              Datos.
            </span>
          </p>
          <p className="c25 c32">
            <span className="c24 c45"></span>
          </p>
          <p className="c18">
            <span className="c0">Este Aviso de Privacidad (</span>
            <span className="c0 c12">junto con los T&eacute;rminos de nuestro Sitio Web </span>
            <span className="c24 c12 c27">
              <a className="c5" href="https://docs.dlocalgo.com/documentation/terms-and-conditions">
                AQU&Iacute;
              </a>
            </span>
            <span className="c0 c12">),</span>
            <span className="c0">
              &nbsp;y otros documentos a los que se hace referencia all&iacute;) establece las bases conforme a las cuales
              los Datos Personales que recopilamos de usted o que usted nos da, ser&aacute;n tratados. Este Aviso de
              Privacidad tambi&eacute;n establece c&oacute;mo puede darnos instrucciones si prefiere limitar el uso de esos
              Datos Personales, as&iacute; como los procedimientos que tenemos para salvaguardar su privacidad.
            </span>
          </p>
          <p className="c18 c25">
            <span className="c0"></span>
          </p>
          <p className="c18">
            <span className="c0">
              Es importante que lea este Aviso de Privacidad junto con cualquier otro aviso de privacidad o de tratamiento
              justo que podamos proporcionar en ocasiones espec&iacute;ficas cuando recojamos o tratemos Datos Personales
              sobre usted, para que sea plenamente consciente de c&oacute;mo y por qu&eacute; estamos utilizando sus Datos
              Personales. Este Aviso de Privacidad complementa los otros avisos y no pretende sustituirlos.
            </span>
          </p>
          <ol className="c14 lst-kix_list_6-0" start={2}>
            <li className="c16 li-bullet-0">
              <h1 style={{ display: 'inline' }}>
                <span className="c24">Informaci&oacute;n importante</span>
              </h1>
            </li>
          </ol>
          <h2 className="c34">
            <span className="c11">Entidad</span>
          </h2>
          <p className="c28">
            <span className="c0">La direcci&oacute;n de Dlocal LLP</span>
            <span className="c2">&nbsp;</span>
            <span className="c0">es 4 King&rsquo;s Bench Walk, Londres EC4Y 7DL, Reino Unido.</span>
          </p>
          <p className="c17">
            <span className="c0"></span>
          </p>
          <p className="c28">
            <span className="c0">
              Dlocal LLP puede actuar como Controlador de Datos Personales o Procesador de Datos Personales y se menciona en
              este Aviso de Privacidad como{' '}
            </span>
            <span className="c2">&#39;dLocal&#39;</span>
            <span className="c0">, </span>
            <span className="c2">&#39;nosotros&#39;</span>
            <span className="c0">&nbsp;o </span>
            <span className="c2">&#39;nuestro&#39;</span>
            <span className="c0">&nbsp;o </span>
            <span className="c2">&#39;nosotros&#39;</span>
            <span className="c0">.</span>
          </p>
          <p className="c17">
            <span className="c0"></span>
          </p>
          <h2 className="c34">
            <span className="c11">
              Personas de las que recogemos Datos Personales (los &ldquo;Titulares de los Datos&rdquo;)
            </span>
          </h2>
          <p className="c18">
            <span className="c0">En este Aviso de Privacidad, </span>
            <span className="c2">&ldquo;usted&rdquo;</span>
            <span className="c0">&nbsp;o </span>
            <span className="c2">&ldquo;su&rdquo;</span>
            <span className="c0">
              &nbsp;se refiere a una persona f&iacute;sica que es titular de los Datos Personales que tratamos como
              responsable del tratamiento, que normalmente ser&iacute;an: los visitantes de nuestro sitio web en
            </span>
            <span className="c24">&nbsp;</span>
            <span className="c27 c24">
              <a className="c5" href="http://www.dlocalgo.com">
                www.dloc
              </a>
            </span>
            <span className="c24 c55">
              <a className="c5" href="http://www.dlocalgo.com">
                algo
              </a>
            </span>
            <span className="c27 c24">
              <a className="c5" href="http://www.dlocalgo.com">
                .com
              </a>
            </span>
            <span className="c24">&nbsp;(</span>
            <span className="c0">nuestro </span>
            <span className="c2">&ldquo;Sitio Web&rdquo;</span>
            <span className="c0">
              ); y (ii) los representantes y usuarios finales de los comerciantes en l&iacute;nea y otros proveedores de
              pagos (nuestros{' '}
            </span>
            <span className="c2">&ldquo;Clientes&rdquo;</span>
            <span className="c0">
              ), que interact&uacute;an con nosotros, abren cuentas de usuario con nosotros y acceden a nuestra plataforma
              para recibir nuestros servicios de procesamiento de pagos.
            </span>
          </p>
          <p className="c25 c61">
            <span className="c45 c24"></span>
          </p>
          <p className="c28">
            <span className="c0">Para efectos de este Aviso de Privacidad, </span>
            <span className="c2">&ldquo;Legislaci&oacute;n de Protecci&oacute;n de Datos&rdquo;</span>
            <span className="c0">
              &nbsp;significa: (i) en el Reino Unido: la Ley de Protecci&oacute;n de Datos del Reino Unido de 2018; y (ii) el
              RGPD modificado y adoptado por la legislaci&oacute;n del Reino Unido en virtud de la secci&oacute;n 3 de la Ley
              de la Uni&oacute;n Europea (Retirada) de 2018 (el{' '}
            </span>
            <span className="c2">&ldquo;RGPD del Reino Unido&rdquo;</span>
            <span className="c0">).</span>
          </p>
          <h2 className="c34">
            <span className="c11">Detalles de Contacto</span>
          </h2>
          <p className="c18">
            <span className="c0">
              Hemos designado a un encargado de la protecci&oacute;n de datos (DPO) que es responsable de supervisar las
              cuestiones relacionadas con este Aviso de Privacidad. Si tiene alguna pregunta sobre este Aviso de Privacidad,
              incluyendo cualquier solicitud para ejercer sus derechos legales, p&oacute;ngase en contacto con nosotros por
              correo electr&oacute;nico:{' '}
            </span>
            <span className="c24 c36">
              <a className="c5" href="mailto:dpo@dlocal.com">
                dpo@dlocal.com
              </a>
            </span>
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c20">
            <span className="c0">
              Tiene derecho a presentar una queja en cualquier momento ante la autoridad de control aplicable en materia de
              protecci&oacute;n de datos: &nbsp;la Oficina del Comisionado de Informaci&oacute;n del Reino Unido (
            </span>
            <span className="c36 c24">
              <a className="c5" href="https://ico.org.uk/">
                https://ico.org.uk/
              </a>
            </span>
            <span className="c0">) &nbsp;</span>
          </p>
          <p className="c21">
            <span className="c0">
              Sin embargo, nos gustar&iacute;a tener la oportunidad de abordar sus preocupaciones antes de que se dirija a la
              OIC, por lo que le rogamos que se ponga en contacto con nosotros en primer lugar.
            </span>
          </p>
          <ol className="c14 lst-kix_list_6-0" start={3}>
            <li className="c16 li-bullet-0">
              <h1 style={{ display: 'inline' }}>
                <span className="c24">Informaci&oacute;n que podemos recopilar (o recibir) sobre usted</span>
              </h1>
            </li>
          </ol>
          <p className="c33">
            <span className="c0">C&oacute;mo se recopilan sus datos personales:</span>
          </p>
          <p className="c59 c25">
            <span className="c43 c24"></span>
          </p>
          <ol className="c14 lst-kix_list_5-0 start" start={1}>
            <li className="c51 c38 li-bullet-1">
              <span className="c2">Interacciones directas. </span>
              <span className="c0">
                Usted puede facilitarnos sus Datos de Identidad y de Contacto (tal y como se definen a continuaci&oacute;n)
                llenando formularios o manteniendo correspondencia con nosotros por correo, tel&eacute;fono, correo
                electr&oacute;nico o de otra manera. Esto incluye los Datos Personales que usted proporciona como
                representante de un Cliente o de un posible Cliente cuando usted:
              </span>
            </li>
          </ol>
          <ol className="c14 lst-kix_list_5-1 start" start={1}>
            <li className="c53 c60 li-bullet-2">
              <span className="c24">S</span>
              <span className="c0">olicite o pregunte por nuestros productos o servicios;</span>
            </li>
            <li className="c39 li-bullet-2">
              <span className="c24">I</span>
              <span className="c0">
                nteract&uacute;a con nosotros en relaci&oacute;n con nuestros servicios o nuestra relaci&oacute;n con el
                Cliente al que representa;
              </span>
            </li>
            <li className="c39 li-bullet-3">
              <span className="c24">C</span>
              <span className="c0">
                rea una cuenta de usuario del Cliente en nuestra plataforma de servicios de procesamiento de pagos para
                recibir nuestros servicios; o
              </span>
            </li>
            <li className="c4 li-bullet-4">
              <span className="c24">S</span>
              <span className="c0">e suscribe a nuestros servicios o publicaciones.</span>
            </li>
          </ol>
          <p className="c32 c25">
            <span className="c0"></span>
          </p>
          <ol className="c14 lst-kix_list_5-0" start={2}>
            <li className="c15 c38 li-bullet-5">
              <span className="c2">Usted usa e interact&uacute;a con nuestro Sitio Web. </span>
              <span className="c0">
                Cuando usted navega por nuestro sitio web, procesamos Datos T&eacute;cnicos (definidos a
                continuaci&oacute;n). Utilizamos estos datos para nuestros intereses leg&iacute;timos de asegurarnos de que
                nuestro sitio web funciona correctamente, incluyendo la depuraci&oacute;n, para poder entregarle su
                contenido, para la mitigaci&oacute;n de DDOS en nuestro sitio web, y para mejorar nuestro sitio web y
                realizar an&aacute;lisis estad&iacute;sticos para optimizar la calidad de nuestro sitio web.{' '}
              </span>
              <span className="c0 c12">Vea nuestra Pol&iacute;tica sobre </span>
              <span className="c0 c12">Cookie</span>
              <span className="c0">s </span>
              <span className="c27 c24">
                <a className="c5" href="https://dlocalgo.com/es/legal/politicas-de-cookies">
                  AQU&Iacute;
                </a>
              </span>
              <span className="c0">&nbsp;si desea detalles adicionales.</span>
            </li>
            <li className="c40 c38 li-bullet-5">
              <span className="c2">Soluciones SmartFields y Redirect. </span>
              <span className="c0">
                Cuando usted adquiere bienes o servicios de los clientes de dLocal que han elegido nuestras soluciones
                Smartfield y Redirect para el cobro de los pagos, lo m&aacute;s probable es que est&eacute; dando sus Datos
                Personales directamente a dLocal.
              </span>
            </li>
            <li className="c40 c38 li-bullet-1">
              <span className="c2">Diligencia Debida. </span>
              <span className="c0">
                Cuando usted solicita convertirse en un cliente comercial de dLocal, le pedimos que nos proporcione los datos
                personales que se detallan en este p&aacute;rrafo, pero sin limitarse a esta descripci&oacute;n. Por motivos
                regulatorios, podemos solicitar el nombre, la direcci&oacute;n postal, el n&uacute;mero de tel&eacute;fono y
                la direcci&oacute;n de correo electr&oacute;nico para cumplir con nuestro socio financiero y los requisitos
                regulatorios. Tambi&eacute;n podemos recopilar informaci&oacute;n financiera y personal sobre usted, como su
                participaci&oacute;n en la empresa, su condici&oacute;n de director o personero, su fecha de nacimiento e
                identificadores gubernamentales asociados a usted y a su negocio (como su n&uacute;mero de seguridad social,
                n&uacute;mero de identificaci&oacute;n fiscal o n&uacute;mero de identificaci&oacute;n del patrono).
                Tambi&eacute;n podemos solicitar informaci&oacute;n sobre cuentas bancarias.
              </span>
            </li>
            <li className="c38 c56 li-bullet-6">
              <span className="c2">Terceros o fuentes disponibles al p&uacute;blico. </span>
              <span className="c0">
                Podemos recibir Datos Personales sobre usted de varias terceros y fuentes p&uacute;blicas, cuando realizamos
                comprobaciones de antecedentes, comprobaciones de prevenci&oacute;n de fraude, comprobaciones de
                identificaci&oacute;n y otras &ldquo;Conozca a su Cliente&rdquo; que necesitamos realizar sobre los
                representantes de nuestros clientes para cumplir con las normas y requisitos de servicios financieros
                aplicables y para cumplir con las leyes y reglamentos aplicables.
              </span>
            </li>
          </ol>
          <p className="c23 c25">
            <span className="c0"></span>
          </p>
          <p className="c23">
            <span className="c0">
              Podemos recoger, utilizar, almacenar y transferir diferentes tipos de datos sobre usted que hemos agrupado de
              la siguiente manera:
            </span>
          </p>
          <ol className="c14 lst-kix_list_4-0 start" start={1}>
            <li className="c51 c38 li-bullet-1">
              <span className="c2">Datos de Identidad </span>
              <span className="c0">
                incluye nombre y apellido, nombre de usuario y n&uacute;mero del documento de identificaci&oacute;n;
              </span>
            </li>
          </ol>
          <p className="c44 c25 c54">
            <span className="c24 c46"></span>
          </p>
          <ol className="c14 lst-kix_list_4-0" start={2}>
            <li className="c18 c38 li-bullet-6">
              <span className="c2">Datos de Contacto </span>
              <span className="c0">
                incluye, datos de contacto, direcci&oacute;n de facturaci&oacute;n, direcci&oacute;n de entrega,
                direcci&oacute;n de correo electr&oacute;nico y n&uacute;meros de tel&eacute;fono;
              </span>
            </li>
            <li className="c9 li-bullet-1">
              <span className="c2">Datos T&eacute;cnicos </span>
              <span className="c0">
                incluye su direcci&oacute;n de protocolo de Internet (IP), sus datos de inicio de sesi&oacute;n, el
                identificador de Google Analytics, el tipo de navegador de Internet y de dispositivo, la configuraci&oacute;n
                de la zona horaria, los datos de localizaci&oacute;n y el uso que hace de nuestro sitio web, incluyendo las
                p&aacute;ginas que ha visitado, c&oacute;mo ha llegado a nuestro sitio web, la hora y la duraci&oacute;n de
                su visita y sus preferencias de idioma;
              </span>
            </li>
            <li className="c31 li-bullet-5">
              <span className="c2">Datos de los usuarios Representantes del Cliente:</span>
              <span className="c0">
                &nbsp;datos de uso generados por los representantes de nuestros Clientes cuando acceden a nuestra plataforma
                o cuando interact&uacute;an con nosotros por correo electr&oacute;nico o de otro modo.
              </span>
            </li>
            <li className="c49 c38 li-bullet-1">
              <span className="c2">Datos del Perfil </span>
              <span className="c0">
                incluye el nombre de usuario y la contrase&ntilde;a de los representantes de nuestros Clientes;
              </span>
            </li>
          </ol>
          <p className="c32 c25 c44">
            <span className="c0"></span>
          </p>
          <ol className="c14 lst-kix_list_4-0" start={6}>
            <li className="c18 c38 li-bullet-1">
              <span className="c2">Datos de Mercadeo y Comunicaciones: </span>
              <span className="c0">
                su nombre, cargo y datos comerciales, e incluye sus preferencias a la hora de recibir publicidad nuestra y de
                terceros, as&iacute; como sus preferencias de comunicaci&oacute;n;{' '}
              </span>
              <span className="c0">y</span>
            </li>
            <li className="c38 c53 li-bullet-1">
              <span className="c2">Datos Financieros </span>
              <span className="c0">incluye datos de tarjetas, datos de cuentas bancarias, informaci&oacute;n fiscal;</span>
            </li>
          </ol>
          <p className="c32 c44 c25">
            <span className="c0"></span>
          </p>
          <ol className="c14 lst-kix_list_4-0" start={8}>
            <li className="c18 c38 li-bullet-5">
              <span className="c2">Categor&iacute;a especial de datos personales, </span>
              <span className="c0">
                como los datos biom&eacute;tricos. Hay circunstancias en las que podemos recoger datos personales especiales;
                por ejemplo, para confirmar la identidad de nuestros clientes.
              </span>
            </li>
          </ol>
          <p className="c18 c25">
            <span className="c0"></span>
          </p>
          <p className="c15">
            <span className="c0">Tambi&eacute;n recopilamos, utilizamos y compartimos </span>
            <span className="c2">Datos Agregados</span>
            <span className="c0">
              &nbsp;como datos estad&iacute;sticos o demogr&aacute;ficos para cualquier prop&oacute;sito. Los Datos Agregados
              podr&iacute;an derivarse de sus Datos Personales, pero no se consideran Datos Personales por ley, ya que estos
              datos no revelan directa o indirectamente su identidad. Por ejemplo, podemos agregar los Datos T&eacute;cnicos
              para calcular el porcentaje de usuarios que acceden a una funci&oacute;n espec&iacute;fica del sitio web.
            </span>
          </p>
          <p className="c21">
            <span className="c0">
              Podemos recopilar informaci&oacute;n sobre condenas e infracciones penales, pero s&oacute;lo en el contexto de
              controles de fraude o de seguridad cuando sea necesario para cumplir con la legislaci&oacute;n aplicable o con
              cualquier norma o requisito de los servicios financieros.
            </span>
          </p>
          <p className="c29 c25">
            <span className="c45 c24"></span>
          </p>
          <h3 className="c18">
            <span className="c2">Si usted no proporciona Datos Personales</span>
          </h3>
          <p className="c18 c25">
            <span className="c0"></span>
          </p>
          <p className="c18">
            <span className="c0">
              Cuando necesitemos recopilar Datos Personales por ley, o en virtud de los t&eacute;rminos de un contrato que
              tengamos con usted, y usted no proporcione esos datos cuando se le soliciten, es posible que no podamos cumplir
              el contrato que tenemos o que estamos intentando firmar con usted (por ejemplo, para proporcionarle bienes o
              servicios). En este caso, es posible que tengamos que cancelar un producto o servicio que tenga con nosotros,
              pero se lo notificaremos en ese momento.
            </span>
          </p>
          <ol className="c14 lst-kix_list_6-0" start={4}>
            <li className="c16 li-bullet-0">
              <h1 style={{ display: 'inline' }}>
                <span className="c24">C&oacute;mo usamos los datos personales</span>
              </h1>
            </li>
          </ol>
          <p className="c19">
            <span className="c0">
              Podemos tratar sus Datos Personales por uno o m&aacute;s fundamentos legales de tratamiento (
            </span>
            <span className="c2">&ldquo;Fundamento Legal&rdquo;</span>
            <span className="c0">
              ) dependiendo de la finalidad espec&iacute;fica para la que estamos utilizando sus datos (ver m&aacute;s
              abajo).
            </span>
          </p>
          <p className="c25 c59">
            <span className="c45 c24"></span>
          </p>
          <p className="c28">
            <span className="c0">D</span>
            <span className="c0">
              e acuerdo con este Aviso de Privacidad, dLocal puede utilizar sus Datos Personales para:
            </span>
          </p>
          <p className="c29 c25">
            <span className="c24 c43"></span>
          </p>
          <ol className="c14 lst-kix_list_3-0 start" start={1}>
            <li className="c38 c52 li-bullet-1">
              <span className="c24">P</span>
              <span className="c7">restar los servicios que nos solicite </span>
              <span className="c37 c24">
                (Fundamento Legal: para cumplir con nuestras obligaciones legales y la ejecuci&oacute;n de nuestro contrato
                con usted)
              </span>
            </li>
            <li className="c6 li-bullet-5">
              <span className="c24">V</span>
              <span className="c7">
                erificar su identidad o realizar las comprobaciones oportunas sobre solvencia o fraude{' '}
              </span>
              <span className="c37 c24">(Fundamento Legal: para cumplir con nuestras obligaciones legales </span>
              <span className="c7">y lo necesario para nuestros intereses leg&iacute;timos</span>
              <span className="c37 c24">)</span>
              <span className="c0">;</span>
            </li>
            <li className="c31 li-bullet-1">
              <span className="c24">C</span>
              <span className="c7">omprender sus necesidades para ofrecerle los productos y servicios que necesita </span>
              <span className="c37 c24">(Fundamento Legal: </span>
              <span className="c7">el cumplimiento de nuestro contrato con usted</span>
              <span className="c37 c24">)</span>
              <span className="c0">;</span>
            </li>
            <li className="c41 c38 li-bullet-5">
              <span className="c24">A</span>
              <span className="c7">
                dministrar y gestionar nuestros servicios, incluyendo la facturaci&oacute;n de los servicios prestados y el
                cobro de deudas{' '}
              </span>
              <span className="c37 c24">(Fundamento Legal: </span>
              <span className="c7">el cumplimiento de nuestro contrato con usted</span>
              <span className="c37 c24">, cumplir con nuestras obligaciones legales </span>
              <span className="c7">y lo necesario para nuestros intereses leg&iacute;timos</span>
              <span className="c24 c37">)</span>
              <span className="c0">;</span>
            </li>
            <li className="c40 c38 li-bullet-1">
              <span className="c24">D</span>
              <span className="c7">
                istribuir informaci&oacute;n, boletines, publicaciones y otras comunicaciones a trav&eacute;s de diversos
                medios para mantenerle informado{' '}
              </span>
              <span className="c37 c24">(Fundamento Legal: su consentimiento, </span>
              <span className="c7">
                el cumplimiento de nuestro contrato con usted y lo necesario para nuestros intereses leg&iacute;timos
              </span>
              <span className="c37 c24">)</span>
              <span className="c7">;</span>
            </li>
            <li className="c6 li-bullet-5">
              <span className="c24">I</span>
              <span className="c7">nvestigar y desarrollar nuevas ofertas de productos y servicios </span>
              <span className="c37 c24">(Fundamento Legal: </span>
              <span className="c7">
                el cumplimiento de nuestro contrato con usted y lo necesario para nuestros intereses leg&iacute;timos
              </span>
              <span className="c37 c24">)</span>
              <span className="c0">;</span>
            </li>
            <li className="c31 li-bullet-1">
              <span className="c24">G</span>
              <span className="c7">
                estionar y dirigir nuestro negocio y los servicios que prestamos a nuestros clientes{' '}
              </span>
              <span className="c37 c24">(Fundamento Legal: </span>
              <span className="c7">
                el cumplimiento de nuestro contrato con usted y lo necesario para nuestros intereses leg&iacute;timos
              </span>
              <span className="c37 c24">)</span>
              <span className="c0">;</span>
            </li>
            <li className="c41 c38 li-bullet-1">
              <span className="c24">A</span>
              <span className="c7">
                segurarse de que nuestro sitio web funciona correctamente, incluyendo la depuraci&oacute;n, para poder
                entregarle su contenido, para la mitigaci&oacute;n de DDOS en nuestro sitio web, y para mejorar nuestro sitio
                web y realizar an&aacute;lisis estad&iacute;sticos{' '}
              </span>
              <span className="c37 c24">(Fundamento Legal: </span>
              <span className="c7">lo necesario para nuestros intereses leg&iacute;timos</span>
              <span className="c37 c24">)</span>
              <span className="c0">;</span>
            </li>
            <li className="c41 c38 li-bullet-1">
              <span className="c24">P</span>
              <span className="c7">
                roporcionarle ofertas personales adaptadas a sus necesidades y personalizar lo que le mostramos seg&uacute;n
                sus preferencias, con su consentimiento previo{' '}
              </span>
              <span className="c37 c24">(Fundamento Legal: </span>
              <span className="c7">
                el cumplimiento de nuestro contrato con usted y lo necesario para nuestros intereses leg&iacute;timos
              </span>
              <span className="c37 c24">)</span>
              <span className="c0">;</span>
            </li>
            <li className="c41 c38 li-bullet-1">
              <span className="c24">C</span>
              <span className="c0">
                omunicarnos efectivamente con terceros (Fundamento Legal: su consentimiento, el cumplimiento de nuestro
                contrato con usted y lo necesario para nuestros intereses leg&iacute;timos); y
              </span>
            </li>
            <li className="c38 c41 li-bullet-1">
              <span className="c24">S</span>
              <span className="c0">
                eg&uacute;n lo exija o autorice la legislaci&oacute;n aplicable (Fundamento Legal: para cumplir con nuestras
                obligaciones legales).
              </span>
            </li>
          </ol>
          <ol className="c14 lst-kix_list_6-0" start={5}>
            <li className="c16 li-bullet-0">
              <h1 style={{ display: 'inline' }}>
                <span className="c24">Cuando podemos revelar los datos personales</span>
              </h1>
            </li>
          </ol>
          <p className="c58">
            <span className="c0">
              Su informaci&oacute;n puede, para los fines establecidos en este Aviso de Privacidad, ser revelada para su
              tratamiento a:
            </span>
          </p>
          <p className="c25 c29">
            <span className="c42 c24"></span>
          </p>
          <ol className="c14 lst-kix_list_2-0 start" start={1}>
            <li className="c51 c38 li-bullet-1">
              <span className="c24">N</span>
              <span className="c0">
                uestros empleados, nuestros afiliados y sus empleados. Por ejemplo, dLocal compartir&aacute; su
                informaci&oacute;n con otros afiliados de Dlocal a efectos de la prestaci&oacute;n de nuestros servicios o
                cuando dichos afiliados presten servicios de apoyo a dLocal;
              </span>
            </li>
            <li className="c38 c40 li-bullet-1">
              <span className="c24">N</span>
              <span className="c0">
                uestros consultores externos, (sub)contratistas, proveedores u otros prestadores de servicios que pueden
                acceder a su informaci&oacute;n personal cuando nos prestan servicios (incluyendo, entre otros, los servicios
                de soporte inform&aacute;tico) (Esto incluye a los expertos en tecnolog&iacute;a de la informaci&oacute;n que
                dise&ntilde;an y alojan nuestro sitio web, y a las empresas de servicios generales);
              </span>
            </li>
            <li className="c31 li-bullet-1">
              <span className="c24">A</span>
              <span className="c0">
                uditores o contratistas u otros asesores que auditen, ayuden o asesoren sobre cualquiera de nuestros
                prop&oacute;sitos comerciales;
              </span>
            </li>
            <li className="c9 li-bullet-1">
              <span className="c24">P</span>
              <span className="c0">
                roveedores de an&aacute;lisis y motores de b&uacute;squeda que nos ayudan a mejorar y optimizar nuestro sitio
                web;
              </span>
            </li>
            <li className="c38 c49 li-bullet-1">
              <span className="c24">N</span>
              <span className="c0">
                uestros sucesores en el t&iacute;tulo, nuestros posibles vendedores o compradores de nuestro negocio o a
                nuestros afiliados cuando tenemos una fusi&oacute;n o reorganizaci&oacute;n;
              </span>
            </li>
            <li className="c6 li-bullet-1">
              <span className="c24">O</span>
              <span className="c0">
                rganismos gubernamentales y fuerzas de seguridad y en respuesta a otras solicitudes legales y reglamentarias;
              </span>
            </li>
            <li className="c9 li-bullet-1">
              <span className="c24">A</span>
              <span className="c0">
                &nbsp;cualquier tercero cuando dicha divulgaci&oacute;n sea necesaria para hacer cumplir o aplicar nuestras
                Condiciones del sitio web u otros acuerdos pertinentes; y
              </span>
            </li>
            <li className="c9 li-bullet-1">
              <span className="c24">P</span>
              <span className="c0">
                roteger los derechos, la propiedad, la integridad o la seguridad de nuestra compa&ntilde;&iacute;a, de
                nuestros clientes o de otros (incluyendo, entre otros, a usted). Esto incluye el intercambio de
                informaci&oacute;n con otras empresas y organizaciones con el fin de proteger el fraude y reducir el riesgo
                de cr&eacute;dito.
              </span>
              <span className="c24">C</span>
              <span className="c0">
                uando sus datos se faciliten a cualquier otra parte de acuerdo con una finalidad expresa, exigiremos que se
                mantengan seguros sus Datos Personales y que s&oacute;lo se utilicen para la finalidad prevista.
              </span>
            </li>
          </ol>
          <ol className="c14 lst-kix_list_6-0" start={6}>
            <li className="c16 li-bullet-0">
              <h1 style={{ display: 'inline' }}>
                <span className="c24">Transferencias internacionales</span>
              </h1>
            </li>
          </ol>
          <p className="c33">
            <span className="c0">
              dLocal atiende a clientes de todo el mundo. En consecuencia, sus Datos Personales pueden ser compartidos con
              otras afiliadas de Dlocal fuera del Reino Unido, cuando sea necesario para los fines mencionados en este Aviso
              de Privacidad. Estos pa&iacute;ses incluyen los pa&iacute;ses en los que tenemos operaciones. Tambi&eacute;n
              incluyen los pa&iacute;ses en los que se encuentran algunos de nuestros proveedores de servicios.
            </span>
          </p>
          <p className="c17">
            <span className="c24 c50"></span>
          </p>
          <p className="c18">
            <span className="c0">
              Para proteger sus Datos Personales cuando &eacute;stos se transfieren a pa&iacute;ses fuera del Reino Unido,
              hemos implementado las protecciones adecuadas. La transferencia de Datos Personales desde el Reino Unido a
              pa&iacute;ses no adecuados est&aacute; protegida por protecciones adecuadas, como las Cl&aacute;usulas
              Contractuales Est&aacute;ndar aprobadas por el Reino Unido.
            </span>
          </p>
          <ol className="c14 lst-kix_list_6-0" start={7}>
            <li className="c16 li-bullet-0">
              <h1 style={{ display: 'inline' }}>
                <span className="c24">Qu&eacute; sucede si usted no proporciona los datos personales solicitados</span>
              </h1>
            </li>
          </ol>
          <p className="c33">
            <span className="c0">
              Siempre que sea posible y pr&aacute;ctico, puede tener la opci&oacute;n de tratar con dLocal de forma
              an&oacute;nima o utilizando un seud&oacute;nimo. Sin embargo, en algunas circunstancias, si no podemos
              recopilar Datos Personales de usted o sobre usted, o si los Datos Personales proporcionados est&aacute;n
              incompletos o son inexactos, dLocal podr&iacute;a no ser capaz de asistirle, incluyendo la provisi&oacute;n de
              los productos o servicios que est&aacute; buscando o proporcionarle apoyo o ayudarle con sus consultas.
            </span>
          </p>
          <ol className="c14 lst-kix_list_6-0" start={8}>
            <li className="c16 li-bullet-0">
              <h1 style={{ display: 'inline' }}>
                <span className="c24">Seguridad de datos personales</span>
              </h1>
            </li>
          </ol>
          <p className="c19">
            <span className="c0">
              Internet no es un medio seguro. Sin embargo, hemos puesto en marcha una serie de procedimientos de seguridad,
              tal y como se indica en este Aviso de Privacidad. Si se le ha asignado una cuenta, esta &aacute;rea est&aacute;
              protegida por su nombre de usuario y contrase&ntilde;a, que nunca debe divulgar a nadie.
            </span>
          </p>
          <p className="c20">
            <span className="c0">
              Tenga en cuenta que las comunicaciones a trav&eacute;s de Internet, como los correos
              electr&oacute;nicos/webmails, no son seguras a menos que est&eacute;n codificadas. Sus comunicaciones pueden
              pasar por varios pa&iacute;ses antes de ser entregadas. Esta es la naturaleza de la World Wide Web/Internet. No
              podemos aceptar la responsabilidad de cualquier acceso no autorizado o p&eacute;rdida de informaci&oacute;n
              personal que est&eacute; fuera de nuestro control.
            </span>
          </p>
          <p className="c35">
            <span className="c0">
              Nos esforzaremos razonablemente por aplicar pol&iacute;ticas, normas y medidas t&eacute;cnicas adecuadas para
              proteger los Datos Personales que tenemos bajo nuestro control (teniendo en cuenta el tipo y la cantidad de
              esos datos) contra el acceso no autorizado, el uso o la divulgaci&oacute;n indebidos, la modificaci&oacute;n no
              autorizada, la destrucci&oacute;n ilegal o la p&eacute;rdida accidental.
            </span>
          </p>
          <p className="c21">
            <span className="c0">
              Nos aseguraremos de que su informaci&oacute;n no se revele a instituciones o autoridades gubernamentales,
              excepto si lo exige la ley (por ejemplo, cuando lo soliciten los organismos reguladores o las organizaciones
              encargadas de hacer cumplir la ley de acuerdo con la legislaci&oacute;n aplicable).
            </span>
          </p>
          <ol className="c14 lst-kix_list_6-0" start={9}>
            <li className="c16 li-bullet-0">
              <h1 style={{ display: 'inline' }}>
                <span className="c24">Cookies</span>
              </h1>
            </li>
          </ol>
          <p className="c19">
            <span className="c0">
              dLocal utiliza cookies para almacenar y recopilar informaci&oacute;n sobre su uso de nuestro sitio web. Las
              cookies son peque&ntilde;os archivos de texto almacenados por el navegador en el disco duro de su equipo.
              Env&iacute;an informaci&oacute;n almacenada en ellos a nuestro servidor web cuando usted accede a nuestro Sitio
              Web. Estas cookies nos permiten establecer ajustes personales y cargar sus preferencias personales para mejorar
              su experiencia. Puede obtener m&aacute;s informaci&oacute;n sobre las cookies en www.allaboutcookies.org
              (https://www.allaboutcookies.org), y m&aacute;s informaci&oacute;n sobre las cookies que utilizamos en nuestra{' '}
            </span>
            <span className="c0 c12">&quot;Pol&iacute;tica sobre Cookies&quot;</span>
            <span className="c0">&nbsp;disponible en nuestro sitio web </span>
            <span className="c27 c24">
              <a className="c5" href="https://dlocalgo.com/es/legal/politicas-de-cookies">
                AQU&Iacute;
              </a>
            </span>
            <span className="c0">.</span>
          </p>
          <ol className="c14 lst-kix_list_6-0" start={10}>
            <li className="c16 li-bullet-0">
              <h1 style={{ display: 'inline' }}>
                <span className="c24">Sus derechos</span>
              </h1>
            </li>
          </ol>
          <p className="c19">
            <span className="c0">
              Tomaremos todas las medidas razonables para garantizar que toda la informaci&oacute;n que recojamos, utilicemos
              o divulguemos sea precisa, completa y est&eacute; actualizada. P&oacute;ngase en contacto con nosotros si sus
              datos cambian o si cree que la informaci&oacute;n que tenemos sobre usted no es exacta o completa.
            </span>
          </p>
          <p className="c21">
            <span className="c0">En algunos casos, usted tambi&eacute;n puede tener los derechos de:</span>
          </p>
          <p className="c29 c25">
            <span className="c42 c24"></span>
          </p>
          <ol className="c14 lst-kix_list_1-0 start" start={1}>
            <li className="c51 c38 li-bullet-1">
              <span className="c2">Solicitar acceso </span>
              <span className="c0">
                a sus Datos Personales (com&uacute;nmente conocida como &quot;solicitud de acceso del titular de los
                datos&quot;). Esto le permite recibir una copia de los Datos Personales que tenemos sobre usted y comprobar
                que los estamos tratando legalmente.
              </span>
            </li>
            <li className="c53 c38 li-bullet-5">
              <span className="c2">Solicitar la correcci&oacute;n </span>
              <span className="c0">
                de los Datos Personales que tenemos sobre ustedes. Esto le permite corregir cualquier dato incompleto o
                inexacto que tengamos sobre usted, aunque es posible que tengamos que verificar la exactitud de la nueva
                informaci&oacute;n que nos proporcione.
              </span>
            </li>
            <li className="c38 c48 li-bullet-5">
              <span className="c2">Solicitar la eliminaci&oacute;n de sus Datos Personales. </span>
              <span className="c0">
                Esto le permite pedirnos que borremos o eliminemos los Datos Personales cuando no haya una buena raz&oacute;n
                para que sigamos trat&aacute;ndolos. No obstante, tenga en cuenta que no siempre podremos atender su
                solicitud de eliminaci&oacute;n por motivos legales espec&iacute;ficos que le ser&aacute;n notificados.
              </span>
            </li>
            <li className="c40 c38 li-bullet-1">
              <span className="c2">Objetar el tratamiento </span>
              <span className="c0">
                de sus Datos Personales cuando nos basemos en un inter&eacute;s leg&iacute;timo (o en los de un tercero) y
                haya algo en su situaci&oacute;n particular que le haga oponerse al tratamiento por este motivo, ya que
                considera que afecta a sus derechos y libertades fundamentales. Tambi&eacute;n tiene derecho a oponerse
                cuando tratemos sus datos personales con fines de mercadeo directo. En algunos casos, podemos demostrar que
                tenemos motivos leg&iacute;timos imperiosos para tratar sus datos que no anulan sus derechos y libertades.
              </span>
            </li>
            <li className="c38 c57 li-bullet-5">
              <span className="c2">Solicitar restricci&oacute;n del tratamiento </span>
              <span className="c0">
                de sus Datos Personales. Esto le permite pedirnos que suspendamos el tratamiento de sus datos personales en
                los siguientes casos: si quiere que establezcamos la exactitud de los datos; cuando el uso que hacemos de los
                datos es ilegal, pero no quiere que los borremos; cuando necesita que conservemos los datos aunque ya no los
                necesitemos, ya que los necesita para establecer, ejercer o defender reclamos legales; o si se ha opuesto a
                que utilicemos sus datos, pero necesitamos verificar si tenemos motivos leg&iacute;timos imperiosos para
                utilizarlos.
              </span>
            </li>
            <li className="c26 li-bullet-1">
              <span className="c2">Solicitar la transferencia de sus Datos Personales </span>
              <span className="c0">
                a usted o a un tercero. Le proporcionaremos a usted, o a un tercero que usted haya elegido, sus Datos
                Personales en un formato estructurado, de uso com&uacute;n y legible por m&aacute;quina. Tenga en cuenta que
                este derecho solo se aplica a la informaci&oacute;n automatizada que usted haya consentido inicialmente que
                utilicemos o cuando hayamos utilizado la informaci&oacute;n para cumplir un contrato con usted.
              </span>
            </li>
            <li className="c31 li-bullet-1">
              <span className="c2">Retirar el consentimiento en cualquier momento </span>
              <span className="c0">
                cuando nos basemos en el consentimiento para el tratamiento de sus Datos Personales. Sin embargo, esto no
                afectar&aacute; a la legalidad de cualquier tratamiento realizado antes de que usted retire su
                consentimiento. Si retira su consentimiento, es posible que no podamos ofrecerle determinados productos o
                servicios. Le avisaremos si este es el caso.
              </span>
            </li>
          </ol>
          <p className="c13">
            <span className="c0"></span>
          </p>
          <h3 className="c51">
            <span className="c2">C&oacute;mo ejercer sus derechos.</span>
          </h3>
          <p className="c17">
            <span className="c0"></span>
          </p>
          <p className="c28">
            <span className="c0">Haga clic en este </span>
            <span className="c36 c24">
              <a
                className="c5"
                href="https://omnitrack.vinciworks.com/dlocal/forms/assigned/839b5347-1ed6-4941-9338-2ecf897d448d"
                target="_blank"
              >
                ENLACE
              </a>
            </span>
            <span className="c0">
              &nbsp;y llene el formulario para presentar su solicitud. Este es el canal exclusivo para presentar una
              solicitud y ejercer sus derechos. Cualquier otra forma de presentar una solicitud no ser&aacute; atendida.
            </span>
          </p>
          <p className="c17">
            <span className="c0"></span>
          </p>
          <ol className="c14 lst-kix_list_6-0" start={11}>
            <li className="c16 li-bullet-0">
              <h1 style={{ display: 'inline' }}>
                <span className="c24">Cu&aacute;nto tiempo conservamos los datos personales</span>
              </h1>
            </li>
          </ol>
          <p className="c28">
            <span className="c0">
              Solo conservaremos sus Datos personales durante el tiempo que haya dado su consentimiento o cuando sea
              necesario para que podamos brindarle nuestros servicios o cumplir con los fines para los que los recopilamos,
              incluso con el fin de satisfacer cualquier requisito legal, contable o informativo.
            </span>
          </p>
          <p className="c17">
            <span className="c0"></span>
          </p>
          <p className="c28">
            <span className="c0">
              Tomaremos las medidas razonables para destruir, anonimizar o desidentificar permanentemente los Datos
              personales que ya no sean necesarios para los prop&oacute;sitos permitido por la Legislaci&oacute;n de
              protecci&oacute;n de datos.
            </span>
          </p>
          <p className="c17">
            <span className="c0"></span>
          </p>
          <p className="c28">
            <span className="c0">
              Por ejemplo, por ley tenemos que mantener informaci&oacute;n b&aacute;sica sobre nuestros clientes (incluidos
              datos de contacto, identidad, financieros y de transacciones). Para determinar el per&iacute;odo de
              retenci&oacute;n apropiado para los Datos personales, consideramos la cantidad, la naturaleza y la sensibilidad
              de los Datos personales, el riesgo potencial de da&ntilde;o por el uso o la divulgaci&oacute;n no autorizados
              de Sus Datos personales, los fines para los que procesamos sus Datos personales y si podemos lograr esos
              prop&oacute;sitos a trav&eacute;s de otros medios, y los requisitos legales aplicables.
            </span>
          </p>
          <ol className="c14 lst-kix_list_6-0" start={12}>
            <li className="c16 li-bullet-0">
              <h1 style={{ display: 'inline' }}>
                <span className="c24">Cambios</span>
              </h1>
            </li>
          </ol>
          <p className="c28">
            <span className="c0">
              Nos reservamos el derecho de modificar o editar este Aviso de privacidad de vez en cuando a nuestra
              discreci&oacute;n, como para reflejar cambios en el negocio o las pr&aacute;cticas de dLocal. Podemos cambiar
              el Aviso de privacidad en cualquier momento publicando el Aviso de privacidad modificado en el sitio web de
              dLocal, incluida la publicaci&oacute;n de un aviso en la p&aacute;gina de inicio del sitio web de dLocal que
              indique que se ha realizado un cambio.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
