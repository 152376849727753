export const SHOPIFY = 'SHOPIFY';
export const SHOPIFY_TRANSPARENT = 'SHOPIFY_TRANSPARENT';
export enum MenuCodes {
  HOME = 'HOME',
  REPORTS = 'REPORTS',
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
  EMAIL_VALIDATION = 'EMAIL_VALIDATION',
  PAYMENT_METHODS = 'PAYMENT_METHODS',
  TRANSACTIONS = 'TRANSACTIONS',
  TRANSACTION_DETAIL = 'TRANSACTION_DETAIL',
  SETTLEMENTS = 'SETTLEMENTS',
  PAYMENT_LINK = 'PAYMENT_LINK',
  CREATE_ONE_TIME_PAYMENT_LINK = 'CREATE_ONE_TIME_PAYMENT_LINK',
  CREATE_RECURRING_PAYMENT_LINK = 'CREATE_RECURRING_PAYMENT_LINK',
  CREATED_PAYMENT_LINKS = 'CREATED_PAYMENT_LINKS',
  VIRTUAL_POS = 'VIRTUAL_POS',
  PAYMENT_BUTTON = 'PAYMENT_BUTTON',
  PAYMENT_INTEGRATION = 'PAYMENT_INTEGRATION',
  COMPANY_INFORMATION = 'COMPANY_INFORMATION',
  REFUNDS = 'REFUNDS',
  CLIENTS = 'CLIENTS',
  BANK_ACCOUNTS = 'BANK_ACCOUNTS',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  SUBSCRIPTIONS_PLANS = 'SUBSCRIPTIONS_PLANS',
  SUBSCRIPTIONS_DETAIL = 'SUBSCRIPTIONS_DETAIL',
  SUBSCRIPTIONS_CREATE_PLAN = 'SUBSCRIPTIONS_CREATE_PLAN',
  SUBSCRIPTIONS_PLANS_CREATE = 'SUBSCRIPTIONS_PLANS_CREATE',
  SUBSCRIPTIONS_PLANS_EDIT = 'SUBSCRIPTIONS_PLANS_EDIT',
  SUBSCRIPTIONS_PLANS_DETAILS = 'SUBSCRIPTIONS_PLANS_DETAILS',
}

export enum StatusSession {
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
  INVALID = 'INVALID',
  UNDEFINED = 'UNDEFINED',
}

export const HTTPCodes = {
  NOT_FOUND: 404,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export enum MessageTypes {
  INFO = 'INFO',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export enum PaymentMethodTypes {
  VOUCHER = 'VOUCHER',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CREDIT_CARD = 'CREDIT_CARD',
}

export const CheckoutTypes = {
  LINK: 'LINK',
  BUTTON: 'BUTTON',
  VIRTUAL_POS: 'VIRTUAL_POS',
};

export enum PaymentStatus {
  DECLINED = 'DECLINED',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
}

export enum InputType {
  COMBO_BOX = 'COMBO_BOX',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  PHONE = 'PHONE',
  TEXT = 'TEXT',
}

export enum PaymentSolutionType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  SHOPIFY = 'SHOPIFY',
  PAYMENT_LINK = 'PAYMENT_LINK',
  PAYMENT_BUTTON = 'PAYMENT_BUTTON',
  VIRTUAL_POS = 'VIRTUAL_POS',
  OTHERS = 'OTHERS',
}

export enum CreatedPaymentLinkType {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}

export enum AccountCreatorRole {
  EMPLOYEE = 'EMPLOYEE',
  OWNER = 'OWNER',
  PARTNER = 'PARTNER',
  LEGAL_REPRESENTATIVE = 'LEGAL_REPRESENTATIVE',
  CFO = 'CFO',
  CTO = 'CTO',
  CCO = 'CCO',
  CIO = 'CIO',
  CMO = 'CMO',
  FINANCIAL_OFFICER_MANAGER = 'FINANCIAL_OFFICER_MANAGER',
  TECHNOLOGY_OFFICER_MANAGER = 'TECHNOLOGY_OFFICER_MANAGER',
  COMMERCIAL_MANAGER = 'COMMERCIAL_MANAGER',
  DIRECTOR_OF_OPERATIONS = 'DIRECTOR_OF_OPERATIONS',
  MARKETING_MANAGER = 'MARKETING_MANAGER',
  ECOMMERCE_MANAGER = 'ECOMMERCE_MANAGER',
  LEGAL_MANAGER = 'LEGAL_MANAGER',
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  OTHER = 'OTHER',
}

export enum Frequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum SubscriptionStatus {
  CONFIRMED = 'CONFIRMED',
  CREATED = 'CREATED',
}

export enum SubscriptionExecutionStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
}

export enum SubscriptionFrequency {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  ANNUALLY = 'ANNUALLY',
  CUSTOM = 'CUSTOM',
}

export enum SubscriptionCustomFrequency {
  DAY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTH = 'MONTHLY',
  YEAR = 'YEARLY',
}

export const SUBSCRIPTION_ALL_COUNTRIES = 'ALL';

export enum MerchantFeatures {
  JUMIO_KYC_INTEGRATION = 'JUMIO_KYC_INTEGRATION',
  ONBOARD_BY_COMPANY_TYPE = 'ONBOARD_BY_COMPANY_TYPE',
}
