import React, { useState } from 'react';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import './styles.scss';
import l from '../../../../lang';
import AccordionMenu from '../../../common/accordion-menu';
import CountryList from '../country-list';
import { countriesByRegions } from '../../../../config';
import Dialog from '../../../common/dialog';
import ButtonLink from '../../../common/button-link';
import { segmentPaymentMethodEditCoverageRegionCountries } from '../../../../utils/analytics';

import { useDashboard } from '../../../../store/dashboard';

const Coverage = ({ countriesWithPMs = [], cancelEdit, saveSelection }) => {
  const [selectedCountries, setSelectedCountries] = useState((countriesWithPMs.length && countriesWithPMs) || []);
  const [defaultSelection] = useState(selectedCountries);

  const [confirmCoverage, setConfirmCoverage] = useState(false);
  const [eliminatedCountries, setEliminatedCountries] = useState([]);

  const { merchantMe } = useDashboard();
  const countryCodeToRemove = merchantMe?.country;

  const modifiedCountriesByRegions = { ...countriesByRegions };

  if (!merchantMe.allowLocalToLocal) {
    for (const region in modifiedCountriesByRegions) {
      if (modifiedCountriesByRegions[region].includes(countryCodeToRemove)) {
        modifiedCountriesByRegions[region] = modifiedCountriesByRegions[region].filter(
          country => country !== countryCodeToRemove
        );
      }
    }
  }

  return (
    <div className="coverage">
      <div className="coverage__content">
        <div className="coverage__continents">
          <AccordionMenu
            items={[
              {
                id: 'LA',
                title: l('region.LA'),
                content: (
                  <CountryList
                    allSelectedCountries={selectedCountries}
                    countriesInRegion={modifiedCountriesByRegions.LA}
                    onChange={countries => {
                      segmentPaymentMethodEditCoverageRegionCountries({
                        region: 'latin',
                        countries: countries,
                      });
                      setSelectedCountries(countries);
                    }}
                  />
                ),
              },
              {
                id: 'AF_ME',
                title: l('region.AF_ME'),
                content: (
                  <CountryList
                    allSelectedCountries={selectedCountries}
                    countriesInRegion={modifiedCountriesByRegions.AF_ME}
                    onChange={countries => {
                      segmentPaymentMethodEditCoverageRegionCountries({
                        region: 'africa',
                        countries: countries,
                      });
                      setSelectedCountries(countries);
                    }}
                  />
                ),
              },
              {
                id: 'AS',
                title: l('region.AS'),
                content: (
                  <CountryList
                    allSelectedCountries={selectedCountries}
                    countriesInRegion={modifiedCountriesByRegions.AS}
                    onChange={countries => {
                      segmentPaymentMethodEditCoverageRegionCountries({
                        region: 'asia',
                        countries: countries,
                      });
                      setSelectedCountries(countries);
                    }}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
      <div className="coverage__buttons">
        <div className="coverage__cancel-btn-right">
          <ButtonLink text={l('common.cancel')} onClick={() => cancelEdit()} />
        </div>
        <div className="coverage__save-btn">
          <ButtonCustom
            text={l('paymentMethods.coverageSave')}
            textLoading={l('form.sending')}
            size="full-square"
            color="primary"
            disabled={selectedCountries.length === 0}
            onClick={() => {
              const eliminated = defaultSelection.filter(coWithPMs => !selectedCountries.includes(coWithPMs));
              if (eliminated.length) {
                setEliminatedCountries(eliminated);
                setConfirmCoverage(true);
              } else {
                saveSelection(selectedCountries);
              }
            }}
          />
        </div>
        <div className="coverage__cancel-btn-bottom">
          <ButtonLink text={l('common.cancel')} onClick={() => cancelEdit()} />
        </div>
      </div>
      {confirmCoverage && (
        <Dialog
          titleCenter
          dividers={false}
          title={l('paymentMethod.confirmCoverage.title')}
          unpaddingFooter
          unpaddingContent
          body={
            <div className="confirm-body-container">
              <div>{l('paymentMethod.confirmCoverage.msg')}</div>
              <br />
              <div>
                {eliminatedCountries.map((co, idx) => (
                  <b key={co}>
                    {l(`countryCode.${co}`)}
                    {idx + 1 !== eliminatedCountries.length ? <span>, </span> : <></>}
                  </b>
                ))}
              </div>
            </div>
          }
          footer={
            <div className="confirm-footer-container">
              <ButtonCustom text={l('common.cancel')} size="half-left" onClick={() => setConfirmCoverage(false)} />
              <ButtonCustom
                name="btn-confirm"
                text={l('common.confirm')}
                variant="contained"
                size="half-right"
                onClick={() => {
                  setConfirmCoverage(false);
                  saveSelection(selectedCountries);
                }}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export default Coverage;
