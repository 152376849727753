import { useEffect, useState } from 'react';
import { useSubscriptions } from '../../../../store/subscriptions';
import './styles.scss';
import Loading from '../../../common/loading';
import MessageError from '../../../common/message-error';
import { Refresh } from '@mui/icons-material';
import ButtonMoreMenu from '../../../common/button-more-menu';
import { Urls } from '../../../../config/urls';
import l from '../../../../lang';
import { ReactComponent as AllCountriesIcon } from '../../../../assets/icons/all-countries.svg';
import { ReactComponent as EmptyIcon } from '../../../../assets/icons/empty-subscriptions.svg';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../config/routes';
import { Plan } from '../../../../store/subscriptions/interfaces';
import DialogSubscriptionCancel from '../dialog-cancel';
import PlanCreatedDialog from './create-edit/plan-created-dialog';

const Plans = () => {
  const history = useHistory();
  const { loading, error, allPlans, getPlans, setPlanDetails, setDeactivatePlan } = useSubscriptions();
  const [cancelPlan, setCancelPlan] = useState<Plan | null>(null);
  const [sharePlan, setSharePlan] = useState<Plan | null>(null);

  useEffect(() => {
    getPlans();
  }, [allPlans?.outdated]);

  useEffect(() => {
    if (allPlans?.outdated) getPlans();
  }, [allPlans?.outdated]);

  const showDetails = (plan: Plan) => {
    setPlanDetails(plan);
    history.push(Routes.SUBSCRIPTIONS_PLANS_DETAILS);
  };

  const showEdit = (plan: Plan) => {
    history.push(Routes.SUBSCRIPTIONS_PLANS_EDIT.replace(':id', `${plan.id}`));
  };

  return (
    <div className="plans">
      {loading && (
        <div className="plans__loading">
          <Loading />
        </div>
      )}
      {error && (
        <div className="plans__error">
          <MessageError iconClose={<Refresh />} onClose={() => getPlans()} />
        </div>
      )}
      {!loading && !error && (
        <div className="plans__content">
          {(allPlans?.data?.length &&
            allPlans.data.map(plan => (
              <div key={plan.id} className="plans__box">
                <div className="plans__box__menu">
                  <ButtonMoreMenu
                    id={`plan_id_${plan.id}_options`}
                    items={[
                      { content: l('subscriptions.seeDetails'), onClick: () => showDetails(plan) },
                      {
                        content: l('subscriptions.plans.details.share'),
                        onClick: () => setSharePlan(plan),
                        hidden: !plan.active,
                      },
                      { content: l('subscriptions.seeEdit'), onClick: () => showEdit(plan), hidden: !plan.active },
                      {
                        content: <span className="plans__box__menu__alert">{l('subscriptions.plans.details.cancel')}</span>,
                        onClick: () => setCancelPlan(plan),
                        hidden: !plan.active,
                      },
                    ]}
                  />
                </div>
                <div>
                  {plan.active ? (
                    <div className="plans__box__status_active">{l('subscriptions.plans.active')}</div>
                  ) : (
                    <div className="plans__box__status_inactive">{l('subscriptions.plans.inactive')}</div>
                  )}
                </div>
                <div id={`plan_id_${plan.id}`} className="plans__box__title">
                  {plan.name}
                </div>
                <div id={`plan_id_${plan.id}`} className="plans__box__desc">
                  {plan.description}
                </div>
                <div className="plans__box__divider" />
                <div className="plans__box__info">
                  <div className="plans__box__info__title">{l('subscriptions.plans.amount')}</div>
                  <div className="plans__box__info__value">
                    {plan.currency} {plan.amount}
                  </div>
                </div>
                <div className="plans__box__info">
                  <div className="plans__box__info__title">{l('subscriptions.plans.country')}</div>
                  <div className="plans__box__info__value">
                    {plan?.country ? (
                      <span className="subscription_details__country">
                        <img
                          alt=""
                          className="subscription_details__country_flag"
                          src={`${Urls.flags.replace(':code', plan.country)}`}
                        />
                        <span>&nbsp;{plan.country}</span>
                      </span>
                    ) : (
                      <span className="subscription_details__country">
                        <AllCountriesIcon height={18} width={18} />
                        <span>&nbsp;{l('subscriptions.allCountries')}</span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))) || (
            <div className="plans__no_data">
              <EmptyIcon />
              <div className="plans__no_data__title">{l('subscriptions.plans.empty.title')}</div>
              <div>{l('subscriptions.plans.empty.body')}</div>
            </div>
          )}
        </div>
      )}
      {cancelPlan && (
        <DialogSubscriptionCancel
          cancelType="PLAN"
          onClose={confirm => {
            setCancelPlan(null);
            if (confirm) setDeactivatePlan(cancelPlan.id);
          }}
        />
      )}
      {sharePlan && (
        <PlanCreatedDialog
          url={sharePlan?.url as string}
          name={sharePlan?.name as string}
          frequencyType={sharePlan?.frequencyType as string}
          frequencyValue={sharePlan?.frequencyValue as number}
          country={sharePlan?.country as string}
          currency={sharePlan?.currency as string}
          amount={sharePlan?.amount as number}
          description={sharePlan?.description as string}
          onClose={() => {
            setSharePlan(null);
          }}
        />
      )}
    </div>
  );
};

export default Plans;
