import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Refresh } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import Loading from '../../../common/loading';
import { getExistingClients } from '../../../../store/payment-link/actions';
import Table from '../../../common/table';
import { Urls } from '../../../../config/urls';
import l from '../../../../lang';
import './styles.scss';
import MessageError from '../../../common/message-error';
import Dialog from '../../../common/dialog';
import ClientDetail from '../../clients/detail';
import RadioButton from '../../../common/radio-button';
import Message from '../../../common/message';
import { MessageTypes } from '../../../../constants';
import ButtonMore from '../../../common/button-more';

const ExistingClients = ({ value, country, existingClients, externalError, fnGetExistingClients, fnSelectClient }) => {
  const { loading, error, clients } = existingClients;
  const isTabletMobile = useMediaQuery('(max-width:768px)');
  const [clientDetail, setClientDetail] = useState(null);

  useEffect(() => {
    if (value?.country !== country) {
      fnSelectClient(null);
    }
    fnGetExistingClients(0, country);
    // eslint-disable-next-line
  }, [fnGetExistingClients, country]);

  return (
    <>
      <div className="existing-clients">
        <div className="existing-clients__container">
          {loading && (
            <div className="existing-clients__loading">
              <Loading />
            </div>
          )}
          {error && (
            <div className="existing-clients__error">
              <MessageError iconClose={<Refresh />} onClose={() => fnGetExistingClients(0, country)} />
            </div>
          )}
          {!loading && !error && (
            <div className="existing-clients__table">
              <Table
                dense
                maxHeight="356px"
                withBtnMenu={isTabletMobile}
                headers={['', l('clients.country'), l('clients.fullName'), l('clients.email')]}
                emptyBody={
                  <div className="existing-clients__no_data">
                    <div className="existing-clients__no_data__title">
                      {country
                        ? l('paymentLink.existingClients.noClientsCountry')
                        : l('paymentLink.existingClients.noClients')}
                    </div>
                  </div>
                }
                body={
                  (clients?.data?.length > 0 &&
                    clients.data.map(client => {
                      const item = [
                        <RadioButton
                          name={`rb-client-${client.id?.toString()}`}
                          checked={value?.id === client.id}
                          value={client.id}
                          onChange={() => {
                            fnSelectClient(client);
                          }}
                        />,
                        <div className="existing-clients__country">
                          <img
                            alt={`img-country-${client.country}`}
                            src={`${Urls.flags.replace(':code', client.country)}`}
                          />
                          <span>{l(`countryCode.${client.country}`)}</span>
                        </div>,
                        <span>
                          {client.firstName || client.lastName ? `${client.firstName ?? ''} ${client.lastName ?? ''}` : '-'}
                        </span>,
                        <span>{client.email || '-'}</span>,
                      ];
                      if (isTabletMobile) {
                        item.push(<ButtonMore onClick={() => setClientDetail(client)} />);
                      }
                      return item;
                    })) ||
                  null
                }
                rowsPerPageOptions={[clients?.size]}
                rowsLength={clients?.totalElements}
                rowsPerPage={clients?.size}
                page={clients?.page}
                handleChangePage={(event, newPage) => {
                  fnGetExistingClients(newPage, country);
                }}
              />
              {clientDetail && (
                <Dialog
                  dividers={false}
                  unpaddingFooter
                  unpaddingContent
                  fullScreenMobile
                  titleCenter
                  title={l('clients.details')}
                  handleClose={() => setClientDetail(null)}
                  body={<ClientDetail detail={clientDetail} />}
                />
              )}
            </div>
          )}
        </div>
        {clients?.data?.length > 0 && !loading && !error && externalError && (
          <div className="existing-clients__external-error">{externalError}</div>
        )}
      </div>
    </>
  );
};

ExistingClients.propTypes = {
  value: PropTypes.object,
  existingClients: PropTypes.object,
  fnGetExistingClients: PropTypes.func,

  externalError: PropTypes.string,
  fnSelectClient: PropTypes.func,
};

const mapStateToProps = state => ({
  existingClients: state.paymentLink.existingClients,
});

const mapDispatchToProps = dispatch => ({
  fnGetExistingClients: (page, country) => dispatch(getExistingClients(page, country)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExistingClients);
