import { call, put, takeLatest, select, take } from 'redux-saga/effects';
import { getTask, patchTask, postTask } from '../../services';
import { Urls } from '../../config/urls';
import { JumioType, startError, startSuccess, statusUpdated } from './actions';
import { checkToken } from '../auth/sagas';
import { PanelStore } from '../interfaces';
import { getMerchantMe, DashboardActionType } from '../dashboard/actions';
import { JumioStatusType } from './interfaces';

function* start() {
  yield call(refreshStatus);
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(postTask, {
    url: Urls.api.jumioKycFlow.replace(':id', `${state.dashboard.merchantMe?.id || ''}`),
    token: state.auth.token,
    body: {},
  });

  if (response) {
    yield put(startSuccess(response));
  } else {
    yield put(startError());
  }
}

function* refreshStatus() {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  yield call(patchTask, {
    url: Urls.api.jumioKycFlow.replace(':id', `${state.dashboard.merchantMe?.id || ''}`),
    token: state.auth.token,
    body: {},
  });

  const { response } = yield call(getTask, {
    url: Urls.api.jumioKycFlow.replace(':id', `${state.dashboard.merchantMe?.id || ''}`),
    token: state.auth.token,
  });

  if (response) {
    if (response.status !== JumioStatusType.PENDING) {
      yield put(getMerchantMe());
      yield take(DashboardActionType.GET_MERCHANT_ME_SUCCESS);
    }
    yield put(statusUpdated({ status: response.status, tryCount: response.tryCount, limitReached: response.limitReached }));
  } else {
    yield put(statusUpdated({ status: JumioStatusType.UNKNOWN }));
  }
}

export function* jumioStartSaga() {
  yield takeLatest(JumioType.START, start);
}

export function* jumioRefreshStatusSaga() {
  yield takeLatest(JumioType.REFRESH_STATUS, refreshStatus);
}
