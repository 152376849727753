import { Values } from '../interfaces';

export enum DashboardActionType {
  INIT_DASHBOARD = 'INIT_DASHBOARD',
  INIT_DASHBOARD_FINISHED = 'INIT_DASHBOARD_FINISHED',
  GET_USER_ME_SUCCESS = 'GET_USER_ME_SUCCESS',
  GET_USER_ME_ERROR = 'GET_USER_ME_ERROR',
  GET_MERCHANT_ME = 'GET_MERCHANT_ME',
  GET_MERCHANT_ME_SUCCESS = 'GET_MERCHANT_ME_SUCCESS',
  GET_MERCHANT_ME_ERROR = 'GET_MERCHANT_ME_ERROR',
  UPDATE_MERCHANT_ME = 'UPDATE_MERCHANT_ME',
  UPDATE_USER_ME = 'UPDATE_USER_ME',
}

export const initDashboard = (menuSelected?: string) => ({
  type: DashboardActionType.INIT_DASHBOARD,
  menuSelected,
});

export const initDashboardSuccess = () => ({
  type: DashboardActionType.INIT_DASHBOARD_FINISHED,
});

export const userMeSuccess = (response: any) => ({
  type: DashboardActionType.GET_USER_ME_SUCCESS,
  response,
});

export const userMeError = (error: any) => ({
  type: DashboardActionType.GET_USER_ME_ERROR,
  error,
});

export const getMerchantMe = () => ({
  type: DashboardActionType.GET_MERCHANT_ME,
});

export const merchantMeSuccess = (response: any) => ({
  type: DashboardActionType.GET_MERCHANT_ME_SUCCESS,
  response,
});

export const merchantMeError = (error: any) => ({
  type: DashboardActionType.GET_MERCHANT_ME_ERROR,
  error,
});

export const updateMerchantMe = (newValues: Values) => ({
  type: DashboardActionType.UPDATE_MERCHANT_ME,
  newValues,
});

export const updateUserMe = (newValues: Values) => ({
  type: DashboardActionType.UPDATE_USER_ME,
  newValues,
});
