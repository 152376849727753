import { MouseEvent, ReactNode, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button, ButtonProps } from '../button';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
interface ButtonMenuProps extends ButtonProps {
  items: { content: string | ReactNode; onClick: () => void }[];
}

const ButtonMenu = (props: ButtonMenuProps) => {
  const { children, items, onClick, endIcon, ...others } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        fullWidth
        endIcon={endIcon || anchorEl ? <ArrowUpIcon /> : <ArrowDownIcon />}
        onClick={e => {
          handleClick(e);
          onClick && onClick(e);
        }}
      >
        {children}
      </Button>
      <Menu
        id="button-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map((item, idx) => (
          <MenuItem
            key={idx}
            onClick={e => {
              handleClose();
              item.onClick();
            }}
          >
            {item.content}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ButtonMenu;
