import BlockedMessage from './blocked-message';
import FraudMessage from './fraud-message';
import './styles.scss';

const AlertMessages = () => (
  <div className="alert_message__container">
    <FraudMessage />
    <BlockedMessage />
  </div>
);

export default AlertMessages;
