import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss';

const Message = ({ type = '', align = 'left', iconClose, onClose, title, body }) => (
  <div
    className={`message message_${align} ${type && typeof type === 'string' ? type.toLowerCase() : ''} ${
      onClose ? 'with-close' : ''
    }`}
  >
    {onClose && (
      <div className="close" onClick={onClose}>
        {iconClose || <CloseIcon />}
      </div>
    )}
    {title && <div className="message-title">{title}</div>}
    {body}
  </div>
);

Message.propTypes = {
  type: PropTypes.string,
  iconClose: PropTypes.object,
  onClose: PropTypes.func,
  title: PropTypes.string,
  aling: PropTypes.bool,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Message;
