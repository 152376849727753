import { FunctionComponent } from 'react';
import l from '../../../lang';
import { Tooltip } from '../../common/tooltip';
import { BankAccountStatus } from '../../../store/bank-accounts/interfaces';

export interface BankAccountPillsProps {
  status: BankAccountStatus;
  preferred: boolean;
}

const BankAccountPills: FunctionComponent<BankAccountPillsProps> = ({ status, preferred }) => {
  return (
    <div className="bank_accounts__pills">
      <Tooltip
        title={l(`bankAccounts.status.tooltip.${status}`)}
        enterTouchDelay={0}
        classes={{
          tooltip: 'bank_accounts__tooltip',
          arrow: 'bank_accounts__tooltip__arrow',
        }}
      >
        <div className={`bank_accounts__status__${status}`}>{l(`bankAccounts.status.${status}`)}</div>
      </Tooltip>
      {preferred && (
        <Tooltip
          title={l(`bankAccounts.status.tooltip.starred`)}
          enterTouchDelay={0}
          classes={{
            tooltip: 'bank_accounts__tooltip',
            arrow: 'bank_accounts__tooltip__arrow',
          }}
        >
          <div className={`bank_accounts__starred`}>☆ {l(`bankAccounts.starred`)}</div>
        </Tooltip>
      )}
    </div>
  );
};

export default BankAccountPills;
