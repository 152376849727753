import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MoreVert } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  root: {
    padding: 8,
    '@media (max-width:768px)': {
      padding: 5,
    },
  },
}));

export interface ButtonMoreProps {
  id?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ButtonMore = ({ id, onClick }: ButtonMoreProps) => {
  const classes = useStyles();
  return (
    <IconButton id={id} classes={classes} onClick={e => onClick && onClick(e)}>
      <MoreVert fontSize="small" />
    </IconButton>
  );
};

export default ButtonMore;
