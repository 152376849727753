import './styles.scss';
import l from '../../../../lang';
import TopDashboardSection from '../../../common/top-dashboard-section';
import Card from '../../../common/card';
import CardDescription from '../../../common/card-description';
import { Routes } from '../../../../config/routes';
import { useHistory } from 'react-router-dom';
import { segmentPaymentLinkListBtnCreatePaymentLink } from '../../../../utils/analytics';
import Tabs from '../../../common/tabs';
import ButtonMenu from '../../../common/button-menu';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link.svg';
import { ReactComponent as SyncIcon } from '../../../../assets/icons/sync.svg';
import CreatedOneTimePaymentLink from './one-time';
import CreatedRecurringPaymentLink from './reccuring';
import { usePaymentLink } from '../../../../store/payment-link';
import { CreatedPaymentLinkType } from '../../../../constants';

const CreatedPaymentLinks = () => {
  const history = useHistory();
  const { tabSelected, changeTabSelected } = usePaymentLink();

  const onCreateOneTimePaymentLinkHandler = () => {
    segmentPaymentLinkListBtnCreatePaymentLink();
    history.push(Routes.CREATE_ONE_TIME_PAYMENT_LINK);
  };

  const onCreateRecurringPaymentLinkHandler = () => {
    history.push(Routes.CREATE_RECURRING_PAYMENT_LINK);
  };

  return (
    <div className="created_payment_links">
      <TopDashboardSection title={l('menu.item.PAYMENT_LINK')} />
      <Card>
        <CardDescription>{l('createdPaymentLink.subheadline')}</CardDescription>
        <div className="created_payment_links__create_btn">
          <ButtonMenu
            items={[
              {
                content: (
                  <div className="created_payment_links__create_btn__text">
                    <LinkIcon />
                    <span>{l('createdPaymentLink.oneTime')}</span>
                  </div>
                ),
                onClick: onCreateOneTimePaymentLinkHandler,
              },
              {
                content: (
                  <div className="created_payment_links__create_btn__text">
                    <SyncIcon />
                    <span>{l('createdPaymentLink.recurring')}</span>
                  </div>
                ),
                onClick: onCreateRecurringPaymentLinkHandler,
              },
            ]}
          >
            {l('createdPaymentLink.redirectBtn')}
          </ButtonMenu>
        </div>
        <div className="created_payment_links__content">
          <Tabs
            id="payment-links"
            idxItemSelected={tabSelected === CreatedPaymentLinkType.RECURRING ? 1 : 0}
            items={[
              {
                title: l('createdPaymentLink.oneTimeLinks'),
                content: <CreatedOneTimePaymentLink />,
              },
              {
                title: l('createdPaymentLink.recurringLinks'),
                content: <CreatedRecurringPaymentLink />,
              },
            ]}
            onChange={idx =>
              changeTabSelected(idx === 1 ? CreatedPaymentLinkType.RECURRING : CreatedPaymentLinkType.ONE_TIME)
            }
          />
        </div>
      </Card>
    </div>
  );
};

export default CreatedPaymentLinks;
