import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import Input from '../common/input';
import ButtonCustom from '../common/__deprecated__/button-custom';
import { commons as validations } from '../../utils/validations';
import l from '../../lang';
import './styles.scss';
import MessageError from '../common/message-error';
import Loading from '../common/loading';
import icInfo from '../../assets/icons/info-filled.svg?url';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PasswordResetForm = ({ requestNewPassword, loading, error, errorInfo, fnSubmit }) => {
  const queryParams = useQuery();
  const [showError, setShowError] = useState(true);

  const initialValues = requestNewPassword ? { password: '' } : { email: '' };

  const validate = values => {
    const errors = {};
    if (requestNewPassword) {
      if (!values.password) {
        errors.password = l('form.input.required');
      } else if (!validations.passwordRequiredCharacters(values.password)) {
        errors.password = l('form.input.password.invalid');
      } else if (!validations.passwordLengthRequired(values.password)) {
        errors.password = l('form.input.password.invalidLength');
      }
    } else if (!values.email) {
      errors.email = l('form.input.required');
    } else if (!validations.emailValid(values.email)) {
      errors.email = l('form.email.invalid');
    }
    return errors;
  };
  return (
    <div className="password-reset-form">
      {loading && <Loading fullScreen />}
      {error && showError && (
        <div className="password-reset-error">
          <MessageError errorInfo={errorInfo} onClose={() => setShowError(false)} />
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={values => {
          setShowError(true);
          if (requestNewPassword) {
            fnSubmit(values.password, queryParams.get('token'));
          } else {
            fnSubmit(values.email);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {!requestNewPassword && (
              <Input
                type="email"
                name="email"
                label={l('emailAddress.label')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                disabled={loading}
                error={errors.email && touched.email && errors.email}
              />
            )}
            {requestNewPassword && (
              <>
                <Input
                  type="password"
                  name="password"
                  label={l('newPassword.label')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  disabled={loading}
                  error={errors.password && touched.password && errors.password}
                />
                <div className="password-description">
                  <label className="select-info">
                    <img src={icInfo} className="img-info" alt="ic-info" />
                    {l('password.helpText')}
                  </label>
                </div>
              </>
            )}
            <div className="password-reset-btn-container">
              <ButtonCustom
                type="submit"
                text={l('passwordReset.btn')}
                textLoading={l('form.sending')}
                loading={loading}
                size="full-square"
                variant="contained"
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

PasswordResetForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  fnSubmit: PropTypes.func,
};

export default PasswordResetForm;
