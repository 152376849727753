import React, { useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { Routes } from '../../../config/routes';
import { redirecTo } from '../../../utils';
import ButtonCustom from '../__deprecated__/button-custom';
import MessageError from '../message-error';
import l from '../../../lang';
import './styles.scss';

const ErrorView = () => {
  useEffect(() => {
    Bugsnag.notify(new Error('General error page was displayed.'));
  }, []);

  return (
    <div className="error-view" onClick={() => redirecTo(Routes.HOME)}>
      <MessageError />
      <div className="error-view__btn">
        <ButtonCustom text={l('common.return')} onClick={() => redirecTo(Routes.HOME)} />
      </div>
    </div>
  );
};

export default ErrorView;
