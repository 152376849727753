import { FC, useState } from 'react';
import l from '../../../lang';
import { Button } from '../../common/button';
import Card from '../../common/card';
import CardDescription from '../../common/card-description';
import Tabs from '../../common/tabs';
import TopDashboardSection from '../../common/top-dashboard-section';
import Plans from './plans';
import './styles.scss';
import Subscriptors from './subscribers';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../config/routes';

const Subscriptions: FC<{ initTabSelected: number }> = ({ initTabSelected }) => {
  const history = useHistory();
  const [tabSelected, setTabSelected] = useState(initTabSelected);
  return (
    <div className="subscriptions">
      <TopDashboardSection title={l('menu.item.SUBSCRIPTIONS')} />
      <Card>
        <CardDescription>{l('subscriptions.subheadline')}</CardDescription>
        <div className="subscriptions__create_btn">
          <Button fullWidth startIcon={<PlusIcon />} onClick={() => history.push(Routes.SUBSCRIPTIONS_PLANS_CREATE)}>
            {l('subscriptions.createPlan')}
          </Button>
        </div>
        <div className="subscriptions__content">
          <Tabs
            id="subscriptions"
            idxItemSelected={tabSelected}
            items={[
              {
                title: l('subscriptions.subscriptors'),
                content: <Subscriptors />,
              },
              {
                title: l('subscriptions.plans'),
                content: <Plans />,
              },
            ]}
            onChange={idx => setTabSelected(idx)}
          />
        </div>
      </Card>
    </div>
  );
};

export default Subscriptions;
