import './polyfills';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import App from './containers/app';
import configureStore from './store';
import './styles/index.scss';
import { ErrorBoundary } from './config/bugsnag';
import { FeaturesProvider } from './hooks/use-features';
import ErrorView from './components/common/error-view';
import theme from './styles/theme';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorView}>
          <FeaturesProvider>
            <App />
          </FeaturesProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('root')
);
