import { FunctionComponent } from 'react';
import { Card } from '@mui/material';
import Parser from 'html-react-parser';
import l from '../../../../../lang';
import { Button } from '../../../../common/button';
import Dialog from '../../../../common/dialog';
import { ReactComponent as DocumentCheckedImage } from '../../../../../assets/images/document-checked.svg';

import '../styles.scss';

interface DialogSuccessKycStep1Props {
  onClose: () => void;
}

const CardSuccessKycStep1: FunctionComponent<DialogSuccessKycStep1Props> = ({ onClose }) => (
  <Card classes={{ root: 'dialog_kyc__card dialog_kyc__card__success' }} elevation={0}>
    <DocumentCheckedImage />
    <div>{Parser(l('kyc_step1.message.completed.headline'))}</div>
    <div>{Parser(l('kyc_step1.message.completed.subheadline'))}</div>
    <div className="dialog_kyc__card__button">
      <Button onClick={onClose}>{l('kyc_step1.message.completed.button')}</Button>
    </div>
  </Card>
);

const DialogSuccessKycStep1: FunctionComponent<DialogSuccessKycStep1Props> = ({ onClose }) => {
  return (
    <Dialog
      dividers={false}
      unpaddingFooter
      unpaddingContent
      type="xs"
      titleCenter
      handleClose={onClose}
      body={<CardSuccessKycStep1 onClose={onClose} />}
    />
  );
};

export default DialogSuccessKycStep1;
