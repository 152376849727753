import { FC } from 'react';
import Card from '../../../../../common/card';
import './styles.scss';
import l from '../../../../../../lang';
import { SubscriptionValues } from '..';
import { SUBSCRIPTION_ALL_COUNTRIES } from '../../../../../../constants';
import { Urls } from '../../../../../../config/urls';
import { ReactComponent as AllCountriesIcon } from '../../../../../../assets/icons/all-countries.svg';

interface PlanSummaryProps {
  values: SubscriptionValues;
}

export const PlanSummary: FC<PlanSummaryProps> = ({ values }) => {
  return (
    <Card>
      <div className="plan_summary">
        <div className="plan_summary__title">{l('subscriptions.planSummary.title')}</div>
        <div className="plan_summary__row">
          <div className="plan_summary__label">{l('subscriptions.plan.summaryCard.planName')}</div>
          <div className="plan_summary__value">{values.planName || '-'}</div>
        </div>
        <div className="plan_summary__row">
          <div className="plan_summary__label">{l('subscriptions.plan.summaryCard.planDescription')}</div>
          <div className="plan_summary__value">{values.description || '-'}</div>
        </div>
        <div className="plan_summary__row">
          <div className="plan_summary__label">{l('subscriptions.plan.summaryCard.subscribersCountry')}</div>
          <div className="plan_summary__value">
            {values.country === SUBSCRIPTION_ALL_COUNTRIES && (
              <>
                <AllCountriesIcon height={18} />
                <span>{l('subscriptions.allCountries')}</span>
              </>
            )}
            {(values.country && values.country !== SUBSCRIPTION_ALL_COUNTRIES && (
              <>
                <img className="plan_summary__value__flag" src={Urls.flags.replace(':code', values.country)} alt="" />
                <span>{l(`countryCode.${values.country}`)}</span>
              </>
            )) ||
              '-'}
          </div>
        </div>
        <div className="plan_summary__row">
          <div className="plan_summary__label">{l('subscriptions.plan.summaryCard.paymentAmount')}</div>
          <div className="plan_summary__value">
            {values.currency} {values.amount || '-'}
          </div>
        </div>
        <div className="plan_summary__row">
          <div className="plan_summary__label">{l('subscriptions.plan.summaryCard.paymentFrequency')}</div>
          <div className="plan_summary__value">
            {values.frequencyValue}&nbsp;
            {l(`subscriptions.frequency.${values.frequencyType}${(values.frequencyValue || 0) > 1 ? '.plural' : ''}`)}
          </div>
        </div>
      </div>
    </Card>
  );
};
